import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

export const TableToggleButtonWrapper = styled(Flex)`
  width: ${theme.sizes.xs};
  height: ${theme.sizes.xs};
  padding: 0;
  border-radius: ${theme.radii.md};
  position: absolute;
  right: 16px;
  align-items: center;
  justify-content: center;
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
`;
