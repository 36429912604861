import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { IconWrapper, StyledBackLink } from 'components/ui/Back/styled';
import { useBackLinkHistory } from 'utils/hooks/useBackLinkHistory';

type BackLinkProps = { state?: Record<string, unknown> };

export const BackLink: React.FC<BackLinkProps> = ({ state }) => {
  const { url, linkCopy } = useBackLinkHistory();
  return (
    <StyledBackLink>
      <Link to={{ pathname: url, state }}>
        <IconWrapper>
          <FiChevronLeft size={14} />
        </IconWrapper>
        {linkCopy}
      </Link>
    </StyledBackLink>
  );
};
