import React, { forwardRef } from 'react';

import { FieldLabel, FormControl } from 'components/primitive';

import { SwitchContainer, SwitchInput, SwitchToggleButton } from './styled';

type Props = {
  label?: string;
  name?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  defaultChecked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  last?: boolean;
  'data-cy'?: string;
};

export const Switch = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      isChecked,
      isDisabled,
      children,
      onChange,
      defaultChecked,
      label,
      last = false,
      'data-cy': dataCy,
      ...rest
    },
    ref,
  ) => {
    return (
      <FormControl last={last} style={{ width: '42px' }}>
        <FieldLabel {...rest}>
          {label && <span>{label}</span>}
          <SwitchInput
            type="checkbox"
            ref={ref}
            name={name}
            defaultChecked={defaultChecked}
            checked={isChecked}
            disabled={isDisabled}
            onChange={onChange}
            data-cy={dataCy}
          />
          <SwitchContainer>
            <SwitchToggleButton />
          </SwitchContainer>
        </FieldLabel>
      </FormControl>
    );
  },
);
