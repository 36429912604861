import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import {
  Column,
  useTable,
  useFlexLayout,
  useSortBy,
  useRowSelect,
} from 'react-table';

import {
  TableHeaderColumn,
  TableHeaderColumnSortbox,
  TableRow,
  TableRowItem,
  TableWrapper,
} from '../styled';
import type { NotificationType } from 'api/notifications/models';
import { Checkbox } from 'components/forms';
import { Skeleton } from 'components/primitive';
import { Button, SortDownIcon, SortUpIcon } from 'components/ui';
import { Box } from 'rebass';
import { selectNotificationsFiltersData } from 'screens/Notifications/notificationsFiltersSlice';
import {
  bulkUpdateNotifications,
  updateNotification,
} from 'screens/Notifications/notificationsSlice';
import { theme } from 'styles/theme';

type Props = {
  data: NotificationType[];
  columns: Column<NotificationType>[];
  isLoading?: boolean;
};

export const NotificationsTable: React.FC<Props> = ({
  data,
  columns,
  isLoading,
}) => {
  const { isResolved } = useSelector(selectNotificationsFiltersData);

  const dispatch = useDispatch();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    //@ts-ignore
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          width: 50,
          Header: ({
            getToggleAllRowsSelectedProps,
            isAllRowsSelected,
            selectedFlatRows,
          }: any) => {
            return (
              <>
                <Checkbox
                  size="sm"
                  {...getToggleAllRowsSelectedProps()}
                  isChecked={isAllRowsSelected}
                  isIndeterminate={
                    selectedFlatRows.length !== 0 && !isAllRowsSelected
                  }
                />
              </>
            );
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: any) => {
            return (
              <Checkbox
                size="md"
                {...row.getToggleRowSelectedProps()}
                isChecked={row.isSelected}
              />
            );
          },
        },
        ...columns,
      ]);
    },
  );

  const handleClearNotifications = () => {
    if (selectedFlatRows.length === 1) {
      dispatch(
        updateNotification(selectedFlatRows[0].original.id, {
          resolved: isResolved ? false : true,
        }),
      );
    } else {
      const ids = selectedFlatRows.map(
        (notification: any) => notification.original.id,
      );

      dispatch(bulkUpdateNotifications(ids));
    }
  };

  return (
    <>
      <TableWrapper>
        <Box as="table" width="100%" {...getTableProps()}>
          <Box as="thead">
            {headerGroups.map((headerGroup) => (
              <Box
                as="tr"
                backgroundColor="whiteLilac"
                sx={{ position: 'sticky', top: 0, zIndex: 2 }}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <TableHeaderColumn
                    as="th"
                    canSort={column.canSort}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <TableHeaderColumnSortbox isSorted={column.isSorted}>
                      {column.canSort && column.isSortedDesc === true && (
                        <SortDownIcon stroke={theme.colors.primary} />
                      )}
                      {column.canSort && column.isSortedDesc === false && (
                        <SortUpIcon stroke={theme.colors.primary} />
                      )}
                      {column.canSort && column.isSortedDesc === undefined && (
                        <SortUpIcon />
                      )}
                    </TableHeaderColumnSortbox>
                  </TableHeaderColumn>
                ))}
              </Box>
            ))}
          </Box>
          <Box as="tbody" {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                <TableRow
                  as="tr"
                  opacity={isResolved ? 0.5 : 1}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    if (isLoading) {
                      return (
                        <Skeleton
                          key={index}
                          as="td"
                          bg="white"
                          width="100%"
                          height="lg"
                        />
                      );
                    }

                    return (
                      <TableRowItem as="td" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableRowItem>
                    );
                  })}
                </TableRow>
              );
            })}
          </Box>
        </Box>
      </TableWrapper>
      <Button
        height="smd"
        opacity={!!selectedFlatRows.length ? 1 : 0}
        leftComponent={<FiCheck />}
        onClick={handleClearNotifications}
        sx={{
          pointerEvents: !!selectedFlatRows.length ? 'auto' : 'none',
          userSelect: !!selectedFlatRows.length ? 'auto' : 'none',
          position: 'absolute',
          top: theme.dimensions.topBarHeight + 20,
          left: 200,
        }}
      >
        {isResolved ? 'Return' : 'Clear'} {selectedFlatRows.length} notification
        {selectedFlatRows.length > 1 && 's'}
      </Button>
    </>
  );
};
