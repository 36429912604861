import React from 'react';

import {
  MenuLink,
  MenuItemTitle,
} from 'components/layout/PrivateRoute/components/MainLayout/styled';

interface Props {
  active: number;
  title: string;
  url: string;
}

export const MainMenuItem: React.FC<Props> = ({
  active,
  url,
  title,
  children,
}) => {
  return (
    <MenuLink to={url} isactive={active}>
      {children}
      <MenuItemTitle ml={4} isactive={active}>
        {title}
      </MenuItemTitle>
    </MenuLink>
  );
};
