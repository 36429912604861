import { UserSupervisor } from 'api/users/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';
import { sortByKey } from 'utils/sortByKey';

type SupervisorsPayload = {
  users: UserSupervisor[];
};

const API_URL = '/users?supervisor=true';

export const useSupervisors = () => {
  const { data } = useSWR<SupervisorsPayload>(API_URL, fetcher);

  return {
    isLoading: !data,
    supervisors: data?.users.sort(sortByKey('full_name')) || [],
  };
};
