import { REGEX } from 'utils/constants';
import i18n from 'utils/i18n';
import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object().shape({
    api_url: Yup.string()
      .required(i18n.t('errors.required'))
      .matches(REGEX.url, i18n.t('errors.invalidUrl')),
    api_key: Yup.string().required(i18n.t('errors.required')),
  });
