import React from 'react';

import { TextLink } from './styled';

interface Props {
  onClick?(...params: any): void;
  color?: string;
  fontSize?: string;
}

export const TextLinkWrapper: React.FC<Props> = ({ ...props }) => (
  <TextLink {...props} />
);
