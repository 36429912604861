import { MdClose } from 'react-icons/md';

import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const RemoveButton = styled.button`
  position: relative;
  opacity: 0;
  margin-left: ${theme.space[2]};
  width: ${theme.sizes.xsm};
  height: ${theme.sizes.xsm};
  border-radius: ${theme.radii.xxl};
  background-color: ${theme.colors.danger};
  color: ${theme.colors.white};
  padding: ${theme.space[2]};
  transition: opacity 150ms;

  &:focus {
    outline: none;
    box-shadow: ${theme.shadows.outline};
  }
`;

export const RemoveIcon = styled(MdClose)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
