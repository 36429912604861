import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex } from 'rebass';
import { SubheaderWrapper } from 'screens/OrganizationSettings/styled';

import { LdapIntegrationForm } from './forms/LdapIntegrationForm';

interface Props {
  isIntegrated: boolean;
  onClose: () => void;
  canIntegrate: boolean;
}

export const LdapSection = ({
  isIntegrated: integrated,
  onClose,
  canIntegrate,
}: Props) => {
  const { t } = useTranslation();

  const [isIntegrated, setIsIntegrated] = useState(false);

  useEffect(() => {
    setIsIntegrated(integrated);
  }, [integrated]);

  const renderInfo = () => {
    return (
      <SubheaderWrapper active={true}>
        <Box mr={2} display="inline">
          <i className="pi pi-info-circle" />
        </Box>
        <Box display="inline">
          {t(
            isIntegrated
              ? 'organizationSettings.ldap.integrationOn'
              : 'organizationSettings.ldap.integrationValid',
          )}
        </Box>
      </SubheaderWrapper>
    );
  };

  return (
    <Flex flexDirection="column" mt={4}>
      {canIntegrate ? (
        renderInfo()
      ) : (
        <LdapIntegrationForm
          onClose={onClose}
          setIsIntegrated={setIsIntegrated}
        />
      )}
    </Flex>
  );
};
