import { RootState } from '../rootReducer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  initialState: { isLoading: false },
  name: 'loading',
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading } = loadingSlice.actions;

export const selectIsLoading = ({ loading }: RootState) => loading.isLoading;

export default loadingSlice.reducer;
