import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t('errors.required'))
      .min(8, t('errors.password.min'))
      .max(20, t('errors.password.max'))
      .matches(/[A-Z]/, t('errors.password.missingUppercase'))
      .matches(/[a-z]/, t('errors.password.missingLowercase'))
      .matches(/[0-9]/, t('errors.password.missingNumber'))
      .matches(
        /[!@#$%^&*()\-_=+{}[\]'":;\\|?/.>,<`~]/,
        t('errors.password.missingSpecialCharacter'),
      ),
    passwordConfirmation: Yup.string()
      .required(t('errors.required'))
      .min(8, t('errors.min8Chars'))
      .max(20, t('errors.password.max'))
      .matches(/[A-Z]/, t('errors.password.missingUppercase'))
      .matches(/[a-z]/, t('errors.password.missingLowercase'))
      .matches(/[0-9]/, t('errors.password.missingNumber'))
      .matches(
        /[!@#$%^&*()\-_=+{}[\]'":;\\|?/.>,<`~]/,
        t('errors.password.missingSpecialCharacter'),
      )
      .oneOf(
        [Yup.ref('password')],
        t('errors.passwordConfirmationDoesntMatch'),
      ),
  });
