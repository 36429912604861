import React from 'react';

import { FormikTextInput } from 'components/forms/formik-prime';
import { Button, GithubIcon } from 'components/ui';
import { Box } from 'rebass';

type RepositoryProps = {
  index: number;
  name: string;
  remove: (index: number) => void;
};

const Repository: React.FC<RepositoryProps> = ({ index, remove }) => {
  const handleRemoveRepository = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    remove(index);
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <FormikTextInput
          data-cy="github-repository-input"
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            (e.target.value = e.target.value.trim())
          }
          icon={<GithubIcon />}
          label={`Github - repository name #${index + 1}`}
          name={`repositories_attributes[${index}].name`}
        />
        <Button
          data-cy="github-repository-remove-btn"
          variant="simpleDanger"
          onClick={handleRemoveRepository}
          height="sm"
          sx={{ position: 'absolute', top: -3, right: 0 }}
        >
          remove
        </Button>
      </Box>
    </>
  );
};

export default Repository;
