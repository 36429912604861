import React, { ChangeEvent, useState, useEffect } from 'react';

import { FieldErrorMessage, FieldLabel } from 'components/primitive';
import { useField } from 'formik';

import {
  Extensions,
  Image,
  ImageWrapper,
  Input,
  InputWrapper,
  Label,
  Title,
  RemoveAvatar,
} from './styled';

type Props = {
  name: string;
  label?: string;
  file?: string | null;
  error?: string;
  onChange?: (value: File | null) => void;
  defaultValue?: string | null;
  'data-cy'?: string;
  validate?: (value: number) => undefined | string | Promise<any>;
};

export const FormikUploader = ({
  name,
  label,
  file,
  onChange,
  defaultValue,
  validate,
  'data-cy': dataCy,
}: Props) => {
  const [uploadedFileSrc, setUploadedFileSrc] = useState(file);
  const [, meta, helpers] = useField({ name, validate });

  const { setValue } = helpers;

  useEffect(() => {
    if (defaultValue) {
      setUploadedFileSrc(defaultValue);
    }
  }, [defaultValue]);

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const srcUrl = URL.createObjectURL(file);
      setUploadedFileSrc(srcUrl);
      setValue(file);
      onChange && onChange(file);
    }
  };

  const handleRemoveAvatar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setValue(null);
    setUploadedFileSrc(null);
    onChange && onChange(null);
  };

  return (
    <>
      <FieldLabel>
        {label && <span>{label}</span>}
        <Input
          data-cy={dataCy}
          name={name}
          type="file"
          onChange={handleUpload}
          accept=".png,.jpg"
        />
        {uploadedFileSrc ? (
          <ImageWrapper>
            <Image src={uploadedFileSrc} />
            <Label change>
              <Title>Click here to change</Title>
              <Extensions>.jpg or .png max 2MB</Extensions>
            </Label>
            <RemoveAvatar
              data-cy="remove-avatar-btn"
              onClick={handleRemoveAvatar}
            >
              <i className="pi pi-trash" />
            </RemoveAvatar>
          </ImageWrapper>
        ) : (
          <InputWrapper isInvalid={!!meta.error}>
            <Label>
              <Title>Click here to upload</Title>
              <Extensions>.jpg or .png max 2MB</Extensions>
            </Label>
          </InputWrapper>
        )}
      </FieldLabel>
      {meta.error && meta.touched && (
        <FieldErrorMessage>{meta.error}</FieldErrorMessage>
      )}
    </>
  );
};
