import React from 'react';

import { Flex } from 'rebass';
import { theme } from 'styles/theme';

const border = `1px solid ${theme.colors.neutral}`;

export const TableMainHeader: React.FC = ({ children, ...props }) => (
  <Flex
    {...props}
    as="header"
    sx={{
      backgroundColor: theme.colors.whiteLilac,
      margin: '0 -2px',
      paddingLeft: '2px',
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndices['1'],
      width: 'auto',
      borderTop: border,
      borderBottom: border,
      height: '52px',
      overflow: 'auto',
    }}
  >
    {children}
  </Flex>
);
