import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SnackbarState = {
  visible: boolean;
  message: string;
  isError: boolean;
};

type DisplaySnackbarPayload = {
  message: string;
  isError?: boolean;
};

const INITIAL_STATE: SnackbarState = {
  visible: false,
  message: '',
  isError: false,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: INITIAL_STATE,
  reducers: {
    displaySnackbar(state, action: PayloadAction<DisplaySnackbarPayload>) {
      const { message, isError = false } = action.payload;

      state.visible = true;
      state.message = message;
      state.isError = isError;
    },
    closeSnackbar(state) {
      state.visible = false;
      state.message = '';
    },
  },
});

export const { displaySnackbar, closeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
