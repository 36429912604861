import { useState, useCallback } from 'react';

export const useDisclosure = (defaultState: boolean = false) => {
  const [isOpen, setIsOpen] = useState(defaultState);

  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onToggle = useCallback(
    () => setIsOpen((prevIsOpen) => !prevIsOpen),
    [],
  );

  return { isOpen, onOpen, onClose, onToggle };
};
