import { OrganizationSettingsPayload } from 'api/organizationSettings/models';

export const getJiraFieldValues = (
  orgSettings?: OrganizationSettingsPayload,
) => ({
  jira_field_configuration_scheme_id: orgSettings
    ? orgSettings.jira_field_configuration_scheme_id
    : '',
  jira_issue_type_scheme_id: orgSettings
    ? orgSettings.jira_issue_type_scheme_id
    : '',
  jira_issue_type_screen_scheme_id: orgSettings
    ? orgSettings.jira_issue_type_screen_scheme_id
    : '',
  jira_workflow_scheme_id: orgSettings
    ? orgSettings.jira_workflow_scheme_id
    : '',
  default_user_jira_group_id: orgSettings
    ? orgSettings.default_user_jira_group_id
    : '',
  default_project_jira_group_id: orgSettings
    ? orgSettings.default_project_jira_group_id
    : '',
  high_level_access_role_id: orgSettings
    ? orgSettings.high_level_access_role_id
    : '',
  low_level_access_role_id: orgSettings
    ? orgSettings.low_level_access_role_id
    : '',
});
