import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { PrivateRoute } from 'components/layout';
import { ModalRoot } from 'components/layout/ModalRoot';
import { Snackbar } from 'components/ui';
import ScrollToTop from 'components/ui/ScrollToTop';
import { getUserDetails, selectRedirectRoute } from 'screens/Auth/authSlice';
import { Dashboard } from 'screens/Dashboard';
import { OrganizationSettings } from 'screens/OrganizationSettings';
import { ProjectProvider } from 'screens/Project/hooks/projectContext';
import { ProjectAdd } from 'screens/Project/screens/Add';
import { Projects } from 'screens/Projects';
import { WeeklySummary } from 'screens/WeeklySummary';
import { AppDispatch } from 'store';
import { history } from 'utils/history';
import { useGTM } from 'utils/hooks/useGTM';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';
import { useUserAuthentication } from 'utils/hooks/useUserAuthentication';

import { PrimeProgressBar } from './components/prime/ProgressBar';
import { AddExampleDataModal } from './components/ui/AddExampleDataModal';
import { useOrganizationSettings } from './fetchers/hooks/useOrganizationSettings';
import NotFound from './screens/404';
import Auth from './screens/Auth';
import AvailableResources from './screens/AvailableResources';
import Notifications from './screens/Notifications';
import Project from './screens/Project';
import User from './screens/User';
import Users from './screens/Users';
import { selectIsLoading } from './store/slices/loadingSlice';
import { getUsers } from './store/slices/usersSlice';

const App: React.FC = () => {
  const loggedIn = useUserAuthentication();
  const dispatch: AppDispatch = useDispatch();
  const redirectRoute = useSelector(selectRedirectRoute);
  const isLoading = useSelector(selectIsLoading);
  const { integrations } = useOrganizationSettings();
  const permissions = {
    dashboard: usePermissions(PERMISSIONS.DASHBOARD),
    notifications: usePermissions(PERMISSIONS.NOTIFICATIONS),
    project: usePermissions(PERMISSIONS.VIEW_PROJECT),
    projects: usePermissions(PERMISSIONS.VIEW_PROJECT_LIST),
    users: usePermissions(PERMISSIONS.USERS),
    user: usePermissions(PERMISSIONS.USER),
    weeklySummary: usePermissions(PERMISSIONS.WEEKLY_SUMMARY),
    createExampleData: usePermissions(PERMISSIONS.CREATE_COMPANY_EXAMPLE_DATA),
    addProject: usePermissions(PERMISSIONS.ADD_PROJECT),
  };
  const [
    shouldDisplayExampleDataModal,
    setShouldDisplayExampleDataModal,
  ] = useState(false);

  useGTM(true);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getUsers());
    } else {
      dispatch(getUserDetails());
    }
  }, [dispatch, loggedIn]);

  useEffect(() => {
    const isExampleDataModalBlocked = localStorage.getItem(
      'isExampleDataModalBlocked',
    );

    if (permissions.createExampleData && !isExampleDataModalBlocked) {
      setShouldDisplayExampleDataModal(true);
    }
  }, [permissions.createExampleData]);

  return (
    <Router history={history}>
      <AddExampleDataModal
        isOpen={shouldDisplayExampleDataModal}
        onClose={() => setShouldDisplayExampleDataModal(false)}
      />
      <ModalRoot />
      <Snackbar />
      <ScrollToTop />
      {isLoading && <PrimeProgressBar />}
      <Switch>
        <Redirect exact from="/members/:userId" to="/users/:userId" />
        <Redirect exact from="/" to={redirectRoute} />
        <PrivateRoute
          exact
          path="/users"
          isScrollable
          hasPermissions={permissions.users}
        >
          <Users />
        </PrivateRoute>
        <PrivateRoute path="/users/:userId" hasPermissions={permissions.user}>
          <User />
        </PrivateRoute>
        <PrivateRoute
          exact
          isScrollable
          path="/dashboard"
          hasPermissions={
            permissions.dashboard &&
            integrations?.atlassian &&
            integrations?.toggl
          }
        >
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/available-resources"
          hasPermissions={permissions.projects}
        >
          <AvailableResources />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/projects"
          hasPermissions={permissions.projects}
        >
          <Projects />
        </PrivateRoute>
        <PrivateRoute
          path="/projects/new"
          hasPermissions={permissions.addProject}
        >
          <ProjectAdd />
        </PrivateRoute>
        <PrivateRoute
          path="/projects/:projectId"
          hasPermissions={permissions.project}
        >
          <ProjectProvider>
            <Project />
          </ProjectProvider>
        </PrivateRoute>
        <PrivateRoute
          path="/notifications"
          hasPermissions={permissions.notifications}
        >
          <Notifications />
        </PrivateRoute>
        <PrivateRoute
          path="/weekly-summary"
          hasPermissions={permissions.weeklySummary}
        >
          <WeeklySummary />
        </PrivateRoute>

        <PrivateRoute path="/organization-settings" isScrollable={false}>
          <OrganizationSettings />
        </PrivateRoute>
        <Route path="/auth">
          <Auth />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
