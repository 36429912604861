import { mutateOrganizationSettings } from '../mutations/mutateOrganizationSettings';
import { IntegrateAppsType } from 'api/organizationSettings/models';
import { organizationAPI } from 'api/phases/endpoints';

export const removeIntegration = async (
  integration: keyof IntegrateAppsType,
) => {
  await organizationAPI.removeIntegration(integration);
  await mutateOrganizationSettings();
};
