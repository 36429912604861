import { TFunction } from 'i18next';
import { REGEX } from 'utils/constants';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    link: Yup.string()
      .required(t('errors.required'))
      .matches(REGEX.url, t('errors.invalidUrl')),
    name: Yup.string()
      .required(t('errors.required'))
      .max(60, t('errors.max60Chars')),
  });
