import { IntegrateAppsType } from '../organizationSettings/models';
import {
  UserPosition,
  UserSeniority,
  UserSkill,
  UserTeam,
} from '../users/models';
import { PhaseMemberPayload, PhaseTypePayload } from 'api/phases/models';
import {
  PhaseUserHour,
  PhaseUserRange,
  UserHoursPayload,
} from 'api/projects/models';
import { AxiosResponse } from 'axios';
import { ProjectTypesPayload } from 'fetchers/hooks/users/useProjectTypes';
import qs from 'qs';
import httpClient from 'utils/httpClient';

const RANGES_PATH = '/phase_user_ranges';
const HOURS_PATH = '/phase_user_hours';
const PHASE_TYPES_PATH = '/phase_types';
const POSITIONS_PATH = '/positions';
const SKILLS_PATH = '/skill_names';
const PROJECT_TYPES_PATH = '/project_types';
const SENIORITY_PATH = '/seniorities';
const TEAM_PATH = '/teams';
const INTEGRATION_REMOVAL_PATH = '/company/integrations/';

export type PhaseUserRangeRes = {
  phase_user_range: Required<PhaseUserRange>;
};

type PhaseUserHourRes = {
  phase_user_hour: Required<PhaseUserHour>;
};

type PhaseTypesRes = {
  phase_types: PhaseTypePayload[];
};

type UserPositionPayload = {
  position: UserPosition;
};

type UserSeniorityPayload = {
  seniority: UserSeniority;
};

type UserTeamPayload = {
  team: UserTeam;
};

type UserSkillPayload = {
  skill: UserSkill;
};

export const organizationAPI = {
  async updatePosition(
    positionId: number,
    formData: FormData,
  ): Promise<UserPositionPayload> {
    return await httpClient.put(`${POSITIONS_PATH}/${positionId}`, formData);
  },
  async addPosition(formData: FormData): Promise<UserPositionPayload> {
    return await httpClient.post(POSITIONS_PATH, formData);
  },
  async deletePosition(positionId: number): Promise<void> {
    return await httpClient.delete(`${POSITIONS_PATH}/${positionId}`);
  },

  async updateSeniority(
    seniorityId: number,
    formData: FormData,
  ): Promise<UserSeniorityPayload> {
    return await httpClient.put(`${SENIORITY_PATH}/${seniorityId}`, formData);
  },
  async addSeniority(formData: FormData): Promise<UserSeniorityPayload> {
    return await httpClient.post(SENIORITY_PATH, formData);
  },
  async deleteSeniority(seniorityId: number): Promise<void> {
    return await httpClient.delete(
      `${SENIORITY_PATH}/${seniorityId}?delete=true`,
    );
  },

  async updateTeam(
    teamId: number,
    formData: FormData,
  ): Promise<UserTeamPayload> {
    return await httpClient.put(`${TEAM_PATH}/${teamId}`, formData);
  },
  async addTeam(formData: FormData): Promise<UserTeamPayload> {
    return await httpClient.post(TEAM_PATH, formData);
  },
  async deleteTeam(teamId: number): Promise<void> {
    return await httpClient.delete(`${TEAM_PATH}/${teamId}`);
  },

  async updateProjectType(
    typeId: number,
    formData: FormData,
  ): Promise<ProjectTypesPayload> {
    return await httpClient.put(`${PROJECT_TYPES_PATH}/${typeId}`, formData);
  },
  async addProjectType(formData: FormData): Promise<ProjectTypesPayload> {
    return await httpClient.post(PROJECT_TYPES_PATH, formData);
  },
  async deleteProjectType(positionId: number): Promise<void> {
    return await httpClient.delete(`${PROJECT_TYPES_PATH}/${positionId}`);
  },

  async updateSkill(
    skillId: number,
    formData: FormData,
  ): Promise<UserSkillPayload> {
    return await httpClient.put(`${SKILLS_PATH}/${skillId}`, formData);
  },
  async addSkill(formData: FormData): Promise<UserSkillPayload> {
    return await httpClient.post(SKILLS_PATH, formData);
  },
  async deleteSkill(skillId: number): Promise<void> {
    return await httpClient.delete(`${SKILLS_PATH}/${skillId}`);
  },

  async removeIntegration(integration: keyof IntegrateAppsType): Promise<void> {
    return await httpClient.delete(INTEGRATION_REMOVAL_PATH + integration);
  },
};

export const phasesAPI = {
  async addMemberToPhase(
    formData: PhaseMemberPayload,
  ): Promise<AxiosResponse<PhaseUserRangeRes>> {
    return await httpClient.post(`${RANGES_PATH}`, formData);
  },
  async confirmMemberToPhase(
    phaseUserRangeId: number,
  ): Promise<AxiosResponse<PhaseUserRangeRes>> {
    return await httpClient.put(`${RANGES_PATH}/${phaseUserRangeId}`, {
      confirmed: true,
    });
  },
  async deleteMemberFromPhase(
    phaseUserRangeId: number,
  ): Promise<AxiosResponse<void>> {
    return await httpClient.delete(`${RANGES_PATH}/${phaseUserRangeId}`);
  },
  async setMemberHoursOnPhase(
    formData: UserHoursPayload,
  ): Promise<AxiosResponse<PhaseUserHourRes>> {
    return await httpClient.post(`${HOURS_PATH}`, formData);
  },
  async updateMemberHoursOnPhase(
    id: number,
    value: number,
  ): Promise<AxiosResponse<PhaseUserHourRes>> {
    return await httpClient.put(`${HOURS_PATH}/${id}`, { value });
  },
  async getPhaseTypes(): Promise<AxiosResponse<PhaseTypesRes>> {
    return await httpClient.get(PHASE_TYPES_PATH);
  },
  async hideMemberOnPhase(
    id: number,
    phaseId: number,
  ): Promise<AxiosResponse<PhaseUserRangeRes>> {
    return await httpClient.put(`${RANGES_PATH}/${id}?hide`, {
      params: {
        phase_user_range: {
          phase_id: phaseId,
        },
      },
      paramsSerializer: (params: any) =>
        qs.stringify(params, {
          arrayFormat: 'brackets',
          encodeValuesOnly: true,
        }),
    });
  },
  async unhideMemberOnPhase(
    id: number,
    phaseId: number,
    clearHours = false,
  ): Promise<AxiosResponse<PhaseUserRangeRes>> {
    const param = `unhide${clearHours ? '&clear_hours' : ''}`;

    return await httpClient.put(`${RANGES_PATH}/${id}?${param}`, {
      params: {
        phase_user_range: {
          phase_id: phaseId,
        },
      },
      paramsSerializer: (params: any) =>
        qs.stringify(params, {
          arrayFormat: 'brackets',
          encodeValuesOnly: true,
        }),
    });
  },
};
