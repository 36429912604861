import React from 'react';
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai';

import { Text } from 'rebass';
import { theme } from 'styles/theme';

import { CheckmarkWrapper, IconWrapper } from './styled';

export interface CheckmarkProps {
  text: string;
  value: boolean;
}

export const Checkmark: React.FC<CheckmarkProps> = ({ text, value }) => {
  return (
    <>
      <CheckmarkWrapper>
        <IconWrapper style={{ marginRight: '0.5rem' }}>
          {!value ? (
            <AiOutlineCheckCircle color={theme.colors.newGreen} />
          ) : (
            <AiOutlineCloseCircle color={theme.colors.brown} />
          )}
        </IconWrapper>
        <Text color={!value ? theme.colors.newGreen : theme.colors.brown}>
          {text}
        </Text>
      </CheckmarkWrapper>
    </>
  );
};
