import i18n from 'utils/i18n';
import * as Yup from 'yup';

const required = i18n.t('errors.required');

export const validationSchema = () =>
  Yup.object().shape({
    project: Yup.number().required(required).nullable(),
    phase_id: Yup.number().required(required).nullable(),
    start_date: Yup.string().required(required).nullable(),
  });
