import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

export const TableSubItemWrapper = styled(Flex)`
  background-color: ${theme.colors.white};
  flex-direction: column;
  justify-content: center;
  font-size: ${theme.fontSizes.sm};

  &:nth-of-type(even) {
    background-color: ${theme.colors.newLightGrey};
  }
`;
