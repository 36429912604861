import styled from '@emotion/styled/macro';
import { Flex, Image } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  size: 'sm' | 'md' | 'lg' | 'small';
  src?: string;
  clickable?: boolean;
  placeholderVariant?: 'default' | 'white';
  radius: keyof typeof theme.radii;
};

const sizeMap = {
  small: '28px',
  sm: '1.5rem',
  md: '2.69rem',
  lg: '5rem',
};

export const AvatarBox = styled(Flex)<Props>`
  height: ${({ size }) => sizeMap[size]};
  width: ${({ size }) => sizeMap[size]};
  min-width: ${({ size }) => sizeMap[size]};
  border-radius: ${({ radius }) => theme.radii[radius]};
  transition: box-shadow 150ms;
  pointer-events: ${({ clickable }) => (clickable ? 'auto' : 'none')};
  align-items: center;
  justify-content: center;
  color: ${theme.colors.bermudaGray};
  background-color: ${({ placeholderVariant }) =>
    placeholderVariant === 'white'
      ? theme.colors.white
      : theme.colors.lightGrayBlue};
  font-size: ${({ size }) => (size === 'sm' ? theme.fontSizes.xs : 'initial')};
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: ${({ clickable }) =>
      clickable ? theme.shadows.outline : 'none'};
  }
`;

export const AvatarImage = styled(Image)<Props>`
  height: ${({ size }) => sizeMap[size]};
  width: ${({ size }) => sizeMap[size]};
  min-width: ${({ size }) => sizeMap[size]};
  border-radius: ${({ radius }) => theme.radii[radius]};
  object-fit: cover;
  transition: box-shadow 150ms;
  cursor: pointer;

  pointer-events: ${({ clickable }) => (clickable ? 'auto' : 'none')};

  &:focus {
    outline: none;
    box-shadow: ${({ clickable }) =>
      clickable ? theme.shadows.outline : 'none'};
  }
`;
