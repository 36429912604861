import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    username: Yup.string()
      .required(t('errors.required'))
      .typeError(t('errors.required')),
    password: Yup.string().required(t('errors.required')),
    ldap_host: Yup.string().required(t('errors.required')),
    ldap_base: Yup.string().required(t('errors.required')),
    ldap_port: Yup.number()
      .required(t('errors.required'))
      .typeError(t('errors.number')),
  });
