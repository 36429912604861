import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const SkillRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background: ${theme.colors.white};
  margin-bottom: 2px;
  padding: ${theme.space[2]};
  border-radius: ${theme.radii.md};
`;

export const Text = styled.p`
  font-size: ${theme.fontSizes.sm};
  line-height: ${theme.lineHeights.normal};
  color: ${theme.colors.veryDarkGray};
  margin: 0;
`;

export const Circle = styled.div<{ isFilled?: boolean }>`
  width: 12px;
  height: 12px;
  background: ${theme.colors.primary};
  opacity: ${({ isFilled }) => (isFilled ? 1 : 0.2)};
  border-radius: 100%;
  margin: 0 1px;
`;
