import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type CheckBoxGroupBoxTypes = {
  display: string;
  mr?: number;
  mb?: number;
};

export const CheckboxGroupBox = styled.div<CheckBoxGroupBoxTypes>`
  display: ${({ display }) => display};
  margin-right: ${({ mr }) => `${mr}px`};
  margin-bottom: ${({ mb }) => `${mb}px`};
`;

export const CheckboxGroupTitle = styled.p`
  text-transform: uppercase;
  font-size: ${theme.fontSizes.xxs};
  color: ${theme.colors.neutralDark};
  font-family: ${theme.fonts.bold};
  margin-bottom: ${theme.space[3]};
  position: relative;
`;
