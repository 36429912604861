import React from 'react';

import { PrimeSwitch } from 'components/prime';
import { useField } from 'formik';
import { InputSwitchProps } from 'primereact/inputswitch';

type Props = InputSwitchProps & {
  name: string;
};

export const FormikSwitch = ({
  name,
  onChange: customOnChange,
  onBlur: customOnBlur,
  ...restProps
}: Props) => {
  const [field] = useField(name);
  return (
    <PrimeSwitch
      {...field}
      onChange={(event: {
        originalEvent: Event;
        value: boolean;
        target: { name: string; id: string; value: boolean };
      }) => {
        field.onChange(event);
        customOnChange && customOnChange(event);
      }}
      onBlur={(event: Event) => {
        field.onBlur(event);
        customOnBlur && customOnBlur(event);
      }}
      checked={field.value}
      {...restProps}
    />
  );
};
