import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type Props = {
  active?: boolean;
  focused?: boolean;
  left?: string;
};

export const SkillLevel = styled.div<Props>`
  width: 33.3%;
  background-color: ${({ active, focused }) =>
    active
      ? theme.colors.primary
      : focused
      ? theme.colors.primary
      : 'transparent'};
  height: ${theme.space[1]};
  transition: background-color 150ms;
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: ${theme.space[1]};
    width: 1px;
    background-color: ${theme.colors.neutral};
  }

  &:first-of-type {
    border-top-left-radius: ${theme.radii.xs};
    border-bottom-left-radius: ${theme.radii.xs};
    &::before {
      background-color: transparent;
    }
  }

  &:last-of-type {
    border-top-right-radius: ${theme.radii.xs};
    border-bottom-right-radius: ${theme.radii.xs};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -200%;
    left: 0;
    width: 100%;
    height: 500%;
  }
`;
