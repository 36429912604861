import React from 'react';

import { TableSubItemWrapper } from './styled';

type Props = {
  as?: React.ElementType;
  isExpanded?: boolean;
  values: {
    [key: string]: any;
  };
};

export const TableSubItem: React.FC<Props> = ({ children, ...props }) => {
  return <TableSubItemWrapper {...props}>{children}</TableSubItemWrapper>;
};
