import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddNewButton } from '../AddNewButton';
import { RemoveItemModal } from '../RemoveModal';
import { Icon, Wrapper } from '../styled';
import { User, UserPosition } from 'api/users/models';
import { Tag } from 'components/prime';
import { Spin } from 'components/ui';
import { CannotRemoveModal } from 'components/ui/CannotRemoveModal/CannotRemoveModal';
import { removePosition } from 'fetchers/actions/position/removePosition';
import { usePositions } from 'fetchers/hooks/users/usePositions';
import { Box, Flex } from 'rebass';

import { PositionModal } from './components/PositionModal';

export const PositionsSettings = () => {
  const [editedPosition, setEditedPosition] = useState<UserPosition | null>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [positionModalOpen, setPositionModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { positions, isLoading } = usePositions();
  const usersBlockingRemoval = useRef<User[] | null>(null);
  const itemToDelete = useRef<UserPosition | null>(null);

  const addNewPosition = () => {
    setEditedPosition(null);
    setPositionModalOpen(true);
  };

  const editPosition = (position: UserPosition) => {
    setEditedPosition(position);
    setPositionModalOpen(true);
  };

  const deletePosition = () => {
    removePosition(itemToDelete.current?.id!)
      .then(() => setConfirmOpen(false))
      .catch((err) => openInfoModal(err));
  };

  const openInfoModal = ({ data }: any) => {
    if (data && data.errors.includes('users')) {
      setConfirmOpen(false);
      usersBlockingRemoval.current = data.users;
      setDialogOpen(true);
    }
  };

  const confirm = (position: UserPosition) => {
    itemToDelete.current = position;
    setConfirmOpen(true);
  };

  const lowercasePositionNames = useMemo(() => {
    return positions?.map((p) => p.name.toLowerCase()) || [];
  }, [positions]);

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Wrapper justifyContent="flex-start">
            <AddNewButton onClick={addNewPosition} />
            {positions?.map((position) => {
              return (
                <Tag>
                  <Flex p={1}>
                    <Flex flex={1}>
                      <Box mr={2} fontSize="xs">
                        {position.name}
                      </Box>
                    </Flex>
                    <Icon>
                      <i
                        className="pi pi-pencil"
                        onClick={() => editPosition(position)}
                      />
                      <i
                        className="pi pi-trash"
                        onClick={() => confirm(position)}
                      />
                    </Icon>
                  </Flex>
                </Tag>
              );
            })}
          </Wrapper>

          <PositionModal
            isOpen={positionModalOpen}
            setIsOpen={setPositionModalOpen}
            positionList={lowercasePositionNames}
            position={editedPosition || null}
          />
          <CannotRemoveModal
            isOpen={dialogOpen}
            setIsOpen={setDialogOpen}
            usersBlockingRemoval={usersBlockingRemoval.current || []}
            itemName="Position"
          />
          <RemoveItemModal
            message={t('organizationSettings.deleteConfirm', {
              name: itemToDelete.current?.name,
              item: t('userProfile.position').toLowerCase(),
            })}
            close={() => setConfirmOpen(false)}
            isOpen={isConfirmOpen}
            confirm={deletePosition}
            header={t('users.removePosition')}
          />
        </>
      )}
    </>
  );
};
