import React from 'react';

import { Text } from './styled';

export enum Statuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

type Props = {
  status: Statuses;
};

export const StatusLabel: React.FC<Props> = ({ status }) => {
  return <Text variant={status}>{status}</Text>;
};
