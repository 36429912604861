import React, { forwardRef } from 'react';

import { Button as RebassButton, ButtonProps, Flex, SxStyleProp } from 'rebass';

type Props = Omit<ButtonProps, 'css'> & {
  as?: React.ElementType;
  href?: string;
  leftComponent?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  rightComponent?: React.ReactNode;
  sx?: SxStyleProp;
};

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    { as, children, leftComponent, rightComponent, onClick, sx, ...props },
    ref,
  ) => {
    return (
      <RebassButton
        {...props}
        as={as || 'button'}
        ref={ref}
        onClick={onClick}
        sx={sx}
      >
        <Flex alignItems="center" height="100%">
          {leftComponent && (
            <Flex mr={children ? 2 : 0} alignItems="center">
              {leftComponent}
            </Flex>
          )}

          {children}

          {rightComponent && (
            <Flex ml={children ? 2 : 0} alignItems="center">
              {rightComponent}
            </Flex>
          )}
        </Flex>
      </RebassButton>
    );
  },
);
