import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { theme } from 'styles/theme';

export const Person = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${theme.radii.lg};
  border: 1px solid ${theme.colors.neutralMedium};
  padding: 0.3rem 0.65rem;
  & span {
    padding-left: 1.1rem;
    color: ${theme.colors.darkGray};
    pointer-events: none;
    user-select: none;
  }
`;

export const Paragraph = styled(Box)`
  font-family: ${theme.fonts.bold};
  color: ${theme.colors.darkGray};
`;

export const MemberRemoveModalBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
