import React from 'react';

import { Logo } from 'components/ui';
import { Box, Flex, Heading } from 'rebass';
import { theme } from 'styles/theme';

import { BoxWithCursor } from './BoxWithCursor';

export type AuthWrapperProps = {
  heading: string;
  onLogoClick?: () => void;
};

export const Wrapper: React.FC<AuthWrapperProps> = ({
  children,
  heading,
  onLogoClick,
}) => {
  return (
    <Box>
      <BoxWithCursor
        height={`${theme.dimensions.topBarHeight}px`}
        px={8}
        py={5}
        onClick={onLogoClick}
        hasOnClick={!!onLogoClick}
      >
        <Logo />
      </BoxWithCursor>

      <Flex
        minHeight={`calc(100vh - ${theme.dimensions.topBarHeight}px - 96px)`}
        mt="96px"
      >
        <Box maxWidth="440px" width="100%" margin="0 auto">
          <Heading
            textAlign="center"
            fontSize="lg2"
            fontFamily="inherit"
            mb="59px"
          >
            {heading}
          </Heading>

          {children}
        </Box>
      </Flex>
    </Box>
  );
};
