import { useMemo } from 'react';

import { Project } from 'api/projects/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

const API_URL = '/projects/';

export const useProject = (projectId: string) => {
  const { data } = useSWR<{ project: Project }>(API_URL + projectId, fetcher);

  const project = useMemo(() => data?.project, [data?.project]);

  return {
    isLoading: !data,
    project: project,
  };
};
