import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { theme } from 'styles/theme';

type TextProps = {
  isNextToAvatar?: boolean;
};

export const Title = styled.h2`
  padding: ${theme.space[6]} 0 ${theme.space[8]};
  font-size: ${theme.fontSizes.xmd};
  color: ${theme.colors.darkestGray};
  margin: 0;
`;

export const Section = styled.section`
  padding: ${theme.space[0]} ${theme.space[6]} ${theme.space[5]} 0;
  border-bottom: 1px solid ${theme.colors.lighterGray};

  &:last-of-type {
    border: none;
  }
`;

export const SecondaryTitle = styled.h3`
  color: ${theme.colors.primeDarkGrey};
  font-family: ${theme.fonts.bold};
  font-size: ${theme.fontSizes.sm};
  padding-bottom: ${theme.space[2]};
  margin: 0;
`;

export const Text = styled.p<TextProps>`
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.darkestGray};
  margin: ${({ isNextToAvatar }) =>
    isNextToAvatar ? `${theme.space[1]} 0 0 ${theme.space[3]}` : '0'};
  line-height: ${theme.lineHeights.md2};
`;

export const TextDanger = styled(Text)`
  color: ${theme.colors.danger};
  margin-top: 20px;
  font-weight: ${theme.fonts.bold};
  font-size: ${theme.fontSizes.md};
`;

export const EmptyState = styled.p`
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.darkGrayOpacity};
  line-height: ${theme.lineHeights.md2};
  margin: 0;
`;

export const InfoGrid = styled(Box)`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  row-gap: ${theme.space[6]};
`;

export const StyledLink = styled.a`
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.primary};
  line-height: ${theme.lineHeights.md2};
  text-decoration: underline;
`;
