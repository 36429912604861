import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SubheaderWrapper } from '../../../styled';
import { FormikTextInput } from 'components/forms/formik-prime';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { addPersonioIntegration } from 'fetchers/actions/addPersonioIntegration';
import { Form, Formik } from 'formik';
import { Box, Flex } from 'rebass';
import { HorizontalLine } from 'screens/User/components/GeneralInfoFields';
import { displaySnackbar } from 'store/slices/snackbarSlice';

import { InputWrapper } from './styled';
import { validationSchema } from './validationSchema';

interface Props {
  onClose: () => void;
  canIntegrate: boolean;
  isIntegrated: boolean;
}

interface PersonioForm {
  client_id: string;
  client_secret: string;
  google_calendar_api_key: string;
}

export const PersonioSection = ({
  onClose,
  canIntegrate,
  isIntegrated,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (values: PersonioForm) => {
    addPersonioIntegration(values)
      .then(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationSuccess'),
          }),
        );
        onClose();
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationFail'),
            isError: true,
          }),
        );
      });
  };

  return (
    <Flex flexDirection="column" mt={4}>
      {canIntegrate ? (
        <SubheaderWrapper active={true}>
          <Box mr={2} display="inline">
            <i className="pi pi-info-circle" />
          </Box>
          <Box display="inline">
            {t(
              isIntegrated
                ? 'organizationSettings.personio.integrationOn'
                : 'organizationSettings.personio.integrationValid',
            )}
          </Box>
        </SubheaderWrapper>
      ) : (
        <Formik
          initialValues={{
            client_id: '',
            client_secret: '',
            google_calendar_api_key: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <InputWrapper>
              <FormikTextInput
                name="client_id"
                label={t('organizationSettings.personio.clientId')}
                ishigh="false"
                variant="default"
              />
              <FormikTextInput
                name="client_secret"
                label={t('organizationSettings.personio.clientSecret')}
                ishigh="false"
                variant="default"
              />
              <FormikTextInput
                name="google_calendar_api_key"
                label={t('organizationSettings.personio.googleCalendarApiKey')}
                ishigh="false"
                variant="default"
              />
            </InputWrapper>
            <HorizontalLine />
            <Flex justifyContent="flex-end">
              <Box mr={3}>
                <PrimeTextButton onClick={onClose} label={t('common.cancel')} />
              </Box>
              <NewStyledButton
                type="submit"
                variant="default"
                label={t('organizationSettings.personio.connect')}
              />
            </Flex>
          </Form>
        </Formik>
      )}
    </Flex>
  );
};
