import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { SideDialog } from 'components/prime/SideDialog/SideDialog';
import { BackButton } from 'components/ui/Back/BackButton';
import { useWeeks } from 'fetchers';
import { useOrganizationSettings } from 'fetchers/hooks/useOrganizationSettings';
import { useUserProjects } from 'fetchers/hooks/useUserProjects';
import { useUser } from 'fetchers/hooks/users/useUser';
import { InputSwitch } from 'primereact/inputswitch';
import { Flex, Text } from 'rebass';
import {
  selectAuthUserSignInCount,
  selectIsSelf,
} from 'screens/Auth/authSlice';
import { RootState } from 'store/rootReducer';
import { updateUser } from 'store/slices/usersSlice';
import { theme } from 'styles/theme';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

import AddUserToProject from './components/AddUserToProject';
import DeveloperDashboard from './components/DeveloperDashboard';
import { UserDetails } from './components/UserDetails';
import UserProjects from './components/UserProjects';

const UserView: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isResourceChecked, setIsResourceChecked] = useState(false);
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();
  const [phaseStatus, setPhaseStatus] = useState<'active' | 'inactive'>(
    'active',
  );
  const { projects, mutate, isLoading } = useUserProjects(userId, phaseStatus);
  const isSelf = useSelector((state: RootState) => selectIsSelf(state, userId));
  const { integrations } = useOrganizationSettings();
  const canEditAvailableResource = usePermissions(
    PERMISSIONS.EDIT_AVAILABLE_RESOURCE,
  );
  const canEditUser = usePermissions(PERMISSIONS.EDIT_USER);
  const canViewDeveloperDashboard = usePermissions(
    PERMISSIONS.VIEW_DEVELOPER_DASHBOARD,
  );
  const signInCount = useSelector(selectAuthUserSignInCount);
  const dispatch = useDispatch();
  const shouldRenderResourceSwitcher =
    (canEditAvailableResource && !isSelf) ||
    (canEditAvailableResource && isSelf && signInCount);

  const { user } = useUser(userId);
  const shouldRenderDeveloperDashboard =
    (canViewDeveloperDashboard || isSelf) &&
    user?.show_jira_tickets &&
    integrations?.atlassian;

  const shouldRenderEditUserButton = canEditUser || isSelf;
  useWeeks();

  useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  useEffect(() => {
    if (user?.available_resource !== isResourceChecked) {
      setIsResourceChecked(!!user?.available_resource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.available_resource]);

  const toggleAvailableResource = () => {
    if (user?.id) {
      setIsResourceChecked(!isResourceChecked);
      dispatch(
        updateUser(user?.id, { available_resource: !user?.available_resource }),
      );
    } else {
      throw new Error(t('errors.userIDNotFound'));
    }
  };

  const onPhaseStatusChange = async ({ value }: any) => {
    const phase = value ? 'inactive' : 'active';
    setPhaseStatus(phase);
  };

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb={6}
        paddingX={theme.sizes.slg}
      >
        <BackButton />

        <Flex alignItems="center">
          {shouldRenderResourceSwitcher && (
            <Flex alignItems="center" mr={4}>
              <Text fontSize="sm" color="veryDarkGray" mr={1}>
                {t('common.available')}
              </Text>
              <InputSwitch
                checked={isResourceChecked}
                onChange={toggleAvailableResource}
              />
            </Flex>
          )}

          {shouldRenderEditUserButton && (
            <Link
              to={{
                pathname: `/users/${userId}/edit`,
                state: {
                  from: `${user?.full_name} profile, ${user?.id}`,
                },
              }}
            >
              <Text color="primary">{t('userProfile.editProfile')}</Text>
            </Link>
          )}
        </Flex>
      </Flex>
      <Flex flexDirection="column" mb={10} paddingX="60px">
        <Flex alignItems="flex-start">
          <UserDetails user={user} isAuthUser={isSelf} />
          <Flex width="calc(100% - 300px)" ml={6} flexDirection="column">
            {user && (
              <UserProjects
                onAddToProject={() => setModalOpen(true)}
                onPhaseStatusChange={onPhaseStatusChange}
                phaseStatus={phaseStatus}
                user={user}
                projects={projects || []}
                isLoading={isLoading}
              />
            )}
            {shouldRenderDeveloperDashboard && (
              <DeveloperDashboard userId={String(user?.id)} />
            )}
          </Flex>
        </Flex>

        <SideDialog
          visible={isModalOpen}
          position="top-right"
          header={t('userProfile.addNewMemberToProject')}
          modal={false}
          width="872px"
          onHide={() => setModalOpen(false)}
        >
          <AddUserToProject
            onClose={async () => setModalOpen(false)}
            user={user}
            onAddSuccess={mutate}
          />
        </SideDialog>
      </Flex>
    </>
  );
};

export default UserView;
