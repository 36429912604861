import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const Wrapper = styled.div`
  padding-bottom: ${theme.space[12]};
`;

export const PageTitle = styled.h2`
  margin: 0 0 ${theme.space[8]};
  font-size: ${theme.fontSizes.xl};
  font-family: ${theme.fonts.medium};
`;

export const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;

  & div:last-child {
    margin-right: 0;
  }
`;

export const ColumnWrapper = styled.div`
  margin: 0 ${theme.space[2]} ${theme.space[3]} 0;
`;

export const HeaderCell = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  letter-spacing: 0.2px;
  color: ${theme.colors.midGray};
  font-family: ${theme.fonts.bold};
  font-size: ${theme.fontSizes.xs};
  line-height: 1;
  text-align: left;
  text-transform: uppercase;
  user-select: none;
  line-height: 1;
  align-items: center;
  padding: 12px ${theme.space[5]};
  position: sticky;
  top: 0;
  background-color: ${theme.colors.whiteLilac};
`;

export const Cell = styled.div`
  line-height: 1.3;
  padding: ${theme.space[4]} ${theme.space[5]};
  border-radius: ${theme.radii.lg};
  background-color: ${theme.colors.white};
  height: calc(100% - ${theme.dimensions.headerCellHeight});
  box-sizing: border-box;
  & h2:first-of-type {
    margin-top: 0;
  }
`;

export const SectionTitle = styled.h2`
  margin: ${theme.space[3]} 0;
`;

export const Title = styled.h4`
  margin: ${theme.space[1]} 0;
`;

export const Accent = styled.span`
  color: ${theme.colors.lightGray};
`;
