import styled from '@emotion/styled/macro';
import { Checkbox } from 'primereact/checkbox';
import { theme } from 'styles/theme';

export const Wrapper = styled.div<{ last?: boolean }>`
  margin-bottom: ${({ last }) => (last ? '0' : theme.space[5])};
`;

export const Error = styled.div`
  color: ${theme.colors.danger};
  font-size: ${theme.fontSizes.xxs};
  margin-top: ${theme.space[1]};
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: ${theme.space[3]};
`;

export const CheckboxPrime = styled(Checkbox)`
  margin-right: ${theme.space[1]};

  &.p-checkbox .p-checkbox-box {
    border: 1px solid ${theme.colors.primeDarkGrey};
  }

  &.p-checkbox .p-checkbox-box.p-highlight {
    border-color: ${theme.colors.newBlueInputHover};
  }
`;

export const CheckboxLabel = styled.label`
  color: ${theme.colors.primeDarkGrey};
  font-size: ${theme.fontSizes.sm};
`;

export const Textarea = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: ${theme.dimensions.uploaderWidth};
  background-color: ${theme.colors.lightestGrey};
  border-color: ${theme.colors.lightestGrey};
  padding: ${theme.space[2]};
  color: ${theme.colors.primeDarkGrey};
  margin-top: ${theme.space[6]};

  &:focus {
    outline-color: ${theme.colors.primaryLight};
  }
`;

export const TextareaLabel = styled.label`
  color: ${theme.colors.primeDarkGrey};
  font-size: ${theme.fontSizes.md};
`;
