import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const Label = styled.h2`
  font-weight: bold;
  font-size: ${theme.fontSizes.sm};
  line-height: 24px;
  color: ${theme.colors.veryDarkGray};
  text-align: center;
  margin-bottom: ${theme.space[2]};
  margin-top: ${theme.space[1]};
`;

export const StyledLink = styled.a`
  margin-left: ${theme.space[2]};
  color: ${theme.colors.veryDarkGray};
  font-size: ${theme.fontSizes.sm};
`;
