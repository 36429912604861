import React from 'react';
import { useDispatch } from 'react-redux';

import { clearFiltersData } from '../../projectsFiltersSlice';
import { useAreProjectsFiltered } from 'utils/hooks/useAreProjectsFiltered';

import { ClearFilters } from './styled';

export const ClearFiltersButton = () => {
  const dispatch = useDispatch();
  const { isFiltered } = useAreProjectsFiltered();

  const clearFilters = () => {
    dispatch(clearFiltersData());
  };

  return (
    <>
      {isFiltered && (
        <ClearFilters onClick={clearFilters}>Clear all filters</ClearFilters>
      )}
    </>
  );
};
