import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { usersAPI, UsersWeeksPayload } from 'api/users/endpoints';
import {
  User,
  UserPayload,
  UsersWeek,
  TFAData,
  TFAPayload,
} from 'api/users/models';
import { getISOWeek } from 'date-fns';
import i18n from 'i18next';
import { getUserDetails } from 'screens/Auth/authSlice';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';
import { currentDate } from 'utils/constants';
import { history } from 'utils/history';

import { displaySnackbar } from './snackbarSlice';

type InitialState = {
  users: User[];
  usersWeeks: UsersWeek[];
  availableResources: User[];
  isLoading: boolean;
  error: string | null;
  user: User | undefined;
  tfaData: TFAData | undefined;
  isTfaEnabled: boolean;
  isTfaError: boolean;
};

const INITIAL_STATE: InitialState = {
  availableResources: [],
  error: null,
  isLoading: false,
  users: [],
  usersWeeks: [],
  user: undefined,
  tfaData: undefined,
  isTfaEnabled: false,
  isTfaError: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState: INITIAL_STATE,
  reducers: {
    getUsersStart(state): void {
      state.isLoading = true;
    },
    getUsersSuccess(state, action: PayloadAction<User[]>): void {
      state.error = null;
      state.users = action.payload;
      state.isLoading = false;
    },
    getUsersFail(state, action: PayloadAction<string>): void {
      state.error = action.payload;
      state.users = [];
      state.isLoading = false;
    },

    addUserStart(state): void {
      state.isLoading = true;
      state.error = null;
    },
    addUserSuccess(state, action: PayloadAction<User>): void {
      state.error = null;
      state.users = [...state.users, action.payload];
      state.isLoading = false;
    },
    addUserFailed(state, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateUserStart(state): void {
      state.isLoading = true;
    },
    updateUserSuccess(state, action: PayloadAction<User>): void {
      const updatedUserIndex = state.users.findIndex(
        (user) => user.id === action.payload.id,
      );
      state.users[updatedUserIndex] = action.payload;

      state.isLoading = false;
      state.error = null;
      state.user = action.payload;
    },
    updateUserFailed(state, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
    },

    getUsersWeeksStart(state, action: PayloadAction<boolean>): void {
      state.isLoading = action.payload;
    },
    getUsersWeeksSuccess(state, action: PayloadAction<UsersWeek[]>): void {
      state.error = null;
      state.usersWeeks = action.payload;
      state.isLoading = false;
    },
    getUsersWeeksWithPartialUpdateSuccess(
      state,
      action: PayloadAction<UsersWeek[]>,
    ): void {
      state.error = null;
      state.usersWeeks = state.usersWeeks.map((usersWeek) => {
        const newUsersWeek = action.payload.find(
          (newUsersWeek) => newUsersWeek.week === usersWeek.week,
        );

        if (newUsersWeek) {
          return newUsersWeek;
        }

        return usersWeek;
      });
      state.isLoading = false;
    },
    getUsersWeeksFail(state, action: PayloadAction<string>): void {
      state.usersWeeks = [];
      state.error = action.payload;
      state.isLoading = false;
    },

    getReportStart(state, action: PayloadAction<boolean>): void {
      state.isLoading = action.payload;
    },
    getReportSuccess(state): void {
      state.error = null;
      state.isLoading = false;
    },
    getReportFail(state, action: PayloadAction<string>): void {
      state.error = action.payload;
      state.isLoading = false;
    },

    getResourcesReportsStart(state, action) {
      state.error = null;
      state.isLoading = true;
    },
    getResourcesReportsSuccess(state, action) {
      state.error = null;
      state.isLoading = false;
    },
    getResourcesReportsFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },

    removeUserStart(state) {
      state.error = null;
    },
    removeUserFail(state, action: PayloadAction<string>): void {
      state.error = action.payload;
    },
    removeUserSuccess(state, action: PayloadAction<number>): void {
      state.error = null;
      state.users = state.users.filter(({ id }) => id !== action.payload);
    },
    getUserByIdStart(state): void {
      state.isLoading = true;
    },
    getUserByIdSuccess(state, action: PayloadAction<User>): void {
      state.error = null;
      state.user = action.payload;
      state.isLoading = false;
    },
    getUserByIdFail(state, action: PayloadAction<string>): void {
      state.error = action.payload;
      state.user = undefined;
      state.isLoading = false;
    },
    getQrCodeForTFAStart(state): void {
      state.isLoading = true;
    },
    getQrCodeForTFASuccess(state, action: PayloadAction<TFAData>): void {
      state.error = null;
      state.tfaData = action.payload;
      state.isLoading = false;
    },
    getQrCodeForTFAFail(state, action: PayloadAction<string>): void {
      state.error = action.payload;
      state.tfaData = undefined;
      state.isLoading = false;
    },
    activateTFAStart(state): void {
      state.isLoading = true;
    },
    activateTFASuccess(state): void {
      state.error = null;
      state.isTfaError = false;
      state.isTfaEnabled = true;
      state.isLoading = false;
    },
    activateTFAFail(state, action: PayloadAction<string>): void {
      state.error = action.payload;
      state.isTfaError = true;
      state.isTfaEnabled = false;
      state.isLoading = false;
    },
    deactivateTFAStart(state): void {
      state.isLoading = true;
    },
    deactivateTFASuccess(state): void {
      state.error = null;
      state.isTfaEnabled = false;
      state.isLoading = false;
    },
    deactivateTFAFail(state, action: PayloadAction<string>): void {
      state.error = action.payload;
      state.isTfaEnabled = true;
      state.isLoading = false;
    },
  },
});

const {
  getUsersStart,
  getUsersSuccess,
  getUsersFail,
  addUserStart,
  addUserSuccess,
  addUserFailed,
  updateUserStart,
  updateUserSuccess,
  updateUserFailed,
  getUsersWeeksStart,
  getUsersWeeksSuccess,
  getUsersWeeksWithPartialUpdateSuccess,
  getUsersWeeksFail,
  getReportStart,
  getReportSuccess,
  getReportFail,
  removeUserFail,
  removeUserStart,
  removeUserSuccess,
  getUserByIdStart,
  getUserByIdSuccess,
  getUserByIdFail,
  getQrCodeForTFAStart,
  getQrCodeForTFASuccess,
  getQrCodeForTFAFail,
  activateTFAStart,
  activateTFASuccess,
  activateTFAFail,
  deactivateTFAStart,
  deactivateTFASuccess,
  deactivateTFAFail,
} = usersSlice.actions;

export default usersSlice.reducer;

export const addUser = (payload: UserPayload): AppThunk => async (dispatch) => {
  dispatch(addUserStart());

  try {
    const {
      data: { user },
    } = await usersAPI.addUser(payload);

    dispatch(addUserSuccess(user));
    dispatch(displaySnackbar({ message: 'Profile was created' }));
    history.push(`/users/${user.id}`);
  } catch (error) {
    dispatch(addUserFailed(error?.message || 'User not added'));
    const userTypesError = error.data?.errors?.user_types;
    const emailError = error.data?.errors?.email;
    const skillsError =
      error.data?.errors && error.data?.errors['skills.level'];
    const cannotSaveUser = error.data?.errors?.user;

    const fullNameError = error.data?.errors?.full_name?.[0];

    const errorString = `${userTypesError ? userTypesError + ' ' : ''}${
      emailError ? 'Email ' + emailError + ' ' : ''
    }${skillsError ? i18n.t('errors.skillLevelRequired') : ''}${
      cannotSaveUser ? i18n.t('errors.cannotSaveUser') : ''
    } ${fullNameError ? i18n.t('errors.invalidFullName') : ''}`;

    dispatch(
      displaySnackbar({
        message: errorString,
        isError: true,
      }),
    );
  }
};

export const getUsers = (): AppThunk => async (dispatch) => {
  dispatch(getUsersStart());

  try {
    const {
      data: { users },
    } = await usersAPI.getUsers();

    dispatch(getUsersSuccess(users));
  } catch (error) {
    dispatch(getUsersFail(error?.message || 'Error fetching users'));
  }
};

export const getUsersWeeks = ({
  page = 1,
  perPage = 1000,
  weeksNumbers = [getISOWeek(currentDate)],
  withLoading = true,
}: UsersWeeksPayload): AppThunk => async (dispatch) => {
  dispatch(getUsersWeeksStart(withLoading));

  try {
    const {
      data: { weeks },
    } = await usersAPI.getUsersWeeks({ weeksNumbers, page, perPage });

    dispatch(getUsersWeeksSuccess(weeks));
  } catch (error) {
    dispatch(getUsersWeeksFail(error?.message || 'Weeks not fetched'));
  }
};

export const getNewUsersWeeks = ({
  weeks,
  error,
}: {
  error: string;
  weeks: UsersWeek[];
}): AppThunk => (dispatch) => {
  if (weeks) {
    dispatch(getUsersWeeksSuccess(weeks));
  }

  if (error) {
    dispatch(getUsersWeeksFail(error));
  }
};

export const getUsersWeeksWithPartialUpdate = ({
  page = 1,
  perPage = 1000,
  weeksNumbers = [getISOWeek(currentDate)],
  withLoading = true,
}: UsersWeeksPayload): AppThunk => async (dispatch) => {
  dispatch(getUsersWeeksStart(withLoading));

  try {
    const {
      data: { weeks },
    } = await usersAPI.getUsersWeeks({ weeksNumbers, page, perPage });

    dispatch(getUsersWeeksWithPartialUpdateSuccess(weeks));
  } catch (error) {
    dispatch(getUsersWeeksFail(error?.message || 'Weeks not fetched'));
  }
};

export const updateUser = (
  id: number,
  payload: UserPayload,
): AppThunk => async (dispatch, getState) => {
  dispatch(updateUserStart());

  try {
    const {
      data: { user },
    } = await usersAPI.updateUser(id, payload);
    const { user: authUser } = getState().auth;

    if (authUser?.id === id) {
      dispatch(getUserDetails());
    }

    dispatch(updateUserSuccess(user));
    dispatch(displaySnackbar({ message: 'Profile was updated' }));
    history.push(`/users/${user.id}`);
  } catch (error) {
    dispatch(updateUserFailed(error?.message || 'User not updated'));
    const userTypesError = error.data?.errors?.user_types;
    const emailError = error.data?.errors?.email;
    const skillsError =
      error.data?.errors && error.data?.errors['skills.level'];
    const errorString = `${userTypesError ? userTypesError + ' ' : ''}${
      emailError ? 'Email ' + emailError + ' ' : ''
    }${skillsError ? i18n.t('errors.skillLevelRequired') : ''}`;
    dispatch(
      displaySnackbar({
        message: errorString,
        isError: true,
      }),
    );
  }
};

export const getReport = ({
  weeksNumbers = [getISOWeek(currentDate)],
  withLoading = true,
}: UsersWeeksPayload): AppThunk => async (dispatch) => {
  dispatch(getReportStart(withLoading));

  try {
    await usersAPI.getReport({ weeksNumbers });

    dispatch(getReportSuccess());
  } catch (error) {
    dispatch(getReportFail(error?.message || 'Report not fetched'));
  }
};

export const removeUser = ({
  id,
  onSuccess,
}: {
  id: number;
  onSuccess: () => void;
}): AppThunk => async (dispatch) => {
  dispatch(removeUserStart());

  try {
    await usersAPI.removeUser(id);
    dispatch(displaySnackbar({ message: 'User has been removed' }));
    dispatch(removeUserSuccess(id));
    onSuccess();
  } catch (error) {
    dispatch(removeUserFail(error?.message || 'Error during removing user'));
  }
};

export const getUserById = (id: number): AppThunk => async (dispatch) => {
  dispatch(getUserByIdStart());

  try {
    const {
      data: { user },
    } = await usersAPI.getUserById(id);

    dispatch(getUserByIdSuccess(user));
  } catch (error) {
    dispatch(getUserByIdFail(error?.message || 'Error fetching user'));
  }
};

export const selectUsersData = (state: RootState) => state.users.data;

export const getQrCodeForTFA = (): AppThunk => async (dispatch) => {
  dispatch(getQrCodeForTFAStart());
  try {
    const {
      data: { message },
    } = await usersAPI.getQrCodeForTFA();

    dispatch(getQrCodeForTFASuccess(message));
  } catch (error) {
    dispatch(getQrCodeForTFAFail(error?.message || i18n.t('tfaFetchingError')));
  }
};

export const selectQrCodeForTFA = createSelector(
  selectUsersData,
  ({ tfaData }) => tfaData,
);

export const activateTFA = (pin: TFAPayload): AppThunk => async (dispatch) => {
  dispatch(activateTFAStart());
  try {
    await usersAPI.activateTFA(pin);
    dispatch(activateTFASuccess());
  } catch (error) {
    dispatch(activateTFAFail(error?.message || i18n.t('tfaActivatingError')));
  }
};

export const deactivateTFA = (): AppThunk => async (dispatch) => {
  dispatch(deactivateTFAStart());
  try {
    await usersAPI.deactivateTFA();
    dispatch(deactivateTFASuccess());
    dispatch(displaySnackbar({ message: i18n.t('tfaDeactivatingSuccess') }));
  } catch (error) {
    dispatch(
      deactivateTFAFail(error?.message || i18n.t('tfaDeactivatingError')),
    );
  }
};

export const selectIsTFAEnabled = createSelector(
  selectUsersData,
  ({ isTfaEnabled }) => isTfaEnabled,
);

export const selectIsTFAError = createSelector(
  selectUsersData,
  ({ isTfaError }) => isTfaError,
);
