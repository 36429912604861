import React, { Fragment, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  useExpanded,
  useFlexLayout,
  useTable,
  useSortBy,
  Column,
  Row,
} from 'react-table';

import { Variant } from '../SimpleUsersTable';
import {
  TableWrapper,
  TableRow,
  TableRowItem,
  SimpleTableRowItem,
  SimpleTableRow,
  ChevronBox,
  TableHeaderColumn,
  TableHeaderColumnSortbox,
} from '../styled';
import { Project, ProjectPhase } from 'api/projects/models';
import { User } from 'api/users/models';
import { ChevronIcon } from 'components/primitive';
import { SortDownIcon, SortUpIcon } from 'components/ui';
import { Box, Text } from 'rebass';
import { selectProjectsWeeks } from 'store/slices/projectsSlice';
import { selectSelectedWeeksData } from 'store/slices/selectedWeeksSlice';
import { selectUsersData } from 'store/slices/usersSlice';
import { theme } from 'styles/theme';
import { usersCompareForSort } from 'utils/functions';

import { HiddenMembers } from './components/HiddenMembers';
import { MemberData } from './components/MemberData';

export type ProjectsTableProps = {
  project: Project;
  phase: ProjectPhase;
};

type Props = {
  data: ProjectsTableProps[];
  columns: Column<ProjectsTableProps>[];
  isPhasesExpanded?: boolean;
  isExpandable?: boolean;
  scroll?: 'x' | 'y' | 'both';
  user?: User;
  variant?: Variant;
  isNewHeader?: boolean;
};

const sortAlphabetically = (
  { values: valuesA }: any,
  { values: valuesB }: any,
  columnName: string,
) => {
  const nameA = valuesA[columnName] || 'No responsible person';
  const nameB = valuesB[columnName] || 'No responsible person';

  if (nameA === 'No responsible person') {
    return 1;
  }

  if (nameB === 'No responsible person') {
    return -1;
  }

  return nameA.localeCompare(nameB, 'pl', {
    sensitivity: 'base',
  });
};

export const ProjectsPhasesTable: React.FC<Props> = ({
  data,
  columns,
  isPhasesExpanded,
  isExpandable = true,
  scroll = 'both',
  user,
  variant = Variant.GRAY_HEADER,
  isNewHeader = false,
}) => {
  const { selectedWeeks } = useSelector(selectSelectedWeeksData);
  const { users } = useSelector(selectUsersData);
  const initialSortBy = useMemo(
    () => [{ id: 'project_name', desc: false }],
    [],
  );
  const projectsWeeks = useSelector(selectProjectsWeeks);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    //@ts-ignore
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns,
      data,
      //@ts-ignore
      autoResetSortBy: false,
      autoResetExpanded: false,
      initialState: {
        //@ts-ignore
        sortBy: initialSortBy,
      },
      //@ts-ignore
      disableSortRemove: true,
      sortTypes: {
        alphanumeric: sortAlphabetically,
      },
    },
    useFlexLayout,
    useSortBy,
    isExpandable && useExpanded,
  );

  const isMultiWeeks = selectedWeeks.length > 1;

  useEffect(() => {
    isExpandable && toggleAllRowsExpanded(isPhasesExpanded);
  }, [isExpandable, isPhasesExpanded, toggleAllRowsExpanded]);

  const getCurrentWeekData = useMemo(
    () => (row: Row<ProjectsTableProps>) => {
      return projectsWeeks
        .find((w) => {
          return selectedWeeks[0].weekNumber === Number(w.week);
        })
        ?.projects?.find((p) => p.id === row.original.project.id)
        ?.phases?.find((ph) => ph.id === row.original.phase.id)
        ?.users?.find((m) => m.id === user?.id);
    },
    [projectsWeeks, selectedWeeks, user?.id],
  );

  const sortedPhasesByMostHours = useMemo(() => {
    return [...rows].sort((rowA, rowB) => {
      prepareRow(rowA);
      prepareRow(rowB);

      const currentWeekDataA = getCurrentWeekData(rowA);
      const currentWeekDataB = getCurrentWeekData(rowB);

      const phaseUserHourValueA = currentWeekDataA?.phase_user_hour?.value || 0;
      const phaseUserHourValueB = currentWeekDataB?.phase_user_hour?.value || 0;

      if (phaseUserHourValueA > phaseUserHourValueB) {
        return -1;
      } else if (phaseUserHourValueA < phaseUserHourValueB) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [getCurrentWeekData, prepareRow, rows]);

  return (
    <TableWrapper overflowX={scroll === 'x' ? 'scroll' : 'auto'}>
      <Box as="table" width="100%" {...getTableProps()}>
        <Box as="thead">
          {headerGroups.map((headerGroup) => (
            <Box
              as="tr"
              backgroundColor="whiteLilac"
              sx={{ position: 'sticky', top: 0, zIndex: 2 }}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column: any) => (
                <TableHeaderColumn
                  as="td"
                  canSort={column.canSort}
                  variant={variant}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  // @ts-ignore
                  flex={column.flex}
                >
                  {isNewHeader ? (
                    <Text
                      color="veryDarkGray"
                      fontSize="sm"
                      textAlign="center"
                      fontFamily="regular"
                      width={column.isFullWidth ? '100%' : 'initial'}
                    >
                      {column.render('Header')}
                    </Text>
                  ) : (
                    column.render('Header')
                  )}
                  <TableHeaderColumnSortbox isSorted={column.isSorted}>
                    {column.canSort && column.isSortedDesc === true && (
                      <SortDownIcon stroke={theme.colors.primary} />
                    )}
                    {column.canSort && column.isSortedDesc === false && (
                      <SortUpIcon stroke={theme.colors.primary} />
                    )}
                    {column.canSort && column.isSortedDesc === undefined && (
                      <SortUpIcon />
                    )}
                  </TableHeaderColumnSortbox>
                </TableHeaderColumn>
              ))}
            </Box>
          ))}
        </Box>
        <tbody {...getTableBodyProps()}>
          {sortedPhasesByMostHours.map((row, index) => {
            prepareRow(row);
            const hiddenUsers = row.original.phase.hiddenUsers
              ?.map((user) => {
                const foundUser = users.find((u) => u.id === user.id)!;

                const phaseUserRange = user.phase_user_range;

                return {
                  ...foundUser,
                  phase_user_range: phaseUserRange,
                };
              })
              .sort(usersCompareForSort);

            return (
              <Fragment key={`row_${index}_1`}>
                <TableRow
                  as="tr"
                  sx={{ cursor: isExpandable ? 'pointer' : 'default' }}
                  isMultiWeeks={isMultiWeeks}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <TableRowItem
                        as="td"
                        data-cy={`expand-members-btn-${cellIndex}`}
                        onClick={() =>
                          //@ts-ignore
                          isExpandable && row.toggleRowExpanded(!row.isExpanded)
                        }
                        isMultiWeeks={isMultiWeeks}
                        {...cell.getCellProps()}
                        // @ts-ignore
                        flex={cell.column.flex}
                      >
                        {cell.render('Cell')}
                        {((isMultiWeeks && cellIndex === 0) ||
                          (!isMultiWeeks &&
                            cellIndex === row.cells.length - 1)) &&
                          isExpandable && (
                            <ChevronBox>
                              <ChevronIcon
                                //@ts-ignore
                                open={row.isExpanded}
                              />
                            </ChevronBox>
                          )}
                      </TableRowItem>
                    );
                  })}
                </TableRow>
                {
                  //@ts-ignore
                  isExpandable && row.isExpanded && (
                    <>
                      <SimpleTableRow
                        as="tr"
                        isMultiWeeks={isMultiWeeks}
                        mt={-3}
                        {...row.getRowProps()}
                        key={`row_${index}_2`}
                      >
                        {row.cells.map((cell, index) => {
                          return (
                            <SimpleTableRowItem
                              as="td"
                              fontSize="xxs"
                              color="midGray"
                              sx={{
                                textTransform: 'uppercase',
                                fontFamily: 'bold',
                              }}
                              isMultiWeeks={isMultiWeeks}
                              {...cell.getCellProps()}
                              key={`cell_${index}_2`}
                            >
                              {cell.render('MemberHeader')}
                            </SimpleTableRowItem>
                          );
                        })}
                      </SimpleTableRow>
                      {row.original.phase.visibleUsers
                        ?.map((user) => {
                          // MARK: If we will need members as separated table we should make it from code above, and then use it as child of <tr><td> with 100% width here.
                          const foundUser = users.find(
                            (u) => u.id === user.id,
                          )!;

                          const phaseUserRange = user.phase_user_range;

                          return {
                            ...foundUser,
                            phase_user_range: phaseUserRange,
                          };
                        })
                        .sort(usersCompareForSort)
                        .map((user) => (
                          <MemberData
                            key={user.id}
                            isMultiWeeks={isMultiWeeks}
                            phaseUserRange={user.phase_user_range}
                            row={row}
                            user={user}
                          />
                        ))}
                      <SimpleTableRow
                        as="tr"
                        isMultiWeeks={isMultiWeeks}
                        mb={3}
                        {...row.getRowProps()}
                        key={`row_${index}_4`}
                      >
                        {row.cells.map((cell, index) => {
                          return (
                            <SimpleTableRowItem
                              as="td"
                              isMultiWeeks={isMultiWeeks}
                              {...cell.getCellProps()}
                              key={`cell_${index}_4`}
                            >
                              {cell.render('MemberFooter')}
                            </SimpleTableRowItem>
                          );
                        })}
                      </SimpleTableRow>
                      <HiddenMembers
                        {...row.getRowProps()}
                        isMultiWeeks={isMultiWeeks}
                        row={row}
                        users={hiddenUsers}
                      />
                    </>
                  )
                }
              </Fragment>
            );
          })}
        </tbody>
      </Box>
    </TableWrapper>
  );
};
