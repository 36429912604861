import { Team } from 'api/issues/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';
import { sortByKey } from 'utils/sortByKey';

type TeamPayload = {
  teams: Team[];
};

const API_URL = '/teams';

export const useTeams = () => {
  const { data, mutate } = useSWR<TeamPayload>(API_URL, fetcher);

  return {
    isLoading: !data,
    teams: data?.teams.sort(sortByKey('name')) || [],
    mutate,
  };
};
