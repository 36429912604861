import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Switch } from 'components/forms';
import { FormikTextInput } from 'components/forms/formik-prime';
import { Flex, Text } from 'rebass';

type SetPasswordFormFieldsProps = {
  passwordFieldName?: string;
  confirmPasswordFieldName?: string;
};

export const SetPasswordFormFields: React.FC<SetPasswordFormFieldsProps> = ({
  children,
  passwordFieldName,
  confirmPasswordFieldName,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <FormikTextInput
        name={passwordFieldName || 'password'}
        label={t('login.password')}
        type={showPassword ? 'text' : 'password'}
      />

      <FormikTextInput
        name={confirmPasswordFieldName || 'passwordConfirmation'}
        label={t('login.passwordConfirmation')}
        type={showPassword ? 'text' : 'password'}
      />

      <Flex justifyContent="space-between" width="100%">
        <Flex alignItems="center">
          <Switch
            isChecked={showPassword}
            onChange={(event) => setShowPassword(event.target.checked)}
            last
          />
          <Text ml={2}>{t('login.showPassword')}</Text>
        </Flex>
        {children}
      </Flex>
    </>
  );
};
