import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const RadioOption = styled.div`
  margin-bottom: ${theme.space[2]};
`;

export const Label = styled.label`
  font-size: ${theme.fontSizes.xs};
  margin-left: ${theme.space[2]};
`;
