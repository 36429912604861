import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatefulPinInput } from 'react-input-pin-code';
import { useDispatch, useSelector } from 'react-redux';

import { signInWithTFA, selectUserEmail } from 'screens/Auth/authSlice';
import { theme } from 'styles/theme';

import {
  CardContainer,
  StyledCard,
  Heading,
  InputContainer,
  StyledButton,
  Footer,
} from './styled';

const PIN_FORM_INITIAL_VALUES = ['', '', '', '', '', ''];

const LoginWith2FA = () => {
  const [values, setValues] = useState(PIN_FORM_INITIAL_VALUES);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const email = useSelector(selectUserEmail);

  const handleSubmit = (vals: string[]) => {
    const formattedVals = vals.join('');
    const data = { email, mfa_code: formattedVals };

    dispatch(signInWithTFA(data));
  };

  const isDisabled = values.includes('');

  return (
    <CardContainer>
      <StyledCard
        footer={
          <Footer>
            <StyledButton
              disabled={isDisabled}
              onClick={() => handleSubmit(values)}
            >
              {t('login.signIn')}
            </StyledButton>
          </Footer>
        }
      >
        <Heading>{t('tfaSteps.2')}</Heading>
        <InputContainer>
          <StatefulPinInput
            onChange={(value, index, values) => setValues(values)}
            placeholder=""
            length={6}
            inputStyle={{ background: theme.colors.newLightestGrey }}
            borderColor={theme.colors.newLightestGrey}
            validBorderColor={theme.colors.newLightestGrey}
            autoFocus
          />
        </InputContainer>
      </StyledCard>
    </CardContainer>
  );
};

export default LoginWith2FA;
