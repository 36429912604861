import { Link } from 'react-router-dom';

import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { theme } from 'styles/theme';

const { colors, fontSizes, zIndices, space } = theme;

type BodyBoxProps = {
  isScrollable: boolean;
};

export const LogoBoxLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 150ms;
  border-radius: ${theme.radii.md};
  margin-left: ${space[9]};

  &:focus {
    outline: none;
    box-shadow: ${theme.shadows.outline};
  }
`;

export const IconBox = styled(Link)<{ active?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${theme.dimensions.menuIconWidth()};
  height: ${theme.dimensions.menuIconHeight};
  border-radius: ${theme.radii.mlg};
  font-size: ${fontSizes.sm};
  color: ${({ active }) => (active ? colors.primary : colors.bermudaGray)};
  transition: all 150ms;
  margin: ${space[2]} 0 ${space[2]} ${space[8]};
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background-color: ${colors.lightBlue};
  }

  & svg,
  & path {
    stroke: ${({ active }) => (active ? colors.primary : colors.veryDarkGray)};
  }
`;

export const NavBar = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0;
  left: 0;
  z-index: ${zIndices['6']};
  background-color: ${theme.colors.white};
  height: 68px;
  box-shadow: 0 1px 3px 0 rgba(189, 199, 211, 0.4);
`;

export const MenuMiddle = styled.div`
  position: absolute;
  left: 16%;
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 60%;
  flex: 1;

  @media (min-width: ${theme.dimensions.desktopSize}) {
    width: auto;
  }
`;

export const ActionButton = styled.div`
  @media (min-width: ${theme.dimensions.desktopSize}) {
    margin-left: max(10%, 60px);
  }
`;

export const MenuEnd = styled.div`
  margin-right: ${space[9]};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TitleBox = styled.div`
  margin-left: ${space[2]};
  color: ${colors.darkGray};
  font-size: ${fontSizes.xs};
  font-family: ${theme.fonts.bold};
  line-height: ${theme.lineHeights.normal};
  letter-spacing: 1px;
  user-select: none;
`;

export const BodyBox = styled.div<BodyBoxProps>`
  display: flex;
  min-height: ${({ isScrollable }) =>
    isScrollable ? `calc(100vh - ${theme.dimensions.topBarHeight}px)` : 'auto'};
  max-height: ${({ isScrollable }) =>
    isScrollable ? 'auto' : `calc(100vh - ${theme.dimensions.topBarHeight}px)`};
  margin-top: ${theme.dimensions.topBarHeight}px;
  padding: ${({ isScrollable }) => (isScrollable ? theme.space[9] : 0)};
  flex-direction: column;
  overflow: ${({ isScrollable }) => (isScrollable ? 'auto' : 'hidden')};
`;

interface MenuItemProps {
  isactive: number;
}

export const MenuLink = styled(Link)<MenuItemProps>`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: ${space[8]};
  border-bottom: ${({ isactive }) =>
    isactive ? `2px solid ${colors.primaryLight}` : '2px solid transparent'};

  & svg path {
    fill: ${({ isactive }) =>
      isactive ? colors.primary : colors.veryDarkGray};
  }

  &:hover {
    border-bottom: ${({ isactive }) =>
      isactive
        ? `2px solid ${colors.primaryLight}`
        : `2px solid ${colors.veryDarkGray}`};
  }
`;

export const MenuItemTitle = styled(Box)<MenuItemProps>`
  color: ${({ isactive }) => (isactive ? colors.primary : colors.veryDarkGray)};
  font-size: ${fontSizes.xs};
`;
