import styled from '@emotion/styled/macro';
import { TextLinkWrapper } from 'components/ui/TextLinkWrapper';
import { theme } from 'styles/theme';

export const ClearFilters = styled(TextLinkWrapper)`
  color: ${theme.colors.bermudaGray};
  font-size: ${theme.fontSizes.sm};
  line-height: ${theme.lineHeights.md};
  margin: ${theme.space[2]} 0;
  padding: 0;
`;
