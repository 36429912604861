import { mutateSkills } from '../../mutations/mutateSkills';
import { organizationAPI } from 'api/phases/endpoints';
import { serialize } from 'object-to-formdata';

export const updateSkill = async (name: string, skillId: number) => {
  const formData = serialize({ skill_name: { name } });
  await organizationAPI.updateSkill(skillId, formData);

  await mutateSkills();
};
