import React, { useCallback } from 'react';

import { Project, ProjectPhase } from 'api/projects/models';
import { PAGES_PATH } from 'components/layout/PrivateRoute/components/MainLayout/hooks';
import { Avatar, ProjectNameLink } from 'components/ui';
import { Box, Flex } from 'rebass';
import { BoldBox } from 'screens/Users/components/listStyles';
import { usePermissions, PERMISSIONS } from 'utils/hooks/usePermissions';

type Props = {
  avatar?: Project['avatar'];
  id: Project['id'];
  phaseName?: ProjectPhase['name'];
  projectName: Project['name'];
  isLinkDisabled?: boolean;
};

export const ProjectName: React.FC<Props> = ({
  avatar = '',
  id,
  phaseName,
  projectName,
  isLinkDisabled,
}) => {
  const canViewProject = usePermissions(PERMISSIONS.VIEW_PROJECT);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (event) {
        event.stopPropagation();

        if (!canViewProject) {
          event.preventDefault();
        }
      }
    },
    [canViewProject],
  );

  return (
    <>
      <Avatar name={projectName} radius="xmd" size="small" src={avatar} />
      <Flex ml={5} flexDirection="column">
        {isLinkDisabled ? (
          <BoldBox fontSize="sm">{projectName}</BoldBox>
        ) : (
          <ProjectNameLink
            data-cy="project-view-btn"
            href={`projects/${id}`}
            onClick={handleClick}
            source={PAGES_PATH.PROJECTS}
          >
            {projectName}
          </ProjectNameLink>
        )}

        {phaseName && (
          <Box as="span" fontSize="xxs" color="primaryBlack" minWidth="md">
            ({phaseName})
          </Box>
        )}
      </Flex>
    </>
  );
};
