import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheck } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import styled from '@emotion/styled/macro';
import {
  Dialog,
  PrimeDatePicker,
  Checkbox,
  CheckboxChangeEvent,
} from 'components/prime';
import { Button, GithubIcon, JiraIcon, TogglIcon } from 'components/ui';
import { parseISO } from 'date-fns';
import { format } from 'date-fns/esm';
import { Box, Flex } from 'rebass';
import {
  NotificationsFilters,
  NotificationsFilters as NotificationsFiltersType,
  selectNotificationsFiltersData,
} from 'screens/Notifications/notificationsFiltersSlice';
import { getNotifications } from 'screens/Notifications/notificationsSlice';
import { getTeams } from 'screens/Users/slices/teamsSlice';
import { theme } from 'styles/theme';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onFilter: (filterName: NotificationsFiltersType, value: any) => void;
};

const TypesCheckboxText = styled.span`
  margin-left: ${theme.space[2]};
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.pureGray};
`;

const NotificationsFiltersModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onFilter,
}) => {
  const { startDate, endDate, periods, types, isResolved } = useSelector(
    selectNotificationsFiltersData,
  );
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  const handleStartDateChange = (event: any) => {
    if (event.target?.value) {
      onFilter(
        NotificationsFiltersType.START_DATE,
        format(event.target?.value, 'yyyy-MM-dd'),
      );
    }
  };

  const handleEndDateChange = (event: any) => {
    if (event.target?.value) {
      onFilter(
        NotificationsFiltersType.END_DATE,
        format(event.target?.value, 'yyyy-MM-dd'),
      );
    }
  };

  const handleApplyFilters = () => {
    dispatch(
      getNotifications({
        startDate,
        endDate,
        periods,
        alertTypes: types,
        isResolved,
      }),
    );
    onClose();
  };

  const onCheckboxChange = (
    e: CheckboxChangeEvent,
    group: 'periods' | 'types',
  ) => {
    const currentElements: string[] = group === 'periods' ? periods : types;
    const clickedElementName = e.target.name;

    const newElements = currentElements.includes(clickedElementName)
      ? currentElements.filter((item) => item !== clickedElementName)
      : [...currentElements, clickedElementName];

    if (group === 'periods') {
      onFilter(NotificationsFilters.PERIODS, newElements);
    } else {
      onFilter(NotificationsFilters.TYPES, newElements);
    }
  };

  return (
    <Dialog
      visible={isOpen}
      header="Notifications filters"
      onHide={onClose}
      top="30%"
      width="600px"
    >
      <Box my={5}>
        <Flex mb={6}>
          <Box width="50%">
            <PrimeDatePicker
              onChange={handleStartDateChange}
              value={startDate ? parseISO(startDate) : undefined}
              name="start_date"
              label={t('common.fromDate')}
              placeholder={t('common.pickStartDate')}
              variant="default"
              maxDate={endDate ? new Date(endDate) : undefined}
              appendTo={document.querySelector('#modal-root')}
            />
          </Box>
          <Box mx={1} />
          <Box width="50%">
            <PrimeDatePicker
              onChange={handleEndDateChange}
              value={endDate ? parseISO(endDate) : undefined}
              name="end_date"
              label={t('common.toDate')}
              placeholder={t('common.pickEndDate')}
              variant="default"
              minDate={startDate ? new Date(startDate) : undefined}
              appendTo={document.querySelector('#modal-root')}
            />
          </Box>
        </Flex>
        <Flex mt={8}>
          <Box width="50%">
            <Checkbox
              isChecked={types.includes('Toggl')}
              onChange={(e: CheckboxChangeEvent) =>
                onCheckboxChange(e, 'types')
              }
              name="Toggl"
            >
              <TogglIcon width="20" height="20" />
              <TypesCheckboxText>
                {t('notifications.togglNotifications')}
              </TypesCheckboxText>
            </Checkbox>

            <Checkbox
              isChecked={types.includes('Jira')}
              onChange={(e: CheckboxChangeEvent) =>
                onCheckboxChange(e, 'types')
              }
              name="Jira"
            >
              <JiraIcon width="20" height="20" />
              <TypesCheckboxText>
                {t('notifications.jiraNotifications')}
              </TypesCheckboxText>
            </Checkbox>

            <Checkbox
              isChecked={types.includes('Github')}
              onChange={(e: CheckboxChangeEvent) =>
                onCheckboxChange(e, 'types')
              }
              name="Github"
            >
              <GithubIcon width="20" height="20" />
              <TypesCheckboxText>
                {t('notifications.githubNotifications')}
              </TypesCheckboxText>
            </Checkbox>
          </Box>
          <Box mx={1} />
          <Box width="50%">
            <Checkbox
              isChecked={periods.includes('daily')}
              name="daily"
              onChange={(e: CheckboxChangeEvent) =>
                onCheckboxChange(e, 'periods')
              }
            >
              <TypesCheckboxText>
                {t('notifications.dailyNotifications')}
              </TypesCheckboxText>
            </Checkbox>

            <Checkbox
              isChecked={periods.includes('weekly')}
              name="weekly"
              onChange={(e: CheckboxChangeEvent) =>
                onCheckboxChange(e, 'periods')
              }
            >
              <TypesCheckboxText>
                {t('notifications.weeklyNotifications')}
              </TypesCheckboxText>
            </Checkbox>
          </Box>
        </Flex>
      </Box>

      <Flex marginTop="auto">
        <Button
          data-cy="apply-notifications-filters-btn"
          mr={2}
          onClick={handleApplyFilters}
          leftComponent={<FiCheck />}
        >
          {t('common.applyFilters')}
        </Button>
        <Button
          data-cy="close-notifications-modal-btn"
          variant="ghost"
          onClick={onClose}
        >
          {t('common.cancel')}
        </Button>
      </Flex>
    </Dialog>
  );
};

export default NotificationsFiltersModal;
