import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const SnackbarWrapper = styled.div<{ isError: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: fixed;
  left: 120px;
  bottom: 50px;
  min-height: 50px;
  z-index: ${theme.zIndices['max']};
  background: ${({ isError }) =>
    isError ? theme.colors.danger : theme.colors.primary};
  border-radius: ${theme.radii.md};
  box-shadow: ${theme.shadows.md};
  color: ${theme.colors.white};
`;
