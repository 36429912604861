import { ProjectPhase } from 'api/projects/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

export const useProjectPhase = (projectId: string, phaseId: string) => {
  const { data, revalidate } = useSWR<ProjectPhase>(
    `projects/${projectId}/phases/${phaseId}`,
    fetcher,
  );

  return {
    isLoading: !data,
    phase: data,
    revalidate,
  };
};
