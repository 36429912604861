import { PhaseTypePayload } from 'api/phases/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

type PhaseTypes = {
  phase_types: PhaseTypePayload[];
};

const API_URL = '/phase_types';

export const usePhaseTypes = () => {
  const { data } = useSWR<PhaseTypes>(API_URL, fetcher);

  return {
    isLoading: !data,
    phaseTypes: data?.phase_types || [],
  };
};
