import styled from '@emotion/styled/macro';
import { horizontalScroll, verticalScroll } from 'components/primitive';
import { Flex } from 'rebass';

export enum OVERFLOW {
  BOTH = 'both',
  X = 'x',
  Y = 'y',
}

type Props = {
  overflowDirection: OVERFLOW;
  shadowOffset?: string;
  marginTop?: string;
};

export const ScrollableContainer = styled(Flex)<Props>`
  align-items: flex-start;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop || '0'};

  ${horizontalScroll}
  ${verticalScroll}
  ${({ overflowDirection }) => {
    switch (overflowDirection) {
      case OVERFLOW.X:
        return `overflow-x: auto;`;
      case OVERFLOW.Y:
        return `overflow-y: auto;`;
      default:
        return `overflow: auto;`;
    }
  }}
  ${({ shadowOffset }) =>
    shadowOffset && `margin: -${shadowOffset}; padding: ${shadowOffset};`}
`;
