import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { history } from 'utils/history';

type Props = {
  history: typeof history;
};

const ScrollToTop: React.FC<Props> = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default withRouter(ScrollToTop);
