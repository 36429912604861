import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectPhaseResponsibleUserData } from 'api/projects/models';
import { PAGES_PATH } from 'components/layout/PrivateRoute/components/MainLayout/hooks';
import { Avatar, ProjectPhasePersonLink } from 'components/ui';
import { Box, Flex } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  personResponsible: ProjectPhaseResponsibleUserData[];
};

export const PersonResponsible: React.FC<Props> = ({ personResponsible }) => {
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent, propagationOff?: boolean) => {
    if (event && propagationOff) {
      event.stopPropagation();
    }
  };

  if (personResponsible.length < 2) {
    const name =
      personResponsible[0]?.user.name || personResponsible[0]?.user.full_name;

    const photo = personResponsible[0]?.user?.photo?.mini?.url;

    if (!name) {
      return (
        <Box color={theme.colors.neutralGray} fontSize={theme.fontSizes.xs}>
          {t('projects.noPersonResponsible')}
        </Box>
      );
    }

    return (
      <>
        <ProjectPhasePersonLink
          data-cy="user-view-btn"
          href={`/users/${personResponsible[0]?.user.id}`}
          isDisabled={!personResponsible[0]?.user.id || !name}
          onClick={handleClick}
          source={PAGES_PATH.PROJECTS}
        >
          <Avatar
            name={name || t('projects.noPersonResponsible')}
            size="small"
            src={photo || ''}
          />
          <Box as="span" ml={2} fontSize="xs">
            {name || t('projects.noPersonResponsible')}
          </Box>
        </ProjectPhasePersonLink>
      </>
    );
  }

  return (
    <Flex sx={{ alignItems: 'center', size: '100%' }}>
      {personResponsible
        .slice()
        .sort((a, b) => a.id - b.id)
        .map((data) => (
          <Box
            key={`${data.id}-${data.user.id}`}
            marginRight={1}
            sx={{
              marginTop: '-4px',
              ':hover .name': {
                opacity: 1,
              },
            }}
          >
            <ProjectPhasePersonLink
              data-cy="user-view-btn"
              href={`/users/${data.user?.id}`}
              isDisabled={!data.user?.id}
              onClick={(event) => handleClick(event, !data.user?.id)}
              source={PAGES_PATH.PROJECTS}
            >
              <Flex sx={{ position: 'relative' }}>
                <Avatar
                  name={data.user.name || data.user.full_name}
                  size="small"
                  src={data.user?.photo?.mini?.url || ''}
                />
                <Box
                  as="span"
                  className="name"
                  sx={{
                    backgroundColor: '#fff',
                    marginLeft: 0,
                    opacity: 0,
                    padding: '2px',
                    pointerEvents: 'none',
                    position: 'absolute',
                    top: '25px',
                    left: '-2px',
                    whiteSpace: 'nowrap',
                    fontSize: theme.fontSizes.xs,
                    zIndex: 2,
                  }}
                >
                  {data.user.name || data.user.full_name}
                </Box>
              </Flex>
            </ProjectPhasePersonLink>
          </Box>
        ))}
    </Flex>
  );
};
