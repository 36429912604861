import React, { useMemo } from 'react';
import { Cell, useFlexLayout, useTable, useSortBy } from 'react-table';

import { ACCESSORS } from '../Projects';
import { PAGES_PATH } from 'components/layout/PrivateRoute/components/MainLayout/hooks';
import { sortAlphabetically } from 'components/layout/Tables/utils/sortAlphabetically';
import {
  Avatar,
  TableRow,
  TableSubCell,
  TableSubItem,
  TableSubRow,
  ProjectPhasePersonLink,
} from 'components/ui';
import { HEADER_STYLE, TableHeader } from 'components/ui/Table/TableHeader';
import { Box } from 'rebass';

type Props = {
  data: any[];
};

export const ProjectsUsers: React.FC<Props> = ({ data }) => {
  const tableColumns = useMemo(
    () => [
      {
        accessor: ACCESSORS.USER,
        Cell: ({
          row: { original: row },
        }: Cell<{ [ACCESSORS.ID]: number; [ACCESSORS.USER]: string }>) => {
          const id = row[ACCESSORS.ID];
          const name = row[ACCESSORS.USER];

          return (
            <ProjectPhasePersonLink
              href={`/users/${id}`}
              source={PAGES_PATH.DASHBOARD}
            >
              <Avatar name={name} size="sm" src="" />
              <Box as="span" ml={2}>
                {name}
              </Box>
            </ProjectPhasePersonLink>
          );
        },
        Header: 'User name',
        id: ACCESSORS.USER,
        width: 300,
        minWidth: 300,
        maxWidth: 300,
      },
      {
        Header: '',
        id: 'blank1',
        width: 200,
        minWidth: 200,
        maxWidth: 200,
      },
      {
        accessor: ACCESSORS.ESTIMATES,
        Header: 'Estimates (h)',
        id: ACCESSORS.ESTIMATES,
        width: 150,
      },
      {
        accessor: ACCESSORS.SPENT_NON_EFFECTIVE,
        Header: 'Spent (non effective) (h)',
        id: ACCESSORS.SPENT_NON_EFFECTIVE,
        width: 170,
      },
      {
        accessor: ACCESSORS.REMAINING,
        Header: 'Remaining (h)',
        id: ACCESSORS.REMAINING,
        width: 150,
      },
      {
        accessor: ACCESSORS.DELIVERED_TO_QA,
        Header: 'Delivered to QA (%)',
        id: ACCESSORS.DELIVERED_TO_QA,
        width: 140,
      },
      {
        accessor: ACCESSORS.ACCEPTED_BY_QA,
        Header: 'Accepted by QA (%)',
        id: ACCESSORS.ACCEPTED_BY_QA,
        width: 130,
      },
      {
        Header: '',
        id: 'blank2',
        width: 120,
      },
      {
        Header: '',
        id: 'blank3',
        width: 120,
      },
      {
        Header: '',
        id: 'blank4',
        width: 170,
      },
      {
        Header: '',
        id: 'blank5',
        width: 185,
      },
      {
        Header: '',
        id: 'blank6',
        width: 32,
      },
    ],
    [],
  );
  const tableData = useMemo(
    () =>
      data.map((user) => ({
        [ACCESSORS.ID]: user.id,
        [ACCESSORS.USER]: user.full_name,
        [ACCESSORS.ESTIMATES]: user.estimation_sum,
        [ACCESSORS.SPENT_NON_EFFECTIVE]: `${user.spent_sum} (${user.non_effective_sum})`,
        [ACCESSORS.REMAINING]: user.remaining,
        [ACCESSORS.DELIVERED_TO_QA]: user.delivered_percentage,
        [ACCESSORS.ACCEPTED_BY_QA]: user.accepted_by_qa_percentage,
      })),
    [data],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      // @ts-ignore
      columns: tableColumns,
      data: tableData,
      disableSortRemove: true,
      initialState: {
        // @ts-ignore
        sortBy: [
          {
            desc: false,
            id: ACCESSORS.USER,
          },
        ],
      },
      sortTypes: {
        alphanumeric: sortAlphabetically('No name'),
      },
    },
    useSortBy,
    useFlexLayout,
  );

  return (
    <TableSubRow>
      <section {...getTableProps()}>
        <TableHeader
          headerGroups={headerGroups}
          style={HEADER_STYLE.MINOR}
          headerColumnStyles={{ flex: 1, width: '200px' }}
          lastCellShorter
        />
        <ul {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <TableSubItem {...row.getRowProps()} as="li" values={row.values}>
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(({ getCellProps, render }) => (
                    <TableSubCell
                      {...getCellProps({
                        style: {
                          flex: '1',
                        },
                      })}
                      lastCellShorter
                    >
                      {render('Cell')}
                    </TableSubCell>
                  ))}
                </TableRow>
              </TableSubItem>
            );
          })}
        </ul>
      </section>
    </TableSubRow>
  );
};
