import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NewStyledButton } from 'components/prime/NewButton/styled';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { Spin } from 'components/ui';
import { fetcher } from 'fetchers/fetcher';
import { Box, Flex } from 'rebass';
import {
  GridWrapper,
  SubheaderWrapper,
} from 'screens/OrganizationSettings/styled';
import { HorizontalLine } from 'screens/User/components/GeneralInfoFields';
import { theme } from 'styles/theme';
import useSWR from 'swr';

import { OrganizationItem } from './styled';

interface Props {
  isIntegrated: boolean;
  canIntegrate: boolean;
  onClose: () => void;
}

export const GitHubSection = ({
  isIntegrated,
  onClose,
  canIntegrate,
}: Props) => {
  const { t } = useTranslation();
  const companyUuid = useMemo(
    () => JSON.parse(localStorage.getItem('company_uuid') || ''),
    [],
  );

  const handleConnectGithub = () => {
    window.open(
      `${
        process.env.REACT_APP_CRM_API_URL
      }/auth/github?origin=${encodeURIComponent(
        window.location.origin,
      )}/organization-settings&company_uuid=${companyUuid}`,
      '_self',
    );
  };

  const { data } = useSWR<any>(
    isIntegrated ? 'github/organizations' : '',
    fetcher,
  );

  const getIntegrationForm = () => (
    <>
      <SubheaderWrapper active={true}>
        <Box mr={2} display="inline">
          <i className="pi pi-info-circle" />
        </Box>
        <Box display="inline">
          {t(
            canIntegrate
              ? 'organizationSettings.github.integrationValid'
              : 'organizationSettings.github.integrationOff',
          )}
        </Box>
      </SubheaderWrapper>
      <HorizontalLine />
      <Flex justifyContent="flex-end">
        <Box mr={3}>
          <PrimeTextButton onClick={onClose} label={t('common.cancel')} />
        </Box>
        {!canIntegrate && (
          <NewStyledButton
            data-cy="github-connect-btn"
            onClick={handleConnectGithub}
            label={t('organizationSettings.github.connect')}
          />
        )}
      </Flex>
    </>
  );

  const getOrganizationsList = () => (
    <>
      {!data ? (
        <SubheaderWrapper active={true}>
          <Spin />
        </SubheaderWrapper>
      ) : (
        <>
          <Box color="midGray" fontSize="xs">
            {t('organizationSettings.github.organizations')}
          </Box>
          <GridWrapper gridGap={`${theme.space[4]} ${theme.space[5]}`} margin>
            {data.organizations.map(({ name }: { name: string }) => (
              <OrganizationItem key={name}>{name}</OrganizationItem>
            ))}
          </GridWrapper>
        </>
      )}
      <HorizontalLine />
      <Box alignSelf="flex-end">
        <Box mr={3}>
          <PrimeTextButton onClick={onClose} label={t('common.cancel')} />
        </Box>
      </Box>
    </>
  );

  return (
    <Flex flexDirection="column" mt={4}>
      {canIntegrate && isIntegrated
        ? getOrganizationsList()
        : getIntegrationForm()}
    </Flex>
  );
};
