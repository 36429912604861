import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { IconWrapper, StyledBackLink } from '../styled';

export const BackButton: React.FC = () => {
  const { t } = useTranslation();
  const { goBack, location, go } = useHistory<{ from?: string }>();
  const onClick = () => {
    if (
      location?.state?.from === 'edit-profile' ||
      location?.state?.from === 'project'
    ) {
      go(-2);
    }
    goBack();
  };

  return (
    <StyledBackLink>
      <div onClick={onClick}>
        <IconWrapper>
          <FiChevronLeft size={14} />
        </IconWrapper>
        <div>{t('common.back')}</div>
      </div>
    </StyledBackLink>
  );
};
