import { UserPermissions } from 'api/users/models';
import { getPermissionList, PERMISSIONS } from 'utils/hooks/usePermissions';

export const hasUserAccessToProjects = (permissions: UserPermissions) =>
  getPermissionList(permissions)[PERMISSIONS.VIEW_PROJECT_LIST]();

export const hasUserAccessToAddNewUser = (permissions: UserPermissions) =>
  getPermissionList(permissions)[PERMISSIONS.ADD_USER]();

export const hasUserAccessToEditUser = (permissions: UserPermissions) =>
  getPermissionList(permissions)[PERMISSIONS.EDIT_USER]();

export const hasUserAccessToEditProfile = (permissions: UserPermissions) =>
  getPermissionList(permissions)[PERMISSIONS.ME]();
