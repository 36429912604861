import { useMemo } from 'react';

import { Project } from 'api/projects/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

type Projects = {
  projects: Project[];
};

const API_URL = '/projects';

export const useProjectsWithoutPhases = () => {
  const { data } = useSWR<Projects>(API_URL, fetcher);

  const projectsWithoutPhases = useMemo(
    () => data?.projects.filter((project) => project.phases.length === 0),
    [data?.projects],
  );

  return {
    isLoading: !data,
    projectsWithoutPhases: projectsWithoutPhases,
  };
};
