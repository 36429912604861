import styled from '@emotion/styled/macro';
import { TableItem as Item } from 'components/ui';
import { ScrollableContainer } from 'components/ui/ScrollableContainer/styled';
import { theme } from 'styles/theme';
import { HEIGHT_OF_TABLE_HEADERS_PLUS_TABLE_GAP_SMALLER } from 'utils/constants';

export const TableItem = styled(Item)`
  background-color: ${theme.colors.white};

  &:last-child {
    border-radius: 0;
  }

  &:nth-of-type(even) {
    background-color: ${theme.colors.blueExtraLight};
  }
`;

export const Wrapper = styled(ScrollableContainer)`
  max-height: ${`calc(100vh - ${HEIGHT_OF_TABLE_HEADERS_PLUS_TABLE_GAP_SMALLER})`};
`;
