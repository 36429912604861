export function getFromStorage(key: string): any | undefined {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export function saveToStorage(key: string, value: any = null): void {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error(error);
  }
}

export function removeFromStorage(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
}

export const saveFilterToStorage = (name: string, value: any) => {
  saveToStorage(`filter-${name}`, value);
};

export const getFilterFromStorage = (name: string): any | undefined => {
  return getFromStorage(`filter-${name}`);
};

export const isFilterSet = (key: string) => {
  return `filter-${key}` in localStorage;
};
