import React from 'react';

import { Checkbox as BaseCheckbox } from 'primereact/checkbox';
import { Box, Flex } from 'rebass';

export type CheckboxChangeEvent = {
  originalEvent: Event;
  value: any;
  checked: boolean;
  target: {
    type: string;
    name: string;
    id: string;
    value: any;
    checked: boolean;
  };
};

type Props = {
  isChecked: boolean;
  onChange: (e: CheckboxChangeEvent) => void;
  name: string;
};

export const Checkbox: React.FC<Props> = ({
  isChecked,
  onChange,
  children,
  name,
}) => {
  return (
    <Flex mb={4} alignItems="center">
      <Box mr={2}>
        <BaseCheckbox checked={isChecked} onChange={onChange} name={name} />
      </Box>
      {children}
    </Flex>
  );
};
