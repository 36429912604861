import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResourcesReportsPayloadFreeResource,
  ResourcesReportsPayloadNeededResource,
  AvailableResources,
  ResourcesReportsPayloadConflict,
  ResourcesReportsPayloadConflictDetail,
} from 'api/users/models';
import { Spin } from 'components/ui';
import { getYear, format } from 'date-fns';
import { useSortedFollowingWeeks } from 'fetchers';
import { useResourcesReports } from 'fetchers/hooks/useResourcesReports';
import { Box } from 'rebass';

import {
  Wrapper,
  TableWrapper,
  Cell,
  HeaderCell,
  ColumnWrapper,
  Title,
  SectionTitle,
  Accent,
  PageTitle,
} from './styled';

export const WeeklySummary: React.FC = () => {
  const { t } = useTranslation();
  const {
    availableResources,
    neededResources,
    isLoading,
  } = useResourcesReports();

  const currentDate = useMemo(() => new Date(), []);
  const currentYear = useMemo(() => getYear(currentDate), [currentDate]);

  const { followingWeeks } = useSortedFollowingWeeks();

  const getAvailableResourcesContent = ({
    freeResources,
  }: {
    freeResources: ResourcesReportsPayloadFreeResource[];
  }) => {
    return (
      <Cell>
        {freeResources?.length > 0 && (
          <Box
            as="section"
            sx={{
              marginBottom: '15px',
            }}
          >
            <WeeklySummaryList freeResources={freeResources} />
          </Box>
        )}
      </Cell>
    );
  };

  const getConflictsContent = ({
    conflicts,
  }: {
    conflicts: ResourcesReportsPayloadConflict[];
  }) => {
    return (
      <>
        {conflicts?.length > 0 ? (
          <Cell>
            <Box
              as="section"
              sx={{
                marginBottom: '15px',
                paddingBottom: '25px',
              }}
            >
              <ul>
                {conflicts.map(
                  (
                    {
                      details,
                      hours_total,
                      user,
                    }: ResourcesReportsPayloadConflict,
                    index: number,
                  ) => (
                    <Box
                      as="li"
                      key={`${user}-${hours_total}`}
                      sx={{ paddingBottom: '12px' }}
                    >
                      <Title>
                        {user} - {hours_total}h
                      </Title>
                      <ul>
                        {details.map(
                          ({
                            hours_sum,
                            people_responsible,
                            project_name,
                          }: ResourcesReportsPayloadConflictDetail) => (
                            <li key={`${project_name}-${hours_sum}`}>
                              [
                              {people_responsible?.join(', ') ||
                                'No person responsible'}
                              ]<Accent> {project_name}</Accent> -{' '}
                              <strong>{hours_sum}h</strong>
                            </li>
                          ),
                        )}
                      </ul>
                    </Box>
                  ),
                )}
              </ul>
            </Box>
          </Cell>
        ) : (
          <ColumnWrapper>
            <Cell>
              <p>{t('common.noData')}</p>
            </Cell>
          </ColumnWrapper>
        )}
      </>
    );
  };

  const getAvailableResourcesHeader = (weekNumber?: number) => {
    const week = followingWeeks.find((week) => week.weekNumber === weekNumber);
    return (
      <HeaderCell>{`Week ${weekNumber} - ${
        week?.start ? format(new Date(week.start), 'dd.MM') : ''
      } - ${week?.end ? format(new Date(week.end), 'dd.MM') : ''}`}</HeaderCell>
    );
  };

  const getNedeedResourcesHeader = (headerNumber: number, year: number) => (
    <HeaderCell>
      {headerNumber < 10 ? '0' : ''}
      {headerNumber}.{year}
    </HeaderCell>
  );

  const getNeededResourceContent = (
    month: ResourcesReportsPayloadNeededResource[],
  ) => {
    return (
      <ul>
        {month.map(
          ({
            average_planned_hours,
            resource_name,
          }: ResourcesReportsPayloadNeededResource) => (
            <li key={`${resource_name}-${average_planned_hours}`}>
              {resource_name} - <strong>{average_planned_hours}h</strong>
            </li>
          ),
        )}
      </ul>
    );
  };

  const getNeededResources = () => {
    if (neededResources) {
      const currentYearResources = neededResources[currentYear];
      const nextYearResources = neededResources[currentYear + 1];

      const resources: {
        year: number;
        data: [string, ResourcesReportsPayloadNeededResource[]][];
      }[] = [];

      if (currentYearResources) {
        resources.unshift({
          data: Object.entries(currentYearResources),
          year: currentYear,
        });
      }

      if (nextYearResources) {
        resources.push({
          data: Object.entries(nextYearResources),
          year: currentYear + 1,
        });
      }

      return resources;
    }
    return undefined;
  };

  const getNeededResourcesSection = () => {
    const resources = getNeededResources();

    return (
      resources &&
      resources.map(({ data, year }) =>
        data.map((month) => (
          <ColumnWrapper key={month[0]}>
            {getNedeedResourcesHeader(parseInt(month[0]), year)}
            <Cell>
              {month[1].length ? (
                getNeededResourceContent(month[1])
              ) : (
                <p>{t('common.noData')}</p>
              )}
            </Cell>
          </ColumnWrapper>
        )),
      )
    );
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Wrapper>
      <PageTitle>{t('weeklySummary.header')}</PageTitle>
      <SectionTitle>{t('weeklySummary.teamAvailability')}</SectionTitle>
      <TableWrapper>
        {availableResources &&
          availableResources.map((week: AvailableResources) => {
            return (
              <ColumnWrapper key={week.weekNumber}>
                {getAvailableResourcesHeader(week.weekNumber)}
                {getAvailableResourcesContent(week)}
              </ColumnWrapper>
            );
          })}
      </TableWrapper>

      <Box as="section" sx={{ mt: '50px' }}>
        <SectionTitle>{t('weeklySummary.conflicts')}</SectionTitle>
        <TableWrapper>
          {availableResources &&
            availableResources.map((week: AvailableResources) => (
              <ColumnWrapper key={week.weekNumber}>
                {getConflictsContent(week)}
              </ColumnWrapper>
            ))}
        </TableWrapper>
      </Box>

      {neededResources && (
        <Box as="section" sx={{ mt: '20px' }}>
          <SectionTitle>{t('weeklySummary.neededResources')}</SectionTitle>
          <TableWrapper>{getNeededResourcesSection()}</TableWrapper>
        </Box>
      )}
    </Wrapper>
  );
};

const WeeklySummaryList: React.FC<{
  freeResources: ResourcesReportsPayloadFreeResource[];
}> = ({ freeResources }) => {
  return (
    <ul>
      {freeResources.map(({ available_hours, full_name }) => (
        <li key={`${full_name}-${available_hours}`}>
          {full_name} - {available_hours}h
        </li>
      ))}
    </ul>
  );
};
