import React, {
  cloneElement,
  useRef,
  useState,
  Children,
  isValidElement,
} from 'react';

import { CheckboxProps, CheckboxVariants } from '../';
import { FormControl } from 'components/primitive';

import { CheckboxGroupBox, CheckboxGroupTitle } from './styled';

type Props = {
  id?: CheckboxProps['id'];
  name?: CheckboxProps['name'];
  children?: React.ReactNode;
  defaultValue?: Array<CheckboxProps['value']> | [];
  value?: Array<CheckboxProps['value']>;
  onChange?: (value: Array<CheckboxProps['value']>) => void;
  isInline?: boolean;
  label?: string;
  spacing?: number;
  size?: CheckboxProps['size'];
  variant?: CheckboxVariants;
};

export const CheckboxGroup: React.FC<Props> = ({
  onChange,
  name,
  size,
  defaultValue,
  isInline,
  value: valueProp,
  children,
  spacing = 2,
  label,
  variant = 'default',
  ...rest
}) => {
  const [values, setValues] = useState(defaultValue || []);

  const { current: isControlled } = useRef(valueProp != null);
  const _values = isControlled ? valueProp : values;

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    let newValues;
    if (checked) {
      newValues = [..._values, value];
    } else {
      newValues = _values?.filter((val) => val !== value);
    }

    !isControlled && setValues(newValues!);
    onChange && onChange(newValues!);
  };

  const validChildren = Children.toArray(children).filter((child) =>
    isValidElement(child),
  );

  const clones = validChildren.map((child: any, index) => {
    const isLastCheckbox = validChildren.length === index + 1;
    const spacingProps = isInline ? { mr: spacing } : { mb: spacing };

    return (
      <CheckboxGroupBox
        key={index}
        display={isInline ? 'inline-block' : 'block'}
        {...(!isLastCheckbox && spacingProps)}
      >
        {cloneElement(child, {
          size,
          variant,
          name: `${name}-${index}`,
          onChange: _onChange,
          isChecked: _values?.includes(child.props.value),
        })}
      </CheckboxGroupBox>
    );
  });

  return (
    <FormControl>
      {label && <CheckboxGroupTitle>{label}</CheckboxGroupTitle>}
      <div
        role="group"
        style={{ display: isInline ? 'flex' : 'block' }}
        {...rest}
      >
        {clones}
      </div>
    </FormControl>
  );
};
