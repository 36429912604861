import styled from '@emotion/styled';
import { Flex } from 'rebass';

export const CheckmarkContainer = styled(Flex)`
  margin-top: 2rem;
`;
export const CheckmarkWrapper = styled(Flex)`
  margin-bottom: 1rem;
`;

export const IconWrapper = styled.div`
  margin-right: 0.5rem;
`;
