import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const SortIcons = styled.div`
  margin-left: ${theme.space[3]};
  font-size: ${theme.fontSizes.xxs};

  & .active {
    color: ${theme.colors.primary};
  }
`;
