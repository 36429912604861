import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

interface WeekDayProps {
  saturday: boolean;
  holiday: boolean;
}

export const StyledOption = styled.li`
  height: 40px;
  padding: 0 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover,
  &:focus {
    outline: none;
    background-color: ${theme.colors.lightBlue};
  }
`;

export const WeekLabel = styled.div`
  padding: 3px;
  width: 36px;
  margin: 0 12px;
  color: ${theme.colors.neutralDark};
  font-size: ${theme.fontSizes.xxs};
  border-radius: ${theme.radii.sm};
  border: solid 1px ${theme.colors.neutral};
`;

export const WeekDay = styled.span<WeekDayProps>`
  color: ${theme.colors.darkGray};
  background-color: ${({ saturday, holiday }) =>
    holiday
      ? theme.colors.paleRed
      : saturday
      ? theme.colors.neutralMediumDark
      : theme.colors.lightBlue};
  margin: 0 5px 0 0;
  border-radius: 2px;
  padding: 4px;
  font-family: ${theme.fonts.regular};
  font-size: ${theme.fontSizes.xxs};
`;
