import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const Cover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  top: 0;
  left: 0;
  background-color: ${theme.colors.neutralLight};
`;
