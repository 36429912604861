import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Label } from '../UserDetails/styled';
import { useUser } from 'fetchers/hooks/users/useUser';
import { Box } from 'rebass';
import { selectLoggedInUserPreferences } from 'screens/Users/slices/preferencesSlice';

import { Title, StyledBox, Text, StyledFlex } from './styled';

const UserPreferences: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { user } = useUser(userId);

  const myPreferences = useSelector(selectLoggedInUserPreferences);
  const myId = myPreferences?.id || '';

  const isLoggedInUser = userId === myId;

  const preferences = isLoggedInUser
    ? myPreferences
    : user?.project_preferences;

  const { description = '', app_type = [], team_size = [], project_type = [] } =
    preferences || {};

  const isAppTypeData = app_type && app_type.length >= 1;
  const isTeamSizeData = team_size && team_size.length >= 1;
  const isProjectTypeData = project_type && project_type.length >= 1;
  const isDescriptionData = description && description.length >= 1;

  const shouldShowPreferences =
    isAppTypeData || isTeamSizeData || isProjectTypeData || isDescriptionData
      ? true
      : false;

  const { t } = useTranslation();

  return shouldShowPreferences ? (
    <Box mt={5} width="100%">
      <Label>{t('userProfile.preferences')}</Label>
      {isTeamSizeData && (
        <StyledFlex>
          <Title>{t('userProfile.teamSize')}:</Title>
          <Text>{team_size.join(', ')}</Text>
        </StyledFlex>
      )}
      {isAppTypeData && (
        <StyledFlex>
          <Title>{t('userProfile.preferencesForm.webMobileTitle')}:</Title>
          <Text>{app_type.join(', ')}</Text>
        </StyledFlex>
      )}
      {isProjectTypeData && (
        <StyledFlex>
          <Title>{t('userProfile.projectType')}:</Title>
          <Text>{project_type.join(', ')}</Text>
        </StyledFlex>
      )}
      {isDescriptionData && (
        <StyledBox>
          <Title>{t('userProfile.additionalInfo')}:</Title>
          {description}
        </StyledBox>
      )}
    </Box>
  ) : null;
};

export default UserPreferences;
