import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, MODAL_TYPES } from 'screens/Project/components/Modal';
import {
  getProjects,
  selectProjectsName,
  selectIsProjectsLoading,
} from 'store/slices/projectsSlice';

export const ProjectAdd: React.FC = () => {
  const dispatch = useDispatch();
  const isProjectsLoading = useSelector(selectIsProjectsLoading);
  const projectsName = useSelector(selectProjectsName);

  useEffect(() => {
    if (!projectsName.length) {
      dispatch(getProjects({}));
    }
  }, [dispatch, projectsName]);

  return (
    <Modal
      isLoading={isProjectsLoading}
      projectsName={projectsName}
      title="Add project"
      type={MODAL_TYPES.ADD}
    />
  );
};
