import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RiAtLine, RiGithubLine } from 'react-icons/all';
import { Link } from 'react-router-dom';

import UserPreferences from '../UserPreferences';
import { User } from 'api/users/models';
import { Tag, StyledDivider } from 'components/prime';
import { Spin, AvatarWithBackground } from 'components/ui';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useIntegrationsStatus } from 'fetchers/hooks/useIntegrationsStatus';
import { useOrganizationSettings } from 'fetchers/hooks/useOrganizationSettings';
import { Box, Flex, Text } from 'rebass';
import { theme } from 'styles/theme';
import { parseUserRoles } from 'utils/functions';
import { sortByKey } from 'utils/sortByKey';

import { UserSkill } from './components/UserSkill/UserSkill';
import { Label, StyledLink } from './styled';

type Props = {
  user?: User;
  isAuthUser: boolean;
};

export const UserDetails = ({ user }: Props) => {
  const { t } = useTranslation();

  const { full_name, user_types } = user || {};

  const parsedUserRoles = parseUserRoles(user_types);
  const hasParsedUserRoles = parsedUserRoles.length > 0;

  const sortedSupervisors = useMemo(() => {
    return user?.supervisors?.sort(sortByKey('name')) || [];
  }, [user?.supervisors]);

  const { integrations } = useIntegrationsStatus();
  const {
    integrations: organizationSettingsIntegrations,
  } = useOrganizationSettings();

  const shouldRenderGithubFields =
    (integrations?.github && organizationSettingsIntegrations?.github) || false;

  return (
    <Flex
      flexDirection="column"
      maxWidth="300px"
      width="300px"
      justifyContent="center"
      alignItems="center"
    >
      <AvatarWithBackground
        name={user?.full_name || ''}
        tagLabel={user?.team.name}
        image={user?.photo.url || ''}
      />
      <Text
        fontSize="xl"
        color="veryDarkGray"
        textAlign="center"
        lineHeight="40px"
        mt={3}
        fontWeight="bold"
      >
        {full_name || t('userProfile.noName')}
      </Text>

      {!user ? (
        <Box mt={5}>
          <Spin />
        </Box>
      ) : (
        <>
          <Flex mt={2} justifyContent="center" flexWrap="wrap">
            {user?.seniority?.name && <Tag>{user?.seniority?.name}</Tag>}
            {user?.position?.name && <Tag>{user?.position.name}</Tag>}
            {user?.department?.name && <Tag>{user?.department.name}</Tag>}
          </Flex>

          {hasParsedUserRoles && (
            <Box mt={5}>
              <Label>
                {parsedUserRoles.length > 1
                  ? t('userProfile.additionalRole')
                  : t('userProfile.additionalRoles')}
              </Label>
              <Flex justifyContent="center" flexWrap="wrap">
                {parsedUserRoles.map((role) => (
                  <Tag key={role}>{role}</Tag>
                ))}
              </Flex>
            </Box>
          )}

          <Box mt={5} width="100%">
            <Label>{t('userProfile.supervisors')}</Label>
            {sortedSupervisors.map((supervisor) => (
              <Flex key={supervisor.id} mb={2} alignItems="center">
                <Link to={`/users/${supervisor.id}`}>
                  <AvatarWithBackground
                    name={supervisor.name || ''}
                    image={supervisor.photo?.medium.url || ''}
                    size="small"
                  />
                </Link>

                <Link to={`/users/${supervisor.id}`}>
                  <Text ml={2} color="veryDarkGray" fontSize="sm">
                    {supervisor.name}
                  </Text>
                </Link>
              </Flex>
            ))}
          </Box>

          {user.skills && user?.skills?.length >= 1 && (
            <Box mt={5} width="100%">
              <Label>{t('userProfile.skills')}</Label>
              {user?.skills?.map((skill) => (
                <UserSkill key={skill.id} skill={skill} />
              ))}
            </Box>
          )}

          <UserPreferences />

          <Box mt={5} mb={8} width="100%">
            <Label>{t('userProfile.contactInformation')}</Label>
            <Flex alignItems="center">
              <RiAtLine size="20px" fill={theme.colors.primaryLight} />
              <Text color="veryDarkGray" fontSize="sm" ml={2}>
                {user?.email}
              </Text>
            </Flex>

            {shouldRenderGithubFields && user?.github_nick && (
              <Flex alignItems="center" mt={1}>
                <RiGithubLine size="20px" fill={theme.colors.primaryLight} />
                <StyledLink href={`https://github.com/${user?.github_nick}`}>
                  {user?.github_nick}
                </StyledLink>
              </Flex>
            )}
          </Box>

          <StyledDivider />

          <Text fontSize="xxs" margin={0}>
            {user?.last_edited_by?.id ? (
              <>
                <span>{t('userProfile.lastEditedBy')} </span>

                <Link to={`/users/${user?.last_edited_by.id}`}>
                  <Text as="span" color="primary">
                    {user?.last_edited_by?.fullname ||
                      user?.last_edited_by?.email}{' '}
                    {''}
                  </Text>
                </Link>

                <span>
                  {user?.updated_at &&
                    format(parseISO(user.updated_at), 'EEEE dd MMMM yyyy')}
                </span>
              </>
            ) : (
              <span>
                {t('userProfile.lastEdited')}{' '}
                {user?.updated_at &&
                  format(parseISO(user.updated_at), 'EEEE dd MMMM yyyy')}{' '}
              </span>
            )}
          </Text>
        </>
      )}
    </Flex>
  );
};
