import { getISOWeek, startOfISOWeek, format } from 'date-fns';

export const currentDate = new Date();
export const currentWeek = getISOWeek(new Date());
export const currentStartWeek = format(
  startOfISOWeek(currentDate),
  'yyyy-MM-dd',
);
export const initialSortKey = 'initial_sort';

// Fix this by using process.env.NODE_ENV - needs Heroku build reconfiguration
const getEnvByUrl = () => {
  const { hostname } = window.location;

  if (hostname.includes('staging')) {
    return 'staging';
  }

  if (hostname.includes('beta')) {
    return 'beta';
  }

  if (hostname.includes('production') || hostname.includes('erp.')) {
    return 'production';
  }

  return 'development';
};

export const NODE_ENV = getEnvByUrl();

export const MODAL_ID = {
  ADD_TEAM_MEMBER: 'add_team_member',
  SHOW_USER: 'show_user',
  REMOVE_USER: 'remove_user',
  PROJECTS_FILTERS: 'projects_filters',
};

export const REGEX = {
  url: /^(https:\/\/)[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
  full_name: /^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]{1,}( [A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+){1,}?$/,
};

export const HEIGHT_OF_TABLE_HEADERS_PLUS_TABLE_GAP = '194px';
export const HEIGHT_OF_TABLE_HEADERS_PLUS_TABLE_GAP_SMALLER = '150px';
