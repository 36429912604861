import React, { FC } from 'react';
import { IoMdClose } from 'react-icons/io';

import { FieldProps } from 'formik';
import { theme } from 'styles/theme';

import { Field, Box, Flex, Checkbox, MultiSelect } from './EditField.styles';

export type EditFieldOptionsList = {
  label: string;
  value: string | number;
};

export type EditFieldProps = {
  name: string;
  label: string;
  type: string;
  Icon?: FC<{ heigh?: string; width?: string }>;
  options?: EditFieldOptionsList[];
  multiple?: boolean;
  required?: boolean;
};

const EditField: FC<EditFieldProps> = ({
  name,
  type,
  options,
  label,
  Icon,
  multiple = false,
  required = false,
}) => {
  let as: string = '';
  let fieldStructure: JSX.Element | null = null;

  switch (type) {
    case 'multiselect':
      fieldStructure = (
        <Field id={name} name={name}>
          {({ field }: FieldProps) => (
            <MultiSelect {...field} options={options} required={required} />
          )}
        </Field>
      );
      break;
    case 'checkboxList':
      fieldStructure = (
        <div role="group" aria-labelledby={name}>
          {options?.map(({ value, label }) => {
            const id = `${name}-${value}`;
            return (
              <Checkbox key={id}>
                <Field id={id} type="checkbox" name={name} value={value} />
                <label htmlFor={id}>
                  {label}
                  <IoMdClose />
                </label>
              </Checkbox>
            );
          })}
        </div>
      );
      break;
    case 'select':
    // @ts-ignore
    case 'textarea':
      as = type;
      type = '';
    default:
      fieldStructure = (
        <Field
          id={name}
          name={name}
          as={as}
          type={type}
          multiple={multiple}
          required={required}
        >
          {options?.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Field>
      );
      break;
  }

  return (
    <Box>
      <Flex alignItems="center" width="100%" marginBottom={theme.space[3]}>
        {Icon && <Icon />}
        <label htmlFor={name}>{label}</label>
      </Flex>
      {fieldStructure}
    </Box>
  );
};

EditField.displayName = 'EditField';

export default EditField;
