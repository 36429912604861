import { TableRowProps } from '.';
import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';

export const TableRowWrapper = styled(Flex)<TableRowProps>`
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'initial')};
  position: relative;
  align-items: center;

  ${({ onClick }) =>
    !!onClick &&
    `
    & {
        transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
    }

    &:focus {
      outline: none;
    }
  `}
`;
