import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { AUTH_HEADER_KEYS } from 'screens/Auth/constants';

import { getFromStorage, removeFromStorage } from './localStorage';

function setRequestAuthHeaders(headers: { [key: string]: string }) {
  AUTH_HEADER_KEYS.forEach((key) => {
    const value = getFromStorage(key);

    if (value) {
      headers[key] = value;
    }
  });
}

function onRequest(config: AxiosRequestConfig): AxiosRequestConfig {
  setRequestAuthHeaders(config.headers);

  return config;
}

function onResponseSuccess(response: AxiosResponse): AxiosResponse {
  return response;
}

function onResponseError(error: AxiosError): Promise<never> {
  if (
    error.response?.status === 401 &&
    window.location.pathname.indexOf('login') === -1
  ) {
    AUTH_HEADER_KEYS.forEach((key) => {
      removeFromStorage(key);
    });
  }

  return Promise.reject(error.response);
}

function httpClient(): AxiosInstance {
  const defaultOptions = {
    withCredentials: true,
    baseURL: process.env.REACT_APP_CRM_API_URL,
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(onRequest);
  instance.interceptors.response.use(onResponseSuccess, onResponseError);

  return instance;
}

export default httpClient();
