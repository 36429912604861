import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { fetcherWithArgs, getArgsAsString } from '../../fetcher';
import { User } from 'api/users/models';
import { selectUsersFiltersData } from 'screens/Users/slices/usersFiltersSlice';
import useSWR from 'swr';
import { sortByKey } from 'utils/sortByKey';

interface UsersData {
  users: User[];
}

type UsersRequest = {
  query?: string;
  teams?: string[];
  positions?: string[];
  supervisors?: string[];
  skills?: string[];
  seniority?: string[];
};

const API_URL = '/users';

export const useUsers = () => {
  const {
    positions,
    skills,
    seniority,
    supervisors,
    teams,
    query,
  } = useSelector(selectUsersFiltersData);

  return useUsersNative({
    positions,
    skills,
    seniority,
    supervisors,
    teams,
    query,
  });
};

export const useUsersNative = ({
  positions,
  skills,
  seniority,
  supervisors,
  teams,
  query,
}: UsersRequest) => {
  const settings = useMemo(
    () => [
      { search: query },
      { teams_ids: teams },
      { supervisors_ids: supervisors },
      { seniorities_ids: seniority },
      { skills_ids: skills },
      { positions_ids: positions },
    ],
    [positions, skills, seniority, supervisors, teams, query],
  );

  const settingsId = getArgsAsString(settings);

  const { data, mutate } = useSWR<UsersData>([API_URL, settingsId], (url) =>
    fetcherWithArgs(url, settings),
  );

  return {
    isLoading: !data,
    users: data ? data.users.sort(sortByKey('full_name')) : undefined,
    mutate,
  };
};
