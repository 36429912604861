import { mutate } from 'swr';
import { getCacheKey } from 'utils/getCacheKey';

export const mutateIntegrationStatus = () => {
  const searchedKey = getCacheKey('/company/integrations');

  if (searchedKey) {
    mutate(searchedKey);
  }
};
