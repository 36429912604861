import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { SkillBox, Link } from '../styled';
import styled from '@emotion/styled/macro';
import {
  Roles,
  User,
  UserPayload,
  UserSkill,
  UserSkillPayload,
} from 'api/users/models';
import { Chip } from 'components/forms';
import { SelectLabel } from 'components/forms/Select/styled';
import {
  FormikMultiSelect,
  FormikNumberInput,
  FormikSelect,
  FormikSwitch,
  FormikTextInput,
  RolesCheckboxGroup,
} from 'components/forms/formik-prime';
import { FormikUploader } from 'components/forms/formik-prime/Uploader';
import { GithubIcon } from 'components/ui';
import { useIntegrationsStatus } from 'fetchers/hooks/useIntegrationsStatus';
import { useOrganizationSettings } from 'fetchers/hooks/useOrganizationSettings';
import { useDepartments } from 'fetchers/hooks/users/useDepartments';
import { usePositions } from 'fetchers/hooks/users/usePositions';
import { useSeniority } from 'fetchers/hooks/users/useSeniority';
import { useTeams } from 'fetchers/hooks/users/useTeams';
import { useUser } from 'fetchers/hooks/users/useUser';
import { useFormikContext } from 'formik';
import { Box, Flex, Image, Text } from 'rebass';
import {
  selectAuthUser,
  selectAuthUserSignInCount,
  selectIsSelf,
} from 'screens/Auth/authSlice';
import { RootState } from 'store/rootReducer';
import { deactivateTFA } from 'store/slices/usersSlice';
import { theme } from 'styles/theme';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

import Skill from './components/Skill';

export enum FORM_TYPES {
  ADD = 'add',
  EDIT = 'edit',
}

type Props = {
  skills: UserSkill[];
  type: FORM_TYPES;
  users: User[];
};

export const HorizontalLine = styled.hr`
  border: 1px solid ${theme.colors.whiteLilac};
  margin-top: 0;
  margin-bottom: ${theme.space[6]};
  position: relative;
  top: 51%;
  width: 100%;
`;

const getItemsForForm = (data: { name: string; id: number }[] = []) =>
  data.map(({ id: value, name: label }) => ({
    label,
    value,
  }));

const GeneralInfoFields: React.FC<Props> = ({ skills, type, users }) => {
  const { userId } = useParams<{ userId: string }>();
  const { user } = useUser(userId);

  const [is2FAChecked, setIs2FAChecked] = useState(user?.google_2fa_enabled);
  const { values, setValues } = useFormikContext<UserPayload>();
  const dispatch = useDispatch();
  const signInCount = useSelector(selectAuthUserSignInCount);
  const { integrations } = useIntegrationsStatus();
  const {
    integrations: organizationSettingsIntegrations,
  } = useOrganizationSettings();

  const isAuthUser = useSelector((state: RootState) =>
    selectIsSelf(state, userId),
  );

  const loggedUser = useSelector(selectAuthUser);

  const onChange2FA = () => {
    if (is2FAChecked) {
      setIs2FAChecked(false);
      dispatch(deactivateTFA());
    } else {
      setIs2FAChecked(true);
      handleMoveTo2FAFlow(userId);
    }
  };

  const isOwnerEditing = loggedUser?.id === user?.id || false;

  const history = useHistory();

  const handleMoveTo2FAFlow = (userId: string) => {
    history.push(`/users/${userId}/edit/two-factor-authentication`, {
      from: `users/${userId}/edit`,
    });
  };

  const { t } = useTranslation();

  const { seniority } = useSeniority();
  const { departments } = useDepartments();

  // TODO: Handle add view
  const hasAccessToEditAdditionalRoles = usePermissions(
    PERMISSIONS.ADDITIONAL_ROLES_EDIT,
  );
  const hasAccessToEditUserStatus = usePermissions(
    PERMISSIONS.SET_ACTIVE_USER_EDIT,
  );
  const hasAccessToEditAvailableResource = usePermissions(
    PERMISSIONS.EDIT_AVAILABLE_RESOURCE,
  );
  const hasAccessToSetAdditionalRoles = usePermissions(
    PERMISSIONS.ADDITIONAL_ROLES_ADD,
  );
  const hasAccessToSetUserStatus = usePermissions(
    PERMISSIONS.SET_ACTIVE_USER_ADD,
  );
  const hasAccessToSetAvailableResource = usePermissions(
    PERMISSIONS.ADD_AVAILABLE_RESOURCE,
  );

  const hasAccessToUpdateNotifications = usePermissions(
    PERMISSIONS.NOTIFICATION_SETTING_UPDATE,
  );

  const shouldRenderAdditionalRoles =
    type === FORM_TYPES.ADD
      ? hasAccessToSetAdditionalRoles
      : hasAccessToEditAdditionalRoles;
  const shouldRenderAvailableResource =
    type === FORM_TYPES.ADD
      ? hasAccessToSetAvailableResource
      : (hasAccessToEditAvailableResource && !isAuthUser) ||
        (hasAccessToEditAvailableResource && isAuthUser && signInCount);
  const shouldRenderActiveUser =
    type === FORM_TYPES.ADD
      ? hasAccessToSetUserStatus
      : hasAccessToEditUserStatus && !isAuthUser;
  const shouldRenderReportsDeliveryMethod =
    isAuthUser &&
    (user?.user_types?.includes(Roles.ADMIN) ||
      user?.user_types?.includes(Roles.TEAM_LEADER) ||
      user?.user_types?.includes(Roles.PM));

  const shouldRenderGithubFields =
    (integrations?.github && organizationSettingsIntegrations?.github) || false;

  const usersItems = useMemo(
    () =>
      users
        .filter((userItem) => userItem.id !== parseInt(userId))
        .map(({ id: userId, full_name: label }) => {
          const supervisorId = user?.team_member_supervisors?.find(
            (sup) => sup.supervisor.id === userId,
          )?.id;
          return {
            label,
            value: {
              id: supervisorId,
              supervisor_id: userId,
              _destroy: false,
            },
          };
        }),
    [userId, users, user?.team_member_supervisors],
  );

  const isNewUser = userId === undefined;

  const sortedSkills = useMemo(
    () => [...skills].sort((a, b) => a.name?.localeCompare(b.name)),
    [skills],
  );

  const skillsItems = useMemo(
    () =>
      sortedSkills.map(({ id, name }) => {
        const skill = values.skills_attributes?.find(
          (skill) => skill.skill_name_id === id,
        );
        return {
          skill_name_id: id,
          id: skill?.id,
          name,
          level: skill?.level || 0,
        };
      }),
    [sortedSkills, values.skills_attributes],
  );

  const { teams } = useTeams();
  const { positions } = usePositions();

  const teamsItems = useMemo(() => getItemsForForm(teams), [teams]);
  const positionsItems = useMemo(() => getItemsForForm(positions), [positions]);

  const alreadySignedIn = user && user.sign_in_count > 0;

  return (
    <>
      <Flex justifyContent="space-between">
        <Box flex={5}>
          <FormikTextInput
            name="full_name"
            label={`${t('users.fullName')}*`}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              (e.target.value = e.target.value.trim())
            }
          />
          <FormikTextInput name="email" label="E-mail*" />
        </Box>
        <Flex flexDirection="column" alignItems="center" pl={4} flex={4}>
          {!values.use_google_avatar && (
            <FormikUploader
              defaultValue={user?.photos?.photo?.url || ''}
              label={t('users.avatar')}
              name="photo"
            />
          )}
          {values.use_google_avatar && type === FORM_TYPES.ADD && (
            <Text mt={theme.space[5]} textAlign="center">
              {t('users.googleAvatar')}
            </Text>
          )}

          {values.use_google_avatar && type === FORM_TYPES.EDIT && (
            <>
              <SelectLabel>Avatar</SelectLabel>
              <Box
                size={148}
                sx={{
                  borderRadius: user?.photos.google_avatar.url
                    ? theme.radii.xxl
                    : 0,
                  overflow: 'hidden',
                }}
              >
                {user?.photos.google_avatar.url ? (
                  <Image
                    size={148}
                    src={user?.photos.google_avatar.url}
                    sx={{ objectFit: 'cover' }}
                  />
                ) : (
                  <Text>{t('users.avatarInfo')}</Text>
                )}
              </Box>
            </>
          )}
          <Flex alignItems="flex-end" my={4}>
            <Box mr={2}>
              <FormikSwitch name="use_google_avatar" />
            </Box>
            <SelectLabel>{t('users.useGoogleAvatar')}</SelectLabel>
          </Flex>
        </Flex>
      </Flex>
      <HorizontalLine />
      <FormikMultiSelect
        name={`team_member_supervisors_attributes`}
        options={usersItems}
        label={`${t('userProfile.supervisors')}*`}
        placeholder={t('common.chooseFromList')}
        isLabelVisible
        filter
        selectedItemsLabel={`${
          values.team_member_supervisors_attributes?.length
        } ${t('userProfile.supervisorsSelected')}`}
        selectedItemTemplate={(item) =>
          item && (
            <Chip
              key={`team_member_supervisors_attributes-${item.supervisor_id}`}
              onRemove={() => {
                setValues((oldValues: UserPayload) => {
                  const newSupervisors = oldValues.team_member_supervisors_attributes?.filter(
                    (supervisor) => supervisor.id !== item.id,
                  );

                  return {
                    ...oldValues,
                    team_member_supervisors_attributes: newSupervisors,
                  };
                });
              }}
              label={
                usersItems?.find(
                  (opt) => opt.value.supervisor_id === item.supervisor_id,
                )?.label
              }
            />
          )
        }
      />
      <FormikSelect
        name="team_id"
        options={teamsItems}
        placeholder={t('common.chooseFromList')}
        label={`${t('userProfile.team')}*`}
        initialValue={values.team_id || undefined}
        showClear
      />
      <Flex justifyContent="space-between">
        <Box width="48%">
          <FormikSelect
            name="position_id"
            options={positionsItems}
            placeholder={t('common.chooseFromList')}
            label={`${t('userProfile.position')}*`}
            initialValue={values.position_id || undefined}
            showClear
            onChange={(event) => {
              if (event.target.id !== String(values.position_id)) {
                setValues((oldValues: UserPayload) => ({
                  ...oldValues,
                  level: null,
                }));
              }
            }}
          />
        </Box>
        <Box width="48%">
          <FormikSelect
            name="seniority_id"
            options={seniority}
            optionLabel="name"
            optionValue="id"
            placeholder={t('common.chooseFromList')}
            label={t('userProfile.seniority')}
            initialValue={values.seniority_id || undefined}
            showClear
          />
        </Box>
      </Flex>
      <FormikSelect
        name="department_id"
        options={departments}
        optionLabel="name"
        optionValue="id"
        placeholder={t('common.chooseFromList')}
        label={`${t('userProfile.department')}*`}
        initialValue={values.department_id || undefined}
        showClear
      />
      <FormikMultiSelect
        name="skills_attributes"
        options={skillsItems}
        label={t('userProfile.skills')}
        isLabelVisible
        optionLabel="name"
        showClear
        filter
        maxSelectedLabels={skills.length}
        placeholder={t('userProfile.skillsPlaceholder')}
        isSkillsPicker={true}
        onChange={(e) => {
          if (!e.target.value) {
            setValues((oldValues: UserPayload) => {
              return {
                ...oldValues,
                skills_attributes: [],
              };
            });
          }
        }}
        selectedItemTemplate={(item) =>
          item && (
            <>
              <SkillBox
                key={`selected-item=${item?.id}`}
                onClick={(e) => e.stopPropagation()}
              >
                <Skill
                  selectedItem={item}
                  selectedItems={values.skills_attributes}
                  setSelectedItems={setValues}
                  removeSelectedItem={({ skill_name_id }: UserSkillPayload) =>
                    setValues((oldValues: UserPayload) => {
                      const newSkills = oldValues.skills_attributes?.filter(
                        (skills) => skills.skill_name_id !== skill_name_id,
                      );

                      return {
                        ...oldValues,
                        skills_attributes: newSkills,
                      };
                    })
                  }
                />
              </SkillBox>
            </>
          )
        }
      />
      {shouldRenderGithubFields ? (
        <Box width="48%">
          <FormikTextInput
            icon={<GithubIcon />}
            label={t('userProfile.githubNick')}
            name="github_nick"
            type="text"
          />
        </Box>
      ) : null}
      <HorizontalLine />
      <Box width="28%">
        <FormikNumberInput
          append="hours"
          label={`${t('userProfile.hoursWeekly')}*`}
          name="hours_weekly"
        />
      </Box>
      <HorizontalLine />
      {shouldRenderAdditionalRoles && (
        <>
          <RolesCheckboxGroup name="user_types" isAuthUser={isAuthUser} />
          <Box color="midGray" fontSize="xs" maxWidth="80%" mb={5}>
            {t('userProfile.roleChangesInfo')}
          </Box>
          <HorizontalLine />
        </>
      )}
      {shouldRenderAvailableResource && (
        <Flex justifyContent="space-between" mb={5}>
          <Flex flexDirection="column">
            <SelectLabel>{t('userProfile.teamAvailability')}</SelectLabel>
            <Box color="midGray" fontSize="xs" maxWidth="85%">
              {t('userProfile.availableResourceInfo')}
            </Box>
          </Flex>
          <FormikSwitch name="available_resource" />
        </Flex>
      )}
      {shouldRenderAvailableResource &&
        (shouldRenderActiveUser || shouldRenderReportsDeliveryMethod) && (
          <HorizontalLine />
        )}
      {shouldRenderActiveUser && !isNewUser && (
        <>
          <Flex justifyContent="space-between" mb={5}>
            <Flex flexDirection="column">
              <SelectLabel>Active user</SelectLabel>
              <Box color="midGray" fontSize="xs">
                {t('userProfile.activeUserInfo')}
              </Box>
            </Flex>
            <FormikSwitch name="active" disabled={!alreadySignedIn} />
          </Flex>
          <HorizontalLine />
        </>
      )}
      <Flex justifyContent="space-between" mb={5}>
        <Flex flexDirection="column">
          <SelectLabel>{t('userProfile.showJiraTaskList')}</SelectLabel>
          <Box color="midGray" fontSize="xs">
            {t('userProfile.showJiraTaskListInfo')}
          </Box>
        </Flex>
        <FormikSwitch name="show_jira_tickets" />
      </Flex>
      <HorizontalLine />
      {shouldRenderReportsDeliveryMethod && (
        <>
          <SelectLabel>{t('reportsDeliveryMethods.title')}</SelectLabel>
          <Flex alignItems="center" mb={3}>
            <Box color="midGray" fontSize="xs" width="60px">
              {t('reportsDeliveryMethods.emailName')}
            </Box>
            <FormikSwitch name="notification_setting_attributes.weekly_report_email_enable" />
          </Flex>
          <Flex alignItems="center" mb={6}>
            <Box color="midGray" fontSize="xs" width="60px">
              {t('reportsDeliveryMethods.slackName')}
            </Box>
            <FormikSwitch name="notification_setting_attributes.weekly_report_slack_enable" />
          </Flex>
          <HorizontalLine />
        </>
      )}

      {hasAccessToUpdateNotifications && (
        <>
          <SelectLabel>{t('notificationsSettings.title')}</SelectLabel>
          {shouldRenderGithubFields ? (
            <Flex alignItems="center" mb={3}>
              <Box color="midGray" fontSize="xs" width="60px">
                {t('notificationsSettings.githubName')}
              </Box>
              <FormikSwitch name="notification_setting_attributes.github_enabled" />
            </Flex>
          ) : null}

          <Flex alignItems="center" mb={3}>
            <Box color="midGray" fontSize="xs" width="60px">
              {t('notificationsSettings.jiraName')}
            </Box>
            <FormikSwitch name="notification_setting_attributes.jira_enabled" />
          </Flex>

          <Flex alignItems="center" mb={6}>
            <Box color="midGray" fontSize="xs" width="60px">
              {t('notificationsSettings.togglName')}
            </Box>
            <FormikSwitch name="notification_setting_attributes.toggl_enabled" />
          </Flex>
          <HorizontalLine />
        </>
      )}
      {isOwnerEditing && (
        <>
          <SelectLabel>{t('tfaSettings.title')}</SelectLabel>
          <Flex alignItems="center" mb={6}>
            <Box color="midGray" fontSize="xs" width="60px">
              {t('tfaSettings.shortcut')}
            </Box>
            <FormikSwitch
              name="google_2fa_enabled"
              checked={is2FAChecked}
              onChange={onChange2FA}
            />
          </Flex>
          <HorizontalLine />

          <SelectLabel>{t('userProfile.projectPreferences')}</SelectLabel>
          <Flex alignItems="center" mb={6}>
            <Box color="midGray" fontSize="xs" width="100%">
              <Link href="edit/project-preferences">
                {t('userProfile.projectPreferencesLink')}
              </Link>
            </Box>
          </Flex>
          <HorizontalLine />
        </>
      )}
    </>
  );
};

export default GeneralInfoFields;
