import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { theme } from 'styles/theme';

export const StyledBox = styled(Box)`
  background-color: ${theme.colors.white};
  padding: ${theme.space[2]};
  width: 100%;
  border-radius: ${theme.radii.md};
  margin-bottom: 2px;
`;

export const Title = styled.span`
  font-family: ${theme.fonts.medium};
  margin-right: ${theme.space[2]};
  min-width: 85px;
`;

export const Text = styled.span`
  text-align: right;
`;

export const StyledFlex = styled(StyledBox)`
  display: flex;
  justify-content: space-between;
`;
