import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { clearUserFilters } from 'screens/Users/slices/usersFiltersSlice';
import { useAreUsersFiltered } from 'utils/hooks/useAreUsersFiltered';

import { ClearFilters } from './styled';

export const ClearUserFiltersButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isFiltered, isQuery } = useAreUsersFiltered();

  const clearFilters = () => {
    dispatch(clearUserFilters());
  };

  return (
    <>
      {(isFiltered || isQuery) && (
        <ClearFilters onClick={clearFilters}>
          {t('common.clearAllFilters')}
        </ClearFilters>
      )}
    </>
  );
};
