import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setLoginLoading, setNewPassword } from '../../authSlice';
import { Wrapper } from '../../components/Wrapper';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { Form, Formik } from 'formik';
import { Flex, Text, Box } from 'rebass';
import { SetPasswordFormFields } from 'screens/Auth/components/SetPassword/FormFields';
import { useQuery } from 'utils/hooks/useQuery';

import { GoogleButton } from './styled';
import { validationSchema } from './validationSchema';

const FIRST_TIME_LOGIN_FLAG = 'new_user';

const SetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isNewUser, setIsNewUser] = useState(false);
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('access-token');

    if (isLoggedIn) {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    const isNewUser = query.get(FIRST_TIME_LOGIN_FLAG);

    if (isNewUser) {
      setIsNewUser(true);
    }
  }, [query]);

  const handleSubmit = ({
    password,
    passwordConfirmation,
  }: {
    password: string;
    passwordConfirmation: string;
  }) => {
    const resetPasswordToken = query.get('reset_password_token');

    if (resetPasswordToken) {
      dispatch(
        setNewPassword({
          password,
          password_confirmation: passwordConfirmation,
          reset_password_token: resetPasswordToken,
        }),
      );
    }
  };

  const handleSelectGoogleAccount = () => {
    dispatch(setLoginLoading(true));
    window.open(
      `${process.env.REACT_APP_CRM_API_URL}/auth/google_oauth2`,
      '_self',
    );
  };

  return (
    <Wrapper
      heading={
        isNewUser ? t('login.chooseSignInMethod') : t('login.setNewPassword')
      }
    >
      <Formik
        initialValues={{ password: '', passwordConfirmation: '' }}
        validationSchema={() => validationSchema(t)}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {({ handleSubmit }) => (
          <Form
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <SetPasswordFormFields>
              <NewStyledButton type="submit" label={t('common.continue')} />
            </SetPasswordFormFields>
          </Form>
        )}
      </Formik>

      {isNewUser && (
        <>
          <Flex alignItems="center" justifyContent="space-between" my={6}>
            <Box
              width="200px"
              height="1px"
              backgroundColor="primaryLightGray"
            />
            <Text fontWeight="600" fontSize="md">
              {t('common.or')}
            </Text>
            <Box
              width="200px"
              height="1px"
              backgroundColor="primaryLightGray"
            />
          </Flex>

          <GoogleButton
            onClick={handleSelectGoogleAccount}
            label={t('login.signInGoogle')}
          />
        </>
      )}
    </Wrapper>
  );
};

export default SetPassword;
