import styled from '@emotion/styled/macro';
import { InputSwitch } from 'primereact/inputswitch';
import { theme } from 'styles/theme';

import { SwitchCustomProps } from './Switch';

export const StyledSwitch = styled(InputSwitch)((props: SwitchCustomProps) => {
  const isDark = props.dark;
  return `
  & .p-inputswitch-slider {
  background-color: ${
    isDark ? theme.colors.newDarkGrey : theme.colors.neutralGray
  };
  &:hover {
    background-color: ${
      isDark ? theme.colors.newDarkGrey : theme.colors.neutralGray
    }
  }
}
  `;
});
