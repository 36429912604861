import { useMemo } from 'react';

import { fetcher } from '../fetcher';
import { UserIssue } from 'api/users/models';
import useSWR from 'swr';
import { sortByKey } from 'utils/sortByKey';

interface UserIssues {
  issues: UserIssue[];
}

export const useUserTasks = (id?: string) => {
  const { data } = useSWR<UserIssues>(
    id ? `users/${id}/issues` : null,
    fetcher,
    { revalidateOnFocus: false },
  );

  const sprints = useMemo(
    () =>
      data?.issues.reduce(
        (
          collection: { name: string; id: number; projectId: number }[],
          issue,
        ) => {
          if (!collection.find((i) => i.id === issue.sprint.id)) {
            collection.push({ ...issue.sprint, projectId: issue.project.id });
          }
          return collection;
        },
        [],
      ),
    [data?.issues],
  );

  const projects = useMemo(
    () =>
      data?.issues.reduce(
        (collection: { name: string | null; id: number | null }[], issue) => {
          if (!collection.find((i) => i.id === issue.project.id)) {
            collection.push(issue.project);
          }
          return collection;
        },
        [],
      ),
    [data?.issues],
  );

  return {
    isLoading: !data,
    tasks: data?.issues.sort(sortByKey('project', 'id')),
    sprints: sprints?.sort(sortByKey('name')) || [],
    projects: projects?.sort(sortByKey('name')) || [],
  };
};
