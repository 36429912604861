import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Project } from 'api/projects/models';
import { DeleteButton } from 'components/forms/DeleteButton';
import { DeleteModal } from 'components/forms/DeleteModal';
import { FormHeader } from 'components/primitive';
import { BackLink, Spin } from 'components/ui';
import { AvatarBox } from 'components/ui/AvatarBox';
import { Box, Flex } from 'rebass';
import { ProjectForm } from 'screens/Project/components/Modal/ProjectForm';
import { UserFormWrapper } from 'screens/User/components/styled';
import { removeProject } from 'store/slices/projectsSlice';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

export enum MODAL_TYPES {
  ADD = 'add',
  EDIT = 'edit',
}

export type ModalProps = {
  isLoading?: boolean;
  project?: Project | null;
  projectsName: string[];
  title: string;
  type: MODAL_TYPES;
};

export const Modal: React.FC<ModalProps> = React.memo(
  ({ isLoading, project, projectsName, title, type }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);
    const hasAccessToDelete =
      usePermissions(PERMISSIONS.REMOVE_PROJECT) && type === MODAL_TYPES.EDIT;

    const handleRemoveProject = () => {
      setDeleteModalVisibility(true);
    };

    const handleCloseModal = () => {
      setDeleteModalVisibility(false);
    };

    const handleRemoveModal = () => {
      setDeleteModalVisibility(false);
      dispatch(
        removeProject({
          id: project?.id || -1,
          onSuccess: () => history.replace(`/projects`),
        }),
      );
    };

    return (
      <section>
        <BackLink />
        <UserFormWrapper>
          <FormHeader>{title}</FormHeader>
          {isLoading ? (
            <Spin />
          ) : (
            <>
              {type === MODAL_TYPES.EDIT && hasAccessToDelete && (
                <DeleteButton
                  onClick={handleRemoveProject}
                  title="Delete project"
                />
              )}
              <ProjectForm
                project={project}
                projectsName={projectsName}
                type={type}
              />
            </>
          )}
        </UserFormWrapper>
        {hasAccessToDelete && (
          <DeleteModal
            content={
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <AvatarBox name={project?.name || ''} />
                <Box as="p" marginBottom="57px">
                  Are you sure you want to delete this project?
                </Box>
              </Flex>
            }
            headerTitle="Remove project"
            isOpen={isDeleteModalVisible}
            onClose={handleCloseModal}
            onRemove={handleRemoveModal}
          />
        )}
      </section>
    );
  },
);
