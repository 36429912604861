import React from 'react';
import { useTable, useFlexLayout, useSortBy, Column } from 'react-table';

import {
  TableWrapper,
  TableHeaderColumn,
  TableHeaderColumnSortbox,
  SimpleTableRowItem,
  SimpleTableRow,
} from '../styled';
import { User } from 'api/users/models';
import { SortDownIcon, SortUpIcon } from 'components/ui';
import { Box, SxProps } from 'rebass';
import { TableBodyWrapper } from 'screens/AvailableResources/styled';
import { theme } from 'styles/theme';

export enum Variant {
  AVAILABLE_RESOURCES = 'availableResources',
  DEFAULT = 'default',
  DEVELOPER_DASHBOARD = 'developerDashboard',
  PANEL_DASHBOARD = 'panelDashboard',
  PHASES = 'phases',
  GRAY_HEADER = 'grayHeader',
  TRANSPARENT_HEADER = 'transparentHeader',
}

type DefaultSx = SxProps & { borderSpacing: number };

type Props = {
  data: User[];
  columns: Column<User>[];
  variant?: Variant;
  sx?: DefaultSx;
};

const NAME_DEFAULT_COPY = 'No position';

const sortAlphabetically = (
  { values: valuesA }: any,
  { values: valuesB }: any,
  columnName: string,
) => {
  const nameA = valuesA[columnName] || NAME_DEFAULT_COPY;
  const nameB = valuesB[columnName] || NAME_DEFAULT_COPY;

  if (nameA === nameB && columnName === 'position') {
    const userNameA = valuesA['user_name'];
    const userNameB = valuesB['user_name'];

    if (!userNameA || !userNameB) {
      return 0;
    }

    return userNameA.localeCompare(userNameB, 'pl', {
      sensitivity: 'base',
    });
  }

  if (nameA === NAME_DEFAULT_COPY) {
    return 1;
  }

  if (nameB === NAME_DEFAULT_COPY) {
    return -1;
  }

  return nameA.localeCompare(nameB, 'pl', {
    sensitivity: 'base',
  });
};

export const SimpleUsersTable: React.FC<Props> = ({
  columns,
  data,
  variant = Variant.DEFAULT,
  sx,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        //@ts-ignore
        sortBy: [{ id: 'user_name', desc: false }],
      },
      //@ts-ignore
      disableSortRemove: true,
      sortTypes: {
        alphanumeric: sortAlphabetically,
      },
    },
    useFlexLayout,
    useSortBy,
  );

  return (
    <TableWrapper>
      <Box as="table" {...getTableProps()} sx={{ ...sx }} width="100%">
        <TableBodyWrapper as="tbody" {...getTableBodyProps()}>
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              right: 0,
              zIndex: theme.zIndices['5'],
            }}
          >
            {headerGroups.map((headerGroup) => (
              <Box
                as="tr"
                backgroundColor={theme.colors.white}
                sx={{
                  borderBottom: `1px solid ${theme.colors.neutralGray}`,
                }}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((
                  column: any, // Can't get sorting hook props without any
                ) => (
                  <TableHeaderColumn
                    as="th"
                    variant={variant}
                    canSort={column.canSort}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    // @ts-ignore
                    flex={column.flex}
                  >
                    {column.render('Header')}
                    <TableHeaderColumnSortbox isSorted={column.isSorted}>
                      {column.canSort && column.isSortedDesc === true && (
                        <SortDownIcon stroke={theme.colors.primary} />
                      )}
                      {column.canSort && column.isSortedDesc === false && (
                        <SortUpIcon stroke={theme.colors.primary} />
                      )}
                      {column.canSort && column.isSortedDesc === undefined && (
                        <SortUpIcon />
                      )}
                    </TableHeaderColumnSortbox>
                  </TableHeaderColumn>
                ))}
              </Box>
            ))}
          </Box>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <SimpleTableRow
                as="tr"
                isStaggered
                data-cy={`user-view-table-row-tr-${index}`}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <SimpleTableRowItem
                    variant={variant}
                    as="td"
                    {...cell.getCellProps()}
                    // @ts-ignore
                    flex={cell.column.flex}
                  >
                    {cell.render('Cell')}
                  </SimpleTableRowItem>
                ))}
              </SimpleTableRow>
            );
          })}
        </TableBodyWrapper>
      </Box>
    </TableWrapper>
  );
};
