import styled from '@emotion/styled/macro';
import { Button } from 'primereact/button';
import { theme } from 'styles/theme';

export const StyledTextButton = styled(Button)`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : theme.fontSizes.xs)};
  color: ${theme.colors.veryDarkGray};

  & .pi {
    font-size: ${theme.fontSizes.xs};
  }
`;

export const StyledListTextButton = styled(StyledTextButton)`
  &.p-button {
    border: none;
    padding: 10px ${theme.space[8]};
  }

  &.p-button-secondary.p-button-text:enabled {
    &:active,
    &:focus {
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }
`;
