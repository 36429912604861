import React, { useState, useEffect } from 'react';

import { Error, Wrapper } from '../styled';
import { PrimeSelect, DropdownCustomProps } from 'components/prime';
import { useField } from 'formik';
import { DropdownProps } from 'primereact/dropdown';

type Props = DropdownProps &
  DropdownCustomProps & {
    name: string;
    initialValue?: string | number;
    validate?: (value: any) => undefined | string | Promise<any>;
  };

export const FormikSelect = ({
  name,
  onChange: customOnChange,
  onBlur: customOnBlur,
  initialValue,
  variant = 'filled',
  isHigh = true,
  validate,
  ...restProps
}: Props) => {
  const [, meta, helpers] = useField({ name, validate });
  const { setTouched, setValue: setFieldValue } = helpers;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (!initialValue) {
      setValue(undefined);
    }
  }, [initialValue]);

  return (
    <Wrapper>
      <PrimeSelect
        {...restProps}
        name={name}
        onChange={(event: {
          originalEvent: Event;
          value: any;
          target: { name: string; id: string; value: any };
        }) => {
          setTouched(true);
          customOnChange && customOnChange(event);
          setValue(event.target.value ? event.target.value : null);
          setFieldValue(event.target.value ? event.target.value : null);
        }}
        onBlur={(event: Event) => {
          setTouched(true);
          customOnBlur && customOnBlur(event);
        }}
        isHigh={isHigh}
        iserror={meta.touched ? meta.error : ''}
        value={value}
        variant={variant}
      />
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </Wrapper>
  );
};
