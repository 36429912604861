import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type TooltipContentProps = {
  isVisible: boolean;
};

export const TooltipContent = styled.div<TooltipContentProps>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  display: flex;
  max-width: 240px;
  padding: 4px 8px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  border-radius: ${theme.radii.sm};
  background-color: ${theme.colors.darkGray};
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.xs};
  line-height: ${theme.lineHeights.base};
  pointer-events: none;
`;
