import styled from '@emotion/styled/macro';
import { MODAL_TYPES, ModalType } from 'components/ui/Modal';
import { theme } from 'styles/theme';

type Props = {
  size?: string;
  modalType?: ModalType;
};

export const ModalOverlay = styled.div<Props>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: ${theme.zIndices.max};
  background-color: ${({ modalType }) => {
    if (modalType === 'confirm') {
      return theme.colors.sideModalOverlayDark;
    }
    return theme.colors.sideModalOverlay;
  }};
`;

export const ModalContent = styled.aside<Props>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${theme.colors.white};
  position: absolute;
  border-radius: ${({ modalType }) =>
    modalType === MODAL_TYPES.SIDE ? theme.radii.xl : theme.radii.xmd};
  box-shadow: ${theme.shadows.modalShadow};
  padding: ${({ modalType }) => {
    if (modalType === MODAL_TYPES.SIDE) {
      return '1.87rem 1.25rem';
    }

    return '1.1rem 1.25rem 1.25rem 1.25rem';
  }};

  min-width: ${({ size }) => {
    if (size === 'expansible') {
      return '700px';
    }
  }};

  max-width: ${({ size }) => {
    if (size === 'sm') {
      return '500px';
    }
    if (size === 'md') {
      return '600px';
    }
    if (size === 'lg') {
      return '700px';
    }
    if (size === 'expansible') {
      return `max-content`;
    }
    return '780px';
  }};

  height: ${({ modalType }) => {
    if (modalType === 'confirm') {
      return 'auto';
    }
    return '97%';
  }};

  top: ${({ modalType }) => {
    if (modalType === 'confirm') {
      return '50%';
    }
    return '10px';
  }};

  right: ${({ modalType }) => {
    if (modalType === 'confirm') {
      return '50%';
    }
    return '10px';
  }};

  margin-left: ${({ modalType }) => {
    if (modalType === 'confirm') {
      return 'unset';
    }
    return '10px';
  }};

  transform: ${({ modalType }) => {
    if (modalType === 'confirm') {
      return 'translate(50%, -50%)';
    }
    return 'translate(0)';
  }};
`;

export const ModalCloseButton = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.9rem;
  height: 1.9rem;
  position: relative;
  z-index: 2;
  border: 1px solid ${theme.colors.neutral};
  border-radius: ${theme.radii.xmd};
  color: ${theme.colors.midGray};
  transition: box-shadow 150ms, border-color 150ms;

  &:focus {
    outline: none;
    box-shadow: ${theme.shadows.outline};
    border-color: transparent;
  }
`;

export const ModalHeader = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.darkGray};
  font-size: ${theme.fontSizes.xmd};
`;

export const ModalFooter = styled.div<Props>`
  display: flex;
  margin-top: auto;
  padding: 0 ${({ modalType }) => (modalType === MODAL_TYPES.SIDE ? '10px' : 0)};
  background: ${theme.colors.white};
  justify-content: ${({ modalType }) => {
    if (modalType === 'confirm') {
      return 'flex-end';
    }
    return 'space-between';
  }};
`;
