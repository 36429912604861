import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Cell, useFlexLayout, useTable } from 'react-table';

import { ACCESSORS } from '../Projects';
import { TableItem } from '../Projects/styled';
import { ProjectsUsers } from '../ProjectsUsers';
import {
  Avatar,
  TableRow,
  TableSubCell,
  TableSubRow,
  TableToggleButton,
} from 'components/ui';
import { HEADER_STYLE, TableHeader } from 'components/ui/Table/TableHeader';
import { format } from 'date-fns';
import { Flex, Box } from 'rebass';
import {
  selectDashboardExpandedRows,
  ROW_PREFIXES,
  setExpandedRow,
} from 'store/slices/dashboardSlice';

type Props = {
  data: any[];
  projectId: number;
};

export const ProjectsSprints: React.FC<Props> = ({ data, projectId }) => {
  const dispatch = useDispatch();
  const expandedRows = useSelector(selectDashboardExpandedRows);
  const { t } = useTranslation();

  const tableColumns = useMemo(
    () => [
      {
        accessor: ACCESSORS.SPRINT,
        Cell: ({
          row: { original },
        }: Cell<{
          [ACCESSORS.SPRINT]: string;
          [ACCESSORS.START_DATE]: string;
          [ACCESSORS.END_DATE]: string;
        }>) => {
          const { sprint, start_date, end_date } = original;

          return (
            <Flex alignItems="center">
              <Avatar name={sprint} size="sm" src="" />
              <Box pl={2}>
                {`${sprint || 'No name'}
                 (${format(new Date(start_date), 'dd.MM.yyyy')} - ${format(
                  new Date(end_date),
                  'dd.MM.yyyy',
                )})`}
              </Box>
            </Flex>
          );
        },
        Header: t('dashboard.sprintNameAndDuration'),
        id: ACCESSORS.SPRINT,
        width: 300,
        minWidth: 300,
        maxWidth: 300,
      },
      {
        accessor: ACCESSORS.TEAM_CAPACITY,
        Header: t('dashboard.teamCapacityInHrs'),
        id: ACCESSORS.TEAM_CAPACITY,
        width: 150,
      },
      {
        accessor: ACCESSORS.ESTIMATES,
        Header: t('dashboard.estimatesInHrs'),
        id: ACCESSORS.ESTIMATES,
        width: 150,
      },
      {
        accessor: ACCESSORS.SPENT_NON_EFFECTIVE,
        Header: t('dashboard.spentInHrs'),
        id: ACCESSORS.SPENT_NON_EFFECTIVE,
        width: 170,
      },
      {
        accessor: ACCESSORS.REMAINING,
        Header: t('dashboard.remainingInHrs'),
        id: ACCESSORS.REMAINING,
        width: 150,
      },
      {
        accessor: ACCESSORS.DELIVERED_TO_QA,
        Header: t('dashboard.deliveredToQaInPercent'),
        id: ACCESSORS.DELIVERED_TO_QA,
        width: 140,
      },
      {
        accessor: ACCESSORS.ACCEPTED_BY_QA,
        Header: t('dashboard.acceptedByQaInPercent'),
        id: ACCESSORS.ACCEPTED_BY_QA,
        width: 130,
      },
      {
        accessor: ACCESSORS.ABWH_FOR_CR,
        Header: t('dashboard.abwhForCrInHrs'),
        id: ACCESSORS.ABWH_FOR_CR,
        width: 120,
      },
      {
        accessor: ACCESSORS.ABWH_FOR_QA,
        Header: t('dashboard.abwhForQaInHrs'),
        id: ACCESSORS.ABWH_FOR_QA,
        width: 120,
      },
      {
        accessor: ACCESSORS.TICKETS_REJECTION,
        Header: t('dashboard.amountOfTicketsRejection'),
        id: ACCESSORS.TICKETS_REJECTION,
        width: 170,
      },
      {
        accessor: ACCESSORS.BUGS,
        Header: t('dashboard.numberOfTicketsAddedAfterSprintStart'),
        id: ACCESSORS.BUGS,
        width: 185,
      },
      {
        accessor: ACCESSORS.USERS,
        Header: '',
        id: ACCESSORS.USERS,
        width: 32,
      },
    ],
    [],
  );
  const tableData = useMemo(
    () =>
      data.map((sprint) => ({
        [ACCESSORS.ID]: sprint.id,
        [ACCESSORS.SPRINT]: sprint.name,
        [ACCESSORS.TEAM_CAPACITY]: sprint.team_capacity,
        [ACCESSORS.ESTIMATES]: sprint.estimation_sum,
        [ACCESSORS.SPENT_NON_EFFECTIVE]: `${sprint.spent_sum} (${sprint.non_effective_sum})`,
        [ACCESSORS.REMAINING]: sprint.remaining,
        [ACCESSORS.DELIVERED_TO_QA]: sprint.delivered_percentage,
        [ACCESSORS.ACCEPTED_BY_QA]: sprint.accepted_by_qa_percentage,
        [ACCESSORS.ABWH_FOR_CR]: sprint.average_cr_to_qa_time,
        [ACCESSORS.ABWH_FOR_QA]: sprint.average_qa_reaction_time,
        [ACCESSORS.TICKETS_REJECTION]: sprint.rejects_count,
        [ACCESSORS.BUGS]: `${sprint.added_after_start_count} (${sprint.added_bugs_after_start_count})`,
        [ACCESSORS.USERS]: sprint.users,
        [ACCESSORS.START_DATE]: sprint.start_date,
        [ACCESSORS.END_DATE]: sprint.end_date,
      })),
    [data],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      // @ts-ignore
      columns: tableColumns,
      data: [...tableData],
    },
    useFlexLayout,
  );

  const handleRowClick = useCallback(
    (rowId: string) => {
      dispatch(setExpandedRow(rowId));
    },
    [dispatch],
  );

  return (
    <TableSubRow ignoreHidingLines>
      <section {...getTableProps()}>
        <TableHeader
          headerGroups={headerGroups}
          style={HEADER_STYLE.MINOR}
          headerColumnStyles={{ flex: 1, width: '200px' }}
          lastCellShorter
        />
        <ul {...getTableBodyProps()}>
          {rows.map((row) => {
            const expanderId = `${ROW_PREFIXES.PROJECT}_${projectId}-${ROW_PREFIXES.SPRINT}_${row.original.id}`;
            const isExpanded = !!expandedRows[expanderId];
            const isExpandable = row.original[ACCESSORS.USERS].length > 0;

            prepareRow(row);

            return (
              <TableItem
                {...row.getRowProps()}
                as="li"
                values={row.values}
                even
              >
                <TableRow
                  isExpanded={isExpanded}
                  onClick={
                    isExpandable ? () => handleRowClick(expanderId) : undefined
                  }
                >
                  {row.cells.map(({ column: { id }, getCellProps, render }) => (
                    <TableSubCell
                      {...getCellProps({
                        style: {
                          flex: '1',
                        },
                      })}
                      lastCellShorter
                    >
                      {id !== ACCESSORS.USERS && render('Cell')}
                    </TableSubCell>
                  ))}
                  {isExpandable && (
                    <TableToggleButton
                      id={expanderId}
                      isExpanded={isExpanded}
                    />
                  )}
                </TableRow>
                {isExpanded && <ProjectsUsers data={row.values.users} />}
              </TableItem>
            );
          })}
        </ul>
      </section>
    </TableSubRow>
  );
};
