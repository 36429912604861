import { Row } from 'react-table';

import {
  PhaseUserRange,
  Project,
  ProjectPhaseMember,
  Sprint,
} from 'api/projects/models';
import { Roles, User, UserLoginData } from 'api/users/models';
import endOfISOWeek from 'date-fns/endOfISOWeek';
import format from 'date-fns/format';
import getISOWeek from 'date-fns/getISOWeek';
import isBefore from 'date-fns/isAfter';
import startOfISOWeek from 'date-fns/startOfISOWeek';

import { hasUserAccessToProjects } from './permissions';

export enum USER_VISIBILITY_ACTION_TYPES {
  HIDE = 'hide',
  UNHIDE = 'unhide',
}

export function getWeekData(date: Date) {
  return {
    start: startOfISOWeek(date),
    end: endOfISOWeek(date),
    weekNumber: getISOWeek(date),
  };
}

export function getStringifiedWeekData(date: Date) {
  return {
    start: format(startOfISOWeek(date), 'yyyy-MM-dd'),
    end: format(endOfISOWeek(date), 'yyyy-MM-dd'),
    weekNumber: getISOWeek(date),
    holidays: [],
  };
}

export const usersCompareForSort = (
  a: Partial<User | ProjectPhaseMember>,
  b: Partial<User | ProjectPhaseMember>,
): number => {
  if (a.full_name && b.full_name) {
    return a.full_name
      .toLowerCase()
      .localeCompare(b.full_name, undefined, { numeric: true });
  }
  if (a.email && b.email) {
    return a.email
      .toLowerCase()
      .localeCompare(b.email, undefined, { numeric: true });
  }
  return 0;
};

export const tableUsersSortType = (row1: Row<User>, row2: Row<User>) => {
  const user1 = row1.original;
  const user2 = row2.original;

  return usersCompareForSort(user1, user2);
};

export const getErrorMesssage = (rubyErrors: { errors: object[] }) => {
  return Object.entries(rubyErrors)
    .map(
      (entry) => `${entry[0][0].toUpperCase() + entry[0].slice(1)} ${entry[1]}`,
    )
    .join(',');
};

export function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function parseUserRoles(roles: Roles[] | undefined): string[] {
  if (roles) {
    const mapOfTypes: Record<string, string> = {
      user: 'User',
      admin: 'Admin',
      resource_planner: 'Resource Planner',
      team_leader: 'Team Leader',
      product_manager: 'PM',
      financial_administrator: 'Financial Administrator',
    };

    const sortStrings = (a: string, b: string) => {
      if (a > b) {
        return 1;
      }
      return -1;
    };

    return Array.from(roles)
      .map((type: string) => mapOfTypes[type])
      .sort(sortStrings)
      .filter((role) => role !== 'User');
  }

  return [];
}

export const formatMinutesToHours = (min: number) => {
  const hours = Math.floor(Math.abs(min) / 60);
  const minutes = Math.round(Math.abs(min) % 60);

  if (min < 0) {
    return '-' + hours + 'h ' + minutes + 'min';
  }
  return hours + 'h ' + minutes + 'min';
};

export const formatMinutes = (min: number) => {
  const hours = Math.floor(min / 60);
  const minutes = Math.round(min % 60);

  if (minutes === 0) {
    return hours;
  }
  if (minutes.toString().length < 2) {
    return hours + ':0' + minutes;
  }
  return hours + ':' + minutes;
};

export const sortByCreationDate = (
  t0: { created_at: string },
  t1: { created_at: string },
) => Number(new Date(t0.created_at)) - Number(new Date(t1.created_at));

export const sortSprintsByNewest = (a: Sprint, b: Sprint) =>
  isBefore(new Date(b.start_date), new Date(a.start_date)) ? 1 : -1;

export const isOnlyRoleUser = (user: UserLoginData): boolean =>
  user.roles.length === 1 && user.roles[0].name === 'user';

export const getRedirectRoute = (user: UserLoginData) => {
  if (user.google_2fa_enabled) {
    return '/projects';
  }
  if (user.sign_in_count === 1) {
    return `/users/${user.id}/edit`;
  }

  return hasUserAccessToProjects(user.permissions) && !isOnlyRoleUser(user)
    ? '/projects'
    : '/users';
};

export const getProjectsAfterToggleUserVisibilityAction = ({
  actionType,
  phaseId,
  phaseUserRange,
  projectId,
  projects,
  userId,
}: {
  actionType: USER_VISIBILITY_ACTION_TYPES;
  phaseId: number;
  phaseUserRange: PhaseUserRange;
  projectId: number;
  projects: Project[];
  userId: number;
}) => {
  const shouldReplaceField = (hiddenAt?: string) =>
    actionType === USER_VISIBILITY_ACTION_TYPES.UNHIDE ? !!hiddenAt : !hiddenAt;

  return projects.map((project) => {
    return project.id === projectId
      ? {
          ...project,
          phases: project.phases.map((phase) =>
            phase.id === phaseId
              ? {
                  ...phase,
                  users: phase.users.map((user) =>
                    user.id === userId &&
                    shouldReplaceField(user.phase_user_range.hidden_at)
                      ? {
                          ...user,
                          phase_user_range: {
                            ...phaseUserRange,
                          },
                        }
                      : user,
                  ),
                }
              : phase,
          ),
        }
      : project;
  });
};

export const trimStringToLength = (str: string, length = 135) => {
  if (str.length < length) {
    return str;
  }

  return `${str.substring(0, length)}...`;
};

export const confirmPageRefresh = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event: BeforeUnloadEvent) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

export const removeConfirmationOnPageRefresh = () => {
  window.onbeforeunload = (event: BeforeUnloadEvent) => {};
};
