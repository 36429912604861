import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type Props = {
  isChecked: boolean;
};

export const UserStatus = styled.div<Props>`
  position: absolute;
  width: ${theme.sizes.xs};
  height: ${theme.sizes.xs};
  border: 1px solid ${theme.colors.white};
  background-color: ${({ isChecked }) =>
    isChecked ? theme.colors.lightGreen : theme.colors.lightGrayBlue};
  border-radius: 10rem;
  bottom: -25%;
  right: -25%;
`;
