import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

const { colors, radii, space } = theme;

export type ProgressBarProps = {
  hours: number;
  maxHours?: number;
};

export type WrapperProps = {
  variant?: 'resourcesModal' | 'default';
};

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  width: 100%;
  min-width: ${({ variant }) => {
    if (variant === 'resourcesModal') {
      return '100px';
    }
    return 'max-content';
  }};
  margin-right: ${({ variant }) => {
    if (variant === 'resourcesModal') {
      return space[7];
    }
    return space[4];
  }};
`;

export const ProgressBar = styled.span<ProgressBarProps>`
  background-color: ${colors.neutralLight};
  height: 3px;
  border-radius: ${radii.xs};
  position: relative;
  margin-bottom: 5px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ hours, maxHours }) =>
      !hours ? 0 : hours <= maxHours! ? (hours / maxHours!) * 100 : 100}%;
    background-color: ${({ hours, maxHours }) =>
      hours < maxHours!
        ? colors.primary
        : hours > maxHours!
        ? colors.danger
        : colors.success};
    height: 3px;
    border-radius: ${radii.xs};
  }
`;
