import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  isMarginLeft?: boolean;
  isSeen?: boolean;
};

export const Text = styled(Box)<Props>`
  color: ${theme.colors.midGray};
  height: max-content;
  text-transform: capitalize;
  font-size: ${theme.fontSizes.xxs};
  font-family: ${theme.fonts.regular};
  margin-left: ${({ isMarginLeft }) => (isMarginLeft ? theme.space[2] : '0')};
`;

export const Dot = styled(Box)`
  width: 3px;
  height: 3px;
  background: ${theme.colors.midGray};
  border-radius: 50%;
  display: flex;
  align-self: flex-end;
  margin: ${theme.space[2]};
`;

export const Label = styled(Box)`
  margin-left: ${theme.space[1]};
  font-size: ${theme.fontSizes.xs};
  font-family: ${theme.fonts.medium};
  color: ${theme.colors.midGray};
  padding: ${theme.space[1]} ${theme.space[2]};
  height: 20px;
  border-radius: ${theme.radii.sm};
  width: fit-content;
  display: flex;
  align-items: center;
  background: ${theme.colors.lightGrayBlue};
`;
