import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('errors.invalidEmail'))
      .required(t('errors.required')),
    password: Yup.string().required(t('errors.required')),
  });
