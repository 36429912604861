import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  notificationsAPI,
  NotificationsApiPayload,
  NotificationResolvedPayload,
} from 'api/notifications/endpoints';
import {
  NotificationResponse,
  NotificationType,
} from 'api/notifications/models';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';

type InitialState = {
  notifications: NotificationResponse;
  notificationsResolved: NotificationType[];
  isLoading: boolean;
  error: string | null;
};

const INITIAL_STATE: InitialState = {
  notifications: {
    notifications: [],
    meta: null,
  },
  notificationsResolved: [],
  isLoading: false,
  error: null,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: INITIAL_STATE,
  reducers: {
    getNotificationsStart: (state) => {
      state.isLoading = true;
    },
    getNotificationsSuccess: (state, action: PayloadAction<any>) => {
      state.notifications = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getNotificationsFail: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateNotificationStart(state): void {
      state.isLoading = true;
    },
    updateNotificationSuccess(
      state,
      action: PayloadAction<NotificationType>,
    ): void {
      state.isLoading = false;
      state.error = null;
      state.notifications.notifications = state.notifications.notifications?.map(
        (notification) =>
          (notification =
            notification.id === action.payload.id
              ? action.payload
              : notification),
      );
    },
    updateNotificationFailed(state, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

const {
  getNotificationsStart,
  getNotificationsSuccess,
  getNotificationsFail,
  updateNotificationStart,
  updateNotificationSuccess,
  updateNotificationFailed,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const getNotifications = ({
  startDate,
  endDate,
  periods,
  alertTypes,
  isResolved,
  page,
}: NotificationsApiPayload): AppThunk => async (dispatch) => {
  dispatch(getNotificationsStart());

  try {
    const { data } = await notificationsAPI.getNotifications({
      startDate,
      endDate,
      periods,
      alertTypes,
      isResolved,
      page,
    });

    dispatch(getNotificationsSuccess(data));
  } catch (error) {
    dispatch(
      getNotificationsFail(error?.message || 'Notifications not fetched'),
    );
  }
};

export const updateNotification = (
  id: number,
  payload: NotificationResolvedPayload,
): AppThunk => async (dispatch, getState) => {
  dispatch(updateNotificationStart());

  try {
    const {
      data: { notification },
    } = await notificationsAPI.updateNotification(id, payload);

    const { periods, types, isResolved } = getState().notifications.filters;

    const { data } = await notificationsAPI.getNotifications({
      periods,
      alertTypes: types,
      isResolved,
    });

    dispatch(updateNotificationSuccess(notification));
    dispatch(getNotificationsSuccess(data));
  } catch (error) {
    dispatch(
      updateNotificationFailed(error?.message || 'Notification not updated'),
    );
  }
};

export const bulkUpdateNotifications = (ids: number[]): AppThunk => async (
  dispatch,
  getState,
) => {
  dispatch(updateNotificationStart());

  try {
    const { periods, types, isResolved } = getState().notifications.filters;

    await notificationsAPI.bulkUpdateNotification(ids, !isResolved);

    const { data } = await notificationsAPI.getNotifications({
      periods,
      alertTypes: types,
      isResolved,
    });

    dispatch(getNotificationsSuccess(data));
  } catch (error) {
    dispatch(
      updateNotificationFailed(error?.message || 'Notification not updated'),
    );
  }
};

export const selectNotificationsData = (state: RootState) =>
  state.notifications.data;
