import { FiMail } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';

import styled from '@emotion/styled/macro';
import { PrimeButton } from 'components/prime/Button/Button';
import { Form as FormikForm } from 'formik';
import { theme } from 'styles/theme';

type TextProps = {
  margin?: string;
};

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  padding: 0 ${theme.space[5]} ${theme.space[5]};
  border: 1px solid ${theme.colors.neutral};
  border-radius: ${theme.radii.md};
  background-color: ${theme.colors.white};
`;

export const Text = styled.p<TextProps>`
  color: ${theme.colors.midGray};
  text-align: center;
  margin: ${({ margin }) => margin};
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-top: ${theme.space[5]};
`;

export const MailIcon = styled(FiMail)`
  margin-top: 2px;
`;

export const SubmitButton = styled(PrimeButton)`
  align-self: center;
  width: 90%;
`;

export const Error = styled.p`
  color: ${theme.colors.danger};
  font-size: 1rem;
  margin-top: 0;
  align-self: center;
`;

export const Link = styled(RouterLink)`
  color: ${theme.colors.neutralDark};
`;
