import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addLdapIntegration } from 'fetchers/actions/addLdapIntegration';
import { Formik } from 'formik';
import { displaySnackbar } from 'store/slices/snackbarSlice';

import { validationSchema } from './validationSchema';

type LdapValues = {
  username: string;
  password: string;
  ldap_host: string;
  ldap_base: string;
  ldap_port: number | undefined;
};

type LdapFormikProps = {
  onSubmitCustom?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
};

export const LdapFormik: React.FC<LdapFormikProps> = ({
  onSuccess,
  onFailure,
  children,
} = {}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = async (values: LdapValues) => {
    addLdapIntegration(values)
      .then(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationSuccess'),
          }),
        );
        onSuccess?.();
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationFail'),
            isError: true,
          }),
        );
        onFailure?.();
      });
  };

  const defaults: LdapValues = {
    username: '',
    password: '',
    ldap_host: '',
    ldap_base: '',
    ldap_port: undefined,
  };

  return (
    <Formik<LdapValues>
      initialValues={defaults}
      validationSchema={() => validationSchema(t)}
      onSubmit={onSubmit}
    >
      {children}
    </Formik>
  );
};
