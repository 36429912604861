import React, { useEffect, useRef, useState } from 'react';

import { HoursInput } from './styled';

type Props = {
  defaultValue?: number;
  disabled?: boolean;
  isMemberConfirmed?: boolean;
  maxHours?: number;
  onEditFinish?: (changedValue: string) => void;
  border?: boolean;
  centered?: boolean;
};

export const HoursTextField: React.FC<
  React.HTMLProps<HTMLInputElement> & Props
> = ({
  defaultValue,
  disabled,
  isMemberConfirmed,
  maxHours = 99,
  onEditFinish,
  centered = false,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fieldValue, setFieldValue] = useState(String(defaultValue));
  let savedValue = String(defaultValue);

  useEffect(() => {
    setFieldValue(String(defaultValue));
  }, [defaultValue]);

  const handleFocus = () => {
    savedValue = fieldValue;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Escape':
        setFieldValue(savedValue);
        inputRef.current?.blur();
        break;
      case 'Enter':
        inputRef.current?.blur();
        break;
      // no-default
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      event.currentTarget.value !== ''
        ? parseInt(event.currentTarget.value, 10)
        : 0;

    if (value <= maxHours) {
      setFieldValue(String(value));
    }
  };

  const handleBlur = () => {
    if (savedValue !== fieldValue && onEditFinish) {
      onEditFinish(fieldValue);
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    inputRef.current?.select();
  };

  return (
    <HoursInput
      {...props}
      disabled={disabled}
      isMemberConfirmed={isMemberConfirmed}
      max={maxHours}
      onBlur={handleBlur}
      onClick={handleClick}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      ref={inputRef}
      type="number"
      value={fieldValue}
      centered={centered}
    />
  );
};
