import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectProjectsFiltersData } from 'screens/Projects/projectsFiltersSlice';

export const useAreProjectsFiltered = () => {
  const {
    projectsId,
    personsResponsible,
    members,
    isActive,
    projectTypes,
    teamsIds,
    phaseTypesIds,
  } = useSelector(selectProjectsFiltersData);

  const filtersCount = useMemo(() => {
    return [
      projectsId.length > 0,
      personsResponsible.length > 0,
      members.length > 0,
      projectTypes.length > 0,
      teamsIds.length > 0,
      phaseTypesIds.length > 0,
    ].filter((f) => f);
  }, [
    projectsId,
    personsResponsible,
    members,
    projectTypes,
    teamsIds,
    phaseTypesIds,
  ]);

  return {
    isFiltered: filtersCount.length > 0 || !isActive,
    filtersCount: filtersCount.length,
  };
};
