import styled from '@emotion/styled/macro';
import { Box, Flex } from 'rebass';
import { theme } from 'styles/theme';

type ProjectPhasePersonButtonWrapperProps = {
  isDisabled?: boolean;
};

export const ActionButtonsBox = styled(Box)`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  display: flex;
  margin-left: ${theme.space[3]};
  transition: opacity 150ms;
  background-color: transparent;
  border-radius: ${theme.radii.xxl};
  transform: translateX(100%);

  & button:first-of-type {
    margin-left: ${theme.space[4]};
  }
`;

export const UserPosition = styled(Flex)`
  align-items: flex-start;
  margin-right: ${theme.space[2]};
`;

export const HoursWrapper = styled.span`
  width: 100%;
  margin-right: ${theme.space[4]};
`;

export const ProjectPhasePersonButtonWrapper = styled.div<ProjectPhasePersonButtonWrapperProps>`
  text-align: left;
  display: flex;
  align-items: center;
  border-radius: ${theme.radii.mlg};
  padding: ${theme.space[1]} ${theme.space[3]};
  margin-left: ${theme.space['-3']};
  outline: none;
  cursor: pointer;
  width: fit-content;

  &:hover,
  &:focus {
    color: ${({ isDisabled }) =>
      isDisabled ? 'initial' : theme.colors.primary};
    background-color: ${({ isDisabled }) =>
      isDisabled ? 'initial' : theme.colors.lightBlue};
  }
`;
