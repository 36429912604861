import { Title as TitleComponent } from '../AboutProjectView/AboutProjectView.styles';
import { InputSwitch as InputSwitchComponent } from 'primereact/inputswitch';
import styled from 'styled-components';
import { theme } from 'styles/theme';

export const TeamMembersViewContainer = styled.section``;

export const Title = styled(TitleComponent)``;

export const SubTitle = styled.span`
  color: ${theme.colors.lightGray};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: ${theme.colors.lightestGrey};
    th {
      font-family: ${theme.fonts.bold};
      font-weight: bold;
    }
  }

  th,
  td {
    line-height: 1.5;
    text-align: left;
    padding: 0.5rem;
  }
  tbody {
    tr {
      border-bottom: 1px solid ${theme.colors.lightestGrey};
    }
  }
`;

export const AvatarNameCell = styled.td`
  display: flex;
  align-items: center;

  > div {
    margin-right: 1rem;
  }
`;

export const ProgressCell = styled.td`
  padding: 0;
`;

export const List = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li`
  display: inline-block;
  margin-right: ${theme.space[8]};

  &::before {
    content: '';
    display: inline-block;
    height: 10px;
    width: 10px;
    border: 0.5px solid #ffffff;
    border-radius: 100px;
    margin-right: ${theme.space[2]};
  }

  &.active::before {
    background: ${theme.colors.success};
  }

  &.inactive::before {
    background: ${theme.colors.primeLightGrey};
  }

  &.needed::before {
    background: ${theme.colors.warning};
  }
`;

export const InputSwitch = styled(InputSwitchComponent)`
  width: 40px;
  height: 24px;
  margin-right: ${theme.space[2]};

  .p-inputswitch-slider::before {
    width: 16px;
    height: 16px;
    margin-top: -8px;
  }

  &.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(100%);
  }
`;

export const AvatarBox = styled.span`
  display: inline-block;
  position: relative;
  margin-right: ${theme.space[2]};

  img {
    display: block;
  }
`;

export const UserStatus = styled.span`
  position: absolute;
  bottom: ${theme.space['-1']};
  right: ${theme.space['-1']};
  width: 10px;
  height: 10px;
  border-radius: 100px;
  &.active {
    background: ${theme.colors.success};
  }

  &.inactive {
    background: ${theme.colors.primeLightGrey};
  }

  &.needed {
    background: ${theme.colors.warning};
  }
`;
