import { UseTableRowProps } from 'react-table';

import { isDecimalValue } from 'utils/isDecimalValue';

type Item = UseTableRowProps<{}>;

const isNumberValue = (value: string) => value.match(/^[0-9]+$/);
const isHoursValue = (value: string) => value.match(/^-?[0-9]+:[0-5][0-9]$/);
const getMinutes = (value: string) => {
  const splited = value.split(':');
  return (
    parseInt(splited[0]) * 60 +
    parseInt(splited[0].includes('-') ? `-${splited[1]}` : splited[1])
  );
};

const getPreparedValue = (value: string | number) => {
  if (typeof value === 'string') {
    const transformedValue = value?.trim()?.toLowerCase();
    if (transformedValue.includes('(')) {
      return transformedValue.split('(')[0]?.trim();
    }
  }
  return value;
};

const transformStringAndCompare = (
  valueA: string,
  valueB: string,
  transform: (value: string) => number,
): number => {
  return transform(valueA) > transform(valueB) ? 1 : -1;
};

export const DEFAULT_VALUE = 'sortAlphabeticallyDefaultValue';

export const sortAlphabetically = (defaultValue = DEFAULT_VALUE) => (
  { values: valuesA }: Item,
  { values: valuesB }: Item,
  columnName: string,
) => {
  if (valuesA[columnName] === defaultValue) {
    return 1;
  }

  if (valuesB[columnName] === defaultValue) {
    return -1;
  }

  const valueA = getPreparedValue(valuesA[columnName]);
  const valueB = getPreparedValue(valuesB[columnName]);

  if (typeof valueA === 'number' && typeof valueB === 'number') {
    return valueA > valueB ? 1 : -1;
  }

  if (!valueA || !valueB) {
    return 0;
  }

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    if (isDecimalValue(valueA) && isDecimalValue(valueB)) {
      return transformStringAndCompare(valueA, valueB, parseFloat);
    }

    if (isHoursValue(valueA) && isHoursValue(valueB)) {
      return transformStringAndCompare(valueA, valueB, getMinutes);
    }

    if (isNumberValue(valueA) && isNumberValue(valueB)) {
      return transformStringAndCompare(valueA, valueB, parseInt);
    }

    return valueA.localeCompare(valueB, 'pl', {
      sensitivity: 'base',
    });
  }
};
