import React, { useState } from 'react';
import { HeaderGroup } from 'react-table';

import { Variant } from 'components/layout/Tables/SimpleUsersTable';
import { TableHeaderColumn } from 'components/layout/Tables/styled';
import { SortIcons } from 'components/primitive/SortIcons/SortIcons';
import { TableMainHeader, TableSubHeader } from 'components/ui';
import { ACCESSORS } from 'screens/Dashboard/components/Projects';
import { theme } from 'styles/theme';

type Props = {
  headerGroups: any[];
  idToIgnore?: ACCESSORS;
  style?: HEADER_STYLE;
  variant?: Variant;
  headerColumnStyles?: Record<string, string | number>;
  lastCellShorter?: boolean;
};

export enum HEADER_STYLE {
  MAIN = 'main',
  MINOR = 'minor',
}

export const TableHeader: React.FC<Props> = React.memo(
  ({
    headerGroups,
    idToIgnore,
    style,
    variant,
    headerColumnStyles,
    lastCellShorter,
  }) => {
    const [updatesCounter, incrementUpdatesCounter] = useState<number>(0);
    const Header =
      style === HEADER_STYLE.MAIN ? TableMainHeader : TableSubHeader;

    const handleSortClick = (callback?: (event: any) => void) => (
      event: any,
    ) => {
      if (callback) {
        callback(event);
        incrementUpdatesCounter(updatesCounter + 1);
      }
    };

    return (
      <>
        {headerGroups.map(({ getHeaderGroupProps, headers }: HeaderGroup) => (
          <Header
            {...getHeaderGroupProps({ style: { minWidth: 'fit-content' } })}
            variant={variant}
          >
            {headers.map(
              ({
                // @ts-ignore
                canSort,
                getHeaderProps,
                // @ts-ignore
                getSortByToggleProps,
                // @ts-ignore
                isSorted,
                // @ts-ignore
                isSortedDesc,
                id,
                render,
              }) => {
                let headerProps = getHeaderProps(
                  getSortByToggleProps?.({ title: undefined }),
                );

                if (headerColumnStyles) {
                  headerProps = {
                    ...headerProps,
                    style: {
                      ...headerProps.style,
                      ...headerColumnStyles,
                    },
                  };
                }

                return (
                  id !== idToIgnore && (
                    <TableHeaderColumn
                      {...headerProps}
                      variant={variant}
                      canSort={canSort}
                      // @ts-ignore
                      onClick={handleSortClick(headerProps.onClick)}
                      lastCellShorter={lastCellShorter}
                    >
                      {render('Header')}
                      {canSort && (
                        <SortIcons>
                          {canSort && isSortedDesc === true && (
                            <i
                              className="pi pi-sort-amount-down active"
                              color={theme.colors.primary}
                            />
                          )}
                          {canSort && isSortedDesc === false && (
                            <i
                              className="pi pi-sort-amount-up active"
                              color={theme.colors.primary}
                            />
                          )}
                          {canSort && isSortedDesc === undefined && (
                            <i className="pi pi-sort-amount-down" />
                          )}
                        </SortIcons>
                      )}
                    </TableHeaderColumn>
                  )
                );
              },
            )}
          </Header>
        ))}
      </>
    );
  },
);
