import { theme } from '../../../../styles/theme';
import styled from '@emotion/styled/macro';

export const IntegrationTitle = styled.h2`
  padding: ${theme.space[2]} ${theme.space[4]};
  border-radius: ${theme.radii.md};
  font-size: ${theme.fontSizes.md};
  color: ${theme.colors.white};
  margin-bottom: ${theme.space[4]};
  background: rgb(20, 40, 71);
  background: linear-gradient(
    90deg,
    ${theme.colors.primeDarkGrey} 0%,
    #cbd3e0 70%,
    rgba(255, 255, 255, 1) 100%
  );
`;

export const IntegrationWrapper = styled.div`
  margin-bottom: ${theme.space[7]};
`;

export const IntegrationMessage = styled.p`
  margin: 0 0 ${theme.space[2]};
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.space[2]};

  &:hover {
    .go-to-link {
      opacity: 1;
    }
  }
`;

export const IntegrationLink = styled.a`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.darkGray};
  line-height: ${theme.lineHeights.md2};
`;

export const LinkMarker = styled.div`
  margin-right: 16px;
  height: 24px;
  width: 5px;
  border-radius: ${theme.radii.sm};
  background-color: ${theme.colors.primeDarkGrey};
  position: relative;
`;

export const GoToLink = styled.div`
  opacity: 0.7;
  transition: opacity 0.3s;
  border: 1px solid ${theme.colors.primeDarkGrey};
  padding: 1px ${theme.space[2]};
  margin-left: ${theme.space[3]};
  border-radius: ${theme.radii.sm};
  background-color: ${theme.colors.primeDarkGrey};
  color: ${theme.colors.white};
  cursor: pointer;
`;
