import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const OrganizationItem = styled.div`
  border: 1px solid ${theme.colors.primaryInputBorder};
  box-sizing: border-box;
  border-radius: ${theme.radii.sm};
  padding: 0 ${theme.space[3]};
  height: ${theme.dimensions.inputHeightLow};
  display: flex;
  align-items: center;
  font-size: ${theme.fontSizes.xs};
  font-family: ${theme.fonts.regular};
  color: ${theme.colors.veryDarkGray};
`;
