import React from 'react';

import { NFBox, Text } from './styles';

type Props = {
  isTextCentered?: boolean;
  isSmall?: boolean;
};

export const NothingFoundBox: React.FC<Props> = ({
  isTextCentered = false,
  children = 'Nothing found',
  isSmall = false,
}) => (
  <NFBox isTextCentered={isTextCentered} isSmall={isSmall}>
    <Text isTextCentered={isTextCentered} isSmall={isSmall}>
      {children}
    </Text>
  </NFBox>
);
