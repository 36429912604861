import styled from '@emotion/styled/macro';
import { Chip } from 'primereact/chip';
import { theme } from 'styles/theme';

export const StyledChip = styled(Chip)`
  background-color: ${theme.colors.primaryDark};
  color: ${theme.colors.white};
  border-radius: ${theme.radii.sm};
  margin-right: ${theme.space[2]};
  padding: ${theme.space[1]} ${theme.space[2]};

  & .p-chip-text {
    font-size: ${theme.fontSizes.xs};
    line-height: ${theme.fontSizes.xs};
    margin: 0;
  }
`;
