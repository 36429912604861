import { addWeeks } from 'date-fns';
import { findIndexByDay } from 'utils/findIndexByDate';

export function getWeeksRange<T extends { [key: string]: any }>(
  week1: Date,
  week2: Date,
  collection: T[],
  key: keyof T,
) {
  const getWeekIndex = (date: Date) => {
    return findIndexByDay(collection, key, date);
  };
  let weeks;
  if (week1 < week2) {
    weeks = collection.slice(
      getWeekIndex(week1),
      getWeekIndex(addWeeks(week2, 1)),
    );
  } else {
    weeks = collection.slice(getWeekIndex(week2), getWeekIndex(week1));
  }
  return weeks;
}
