import type {
  User,
  UserPayload,
  UserTeam,
  UserSkill,
  UserType,
  UserLoginData,
  UsersWeek,
  TFAData,
  TFAPayload,
  UserPreferences,
} from 'api/users/models';
import {
  ResourcesReportsPayloadConflict,
  ResourcesReportsPayloadFreeResource,
  ResourcesReportsPayloadNeededResource,
} from 'api/users/models';
import { AxiosResponse } from 'axios';
import { formatISO } from 'date-fns';
import FileDownload from 'js-file-download';
import { serialize } from 'object-to-formdata';
import qs from 'qs';
import httpClient from 'utils/httpClient';

type UserRes = {
  user: User;
};

type UsersRes = {
  users: User[];
};

type WeeksRes = {
  weeks: UsersWeek[];
};

type MeRes = {
  user: UserLoginData;
};

type UserSkillsRes = {
  skill_names: UserSkill[];
};

type UserTeamsRes = {
  teams: UserTeam[];
};

type UserTypesRes = {
  user_types: UserType[];
};

type ResourcesReportsRes = {
  conflicts: ResourcesReportsPayloadConflict[];
  free_be_resources: ResourcesReportsPayloadFreeResource[];
  free_fe_resources: ResourcesReportsPayloadFreeResource[];
  next_month_needed_resources: ResourcesReportsPayloadNeededResource[];
};

type QrCodeRes = {
  message: TFAData;
};

export type UsersWeeksPayload = {
  page?: number;
  perPage?: number;
  weeksNumbers: number[];
  withLoading?: boolean;
};

export type UsersReportPayload = {
  phasesIds?: number[];
  usersIds?: number[];
  weeksNumbers?: number[];
  withLoading?: boolean;
};

export type UserPreferencesPayload = {
  app_type: string[];
  description: string;
  project_type: string[];
  team_size: string[];
};
//#endregion

const BASE_URL = 'users';

export const usersAPI = {
  async addUser(user: UserPayload): Promise<AxiosResponse<UserRes>> {
    const options = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = serialize({ user });

    return await httpClient.post(`/${BASE_URL}`, data, options);
  },

  async updateUser(
    id: number,
    user: UserPayload,
  ): Promise<AxiosResponse<UserRes>> {
    const options = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = serialize({ user });

    return await httpClient.put(`/${BASE_URL}/${id}`, data, options);
  },

  async getUserById(id: number): Promise<AxiosResponse<UserRes>> {
    return await httpClient.get(`/${BASE_URL}/${id}`);
  },

  async getUsers(): Promise<AxiosResponse<UsersRes>> {
    return await httpClient.get(`/${BASE_URL}`);
  },

  async getUsersWeeks({
    weeksNumbers,
    page,
    perPage,
  }: UsersWeeksPayload): Promise<AxiosResponse<WeeksRes>> {
    const weeksQuery = `&${weeksNumbers
      .map((week) => `weeks[]=${week}`)
      .join('&')}`;
    return await httpClient.get(
      `/${BASE_URL}/weeks?page=${page}&per=${perPage}${weeksQuery}`,
    );
  },

  async getReport({
    weeksNumbers,
  }: UsersReportPayload): Promise<void | AxiosResponse> {
    const weeksQuery = weeksNumbers
      ? `&${weeksNumbers.map((week) => `weeks[]=${week}`).join('&')}`
      : '';
    return await httpClient
      .get(`/users/weeks.xlsx?${weeksQuery}`, {
        responseType: 'blob',
      })
      .then((response) =>
        FileDownload(response.data, 'users_hours_report.xlsx'),
      );
  },

  async getWeeksReport({
    phasesIds = [],
    usersIds = [],
    weeksNumbers = [],
  }: UsersReportPayload): Promise<void | AxiosResponse> {
    return await httpClient
      .get(`reports/${BASE_URL}/weeks.xlsx`, {
        params: {
          phases_ids: phasesIds,
          users_ids: usersIds,
          weeks: weeksNumbers,
        },
        paramsSerializer: (params) =>
          qs.stringify(params, {
            arrayFormat: 'brackets',
            encodeValuesOnly: true,
          }),
        responseType: 'blob',
      })
      .then((response) =>
        FileDownload(
          response.data,
          `hours-report-${formatISO(new Date()).split('T')[0]}.xlsx`,
        ),
      );
  },

  async getSkills(): Promise<AxiosResponse<UserSkillsRes>> {
    return await httpClient.get(`/skills/names`);
  },

  async getTeams(): Promise<AxiosResponse<UserTeamsRes>> {
    return await httpClient.get(`/teams`);
  },

  async getMe(): Promise<AxiosResponse<MeRes>> {
    return await httpClient.get(`/${BASE_URL}/me`);
  },

  async getUserTypes(): Promise<AxiosResponse<UserTypesRes>> {
    return await httpClient.get(`/user_types`);
  },

  async getResourcesReports(): Promise<AxiosResponse<ResourcesReportsRes>> {
    return await httpClient.get(`/${BASE_URL}/resources_reports`);
  },

  async removeUser(id: number): Promise<AxiosResponse<UserRes>> {
    return await httpClient.delete(`/users/${id}`);
  },

  async getQrCodeForTFA(): Promise<AxiosResponse<QrCodeRes>> {
    return await httpClient.post(`/users/mfa_integrations`);
  },

  async activateTFA(payload: TFAPayload): Promise<AxiosResponse> {
    return await httpClient.post(`/users/mfa_integrations`, payload);
  },
  async deactivateTFA(): Promise<AxiosResponse> {
    return await httpClient.delete(`/users/mfa_integrations`);
  },
  async getPreferences(): Promise<AxiosResponse<UserPreferences>> {
    return await httpClient.get(`/project_preferences`);
  },
  async setPreferences(
    payload: UserPreferencesPayload,
  ): Promise<AxiosResponse> {
    return await httpClient.put(`/project_preferences`, payload);
  },
};
