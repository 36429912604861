import React from 'react';
import { useTranslation } from 'react-i18next';

import { User, UserPayload } from 'api/users/models';
import { NewPrimeButton } from 'components/prime/NewButton/NewButton';
import { Avatar, ProjectPhasePersonLink } from 'components/ui/index';
import { Dialog } from 'primereact/dialog';
import { Box, Flex } from 'rebass';
import { BoldBox } from 'screens/Users/components/listStyles';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  usersBlockingRemoval?: Array<User | UserPayload>;
  itemName:
    | 'Position'
    | 'Team'
    | 'Seniority'
    | 'ProjectType'
    | 'Skill'
    | 'Supervisor';
}

export const CannotRemoveModal = ({
  isOpen,
  setIsOpen,
  usersBlockingRemoval,
  itemName,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      onHide={() => setIsOpen(false)}
      visible={isOpen}
      blockScroll={true}
      header={t(`users.cannotRemove${itemName}`)}
    >
      <Box fontSize="xs" py={4}>
        {t(`users.cannotRemove${itemName}Info`)}
      </Box>
      <Flex mt={4} flexWrap="wrap" maxWidth="500px">
        {usersBlockingRemoval?.map((user: any) => (
          <Box key={user.id} p={2}>
            <ProjectPhasePersonLink href={`/users/${user.id}`}>
              <Flex alignItems="center">
                <Avatar
                  src={user.photo?.mini?.url}
                  size="small"
                  name={user.full_name || user.name || ''}
                  radius="xmd"
                />
                <BoldBox as="span" ml={3} fontSize="xs">
                  {user.full_name || user.name || ''}
                </BoldBox>
              </Flex>
            </ProjectPhasePersonLink>
          </Box>
        ))}
      </Flex>
      <Flex justifyContent="flex-end">
        <NewPrimeButton
          onClick={() => setIsOpen(false)}
          label={t('common.close')}
        />
      </Flex>
    </Dialog>
  );
};
