import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import debounce from 'lodash.debounce';
import { Box, Flex } from 'rebass';
import UserSideControls from 'screens/Users/components/FiltersButton';
import {
  clearUserFilters,
  selectUsersFiltersData,
  setFilter,
  UserFilters,
} from 'screens/Users/slices/usersFiltersSlice';

import { UserList } from './components/UserList/UserList';
import { ListHeaderWrapper, ListSearchField } from './components/listStyles';
import { UsersList } from './styled';

const Users: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { query } = useSelector(selectUsersFiltersData);

  useEffect(() => {
    dispatch(setFilter(UserFilters.QUERY, ''));

    return () => {
      dispatch(clearUserFilters());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!query) {
      setSearchQuery('');
    }
  }, [query]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = useCallback(
    debounce((query: string) => {
      dispatch(setFilter(UserFilters.QUERY, query));
    }, 300),
    [],
  );

  return (
    <>
      <UsersList>
        <ListHeaderWrapper alignItems="center">
          <Flex justifyContent="space-between" width="100%">
            <Box className="p-input-icon-left" ml={2} flex={1}>
              <i className="pi pi-search" />
              <ListSearchField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  setSearchQuery(value);
                  handleUpdate(value);
                }}
                value={searchQuery}
                placeholder={t('common.search')}
              />
            </Box>
            <UserSideControls />
          </Flex>
        </ListHeaderWrapper>
        <UserList />
      </UsersList>
    </>
  );
};

export default Users;
