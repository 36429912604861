import styled from '@emotion/styled/macro';
import { Tag } from 'primereact/tag';
import { theme } from 'styles/theme';

const sizes: Record<string, Record<string, string>> = {
  big: {
    wrapper: '128px',
    image: '90%',
    font: theme.fontSizes.xxl,
  },
  medium: {
    wrapper: '48px',
    image: '44px',
    font: theme.fontSizes.lg,
  },
  small: {
    wrapper: '32px',
    image: '90%',
    font: theme.fontSizes.sm,
  },
};

export const StyledAvatar = styled.div<{
  size: string;
  radius?: string;
  bgColor?: string;
}>`
  border-radius: ${({ radius }) => radius || '100%'};
  width: ${({ size }) => sizes[size].wrapper};
  min-width: ${({ size }) => sizes[size].wrapper};
  height: ${({ size }) => sizes[size].wrapper};
  background: ${({ bgColor }) => bgColor || theme.colors.primaryLightGray};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Image = styled.img<{ radius?: string; size: string }>`
  width: ${({ size }) => sizes[size].image};
  height: ${({ size }) => sizes[size].image};
  border-radius: ${({ radius }) => radius || '100%'};
  object-fit: cover;
`;

export const Initials = styled.p<{ size: string }>`
  font-size: ${({ size }) => sizes[size].font};
`;

export const StyledTag = styled(Tag)`
  min-width: 135px;
  max-width: 300px;
  background: ${theme.colors.zumthor};
  color: ${theme.colors.primary};
  border-radius: ${theme.radii.mlg};
  font-size: ${theme.fontSizes.xs};
  font-weight: normal;
  text-align: center;
  padding: 0.25rem 0.4rem;
  width: max-content;
`;
