import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectAuthUser } from '../Auth/authSlice';
import { Roles } from 'api/users/models';
import { ProjectsWithoutPhasesTable } from 'components/layout/Tables/ProjectsWithoutPhasesTable';
import { PhasesTable } from 'components/ui/Phases/components/PhasesTable';
import { ProjectWithPhase, useProjects } from 'fetchers';
import { usePersonsResponsible } from 'fetchers/hooks/usePersonsResponsible';
import { useProjectsWithoutPhases } from 'fetchers/hooks/useProjectsWithoutPhases';
import { setLoading } from 'store/slices/loadingSlice';
import { initialSortKey } from 'utils/constants';
import { getFromStorage, saveToStorage } from 'utils/localStorage';

import { ProjectFilters, setProjectFilter } from './projectsFiltersSlice';
import { SectionHeader, TableWrapper, Text } from './styled';

export const Projects: React.FC = React.memo(() => {
  const [projectList, setProjectList] = useState<ProjectWithPhase[]>();
  const dispatch = useDispatch();
  const { isLoading, projects } = useProjects();

  const {
    isLoading: isLoadingProjectsWihoutPhases,
    projectsWithoutPhases,
  } = useProjectsWithoutPhases();

  const {
    personsResponsible,
    isLoading: arePersonsLoading,
  } = usePersonsResponsible();
  const loggedUser = useSelector(selectAuthUser);
  const hasBeenSorted = getFromStorage(initialSortKey);

  const { t } = useTranslation();
  useEffect(() => {
    if (isLoading || isLoadingProjectsWihoutPhases) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
      setProjectList(projects);
    }
  }, [isLoading, isLoadingProjectsWihoutPhases, projects, dispatch]);

  useEffect(
    () => () => {
      dispatch(setLoading(false));
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      loggedUser &&
      !hasBeenSorted &&
      !arePersonsLoading &&
      personsResponsible
    ) {
      saveToStorage(initialSortKey, 'true');
      const isResponsibleUser = personsResponsible?.find(
        (u) => u.id === loggedUser.id,
      );
      if (isResponsibleUser) {
        dispatch(
          setProjectFilter(ProjectFilters.PERSONS_RESPONSIBLE, [loggedUser.id]),
        );
      }
      if (!isResponsibleUser) {
        dispatch(setProjectFilter(ProjectFilters.MEMBERS, [loggedUser.id]));
      }
    }
  }, [
    loggedUser,
    arePersonsLoading,
    dispatch,
    hasBeenSorted,
    personsResponsible,
  ]);

  const eligibleRoles = [Roles.ADMIN, Roles.RESOURCE_PLANNER, Roles.PM];

  const userRoles = loggedUser?.roles.map((role) => role.name);

  const hasPermissionToViewProjectsWithoutPhases = userRoles?.some((role) =>
    eligibleRoles.includes(role),
  );

  return (
    <>
      {hasPermissionToViewProjectsWithoutPhases &&
        projectsWithoutPhases &&
        projectsWithoutPhases.length > 0 && (
          <TableWrapper>
            <SectionHeader>{t('projects.projectsWithoutPhases')}</SectionHeader>
            <Text>{t('projects.completeProjectSetup')}</Text>
            <ProjectsWithoutPhasesTable data={projectsWithoutPhases} />
          </TableWrapper>
        )}
      <PhasesTable data={projectList || []} />
    </>
  );
});
