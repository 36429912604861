import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const TextLink = styled.div<{ color?: string; fontSize?: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : theme.fontSizes.xxs)};
  color: ${({ color }) => (color ? color : theme.colors.veryDarkGray)};
  text-decoration: underline;
  font-weight: normal;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  text-align: left;
  word-break: break-all;
  border-radius: ${theme.radii.xmd};
  padding: ${theme.space[1]} ${theme.space[1]};
  outline: none;
  transition: all 150ms;
  user-select: none;
  &:hover,
  &:focus {
    color: ${theme.colors.primary};
    background-color: ${theme.colors.lightBlue};
  }

  svg {
    margin-right: ${theme.space[2]};
  }
`;
