import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectUsersFiltersData,
  setFilter,
  UserFilters,
} from '../../slices/usersFiltersSlice';
import { ClearUserFiltersButton } from '../ClearUserFiltersButton';
import { CommonSettingsFields } from 'api/users/models';
import { Chip } from 'components/forms';
import { PrimeMultiSelect } from 'components/prime';
import { Button } from 'components/ui';
import { usePositions } from 'fetchers/hooks/users/usePositions';
import { useSeniority } from 'fetchers/hooks/users/useSeniority';
import { useSkills } from 'fetchers/hooks/users/useSkills';
import { useSupervisors } from 'fetchers/hooks/users/useSupervisors';
import { useTeams } from 'fetchers/hooks/users/useTeams';
import { Skeleton } from 'primereact/skeleton';
import { Flex } from 'rebass';
import { MultiSelectLabel } from 'screens/Projects/components/ProjectsFilters/styled';
import { useKeyPress } from 'utils/hooks/useKeyPress';

interface Props {
  onHide: () => void;
  isVisible: boolean;
}

export const UsersFilters = ({ onHide, isVisible }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modalContainer = document.querySelector('#modal-root') as HTMLElement;

  const { skills, isLoading: areSkillsLoading } = useSkills();
  const { positions, isLoading: arePositionsLoading } = usePositions();
  const { supervisors, isLoading: areSupervisorsLoading } = useSupervisors();
  const { teams, isLoading: areTeamsLoading } = useTeams();
  const { seniority, isLoading: isSeniorityLoading } = useSeniority();
  const {
    positions: selectedPositions,
    supervisors: selectedSupervisors,
    skills: selectedSkills,
    seniority: selectedSeniority,
    teams: selectedTeams,
  } = useSelector(selectUsersFiltersData);

  const handleFilter = (value: string | string[], filterName: UserFilters) => {
    dispatch(setFilter(filterName, value || []));
  };

  const onClose = () => {
    onHide();
  };

  const renderInputSkeleton = () => {
    return <Skeleton width="100%" height="38px" />;
  };

  useKeyPress({
    targetKey: 'Escape',
    downHandler: onClose,
    listenWhen: isVisible,
  });

  const renderSelectedItem = (
    item: string,
    key: string,
    filter: UserFilters,
    selectedItems: string[],
    allOptions: CommonSettingsFields[],
  ) => {
    const selectedItem = allOptions?.find(
      (option) => option.id === parseInt(item),
    );

    if (selectedItem) {
      return (
        <Chip
          key={`users.filters.${key}-${item}`}
          onRemove={() => {
            const newSelectedFilters = selectedItems.filter(
              (value) => value !== item,
            );
            handleFilter(newSelectedFilters, filter);
          }}
          label={
            selectedItem.full_name ? selectedItem.full_name : selectedItem.name
          }
        />
      );
    }

    return undefined;
  };

  const isLoading =
    areSupervisorsLoading ||
    areSkillsLoading ||
    arePositionsLoading ||
    areTeamsLoading ||
    isSeniorityLoading;

  return (
    <>
      <Flex width="100%" mt={8}>
        <Flex width="100%" flexDirection="column">
          <MultiSelectLabel>{t('users.filters.positions')}</MultiSelectLabel>
          {isLoading ? (
            renderInputSkeleton()
          ) : (
            <PrimeMultiSelect
              optionLabel="name"
              optionValue="id"
              placeholder={t('users.filters.positions')}
              maxSelectedLabels={100}
              value={selectedPositions}
              options={positions}
              onChange={({ value }) =>
                handleFilter(value, UserFilters.POSITIONS)
              }
              filter
              showClear={true}
              appendTo={modalContainer}
              selectedItemTemplate={(item) =>
                item &&
                renderSelectedItem(
                  item,
                  'positions',
                  UserFilters.POSITIONS,
                  selectedPositions,
                  positions,
                )
              }
            />
          )}

          <MultiSelectLabel>{t('users.filters.teams')}</MultiSelectLabel>
          {isLoading ? (
            renderInputSkeleton()
          ) : (
            <PrimeMultiSelect
              optionLabel="name"
              display="chip"
              placeholder={t('users.filters.teams')}
              value={selectedTeams}
              optionValue="id"
              options={teams}
              onChange={({ value }) => handleFilter(value, UserFilters.TEAMS)}
              filter
              showClear={true}
              maxSelectedLabels={100}
              appendTo={modalContainer}
              selectedItemTemplate={(item) =>
                item &&
                renderSelectedItem(
                  item,
                  'teams',
                  UserFilters.TEAMS,
                  selectedTeams,
                  teams,
                )
              }
            />
          )}

          <MultiSelectLabel>{t('users.supervisor')}</MultiSelectLabel>
          {isLoading ? (
            renderInputSkeleton()
          ) : (
            <PrimeMultiSelect
              optionLabel="full_name"
              optionValue="id"
              placeholder={t('users.supervisor')}
              display="chip"
              value={selectedSupervisors}
              options={supervisors}
              onChange={({ value }) =>
                handleFilter(value, UserFilters.SUPERVISORS)
              }
              filter
              showClear={true}
              maxSelectedLabels={100}
              appendTo={modalContainer}
              selectedItemTemplate={(item) =>
                item &&
                renderSelectedItem(
                  item,
                  'supervisor',
                  UserFilters.SUPERVISORS,
                  selectedSupervisors,
                  supervisors,
                )
              }
            />
          )}

          <MultiSelectLabel>{t('users.seniority')}</MultiSelectLabel>
          {isLoading ? (
            renderInputSkeleton()
          ) : (
            <PrimeMultiSelect
              optionLabel="name"
              optionValue="id"
              placeholder={t('users.seniority')}
              display="chip"
              value={selectedSeniority}
              options={seniority}
              onChange={({ value }) =>
                handleFilter(value, UserFilters.SENIORITY)
              }
              filter
              showClear={true}
              maxSelectedLabels={100}
              appendTo={modalContainer}
              selectedItemTemplate={(item) =>
                item &&
                renderSelectedItem(
                  item,
                  'seniority',
                  UserFilters.SENIORITY,
                  selectedSeniority,
                  seniority,
                )
              }
            />
          )}

          <MultiSelectLabel>{t('users.skills')}</MultiSelectLabel>
          {isLoading ? (
            renderInputSkeleton()
          ) : (
            <PrimeMultiSelect
              optionLabel="name"
              display="chip"
              optionValue="id"
              placeholder={t('users.skills')}
              value={selectedSkills}
              options={skills}
              onChange={({ value }) => handleFilter(value, UserFilters.SKILLS)}
              filter
              showClear={true}
              maxSelectedLabels={100}
              appendTo={modalContainer}
              selectedItemTemplate={(item) =>
                item &&
                renderSelectedItem(
                  item,
                  'skills',
                  UserFilters.SKILLS,
                  selectedSkills,
                  skills,
                )
              }
            />
          )}

          <Flex width="100%" justifyContent="flex-end" mt={4}>
            <ClearUserFiltersButton />
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" marginTop="auto" width="100%">
        <Button variant="ghost" data-cy="close-modal-btn" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button data-cy="add-member-btn" onClick={onClose}>
          {t('common.applyFilters')}
        </Button>
      </Flex>
    </>
  );
};
