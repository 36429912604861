import { ProjectAvatar } from 'api/projects/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

const API_URL = '/projects/names';

type ProjectsNames = {
  projects: {
    avatar: ProjectAvatar;
    id: number;
    name: string;
  }[];
};

export const useProjectsNames = () => {
  const { data } = useSWR<ProjectsNames>(API_URL, fetcher);

  return {
    isLoading: !data,
    projectsNames: data?.projects,
  };
};
