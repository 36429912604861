import React, { useEffect, useState } from 'react';
import { FiFilter, FiRotateCcw } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';

import type { NotificationType } from 'api/notifications/models';
import { Switch } from 'components/forms';
import { PAGES_PATH } from 'components/layout/PrivateRoute/components/MainLayout/hooks';
import { NotificationsTable } from 'components/layout/Tables/NotificationsTable';
import {
  Avatar,
  Button,
  GithubIcon,
  JiraIcon,
  NothingFoundBox,
  ProjectPhasePersonLink,
  TogglIcon,
  Tooltip,
} from 'components/ui';
import { PageState, Paginator } from 'primereact/paginator';
import { Box, Flex } from 'rebass';
import { useDisclosure } from 'utils/hooks/useDisclosure';

import NotificationsFiltersModal from './components/NotificationsFiltersModal';
import NotificationsSettingsModal from './components/NotificationsSettingsModal';
import {
  clearNotificationsFilters,
  NotificationsFilters,
  selectNotificationsFiltersData,
  setNotificationsFilters,
  switchNotificationsResolved,
} from './notificationsFiltersSlice';
import {
  getNotifications,
  selectNotificationsData,
} from './notificationsSlice';
import { Label } from './styled';

const ITEMS_PER_PAGE = 25;

const Notifications: React.FC = () => {
  const [selectedPage, setSelectedPage] = useState(0);
  const [rows, setRows] = useState(ITEMS_PER_PAGE);

  const { startDate, endDate, periods, types, isResolved } = useSelector(
    selectNotificationsFiltersData,
  );
  const { notifications, isLoading } = useSelector(selectNotificationsData);

  const {
    isOpen: isSettingsModalOpen,
    onClose: onSettingsModalClose,
  } = useDisclosure();

  const {
    isOpen: isFiltersModalOpen,
    onClose: onFiltersModalClose,
    onOpen: onFiltersModalOpen,
  } = useDisclosure();

  const dispatch = useDispatch();

  const onFilter = (
    filterName: NotificationsFilters,
    value: string | number | string[] | boolean,
  ) => {
    dispatch(setNotificationsFilters(filterName, value));
  };

  const memoizedNotifications = React.useMemo(() => notifications, [
    notifications,
  ]);

  const columns: Column<NotificationType>[] = [
    {
      minWidth: 350,
      accessor: (notification) => notification.user?.full_name,
      Header: 'Full name',
      Cell: ({ row }: any) => {
        const notification = row.original as NotificationType;

        const handleMoveToUserView = (event: React.MouseEvent) => {
          if (event) {
            event.stopPropagation();
          }
        };

        return (
          <>
            <Box marginRight="20px">
              <Avatar
                name={notification.user?.full_name || notification.user?.email}
                src={notification.user?.photo?.medium?.url || ''}
              />
            </Box>
            <ProjectPhasePersonLink
              data-cy="user-view-btn"
              href={`/users/${notification.user?.id}`}
              onClick={handleMoveToUserView}
              source={PAGES_PATH.NOTIFICATIONS}
            >
              <Box>{notification?.user?.full_name}</Box>
              <Box>{notification?.user?.position?.name}</Box>
            </ProjectPhasePersonLink>
          </>
        );
      },
    },
    {
      minWidth: 450,
      accessor: (notification) => notification.label,
      Header: 'Information / Label',
      Cell: ({ row }: any) => {
        const notification = row.original as NotificationType;

        return (
          <>
            <Flex mr={3} flexWrap="wrap">
              <Box as="span" mb={1} width="75%">
                <Tooltip
                  render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
                    <Box
                      marginLeft="6px"
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      ref={triggerRef}
                      sx={{
                        cursor: 'default',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {notification.title}
                    </Box>
                  )}
                >
                  {notification.title}
                </Tooltip>
              </Box>
              <Label>{notification.label}</Label>
            </Flex>
          </>
        );
      },
    },
    {
      accessor: (notification) => notification.alert_type,
      Header: 'Type',
      Cell: ({ row }: any) => {
        const notification = row.original as NotificationType;

        const type = notification.alert_type;
        let icon;
        switch (type) {
          case 'Toggl':
            icon = <TogglIcon width="20" height="20" />;
            break;
          case 'Jira':
            icon = <JiraIcon width="20" height="20" />;
            break;
          case 'Github':
            icon = <GithubIcon width="20" height="20" />;
            break;
          default:
            icon = null;
        }

        return (
          <Flex alignItems="center">
            {icon}
            <Box ml={2}>{notification.alert_type}</Box>
          </Flex>
        );
      },
    },
    {
      accessor: (notification) => notification.period,
      Header: 'Period',
      Cell: ({ row }: any) => {
        const notification = row.original as NotificationType;

        return (
          <Box as="span" sx={{ textTransform: 'capitalize' }}>
            {notification.period}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      getNotifications({
        startDate,
        endDate,
        periods,
        alertTypes: types,
        isResolved,
      }),
    );
    // eslint-disable-next-line
  }, []);

  const handleFiltersClear = () => {
    dispatch(clearNotificationsFilters());
    dispatch(getNotifications({ isResolved }));
  };

  const handleResolvedSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(switchNotificationsResolved(e.target.checked));
  };

  const onPageChange = (event: PageState) => {
    setSelectedPage(event.first);
    setRows(event.rows);

    dispatch(
      getNotifications({
        startDate,
        endDate,
        periods,
        alertTypes: types,
        isResolved,
        page: event.page + 1,
      }),
    );

    window.scrollTo(0, 0);
  };

  return (
    <Box>
      <Flex justifyContent="space-between" mb={5}>
        <Flex>
          <Switch
            last
            data-cy="resolved-notifications-switch"
            label="Resolved"
            isChecked={isResolved}
            onChange={handleResolvedSwitch}
          />
        </Flex>
        <Flex>
          <Button
            data-cy="notifications-filters-modal-open-btn"
            height="smd"
            onClick={onFiltersModalOpen}
            leftComponent={<FiFilter />}
          >
            Filters
          </Button>
          <Button
            data-cy="notifications-filters-reset-btn"
            onClick={handleFiltersClear}
            ml={2}
            height="smd"
            variant="secondary"
            leftComponent={<FiRotateCcw />}
          >
            Clear filters
          </Button>
        </Flex>
        <NotificationsFiltersModal
          isOpen={isFiltersModalOpen}
          onClose={onFiltersModalClose}
          onFilter={onFilter}
        />
        <NotificationsSettingsModal
          isOpen={isSettingsModalOpen}
          onClose={onSettingsModalClose}
          notifications={notifications.notifications}
        />
      </Flex>

      {!memoizedNotifications.notifications.length ? (
        <NothingFoundBox />
      ) : (
        <>
          <NotificationsTable
            data={memoizedNotifications.notifications}
            columns={columns}
            isLoading={isLoading}
          />
          <Paginator
            first={selectedPage}
            rows={rows}
            totalRecords={memoizedNotifications.meta?.total_count}
            onPageChange={onPageChange}
          />
        </>
      )}
    </Box>
  );
};

export default Notifications;
