import styled from '@emotion/styled/macro';
import { ProgressBar } from 'primereact/progressbar';
import { theme } from 'styles/theme';

export const StyledProgressBar = styled(ProgressBar)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  color: ${theme.colors.primary};
  z-index: ${theme.zIndices['7']};
`;
