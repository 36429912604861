import styled from '@emotion/styled/macro';
import { Tag } from 'primereact/tag';
import { theme } from 'styles/theme';

export const StyledTag = styled(Tag)`
  background: ${theme.colors.zumthor};
  color: ${theme.colors.primary};
  border-radius: ${theme.radii.mlg};
  font-size: ${theme.fontSizes.xs};
  font-weight: normal;
  margin: 0 2px 4px 2px;
`;
