import { mutateProject } from '../mutations/mutateProject';
import { phasesAPI } from 'api/phases/endpoints';
import { mutateProjects } from 'fetchers/mutations/mutateProjects';
import { mutateProjectsWeeks } from 'fetchers/mutations/mutateProjectsWeeks';

export enum USER_VISIBILITY_ACTIONS {
  HIDE = 'hide',
  SHOW = 'show',
}

export type UserVisibility = {
  action: USER_VISIBILITY_ACTIONS;
  clearHours?: boolean;
  phaseId: number;
  phaseUserRangeId: number;
  projectId?: number;
};

export const setUserVisibility = async ({
  action,
  clearHours,
  phaseId,
  phaseUserRangeId,
  projectId,
}: UserVisibility) => {
  switch (action) {
    case USER_VISIBILITY_ACTIONS.HIDE:
      await phasesAPI.hideMemberOnPhase(phaseUserRangeId, phaseId);
      break;
    case USER_VISIBILITY_ACTIONS.SHOW:
      await phasesAPI.unhideMemberOnPhase(
        phaseUserRangeId,
        phaseId,
        clearHours,
      );
      break;
    // no-default
  }

  if (projectId) {
    await mutateProject(projectId.toString());
  }
  await mutateProjects();
  await mutateProjectsWeeks();
};
