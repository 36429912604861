import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  ignoreHidingLines?: boolean;
};

export const TableSubRow = styled(Box)<Props>`
  border-bottom: ${({ ignoreHidingLines }) =>
    ignoreHidingLines ? 0 : `1px solid ${theme.colors.neutral}`};

  & li:last-of-type > div:last-of-type {
    ${({ ignoreHidingLines }) => !ignoreHidingLines && `border-bottom: 0;`}
  }
`;
