import { serialize } from 'object-to-formdata';
import httpClient from 'utils/httpClient';

import { OnboardingService } from './models';

const TRIGGER_ONBOARDING_PATH = '/projects';

export const onboardingAPI = {
  async triggerOnboarding(
    service: OnboardingService,
    projectId: string,
  ): Promise<any> {
    const formData = serialize({ project_id: projectId });
    return await httpClient.post(
      `/${service}${TRIGGER_ONBOARDING_PATH}`,
      formData,
    );
  },
};
