import React from 'react';

import { Error, Wrapper } from '../styled';
import { PrimeNumberInput, InputNumberCustomProps } from 'components/prime';
import { useField } from 'formik';
import { InputNumberProps } from 'primereact/inputnumber';

type Props = InputNumberProps &
  InputNumberCustomProps & {
    name: string;
    last?: boolean;
    onChange?: (event: { originalEvent: Event; value: any }) => void;
    onBlur?: (event: Event) => void;
    validate?: (value: number) => undefined | string | Promise<any>;
  };

export const FormikNumberInput = ({
  name,
  last,
  onChange: customOnChange,
  onBlur: customOnBlur,
  validate,
  ...restProps
}: Props) => {
  const [field, meta, helpers] = useField({ name, validate });
  const { setValue, setTouched } = helpers;
  return (
    <Wrapper last={last}>
      <PrimeNumberInput
        {...field}
        onChange={(event: { originalEvent: Event; value: any }) => {
          setTouched(true);
          setValue(event.value);
          customOnChange && customOnChange(event);
        }}
        onBlur={(event: Event) => {
          field.onBlur(event);
          customOnBlur && customOnBlur(event);
        }}
        iserror={meta.error}
        {...restProps}
      />
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </Wrapper>
  );
};
