import React, { useState } from 'react';

import { Checkbox } from 'components/forms';
import { AvatarBox } from 'components/ui/AvatarBox';
import { Button } from 'components/ui/Button';
import {
  USER_VISIBILITY_ACTIONS,
  setUserVisibility,
} from 'fetchers/actions/setUserVisibility';
import { Box, Flex } from 'rebass';
import { theme } from 'styles/theme';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

export type ShownMember = {
  avatar?: string;
  phaseUserRangeId: number;
  name: string;
  phaseId: number;
  projectId?: number;
};

type Props = {
  onClose: () => void;
  onConfirm: () => void;
  user: ShownMember;
  projectId?: string;
};

export const UnhideMemberModal = ({ onClose, onConfirm, user }: Props) => {
  const [withClearHours, setClearHours] = useState<boolean>(false);
  const hasAccessToClearHours = usePermissions(PERMISSIONS.CLEAR_USER_HOURS);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClearHours(event.target?.checked);
  };

  const handleUnhideUser = () => {
    const { phaseUserRangeId, phaseId, projectId } = user;

    setUserVisibility({
      action: USER_VISIBILITY_ACTIONS.SHOW,
      clearHours: withClearHours,
      phaseUserRangeId,
      projectId,
      phaseId,
    });

    if (onConfirm) {
      onConfirm();
    } else {
      onClose();
    }
  };

  return (
    <>
      <Flex justifyContent="center">
        <AvatarBox name={user.name} photo={user?.avatar} />
      </Flex>
      <Box as="p" marginBottom={theme.space[10]}>
        This user is already assigned to a project but is hidden. Do you want to
        unhide them?
      </Box>
      <Flex alignItems="center" width="100%">
        {hasAccessToClearHours && (
          <Box>
            <Checkbox
              defaultIsChecked={withClearHours}
              onChange={handleSwitchChange}
            >
              Clear hours
            </Checkbox>
          </Box>
        )}
        <Box marginLeft="auto">
          <Button mr={2} variant="ghost" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button autoFocus={true} onClick={handleUnhideUser} type="button">
            Unhide
          </Button>
        </Box>
      </Flex>
    </>
  );
};
