import styled from '@emotion/styled/macro';
import { Tag } from 'primereact/tag';
import { theme } from 'styles/theme';

export const StyledNewTag = styled(Tag)`
  background: ${theme.colors.primaryOpacity};
  color: ${theme.colors.cobalt};
  border-radius: ${theme.radii.md};
  font-size: ${theme.fontSizes.sm};
  font-weight: normal;
  margin: 0 14px 6px 0;
  line-height: ${theme.lineHeights.md2};
  padding: ${theme.space[1]} ${theme.space[2]};
`;
