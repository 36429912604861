import { Meta } from 'api/shared/models';
import { User } from 'api/users/models';

export type NotificationsTypes = 'Toggl' | 'Jira' | 'Github';

export enum NotificationsSubTypes {
  COMMITS_WITHOUT_JIRA_ID = 'commits-without-jira-id',
  NO_COMMITS_LAST_WEEK = 'no-commits-last-week',
  ISSUES_EXCEEDING_ORIGINAL_ESTIMATE = 'issues-exceeding-original-estimate',
  ISSUES_WITHOUT_ESTIMATIONS = 'issues-without-estimations',
  ISSUES_WITH_ESTIMATIONS_EXCEEDING_16_HOURS = 'issues-with-estimations-exceeding-16-hours',
  ISSUES_WITH_MANY_REJECTS = 'issues-with-many-rejects',
  DAILY_CALLS_LENGTH = 'daily-calls-length',
  ONE_ON_ONE_CALLS_LENGTH = '1:1-calls-length',
  LUNCH_LENGTH = 'lunch-length',
  CALLS_LENGTH = 'calls-length',
  TASKS_SUMMARED_TOO_LONG = 'tasks-summared-too-long',
  TASKS_WITHOUT_JIRA_ID = 'tasks-without-jira-id',
  PROJECTS_WITH_TOO_MUCH_TOGGLED_TIME = 'projects-with-too-much-toggled-time',
}

export enum NotificationsLabels {
  COMMITS = 'commits',
  ISSUES = 'issues',
  DAILY = 'daily',
  ONE_ON_ONE = '1:1',
  LUNCH = 'lunch',
  CALLS = 'calls',
  TASK = 'task',
  PROJECT = 'project',
}

export type NotificationsPeriods = 'daily' | 'weekly';

export interface NotificationType {
  id: number;
  user: User;
  period: NotificationsPeriods;
  project_id: number | null;
  ack_by: number | null;
  resolved: boolean;
  label: string;
  alert_type: NotificationsTypes;
  alert_subtype: string;
  alert_level: string;
  title: string;
  description: string | null;
  data: string; // TODO: Figure out what is the use case for it
  created_at: string;
}

export interface NotificationResponse {
  notifications: NotificationType[];
  meta: Meta | null;
}
