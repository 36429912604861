import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { projectsAPI } from 'api/projects/endpoints';
import { Project, ProjectFormikForm, ProjectWeek } from 'api/projects/models';
import getISOWeek from 'date-fns/getISOWeek';
import { mutateProject } from 'fetchers/mutations/mutateProject';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';
import { currentDate } from 'utils/constants';
import { getErrorMesssage } from 'utils/functions';
import { history } from 'utils/history';

import { displaySnackbar } from './snackbarSlice';

export enum ROW_PREFIXES {
  PHASES = 'phases',
  USERS = 'users',
}

export type ProjectsPayload = {
  page?: number;
  perPage?: number;
  withLoading?: boolean;
};

export type ProjectsWeeksPayload = {
  page?: number;
  perPage?: number;
  weeksNumbers: number[];
  withLoading?: boolean;
};

type ProjectsWeeksState = {
  error: string | null;
  expandAllRows: boolean;
  expandedRows: {
    [rowId: string]: boolean;
  };
  isLoading: boolean;
  projects: Project[];
  projectsWeeks: ProjectWeek[];
};

const INITIAL_STATE: ProjectsWeeksState = {
  error: null,
  expandAllRows: false,
  expandedRows: {},
  isLoading: true,
  projects: [],
  projectsWeeks: [],
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState: INITIAL_STATE,
  reducers: {
    getWeeksStart(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
      state.error = null;
    },
    getWeeksSuccess(state, action: PayloadAction<ProjectWeek[]>) {
      state.projectsWeeks = action.payload;
      state.isLoading = false;
    },
    getWeeksSuccessWithPartialUpdate(
      state,
      action: PayloadAction<ProjectWeek[]>,
    ) {
      state.error = null;
      let counter = 0;
      state.projectsWeeks = state.projectsWeeks.map((projectsWeek) => {
        const newProjectsWeek = action.payload.find(
          (newProjectsWeek) => newProjectsWeek.week === projectsWeek.week,
        );

        if (
          !state.projectsWeeks
            .map((projectsWeek) => projectsWeek.week)
            .includes(action.payload[0].week)
        ) {
          counter = -1;
        }

        if (newProjectsWeek) {
          return newProjectsWeek;
        }

        return projectsWeek;
      });

      if (counter === -1) {
        state.projectsWeeks = [...state.projectsWeeks, ...action.payload];
      }
      state.isLoading = false;
    },
    getWeeksFail(state, action: PayloadAction<string>) {
      state.projectsWeeks = [];
      state.error = action.payload;
      state.isLoading = false;
    },

    setWeeks(state, action: PayloadAction<ProjectWeek[]>) {
      state.projectsWeeks = action.payload;
    },

    getProjectsStart(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
      state.error = null;
    },
    getProjectsSuccess(state, action: PayloadAction<Project[]>) {
      state.projects = action.payload;
      state.isLoading = false;
    },
    getProjectsFail(state, action: PayloadAction<string>) {
      state.projects = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    addProjectStart(state) {
      state.isLoading = true;
    },
    addProjectSuccess(state, action: PayloadAction<Project>) {
      state.projects = [...state.projects, action.payload];
      state.isLoading = false;
    },
    addProjectFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateProjectStart(state) {
      state.isLoading = true;
    },
    updateProjectSuccess(state, action: PayloadAction<Project>) {
      state.projects = state.projects.map((project) =>
        project.id === action.payload.id ? action.payload : project,
      );
      state.isLoading = false;
    },
    updateProjectFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    removeProjectStart(state) {
      state.error = null;
    },
    removeProjectFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    removeProjectSuccess(state, action: PayloadAction<number>) {
      state.error = null;
      state.projects = state.projects.filter(({ id }) => id !== action.payload);
    },
    setRow(
      state,
      action: PayloadAction<ProjectsWeeksState['expandedRows']>,
    ): void {
      state.expandedRows = action.payload;
    },
    setAllRows(state, action: PayloadAction<boolean>): void {
      state.expandAllRows = action.payload;
      state.expandedRows = Object.keys(state.expandedRows).reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue]: undefined,
        }),
        {},
      );
    },
  },
});

const {
  addProjectFailed,
  addProjectStart,
  addProjectSuccess,
  getProjectsFail,
  getProjectsStart,
  getProjectsSuccess,
  getWeeksFail,
  getWeeksStart,
  getWeeksSuccess,
  getWeeksSuccessWithPartialUpdate,
  removeProjectFail,
  removeProjectStart,
  removeProjectSuccess,
  setAllRows,
  setRow,
  updateProjectFailed,
  updateProjectStart,
  updateProjectSuccess,
} = projectsSlice.actions;

export const { setWeeks } = projectsSlice.actions;
export default projectsSlice.reducer;

export const getProjectsWeeks = ({
  page = 1,
  perPage = 1000,
  weeksNumbers = [getISOWeek(currentDate)],
  withLoading = true,
}: ProjectsWeeksPayload): AppThunk => async (dispatch) => {
  dispatch(getWeeksStart(withLoading));

  try {
    const {
      data: { weeks },
    } = await projectsAPI.getProjectsWeeks({ weeksNumbers, page, perPage });

    dispatch(getWeeksSuccess(weeks));
  } catch (error) {
    dispatch(getWeeksFail(error?.message || 'Weeks not fetched'));
  }
};

export const getNewProjectsWeeks = ({
  error,
  weeks,
}: {
  error: string;
  weeks: ProjectWeek[];
}): AppThunk => (dispatch) => {
  if (weeks) {
    dispatch(getWeeksSuccess(weeks));
  }

  if (error) {
    dispatch(getWeeksFail(error));
  }
};

export const getProjectsWeeksWithPartialUpdate = ({
  page = 1,
  perPage = 1000,
  weeksNumbers = [getISOWeek(currentDate)],
  withLoading = true,
}: ProjectsWeeksPayload): AppThunk => async (dispatch) => {
  dispatch(getWeeksStart(withLoading));

  try {
    const {
      data: { weeks },
    } = await projectsAPI.getProjectsWeeks({ weeksNumbers, page, perPage });

    dispatch(getWeeksSuccessWithPartialUpdate(weeks));
  } catch (error) {
    dispatch(getWeeksFail(error?.message || 'Weeks not updated'));
  }
};

export const getProjects = ({
  page = 1,
  perPage = 1000,
  withLoading = true,
}: ProjectsPayload): AppThunk => async (dispatch) => {
  dispatch(getProjectsStart(withLoading));

  try {
    const {
      data: { projects },
    } = await projectsAPI.getProjects({ page, perPage });

    dispatch(getProjectsSuccess(projects));
  } catch (error) {
    dispatch(getProjectsFail(error?.message || 'Weeks not fetched'));
  }
};

export const addProject = (payload: ProjectFormikForm): AppThunk => async (
  dispatch,
) => {
  dispatch(addProjectStart());

  try {
    const {
      data: { project },
    } = await projectsAPI.addProject(payload);

    dispatch(addProjectSuccess(project));
    dispatch(displaySnackbar({ message: 'Project was added' }));
    history.push(`/projects/${project.id}`);
  } catch (error) {
    const errorMessage = error?.data?.errors
      ? getErrorMesssage(error?.data?.errors)
      : error?.message || 'Failed adding a project';

    dispatch(addProjectFailed(errorMessage));
    dispatch(displaySnackbar({ message: errorMessage, isError: true }));
  }
};

export const updateProject = (
  id: number,
  payload: ProjectFormikForm,
): AppThunk => async (dispatch) => {
  dispatch(updateProjectStart());

  try {
    const {
      data: { project },
    } = await projectsAPI.updateProject(id, payload);

    mutateProject(id.toString());
    dispatch(updateProjectSuccess(project));
    dispatch(displaySnackbar({ message: 'Project was updated' }));
    history.push(`/projects/${project.id}`);
  } catch (error) {
    const errorMessage = error?.data?.errors
      ? getErrorMesssage(error?.data?.errors)
      : error?.message || 'Failed updating a project';
    dispatch(updateProjectFailed(errorMessage));
    dispatch(displaySnackbar({ message: errorMessage, isError: true }));
  }
};

export const selectProjectsData = (state: RootState) => state.projects.data;

export const selectIsProjectsLoading = createSelector(
  selectProjectsData,
  ({ isLoading }) => isLoading,
);

export const selectProjectsName = createSelector(
  selectProjectsData,
  ({ projects }) => projects.map(({ name }) => name.toLowerCase()),
);

export const selectProjectsWeeks = createSelector(
  selectProjectsData,
  ({ projectsWeeks }) => projectsWeeks,
);

export const selectProjectsExpandedRow = createSelector(
  selectProjectsData,
  ({ expandedRows }) => expandedRows,
);

export const selectIsAllRowsExpanded = createSelector(
  selectProjectsData,
  ({ expandAllRows }) => expandAllRows,
);

export const removeProject = ({
  id,
  onSuccess,
}: {
  id: number;
  onSuccess: () => void;
}): AppThunk => async (dispatch) => {
  dispatch(removeProjectStart());

  try {
    await projectsAPI.removeProject(id);
    dispatch(displaySnackbar({ message: 'Project has been removed' }));
    dispatch(removeProjectSuccess(id));
    onSuccess();
  } catch (error) {
    dispatch(
      removeProjectFail(error?.message || 'Error during removing project'),
    );
  }
};

export const setExpandedRow = (rowId: string, value?: boolean): AppThunk => (
  dispatch,
  getState,
) => {
  const {
    projects: {
      data: { expandedRows },
    },
  } = getState();
  const itemToCollapseId = Object.keys(expandedRows).find(
    (expandedRowKey) =>
      expandedRowKey !== rowId && expandedRowKey.includes(rowId),
  );
  const sprintRow = itemToCollapseId ? { [itemToCollapseId]: false } : {};

  dispatch(
    setRow({
      ...expandedRows,
      ...sprintRow,
      [rowId]: value !== undefined ? value : !expandedRows[rowId],
    }),
  );
};

export const setAllExpandedRow = (): AppThunk => (dispatch, getState) => {
  const {
    projects: {
      data: { expandAllRows },
    },
  } = getState();

  dispatch(setAllRows(!expandAllRows));
};
