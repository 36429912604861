import React, { FC } from 'react';

import { Avatar } from 'components/ui';
import { Box } from 'rebass';

import { CardContainer, Title, Description } from './Card.styles';

export type CardProps = {
  imageUrl?: string | undefined;
  title: string;
  description?: string | undefined;
};

const Card: FC<CardProps> = ({ imageUrl, title, description }) => {
  return (
    <CardContainer>
      <Avatar src={imageUrl || ''} name={title} />
      <Box>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </Box>
    </CardContainer>
  );
};

export default Card;
