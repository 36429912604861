import React from 'react';

import { Spin } from 'components/ui/Spin';

import { Cover } from './styled';

export const SpinnerCover: React.FC = () => {
  return (
    <Cover>
      <Spin />
    </Cover>
  );
};
