import { UserDepartment } from 'api/users/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';
import { sortByKey } from 'utils/sortByKey';

const API_URL = '/departments';

type DepartmentsPayload = {
  departments: UserDepartment[];
};

export const useDepartments = () => {
  const { data } = useSWR<DepartmentsPayload>(API_URL, fetcher);

  return {
    isLoading: !data,
    departments: data?.departments.sort(sortByKey('name')) || [],
  };
};
