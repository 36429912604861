import { TFunction } from 'i18next';
import { REGEX } from 'utils/constants';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    full_name: Yup.string()
      .required(t('errors.required'))
      .trim()
      .matches(REGEX.full_name, t('errors.invalidFullName')),
    email: Yup.string()
      .email(t('errors.invalidEmail'))
      .required(t('errors.required')),

    hours_weekly: Yup.number()
      .required(t('errors.required'))
      .typeError(t('errors.required'))
      .max(99, `${t('errors.maxNumber')} 99`),
    team_member_supervisors_attributes: Yup.array()
      .of(Yup.object())
      .min(1, t('errors.required'))
      .required(t('errors.required')),
    position_id: Yup.number().required(t('errors.required')).nullable(),
    department_id: Yup.number().required(t('errors.required')).nullable(),
    team_id: Yup.number().required(t('errors.required')).nullable(),
    password: Yup.string()
      .optional()
      .min(8, t('errors.password.min'))
      .max(20, t('errors.password.max'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]'":;\\|?/.>,<`~])[A-Za-z\d!@#$%^&*()\-_=+{}[\]'":;\\|?/.>,<`~]{8,}$/g,
        t('errors.password.invalid'),
      ),
    password_confirmation: Yup.string().when('password', (password: string) => {
      if (password)
        return Yup.string()
          .required(t('errors.required'))
          .oneOf([Yup.ref('password')], t('errors.password.notMatch'));
      return Yup.string().optional();
    }),
  });
