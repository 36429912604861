import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';

import { ImageUploader } from '../ImageUploader';
import {
  ProjectFormikForm,
  Repository as RepositoryProps,
} from 'api/projects/models';
import { FormikSelect, FormikTextInput } from 'components/forms/formik-prime';
import { Card } from 'components/primitive';
import { JiraIcon, TogglIcon, Button } from 'components/ui';
import { useOrganizationSettings } from 'fetchers/hooks/useOrganizationSettings';
import { useProjectTypes } from 'fetchers/hooks/users/useProjectTypes';
import { useFormikContext } from 'formik';
import uniqueId from 'lodash.uniqueid';
import { Flex, Box } from 'rebass';

import Repository from './Repository';
import { CustomLabel, InfoMessage } from './styled';

const gitHubRepositoriesMaxNumber = 8;

type GeneralInfoProps = {
  isReadOnly?: boolean;
};

const GeneralInfoFields: React.FC<GeneralInfoProps> = ({ isReadOnly }) => {
  const { values, setValues } = useFormikContext<ProjectFormikForm>();
  const { projectTypes } = useProjectTypes();
  const { t } = useTranslation();
  const { integrations } = useOrganizationSettings();

  const handleAddNewRepository = () => {
    const repositories_attributes = [
      ...values.repositories_attributes,
      { name: '', uniqueId: uniqueId('id_') },
    ];

    setValues((oldValues) => ({ ...oldValues, repositories_attributes }));
  };

  const handleRepositoryRemove = (index: number) => {
    const repositories_attributes = [...values.repositories_attributes];
    repositories_attributes.splice(index, 1);

    setValues((oldValues) => ({ ...oldValues, repositories_attributes }));
  };

  return (
    <Card marginBottom="10px">
      <Flex>
        <Box width="48%">
          <FormikTextInput
            name="name"
            label={`${t('userProfile.projectName')}*`}
            disabled={isReadOnly}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              (e.target.value = e.target.value.trim())
            }
            data-cy="project-name-input"
          />
          <FormikSelect
            name="project_type_id"
            label={`${t('userProfile.projectType')}*`}
            optionLabel="name"
            disabled={isReadOnly}
            optionValue="id"
            initialValue={values.project_type_id}
            options={projectTypes}
          />
        </Box>
        <Box pl={10} width={2}>
          <ImageUploader
            name="avatar"
            defaultValue={
              typeof values.avatar === 'string' ? values.avatar : null
            }
            data-cy="project-avatar"
          />
        </Box>
      </Flex>
      <CustomLabel alignItems="center">
        <TogglIcon />
        <span>{`${t('userProfile.togglClientName')}`}</span>
        {integrations?.toggl ? (
          ''
        ) : (
          <InfoMessage alignItems="flex-end">
            <i className="pi pi-info-circle" />
            {t('userProfile.togglOff')}
          </InfoMessage>
        )}
      </CustomLabel>
      <FormikTextInput
        name="toggl_name"
        disabled={isReadOnly}
        data-cy="toggl-name-input"
      />
      <CustomLabel alignItems="center">
        <JiraIcon />
        <span>{t('userProfile.jiraProjectName')}</span>
        {integrations?.atlassian ? (
          ''
        ) : (
          <InfoMessage alignItems="flex-end">
            <i className="pi pi-info-circle" />
            {t('userProfile.jiraOff')}
          </InfoMessage>
        )}
      </CustomLabel>
      <FormikTextInput
        name="jira_name"
        disabled={isReadOnly}
        data-cy="jira-name-input"
      />
      <CustomLabel alignItems="center">
        <JiraIcon />
        <span>{t('userProfile.jiraProjectKey')}</span>
        {integrations?.atlassian ? (
          ''
        ) : (
          <InfoMessage alignItems="flex-end">
            <i className="pi pi-info-circle" />
            {t('userProfile.jiraOff')}
          </InfoMessage>
        )}
      </CustomLabel>
      <FormikTextInput
        name="jira_key"
        disabled={isReadOnly}
        data-cy="jira-key-input"
      />
      {values.repositories_attributes
        .sort((a, b) => {
          const key1 = String(a.id || a.uniqueId);
          const key2 = String(b.id || b.uniqueId);
          return key1.localeCompare(key2);
        })
        .map(({ id, name, uniqueId }: RepositoryProps, index: number) => (
          <Repository
            key={id || uniqueId}
            index={index}
            name={name}
            remove={handleRepositoryRemove}
          />
        ))}
      {!isReadOnly &&
        values.repositories_attributes.length < gitHubRepositoriesMaxNumber && (
          <Flex justifyContent="space-between">
            <Button
              leftComponent={<FiPlus />}
              data-cy="add-github-btn"
              variant="simple"
              type="button"
              onClick={handleAddNewRepository}
            >
              Add github repository
            </Button>
          </Flex>
        )}
    </Card>
  );
};

export default GeneralInfoFields;
