import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useProject } from '../../hooks/projectContext';
import {
  DevStacksAttributes,
  ProjectAccountManagersAttributes,
  ProjectSettings,
} from 'api/projects/models';
import { Checkbox } from 'components/forms';
import { GithubIcon, JiraIcon, TogglIcon } from 'components/ui';
import { Spinner } from 'components/ui/Spin/styled';
import { SpinnerCover } from 'components/ui/SpinCover';
import { useDevStack } from 'fetchers/hooks/useDevStack';
import { useProjectTypes } from 'fetchers/hooks/users/useProjectTypes';
import { useUsersNative } from 'fetchers/hooks/users/useUsers';
import { FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import { Flex } from 'rebass';
import { ImageUploader } from 'screens/Project/components/ImageUploader';
import Title from 'screens/Project/components/Title';
import { theme } from 'styles/theme';

import EditField from './EditField';
import { Box, Button } from './EditView.styles';

const generateTranslationKey = (key: string) =>
  `projectCard.settings.editSettings.${key}`;

const salesTeamIds = process.env.REACT_APP_SALES_TEAM_IDS?.split(',');

const EditView: React.FC = () => {
  const { project, setProjectData, isLoading: projectLoading } = useProject();
  const devStackHookObj = useDevStack();
  const projectTypesHookObj = useProjectTypes();
  const [t] = useTranslation();
  const usersHookObj = useUsersNative({ teams: salesTeamIds });
  const handleUpdateProject = (values: ProjectSettings) => {
    setProjectData(values);
  };

  const initialValues = project && {
    avatar: project.avatar || '',
    summary: project.summary || '',
    project_type: project.project_type.id,
    name: project.name,
    client_name: project.client_name,
    client_company_name: project.client_company_name,
    client_company_website: project.client_company_website,
    dev_stack: project.dev_stack.map((x) => x.dev_stack_id.toString()),
    jira_name: project.jira_name,
    jira_key: project.jira_key,
    toggl_name: project.toggl_name,
    repositories: project.repositories?.filter((r) => r?.name),
    account_managers: project.account_managers.map((x) => x.user_id),
    archived: project.archived,
    is_sensitive: project.is_sensitive,
  };

  const isLoadingDependencies =
    !project ||
    devStackHookObj.isLoading ||
    projectTypesHookObj.isLoading ||
    usersHookObj.isLoading;

  const isLoading = isLoadingDependencies || projectLoading;

  type FormType = typeof initialValues;

  const prepareDevStackPostInfo: (values: FormType) => DevStacksAttributes[] = (
    values,
  ) => {
    return initialValues!.dev_stack
      .filter((v) => !values!.dev_stack.includes(v))
      .map<DevStacksAttributes>((v) => ({
        _destroy: true,
        id: project!.dev_stack!.find((d) => v === d.dev_stack_id.toString())!
          .id,
      }))
      .concat(
        values!.dev_stack
          .filter((v) => !initialValues!.dev_stack.includes(v))
          .map<DevStacksAttributes>((v) => ({
            dev_stack_id: parseInt(v),
          })),
      );
  };

  const prepareProjectManagersPostInfo: (
    values: FormType,
  ) => ProjectAccountManagersAttributes[] = (values) => {
    const newList = values!.account_managers
      .filter((v) => !initialValues!.account_managers.includes(v))
      .map<ProjectAccountManagersAttributes>((v) => ({
        user_id: v,
      }));

    const submitList = initialValues!.account_managers
      .filter((v) => !values!.account_managers.includes(v))
      .map<ProjectAccountManagersAttributes>((v) => ({
        _destroy: true,
        id: project!.account_managers!.find((d) => v === d.user_id)!.id,
      }));

    return [...submitList, ...newList];
  };

  const onSubmit = (values: FormType) => {
    if (!values) {
      return;
    }

    handleUpdateProject({
      project_account_managers_attributes: prepareProjectManagersPostInfo(
        values,
      ),
      description: values.summary,
      avatar:
        typeof values.avatar === 'object' || values.avatar === null
          ? values.avatar
          : undefined,
      client_name: values.client_name,
      client_company_name: values.client_company_name,
      client_company_website: values.client_company_website,
      jira_key: values.jira_key,
      jira_name: values.jira_name,
      name: values.name,
      repositories_attributes: values.repositories,
      toggl_name: values.toggl_name,
      project_type_id: values.project_type,
      project_dev_stacks_attributes: prepareDevStackPostInfo(values),
      is_sensitive: values.is_sensitive,
      archived: values.archived,
    });
  };

  return (
    <>
      {isLoading &&
        createPortal(
          <SpinnerCover>
            <Spinner />
          </SpinnerCover>,
          document.body,
        )}
      {!isLoadingDependencies && initialValues && (
        <Formik initialValues={{ ...initialValues }} onSubmit={onSubmit}>
          {({ values, handleChange }) => (
            <Form>
              <Box>
                <Title>{t(generateTranslationKey('title'))}</Title>
                <Button type="submit">
                  {t(generateTranslationKey('save'))}
                </Button>
              </Box>
              <Flex alignItems="center">
                <ImageUploader name="avatar" defaultValue={project?.avatar} />
                <Flex
                  flexDirection={'column'}
                  marginLeft={theme.space[9]}
                  flexGrow={1}
                >
                  <EditField
                    name="project_type"
                    type="select"
                    label={t(generateTranslationKey('projectType'))}
                    options={projectTypesHookObj.projectTypes.map((x) => ({
                      value: x.id,
                      label: x.name,
                    }))}
                    multiple={false}
                    required
                  />
                  <EditField
                    name="name"
                    type="text"
                    label={t(generateTranslationKey('projectName'))}
                    required
                  />
                </Flex>
              </Flex>
              <EditField
                name="client_name"
                type="text"
                label={t(generateTranslationKey('clientName'))}
              />
              <EditField
                name="client_company_name"
                type="text"
                label={t(generateTranslationKey('clientCompanyName'))}
              />
              <EditField
                name="account_managers"
                type="multiselect"
                label={t(generateTranslationKey('accountManager'))}
                options={usersHookObj.users?.map((v) => ({
                  label: v.full_name,
                  value: v.id,
                }))}
                multiple={true}
              />
              <EditField
                name="client_company_website"
                type="url"
                label={t(generateTranslationKey('companyWebsite'))}
              />
              <EditField
                name="summary"
                type="textarea"
                label={t(generateTranslationKey('summary'))}
              />
              <EditField
                name="dev_stack"
                type="checkboxList"
                label={t(generateTranslationKey('devStack'))}
                options={devStackHookObj.devStack?.map(({ id, name }) => ({
                  value: id.toString(),
                  label: name,
                }))}
              />
              <EditField
                name="jira_name"
                type="text"
                Icon={JiraIcon}
                label={t(generateTranslationKey('jiraName'))}
              />
              <EditField
                name="jira_key"
                type="text"
                Icon={JiraIcon}
                label={t(generateTranslationKey('jiraKey'))}
              />
              <EditField
                name="toggl_name"
                type="text"
                Icon={TogglIcon}
                label={t(generateTranslationKey('togglName'))}
              />
              <FieldArray
                name="repositories"
                render={(arrayHelpers: FieldArrayRenderProps) => (
                  <>
                    {(arrayHelpers.form.values as FormType)?.repositories?.map(
                      (_, i) => (
                        <EditField
                          key={i}
                          name={`repositories[${i}].name`}
                          type="text"
                          Icon={GithubIcon}
                          label={t(generateTranslationKey('gitRepo')) + (i + 1)}
                        />
                      ),
                    )}
                    <Button
                      onClick={() => arrayHelpers.push({ name: '' })}
                      type="button"
                    >
                      {t(generateTranslationKey('addGithubRepository'))}
                    </Button>
                  </>
                )}
              />

              <Box my="8px">
                <Checkbox
                  id="archived"
                  name="archived"
                  defaultIsChecked={values.archived}
                  onChange={handleChange}
                >
                  {t('projectCard.archivedProject')}
                </Checkbox>
              </Box>
              <Checkbox
                id="is_sensitive"
                name="is_sensitive"
                defaultIsChecked={values.is_sensitive}
                onChange={handleChange}
              >
                {t('projectCard.sensitiveDataProject')}
              </Checkbox>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

EditView.displayName = 'EditView';

export default EditView;
