import React, { useEffect, useState } from 'react';

import { Error, Wrapper } from '../styled';
import {
  AutocompleteCustomProps,
  PrimeAutocomplete,
} from 'components/prime/Autocomplete/Autocomplete';
import { useField } from 'formik';
import { AutoCompleteProps } from 'primereact/autocomplete';

type Props = AutoCompleteProps &
  AutocompleteCustomProps & {
    name: string;
    initialValue?: string | number;
    reset?: () => void;
  };

export const FormikAutocomplete = ({
  name,
  options,
  valueKey,
  onChange: customOnChange,
  onBlur: customOnBlur,
  initialValue,
  variant = 'filled',
  isHigh = true,
  searchKey,
  reset,
  ...restProps
}: Props) => {
  const [, meta, helpers] = useField(name);
  const { setTouched, setValue: setFieldValue } = helpers;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (!initialValue) {
      setValue(undefined);
    }
  }, [initialValue]);

  useEffect(() => {
    setValue(undefined);
  }, [reset]);

  const updateValue = (value: any) => {
    if (value) {
      setValue(value);
      setFieldValue(valueKey ? value[valueKey] : value);
    } else {
      setValue(undefined);
      setFieldValue(null);
    }
  };

  return (
    <Wrapper>
      <PrimeAutocomplete
        options={options}
        searchKey={searchKey}
        {...restProps}
        name={name}
        delay={0}
        onChange={(event) => {
          updateValue(event.target.value);
          setTouched(true);
          customOnChange && customOnChange(event);
        }}
        onBlur={(event) => {
          setTouched(true);
          customOnBlur && customOnBlur(event);
        }}
        isHigh={isHigh}
        iserror={meta.touched ? meta.error : ''}
        value={value}
        variant={variant}
      />
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </Wrapper>
  );
};
