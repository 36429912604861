import React from 'react';
import { TableCellProps } from 'react-table';

import { theme } from 'styles/theme';

import { TableCell } from './styled';

type Props = {
  lastCellShorter?: boolean;
} & TableCellProps;
export const TableMainCell: React.FC<Props> = ({
  children,
  lastCellShorter,
  ...props
}) => (
  <TableCell
    {...props}
    alignItems="center"
    flexGrow={0}
    height={theme.space[12]}
    pl="20px"
    fontSize="xxs"
    lastCellShorter={lastCellShorter}
  >
    {children}
  </TableCell>
);
