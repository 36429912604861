import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';

import {
  ProjectFormikForm,
  ProjectPhaseResponsibleUser,
  Repository,
} from 'api/projects/models';
import { Button } from 'components/ui/Button';
import { parseISO } from 'date-fns';
import { Formik, Form } from 'formik';
import { Box } from 'rebass';
import GeneralInfoFields from 'screens/Project/components/GeneralInfoFields';
import { MODAL_TYPES, ModalProps } from 'screens/Project/components/Modal';
import { addProject, updateProject } from 'store/slices/projectsSlice';
import checkPageForErrors from 'utils/checkForErrors';
import {
  confirmPageRefresh,
  removeConfirmationOnPageRefresh,
} from 'utils/functions';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

import { validationSchema } from './validationSchema';

type ProjectFormProps = {
  project: ModalProps['project'];
  projectsName: ModalProps['projectsName'];
  type: ModalProps['type'];
};

export const ProjectForm: React.FC<ProjectFormProps> = ({
  project,
  projectsName,
  type,
}) => {
  const dispatch = useDispatch();
  const canEditProject = usePermissions(PERMISSIONS.EDIT_PROJECT);
  const isReadOnly = type === MODAL_TYPES.EDIT && !canEditProject;
  const actionButtonCopy =
    type === MODAL_TYPES.ADD ? 'Save project' : 'Update project';
  const { t } = useTranslation();
  const [enableValidation, setEnableValidation] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      confirmPageRefresh(false);
    };
  }, []);

  const handleUpdateProject = (values: ProjectFormikForm) => {
    if (!project?.id) {
      console.error('Project id is required for edit mode');
      return;
    }

    if (typeof values.avatar !== 'object') {
      delete values.avatar;
    }

    dispatch(updateProject(project.id, values));
  };

  const findRepositoriesToDelete = (repositories: Repository[]) => {
    return project?.repositories
      ?.filter(
        (oldRepository) =>
          !repositories?.some(
            (incomingRepository: Repository) =>
              incomingRepository.id === oldRepository.id,
          ),
      )
      ?.map(({ id, name }) => ({
        id,
        name,
        _destroy: true,
      }));
  };

  const transformRepositoriesData = (repositories: Repository[]) =>
    repositories?.map((repository) => ({
      name: repository.name,
      id: repository.id,
      _destroy: false,
    }));

  const prepareRepositories = (values: ProjectFormikForm) => {
    const repositoriesToDelete = findRepositoriesToDelete(
      values.repositories_attributes,
    );

    const repositoriesFields = transformRepositoriesData(
      values.repositories_attributes,
    );

    const repositoriesPayload = [];

    if (repositoriesToDelete) {
      repositoriesPayload.push(...repositoriesToDelete);
    }

    if (repositoriesFields) {
      repositoriesPayload.push(...repositoriesFields);
    }
    return repositoriesPayload;
  };

  const prepareResponsibleUsers = (
    items: ProjectPhaseResponsibleUser[] | undefined,
    index: number,
  ) => {
    const initialResponsibleUsers =
      project?.phases[index]?.phase_responsible_users || [];
    const result =
      items?.map((item) => {
        const responsibleUserIndex = initialResponsibleUsers.findIndex(
          ({ user }) => user.id === item.user_id,
        );
        if (responsibleUserIndex >= 0) {
          return { ...item };
        }

        return {
          _destroy: false,
          user_id: item.user_id,
        };
      }) || [];

    initialResponsibleUsers.forEach((responsibleUser) => {
      if (!items?.some((item) => item.user_id === responsibleUser.user.id)) {
        result.push({
          id: responsibleUser.id,
          user_id: responsibleUser.user.id,
          _destroy: true,
        });
      }
    });

    return result;
  };

  const prepareUpdateProjectData = (values: ProjectFormikForm) => {
    const payload = { ...values };
    payload.repositories_attributes = prepareRepositories(payload);
    payload.phases_attributes = payload.phases_attributes.map(
      (phase, index) => ({
        ...phase,
        phase_responsible_users_attributes: prepareResponsibleUsers(
          phase.phase_responsible_users_attributes,
          index,
        ),
      }),
    );

    return payload;
  };

  const handleAddProject = (values: ProjectFormikForm) => {
    const payload = { ...values };
    payload.phases_attributes?.forEach((phase) => {
      if (phase.start_date) {
        phase.start_date = phase.start_date.toString();
      }
      if (phase.end_date) {
        phase.end_date = phase.end_date.toString();
      }
    });
    dispatch(addProject(payload));
  };

  const checkPageForErrorsAndChangeActivity = () => {
    checkPageForErrors();
  };

  const handleFormikSubmit = (values: ProjectFormikForm) => {
    if (type === MODAL_TYPES.ADD) {
      handleAddProject(values);
    } else {
      const data = prepareUpdateProjectData(values);
      handleUpdateProject(data);
    }
  };

  const getProjectPhases = () =>
    project?.phases?.map((phase) => ({
      id: phase.id,
      be_hours: phase.be_hours || 0,
      date:
        (phase.date && new Date(parseISO(phase.date.toString()))) || undefined,
      design_hours: phase.design_hours || 0,
      end_date:
        (phase.end_date && new Date(parseISO(phase.end_date.toString()))) ||
        undefined,
      fe_hours: phase.fe_hours || 0,
      hours_monthly: phase.hours_monthly || 0,
      name: phase.name,
      phase_type_id: phase.type.id,
      phase_responsible_users_attributes:
        phase.phase_responsible_users.map(({ user, id }) => ({
          user_id: user.id,
          id,
          _destroy: false,
        })) || [],
      pm_hours: phase.pm_hours || 0,
      qa_hours: phase.qa_hours || 0,
      settlement: phase.settlement || undefined,
      start_and_end_date:
        phase.start_date || phase.end_date
          ? [
              phase.start_date &&
                new Date(parseISO(phase.start_date.toString())),
              phase.end_date && new Date(parseISO(phase.end_date.toString())),
            ]
          : undefined,
      start_date:
        (phase.start_date && new Date(parseISO(phase.start_date.toString()))) ||
        undefined,
      status: phase.status,
    }));

  const getDefaultValues = () => {
    if (type === MODAL_TYPES.ADD || !project) {
      return {
        avatar: null,
        jira_key: '',
        jira_name: '',
        name: '',
        phases_attributes: [],
        project_type_id: undefined,
        repositories_attributes: [],
        toggl_name: '',
      };
    }

    return {
      avatar: project.avatar || null,
      isReadOnly,
      jira_key: project.jira_key,
      jira_name: project.jira_name,
      name: project.name,
      project_type_id: project.project_type.id,
      repositories_attributes: project.repositories,
      phases_attributes: getProjectPhases() || [],
      toggl_name: project.toggl_name,
    };
  };

  return (
    <>
      <Formik
        initialValues={getDefaultValues()}
        onSubmit={handleFormikSubmit}
        validationSchema={() =>
          validationSchema(t, projectsName, project?.name)
        }
        validateOnBlur={enableValidation}
        validateOnChange={enableValidation}
      >
        {({ handleSubmit, dirty, submitCount }) => {
          if (dirty && submitCount === 0) {
            confirmPageRefresh(true);
          } else {
            removeConfirmationOnPageRefresh();
          }

          return (
            <Form
              onSubmit={(event) => {
                setEnableValidation(true);
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Prompt
                when={dirty && submitCount === 0}
                message={t('prompts.userEditForm')}
              />
              <GeneralInfoFields isReadOnly={isReadOnly} />
              <Box mt={7} sx={{ textAlign: 'right' }}>
                <Link
                  to={
                    type === MODAL_TYPES.ADD
                      ? '/projects'
                      : `/projects/${project?.id}`
                  }
                >
                  <Button variant="ghost" type="button">
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="submit"
                  onClick={checkPageForErrorsAndChangeActivity}
                >
                  {actionButtonCopy}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
