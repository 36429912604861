import React, { useEffect, useState } from 'react';

import { theme } from 'styles/theme';

import { AvatarBox, AvatarImage } from './styled';

type Props = {
  name?: string;
  src?: string | null;
  size?: 'sm' | 'md' | 'lg' | 'small';
  radius?: keyof typeof theme.radii;
  placeholder?: React.ReactNode;
  placeholderVariant?: 'default' | 'white';
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const Avatar: React.FC<Props> = React.memo(
  ({
    name,
    src,
    size = 'md',
    radius = 'md',
    placeholder,
    placeholderVariant,
    onClick,
    ...rest
  }) => {
    const [isImageLoaded, setImageLoaded] = useState(true);
    const [firstName, lastName] = name
      ? name.toUpperCase().split(' ')
      : ['N', 'N'];

    useEffect(() => {
      setImageLoaded(true);
    }, [src]);

    const getInitials = () => {
      if (firstName && lastName) {
        return firstName[0] + lastName[0];
      }

      return firstName[0];
    };

    const handleError = () => {
      setImageLoaded(false);
    };

    if (src && isImageLoaded) {
      return (
        <AvatarImage
          {...rest}
          clickable={Boolean(onClick)}
          onClick={onClick}
          onError={handleError}
          placeholderVariant={placeholderVariant}
          radius={radius}
          size={size}
          src={src}
          tabIndex={Boolean(onClick) ? 0 : -1}
        />
      );
    }

    return (
      <AvatarBox
        size={size}
        radius={radius}
        onClick={onClick}
        placeholderVariant={placeholderVariant}
        clickable={Boolean(onClick)}
        tabIndex={Boolean(onClick) ? 0 : -1}
      >
        {placeholder || getInitials()}
      </AvatarBox>
    );
  },
);
