import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { theme } from 'styles/theme';

// todo: font weight to be adjusted

export const StyledLink = styled(Box)<{
  fontSize?: string;
  fontFamily?: string;
}>`
  font-family: ${({ fontFamily }) => fontFamily || theme.fonts.bold};
  font-size: ${({ fontSize }) => fontSize || theme.fontSizes.xs};
  line-height: ${theme.lineHeights.md2};
  font-weight: ${theme.fonts.bold};
`;
