import React from 'react';

import { InputSwitchProps } from 'primereact/inputswitch';

import { StyledSwitch } from './styled';

export interface SwitchCustomProps {
  dark?: string;
}

export const PrimeSwitch = (props: InputSwitchProps & SwitchCustomProps) => {
  return <StyledSwitch {...props} />;
};
