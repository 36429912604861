import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';

import { clearUserFilters } from '../../../Users/slices/usersFiltersSlice';
import { validationSchema } from '../Edit/validationSchema';
import { UserPayload } from 'api/users/models';
import { Card, CardStack, FormHeader } from 'components/primitive';
import { BackLink, Button, Spin } from 'components/ui';
import { useSkills } from 'fetchers/hooks/users/useSkills';
import { useUsers } from 'fetchers/hooks/users/useUsers';
import { Formik, Form } from 'formik';
import { Box } from 'rebass';
import GeneralInfoFields, {
  FORM_TYPES,
} from 'screens/User/components/GeneralInfoFields';
import { UserFormWrapper } from 'screens/User/components/styled';
import { addUser } from 'store/slices/usersSlice';
import checkPageForErrors from 'utils/checkForErrors';
import { confirmPageRefresh } from 'utils/functions';

const UserAdd: React.FC = () => {
  const dispatch = useDispatch();
  const { users } = useUsers();
  const { t } = useTranslation();

  const { skills } = useSkills();

  const handleSubmit = ({ full_name, ...values }: UserPayload) => {
    const trimmed_full_name = full_name ? full_name?.trim?.() : undefined;

    dispatch(addUser({ full_name: trimmed_full_name, ...values }));
  };

  useEffect(() => {
    dispatch(clearUserFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      confirmPageRefresh(false);
    };
  }, []);

  const sortedUsers = useMemo(() => {
    if (users) {
      return users
        .filter(({ full_name }) => full_name && full_name !== ' ')
        .sort((a, b) => a.full_name.localeCompare(b.full_name));
    }
    return [];
  }, [users]);

  const getDefaultValues = () => ({
    full_name: '',
    email: '',
    sex: '',
    use_google_avatar: false,
    photo: '',
    team_id: undefined,
    position_id: undefined,
    department_id: undefined,
    level: undefined,
    skills_attributes: [],
    github_nick: '',
    hours_weekly: 40,
    user_types: [],
    available_resource: false,
    active: false,
    team_member_supervisors_attributes: undefined,
    show_jira_tickets: true,
  });

  const checkPageForErrorsAndChangeActivity = () => {
    checkPageForErrors();
  };

  return (
    <>
      <BackLink />
      <UserFormWrapper>
        <FormHeader>Add new team member</FormHeader>
        {skills && users ? (
          <Formik
            initialValues={getDefaultValues()}
            onSubmit={handleSubmit}
            validationSchema={() => validationSchema(t)}
          >
            {({ dirty, submitCount }) => {
              if (dirty && submitCount === 0) {
                confirmPageRefresh(true);
              }

              return (
                <Form>
                  <Prompt
                    when={dirty && submitCount === 0}
                    message={t('prompts.userEditForm')}
                  />
                  <CardStack>
                    <Card>
                      <GeneralInfoFields
                        users={sortedUsers}
                        skills={skills}
                        type={FORM_TYPES.ADD}
                      />
                    </Card>
                    <Card>
                      <Box sx={{ textAlign: 'center' }} pb="4">
                        <Link to="/users">
                          <Button variant="ghost" type="button">
                            Cancel
                          </Button>
                        </Link>
                        <Button
                          ml={2}
                          type="submit"
                          onClick={checkPageForErrorsAndChangeActivity}
                        >
                          Save user
                        </Button>
                      </Box>
                    </Card>
                  </CardStack>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Spin />
        )}
      </UserFormWrapper>
    </>
  );
};

export default UserAdd;
