import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const NotFoundBox = styled.div`
  position: absolute;
  transform: translate(-50%);
  top: 45%;
  left: 50%;

  max-width: 400px;
  width: 100%;
  padding: 20px 20px 25px 20px;
  border: 1px solid ${theme.colors.neutral};
  border-radius: ${theme.radii.md};
  background-color: ${theme.colors.white};
  text-align: center;
`;

export const NotFoundTitle = styled.h3`
  font-size: ${theme.fontSizes.lg};
  font-family: ${theme.fonts.bold};
`;

export const NotFoundDescription = styled.p`
  font-size: ${theme.fontSizes.md};
`;
