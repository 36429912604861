import { mutateIntegrationStatus } from '../mutations/mutateIntegrationStatus';
import { mutateOrganizationSettings } from '../mutations/mutateOrganizationSettings';
import { organizationSettingsAPI } from 'api/organizationSettings/endpoints';
import { PersonioIntegrationPayload } from 'api/organizationSettings/models';

export const addPersonioIntegration = async (
  formData: PersonioIntegrationPayload,
) => {
  await organizationSettingsAPI.addPersonioIntegration(formData);
  await mutateOrganizationSettings();
  await mutateIntegrationStatus();
};
