import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';

import { UserPreferencesPayload } from 'api/users/endpoints';
import { FormikCheckbox } from 'components/forms/formik-prime';
import { PrimeEvent } from 'components/forms/formik-prime';
import { FormikTextarea } from 'components/forms/formik-prime/Textarea/Textarea';
import { FullScreenLoader } from 'components/ui';
import { useUser } from 'fetchers/hooks/users/useUser';
import { Form, Formik, FormikState } from 'formik';
import { setPreferences } from 'screens/Users/slices/preferencesSlice';
import {
  confirmPageRefresh,
  removeConfirmationOnPageRefresh,
} from 'utils/functions';

import { formData, onPreferencesChange } from './helpers';
import {
  HeadingWrapper,
  Heading,
  Container,
  InputBox,
  ButtonContainer,
  StyledButton,
} from './styled';
import { validationSchema } from './validationSchema';

const ProjectPreferences = () => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();
  const { user } = useUser(userId);
  const history = useHistory();

  useEffect(() => {
    return () => {
      confirmPageRefresh(false);
    };
  }, []);

  const preferences = user?.project_preferences;

  const [teamSizePreferences, setTeamSizePreferences] = useState<string[]>(
    preferences?.team_size || [],
  );
  const [appTypePreferences, setAppTypePreferences] = useState<string[]>(
    preferences?.app_type || [],
  );
  const [projectTypePreferences, setProjectTypePreferences] = useState<
    string[]
  >(preferences?.project_type || []);

  const dispatch = useDispatch();

  const initialValues = {
    app_type: preferences?.app_type || [],
    description: preferences?.description || '',
    project_type: preferences?.project_type || [],
    team_size: preferences?.team_size || [],
  };

  const getDefaultValues = () => {
    return initialValues;
  };

  const handleResetForm = (
    reset: (
      nextState?: Partial<FormikState<UserPreferencesPayload>> | undefined,
    ) => void,
  ) => {
    reset({ values: initialValues });
    setTeamSizePreferences(preferences?.team_size || []);
    setAppTypePreferences(preferences?.app_type || []);
    setProjectTypePreferences(preferences?.project_type || []);
  };
  const hasChanged = () =>
    preferences?.team_size !== teamSizePreferences ||
    preferences?.app_type !== appTypePreferences ||
    preferences?.project_type !== projectTypePreferences;

  const handleMoveToProfile = () => history.push(`/users/${userId}`);

  const handleSubmit = (values: UserPreferencesPayload) => {
    const newValues = {
      ...values,
      id: userId,
      app_type: appTypePreferences,
      project_type: projectTypePreferences,
      team_size: teamSizePreferences,
    };

    dispatch(setPreferences(newValues));
    handleMoveToProfile();
  };

  const { teamSizeItems, webOrMobileItems, projectTypeItems } = formData;

  return preferences ? (
    <Formik
      initialValues={getDefaultValues()}
      onSubmit={handleSubmit}
      validationSchema={() => validationSchema(t)}
    >
      {({ dirty, handleSubmit, resetForm, submitCount, values }) => {
        const isChanged = hasChanged();
        if ((dirty || isChanged) && submitCount === 0) {
          confirmPageRefresh(true);
        } else {
          removeConfirmationOnPageRefresh();
        }

        return (
          <Form
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <Prompt
              when={(dirty || isChanged) && submitCount === 0}
              message={t('prompts.userEditForm')}
            />
            <Container>
              <ButtonContainer>
                <StyledButton
                  type="button"
                  onClick={() => handleResetForm(resetForm)}
                >
                  {t('common.reset')}
                </StyledButton>
                <StyledButton type="submit">
                  {t('common.saveChanges')}
                </StyledButton>
              </ButtonContainer>
              <HeadingWrapper>
                <Heading>
                  {t('userProfile.preferencesForm.projectPreferencesTitle')}
                </Heading>
              </HeadingWrapper>
              <InputBox>
                <FormikTextarea
                  label={t(
                    'userProfile.preferencesForm.projectPreferencesDescription',
                  )}
                  placeholder={t(
                    'userProfile.preferencesForm.projectPreferencesPlaceholder',
                  )}
                  name="description"
                  value={values.description}
                />
              </InputBox>

              <HeadingWrapper>
                <Heading>
                  {t('userProfile.preferencesForm.teamSizeTitle')}
                </Heading>
              </HeadingWrapper>
              <InputBox>
                {teamSizeItems.map(({ value }) => (
                  <FormikCheckbox
                    key={value}
                    name="team_size"
                    value={value}
                    onChange={(event: PrimeEvent) =>
                      onPreferencesChange(
                        event,
                        teamSizePreferences,
                        setTeamSizePreferences,
                      )
                    }
                    checked={teamSizePreferences.includes(value)}
                  />
                ))}
              </InputBox>
              <HeadingWrapper>
                <Heading>
                  {t('userProfile.preferencesForm.webMobileTitle')}
                </Heading>
              </HeadingWrapper>
              <InputBox>
                {webOrMobileItems.map(({ value }) => (
                  <FormikCheckbox
                    key={value}
                    name="app_type"
                    value={value}
                    onChange={(event: PrimeEvent) =>
                      onPreferencesChange(
                        event,
                        appTypePreferences,
                        setAppTypePreferences,
                      )
                    }
                    checked={appTypePreferences.includes(value)}
                  />
                ))}
              </InputBox>

              <HeadingWrapper>
                <Heading>{t('projects.projectType')}</Heading>
              </HeadingWrapper>
              <InputBox>
                {projectTypeItems.map(({ value }) => (
                  <FormikCheckbox
                    key={value}
                    name="project_type"
                    value={value}
                    onChange={(event: PrimeEvent) =>
                      onPreferencesChange(
                        event,
                        projectTypePreferences,
                        setProjectTypePreferences,
                      )
                    }
                    checked={projectTypePreferences.includes(value)}
                  />
                ))}
              </InputBox>
            </Container>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <FullScreenLoader />
  );
};

export default ProjectPreferences;
