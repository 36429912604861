import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormikTextInput } from 'components/forms/formik-prime';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { Form } from 'formik';
import { Box, Flex } from 'rebass';
import { HorizontalLine } from 'screens/User/components/GeneralInfoFields';

import { LdapFormik } from './LdapFormik';

interface Props {
  onClose: () => void;
  setIsIntegrated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LdapIntegrationForm = ({ onClose, setIsIntegrated }: Props) => {
  const { t } = useTranslation();

  const onSuccess = () => {
    setIsIntegrated(true);
    onClose();
  };

  return (
    <LdapFormik onSuccess={onSuccess}>
      <Form>
        <Flex flexDirection="column">
          <FormikTextInput
            name="username"
            label={t('organizationSettings.ldap.fields.username')}
            ishigh={'false'}
            variant="default"
          />
          <FormikTextInput
            name="password"
            label={t('organizationSettings.ldap.fields.password')}
            ishigh={'false'}
            variant="default"
          />
          <FormikTextInput
            name="ldap_host"
            label={t('organizationSettings.ldap.fields.ldap_host')}
            ishigh={'false'}
            variant="default"
          />
          <FormikTextInput
            name="ldap_base"
            label={t('organizationSettings.ldap.fields.ldap_base')}
            ishigh={'false'}
            variant="default"
          />
          <FormikTextInput
            name="ldap_port"
            label={t('organizationSettings.ldap.fields.ldap_port')}
            ishigh={'false'}
            variant="default"
          />
          <HorizontalLine />
          <Flex justifyContent="flex-end">
            <Box mr={3}>
              <PrimeTextButton onClick={onClose} label={t('common.cancel')} />
            </Box>
            <NewStyledButton
              type="submit"
              data-cy="ldap-submit-btn"
              label={t('organizationSettings.ldap.connect')}
            />
          </Flex>
        </Flex>
      </Form>
    </LdapFormik>
  );
};
