import { Dialog } from '../Dialog';
import styled from '@emotion/styled/macro';

interface Props {
  width?: string;
}

export const SideDialog = styled(Dialog)<Props>`
  width: ${({ width }) => (width ? width : '460px')};
  height: 100%;
  max-height: calc(100% - 98px);
`;
