import React, { LegacyRef } from 'react';

import { Error, Wrapper } from '../styled';
import { Textarea, TextareaLabel } from '../styled';
import { useField } from 'formik';
import { InputTextareaProps } from 'primereact/inputtextarea';

type Props = InputTextareaProps & {
  name: string;
  value: string;
  validate?: (value: number) => undefined | string | Promise<any>;
};

export const FormikTextarea = React.forwardRef(
  (
    {
      name,
      onChange: customOnChange,
      onBlur: customOnBlur,
      validate,
      label,
      placeholder,
      value,
      ...restProps
    }: Props,
    ref: LegacyRef<HTMLTextAreaElement>,
  ) => {
    const [field, meta] = useField({ name, validate });
    return (
      <Wrapper>
        <TextareaLabel>{label}</TextareaLabel>
        <Textarea
          {...field}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            field.onChange(event);
            customOnChange && customOnChange(event);
          }}
          onBlur={(event: React.FocusEvent<HTMLTextAreaElement>) => {
            field.onBlur(event);
            customOnBlur && customOnBlur(event);
          }}
          placeholder={placeholder}
          value={value}
          {...restProps}
          ref={ref}
        />
        {meta.touched && meta.error && <Error>{meta.error}</Error>}
      </Wrapper>
    );
  },
);
