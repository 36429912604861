import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ClearFiltersButton } from '../ClearFiltersButton';
import { CommonSettingsFields } from 'api/users/models';
import { Chip, Switch } from 'components/forms';
import { PrimeMultiSelect } from 'components/prime';
import { Avatar, Button, Spin } from 'components/ui';
import { useProjectsNames } from 'fetchers';
import { usePersonsResponsible } from 'fetchers/hooks/usePersonsResponsible';
import { usePhaseTypes } from 'fetchers/hooks/usePhaseTypes';
import { useProjectTypes } from 'fetchers/hooks/users/useProjectTypes';
import { useUsers } from 'fetchers/hooks/users/useUsers';
import { Flex } from 'rebass';
import {
  ProjectFilters,
  selectProjectsFiltersData,
  setProjectFilter,
} from 'screens/Projects/projectsFiltersSlice';
import { getTeams, selectSortedTeams } from 'screens/Users/slices/teamsSlice';
import { useKeyPress } from 'utils/hooks/useKeyPress';
import { useSortByUserFirst } from 'utils/hooks/useSortByUserFirst';
import { sortByKey } from 'utils/sortByKey';

import { MultiSelectLabel, OptionBox } from './styled';

type Props = {
  onClose: () => void;
};

type InitialProjectFilters = {
  projectsId?: number[];
  personsResponsible?: string[];
  members?: number[];
  projectTypes?: string[];
  isActive?: boolean;
  isArchived?: boolean;
  teamsIds?: number[];
  phaseTypesIds?: number[];
};

const ProjectsFilters = ({ onClose }: Props) => {
  const initialFilters = useRef<InitialProjectFilters>({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    projectsId: selectedProjectsIds,
    personsResponsible,
    members,
    projectTypes,
    isActive,
    teamsIds: selectedTeamsIds,
    phaseTypesIds,
    isArchived,
  } = useSelector(selectProjectsFiltersData);
  const modalContainer = document.querySelector('#modal-root') as HTMLElement;

  useEffect(() => {
    initialFilters.current = {
      projectsId: selectedProjectsIds,
      personsResponsible,
      members,
      projectTypes,
      isActive,
      teamsIds: selectedTeamsIds,
      phaseTypesIds,
      isArchived,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getTeams());
  }, [dispatch]);

  const { isLoading: areProjectsLoading, projectsNames } = useProjectsNames();
  const { isLoading: arePhasesLoading, phaseTypes } = usePhaseTypes();
  const { projectTypes: allProjectTypes } = useProjectTypes();
  const teams = useSelector(selectSortedTeams);

  const {
    isLoading: arePersonsResponsibleLoading,
    personsResponsible: allPersonsResponsible,
  } = usePersonsResponsible();
  const { isLoading: areUsersLoading, users: allUsers } = useUsers();

  const sortedProjects = useMemo(
    () => (projectsNames || []).sort(sortByKey('name')),
    [projectsNames],
  );

  const sortedPhases = useMemo(
    () => (phaseTypes || []).sort(sortByKey('name')),
    [phaseTypes],
  );
  const sortedPersonsResponsible = useSortByUserFirst(
    allPersonsResponsible || [],
    'full_name',
  );
  const sortedMembers = useSortByUserFirst(allUsers || [], 'full_name');

  const setFilter = (filterName: ProjectFilters, value: any) => {
    dispatch(setProjectFilter(filterName, value));
  };

  const onCancel = () => {
    const {
      projectsId,
      personsResponsible,
      members,
      projectTypes,
      isActive,
      teamsIds,
      phaseTypesIds,
      isArchived,
    } = initialFilters.current;
    setFilter(ProjectFilters.PROJECTS_ID, projectsId);
    setFilter(ProjectFilters.PERSONS_RESPONSIBLE, personsResponsible);
    setFilter(ProjectFilters.MEMBERS, members);
    setFilter(ProjectFilters.PROJECT_TYPES, projectTypes);
    setFilter(ProjectFilters.IS_ACTIVE, isActive);
    setFilter(ProjectFilters.TEAMS_IDS, teamsIds);
    setFilter(ProjectFilters.PHASE_TYPES_IDS, phaseTypesIds);
    setFilter(ProjectFilters.IS_ARCHIVED, isArchived);
    onClose();
  };

  useKeyPress({
    targetKey: 'Escape',
    downHandler: onCancel,
    listenWhen: isActive,
  });

  const renderItemWithAvatar = (
    option: any,
    nameKey: string,
    avatarKey: string = 'avatar',
  ) => {
    return (
      <Flex alignItems="center">
        <Avatar
          size="sm"
          name={option[nameKey]}
          src={option[avatarKey]?.mini?.url || 'no_url'}
          radius="xmd"
        />
        <OptionBox ml={2}>{option[nameKey]}</OptionBox>
      </Flex>
    );
  };

  const renderSelectedItem = (
    item: number,
    key: string,
    filter: ProjectFilters,
    selectedItems: number[],
    allOptions: CommonSettingsFields[],
  ) => {
    const selectedItem = allOptions?.find((option) => option.id === item);

    if (selectedItem) {
      return (
        <Chip
          key={`projects.filters.${key}-${item}`}
          onRemove={() => {
            const newSelectedFilters = selectedItems.filter(
              (value) => value !== item,
            );
            setFilter(filter, newSelectedFilters);
          }}
          label={selectedItem.full_name || selectedItem.name}
        />
      );
    }

    return undefined;
  };

  return (
    <>
      <Flex width="100%">
        {areProjectsLoading ||
        arePersonsResponsibleLoading ||
        areUsersLoading ||
        arePhasesLoading ? (
          <Spin />
        ) : (
          <Flex width="100%" flexDirection="column">
            <MultiSelectLabel>{t('projects.onlyActive')}</MultiSelectLabel>
            <Switch
              data-cy="is-only-phases-switch"
              isChecked={isActive}
              onChange={(event) => {
                setFilter(ProjectFilters.IS_ARCHIVED, false);
                setFilter(ProjectFilters.IS_ACTIVE, event.target.checked);
              }}
              last
            />

            <MultiSelectLabel>{t('projects.onlyArchived')}</MultiSelectLabel>
            <Switch
              data-cy="is-only-archived-switch"
              isChecked={isArchived}
              onChange={(event) => {
                setFilter(ProjectFilters.IS_ACTIVE, false);
                setFilter(ProjectFilters.IS_ARCHIVED, event.target.checked);
              }}
              last
            />

            <MultiSelectLabel>{t('projects.projects')}</MultiSelectLabel>
            <PrimeMultiSelect
              value={selectedProjectsIds}
              options={sortedProjects}
              optionLabel="name"
              optionValue="id"
              onChange={(e) =>
                setFilter(ProjectFilters.PROJECTS_ID, e.value || [])
              }
              placeholder={t('projects.selectProjects')}
              filter
              display="chip"
              showClear={true}
              itemTemplate={(item) => renderItemWithAvatar(item, 'name')}
              appendTo={modalContainer}
              selectedItemTemplate={(item) =>
                item &&
                renderSelectedItem(
                  item,
                  'projects',
                  ProjectFilters.PROJECTS_ID,
                  selectedProjectsIds,
                  sortedProjects,
                )
              }
            />

            <MultiSelectLabel>
              {t('projects.personsResponsible')}
            </MultiSelectLabel>
            <PrimeMultiSelect
              value={personsResponsible}
              options={sortedPersonsResponsible}
              optionLabel="full_name"
              optionValue="id"
              onChange={(e) =>
                setFilter(ProjectFilters.PERSONS_RESPONSIBLE, e.value || [])
              }
              placeholder={t('projects.selectPersonResponsible')}
              filter
              display="chip"
              showClear={true}
              itemTemplate={(item) =>
                renderItemWithAvatar(item, 'full_name', 'photo')
              }
              appendTo={modalContainer}
              selectedItemTemplate={(item) =>
                item &&
                renderSelectedItem(
                  item,
                  'person-responsible',
                  ProjectFilters.PERSONS_RESPONSIBLE,
                  (personsResponsible as unknown) as number[],
                  sortedPersonsResponsible,
                )
              }
            />

            <MultiSelectLabel>{t('projects.members')}</MultiSelectLabel>
            <PrimeMultiSelect
              value={members}
              options={sortedMembers}
              optionLabel="full_name"
              optionValue="id"
              onChange={(e) => setFilter(ProjectFilters.MEMBERS, e.value || [])}
              placeholder={t('projects.selectMembers')}
              filter
              display="chip"
              showClear={true}
              itemTemplate={(item) =>
                renderItemWithAvatar(item, 'full_name', 'photo')
              }
              appendTo={modalContainer}
              selectedItemTemplate={(item) =>
                item &&
                renderSelectedItem(
                  item,
                  'members',
                  ProjectFilters.MEMBERS,
                  members,
                  sortedMembers,
                )
              }
            />

            <MultiSelectLabel>{t('projects.projectTypes')}</MultiSelectLabel>
            <PrimeMultiSelect
              value={projectTypes}
              options={allProjectTypes}
              optionLabel="name"
              optionValue="id"
              showClear={true}
              display="chip"
              filter
              onChange={(e) =>
                setFilter(ProjectFilters.PROJECT_TYPES, e.value || [])
              }
              placeholder={t('projects.selectProjectTypes')}
              appendTo={modalContainer}
              selectedItemTemplate={(item) =>
                item &&
                renderSelectedItem(
                  item,
                  'projectTypes',
                  ProjectFilters.PROJECT_TYPES,
                  (projectTypes as unknown) as number[],
                  allProjectTypes,
                )
              }
            />

            <MultiSelectLabel>{t('users.filters.teams')}</MultiSelectLabel>
            <PrimeMultiSelect
              value={selectedTeamsIds}
              options={teams}
              optionLabel="name"
              optionValue="id"
              showClear={true}
              display="chip"
              filter
              onChange={(e) =>
                setFilter(ProjectFilters.TEAMS_IDS, e.value || [])
              }
              placeholder={t('projects.selectTeams')}
              appendTo={modalContainer}
              selectedItemTemplate={(item) =>
                item &&
                renderSelectedItem(
                  item,
                  'teams_id',
                  ProjectFilters.TEAMS_IDS,
                  selectedTeamsIds,
                  teams,
                )
              }
            />

            <MultiSelectLabel>{t('projects.phases')}</MultiSelectLabel>
            <PrimeMultiSelect
              value={phaseTypesIds}
              options={sortedPhases}
              optionLabel="name"
              optionValue="id"
              showClear={true}
              display="chip"
              filter
              onChange={(e) =>
                setFilter(ProjectFilters.PHASE_TYPES_IDS, e.value || [])
              }
              placeholder={t('projects.selectProjectPhase')}
              appendTo={modalContainer}
              selectedItemTemplate={(item) =>
                item &&
                renderSelectedItem(
                  item,
                  'phase_types_ids',
                  ProjectFilters.PHASE_TYPES_IDS,
                  phaseTypesIds,
                  sortedPhases,
                )
              }
            />
            <Flex width="100%" justifyContent="flex-end" mt={4}>
              <ClearFiltersButton />
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex justifyContent="space-between" marginTop="auto" width="100%">
        <Button variant="ghost" data-cy="close-modal-btn" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button data-cy="add-member-btn" onClick={onClose}>
          {t('common.applyFilters')}
        </Button>
      </Flex>
    </>
  );
};

export default ProjectsFilters;
