import styled from '@emotion/styled/macro';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

export const CardContainer = styled(Flex)`
  min-height: 70vh;
  justify-content: center;
  align-items: center;
`;

export const StyledCard = styled(Card)`
  &.p-card {
    box-shadow: none;
    width: 690px;
  }
  & .p-card-content {
    padding: 0 ${theme.space[2]};
  }
`;

export const Heading = styled.h1`
  text-align: left;
  font-size: ${theme.fontSizes.sm};
  font-weight: 400;
  color: ${theme.colors.primeDarkGrey};
  margin-top: ${theme.space[2]};
`;

export const InputContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
  padding: ${theme.space[9]} 0 ${theme.space[8]};
`;

export const StyledButton = styled(Button)`
  border: 1.5px solid ${theme.colors.primaryLight};
  background-color: ${theme.colors.white};
  border-radius: ${theme.radii.xs};
  padding: ${theme.space[4]};
  margin-right: ${theme.space[2]};

  &.p-button {
    height: ${theme.space[9]};
    color: ${theme.colors.primaryLight};
    font-size: ${theme.fontSizes.sm};

    &:hover {
      background-color: ${theme.colors.primaryLight};
      box-shadow: ${theme.shadows.mainButton};
    }
    &:focus {
      box-shadow: ${theme.shadows.outline};
    }
  }

  &.p-disabled {
    border-color: ${theme.colors.primeDarkGrey};
    color: ${theme.colors.primeDarkGrey};
    opacity: 0.2;
  }
`;

export const Footer = styled(Flex)`
  justify-content: flex-end;
  border-top: 1px solid ${theme.colors.lightestGrey};
  padding-top: ${theme.space[5]};
`;
