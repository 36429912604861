import { mutateIntegrationStatus } from '../mutations/mutateIntegrationStatus';
import { mutateOrganizationSettings } from '../mutations/mutateOrganizationSettings';
import { organizationSettingsAPI } from 'api/organizationSettings/endpoints';
import { CalamariIntegrationPayload } from 'api/organizationSettings/models';

export const addCalamariIntegration = async (
  formData: CalamariIntegrationPayload,
) => {
  await organizationSettingsAPI.addCalamariIntegration(formData);
  await mutateOrganizationSettings();
  await mutateIntegrationStatus();
};
