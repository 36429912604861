import { UsefulLink } from 'api/projects/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

export const useProjectsUsefulLinks = (projectId: string) => {
  const { data, mutate } = useSWR<{ useful_links: UsefulLink[] }>(
    `projects/${projectId}/useful_links`,
    fetcher,
  );

  return {
    isLoading: !data,
    usefulLinks: data?.useful_links,
    mutate,
  };
};
