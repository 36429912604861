import React from 'react';

import { TableItemWrapper } from './styled';

type Props = {
  as?: React.ElementType;
  isExpanded?: boolean;
  values: {
    [key: string]: any;
  };
  even?: boolean;
};

export const TableItem: React.FC<Props> = React.memo(
  ({ children, ...props }) => {
    return <TableItemWrapper {...props}>{children}</TableItemWrapper>;
  },
  (left, right) =>
    left.values === right.values && left.isExpanded === right.isExpanded,
);
