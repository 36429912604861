import { useMemo } from 'react';

import {
  AtlassianFields,
  AtlassianSettings,
  CalamariSettings,
  CompanyIntegration,
  EnabledFeatures,
  IntegrateApps,
  IntegrateAppsType,
  IntegrationService,
  OrganizationSettings,
  OrganizationSettingsPayload,
  RollbarSettings,
  TempoSettings,
} from 'api/organizationSettings/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

const API_URL = '/company';

type Settings = {
  atlassian: AtlassianFields;
  github: unknown;
  calamari: CalamariSettings;
  toggl: unknown;
  slack: unknown;
  ldap: unknown;
  personio: unknown;
  google_drive: unknown;
  tempo: TempoSettings;
  rollbar: RollbarSettings;
};

const findIntegrationSettings = (
  serviceName: IntegrationService,
  integrations?: CompanyIntegration[],
) =>
  integrations?.find((integration) => integration.service_name === serviceName);

export const useOrganizationSettings = (): {
  isLoading: boolean;
  organizationSettings?: OrganizationSettingsPayload;
  integrations: IntegrateAppsType | undefined;
  mutate: any;
  settings: Settings;
  enabledFeatures?: EnabledFeatures;
} => {
  const { data, mutate } = useSWR<OrganizationSettings>(
    '/companySettings',
    () => fetcher(API_URL),
  );
  const organizationSettings = useMemo(() => data?.company.settings, [data]);

  const integrations = useMemo(() => {
    const servicesNames = data?.company?.integrations
      .filter((integration) => integration.active)
      .map((integration) => integration.service_name);

    if (servicesNames && servicesNames?.length > 0) {
      const int: IntegrateAppsType = { ...IntegrateApps };
      Object.keys(int).forEach((key) => {
        int[key as IntegrationService] =
          servicesNames.includes(key as IntegrationService) || false;
      });
      return int;
    }
  }, [data]);

  const settings = useMemo<Settings>(() => {
    const int: any = {};
    Object.keys(IntegrateApps).forEach((key) => {
      int[key] = findIntegrationSettings(
        key as IntegrationService,
        data?.company.integrations,
      );
    });
    return int as Settings;
  }, [data]);

  return {
    isLoading: !data,
    organizationSettings,
    integrations,
    mutate,
    settings,
    enabledFeatures: data?.company.enabled_features,
  };
};
