import styled from '@emotion/styled/macro';
import { Divider } from 'primereact/divider';
import { theme } from 'styles/theme';

export const StyledDivider = styled(Divider)`
  background-color: ${theme.colors.primaryLight};
  opacity: 0.1;
  height: 1px;
  margin-bottom: ${theme.space[2]};
`;
