import { ProjectAvatar, Sprint } from 'api/projects/models';
import { compareDesc } from 'date-fns';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

const API_URL = '/projects/sprints';

export type ProjectSprint = {
  id: number;
  name: string;
  sprints: Sprint[];
  avatar: ProjectAvatar;
};

type Dashboard = {
  projects: ProjectSprint[];
};

const sortByDate = (a: Sprint, b: Sprint) =>
  compareDesc(new Date(a.start_date), new Date(b.start_date));

export const useDashboard = () => {
  const { data } = useSWR<Dashboard>(API_URL, fetcher);

  const projectsSprints = data?.projects.map(
    ({ name, sprints, avatar, ...rest }) => ({
      ...rest,
      name,
      avatar,
      sprints: [...sprints].sort(sortByDate),
    }),
  );

  return {
    isLoading: !data,
    projectsSprints,
  };
};
