import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

interface Props {
  even?: boolean;
  isExpanded?: boolean;
}

export const TableItemWrapper = styled(Flex)<Props>`
  position: relative;
  justify-content: center;
  flex-direction: column;
  box-shadow: ${theme.shadows.md40};
  color: ${theme.colors.darkGray};
  outline: none;

  &:last-child {
    border-radius: 0 0 ${theme.radii.lg} ${theme.radii.lg};
  }

  &:nth-of-type(even) {
    background-color: ${theme.colors.newLightGrey};
  }
`;
