import { mutateProject } from '../mutations/mutateProject';
import { phasesAPI } from 'api/phases/endpoints';
import { mutateProjects } from 'fetchers/mutations/mutateProjects';
import { mutateProjectsWeeks } from 'fetchers/mutations/mutateProjectsWeeks';

export const removeUserFromPhase = async (
  phaseUserRangeId: number,
  projectId?: number,
) => {
  await phasesAPI.deleteMemberFromPhase(phaseUserRangeId);

  if (projectId) {
    await mutateProject(projectId.toString());
  }
  await mutateProjects();
  await mutateProjectsWeeks();
};
