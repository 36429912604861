import { mutateProject } from '../mutations/mutateProject';
import { phasesAPI } from 'api/phases/endpoints';
import { mutateProjects } from 'fetchers/mutations/mutateProjects';
import { mutateProjectsWeeks } from 'fetchers/mutations/mutateProjectsWeeks';

export enum REQUESTED_USER_ACTIONS {
  CONFIRM = 'confirm',
  DELETE = 'delete',
}

type MemberToPhase = {
  action: REQUESTED_USER_ACTIONS;
  phaseUserRangeId: number;
  projectId: number;
};

export const setRequestedMemberToPhase = async ({
  action,
  phaseUserRangeId,
  projectId,
}: MemberToPhase) => {
  switch (action) {
    case REQUESTED_USER_ACTIONS.CONFIRM:
      await phasesAPI.confirmMemberToPhase(phaseUserRangeId);
      break;
    case REQUESTED_USER_ACTIONS.DELETE:
      await phasesAPI.deleteMemberFromPhase(phaseUserRangeId);
      break;
  }

  await mutateProject(projectId.toString());
  await mutateProjects();
  await mutateProjectsWeeks();
};
