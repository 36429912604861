import {
  Project,
  ProjectName,
  ProjectWeek,
  ProjectSprint,
  ProjectFormikForm,
  ProjectSettings,
} from 'api/projects/models';
import { AxiosResponse } from 'axios';
import { serialize } from 'object-to-formdata';
import {
  ProjectsPayload,
  ProjectsWeeksPayload,
} from 'store/slices/projectsSlice';
import httpClient from 'utils/httpClient';

const BASE_PATH = '/projects';

//#region Types
type WeeksRes = {
  weeks: ProjectWeek[];
};

type ProjectsRes = {
  projects: Project[];
};

type ProjectRes = {
  project: Project;
};

type ProjectsNamesRes = {
  projects: ProjectName[];
};

type ProjectsSprintsRes = {
  projects: ProjectSprint[];
};

//#endregion

//#region API
export const projectsAPI = {
  async getProjects({
    page,
    perPage,
  }: ProjectsPayload): Promise<AxiosResponse<ProjectsRes>> {
    return await httpClient.get(`${BASE_PATH}?page=${page}&per=${perPage}`);
  },

  async getProjectsWeeks({
    weeksNumbers,
    page,
    perPage,
  }: ProjectsWeeksPayload): Promise<AxiosResponse<WeeksRes>> {
    const weeksQuery = `&${weeksNumbers
      .map((week) => `weeks[]=${week}`)
      .join('&')}`;
    return await httpClient.get(
      `${BASE_PATH}/weeks?page=${page}&per=${perPage}${weeksQuery}`,
    );
  },

  async addProject(
    project: ProjectFormikForm,
  ): Promise<AxiosResponse<ProjectRes>> {
    const options = { indices: false };
    const formData = serialize({ project }, options);
    return await httpClient.post(`${BASE_PATH}`, formData);
  },

  async removeProject(id: number): Promise<AxiosResponse<ProjectRes>> {
    return await httpClient.delete(`${BASE_PATH}/${id}`);
  },

  async updateProject(
    id: number | string,
    project: ProjectFormikForm | ProjectSettings,
  ): Promise<AxiosResponse<ProjectRes>> {
    const options = { indices: false };
    const formData = serialize({ project }, options);

    return await httpClient.put(`${BASE_PATH}/${id}`, formData);
  },

  async getProjectById(projectId: number): Promise<AxiosResponse<ProjectRes>> {
    return await httpClient.get(`${BASE_PATH}/${projectId}`);
  },

  async getProjectsNames(): Promise<AxiosResponse<ProjectsNamesRes>> {
    return await httpClient.get(`${BASE_PATH}/names`);
  },

  async getFollowingWeeks(): Promise<AxiosResponse<any>> {
    return await httpClient.get(`/weeks`);
  },

  async getProjectsSprints(): Promise<AxiosResponse<ProjectsSprintsRes>> {
    return await httpClient.get(`${BASE_PATH}/sprints`);
  },

  async addProjectsUsefulLink(
    projectId: string,
    formData: FormData,
  ): Promise<AxiosResponse> {
    return await httpClient.post(
      `${BASE_PATH}/${projectId}/useful_links`,
      formData,
    );
  },

  async removeProjectsUsefulLink(
    projectId: string,
    id: string,
  ): Promise<AxiosResponse> {
    return await httpClient.delete(
      `${BASE_PATH}/${projectId}/useful_links/${id}`,
    );
  },

  async updateProjectPhase(
    projectId: string,
    id: string,
    formData: FormData,
  ): Promise<AxiosResponse> {
    return await httpClient.put(
      `${BASE_PATH}/${projectId}/phases/${id}`,
      formData,
    );
  },

  async addProjectPhase(
    projectId: string,
    formData: FormData,
  ): Promise<AxiosResponse> {
    return await httpClient.post(`${BASE_PATH}/${projectId}/phases`, formData);
  },

  async removeProjectPhase(
    projectId: string,
    id: number,
  ): Promise<AxiosResponse> {
    return await httpClient.delete(`${BASE_PATH}/${projectId}/phases/${id}`);
  },
};
//#endregion
