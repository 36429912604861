import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { UsefulLinkForm } from 'api/projects/models';
import { Spin } from 'components/ui';
import { addUsefulLink } from 'fetchers/actions/projectUsefulLinks/addProjectsUsefulLink';
import { useProjectsUsefulLinks } from 'fetchers/hooks/useProjectsUsefulLinks';
import { Formik } from 'formik';
import { Flex } from 'rebass';
import Title from 'screens/Project/components/Title/Title';
import UsefulLinksEditTable from 'screens/Project/screens/UsefulLinksEdit/components/UsefulLinksEditTable';

import {
  AddIcon,
  Button,
  Text,
  Field,
  Container,
  Form,
  Label,
  ErrorMessage,
  Wrapper,
  SubmitButton,
} from './UsefulLinksEdit.styles';
import { validationSchema } from './validationSchema';

const UsefulLinksEdit = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { isLoading, usefulLinks } = useProjectsUsefulLinks(projectId);

  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = (values: UsefulLinkForm) => {
    addUsefulLink(projectId, values.link, values.name);
    setIsFormVisible(false);
  };
  const initialValues = { link: '', name: '' };

  return (
    <>
      {isLoading ? (
        <Flex justifyContent="center">
          <Spin />
        </Flex>
      ) : (
        <Wrapper>
          <Title>{t('projectCard.settings.editSettings.editLinks')}</Title>

          <UsefulLinksEditTable
            data={usefulLinks || []}
            projectId={projectId}
          />

          {isFormVisible ? (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={() => validationSchema(t)}
            >
              {({ handleSubmit, touched, errors }) => {
                return (
                  <Form
                    style={{ width: '100%' }}
                    onSubmit={(event) => {
                      event.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <SubmitButton type="submit">
                      {t('common.saveChanges')}
                    </SubmitButton>
                    <Label htmlFor="link">
                      {t('projectCard.settings.editSettings.link')}
                    </Label>
                    <Field id="link" type="text" name="link" />

                    {touched.link && errors.link && (
                      <ErrorMessage>{errors.link}</ErrorMessage>
                    )}

                    <Label htmlFor="name">
                      {t('projectCard.settings.editSettings.description')}
                    </Label>
                    <Field id="name" type="text" name="name" />

                    {touched.name && errors.name && (
                      <ErrorMessage>{errors.name}</ErrorMessage>
                    )}
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <Container>
              <Button onClick={() => setIsFormVisible(true)}>
                <AddIcon />
                <Text>{t('projectCard.settings.editSettings.addNewLink')}</Text>
              </Button>
            </Container>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default UsefulLinksEdit;
