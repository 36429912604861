import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';

import styled from '@emotion/styled/macro';
import { verticalScroll } from 'components/primitive';
import { theme } from 'styles/theme';

type IconVisible = {
  visible: 1 | 0;
};

type PickerProps = {
  variant?: 'default' | 'table';
};

export const Wrapper = styled('div')<{ withBorder?: boolean }>`
  display: flex;
  border: ${({ withBorder }) =>
    withBorder ? `1px solid ${theme.colors.neutral}` : null};
`;

export const Picker = styled.button(({ variant }: PickerProps) => {
  const isTable = variant === 'table';
  return `
  position: relative;
  border-right: 1px solid ${theme.colors.neutral};
  background-color: ${theme.colors.white};
  width: ${isTable ? '350px' : '600px'};
  height: ${isTable ? '40px' : '50px'};
  margin: ${isTable ? '0' : '0 20px'};
  padding: 0 20px;
  transition: box-shadow 150ms;
  color: ${theme.colors.midGray};

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:focus {
    outline: none;
  }
`;
});

export const RefreshIcon = styled(IoMdClose)<IconVisible & PickerProps>`
  transition: opacity 200ms, transform 400ms ease-out;
  opacity: ${(props) => (props.visible === 1 ? '1' : '0')};
  pointer-events: ${(props) => (props.visible === 1 ? 'auto' : 'none')};
  margin-right: ${(props) =>
    props.variant === 'table' ? '0' : theme.space[4]};
`;

export const LeftIcon = styled(FiChevronLeft)`
  color: ${theme.colors.midGray};
`;

export const RightIcon = styled(FiChevronRight)`
  color: ${theme.colors.midGray};
`;

export const IconsWrapper = styled.div(({ variant }: PickerProps) => {
  const isTable = variant === 'table';

  return `
  display: flex;
  align-items: center;
  margin-left: ${isTable ? theme.space[1] : theme.space[5]};
`;
});

export const ScrollWrapper = styled.div`
  max-height: 320px;
  overflow: hidden;
  overflow-y: scroll;

  ${verticalScroll}
`;

export const PickerOptions = styled.ul(({ variant }: PickerProps) => {
  const isTable = variant === 'table';

  return `
  background-color: ${theme.colors.white};
  width: ${isTable ? '450px' : '100%'};
  box-shadow: ${theme.shadows.weekPickerShadow};

  position: absolute;
  top: 41px;
  left: -51px;

  z-index: ${theme.zIndices['6']};
`;
});

export const SideButton = styled.button<{
  table: boolean;
  isRightSide?: boolean;
  noRadius?: boolean;
  noBorder?: boolean;
}>`
  background-color: ${theme.colors.white};
  min-width: 50px;
  height: ${({ table }) => (table ? '40px' : '50px')};
  color: ${theme.colors.midGray};
  transition: box-shadow 150ms, color 150ms, background-color 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: ${({ noBorder }) =>
    !noBorder ? `1px solid ${theme.colors.neutral}` : null};
  border-radius: ${({ isRightSide, noRadius }) =>
    isRightSide || noRadius ? 0 : `${theme.radii.lg} 0 0 0`};

  &:hover {
    background: ${theme.colors.lightGrayOpacity};

    & ${RightIcon} {
      color: ${theme.colors.primary};
    }
    & ${LeftIcon} {
      color: ${theme.colors.primary};
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${theme.colors.neutralLight};
    color: ${theme.colors.midGray};
    cursor: default;
    & ${LeftIcon} {
      color: ${theme.colors.midGray};
    }
    & ${RightIcon} {
      color: ${theme.colors.midGray};
    }
  }
`;

export const CheckboxWrapper = styled.div`
  margin: 0.9rem 1.3rem 0.8rem 0.2rem;
`;
