import React from 'react';
import { FiSettings } from 'react-icons/fi';
import { useSelector } from 'react-redux';

import {
  NotificationType,
  NotificationsSubTypes,
} from 'api/notifications/models';
import { MultiSelect, Switch } from 'components/forms';
import { Button, Modal } from 'components/ui';
import { selectUsersData } from 'store/slices/usersSlice';

import {
  SideModalBody,
  TitleIcon,
  DescriptionBox,
  DescriptionTitle,
  Description,
  SubTypeBox,
  SubTypeItem,
  SubTypeTitle,
  Gradient,
  Flex,
  CompanyLogo,
  Placeholder,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  notifications: NotificationType[];
};

const SettingsModal: React.FC<Props> = ({ isOpen, onClose, notifications }) => {
  const { users } = useSelector(selectUsersData);

  const notificationsSubTypesParsed = Object.values(NotificationsSubTypes).map(
    (subtype) =>
      subtype.charAt(0).toUpperCase() + subtype.slice(1).split('-').join(' '),
  );

  const handleSubmit = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Notification settings"
      titleIcon={
        <TitleIcon>
          <FiSettings size={16} />
        </TitleIcon>
      }
      footer={
        <>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Add to project</Button>
        </>
      }
    >
      <SideModalBody>
        <DescriptionBox>
          <DescriptionTitle>apply to</DescriptionTitle>
          <Description>
            You may change notifications you want to receive about all team
            members in company or just selected people
          </Description>
        </DescriptionBox>
        <MultiSelect
          label="Members notifications settings"
          items={users.map((user) => ({
            value: user.full_name || user.email,
            label: user.full_name || user.email,
            avatar: user.photo.mini.url || '',
          }))}
          variant="default"
          iconVariant="avatar"
          placeholder={
            <Flex>
              <CompanyLogo />
              <Placeholder>All company</Placeholder>
            </Flex>
          }
        />
        <SubTypeBox>
          {notificationsSubTypesParsed.map((subtype, index) => (
            <SubTypeItem key={index}>
              <SubTypeTitle>{subtype}</SubTypeTitle>
              <Switch
                data-cy="notification-setting-switch"
                last
                name="notification_setting"
                defaultChecked
              />
            </SubTypeItem>
          ))}
        </SubTypeBox>
      </SideModalBody>
      <Gradient />
    </Modal>
  );
};

export default SettingsModal;
