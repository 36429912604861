import { MdClose } from 'react-icons/md';

import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const Table = styled.table`
  border: 1px solid ${theme.colors.lightestGrey};
  border-spacing: 0;
  width: 100%;
  border-top-left-radius: ${theme.radii.md};
  border-top-right-radius: ${theme.radii.md};
  border-bottom: 2px solid ${theme.colors.lighterGrayOpacity};
`;

export const TableHeadCell = styled.th`
  padding: ${theme.space[2]};
  background-color: ${theme.colors.lightestGrey};
  color: ${theme.colors.primeDarkGrey};
  font-family: ${theme.fonts.bold};
  font-size: ${theme.fontSizes.sm};
  text-align: left;
`;

export const TableData = styled.td`
  max-width: 400px;
  max-height: 36px;
  min-width: 80px;
  line-height: ${theme.lineHeights.normal};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 2px solid ${theme.colors.lightestGrey};
  border-right: 2px solid ${theme.colors.lightestGrey};
  color: ${theme.colors.primeDarkGrey};
  padding: ${theme.space[3]} ${theme.space[2]} ${theme.space[2]};

  &:last-of-type {
    border-right: none;
    width: ${theme.sizes.slg};
    min-width: ${theme.sizes.slg};
  }
`;

export const TableLinkData = styled(TableData)`
  text-decoration: underline;
  color: ${theme.colors.primary};
  padding-right: ${theme.space[4]};
`;

export const RemoveButton = styled.button`
  position: relative;
  margin-left: ${theme.space[2]};
  width: ${theme.sizes.xsm};
  height: ${theme.sizes.xsm};
  border-radius: ${theme.radii.xxl};
  background-color: ${theme.colors.danger};
  color: ${theme.colors.white};
  padding: ${theme.space[2]};

  &:focus {
    outline: none;
    box-shadow: ${theme.shadows.outline};
  }
`;

export const RemoveIcon = styled(MdClose)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
