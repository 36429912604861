import React from 'react';
import { useTranslation } from 'react-i18next';

import { NewPrimeButton } from 'components/prime/NewButton/NewButton';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { Dialog } from 'primereact/dialog';
import { Box, Flex } from 'rebass';

interface Props {
  header: string;
  message: string;
  isOpen: boolean;
  close: () => void;
  confirm: () => void;
}

export const RemoveItemModal = ({
  message,
  close,
  isOpen,
  confirm,
  header,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      onHide={close}
      visible={isOpen}
      blockScroll={true}
      header={header}
      style={{ width: '450px' }}
    >
      <Box fontSize="xs" py={5}>
        {message}
      </Box>
      <Flex justifyContent="flex-end" mt={4}>
        <PrimeTextButton onClick={close} label={t('common.cancel')} />
        <NewPrimeButton label={t('common.remove')} onClick={confirm} />
      </Flex>
    </Dialog>
  );
};
