import { mutateProjectPhases } from '../../mutations/mutateProjectPhases';
import { projectsAPI } from 'api/projects/endpoints';

export const removeProjectPhase = async (
  projectId: string,
  phaseId: number,
) => {
  await projectsAPI.removeProjectPhase(projectId, phaseId);
  await mutateProjectPhases();
};
