import { useMemo } from 'react';

import { User } from 'api/users/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

const API_URL = '/users/';

export const useUser = (userId: string) => {
  const { data, mutate } = useSWR<{ user: User }>(API_URL + userId, fetcher);

  const user = useMemo(() => data?.user, [data?.user]);

  return {
    isLoading: !data,
    user,
    mutate,
  };
};
