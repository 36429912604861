import { useLocation } from 'react-router-dom';

import { PAGES_PATH } from 'components/layout/PrivateRoute/components/MainLayout/hooks';
import { capitalize } from 'lodash';

export const useBackLinkHistory = () => {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const rootUrl = currentUrl.substring(0, currentUrl.indexOf('/'));
  // @ts-ignore
  const from = location.state?.from || rootUrl;

  if (from.includes(' profile')) {
    const [linkCopy, userId] = from.split(', ');

    return {
      linkCopy: `Back to ${linkCopy}`,
      source: from,
      url: `/${PAGES_PATH.USERS}/${userId}`,
    };
  }

  if (from.includes('/edit')) {
    return {
      linkCopy: 'Back to profile settings',
      source: from,
      url: `/${from}`,
    };
  }

  if (from.includes(' project')) {
    const [linkCopy, projectId] = from.split(', ');

    return {
      linkCopy: `Back to ${linkCopy}`,
      source: from,
      url: `/${PAGES_PATH.PROJECTS}/${projectId}`,
    };
  }

  if (from.includes('available-resources')) {
    return {
      linkCopy: 'Back to Team availability',
      source: from,
      url: '/projects/available-resources',
    };
  }

  return {
    linkCopy: `Back to ${capitalize(from)}`,
    source: from,
    url: `/${from}`,
  };
};
