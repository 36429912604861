import styled from '@emotion/styled/macro';
import { Button } from 'primereact/button';
import { Box, Flex } from 'rebass';
import { theme } from 'styles/theme';

export const HeadingWrapper = styled.div`
  width: 100%;
  margin: ${theme.space[6]} 0 ${theme.space[3]};
  padding-bottom: ${theme.space[1]};
  border-bottom: 1px solid rgb(1, 102, 255, 0.1);
`;

export const Heading = styled.h2`
  font-size: ${theme.fontSizes.lg};
  margin: 0;
  font-family: ${theme.fonts.bold};
  color: ${theme.colors.primeDarkGrey};
`;

// TO DO: adjust while working on sidebar nav
export const Container = styled.div`
  width: 60%;
  margin: 0 auto;
`;

export const InputBox = styled(Box)`
  background-color: ${theme.colors.white};
  padding: ${theme.space[6]};
  border-radius: 6px;
`;

export const StyledButton = styled(Button)`
  border: 1.5px solid ${theme.colors.primaryLight};
  border-radius: ${theme.radii.xs};
  padding: ${theme.space[4]};
  margin-right: ${theme.space[2]};
  background: ${theme.colors.whiteLilac};
  &.p-button {
    height: ${theme.space[9]};
    color: ${theme.colors.primaryLight};
    font-size: ${theme.fontSizes.sm};

    &:hover {
      background-color: ${theme.colors.primaryLight};
      box-shadow: ${theme.shadows.mainButton};
    }
    &:focus {
      box-shadow: ${theme.shadows.outline};
    }
  }
  &.p-disabled {
    border-color: ${theme.colors.primeDarkGrey};
    color: ${theme.colors.primeDarkGrey};
    opacity: 0.2;
  }
`;

export const ButtonContainer = styled(Flex)`
  justify-content: flex-end;
  width: 100%;
  margin-bottom: ${theme.space[9]};
`;
