import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { theme } from 'styles/theme';

export const SwitchLabel = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.5rem;
  font-size: ${theme.fontSizes.xxs};
  text-transform: uppercase;
  padding: ${theme.space[1]};

  & > label {
    color: ${theme.colors.midGray};
    font-family: ${theme.fonts.medium};
    margin-right: ${theme.space[4]};
    margin-top: ${theme.space[1]};
  }
`;

export const OptionBox = styled(Box)`
  font-size: ${theme.fontSizes.xs};
`;

export const MultiSelectLabel = styled.label`
  padding: ${theme.space[4]} 0 ${theme.space[3]};
  font-size: ${theme.fontSizes.xs};
`;
