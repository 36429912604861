import React from 'react';

import { FieldSubLabel } from 'components/primitive';
import { InputTextProps, InputText } from 'primereact/inputtext';

import {
  StyledTextInput,
  FieldIcon,
  Label,
  InputRightElement,
  InputGroup,
  Wrapper,
} from './styled';

export interface InputTextCustomProps {
  label?: string;
  lefticon?: HTMLElement;
  righticon?: HTMLElement;
  variant?: 'filled' | 'default';
  width?: string;
  isLabelVisible?: boolean;
  icon?: HTMLElement;
  subLabel?: string;
  iserror?: string;
  ishigh?: 'true' | 'false';
}

export type RefProps =
  | React.ForwardedRef<InputText>
  | ((instance: InputText | null) => void | React.RefObject<InputText>)
  | null
  | undefined;

export const PrimeTextInput = React.forwardRef(
  (props: InputTextProps & InputTextCustomProps, ref: RefProps) => {
    const {
      lefticon,
      righticon,
      'data-cy': dataCy,
      isLabelVisible = true,
      label,
      subLabel,
      variant,
      append,
      icon,
      iserror,
      ishigh = 'true',
    } = props;
    return (
      <Wrapper>
        {icon ? <FieldIcon iserror={iserror}>{icon}</FieldIcon> : null}
        {isLabelVisible ? (
          <Label isIcon={icon} iserror={iserror}>
            {label}
          </Label>
        ) : null}
        {subLabel ? <FieldSubLabel>{subLabel}</FieldSubLabel> : null}
        <InputGroup
          className={`${lefticon && 'p-input-icon-left'} ${
            righticon && 'p-input-icon-right'
          }`}
        >
          {lefticon && lefticon}
          <StyledTextInput
            {...props}
            ref={ref}
            data-cy={dataCy}
            variant={variant}
            iserror={iserror}
            ishigh={ishigh}
            className={'p-inputtext-sm ' + (iserror ? 'p-invalid' : '')}
          />
          {righticon && righticon}
          {append && <InputRightElement children={append} variant={variant} />}
        </InputGroup>
      </Wrapper>
    );
  },
);
