import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { OnboardingService } from 'api/onboarding/models';
import { NewPrimeButton } from 'components/prime/NewButton/NewButton';
import { Spinner } from 'components/ui/Spin/styled';
import { triggerOnboarding } from 'fetchers/actions/triggerOnboarding';
import { useOrganizationSettings } from 'fetchers/hooks/useOrganizationSettings';
import { useProject } from 'fetchers/hooks/useProject';
import { Flex, Text } from 'rebass';
import { displaySnackbar } from 'store/slices/snackbarSlice';

import { SpinnerWrapper } from './styled';

const serviceNameToLabel: Record<OnboardingService, string> = {
  github: 'GitHub',
  'atlassian/jira': 'Jira',
  'atlassian/confluence': 'Confluence',
  google: 'Google',
  slack: 'Slack',
  rollbar: 'Rollbar',
};

export const OnboardingActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { projectId } = useParams<{ projectId: string }>();
  const { project } = useProject(projectId);
  const { integrations } = useOrganizationSettings();

  if (!project) {
    return null;
  }

  const {
    onboarded_to_jira,
    onboarded_to_confluence,
    onboarded_to_github,
    onboarded_to_google,
    onboarded_to_slack,
    onboarded_to_rollbar,
  } = project;
  const handleTriggerIntegration = (serviceName: OnboardingService) => {
    setIsLoading(true);
    triggerOnboarding(serviceName, projectId)
      .then(() => {
        dispatch(
          displaySnackbar({
            message: t('onboarding.triggerSuccess', {
              name: serviceNameToLabel[serviceName],
            }),
          }),
        );
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: t('onboarding.triggerFail', {
              name: serviceNameToLabel[serviceName],
            }),
            isError: true,
          }),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Flex flexDirection="column" sx={{ gap: '20px', position: 'relative' }}>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}

      <Text mt="20px">Onboarding</Text>

      {integrations?.google_drive && (
        <NewPrimeButton
          disabled={Boolean(onboarded_to_google) || isLoading}
          onClick={() => handleTriggerIntegration('google')}
        >
          Google Drive
        </NewPrimeButton>
      )}

      {integrations?.atlassian && (
        <>
          <NewPrimeButton
            disabled={Boolean(onboarded_to_jira) || isLoading}
            onClick={() => handleTriggerIntegration('atlassian/jira')}
          >
            Jira
          </NewPrimeButton>
          <NewPrimeButton
            disabled={Boolean(onboarded_to_confluence) || isLoading}
            onClick={() => handleTriggerIntegration('atlassian/confluence')}
          >
            Confluence
          </NewPrimeButton>
        </>
      )}

      {integrations?.github && (
        <NewPrimeButton
          disabled={Boolean(onboarded_to_github) || isLoading}
          onClick={() => handleTriggerIntegration('github')}
        >
          GitHub
        </NewPrimeButton>
      )}

      {integrations?.slack && (
        <NewPrimeButton
          disabled={Boolean(onboarded_to_slack) || isLoading}
          onClick={() => handleTriggerIntegration('slack')}
        >
          Slack
        </NewPrimeButton>
      )}

      {integrations?.rollbar && (
        <NewPrimeButton
          disabled={Boolean(onboarded_to_rollbar) || isLoading}
          onClick={() => handleTriggerIntegration('rollbar')}
        >
          Rollbar
        </NewPrimeButton>
      )}
    </Flex>
  );
};
