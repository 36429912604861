import { FiChevronDown } from 'react-icons/fi';

import styled from '@emotion/styled/macro';

type Props = {
  open: boolean;
};

export const ChevronIcon = styled(FiChevronDown)<Props>`
  transition: transform 150ms;
  user-select: none;
  pointer-events: none;
  transform: ${({ open }) => (open ? `rotate(180deg)` : `rotate(0deg)`)};
`;
