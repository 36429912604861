import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from 'components/forms/Checkbox';
import { ChevronIcon } from 'components/primitive';
import { addWeeks, isSameDay, startOfWeek } from 'date-fns';
import endOfISOWeek from 'date-fns/endOfISOWeek';
import format from 'date-fns/format';
import startOfISOWeek from 'date-fns/startOfISOWeek';
import { useSortedFollowingWeeks } from 'fetchers';
import { Flex } from 'rebass';
import {
  formatDate,
  selectSelectedWeeksInISOFormat,
  setSelectedWeeks,
  setCurrentWeek,
} from 'store/slices/selectedWeeksSlice';
import { findIndexByDay } from 'utils/findIndexByDate';
import { getWeeksRange } from 'utils/getWeeksRange';
import { useClickOutside } from 'utils/hooks/useClickOutside';
import { useKeyPress } from 'utils/hooks/useKeyPress';

import { Option } from './components/Option';
import { useWeekDatePicker, WeekDataType } from './hooks';
import {
  Wrapper,
  SideButton,
  Picker,
  ScrollWrapper,
  PickerOptions,
  IconsWrapper,
  RefreshIcon,
  LeftIcon,
  RightIcon,
  CheckboxWrapper,
} from './styled';

type Props = {
  onChange?: (dates: WeekDataType[]) => void;
  numberOfWeeks?: number;
  initialSelectedWeeks?: WeekDataType[];
  hideNavigationButtons?: boolean;
  variant?: 'default' | 'table';
  noRadius?: boolean;
  withBorders?: boolean;
};

const WEEK_HEIGHT = 40;

// @TODO: Remove `React.memo` when all parent components won't have an issue with redundant rerendering
export const WeekDatePicker: React.FC<Props> = React.memo(
  ({
    onChange,
    hideNavigationButtons,
    variant,
    noRadius = false,
    withBorders = false,
  }) => {
    const dispatch = useDispatch();
    const previouslyClickedWeek = useRef<WeekDataType | null>(null);
    const previouslyUncheckedWeek = useRef<WeekDataType | null>(null);
    const isShiftKeyDown = useRef<boolean>(false);
    const blockSelectedWeeksUpdate = useRef(false);
    const { followingWeeks: availableWeeks } = useSortedFollowingWeeks();
    const selectedWeeks = useSelector(
      selectSelectedWeeksInISOFormat,
      () => true,
    );
    const wrapperRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);
    const isTableVariant = useMemo(() => variant === 'table', [variant]);

    const currentWeek = useMemo(
      () =>
        availableWeeks.find((week) =>
          isSameDay(week.start, startOfWeek(new Date(), { weekStartsOn: 1 })),
        ),
      [availableWeeks],
    );

    const isOtherThanCurrentWeekSelected = useMemo(() => {
      return (
        selectedWeeks.length === 1 &&
        selectedWeeks[0].weekNumber !== currentWeek?.weekNumber
      );
    }, [currentWeek, selectedWeeks]);

    useEffect(() => {
      if (currentWeek) {
        const _currentWeek = {
          ...currentWeek,
          start: formatDate(currentWeek.start),
          end: formatDate(currentWeek.end),
        };
        dispatch(setCurrentWeek(_currentWeek));
      }
    }, [dispatch, availableWeeks, currentWeek]);

    const handleChange = useCallback(
      (selectedWeeks: WeekDataType[]) => {
        onChange?.(selectedWeeks);
        window.requestAnimationFrame(() => {
          dispatch(
            setSelectedWeeks(
              selectedWeeks.map(({ end, start, ...rest }) => ({
                ...rest,
                end: formatDate(end),
                start: formatDate(start),
              })),
            ),
          );
        });
        if (selectedWeeks.length === 1) {
          previouslyClickedWeek.current = selectedWeeks[0];
        }
      },
      [dispatch, onChange],
    );
    const {
      addWeek,
      chosenWeeks,
      isPickerOpen,
      removeWeek,
      resetWeeks,
      setChosenWeek,
      setChosenWeeks,
      togglePicker,
    } = useWeekDatePicker(selectedWeeks, handleChange);
    const columns = useMemo(
      () => [
        { name: 'Date', width: isTableVariant ? '33%' : '50%' },
        { name: 'Working Days', width: isTableVariant ? '46%' : '34%' },
        { name: 'Week #', width: '10%' },
      ],
      [isTableVariant],
    );
    const isAllWeeksChecked = chosenWeeks.length === availableWeeks.length;
    const isSomeWeeksChecked = chosenWeeks.length >= 1 && !isAllWeeksChecked;
    const isOneWeekChecked = chosenWeeks.length === 1;

    useEffect(
      () => {
        if (isOneWeekChecked) {
          previouslyClickedWeek.current = chosenWeeks[0];
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isOneWeekChecked],
    );

    const handleTogglePicker = useCallback(() => {
      blockSelectedWeeksUpdate.current = !isPickerOpen;

      togglePicker();
    }, [isPickerOpen, togglePicker]);

    useClickOutside({
      handler: handleTogglePicker,
      refs: [wrapperRef],
      listenWhen: isPickerOpen,
    });

    useKeyPress({
      targetKey: 'Escape',
      downHandler: handleTogglePicker,
      listenWhen: isPickerOpen,
    });

    const onWeekAdd = (week: WeekDataType) => {
      if (previouslyClickedWeek.current && isShiftKeyDown.current) {
        const previousWeekStart = previouslyClickedWeek.current.start;
        const currentWeekStart = week.start;
        let weeksToSelect: WeekDataType[] = [];

        const getWeekIndex = (date: Date) => {
          return findIndexByDay(availableWeeks, 'start', date);
        };
        if (previousWeekStart < currentWeekStart) {
          weeksToSelect = availableWeeks.slice(
            getWeekIndex(previousWeekStart),
            getWeekIndex(addWeeks(currentWeekStart, 1)),
          );
        } else {
          weeksToSelect = availableWeeks.slice(
            getWeekIndex(currentWeekStart),
            getWeekIndex(previousWeekStart),
          );
        }
        const otherSelectedWeeks = chosenWeeks.filter(
          (w) =>
            !weeksToSelect.find((week) => week.weekNumber === w.weekNumber),
        );
        setChosenWeeks([...weeksToSelect, ...otherSelectedWeeks]);
      } else {
        addWeek(week);
        previouslyClickedWeek.current = week;
      }
    };

    const onWeekRemove = (week: WeekDataType) => {
      if (previouslyUncheckedWeek.current && isShiftKeyDown.current) {
        const weeksToUnselect = getWeeksRange(
          previouslyUncheckedWeek.current.start,
          week.start,
          availableWeeks,
          'start',
        );
        const otherSelectedWeeks = chosenWeeks.filter(
          (w) =>
            !weeksToUnselect.find((week) => week.weekNumber === w.weekNumber),
        );
        if (otherSelectedWeeks.length) {
          setChosenWeeks([...otherSelectedWeeks]);
        } else {
          setChosenWeek(week);
          previouslyUncheckedWeek.current = null;
        }
      } else {
        removeWeek(week);
        previouslyClickedWeek.current = null;
        previouslyUncheckedWeek.current = week;
      }
    };

    const isSameWeekNumber = useCallback(
      ({ weekNumber }) => weekNumber === chosenWeeks[0].weekNumber,
      [chosenWeeks],
    );

    const scrollToFirstSelectedWeek = useCallback(() => {
      const chosenWeekIndex = availableWeeks.findIndex(isSameWeekNumber);

      optionsRef?.current?.scrollTo({
        top: chosenWeekIndex * WEEK_HEIGHT,
      });
    }, [availableWeeks, isSameWeekNumber]);

    const handleRefreshClick = useCallback(
      (event: React.MouseEvent<SVGElement>) => {
        event.stopPropagation();
        resetWeeks();
      },
      [resetWeeks],
    );

    const handleDecreaseWeek = useCallback(() => {
      const chosenWeekIndex = availableWeeks.findIndex(isSameWeekNumber);

      if (chosenWeekIndex > 0) {
        setChosenWeek(availableWeeks[chosenWeekIndex - 1]);
      }
    }, [availableWeeks, isSameWeekNumber, setChosenWeek]);

    const handleIncreaseWeek = useCallback(() => {
      const chosenWeekIndex = availableWeeks.findIndex(isSameWeekNumber);

      if (chosenWeekIndex < availableWeeks.length) {
        setChosenWeek(availableWeeks[chosenWeekIndex + 1]);
      }
    }, [availableWeeks, isSameWeekNumber, setChosenWeek]);

    const handleSelectAllWeeks = useCallback(() => {
      if (isAllWeeksChecked) {
        resetWeeks();
      } else {
        setChosenWeeks(availableWeeks);
      }
    }, [availableWeeks, isAllWeeksChecked, resetWeeks, setChosenWeeks]);

    const handleTriggerClick = useCallback(() => {
      if (!isPickerOpen) {
        window.requestAnimationFrame(() => {
          scrollToFirstSelectedWeek();
        });
      }

      handleTogglePicker();
    }, [isPickerOpen, scrollToFirstSelectedWeek, handleTogglePicker]);

    const triggerCopy = useMemo(() => {
      const weeksNumber =
        !!chosenWeeks.length && chosenWeeks.map((w) => w.weekNumber).join(', ');
      const startDate = format(
        startOfISOWeek(chosenWeeks[0].start),
        isTableVariant ? 'dd.MM' : 'dd MMMM',
      );
      const endDate = format(
        endOfISOWeek(chosenWeeks[chosenWeeks.length - 1].end),
        isTableVariant ? 'dd.MM.yyyy' : 'dd MMMM',
      );

      return weeksNumber
        ? `${`Week ${weeksNumber}`} \u00A0 - \u00A0 ${startDate} – ${endDate}`
        : 'Weeks are not selected';
    }, [chosenWeeks, isTableVariant]);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Shift') {
        isShiftKeyDown.current = e.type === 'keydown';
      }
    };

    return (
      <Wrapper
        ref={wrapperRef}
        onKeyDown={handleKeyPress}
        onKeyUp={handleKeyPress}
        withBorder={withBorders && chosenWeeks.length > 1}
      >
        {!hideNavigationButtons && (
          <SideButton
            table={isTableVariant}
            onClick={handleDecreaseWeek}
            disabled={
              !chosenWeeks.length ||
              chosenWeeks.length > 1 ||
              chosenWeeks[0].start === availableWeeks[0]?.start
            }
            noRadius={noRadius}
          >
            <LeftIcon />
          </SideButton>
        )}
        <Picker onClick={handleTriggerClick} variant={variant}>
          <span>{triggerCopy}</span>
          <IconsWrapper>
            <RefreshIcon
              visible={
                chosenWeeks.length > 1 || isOtherThanCurrentWeekSelected ? 1 : 0
              }
              onClick={handleRefreshClick}
              variant={variant}
            />
            {!isTableVariant && <ChevronIcon open={isPickerOpen} />}
          </IconsWrapper>
          {isPickerOpen ? (
            <PickerOptions variant={variant}>
              <Flex alignItems="center" px={4}>
                <CheckboxWrapper>
                  <Checkbox
                    size="sm"
                    onChange={handleSelectAllWeeks}
                    isChecked={isAllWeeksChecked}
                    isIndeterminate={isSomeWeeksChecked}
                  />
                </CheckboxWrapper>
                {columns.map((column, index) => (
                  <Flex
                    key={index}
                    color="midGray"
                    fontSize="xxs"
                    height="sm"
                    flex="none"
                    alignItems="center"
                    width={column.width}
                    sx={{
                      textAlign: 'start',
                      fontFamily: 'bold',
                      textTransform: 'uppercase',
                    }}
                  >
                    {column.name}
                  </Flex>
                ))}
              </Flex>
              <ScrollWrapper ref={optionsRef}>
                {availableWeeks.map(({ end, start, weekNumber, holidays }) => (
                  <Option
                    key={`${start}-${end}-${weekNumber}`}
                    start={start}
                    end={end}
                    holidays={holidays}
                    isSelected={
                      isSomeWeeksChecked && !isAllWeeksChecked
                        ? !!chosenWeeks.find(
                            ({ weekNumber: chosenWeekNumber }) =>
                              chosenWeekNumber === weekNumber,
                          )
                        : isAllWeeksChecked
                    }
                    weekNumber={weekNumber}
                    onAdd={onWeekAdd}
                    onRemove={onWeekRemove}
                  />
                ))}
              </ScrollWrapper>
            </PickerOptions>
          ) : null}
        </Picker>
        {!hideNavigationButtons && (
          <SideButton
            table={isTableVariant}
            onClick={handleIncreaseWeek}
            noBorder={withBorders}
            disabled={
              !chosenWeeks.length ||
              chosenWeeks.length > 1 ||
              availableWeeks[availableWeeks.length - 1]?.weekNumber ===
                chosenWeeks[0].weekNumber
            }
            isRightSide
            noRadius={noRadius}
          >
            <RightIcon />
          </SideButton>
        )}
      </Wrapper>
    );
  },
);
