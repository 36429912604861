import styled from '@emotion/styled/macro';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

type ButtonProps = {
  isPrevStep?: boolean;
};

export const HeaderWrapper = styled.div`
  padding: ${theme.space[5]} ${theme.space[6]} 0;
`;

export const Header = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.lightestGrey};
  padding-bottom: ${theme.space[5]};
`;

export const TFAHeading = styled.h2`
  font-size: ${theme.fontSizes.xmd};
  margin: 0;
  color: ${theme.colors.primeDarkGrey};
`;

export const TFASpan = styled.span`
  color: ${theme.colors.primeLightGrey};
  font-size: ${theme.fontSizes.sm};
`;

export const CardContainer = styled(Flex)`
  min-height: 70vh;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled(Flex)`
  justify-content: flex-end;
  border-top: 1px solid ${theme.colors.lightestGrey};
  padding-top: ${theme.space[5]};
`;

export const StyledCard = styled(Card)`
  &.p-card {
    box-shadow: none;
    width: 690px;
  }
  & .p-card-content {
    padding: 0 ${theme.space[2]};
  }
`;

export const StyledButton = styled(Button)(
  ({ isPrevStep = false }: ButtonProps) => {
    return `
  border: ${isPrevStep ? 'none' : `1.5px solid ${theme.colors.primaryLight}`};
  background-color: ${theme.colors.white};
  border-radius: ${theme.radii.xs};
  padding: ${theme.space[4]};
  margin-right: ${theme.space[2]};

  &.p-button {
    height: ${theme.space[9]};
    color: ${
      isPrevStep ? theme.colors.primeDarkGrey : theme.colors.primaryLight
    };
    font-size: ${theme.fontSizes.sm};

    &:hover {
      background-color: ${
        isPrevStep ? theme.colors.primeLightGrey : theme.colors.primaryLight
      };
      box-shadow: ${isPrevStep ? 'none' : theme.shadows.mainButton};
    }
    &:focus {
      box-shadow: ${
        isPrevStep ? theme.shadows.outlineGray : theme.shadows.outline
      };
    }
  }

  &.p-disabled {
    border-color: ${isPrevStep ? 'transparent' : theme.colors.primeDarkGrey};
    color: ${theme.colors.primeDarkGrey};
    opacity: 0.2;
  }
  
`;
  },
);

export const Text = styled.p`
  text-align: left;
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.primeDarkGrey};
  margin-top: 0;
`;

export const Image = styled.img`
  display: block;
  margin: 0 auto;
`;

export const TfaKey = styled.div`
  text-align: center;
  margin: ${theme.space[7]} 0;
  font-size: ${theme.fontSizes.xmd};
  font-weight: 700;
  color: ${theme.colors.primeDarkGrey};
`;

export const InputContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
  padding: ${theme.space[9]} 0 ${theme.space[8]};
`;

export const IconBox = styled.div`
  width: fit-content;
  margin: 0 auto;
  padding-bottom: ${theme.space[4]};
`;
