import React from 'react';

import { Spin } from 'components/ui';
import { Flex } from 'rebass';

export const FullScreenLoader = () => {
  return (
    <Flex
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.3)',
        zIndex: 1020,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin />
    </Flex>
  );
};
