import { useHistory } from 'react-router-dom';

export enum PAGES_PATH {
  DASHBOARD = 'dashboard',
  NOTIFICATIONS = 'notifications',
  PROJECTS = 'projects',
  USERS = 'users',
  WEEKLY_SUMMARY = 'weekly-summary',
  ORGANIZATION_SETTINGS = 'organization-settings',
  TIMELINE = 'timeline',
  AVAILABLE_RESOURCES = 'available-resources',
}

export const PAGE_PATH_TO_ID = {
  [PAGES_PATH.DASHBOARD]: 3,
  [PAGES_PATH.NOTIFICATIONS]: 4,
  [PAGES_PATH.PROJECTS]: 1,
  [PAGES_PATH.USERS]: 2,
  [PAGES_PATH.WEEKLY_SUMMARY]: 5,
  [PAGES_PATH.ORGANIZATION_SETTINGS]: 6,
  [PAGES_PATH.TIMELINE]: 6,
  [PAGES_PATH.AVAILABLE_RESOURCES]: 7,
};

const getActivePage = (pathname: string) => {
  const activePagePath = Object.values(PAGES_PATH).find((pagePath) =>
    pathname.includes(pagePath),
  );

  if (!activePagePath) {
    // TODO: Show only for development environment
    console.error(
      `Warning: Received \`${pathname}\` is not available in page path list.`,
    );

    return null;
  }

  return PAGE_PATH_TO_ID[activePagePath];
};

export function useActiveTabState() {
  const history = useHistory();

  return getActivePage(history.location.pathname);
}
