import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type CardProps = {
  isHidden?: boolean;
  marginBottom?: string;
  size?: 'sm' | 'md';
  background?: string;
};

export const Card = styled.div<CardProps>`
  background: ${({ background }) =>
    background ? background : theme.colors.white};
  border-radius: ${theme.radii.md};
  box-shadow: ${theme.shadows.md40};
  padding: ${({ size = 'md' }) =>
    size === 'md' ? theme.space[6] : theme.space[2]};
  padding-bottom: ${theme.space[2]};
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
`;

export const CardStack = styled.div`
  ${Card} {
    border-radius: 0;
    &:first-of-type {
      border-top-left-radius: ${theme.radii.md};
      border-top-right-radius: ${theme.radii.md};
    }
    &:last-of-type {
      border-bottom-left-radius: ${theme.radii.md};
      border-bottom-right-radius: ${theme.radii.md};
      border-bottom: none;
    }
  }
`;
