import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddNewButton } from '../AddNewButton';
import { RemoveItemModal } from '../RemoveModal';
import { Icon, Wrapper } from '../styled';
import { User, UserSkill } from 'api/users/models';
import { Tag } from 'components/prime';
import { Spin } from 'components/ui';
import { CannotRemoveModal } from 'components/ui/CannotRemoveModal/CannotRemoveModal';
import { removeSkill } from 'fetchers/actions/skill/removeSkill';
import { useSkills } from 'fetchers/hooks/users/useSkills';
import { Box, Flex } from 'rebass';

import { SkillModal } from './components/SkillModal';

export const SkillsSettings = () => {
  const [editedSkill, setEditedSkill] = useState<UserSkill | null>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [skillModalOpen, setSkillModalOpen] = useState<boolean>(false);
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { skills, isLoading } = useSkills();
  const usersBlockingRemoval = useRef<User[] | null>(null);
  const itemToDelete = useRef<UserSkill | null>(null);

  const addNewSkill = () => {
    setEditedSkill(null);
    setSkillModalOpen(true);
  };

  const editSkill = (skill: UserSkill) => {
    setEditedSkill(skill);
    setSkillModalOpen(true);
  };

  const deleteSkill = () => {
    removeSkill(itemToDelete.current?.id!).then(() => setConfirmOpen(false));
  };

  const openConfirm = (skill: UserSkill) => {
    itemToDelete.current = skill;
    setConfirmOpen(true);
  };

  const lowercaseSkillNames = useMemo(() => {
    return skills?.map((p) => p.name.toLowerCase()) || [];
  }, [skills]);

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Wrapper justifyContent="flex-start">
            <AddNewButton onClick={addNewSkill} />
            {skills?.map((skill) => {
              return (
                <Tag key={skill.id}>
                  <Flex p={1}>
                    <Flex flex={1}>
                      <Box mr={2} fontSize="xs">
                        {skill.name}
                      </Box>
                    </Flex>
                    <Icon>
                      <i
                        className="pi pi-pencil"
                        onClick={() => editSkill(skill)}
                      />
                      <i
                        className="pi pi-trash"
                        onClick={() => openConfirm(skill)}
                      />
                    </Icon>
                  </Flex>
                </Tag>
              );
            })}
          </Wrapper>

          <SkillModal
            isOpen={skillModalOpen}
            setIsOpen={setSkillModalOpen}
            skillList={lowercaseSkillNames}
            skill={editedSkill || null}
          />
          <CannotRemoveModal
            isOpen={dialogOpen}
            setIsOpen={setDialogOpen}
            usersBlockingRemoval={usersBlockingRemoval.current || []}
            itemName="Skill"
          />
          <RemoveItemModal
            message={t('organizationSettings.deleteConfirm', {
              name: itemToDelete.current?.name,
              item: t('userProfile.skill').toLowerCase(),
            })}
            close={() => setConfirmOpen(false)}
            isOpen={isConfirmOpen}
            confirm={deleteSkill}
            header={t('users.removeSkill')}
          />
        </>
      )}
    </>
  );
};
