import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SubheaderWrapper } from '../../../styled';
import { FormikTextInput } from 'components/forms/formik-prime';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { addCalamariIntegration } from 'fetchers/actions/addCalamariIntegration';
import { Form, Formik } from 'formik';
import { Box, Flex } from 'rebass';
import { HorizontalLine } from 'screens/User/components/GeneralInfoFields';
import { displaySnackbar } from 'store/slices/snackbarSlice';

import { InputWrapper } from './styled';
import { validationSchema } from './validationSchema';

interface Props {
  apiUrl?: string;
  onClose: () => void;
  canIntegrate: boolean;
  isIntegrated: boolean;
}

interface CalamariForm {
  api_key: string;
  api_url: string;
}

export const CalamariSection = ({
  apiUrl,
  onClose,
  canIntegrate,
  isIntegrated,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (values: CalamariForm) => {
    addCalamariIntegration(values)
      .then(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationSuccess'),
          }),
        );
        onClose();
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationFail'),
            isError: true,
          }),
        );
      });
  };

  return (
    <Flex flexDirection="column" mt={4}>
      {canIntegrate ? (
        <SubheaderWrapper active={true}>
          <Box mr={2} display="inline">
            <i className="pi pi-info-circle" />
          </Box>
          <Box display="inline">
            {t(
              isIntegrated
                ? 'organizationSettings.calamari.integrationOn'
                : 'organizationSettings.calamari.integrationValid',
            )}
          </Box>
        </SubheaderWrapper>
      ) : (
        <Formik
          initialValues={{ api_key: '', api_url: apiUrl || '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <InputWrapper>
              <FormikTextInput
                name="api_url"
                label={t('organizationSettings.jira.organizationURL')}
                ishigh="false"
                variant="default"
              />
              <FormikTextInput
                name="api_key"
                label="Token"
                ishigh="false"
                variant="default"
              />
            </InputWrapper>
            <HorizontalLine />
            <Flex justifyContent="flex-end">
              <Box mr={3}>
                <PrimeTextButton onClick={onClose} label={t('common.cancel')} />
              </Box>
              <NewStyledButton
                type="submit"
                variant="default"
                label={t('organizationSettings.calamari.connect')}
              />
            </Flex>
          </Form>
        </Formik>
      )}
    </Flex>
  );
};
