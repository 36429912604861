import styled from '@emotion/styled';
import { theme } from 'styles/theme';

export const Title = styled.h2`
  padding: ${theme.space[6]} 0 ${theme.space[8]};
  font-size: ${theme.fontSizes.xmd};
  color: ${theme.colors.darkestGray};
  margin: 0;
`;

Title.displayName = 'Title';

export default Title;
