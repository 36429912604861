import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import packageConfig from '../../../package.json';
import {
  IntegrateApps,
  IntegrateAppsType,
  UpdateOrganizationSettingsPayload,
} from 'api/organizationSettings/models';
import { PrimeSwitch } from 'components/prime';
import { updateOrganizationSettings } from 'fetchers/actions/updateOrganizationSettings';
import { useIntegrationsStatus } from 'fetchers/hooks/useIntegrationsStatus';
import { useOrganizationSettings } from 'fetchers/hooks/useOrganizationSettings';
import { Dialog } from 'primereact/dialog';
import { Box, Flex } from 'rebass';
import { displaySnackbar } from 'store/slices/snackbarSlice';
import { theme } from 'styles/theme';

import { AtlassianSection } from './components/Integrations/Atlassian';
import { CalamariSection } from './components/Integrations/Calamari';
import { GitHubSection } from './components/Integrations/GitHub';
import { GoogleDriveSection } from './components/Integrations/GoogleDrive';
import { LdapSection } from './components/Integrations/Ldap';
import { PersonioSection } from './components/Integrations/Personio';
import { RemoveIntegration } from './components/Integrations/RemoveIntegration';
import { RollbarSection } from './components/Integrations/Rollbar';
import { SlackSection } from './components/Integrations/Slack';
import { TempoSection } from './components/Integrations/Tempo';
import { TogglSection } from './components/Integrations/Toggl';
import { PositionsSettings } from './components/Settings/Positions';
import { ProjectTypes } from './components/Settings/ProjectTypes';
import { SenioritySettings } from './components/Settings/Seniority';
import { SkillsSettings } from './components/Settings/Skills';
import { TeamSettings } from './components/Settings/TeamSettings';
import {
  Icon,
  Integration,
  IntegrationHeader,
  SectionHeader,
  StyledCard,
  Wrapper,
  MenuHeader,
  MenuWrapper,
  IntegrationInner,
} from './styled';

export const OrganizationSettings = () => {
  const {
    isLoading,
    settings,
    integrations: integrationsActive,
    enabledFeatures,
  } = useOrganizationSettings();
  const { integrations } = useIntegrationsStatus();
  const integrationsRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const positionRef = useRef<HTMLDivElement>(null);
  const seniorityRef = useRef<HTMLDivElement>(null);
  const projectTypesRef = useRef<HTMLDivElement>(null);
  const skillsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [editing, setEditing] = useState<string>('');
  const [canIntegrate, setCanIntegrate] = useState<IntegrateAppsType>(
    IntegrateApps,
  );
  const [isIntegrated, setIsIntegrated] = useState<IntegrateAppsType>(
    IntegrateApps,
  );

  const { search } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const gitHubSettings = canIntegrate.github || isIntegrated.github;
  const atlassianSettings = canIntegrate.atlassian || isIntegrated.atlassian;
  const calamariSettings = canIntegrate.calamari || isIntegrated.calamari;
  const togglSettings = canIntegrate.toggl || isIntegrated.toggl;
  const slackSettings = canIntegrate.slack || isIntegrated.slack;
  const ldapSettings = canIntegrate.ldap || isIntegrated.ldap;
  const personioSettings = canIntegrate.personio || isIntegrated.personio;
  const googleDriveSettings =
    canIntegrate.google_drive || isIntegrated.google_drive;
  const tempoSettings = canIntegrate.tempo || isIntegrated.tempo;
  const rollbarSettings = canIntegrate.rollbar || isIntegrated.rollbar;

  useEffect(() => {
    const _canIntegrate = {
      atlassian: integrations?.atlassian || false,
      github: integrations?.github || false,
      calamari: integrations?.calamari || false,
      toggl: integrations?.toggl || false,
      slack: integrations?.slack || false,
      ldap: integrations?.ldap || false,
      personio: integrations?.personio || false,
      google_drive: integrations?.google_drive || false,
      tempo: integrations?.tempo || false,
      rollbar: integrations?.rollbar || false,
    };

    setCanIntegrate({
      ...canIntegrate,
      ..._canIntegrate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations]);

  useEffect(() => {
    if (integrationsActive) {
      setIsIntegrated({
        ...isIntegrated,
        ...integrationsActive,
      });
    } else {
      setIsIntegrated(IntegrateApps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationsActive]);

  useEffect(() => {
    if (search.includes('success=true')) {
      dispatch(
        displaySnackbar({
          message: t('organizationSettings.integrationSuccess'),
        }),
      );
    }
  }, [dispatch, search, t]);

  const updateOrgSettings = (value: UpdateOrganizationSettingsPayload) => {
    updateOrganizationSettings(value)
      .then(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationSwitchSuccess'),
          }),
        );
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationSwitchFail'),
            isError: true,
          }),
        );
      });
  };

  const renderModalContent = () => {
    switch (editing) {
      case 'atlassian':
        return (
          <AtlassianSection
            organizationSettings={settings.atlassian?.settings}
            integrated={isIntegrated.atlassian}
            canIntegrate={canIntegrate.atlassian}
            isLoading={isLoading}
            onClose={() => setEditing('')}
          />
        );
      case 'github':
        return (
          <GitHubSection
            isIntegrated={isIntegrated.github}
            canIntegrate={canIntegrate.github}
            onClose={() => setEditing('')}
          />
        );
      case 'toggl':
        return (
          <TogglSection
            onClose={() => setEditing('')}
            isIntegrated={isIntegrated.toggl}
            canIntegrate={canIntegrate.toggl}
            isLoading={isLoading}
          />
        );
      case 'calamari':
        return (
          <CalamariSection
            onClose={() => setEditing('')}
            apiUrl={settings.calamari?.calamari_api_url}
            canIntegrate={canIntegrate.calamari}
            isIntegrated={isIntegrated.calamari}
          />
        );
      case 'slack':
        return (
          <SlackSection
            onClose={() => setEditing('')}
            canIntegrate={canIntegrate.slack}
            isIntegrated={isIntegrated.slack}
          />
        );
      case 'ldap':
        return (
          <LdapSection
            onClose={() => setEditing('')}
            canIntegrate={canIntegrate.ldap}
            isIntegrated={isIntegrated.ldap}
          />
        );
      case 'personio':
        return (
          <PersonioSection
            onClose={() => setEditing('')}
            canIntegrate={canIntegrate.personio}
            isIntegrated={isIntegrated.personio}
          />
        );
      case 'google_drive':
        return (
          <GoogleDriveSection
            onClose={() => setEditing('')}
            canIntegrate={canIntegrate.google_drive}
            isIntegrated={isIntegrated.google_drive}
          />
        );
      case 'tempo':
        return (
          <TempoSection
            onClose={() => setEditing('')}
            canIntegrate={canIntegrate.tempo}
            isIntegrated={isIntegrated.tempo}
          />
        );
      case 'rollbar':
        return (
          <RollbarSection
            onClose={() => setEditing('')}
            canIntegrate={canIntegrate.rollbar}
            isIntegrated={isIntegrated.rollbar}
          />
        );
    }
  };

  const scrollToElement = (element: RefObject<HTMLDivElement>) => {
    if (element.current && wrapperRef.current) {
      const position = element.current.offsetTop - 16;
      wrapperRef.current.scrollTo({ top: position });
    }
  };

  return (
    <Wrapper ref={wrapperRef}>
      <MenuWrapper
        flex={2}
        mr={8}
        pr={8}
        pt={1}
        alignItems="flex-end"
        flexDirection="column"
        width="100%"
      >
        <MenuHeader onClick={() => scrollToElement(integrationsRef)}>
          {t('organizationSettings.integrations')}
        </MenuHeader>
        <MenuHeader onClick={() => scrollToElement(teamRef)}>
          {t('users.teamSettings')}
        </MenuHeader>
        <MenuHeader onClick={() => scrollToElement(positionRef)}>
          {t('users.positionSettings')}
        </MenuHeader>
        <MenuHeader onClick={() => scrollToElement(seniorityRef)}>
          {t('users.senioritySettings')}
        </MenuHeader>
        <MenuHeader onClick={() => scrollToElement(projectTypesRef)}>
          {t('users.typeSettings')}
        </MenuHeader>
        <MenuHeader onClick={() => scrollToElement(skillsRef)}>
          {t('users.skillSettings')}
        </MenuHeader>
        <MenuHeader>
          {t('organizationSettings.versionNumber', {
            versionNumber: packageConfig.version,
          })}
        </MenuHeader>
      </MenuWrapper>

      <Box flex={4} pr={4}>
        <SectionHeader ref={integrationsRef}>
          {t('organizationSettings.integrations')}
        </SectionHeader>
        <StyledCard active={true}>
          {enabledFeatures?.github && (
            <Integration onClick={() => setEditing('github')}>
              <IntegrationInner>
                <IntegrationHeader>
                  {t('organizationSettings.githubName')}
                </IntegrationHeader>
                <Flex>
                  {gitHubSettings && (
                    <Flex onClick={(event) => event.stopPropagation()}>
                      <RemoveIntegration integration="github" />
                      <PrimeSwitch
                        checked={isIntegrated.github}
                        onChange={(e) => {
                          updateOrgSettings({
                            service_name: 'github',
                            active: e.target.value,
                          });
                        }}
                        dark="true"
                      />
                    </Flex>
                  )}
                  <Icon className="pi pi-chevron-right" />
                </Flex>
              </IntegrationInner>
            </Integration>
          )}

          {enabledFeatures?.atlassian && (
            <Integration onClick={() => setEditing('atlassian')}>
              <IntegrationInner>
                <IntegrationHeader>
                  {t('organizationSettings.atlassianName')}
                </IntegrationHeader>
                <Flex>
                  {atlassianSettings && (
                    <Flex onClick={(event) => event.stopPropagation()}>
                      <RemoveIntegration integration="atlassian" />
                      <PrimeSwitch
                        checked={isIntegrated.atlassian}
                        onChange={(e) => {
                          updateOrgSettings({
                            service_name: 'atlassian',
                            active: e.target.value,
                          });
                        }}
                        dark="true"
                      />
                    </Flex>
                  )}
                  <Icon className="pi pi-chevron-right" />
                </Flex>
              </IntegrationInner>
            </Integration>
          )}

          {enabledFeatures?.calamari && (
            <Integration onClick={() => setEditing('calamari')}>
              <IntegrationInner>
                <IntegrationHeader>
                  {t('organizationSettings.calamariName')}
                </IntegrationHeader>
                <Flex>
                  {calamariSettings && (
                    <Flex onClick={(event) => event.stopPropagation()}>
                      <RemoveIntegration integration="calamari" />
                      <PrimeSwitch
                        checked={isIntegrated.calamari}
                        onChange={(e) => {
                          updateOrgSettings({
                            service_name: 'calamari',
                            active: e.target.value,
                          });
                        }}
                        dark="true"
                      />
                    </Flex>
                  )}
                  <Icon className="pi pi-chevron-right" />
                </Flex>
              </IntegrationInner>
            </Integration>
          )}

          {enabledFeatures?.toggl && (
            <Integration onClick={() => setEditing('toggl')}>
              <IntegrationInner>
                <IntegrationHeader>
                  {t('organizationSettings.togglName')}
                </IntegrationHeader>
                <Flex>
                  {togglSettings && (
                    <Flex onClick={(event) => event.stopPropagation()}>
                      <RemoveIntegration integration="toggl" />
                      <PrimeSwitch
                        checked={isIntegrated.toggl}
                        onChange={(e) => {
                          updateOrgSettings({
                            service_name: 'toggl',
                            active: e.target.value,
                          });
                        }}
                        dark="true"
                      />
                    </Flex>
                  )}
                  <Icon className="pi pi-chevron-right" />
                </Flex>
              </IntegrationInner>
            </Integration>
          )}

          {enabledFeatures?.slack && (
            <Integration onClick={() => setEditing('slack')}>
              <IntegrationInner>
                <IntegrationHeader>
                  {t('organizationSettings.slackName')}
                </IntegrationHeader>
                <Flex>
                  {slackSettings && (
                    <Flex onClick={(event) => event.stopPropagation()}>
                      <RemoveIntegration integration="slack" />
                      <PrimeSwitch
                        checked={isIntegrated.slack}
                        onChange={(e) => {
                          updateOrgSettings({
                            service_name: 'slack',
                            active: e.target.value,
                          });
                        }}
                        dark="true"
                      />
                    </Flex>
                  )}
                  <Icon className="pi pi-chevron-right" />
                </Flex>
              </IntegrationInner>
            </Integration>
          )}

          {enabledFeatures?.ldap && (
            <Integration onClick={() => setEditing('ldap')}>
              <IntegrationInner>
                <IntegrationHeader>
                  {t('organizationSettings.ldapName')}
                </IntegrationHeader>
                <Flex>
                  {ldapSettings && (
                    <Flex onClick={(event) => event.stopPropagation()}>
                      <RemoveIntegration integration="ldap" />
                      <PrimeSwitch
                        checked={isIntegrated.ldap}
                        onChange={(e) => {
                          updateOrgSettings({
                            service_name: 'ldap',
                            active: e.target.value,
                          });
                        }}
                        dark="true"
                      />
                    </Flex>
                  )}
                  <Icon className="pi pi-chevron-right" />
                </Flex>
              </IntegrationInner>
            </Integration>
          )}

          {enabledFeatures?.personio && (
            <Integration onClick={() => setEditing('personio')}>
              <IntegrationInner>
                <IntegrationHeader>
                  {t('organizationSettings.personioName')}
                </IntegrationHeader>
                <Flex>
                  {personioSettings && (
                    <Flex onClick={(event) => event.stopPropagation()}>
                      <RemoveIntegration integration="personio" />
                      <PrimeSwitch
                        checked={isIntegrated.personio}
                        onChange={(e) => {
                          updateOrgSettings({
                            service_name: 'personio',
                            active: e.target.value,
                          });
                        }}
                        dark="true"
                      />
                    </Flex>
                  )}
                  <Icon className="pi pi-chevron-right" />
                </Flex>
              </IntegrationInner>
            </Integration>
          )}

          {enabledFeatures?.google_drive && (
            <Integration onClick={() => setEditing('google_drive')}>
              <IntegrationInner>
                <IntegrationHeader>
                  {t('organizationSettings.googleDriveName')}
                </IntegrationHeader>
                <Flex>
                  {googleDriveSettings && (
                    <Flex onClick={(event) => event.stopPropagation()}>
                      <RemoveIntegration integration="google_drive" />
                      <PrimeSwitch
                        checked={isIntegrated.google_drive}
                        onChange={(e) => {
                          updateOrgSettings({
                            service_name: 'google_drive',
                            active: e.target.value,
                          });
                        }}
                        dark="true"
                      />
                    </Flex>
                  )}
                  <Icon className="pi pi-chevron-right" />
                </Flex>
              </IntegrationInner>
            </Integration>
          )}

          {enabledFeatures?.tempo && (
            <Integration onClick={() => setEditing('tempo')}>
              <IntegrationInner>
                <IntegrationHeader>
                  {t('organizationSettings.tempoName')}
                </IntegrationHeader>
                <Flex>
                  {tempoSettings && (
                    <Flex onClick={(event) => event.stopPropagation()}>
                      <RemoveIntegration integration="tempo" />
                      <PrimeSwitch
                        checked={isIntegrated.tempo}
                        onChange={(e) => {
                          updateOrgSettings({
                            service_name: 'tempo',
                            active: e.target.value,
                          });
                        }}
                        dark="true"
                      />
                    </Flex>
                  )}
                  <Icon className="pi pi-chevron-right" />
                </Flex>
              </IntegrationInner>
            </Integration>
          )}

          {enabledFeatures?.rollbar && (
            <Integration onClick={() => setEditing('rollbar')}>
              <IntegrationInner>
                <IntegrationHeader>
                  {t('organizationSettings.rollbarName')}
                </IntegrationHeader>
                <Flex>
                  {rollbarSettings && (
                    <Flex onClick={(event) => event.stopPropagation()}>
                      <RemoveIntegration integration="rollbar" />
                      <PrimeSwitch
                        checked={isIntegrated.tempo}
                        onChange={(e) => {
                          updateOrgSettings({
                            service_name: 'rollbar',
                            active: e.target.value,
                          });
                        }}
                        dark="true"
                      />
                    </Flex>
                  )}
                  <Icon className="pi pi-chevron-right" />
                </Flex>
              </IntegrationInner>
            </Integration>
          )}
        </StyledCard>

        <SectionHeader ref={teamRef}>{t('users.teamSettings')}</SectionHeader>
        <StyledCard>
          <TeamSettings />
        </StyledCard>

        <SectionHeader ref={positionRef}>
          {t('users.positionSettings')}
        </SectionHeader>
        <StyledCard>
          <PositionsSettings />
        </StyledCard>

        <SectionHeader ref={seniorityRef}>
          {t('users.senioritySettings')}
        </SectionHeader>
        <StyledCard>
          <SenioritySettings />
        </StyledCard>

        <SectionHeader ref={projectTypesRef}>
          {t('users.typeSettings')}
        </SectionHeader>
        <StyledCard>
          <ProjectTypes />
        </StyledCard>

        <SectionHeader ref={skillsRef}>
          {t('users.skillSettings')}
        </SectionHeader>
        <StyledCard>
          <SkillsSettings />
        </StyledCard>

        {/*trick to adjust margin when BodyBox is not scrollable*/}
        <Box height={theme.space[6]} />
      </Box>
      <Dialog
        header={`${t('organizationSettings.' + editing + 'Name')} 
          ${t('organizationSettings.editSettings')}`}
        onHide={() => {
          setEditing('');
        }}
        visible={!!editing}
        blockScroll={true}
        style={{ minWidth: '500px' }}
      >
        {renderModalContent()}
      </Dialog>
    </Wrapper>
  );
};
