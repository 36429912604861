const checkPageForErrors = (
  className?: string,
  timeout?: number,
): Promise<any> => {
  return new Promise((resolve) =>
    setTimeout(
      () => {
        const elementOnThePage = document.getElementsByClassName(
          className ? className : 'p-invalid',
        )[0];
        if (elementOnThePage) {
          elementOnThePage?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          return resolve(null);
        }
        const scrollingElement = document.scrollingElement || document.body;
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
        return resolve(null);
      },
      timeout ? timeout : 300,
    ),
  );
};

export default checkPageForErrors;
