import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddNewButton } from '../AddNewButton';
import { RemoveItemModal } from '../RemoveModal';
import { Icon, Wrapper } from '../styled';
import { User, UserSeniority } from 'api/users/models';
import { Tag } from 'components/prime';
import { Spin } from 'components/ui';
import { CannotRemoveModal } from 'components/ui/CannotRemoveModal/CannotRemoveModal';
import { removeSeniority } from 'fetchers/actions/seniority/removeSeniority';
import { useSeniority } from 'fetchers/hooks/users/useSeniority';
import { Box, Flex } from 'rebass';

import { SeniorityModal } from './components/SeniorityModal';

export const SenioritySettings = () => {
  const [
    editedSeniority,
    setEditedSeniority,
  ] = useState<UserSeniority | null>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [seniorityModalOpen, setSeniorityModalOpen] = useState<boolean>(false);
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { seniority, isLoading } = useSeniority();
  const usersBlockingRemoval = useRef<User[] | null>(null);
  const itemToDelete = useRef<UserSeniority | null>(null);

  const lowercaseSeniorityNames = useMemo(
    () => seniority?.map((p) => p.name.toLowerCase()) || [],
    [seniority],
  );

  const addNewSeniority = () => {
    setEditedSeniority(null);
    setSeniorityModalOpen(true);
  };

  const editSeniority = (seniority: UserSeniority) => {
    setEditedSeniority(seniority);
    setSeniorityModalOpen(true);
  };

  const deleteSeniority = () => {
    removeSeniority(itemToDelete.current?.id!)
      .then(() => setConfirmOpen(false))
      .catch((err) => openInfoModal(err));
  };

  const openInfoModal = ({ data }: any) => {
    if (data && data.errors.includes('users')) {
      setConfirmOpen(false);
      usersBlockingRemoval.current = data.users;
      setDialogOpen(true);
    }
  };

  const openConfirm = (seniority: UserSeniority) => {
    itemToDelete.current = seniority;
    setConfirmOpen(true);
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Wrapper justifyContent="flex-start">
            <AddNewButton onClick={addNewSeniority} />
            {seniority.map((seniority) => {
              return (
                <Tag key={seniority.id}>
                  <Flex p={1}>
                    <Flex flex={1}>
                      <Box mr={2} fontSize="xs">
                        {seniority.name}
                      </Box>
                    </Flex>
                    <Icon>
                      <i
                        className="pi pi-pencil"
                        onClick={() => editSeniority(seniority)}
                      />
                      <i
                        className="pi pi-trash"
                        onClick={() => openConfirm(seniority)}
                      />
                    </Icon>
                  </Flex>
                </Tag>
              );
            })}
          </Wrapper>

          <SeniorityModal
            isOpen={seniorityModalOpen}
            setIsOpen={setSeniorityModalOpen}
            seniorityList={lowercaseSeniorityNames}
            seniority={editedSeniority || null}
          />
          <CannotRemoveModal
            isOpen={dialogOpen}
            setIsOpen={setDialogOpen}
            usersBlockingRemoval={usersBlockingRemoval.current || []}
            itemName="Seniority"
          />
          <RemoveItemModal
            message={t('organizationSettings.deleteConfirm', {
              name: itemToDelete.current?.name,
              item: t('userProfile.seniority').toLowerCase(),
            })}
            close={() => setConfirmOpen(false)}
            isOpen={isConfirmOpen}
            confirm={deleteSeniority}
            header={t('users.removeSeniority')}
          />
        </>
      )}
    </>
  );
};
