import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectIsAuthUserLoading,
  selectUserLoggedIn,
} from 'screens/Auth/authSlice';

const REDIRECT_PATH = '/auth/login';

export const useUserAuthentication = () => {
  const isLoggedIn = useSelector(selectUserLoggedIn);
  const isAuthDataLoading = useSelector(selectIsAuthUserLoading);
  const isUserOnLoginPage = useMemo(
    () => window.location.href.includes(REDIRECT_PATH),
    [],
  );

  if (!isLoggedIn && !isUserOnLoginPage && !isAuthDataLoading) {
    localStorage.clear();
  }

  return isLoggedIn;
};
