import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Row } from 'react-table';

import { ListHeaderWrapper } from '../Users/components/listStyles';
import { User, WeekUser } from 'api/users/models';
import {
  SimpleUsersTable,
  Variant,
} from 'components/layout/Tables/SimpleUsersTable';
import { NoInfoText } from 'components/layout/Tables/styled';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { BorderBox } from 'components/primitive/BorderBox/BorderBox';
import {
  Avatar,
  Button,
  HoursProgressBar,
  ProjectPhasePersonLink,
  Spin,
  WeekDatePicker,
} from 'components/ui';
import { Tooltip } from 'components/ui/Tooltip';
import { WeekDataType } from 'components/ui/WeekDatePicker/hooks';
import { format, parseISO } from 'date-fns';
import { useWeeks } from 'fetchers';
import isArray from 'lodash/isArray';
import { Box, Flex } from 'rebass';
import {
  SelectedWeek,
  selectSelectedWeeksData,
} from 'store/slices/selectedWeeksSlice';
import { getReport, selectUsersData } from 'store/slices/usersSlice';
import { theme } from 'styles/theme';
import { currentWeek } from 'utils/constants';

import { TableWrapper, TableBody } from './styled';

const AvailableResources: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedWeeks } = useSelector(selectSelectedWeeksData);
  const { isLoading, users } = useSelector(selectUsersData);
  const [activeWeekData, setActiveWeekData] = useState<{
    users: WeekUser[] | undefined;
    weekNumber: number;
  }>({
    users: [],
    weekNumber: currentWeek,
  });
  const { isLoading: isUsersWeeksLoading, usersWeeks } = useWeeks();
  const isActiveWeekDataSet = useRef(false);
  const isMultiWeeks = selectedWeeks.length > 1;

  const prepareAndSetActiveWeekData = useCallback(
    (weekNumber: number) => {
      if (usersWeeks.length > 0) {
        setActiveWeekData({
          users: usersWeeks.find((usersWeek) => usersWeek.week === weekNumber)
            ?.users,
          weekNumber: weekNumber,
        });
      }
    },
    [usersWeeks],
  );

  useEffect(() => {
    if (!isLoading && !isUsersWeeksLoading && !isActiveWeekDataSet.current) {
      isActiveWeekDataSet.current = true;

      prepareAndSetActiveWeekData(currentWeek);
    }
  }, [isLoading, isUsersWeeksLoading, prepareAndSetActiveWeekData]);

  const getUsersByAvailabilty = useCallback(
    (available: boolean) =>
      activeWeekData.users?.filter((user) =>
        available ? user.is_available : !user.is_available,
      ),
    [activeWeekData.users],
  );

  const availableUsersThisWeek = useMemo(() => getUsersByAvailabilty(true), [
    getUsersByAvailabilty,
  ]);

  const unavailableUsersThisWeek = useMemo(() => getUsersByAvailabilty(false), [
    getUsersByAvailabilty,
  ]);

  const availableUsers = useMemo(
    () =>
      users.filter((user) => {
        if (
          usersWeeks.find(
            (usersWeek) => usersWeek.week === activeWeekData.weekNumber,
          )
        ) {
          return (
            isArray(availableUsersThisWeek) &&
            availableUsersThisWeek.length > 0 &&
            !unavailableUsersThisWeek?.find((uu) => uu.id === user.id) &&
            user.available_resource
          );
        }
        return user.available_resource;
      }),
    [
      availableUsersThisWeek,
      unavailableUsersThisWeek,
      users,
      usersWeeks,
      activeWeekData.weekNumber,
    ],
  );

  const handleSelectWeeks = useCallback(
    (selectedWeeks: WeekDataType[]) =>
      prepareAndSetActiveWeekData(selectedWeeks[0].weekNumber),
    [prepareAndSetActiveWeekData],
  );

  const handleGenerateReport = (additionalWeeks = 3) => {
    let weeksNumbers = [];
    let week = currentWeek;

    for (let i = 0; i <= additionalWeeks; i++) {
      weeksNumbers.push(week++);
    }

    dispatch(getReport({ weeksNumbers }));
  };

  const getWeeklyReportColumnHeader = (week: SelectedWeek, index: number) => (
    <Flex width="100%">
      <Tooltip
        render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
          <Box mr={28} sx={{ flexShrink: 0 }} width={100}>
            <Button
              key={week.weekNumber}
              variant="simpleWithBorder"
              minWidth="max-content"
              onClick={() => prepareAndSetActiveWeekData(week.weekNumber)}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              ref={triggerRef}
              sx={
                activeWeekData.weekNumber === week.weekNumber && isMultiWeeks
                  ? { backgroundColor: 'white' }
                  : null
              }
            >
              {t('common.week')} {week.weekNumber} -{' '}
              {format(parseISO(week.start), 'dd.MM')} -{' '}
              {format(parseISO(week.end), 'dd.MM')}
            </Button>
          </Box>
        )}
      >
        {t('tooltips.weekFilterSelect')}
      </Tooltip>
    </Flex>
  );

  const getWeeklyReportColumnCell = (row: any, week: SelectedWeek) => {
    const user = row.original;

    const singleUserData = usersWeeks
      .find((userWeek) => userWeek.week === week.weekNumber)
      ?.users.find((u) => u.id === user.id);

    return (
      <HoursProgressBar
        verifiedHours={singleUserData?.verified_user_hours || 0}
        maxHours={user.hours_weekly || 0}
        hoursOff={singleUserData?.hours_off}
      />
    );
  };

  const columns: Column<User>[] = React.useMemo(
    () => [
      {
        id: 'user_name',
        minWidth: 310,
        flex: '1 0 auto',
        accessor: 'full_name',
        Header: 'Person',
        Cell: ({ row }: { row: Row<User> }) => {
          const user = row.original;
          return (
            <ProjectPhasePersonLink
              href={`/users/${row.original.id}`}
              source={'projects/available-resources'}
            >
              <Avatar
                src={user.photo?.mini?.url || ''}
                name={user.full_name || user.email}
                size="sm"
              />
              <Box as="span" ml={3} sx={{ fontSize: 'sm' }}>
                {user.full_name}
              </Box>
            </ProjectPhasePersonLink>
          );
        },
      },
      {
        accessor: (user) =>
          user.position?.name ? user.position?.name : user.position,
        id: 'position',
        minWidth: 270,
        Header: 'Position',
        flex: '1 0 auto',
        Cell: ({ row }: any) => {
          const user = row.original;
          const position =
            user.position && user.position?.name
              ? user.position.name
              : user.position;

          if (position) {
            return <Box sx={{ fontSize: 'sm' }}>{position}</Box>;
          }

          return <NoInfoText>No position</NoInfoText>;
        },
      },
      ...selectedWeeks.map((week, index, selectedWeeksArray) => ({
        id: `user_hours_weekly_${index}`,
        minWidth: 200,
        width: 'max-content',
        Header: () => getWeeklyReportColumnHeader(week, index),
        Cell: ({ row }: any) => getWeeklyReportColumnCell(row, week),
      })),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMultiWeeks, selectedWeeks, usersWeeks, activeWeekData],
  );

  return (
    <>
      {isLoading || !isActiveWeekDataSet.current ? (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%)',
          }}
        >
          <Spin />
        </Box>
      ) : (
        <TableWrapper>
          <ListHeaderWrapper justifyContent="space-between">
            <Flex>
              <WeekDatePicker onChange={handleSelectWeeks} variant="table" />
            </Flex>
            <Flex>
              <BorderBox left={true}>
                <PrimeTextButton
                  label={t('projects.generateReport')}
                  onClick={handleGenerateReport}
                  icon="pi pi-download"
                  variant="secondary"
                  option="list"
                  fontSize={theme.fontSizes.sm}
                />
              </BorderBox>
            </Flex>
          </ListHeaderWrapper>

          {isUsersWeeksLoading ? (
            <Flex justifyContent="center" height={300} mt={theme.space[5]}>
              <Spin />
            </Flex>
          ) : (
            <TableBody>
              <SimpleUsersTable
                variant={Variant.GRAY_HEADER}
                columns={columns}
                data={availableUsers}
                sx={{ borderSpacing: 0 }}
              />
            </TableBody>
          )}
        </TableWrapper>
      )}
    </>
  );
};

export default AvailableResources;
