import React from 'react';

const JiraIcon = ({ width = '20', height = '19' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 19 19"
  >
    <path
      fill="#2684FF"
      fillRule="evenodd"
      d="M18.756 9.497L10.013 1 1.269 9.497c-.285.278-.285.728 0 1.007l8.744 8.493 8.743-8.493c.285-.279.285-.729 0-1.007zm-8.74 3.164L7.277 9.999l2.739-2.66 2.738 2.66-2.738 2.662z"
      clipRule="evenodd"
    />
    <path
      fill="url(#prefix__paint0_linear)"
      fillRule="evenodd"
      d="M10.014 7.356C8.22 5.614 8.212 2.791 9.994 1.04l-6.01 5.804 3.26 3.169 2.77-2.656z"
      clipRule="evenodd"
    />
    <path
      fill="url(#prefix__paint1_linear)"
      fillRule="evenodd"
      d="M12.76 9.992l-2.746 2.669c.865.84 1.351 1.98 1.351 3.169s-.486 2.329-1.351 3.169l6.005-5.836-3.26-3.17z"
      clipRule="evenodd"
    />
    <path
      fill="#2684FF"
      fillRule="evenodd"
      d="M17.7 8.497L8.958 0 .214 8.497c-.285.278-.285.728 0 1.007l8.743 8.493 8.744-8.493c.285-.279.285-.729 0-1.007zM8.96 11.66L6.222 8.999 8.96 6.34l2.739 2.66-2.739 2.662z"
      clipRule="evenodd"
    />
    <path
      fill="url(#prefix__paint2_linear)"
      fillRule="evenodd"
      d="M8.958 6.356C7.165 4.614 7.156 1.791 8.938.04l-6.01 5.804 3.261 3.169 2.77-2.656z"
      clipRule="evenodd"
    />
    <path
      fill="url(#prefix__paint3_linear)"
      fillRule="evenodd"
      d="M11.704 8.992l-2.746 2.669c.865.84 1.351 1.98 1.351 3.169s-.486 2.329-1.351 3.169l6.006-5.836-3.26-3.17z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="prefix__paint0_linear"
        x1="11.674"
        x2="2.341"
        y1="-2.021"
        y2="-.273"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="prefix__paint1_linear"
        x1="8.346"
        x2="17.799"
        y1="22.139"
        y2="20.424"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="prefix__paint2_linear"
        x1="10.618"
        x2="1.285"
        y1="-3.021"
        y2="-1.273"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="prefix__paint3_linear"
        x1="7.29"
        x2="16.743"
        y1="21.139"
        y2="19.424"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>
);

JiraIcon.displayName = 'JiraIcon';

export default JiraIcon;
