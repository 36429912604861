import React from 'react';

import { ChevronIcon } from 'components/primitive';
import { Box } from 'rebass';

import { TableToggleButtonWrapper } from './styled';

type Props = {
  id: string;
  isExpanded: boolean;
  marginTop?: string;
};

export const TableToggleButton: React.FC<Props> = React.memo(
  ({ id, isExpanded, ...props }) => {
    return (
      <TableToggleButtonWrapper {...props} as="span">
        <ChevronIcon open={isExpanded} />
        <Box as="span" fontSize={0}>
          {isExpanded ? 'Collapse row' : 'Expand row'}
        </Box>
      </TableToggleButtonWrapper>
    );
  },
);
