import React, { forwardRef, useEffect } from 'react';
import { FiCheck, FiMinus } from 'react-icons/fi';

import { IconVariant } from '../';

import {
  CheckboxWrapper,
  CheckboxInput,
  CheckboxLabel,
  CheckboxLabelText,
  CheckboxLabelInsideWrapper,
  CheckboxRoundedCheck,
} from './styled';

export type CheckboxVariants = 'default' | 'labelInside';

export type CheckboxProps = {
  id?: string;
  name?: string;
  value?: string | number;
  iconVariant?: IconVariant;
  isIndeterminate?: boolean;
  'aria-label'?: string;
  'aria-labelledby'?: string;
  size?: 'sm' | 'md';
  variant?: CheckboxVariants;
  defaultIsChecked?: boolean;
  isChecked?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isReadOnly?: boolean;
  labelStyles?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  children?: React.ReactNode;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id,
      name,
      value,
      iconVariant,
      isIndeterminate = false,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      size = 'md',
      defaultIsChecked,
      isChecked,
      isDisabled,
      isInvalid,
      isReadOnly,
      labelStyles,
      onChange,
      onBlur,
      onFocus,
      variant = 'default',
      children,
      ...rest
    },
    ref,
  ) => {
    const defaultRef = React.useRef<HTMLInputElement>(null!);
    const resolvedRef = ref || defaultRef;

    const sizes = (() => ({
      styled: size === 'sm' ? '13px' : '20px',
      check: size === 'sm' ? '11px' : '17px',
    }))();

    useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = isIndeterminate;
    }, [resolvedRef, isIndeterminate]);

    return (
      <CheckboxLabel
        onClick={(e) => e.stopPropagation()}
        styles={labelStyles}
        iconVariant={iconVariant}
        disabled={isDisabled}
      >
        <CheckboxInput
          {...rest}
          id={id}
          type="checkbox"
          ref={resolvedRef}
          name={name}
          value={value}
          onChange={isReadOnly ? undefined : onChange}
          disabled={isDisabled}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          onBlur={onBlur}
          onFocus={onFocus}
          defaultChecked={isReadOnly ? undefined : defaultIsChecked}
          checked={
            isReadOnly
              ? Boolean(isChecked)
              : defaultIsChecked
              ? undefined
              : isChecked
          }
          readOnly={isReadOnly}
          aria-readonly={isReadOnly}
          aria-invalid={isInvalid}
          aria-checked={isChecked}
        />

        {variant === 'default' && (
          <>
            <CheckboxWrapper size={sizes.styled}>
              {isIndeterminate ? (
                <FiMinus size={sizes.check} />
              ) : (
                <FiCheck size={sizes.check} />
              )}
            </CheckboxWrapper>
            {children && <CheckboxLabelText>{children}</CheckboxLabelText>}
          </>
        )}

        {variant === 'labelInside' && (
          <CheckboxLabelInsideWrapper>
            <CheckboxRoundedCheck>
              <FiCheck size="12px" />
            </CheckboxRoundedCheck>
            {children && children}
          </CheckboxLabelInsideWrapper>
        )}
      </CheckboxLabel>
    );
  },
);
