import { Supervisor } from '../projects/models';

export enum Roles {
  ADMIN = 'admin',
  RESOURCE_PLANNER = 'resource_planner',
  TEAM_LEADER = 'team_leader',
  PM = 'product_manager',
  USER = 'user',
  FINANCIAL_ADMINISTRATOR = 'financial_administrator',
}

export interface UserPayload {
  team_member_supervisors?: { id: number; supervisor: Supervisor }[];
  id?: number;
  available_resource?: boolean;
  full_name?: string;
  email?: string;
  hours_weekly?: number;
  active?: boolean;
  team_id?: number | null;
  position_id?: number | null;
  seniority_id?: number | null;
  department_id?: number | null;
  photo?: File | string;
  password?: string;
  password_confirmation?: string;
  sex?: string;
  toggle_name?: string;
  github_nick?: string;
  skills_attributes?: UserSkillPayload[];
  slack_mention?: string;
  user_types?: Roles[];
  _destroy?: boolean;
  use_google_avatar?: boolean;
  supervisor_id?: number;
  team_member_supervisors_attributes?: Supervisor[];
  show_jira_tickets?: boolean;
}

export interface UserPhoto {
  url: string | null;
  mini: {
    url: string | null;
  };
  medium: {
    url: string | null;
  };
}

export interface UsersWeek {
  week: number;
  year: number;
  start_date: string;
  end_date: string;
  users: WeekUser[];
}

export interface WeekUser {
  id: number;
  hours_off: number;
  hours_weekly: number;
  verified_user_hours: number;
  not_verified_user_hours: number;
  is_available: boolean;
}

export interface User {
  id: number;
  active: boolean;
  available_resource?: boolean;
  created_at: string;
  email: string;
  full_name: string;
  github_nick?: string;
  google_2fa_enabled?: boolean;
  hours_weekly?: number;
  last_edited_by: {
    email: string;
    fullname: string;
    id: number;
  };
  level?: string;
  seniority?: UserSeniority;
  photo: UserPhoto;
  photos: {
    photo: UserPhoto;
    google_avatar: UserPhoto;
  };
  position?: UserPosition;
  department?: UserDepartment;
  sex: string;
  skills?: UserSkill[];
  supervisors: Supervisor[];
  team_member_supervisors?: { id: number; supervisor: Supervisor }[];
  team_members: User[];
  team: UserTeam;
  updated_at: string;
  use_google_avatar: boolean;
  user_types: Roles[];
  notification_setting: {
    weekly_report_email_enable: boolean | null;
    weekly_report_slack_enable: boolean | null;
  };
  show_jira_tickets: boolean;
  sign_in_count: number;
  project_preferences: UserPreferences;
}

export type UserType = {
  id: number;
  name: Roles;
};

export interface UserRole {
  id: number;
  name: Roles;
  resource_id?: number | null;
  resource_type?: string | null;
  created_at?: string;
  updated_at?: string;
}

export interface CommonSettingsFields {
  id: number;
  name: string;
  full_name?: string;
}

export type UserPosition = {
  available_levels: string[];
} & CommonSettingsFields;

export type UserSeniority = CommonSettingsFields;
export type UserSupervisor = CommonSettingsFields;
export type ProjectType = CommonSettingsFields;
export type UserDepartment = CommonSettingsFields;

export interface UserLoginData {
  avatar: string;
  email: string;
  full_name: string;
  id: number;
  permissions: UserPermissions;
  photo: UserPhoto;
  roles: UserRole[];
  sign_in_count: number;
  google_2fa_enabled: boolean;
}

export interface UserPermissions {
  [key: string]: {
    'index?'?: boolean;
    'create?'?: boolean;
    'destroy?'?: boolean;
    'me?'?: boolean;
    'show?'?: boolean;
    'update?'?: boolean;
    permitted_attributes_for_create?: UserPermittedAttributes;
    permitted_attributes_for_show?: UserPermittedAttributes;
    permitted_attributes_for_update?: UserPermittedAttributes;
  };
}

export type UserPermittedAttributes = (string | { [key: string]: [] })[];

export interface UserSkill {
  id: number;
  name: string;
  level?: number;
  _destroy?: boolean;
}

export interface UserSkillPayload {
  id: number;
  name: string;
  skill_name_id?: number;
  level?: number;
  _destroy?: boolean;
}

export interface UserTeam {
  id: number;
  name: string;
  positions: UserPosition[];
  created_at?: string;
}

export interface UserIssue {
  id: number;
  project: {
    id: number;
    name: string;
  };
  sprint: {
    id: number;
    name: string;
  };
  description: string;
  jira_key: string;
  time_original_estimate: number;
  time_spent: number;
  time_remaining: number;
  delivered: boolean;
  accepted: boolean;
  done: boolean;
  created_at: string;
}

export interface UserProject {
  id: number;
  name: string;
  avatar: string | null;
  sprints: UserSprint[];
}

export interface UserSprint {
  id: number;
  jira_id: string;
  name: string;
  state: 'active' | 'closed';
}

export interface TFAData {
  key: string;
  qr_code: string;
}

export interface TFAPayload {
  mfa_code: string;
}

export interface SignInWithTFAPayload {
  email: string;
  mfa_code: string;
}

export interface ResponsibleUser {
  id: number;
  user_id: number;
  full_name: string;
  photo?: UserPhoto;
}

export interface ResourcesReportsPayload {
  conflicts: {
    [key: number]: { [key: number]: ResourcesReportsPayloadConflict[] };
  };
  free_resources: ResourceReportsFreeResources;
  needed_resources: {
    [key: number]: { [key: number]: ResourcesReportsPayloadNeededResource[] };
  };
}

export interface ResourceReportsFreeResources {
  [key: number]: { [key: number]: ResourcesReportsPayloadFreeResource[] };
}

export interface ResourcesReportsPayloadConflict {
  user: string;
  details: ResourcesReportsPayloadConflictDetail[];
  hours_total: number;
}

export interface ResourcesReportsPayloadConflictDetail {
  hours_sum: number;
  people_responsible: string[];
  project_name: string;
}

export interface ResourcesReportsPayloadFreeResource {
  available_hours: number;
  full_name: string;
  seniority: string;
}

export interface ResourcesReportsPayloadNeededResource {
  average_planned_hours: number;
  resource_name: string;
}
export interface AvailableResources {
  weekNumber?: number;
  conflicts: ResourcesReportsPayloadConflict[];
  freeResources: ResourcesReportsPayloadFreeResource[];
}

export interface HiddenUserData {
  avatar?: string;
  id?: number;
  name?: string;
  phaseId?: number;
}

export interface UserPreferences {
  id: string;
  app_type: string[];
  description: string;
  project_type: string[];
  team_size: string[];
}
