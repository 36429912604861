import React from 'react';

import { Error, Wrapper } from '../styled';
import { PrimeDatePicker, DatePickerCustomProps } from 'components/prime';
import { useField } from 'formik';
import { CalendarProps } from 'primereact/calendar';

type Props = CalendarProps &
  DatePickerCustomProps & {
    name: string;
    isHigh?: boolean;
    validate?: (values: any) => undefined | string | Promise<any>;
  };

export const FormikDatePicker = ({
  name,
  onChange: customOnChange,
  onBlur: customOnBlur,
  variant = 'filled',
  validate,
  ...restProps
}: Props) => {
  const [field, meta, helpers] = useField({ name, validate });
  const { setTouched } = helpers;
  return (
    <Wrapper>
      <PrimeDatePicker
        {...field}
        onChange={(event) => {
          field.onChange(event);
          customOnChange && customOnChange(event);
        }}
        onBlur={(event) => {
          setTouched(true);
          customOnBlur && customOnBlur(event);
        }}
        iserror={meta.touched ? meta.error : ''}
        value={field.value ? field.value : undefined}
        variant={variant}
        {...restProps}
      />
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </Wrapper>
  );
};
