import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Supervisor } from 'api/projects/models';
import { PAGES_PATH } from 'components/layout/PrivateRoute/components/MainLayout/hooks';
import { ProjectPhasePersonLink, Avatar } from 'components/ui';
import { Box, Flex } from 'rebass';
import { theme } from 'styles/theme';
import { sortByKey } from 'utils/sortByKey';

type Props = {
  supervisors: Supervisor[];
};

export const Supervisors: React.FC<Props> = ({ supervisors }) => {
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
  };

  const sortedSupervisors = useMemo(() => {
    return supervisors?.sort(sortByKey('name')) || [];
  }, [supervisors]);

  if (!supervisors?.length) {
    return (
      <Box color={theme.colors.neutralGray}>
        {t('users.noSupervisorAssigned')}
      </Box>
    );
  }

  if (supervisors?.length < 2) {
    const { name, photo, id } = supervisors[0];
    const photoUrl = photo?.mini?.url;

    return (
      <Box pl={1}>
        <ProjectPhasePersonLink
          data-cy="user-view-btn"
          href={`/users/${id}`}
          isDisabled={!id}
          onClick={handleClick}
          source={PAGES_PATH.PROJECTS}
        >
          <Avatar name={name} size="small" radius="xmd" src={photoUrl || ''} />
          <Box as="span" ml={2} fontSize="xs">
            {name}
          </Box>
        </ProjectPhasePersonLink>
      </Box>
    );
  }

  return (
    <Flex sx={{ alignItems: 'center', size: '100%' }} pl={1}>
      {sortedSupervisors.map((supervisor) => {
        const { name, id, photo } = supervisor;
        return (
          <Box
            key={id}
            marginRight={1}
            sx={{
              marginTop: '-4px',
              ':hover .name': {
                opacity: 1,
              },
            }}
          >
            <ProjectPhasePersonLink
              data-cy="user-view-btn"
              href={`/users/${id}`}
              isDisabled={!id}
              onClick={handleClick}
              source={PAGES_PATH.PROJECTS}
            >
              <Flex sx={{ position: 'relative' }}>
                <Avatar
                  name={name}
                  size="small"
                  radius="xmd"
                  src={photo?.mini?.url || ''}
                />
                <Box
                  as="span"
                  className="name"
                  sx={{
                    backgroundColor: '#fff',
                    marginLeft: 0,
                    opacity: 0,
                    padding: '2px',
                    pointerEvents: 'none',
                    position: 'absolute',
                    top: '50px',
                    left: '-2px',
                    whiteSpace: 'nowrap',
                    zIndex: 2,
                  }}
                >
                  {name}
                </Box>
              </Flex>
            </ProjectPhasePersonLink>
          </Box>
        );
      })}
    </Flex>
  );
};
