import React from 'react';

import { RadioButton, RadioButtonProps } from 'primereact/radiobutton';

import { Label, RadioOption } from './styled';

const PrimeRadioButton = (props: RadioButtonProps & { label: string }) => {
  return (
    <RadioOption>
      <RadioButton {...props} />
      <Label htmlFor={props.label}>{props.label}</Label>
    </RadioOption>
  );
};

export default PrimeRadioButton;
