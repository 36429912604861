import { IconVariant } from '../';
import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type Props = {
  size?: string;
  styles?: any;
  disabled?: boolean;
  iconVariant?: IconVariant;
};

export const CheckboxWrapper = styled.span<Props>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  position: relative;
  border: 1px solid rgba(154, 166, 181, 0.75);
  border-radius: 2px;
  background-color: transparent;
  display: inline-block;
  color: ${theme.colors.white};
  transition: box-shadow 150ms, background-color 150ms, border-color 150ms;

  svg {
    position: absolute;
    display: none;
  }
`;

export const CheckboxLabelInsideWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${theme.fontSizes.xs};

  border-radius: ${theme.radii.sm};
  border: 1px solid ${theme.colors.neutral};

  width: 90px;
  height: 90px;
`;

export const CheckboxRoundedCheck = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: ${theme.radii.lg};
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
`;

export const CheckboxLabelText = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
  user-select: none;
  color: ${theme.colors.darkGray};
`;

export const CheckboxLabel = styled.label<Props>`
  width: fit-content;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  display: flex;
  align-items: center;
  align-self: ${({ iconVariant }) =>
    iconVariant === 'below' ? 'flex-start' : 'center'};

  ${({ styles }) => styles};
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;

  &:focus {
    & ~ ${CheckboxWrapper} {
      outline: none;
      box-shadow: ${theme.shadows.outline};
    }
  }

  &:checked,
  &:indeterminate {
    & ~ ${CheckboxWrapper} {
      background-color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
      svg {
        display: block;
      }
    }

    & ~ ${CheckboxLabelInsideWrapper} {
      border-color: ${theme.colors.primary};
      background-color: ${theme.colors.lightBlue};
      & > ${CheckboxRoundedCheck} {
        opacity: 1;
      }
    }
  }
  &:disabled {
    & ~ ${CheckboxWrapper} {
      background-color: ${theme.colors.neutral};
      border-color: ${theme.colors.neutral};
    }

    & ~ ${CheckboxLabelText} {
      color: ${theme.colors.neutralDark};
    }

    & ~ ${CheckboxLabelInsideWrapper} {
      color: ${theme.colors.neutralDark};
      border-color: ${theme.colors.neutralDark};
      background-color: ${theme.colors.neutralLight};
      & > ${CheckboxRoundedCheck} {
        background-color: ${theme.colors.neutralDark};
      }
    }
  }
`;
