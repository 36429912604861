import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const StyledAddButton = styled.button`
  width: 32px;
  height: 32px;
  background: ${theme.colors.primaryLight};
  color: ${theme.colors.white};
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.radii.md};
  margin: 0 2px;
`;
