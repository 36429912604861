import i18n from 'utils/i18n';
import * as Yup from 'yup';

export const validationSchemaIntegration = () =>
  Yup.object().shape({
    token: Yup.string().required(i18n.t('errors.required')),
  });

export const validationSchemaConfiguration = () =>
  Yup.object().shape({
    worklog_issue_type_id: Yup.string().required(i18n.t('errors.required')),
    worklog_issue_type_name: Yup.string().required(i18n.t('errors.required')),
  });
