import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSeniority } from 'api/users/models';
import { Error } from 'components/forms/formik-prime/styled';
import { PrimeTextInput } from 'components/prime';
import { NewPrimeButton } from 'components/prime/NewButton/NewButton';
import { addSeniority } from 'fetchers/actions/seniority/addSeniority';
import { updateSeniority } from 'fetchers/actions/seniority/updateSeniority';
import { Dialog } from 'primereact/dialog';
import { Box, Flex } from 'rebass';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  seniorityList: string[];
  seniority: UserSeniority | null;
}

export const SeniorityModal = ({
  isOpen,
  setIsOpen,
  seniorityList,
  seniority,
}: Props) => {
  const [seniorityName, setSeniorityName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setDefaultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seniority]);

  useEffect(() => {
    if (seniorityName.length > 0) {
      setError('');
    }
  }, [seniorityName]);

  const onClose = () => {
    setError('');
    setDefaultData();
    setIsOpen(false);
  };

  const setDefaultData = () => {
    if (seniority) {
      setSeniorityName(seniority.name);
    } else {
      setSeniorityName('');
    }
  };

  const onSubmit = () => {
    const _seniorityName = seniorityName.trim();
    const list = seniority
      ? seniorityList.filter((p) => p !== seniority.name.toLowerCase())
      : seniorityList;
    if (!_seniorityName.length) {
      setError(t('errors.required'));
    } else if (list.includes(_seniorityName.toLowerCase())) {
      setError(t('errors.nameTaken'));
    } else {
      if (seniority) {
        updateSeniority(_seniorityName, seniority.id);
      } else {
        addSeniority(_seniorityName);
      }
      onClose();
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Dialog
      onHide={onClose}
      visible={isOpen}
      blockScroll={true}
      header={t(seniority ? 'users.editSeniority' : 'users.addSeniority')}
    >
      <Flex minWidth="300px" flexDirection="column">
        <PrimeTextInput
          ishigh="false"
          iserror={error}
          value={seniorityName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSeniorityName(event.target.value)
          }
          label={`${t('users.seniorityName')}*`}
          onKeyDown={onKeyPress}
          autoFocus
        />
        {error && <Error>{error}</Error>}
      </Flex>
      <Flex mt={4} justifyContent="flex-end">
        <Box>
          <NewPrimeButton label={t('common.cancel')} onClick={onClose} />
        </Box>
        <Box ml={2}>
          <NewPrimeButton label={t('common.save')} onClick={onSubmit} />
        </Box>
      </Flex>
    </Dialog>
  );
};
