import styled from '@emotion/styled/macro';
import { Button } from 'primereact/button';
import { theme } from 'styles/theme';

export const NewStyledButton = styled(Button)`
  background-color: ${theme.colors.primary};
  border-radius: ${theme.radii.xs};
  padding: ${theme.space[3]} ${theme.space[6]};

  &.p-button {
    height: ${theme.dimensions.inputHeightLow};
    color: ${theme.colors.white};

    &:hover {
      background-color: ${theme.colors.primary};
      box-shadow: ${theme.shadows.mainButton};
    }

    & span.pi {
      font-weight: 600;
      margin-right: ${theme.space[4]};
    }
  }

  & .p-button-label {
    font-size: ${theme.fontSizes.xxs};
  }

  & .p-button-icon-left,
  & .p-button-icon-right {
    font-size: ${theme.fontSizes.xxs};
  }
`;
