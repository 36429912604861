import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const Wrapper = styled.div`
  position: relative;
  width: 604px;
`;

export const SearchIcon = styled.i`
  color: ${theme.colors.midGray};
  pointer-events: none;
  max-width: ${theme.space[4]};
`;

export const ClearIcon = styled.i`
  outline: none;
  color: ${theme.colors.midGray};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: ${theme.space[1]};
`;
