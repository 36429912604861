import {
  OrganizationSettingsResponse,
  AtlassianIntegrationPayload,
  CalamariIntegrationPayload,
  TogglIntegrationPayload,
  LdapIntegrationPayload,
  PersonioIntegrationPayload,
  UpdateOrganizationSettingsPayload,
  TempoIntegrationPayload,
  RollbarIntegrationPayload,
} from 'api/organizationSettings/models';
import { AxiosResponse } from 'axios';
import { serialize } from 'object-to-formdata';
import httpClient from 'utils/httpClient';

export type OrganizationSettingsRes = {
  phase_user_range: OrganizationSettingsResponse;
};

export type JiraIntegrationRes = {
  phase_user_range: Required<AtlassianIntegrationPayload>;
};

export type DefaultIntegrationRes = {
  phase_user_range: any;
};

const ORGANIZATION_SETTINGS_PATH = '/company/integrations/settings';
const ATLASSIAN_INTEGRATIONS_PATH = '/company/integrations/atlassian';
const CALAMARI_INTEGRATIONS_PATH = '/company/integrations/calamari';
const TOGGL_INTEGRATIONS_PATH = '/company/integrations/toggl';
const LDAP_INTEGRATIONS_PATH = '/company/integrations/ldap';
const PERSONIO_INTEGRATIONS_PATH = '/company/integrations/personio';
const TEMPO_INTEGRATION_PATH = '/company/integrations/tempo';
const ROLLBAR_INTEGRATION_PATH = '/company/integrations/rollbar';

export const organizationSettingsAPI = {
  async getIntegrationsStatus(): Promise<any> {
    return await httpClient.get(ORGANIZATION_SETTINGS_PATH);
  },
  async updateOrganizationSettings(
    settings: UpdateOrganizationSettingsPayload,
  ): Promise<AxiosResponse<OrganizationSettingsRes>> {
    const options = { indices: false };
    const formData = serialize(settings, options);
    return await httpClient.put(`${ORGANIZATION_SETTINGS_PATH}`, formData);
  },
  async addAtlassianIntegration(
    project: AtlassianIntegrationPayload,
  ): Promise<AxiosResponse<JiraIntegrationRes>> {
    return await httpClient.post(`${ATLASSIAN_INTEGRATIONS_PATH}`, project);
  },
  async addCalamariIntegration(
    formData: CalamariIntegrationPayload,
  ): Promise<AxiosResponse<DefaultIntegrationRes>> {
    return await httpClient.post(`${CALAMARI_INTEGRATIONS_PATH}`, formData);
  },
  async addTogglIntegration(
    formData: TogglIntegrationPayload,
  ): Promise<AxiosResponse<DefaultIntegrationRes>> {
    return await httpClient.post(`${TOGGL_INTEGRATIONS_PATH}`, formData);
  },
  async addLdapIntegration(
    formData: LdapIntegrationPayload,
  ): Promise<AxiosResponse<DefaultIntegrationRes>> {
    return await httpClient.post(`${LDAP_INTEGRATIONS_PATH}`, formData);
  },
  async addPersonioIntegration(
    formData: PersonioIntegrationPayload,
  ): Promise<AxiosResponse<DefaultIntegrationRes>> {
    return await httpClient.post(`${PERSONIO_INTEGRATIONS_PATH}`, formData);
  },
  async addTempoIntegration(
    formData: TempoIntegrationPayload,
  ): Promise<AxiosResponse<DefaultIntegrationRes>> {
    return await httpClient.post(`${TEMPO_INTEGRATION_PATH}`, formData);
  },
  async addRollbarIntegration(
    formData: RollbarIntegrationPayload,
  ): Promise<AxiosResponse<DefaultIntegrationRes>> {
    return await httpClient.post(`${ROLLBAR_INTEGRATION_PATH}`, formData);
  },
};
