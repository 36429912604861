import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SubheaderWrapper } from '../../../styled';
import { organizationSettingsAPI } from 'api/organizationSettings/endpoints';
import { FormikTextInput } from 'components/forms/formik-prime';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { addTempoIntegration } from 'fetchers/actions/addTempoIntegration';
import { useOrganizationSettings } from 'fetchers/hooks/useOrganizationSettings';
import { Form, Formik } from 'formik';
import { Box, Flex } from 'rebass';
import { HorizontalLine } from 'screens/User/components/GeneralInfoFields';
import { displaySnackbar } from 'store/slices/snackbarSlice';

import { InputWrapper } from './styled';
import {
  validationSchemaConfiguration,
  validationSchemaIntegration,
} from './validationSchema';

interface Props {
  onClose: () => void;
  canIntegrate: boolean;
  isIntegrated: boolean;
}

interface TempoIntegrationForm {
  token: string;
}

interface TempoConfigurationForm {
  worklog_issue_type_id: string;
  worklog_issue_type_name: string;
}

export const TempoSection = ({
  onClose,
  canIntegrate,
  isIntegrated,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mutate, settings } = useOrganizationSettings();

  const settingFormInit = useMemo(
    () => ({
      worklog_issue_type_id:
        settings?.tempo?.settings.worklog_issue_type_id || '',
      worklog_issue_type_name:
        settings?.tempo?.settings.worklog_issue_type_name || '',
    }),
    [settings],
  );

  const handleSubmitIntegration = (values: TempoIntegrationForm) => {
    addTempoIntegration(values)
      .then(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationSuccess'),
          }),
        );
        onClose();
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationFail'),
            isError: true,
          }),
        );
      });
  };

  const handleSubmitConfiguration = (values: TempoConfigurationForm) => {
    organizationSettingsAPI
      .updateOrganizationSettings({
        service_name: 'tempo',
        settings: { ...values },
      })
      .then(() => {
        mutate();
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.updateSuccess'),
          }),
        );
        onClose();
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.updateFail'),
            isError: true,
          }),
        );
      });
  };

  return (
    <Flex flexDirection="column" mt={4}>
      {canIntegrate ? (
        <SubheaderWrapper active={true}>
          <Box mr={2} display="inline">
            <i className="pi pi-info-circle" />
          </Box>
          <Box display="inline">
            {t(
              isIntegrated
                ? 'organizationSettings.tempo.integrationOn'
                : 'organizationSettings.tempo.integrationValid',
            )}
          </Box>
        </SubheaderWrapper>
      ) : (
        <>
          {isIntegrated ? (
            <Formik
              initialValues={settingFormInit}
              validationSchema={validationSchemaConfiguration}
              onSubmit={handleSubmitConfiguration}
            >
              <Form>
                <InputWrapper>
                  <FormikTextInput
                    name="worklog_issue_type_id"
                    label={t('organizationSettings.tempo.id')}
                    ishigh="false"
                    variant="default"
                  />
                  <FormikTextInput
                    name="worklog_issue_type_name"
                    label={t('organizationSettings.tempo.name')}
                    ishigh="false"
                    variant="default"
                  />
                </InputWrapper>
                <HorizontalLine />
                <Flex justifyContent="flex-end">
                  <Box mr={3}>
                    <PrimeTextButton
                      onClick={onClose}
                      label={t('common.cancel')}
                    />
                  </Box>
                  <NewStyledButton
                    type="submit"
                    variant="default"
                    label={t('organizationSettings.tempo.save')}
                  />
                </Flex>
              </Form>
            </Formik>
          ) : (
            <Formik
              initialValues={{
                token: '',
              }}
              validationSchema={validationSchemaIntegration}
              onSubmit={handleSubmitIntegration}
            >
              <Form>
                <InputWrapper>
                  <FormikTextInput
                    name="token"
                    label={t('organizationSettings.tempo.token')}
                    ishigh="false"
                    variant="default"
                  />
                </InputWrapper>
                <HorizontalLine />
                <Flex justifyContent="flex-end">
                  <Box mr={3}>
                    <PrimeTextButton
                      onClick={onClose}
                      label={t('common.cancel')}
                    />
                  </Box>
                  <NewStyledButton
                    type="submit"
                    variant="default"
                    label={t('organizationSettings.tempo.connect')}
                  />
                </Flex>
              </Form>
            </Formik>
          )}
        </>
      )}
    </Flex>
  );
};
