import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserSkill, UserPayload } from 'api/users/models';
import { Tooltip } from 'primereact/tooltip';
import { Flex, Box } from 'rebass';

import { SkillLevel } from './styled';

type Props = {
  selectedItem: UserSkill;
  selectedItems?: UserSkill[];
  scale?: number;
  removeSelectedItem?: (item: UserSkill) => void;
  setSelectedItems: (
    values: React.SetStateAction<UserPayload>,
    shouldValidate?: boolean | undefined,
  ) => void;
};

const Skill: React.FC<Props> = ({
  selectedItem,
  selectedItems = [],
  scale = 3,
  removeSelectedItem,
  setSelectedItems,
}) => {
  const { t } = useTranslation();
  const levels = [...Array(scale).keys()];
  const [focusedLevel, setFocusedLevel] = useState<number>(
    selectedItem?.level || 0,
  );

  const handleChangeSkillLevel = (level: number) => {
    if (selectedItems) {
      const newSelectedItems = selectedItems.map((selectedSkill) =>
        selectedSkill.name === selectedItem.name
          ? { ...selectedSkill, level }
          : selectedSkill,
      );
      setSelectedItems((oldValues: UserPayload) => ({
        ...oldValues,
        skills_attributes: newSelectedItems,
      }));
    }
  };

  return (
    <>
      <Box pl={1} py={1}>
        <span>{selectedItem?.name}</span>
        <Tooltip target=".skillBox" />
        <Flex
          mt={2}
          mb={1}
          backgroundColor="neutral"
          width="lg"
          height="tiny"
          sx={{ borderRadius: 'lg' }}
          className="skillBox"
          data-pr-tooltip={`I - ${t('userProfile.skillsLevels.firstLevel')}
              II - ${t('userProfile.skillsLevels.secondLevel')}
              III - ${t('userProfile.skillsLevels.thirdLevel')}`}
        >
          {levels.map((level, idx) => (
            <SkillLevel
              key={`skill-level-${idx}`}
              onClick={() => handleChangeSkillLevel(level + 1)}
              focused={focusedLevel >= idx + 1}
              onMouseEnter={() => setFocusedLevel(idx + 1)}
              onMouseOut={() => setFocusedLevel(selectedItem?.level || 0)}
              left={`${level * 20}%`}
            />
          ))}
        </Flex>
      </Box>
      <Box
        as="span"
        ml={2}
        mr={1}
        sx={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          removeSelectedItem && removeSelectedItem(selectedItem);
        }}
      >
        &#10005;
      </Box>
    </>
  );
};

export default Skill;
