import React, { useEffect, useState } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { UserBoxLink } from '../UserBoxLink/styled';
import { UserName } from '../UserName/styled';
import { UserStatus } from '../UserStatus/styled';
import { ProjectAvatar } from 'api/projects/models';
import { PAGES_PATH } from 'components/layout/PrivateRoute/components/MainLayout/hooks';
import { ActionButtonsBox } from 'components/layout/Tables/columnsStyled';
import {
  Avatar,
  ConfirmedIcon,
  NotConfirmedIcon,
  Button,
  MODAL_TYPES,
} from 'components/ui';
import {
  setRequestedMemberToPhase,
  REQUESTED_USER_ACTIONS,
} from 'fetchers/actions/setRequestedMemberToPhase';
import { Box, Flex } from 'rebass';
import {
  RemoveButton,
  RemoveIcon,
} from 'screens/Projects/components/User/styled';
import { setActiveModal } from 'store/slices/modalsSlice';
import { MODAL_ID } from 'utils/constants';
import { usePermissions, PERMISSIONS } from 'utils/hooks/usePermissions';

type Props = {
  avatar?: ProjectAvatar;
  id: number;
  isConfirmed: boolean;
  name: string;
  phaseUserRangeId: number;
  phaseName: string;
  projectName: string;
  projectId: number;
};

export const User: React.FC<Props> = ({
  avatar,
  id,
  isConfirmed,
  name,
  phaseUserRangeId,
  phaseName,
  projectName,
  projectId,
}) => {
  const [isUserConfirmed, setIsUserConfirmed] = useState<boolean>(isConfirmed);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsUserConfirmed(isConfirmed);
  }, [isConfirmed, id]);

  const setShowRemoveUserModal = () => {
    dispatch(
      setActiveModal({
        activeModalId: MODAL_ID.REMOVE_USER,
        data: {
          user: {
            avatar: avatar?.medium?.url,
            name,
            phaseUserRangeId,
            phaseName,
            projectName,
            projectId,
          },
          title: 'Remove member',
          type: MODAL_TYPES.CONFIRM,
        },
      }),
    );
  };

  const canConfirmUsersOnPhase = usePermissions(
    PERMISSIONS.CONFIRM_USER_ON_PHASE,
  );
  const canRemoveUserFromPhase = usePermissions(
    PERMISSIONS.REMOVE_USER_FROM_PHASE,
  );

  const handleConfirmUser = () => {
    setRequestedMemberToPhase({
      action: REQUESTED_USER_ACTIONS.CONFIRM,
      phaseUserRangeId,
      projectId,
    });
  };

  const handleDeleteUser = () => {
    setRequestedMemberToPhase({
      action: REQUESTED_USER_ACTIONS.DELETE,
      phaseUserRangeId,
      projectId,
    });
  };

  return (
    <Flex
      alignItems="center"
      sx={{
        height: '100%',
        position: 'relative',
        width: '100%',
        [`&:hover > div, &:hover > button`]: {
          opacity: 1,
        },
      }}
    >
      <UserBoxLink
        to={{
          pathname: `/users/${id}`,
          state: {
            from: PAGES_PATH.PROJECTS,
          },
        }}
      >
        <Box mr={2} sx={{ position: 'relative' }}>
          <Avatar
            src={avatar?.mini?.url || ''}
            name={name}
            size="small"
            radius="xmd"
          />
          <UserStatus isChecked={isUserConfirmed}>
            {isUserConfirmed ? <ConfirmedIcon /> : <NotConfirmedIcon />}
          </UserStatus>
        </Box>
        <UserName>{name}</UserName>
      </UserBoxLink>
      {canRemoveUserFromPhase && isUserConfirmed && (
        <>
          <RemoveButton
            data-cy="open-remove-member-modal-btn"
            onClick={setShowRemoveUserModal}
          >
            <RemoveIcon size="17px" />
          </RemoveButton>
        </>
      )}
      {!isUserConfirmed && canConfirmUsersOnPhase && (
        <ActionButtonsBox
          sx={{
            pointerEvents: 'initial !important',
            zIndex: 100,
          }}
        >
          <Button
            fontSize="xs"
            px={3}
            mr={1}
            height="sm"
            leftComponent={<MdCheck />}
            variant="success"
            onClick={handleConfirmUser}
          >
            Confirm
          </Button>
          {canRemoveUserFromPhase && (
            <Button
              fontSize="xs"
              px={3}
              height="sm"
              leftComponent={<MdClose />}
              variant="danger"
              onClick={handleDeleteUser}
            >
              Decline
            </Button>
          )}
        </ActionButtonsBox>
      )}
    </Flex>
  );
};
