import { mutateIntegrationStatus } from '../mutations/mutateIntegrationStatus';
import { mutateOrganizationSettings } from '../mutations/mutateOrganizationSettings';
import { organizationSettingsAPI } from 'api/organizationSettings/endpoints';
import { LdapIntegrationPayload } from 'api/organizationSettings/models';

export const addLdapIntegration = async (formData: LdapIntegrationPayload) => {
  await organizationSettingsAPI.addLdapIntegration(formData);
  await mutateOrganizationSettings();
  await mutateIntegrationStatus();
};
