import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const StyledDatePicker = styled(DatePicker)<any>`
  height: ${theme.sizes.md};
  padding-left: ${theme.space[2]};
  padding-right: 55px;
  border-radius: ${theme.radii.md};
  border-width: 1px;
  border-style: solid;
  font-family: ${theme.fonts.regular};
  color: ${theme.colors.darkGray};
  border-color: ${({ isInvalid }) =>
    isInvalid ? theme.colors.danger : theme.colors.neutral};
  font-size: ${theme.fontSizes.sm};
  width: 100%;
  transition: box-shadow 150ms;
  cursor: pointer;

  background-color: ${({ variant }) => {
    if (variant === 'light') {
      return theme.colors.white;
    }
    return theme.colors.whiteLilac;
  }};

  &:focus {
    outline: none;
    box-shadow: ${theme.shadows.outline};
  }

  &::placeholder {
    color: ${theme.colors.darkGray};
    opacity: 0.5;
  }
`;
