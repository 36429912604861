import { useTranslation } from 'react-i18next';

import { NavBarProps } from '../components/NavBar';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

import { useProject } from './projectContext';

export const useMenuLinks: () => [NavBarProps, boolean] = () => {
  const { t } = useTranslation();
  const { isLoading, project, projectId } = useProject();

  const navBarProps: NavBarProps = {
    headerCard: {
      title: project?.name ?? '',
      description: project?.project_type.name,
      imageUrl: project?.avatar,
    },
    items: [
      {
        label: t('projectCard.pages.projectCard'),
        path: `/projects/${projectId}`,
      },
      {
        label: t('projectCard.pages.teamMembers'),
        path: `/projects/${projectId}/members`,
      },
      {
        label: t('projectCard.pages.projectTimeline'),
        path: `/projects/${projectId}/timeline`,
      },
      {
        label: t('projectCard.pages.projectPhases'),
        path: `/projects/${projectId}/phases`,
      },
      {
        label: t('projectCard.pages.projectIntegrations'),
        path: `/projects/${projectId}/integrations`,
      },
    ],
  };

  const hasAccessToEditProject = usePermissions(PERMISSIONS.EDIT_PROJECT);
  if (hasAccessToEditProject)
    navBarProps.items.push({
      label: t('projectCard.pages.projectSettings'),
      path: `/projects/${projectId}/settings`,
      subNavigation: {
        description: t('projectCard.pages.settings.description'),
        returnItem: {
          link: `/projects/${projectId}`,
          label: t('projectCard.pages.settings.returnToProject'),
        },
        items: [
          {
            label: t('projectCard.pages.settings.basicInformation'),
            path: `/projects/${projectId}/settings`,
          },
          {
            label: t('projectCard.pages.settings.links'),
            path: `/projects/${projectId}/settings/links`,
          },
          {
            label: t('projectCard.pages.settings.phases'),
            path: `/projects/${projectId}/settings/phases`,
          },
        ],
      },
    });

  return [navBarProps, isLoading];
};
