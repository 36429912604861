import React from 'react';

import { FieldSubLabel } from 'components/primitive';
import { InputNumberProps } from 'primereact/inputnumber';

import {
  StyledNumberInput,
  FieldIcon,
  Label,
  InputRightElement,
  InputGroup,
  Wrapper,
} from './styled';

export interface InputNumberCustomProps {
  label?: string;
  lefticon?: HTMLElement;
  righticon?: HTMLElement;
  width?: string;
  isLabelVisible?: boolean;
  icon?: HTMLElement;
  subLabel?: string;
  iserror?: string;
  'data-cy'?: string;
  append?: string | HTMLElement;
  variant?: 'filled' | 'standard';
  isHigh?: boolean;
  paddingRight?: string;
}

export const PrimeNumberInput = (
  props: InputNumberProps & InputNumberCustomProps,
) => {
  const {
    lefticon,
    righticon,
    'data-cy': dataCy,
    isLabelVisible = true,
    label,
    subLabel,
    append,
    icon,
    iserror,
    variant = 'filled',
    isHigh = true,
    paddingRight = '55px',
  } = props;
  return (
    <Wrapper>
      {icon ? <FieldIcon iserror={iserror}>{icon}</FieldIcon> : null}
      {isLabelVisible && label ? (
        <Label isIcon={icon} iserror={iserror}>
          {label}
        </Label>
      ) : null}
      {subLabel ? <FieldSubLabel>{subLabel}</FieldSubLabel> : null}
      <InputGroup
        className={`${lefticon && 'p-input-icon-left'} ${
          righticon && 'p-input-icon-right'
        }`}
      >
        {lefticon && lefticon}
        <StyledNumberInput
          {...props}
          isHigh={isHigh}
          variant={variant}
          data-cy={dataCy}
          iserror={iserror}
          className={iserror ? 'p-invalid' : ''}
          paddingRight={paddingRight}
        />
        {righticon && righticon}
        {append && <InputRightElement children={append} />}
      </InputGroup>
    </Wrapper>
  );
};
