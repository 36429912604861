import React, { ChangeEvent, useState, FC } from 'react';

import { Field, FieldProps } from 'formik';

import { CameraIcon } from './CameraIcon';
import {
  Image,
  ImageWrapper,
  Input,
  MissingImageWrapper,
  FieldLabel,
  RemoveAvatar,
} from './ImageUploader.styles';

export type ImageUploaderProps = {
  name: string;
  error?: string;
  onChange?: (value: File | null) => void;
  defaultValue?: string | null;
  'data-cy'?: string;
  validate?: (value: number) => undefined | string | Promise<any>;
};

export const ImageUploader: FC<ImageUploaderProps> = ({
  name,
  defaultValue,
  'data-cy': dataCy,
}) => {
  const [uploadedFileSrc, setUploadedFileSrc] = useState(defaultValue);

  return (
    <FieldLabel>
      <Field name={name}>
        {({ form: { setFieldValue } }: FieldProps) => (
          <>
            <Input
              type="file"
              data-cy={dataCy}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const file = e.target.files && e.target.files[0];
                if (file) {
                  const srcUrl = URL.createObjectURL(file);
                  setUploadedFileSrc(srcUrl);
                  setFieldValue(name, file);
                }
              }}
              accept=".png,.jpg,.webp"
            />
            <RemoveAvatar
              data-cy="remove-avatar-btn"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setFieldValue(name, null);
                setUploadedFileSrc(undefined);
              }}
            >
              <i className="pi pi-trash" />
            </RemoveAvatar>
          </>
        )}
      </Field>
      <ImageWrapper>
        {uploadedFileSrc ? (
          <Image src={uploadedFileSrc || undefined} />
        ) : (
          <MissingImageWrapper>
            <p>Click here to upload</p>
            <p>.jpg or .png max 2MB</p>
          </MissingImageWrapper>
        )}
        <CameraIcon />
      </ImageWrapper>
    </FieldLabel>
  );
};
