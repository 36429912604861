import { ResourcesReportsPayload, AvailableResources } from 'api/users/models';
import { getYear } from 'date-fns';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

const API_URL = '/users/resources_reports';
const currentDate = new Date();
const currentYear = getYear(currentDate);

const getResources = (
  data:
    | ResourcesReportsPayload['conflicts']
    | ResourcesReportsPayload['free_resources'],
) => {
  const currentYearResources = data[currentYear];
  const nextYearResources = data[currentYear + 1];
  if (!currentYearResources) {
    return Object.entries(nextYearResources);
  }
  if (nextYearResources) {
    const next = Object.entries(nextYearResources);
    const current = Object.entries(currentYearResources);

    const occurences: string[] = [];
    const all = [current, next].flatMap((arr) => arr);

    // TODO: Remove this uniqueAll.
    // I created this filter because backend returned wrong data for nextYear (there were weeks that belonged to currentYear).
    // https://start-up-house.slack.com/archives/CSV9ZE0BF/p1638951787006600?thread_ts=1638890153.000300&cid=CSV9ZE0BF
    const uniqueAll = all.filter((val) => {
      if (!occurences.find((occurencesVal) => val[0] === occurencesVal)) {
        occurences.push(val[0]);
        return true;
      }
      return false;
    });
    return uniqueAll;
  }
  return Object.entries(currentYearResources);
};

const getAvailableResources = (data: ResourcesReportsPayload | undefined) => {
  if (data) {
    const availableResources: AvailableResources[] = Array.from(Array(6)).map(
      () => ({
        weekNumber: undefined,
        conflicts: [],
        freeResources: [],
      }),
    );
    const conflicts = getResources(data?.conflicts);
    const freeResources = getResources(data?.free_resources);
    conflicts?.forEach((month, index) => {
      availableResources[index].weekNumber = parseInt(month[0]);
      availableResources[index].conflicts = month[1];
    });

    freeResources?.forEach((month, index) => {
      availableResources[index].freeResources = month[1];
    });
    return availableResources;
  }
  return undefined;
};

export const useResourcesReports = (): {
  isLoading: boolean;
  availableResources?: AvailableResources[];
  neededResources?: ResourcesReportsPayload['needed_resources'];
} => {
  const { data } = useSWR<ResourcesReportsPayload>(API_URL, fetcher);
  const availableResources = getAvailableResources(data);

  return {
    isLoading: !data,
    availableResources: availableResources,
    neededResources: data?.needed_resources,
  };
};
