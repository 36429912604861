import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/ui';

import { NotFoundBox, NotFoundTitle, NotFoundDescription } from './styled';

const NotFound: React.FC = () => {
  const history = useHistory();

  const handleMoveToMainPage = () => {
    history.push('/');
  };

  return (
    <NotFoundBox>
      <NotFoundTitle>404, Page not found</NotFoundTitle>
      <NotFoundDescription>
        Unfortunatelly page that you are looking for is missing...
      </NotFoundDescription>
      <Button data-cy="main-page-redirect-btn" onClick={handleMoveToMainPage}>
        Go to the main page
      </Button>
    </NotFoundBox>
  );
};

export default NotFound;
