import React, { useEffect, useState } from 'react';

import {
  FieldIcon,
  Label,
  StyledAutocomplete,
  Wrapper,
} from '../Select/styled';
import { FieldSubLabel } from 'components/primitive';
import { AutoCompleteProps } from 'primereact/autocomplete';
import { sortByKey } from 'utils/sortByKey';

export interface AutocompleteCustomProps {
  iserror?: string;
  variant?: 'default' | 'filled' | 'blue';
  isIcon?: HTMLElement;
  isLabelVisible?: boolean;
  icon?: HTMLElement;
  label?: string;
  subLabel?: string;
  isHigh?: boolean;
  options: any[];
  searchKey: string;
  valueKey?: string;
  clear?: boolean;
  onCustomClear?: () => void;
}

export const PrimeAutocomplete = ({
  iserror,
  icon,
  label,
  isLabelVisible = !!label,
  subLabel,
  options,
  searchKey,
  clear,
  onCustomClear,
  ...restProps
}: AutoCompleteProps & AutocompleteCustomProps) => {
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    setItems(options);
  }, [options]);

  const search = (event: any) => {
    setTimeout(() => {
      let _filtered;
      if (!event.query.trim?.().length) {
        _filtered = [...options];
      } else {
        _filtered = options.filter((item) => {
          return item[searchKey]
            ?.toLowerCase()
            .includes(event.query.toLowerCase());
        });
      }

      setItems(_filtered.sort(sortByKey(searchKey)));
    }, 250);
  };

  return (
    <Wrapper>
      {icon ? <FieldIcon iserror={iserror}>{icon}</FieldIcon> : null}
      {isLabelVisible ? (
        <Label isIcon={icon} iserror={iserror}>
          {label}
        </Label>
      ) : null}
      {subLabel ? <FieldSubLabel>{subLabel}</FieldSubLabel> : null}
      <StyledAutocomplete
        {...restProps}
        suggestions={items}
        field={searchKey}
        dropdown
        completeMethod={search}
        forceSelection
        autoHighlight
        scrollHeight="400px"
        className={iserror ? 'p-invalid' : ''}
      />
      {clear && <i className="pi pi-times clear" onClick={onCustomClear} />}
    </Wrapper>
  );
};
