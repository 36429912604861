import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ShownMember, UnhideMemberModal } from '../../UnhideMemberModal';
import { PhaseMemberPayload } from 'api/phases/models';
import type { ProjectPhaseMember } from 'api/projects/models';
import { User } from 'api/users/models';
import { FormikDatePicker } from 'components/forms/formik-prime';
import { FormikAutocomplete } from 'components/forms/formik-prime/Autocomplete/Autocomplete';
import { FormikRadioButtons } from 'components/forms/formik-prime/RadioButton/RadioButton';
import { Dialog } from 'components/prime/Dialog';
import { FieldLabel } from 'components/primitive';
import { Avatar, Button, HoursProgressBar } from 'components/ui';
import { AddUserHours } from 'components/ui/AddUserHours/AddUserHours';
import format from 'date-fns/format';
import { useWeeks } from 'fetchers';
import { addUserToPhase } from 'fetchers/actions/addUserToPhase';
import { useUsers } from 'fetchers/hooks/users/useUsers';
import { Form, Formik } from 'formik';
import { Box, Flex } from 'rebass';
import { selectSelectedWeeks } from 'store/slices/selectedWeeksSlice';

import { validationSchema } from './validationSchema';

type Project = {
  avatar?: string;
  id: number;
  name: string;
};

type Props = {
  onClose: () => void;
  project: Partial<Project>;
  phaseId: number;
  members: ProjectPhaseMember[];
};

export type FormValues = {
  user: string | number | null;
  start_date: null | Date;
  end_date: null | Date;
  confirmed: boolean;
  hours: number;
};

export const AddNewMemberModal: React.FC<Props> = ({
  onClose,
  project,
  members,
  phaseId,
}) => {
  const { t } = useTranslation();
  const { users } = useUsers();
  const [enableValidation, setEnableValidation] = useState<boolean>(false);
  const [isUnhideModalOpen, setUnhideModalOpen] = useState<boolean>(false);
  const [shouldReset, setShouldReset] = useState<any>();
  const selectedUser = useRef<ShownMember | undefined>();
  const dispatch = useDispatch();

  const selectedWeeks = useSelector(selectSelectedWeeks);
  const { usersWeeks } = useWeeks();
  const getUserWeek = (userId: number) => {
    return usersWeeks
      .find((week) => week.week === selectedWeeks[0].weekNumber)
      ?.users.find((user) => user.id === userId);
  };

  const getDefaultValues = useMemo(
    () => ({
      user: null,
      start_date: null,
      end_date: null,
      confirmed: true,
      hours: 0,
    }),
    [],
  );

  const isUserExcludedFromList = useCallback(
    (user) =>
      members.some(
        (member) => member.id === user.id && !member.phase_user_range.hidden_at,
      ),
    [members],
  );

  const filteredUsers =
    users?.filter(
      (user) => !isUserExcludedFromList(user) && user.available_resource,
    ) || [];

  const handleSubmit = (values: FormValues) => {
    const formValues: PhaseMemberPayload = {
      start_date: values.start_date && format(values.start_date, 'yyyy-MM-dd'),
      end_date: values.end_date && format(values.end_date, 'yyyy-MM-dd'),
      user_id: Number(values.user),
      phase_id: phaseId,
      confirmed: values.confirmed,
      year:
        values.start_date?.getFullYear() ||
        values.end_date?.getFullYear() ||
        new Date().getFullYear(),
    };

    if (values.hours > 0) {
      formValues.hours = values.hours;
    } else {
      delete formValues.hours;
    }

    addUserToPhase(formValues, dispatch, project.id);
    onClose();
  };

  const handleUserChange = (user: User) => {
    if (!user) {
      return;
    }
    const member = members.find((member) => member.id === user.id);
    const isHidden = !!member?.phase_user_range.hidden_at;

    if (member && isHidden) {
      selectedUser.current = {
        name: user.full_name,
        phaseId,
        phaseUserRangeId: member.phase_user_range.id,
        projectId: project.id,
        avatar: user.photo.url || '',
      };
      setUnhideModalOpen(true);
    }
  };

  const renderUserHoursBar = (user: User) => {
    const userWeek = getUserWeek(user.id);
    return (
      <HoursProgressBar
        fontSize="xxs"
        verifiedHours={userWeek?.verified_user_hours || 0}
        maxHours={user.hours_weekly || 0}
        hoursOff={userWeek?.hours_off}
      />
    );
  };

  const userOptionTemplate = (user: User) => {
    return (
      <Flex alignItems="center">
        <Flex flex={4} alignItems="center">
          <Avatar
            src={user.photo?.mini?.url || ''}
            name={user.full_name}
            size="sm"
          />
          <Box fontSize="xs" ml={2}>
            {user.full_name}
          </Box>
        </Flex>
        <Box fontSize="xxs" flex={4}>
          {user.position || t('users.noPosition')}
        </Box>
        <Box fontSize="xxs" flex={2}>
          {renderUserHoursBar(user)}
        </Box>
      </Flex>
    );
  };

  return (
    <>
      <Box sx={{ position: 'relative', height: '100%' }} my={5}>
        <Formik
          initialValues={getDefaultValues}
          onSubmit={handleSubmit}
          validateOnChange={enableValidation}
          validateOnBlur={enableValidation}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, values }) => (
            <Form
              onSubmit={(event) => {
                setEnableValidation(true);
                event.preventDefault();
                handleSubmit();
              }}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormikAutocomplete
                isHigh={false}
                options={filteredUsers}
                searchKey="full_name"
                valueKey="id"
                reset={shouldReset}
                name="user"
                placeholder={t('users.pickUserPlaceholder')}
                label={`${t('users.teamMember')}*`}
                itemTemplate={userOptionTemplate}
                onChange={(e) => handleUserChange(e.value)}
              />
              {values.user && (
                <Flex flexDirection="column">
                  <FieldLabel>
                    <span>{t('users.isConfirmed')}</span>
                  </FieldLabel>
                  <Flex>
                    <FormikRadioButtons
                      values={[
                        { label: t('users.confirmed'), value: true },
                        { label: t('users.proposition'), value: false },
                      ]}
                      name="confirmed"
                    />
                  </Flex>
                </Flex>
              )}
              <Flex width="100%">
                <Box mr={2} width="408px">
                  <FormikDatePicker
                    data-cy="phase-date-picker"
                    name="start_date"
                    label={`${t('users.onPhaseFrom')}*`}
                    variant="default"
                    maxDate={values.end_date || undefined}
                    showButtonBar
                  />
                </Box>
                <Box width="408px">
                  <FormikDatePicker
                    data-cy="phase-date-picker"
                    name="end_date"
                    label={t('common.until')}
                    variant="default"
                    minDate={values.start_date || undefined}
                    showButtonBar
                  />
                </Box>
              </Flex>
              <Flex>
                <AddUserHours />
              </Flex>
              <Flex
                justifyContent="space-between"
                marginTop="auto"
                width="100%"
              >
                <Button
                  variant="ghost"
                  data-cy="close-modal-btn"
                  onClick={onClose}
                >
                  {t('common.cancel')}
                </Button>
                <Button data-cy="add-member-btn" type="submit">
                  {t('users.addMemberToPhase')}
                </Button>
              </Flex>
              <Dialog
                visible={isUnhideModalOpen}
                header={t('users.unhideMember')}
                onHide={() => {
                  setUnhideModalOpen(false);
                  onClose();
                }}
                noPadding
              >
                {selectedUser.current && (
                  <UnhideMemberModal
                    onClose={() => {
                      setUnhideModalOpen(false);
                      setShouldReset(true);
                      onClose();
                    }}
                    onConfirm={() => {
                      setUnhideModalOpen(false);
                      onClose();
                    }}
                    user={selectedUser.current}
                  />
                )}
              </Dialog>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
