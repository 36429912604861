import { mutate } from 'swr';
import { getCacheKey } from 'utils/getCacheKey';

export const mutateOrganizationSettings = () => {
  const searchedKey = getCacheKey('/companySettings');

  if (searchedKey) {
    mutate(searchedKey);
  }
};
