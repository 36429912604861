import { keyframes } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Box } from 'rebass';

const loading = keyframes`
    0% {
        opacity: 0%;
    }

    50% {
        opacity: 100%;
    }

    100% {
        opacity: 0%;
    }
`;

export const Skeleton = styled(Box)`
  animation: ${loading} 1s linear infinite;
`;
