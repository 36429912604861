import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTable, useSortBy, useFlexLayout, Cell } from 'react-table';

import { PhasesTableHeader } from '../PhasesTableHeader/PhasesTableHeader';
import { UsersTable } from '../UsersTable';
import {
  ProjectPhaseResponsibleUserData,
  ProjectPhaseMember,
} from 'api/projects/models';
import { Variant } from 'components/layout/Tables/SimpleUsersTable';
import {
  sortAlphabetically,
  DEFAULT_VALUE,
} from 'components/layout/Tables/utils/sortAlphabetically';
import {
  TableHeader,
  TableItem,
  TableRow,
  TableMainCell,
  ProjectName,
  Statuses,
  TableToggleButton,
  Tooltip,
} from 'components/ui/index';
import { ProjectWithPhase } from 'fetchers';
import { useProjectsWeeks } from 'fetchers/hooks/useProjectsWeeks';
import { Flex, Text } from 'rebass';
import { PersonResponsible } from 'screens/Projects/components/PersonResponsible';
import {
  selectProjectsExpandedRow,
  ROW_PREFIXES,
  setExpandedRow,
  selectIsAllRowsExpanded,
} from 'store/slices/projectsSlice';
import {
  selectSelectedWeeks,
  formatDate,
} from 'store/slices/selectedWeeksSlice';
import { theme } from 'styles/theme';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

import { TableWrapper, UlWrapper, TableScroller } from './styled';

export enum ACCESSORS {
  PERSON_RESPONSIBLE = 'person_responsible',
  PHASE_ID = 'phase_id',
  PHASE_NAME = 'phase_name',
  PROJECT_AVATAR = 'project_avatar',
  PROJECT_ID = 'project_id',
  PROJECT_NAME = 'project',
  STATUS = 'status',
  TYPE = 'type',
  USERS = 'users',
  WEEK = 'week',
}

type Props = {
  data: ProjectWithPhase[];
  expandAllPhases?: boolean;
  type?: string;
};

type CellData = {
  [ACCESSORS.PHASE_ID]: number;
  [ACCESSORS.PHASE_NAME]: string;
  [ACCESSORS.PROJECT_ID]: number;
  [ACCESSORS.PROJECT_AVATAR]: string;
  [ACCESSORS.PROJECT_NAME]: string;
  [ACCESSORS.PERSON_RESPONSIBLE]: ProjectPhaseResponsibleUserData[];
  [ACCESSORS.STATUS]: Statuses;
  [ACCESSORS.USERS]: ProjectPhaseMember[];
};

type CellProps = Cell<CellData>;

export const PhasesTable: React.FC<Props> = ({
  data,
  expandAllPhases,
  type,
}) => {
  const dispatch = useDispatch();
  const selectedWeeks = useSelector(selectSelectedWeeks);
  const expandedRows = useSelector(selectProjectsExpandedRow);
  const isAllRowsExpanded = useSelector(selectIsAllRowsExpanded);
  const { projectId: isSingleProjectPage } = useParams<{ projectId: string }>();
  const { isLoading, weeks } = useProjectsWeeks();
  const { t } = useTranslation();

  const canHideUser = usePermissions(PERMISSIONS.HIDE_USER);
  const canShowUser = usePermissions(PERMISSIONS.SHOW_USER);
  const canHideAndShowUser = canHideUser && canShowUser;

  useEffect(() => {
    if (expandAllPhases !== undefined) {
      data
        .map(({ phase }) => phase.id)
        .forEach((id) => {
          dispatch(
            setExpandedRow(`${ROW_PREFIXES.PHASES}_${id}`, expandAllPhases),
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAllPhases]);

  const tableColumns = useMemo(
    () => [
      {
        accessor: ACCESSORS.PROJECT_NAME,
        Cell: ({ row: { original: row } }: CellProps) => {
          const phaseName = row[ACCESSORS.PHASE_NAME];
          const projectId = row[ACCESSORS.PROJECT_ID];
          const projectName = row[ACCESSORS.PROJECT_NAME];
          const projectAvatar = row[ACCESSORS.PROJECT_AVATAR];

          return (
            <ProjectName
              avatar={projectAvatar}
              id={projectId}
              phaseName={phaseName}
              projectName={projectName}
              isLinkDisabled={!!isSingleProjectPage}
            />
          );
        },
        Header: 'Project',
        id: ACCESSORS.PROJECT_NAME,
        minWidth: 300,
      },
      ...(selectedWeeks.length === 1
        ? [
            {
              accessor: (d: CellData) =>
                d.person_responsible.length
                  ? d.person_responsible[0].user.name ||
                    d.person_responsible[0].user.full_name
                  : DEFAULT_VALUE,
              Cell: ({ row: { original: row } }: CellProps) => {
                const personResponsible = row[ACCESSORS.PERSON_RESPONSIBLE];
                return (
                  <PersonResponsible personResponsible={personResponsible} />
                );
              },
              Header: 'Person responsible',
              id: ACCESSORS.PERSON_RESPONSIBLE,
              disableSortBy: true,
              minWidth: 200,
            },
            {
              accessor: ACCESSORS.TYPE,
              // @ts-ignore
              disableSortBy: true,
              Header: 'Type',
              id: ACCESSORS.TYPE,
              minWidth: 150,
              Cell: ({ row: { original: row } }: CellProps) => {
                // @ts-ignore
                const type = row[ACCESSORS.TYPE];
                return type.name || null;
              },
            },
            {
              accessor: ACCESSORS.STATUS,
              Cell: () => null,
              Header: '',
              disableSortBy: true,
              id: ACCESSORS.STATUS,
              minWidth: 120,
            },
          ]
        : canHideAndShowUser
        ? [
            {
              Header: '',
              id: 'hide_user',
              width: 50,
            },
          ]
        : []),
      ...selectedWeeks.map(({ end, start, weekNumber }) => {
        const accessor = `${ACCESSORS.WEEK}_${weekNumber}`;
        const datePattern = 'dd.MM';

        return {
          accessor,
          // @ts-ignore
          disableSortBy: true,
          Header: () => (
            <Flex alignItems="center">
              <Text>
                Week {weekNumber} - {formatDate(new Date(start), datePattern)} -{' '}
                {formatDate(new Date(end), datePattern)}
              </Text>
              <Tooltip
                render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    marginLeft="6px"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    ref={triggerRef}
                    sx={{
                      cursor: 'default',
                    }}
                  >
                    <AiOutlineQuestionCircle />
                  </Flex>
                )}
              >
                {t('projects.plannedLoggedTooltip')}
              </Tooltip>
            </Flex>
          ),
          id: accessor,
          minWidth: 300,
        };
      }),
      ...(selectedWeeks.length === 1 && canHideAndShowUser
        ? [
            {
              Header: '',
              id: 'hide_user',
              minWidth: 70,
              width: 70,
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedWeeks, isSingleProjectPage],
  );

  const tableData = useMemo(
    () => [
      ...data.map(({ phase, project }) => ({
        [ACCESSORS.PROJECT_ID]: project.id,
        [ACCESSORS.PHASE_ID]: phase.id,
        [ACCESSORS.PROJECT_AVATAR]: project.avatar,
        [ACCESSORS.PROJECT_NAME]: project.name,
        [ACCESSORS.PHASE_NAME]: phase.name,
        [ACCESSORS.PERSON_RESPONSIBLE]: phase.phase_responsible_users,
        [ACCESSORS.TYPE]: project.project_type,
        [ACCESSORS.STATUS]: phase.status,
        [ACCESSORS.USERS]: phase.users,
        ...selectedWeeks.reduce((accumulator, { weekNumber }) => {
          if (isLoading) {
            return {
              ...accumulator,
              [`${ACCESSORS.WEEK}_${weekNumber}`]: 'Loading...',
            };
          }

          const phaseData = weeks?.[weekNumber]?.[project.id]?.[phase.id];

          return {
            ...accumulator,
            [`${ACCESSORS.WEEK}_${weekNumber}`]: `${
              phaseData?.verifiedUserHours || 0
            } / ${phaseData?.notVerifiedUserHours || 0}`,
          };
        }, {}),
      })),
    ],
    [data, isLoading, selectedWeeks, weeks],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      // @ts-ignore
      columns: tableColumns,
      // @ts-ignore
      data: tableData,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        // @ts-ignore
        sortBy: [
          {
            desc: false,
            id: ACCESSORS.PROJECT_NAME,
          },
        ],
      },
      sortTypes: {
        alphanumeric: sortAlphabetically(),
      },
    },
    useSortBy,
    useFlexLayout,
  );
  const tableProps = {
    ...getTableProps(),
    style: {
      ...getTableProps().style,
      ...(selectedWeeks.length <= 1 && { minWidth: '100%' }),
    },
  };

  const handleRowClick = useCallback(
    (rowId: string, value?: boolean) => {
      dispatch(setExpandedRow(rowId, value));
    },
    [dispatch],
  );

  return (
    <TableWrapper flexDirection="column">
      <PhasesTableHeader type={type} />
      <section
        {...tableProps}
        style={{
          ...tableProps.style,
          minWidth: '100%',
          borderTop: `1px solid ${theme.colors.neutral}`,
        }}
      >
        <TableScroller>
          <TableHeader headerGroups={headerGroups} variant={Variant.PHASES} />
          <UlWrapper {...getTableBodyProps()}>
            {rows.map((row, index) => {
              const expanderId = `${ROW_PREFIXES.PHASES}_${
                row.original[ACCESSORS.PHASE_ID]
              }`;
              const isExpanded =
                expandedRows[expanderId] !== undefined
                  ? !!expandedRows[expanderId]
                  : isAllRowsExpanded;
              const users = row.original[ACCESSORS.USERS];

              prepareRow(row);

              return (
                <TableItem
                  {...row.getRowProps()}
                  as="li"
                  isExpanded={isExpanded}
                  values={row.values}
                  even={index % 2 === 0}
                >
                  <TableRow
                    isExpanded={isExpanded}
                    onClick={() =>
                      handleRowClick(
                        expanderId,
                        isAllRowsExpanded &&
                          expandedRows[expanderId] === undefined
                          ? !isAllRowsExpanded
                          : undefined,
                      )
                    }
                    tabIndex={users.length && 0}
                  >
                    {row.cells.map(
                      ({ column: { id }, getCellProps, render }) => (
                        <TableMainCell {...getCellProps()}>
                          {render('Cell')}
                        </TableMainCell>
                      ),
                    )}
                    <TableToggleButton
                      id={expanderId}
                      isExpanded={isExpanded}
                    />
                  </TableRow>
                  {isExpanded && (
                    <UsersTable
                      data={users}
                      projectAvatar={row.original[ACCESSORS.PROJECT_AVATAR]}
                      projectId={row.original[ACCESSORS.PROJECT_ID]}
                      projectName={row.original[ACCESSORS.PROJECT_NAME]}
                      phaseId={row.original[ACCESSORS.PHASE_ID]}
                      phaseName={row.original[ACCESSORS.PHASE_NAME]}
                    />
                  )}
                </TableItem>
              );
            })}
          </UlWrapper>
        </TableScroller>
      </section>
    </TableWrapper>
  );
};
