import { Link } from 'react-router-dom';

import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const UserBoxLink = styled(Link)`
  background-color: transparent;
  border-radius: 18px;
  width: max-content;
  display: flex;
  align-items: center;
  padding: 0.35rem 0.75rem;
  position: relative;
  outline: none;
  text-align: left;
  margin-left: ${theme.space[-3]};
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${theme.colors.lightBlue};
  }
`;
