import { mutateIntegrationStatus } from '../mutations/mutateIntegrationStatus';
import { mutateOrganizationSettings } from '../mutations/mutateOrganizationSettings';
import { organizationSettingsAPI } from 'api/organizationSettings/endpoints';
import { AtlassianIntegrationPayload } from 'api/organizationSettings/models';

export const addAtlassianIntegration = async (
  formData: AtlassianIntegrationPayload,
) => {
  await organizationSettingsAPI.addAtlassianIntegration(formData);
  await mutateOrganizationSettings();
  await mutateIntegrationStatus();
};
