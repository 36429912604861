import React, { FC } from 'react';

export const CameraIcon: FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" fill="white" />
    <path
      d="M14.1903 10.1667L12.5237 11.8333H9.33366V21.8333H22.667V11.8333H19.477L17.8103 10.1667H14.1903ZM13.5003 8.5H18.5003L20.167 10.1667H23.5003C23.7213 10.1667 23.9333 10.2545 24.0896 10.4107C24.2459 10.567 24.3337 10.779 24.3337 11V22.6667C24.3337 22.8877 24.2459 23.0996 24.0896 23.2559C23.9333 23.4122 23.7213 23.5 23.5003 23.5H8.50033C8.27931 23.5 8.06735 23.4122 7.91107 23.2559C7.75479 23.0996 7.66699 22.8877 7.66699 22.6667V11C7.66699 10.779 7.75479 10.567 7.91107 10.4107C8.06735 10.2545 8.27931 10.1667 8.50033 10.1667H11.8337L13.5003 8.5ZM16.0003 21C14.7848 21 13.619 20.5171 12.7594 19.6576C11.8999 18.798 11.417 17.6322 11.417 16.4167C11.417 15.2011 11.8999 14.0353 12.7594 13.1758C13.619 12.3162 14.7848 11.8333 16.0003 11.8333C17.2159 11.8333 18.3817 12.3162 19.2412 13.1758C20.1008 14.0353 20.5837 15.2011 20.5837 16.4167C20.5837 17.6322 20.1008 18.798 19.2412 19.6576C18.3817 20.5171 17.2159 21 16.0003 21ZM16.0003 19.3333C16.7739 19.3333 17.5157 19.026 18.0627 18.4791C18.6097 17.9321 18.917 17.1902 18.917 16.4167C18.917 15.6431 18.6097 14.9013 18.0627 14.3543C17.5157 13.8073 16.7739 13.5 16.0003 13.5C15.2268 13.5 14.4849 13.8073 13.9379 14.3543C13.391 14.9013 13.0837 15.6431 13.0837 16.4167C13.0837 17.1902 13.391 17.9321 13.9379 18.4791C14.4849 19.026 15.2268 19.3333 16.0003 19.3333Z"
      fill="#454F5F"
    />
    <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" stroke="#BCC0C5" />
  </svg>
);
