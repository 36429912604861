import React from 'react';

import { Spinner } from './styled';

type Props = {
  size?: string;
  variant?: 'white' | 'default';
};

export const Spin: React.FC<Props> = ({ size = '24', variant = 'default' }) => (
  <Spinner size={size} variant={variant} />
);
