import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  lastCellShorter?: boolean;
};

export const TableSubCelllWrapper = styled(Flex)<Props>`
  font-size: ${theme.fontSizes.xxs};
  height: 60px;
  padding-left: 20px;
  align-items: center;

  &:last-child {
    width: ${({ lastCellShorter }) =>
      lastCellShorter ? '80px !important' : 'initial'};
    flex: ${({ lastCellShorter }) =>
      lastCellShorter ? '0 !important' : 'initial'};
  }
  &:last-of-type {
    width: ${({ lastCellShorter }) =>
      lastCellShorter ? '80px !important' : 'initial'};
    flex: ${({ lastCellShorter }) =>
      lastCellShorter ? '0 !important' : 'initial'};
  }
`;
