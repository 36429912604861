import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import {
  PhaseAttribute,
  ProjectPhaseResponsibleUser,
  ProjectPhaseResponsibleUserData,
} from 'api/projects/models';
import {
  FormikDatePicker,
  FormikMultiSelect,
  FormikNumberInput,
  FormikSelect,
  FormikTextInput,
} from 'components/forms/formik-prime';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { Card } from 'components/primitive';
import { Button } from 'components/ui';
import { removeProjectPhase } from 'fetchers/actions/projectPhases/removeProjectPhase';
import { usePhaseTypes } from 'fetchers/hooks/usePhaseTypes';
import { useUsers } from 'fetchers/hooks/users/useUsers';
import { useFormikContext } from 'formik';
import { Box, Flex } from 'rebass';
import {
  DatesContainer,
  HoursContainer,
} from 'screens/Project/components/ProjectPhasesForm/PhaseFieldsItem/styled';
import {
  PhasesFormProps,
  PHASE_FORM_TYPES,
} from 'screens/Project/screens/ProjectPhasesEdit/ProjectPhasesEdit';
import { sortByKey } from 'utils/sortByKey';

export interface PhaseFieldsForm {
  be_hours: string;
  date: Date;
  design_hours: string;
  end_date: Date;
  fe_hours: string;
  hours_monthly: number;
  name: string;
  phase_responsible_users_attributes: ProjectPhaseResponsibleUser[];
  pm_hours: string;
  qa_hours: string;
  settlement: string;
  start_date: Date;
  status: string;
  user_id: string;
}

type Props = {
  isReadOnly: boolean;
  initialResponsibleUsers: ProjectPhaseResponsibleUserData[];
  type: PhasesFormProps['type'];
  actionButtonCopy: string;
  onClick: () => void;
  isDisabled: boolean;
};

enum FIELDS {
  BE_HOURS = 'be_hours',
  DATE = 'date',
  DESIGN_HOURS = 'design_hours',
  END_DATE = 'end_date',
  FE_HOURS = 'fe_hours',
  HOURS_MONTHLY = 'hours_monthly',
  NAME = 'name',
  PHASE_TYPE = 'phase_type_id',
  PM_HOURS = 'pm_hours',
  QA_HOURS = 'qa_hours',
  RESPONSIBLE_USERS = 'phase_responsible_users_attributes',
  SETTLEMENT = 'settlement',
  START_DATE = 'start_date',
  STATUS = 'status',
}

const PhaseFieldsItem: React.FC<Props> = ({
  isReadOnly,
  initialResponsibleUsers,
  type,
  actionButtonCopy,
  onClick,
  isDisabled,
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const { phaseTypes } = usePhaseTypes() || [];
  const { users } = useUsers();
  const { projectId } = useParams<{ projectId: string }>();
  const { values, setFieldValue } = useFormikContext<PhaseAttribute>();

  const currentPhaseTypeId = values.phase_type_id;
  const currentPhaseId = values.id;

  const sortedUsers = useMemo(
    () =>
      users
        ?.filter(({ full_name }) => full_name?.trim?.())
        .map(({ id, full_name: label }) => {
          const itemId = initialResponsibleUsers?.find(
            (user) => user.user.id === id,
          )?.id;
          return {
            label,
            value: { user_id: id, id: itemId, _destroy: false },
          };
        })
        .sort(sortByKey('full_name')) || [],
    [users, initialResponsibleUsers],
  );
  const { t } = useTranslation();

  const phaseTypeItems = useMemo(() => {
    if (phaseTypes?.length) {
      return (
        phaseTypes
          .map(({ name, id }) => ({
            label: name,
            value: id,
          }))
          .sort(sortByKey('label')) || []
      );
    }
    return [];
  }, [phaseTypes]);

  const availableFields = useMemo(
    () =>
      phaseTypes.find((p) => p.id === currentPhaseTypeId)?.available_fields ||
      [],
    [currentPhaseTypeId, phaseTypes],
  );

  const isFieldAvailable = (fieldName: FIELDS) =>
    availableFields.includes(fieldName);

  const fieldsPermissions = useMemo(
    () => ({
      beHours: isFieldAvailable(FIELDS.BE_HOURS),
      date: isFieldAvailable(FIELDS.DATE),
      designHours: isFieldAvailable(FIELDS.DESIGN_HOURS),
      endDate: isFieldAvailable(FIELDS.END_DATE),
      feHours: isFieldAvailable(FIELDS.FE_HOURS),
      hoursMonthly: isFieldAvailable(FIELDS.HOURS_MONTHLY),
      pmHours: isFieldAvailable(FIELDS.PM_HOURS),
      qaHours: isFieldAvailable(FIELDS.QA_HOURS),
      responsibleUsers: isFieldAvailable(FIELDS.RESPONSIBLE_USERS),
      settlement: isFieldAvailable(FIELDS.SETTLEMENT),
      startDate: isFieldAvailable(FIELDS.START_DATE),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableFields],
  );

  const handleRemovePhase = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (currentPhaseId) {
      removeProjectPhase(projectId, currentPhaseId);
      setIsHidden(true);
    }

    return;
  };

  const resetValues = () => {
    setFieldValue(`settlement`, undefined);
    setFieldValue(`phase_responsible_users_attributes`, []);
    setFieldValue(`date`, undefined);
    setFieldValue(`start_and_end_date`, undefined);
    setFieldValue(`start_date`, undefined);
    setFieldValue(`end_date`, undefined);
    setFieldValue(`pm_hours`, 0);
    setFieldValue(`be_hours`, 0);
    setFieldValue(`fe_hours`, 0);
    setFieldValue(`design_hours`, 0);
    setFieldValue(`qa_hours`, 0);
    setFieldValue(`hours_monthly`, 0);
  };

  const handleDatesValidation = (value: Array<Date | null>) => {
    if (!value) {
      return t('errors.required');
    }
    if (value.includes(null)) {
      return t('errors.endDateRequired');
    }
    return undefined;
  };

  const handleResponsibleUsersValidation = (values: number[]) =>
    values?.length >= 1 ? undefined : t('errors.required');

  const handleRequiredValueValidation = (value: any) =>
    value ? undefined : t('errors.required');

  const handleRequiredNumberValueValidation = (value: number) =>
    value || value === 0 ? undefined : t('errors.required');

  const isHoursContainerVisible =
    fieldsPermissions.pmHours ||
    fieldsPermissions.beHours ||
    fieldsPermissions.feHours ||
    fieldsPermissions.designHours ||
    fieldsPermissions.qaHours ||
    fieldsPermissions.hoursMonthly;

  return (
    <Card isHidden={isHidden} marginBottom="10px">
      <Flex pb={7} justifyContent="space-between" alignItems="center">
        <Box as="p" fontSize="md" color="darkGray" sx={{ fontFamily: 'bold' }}>
          Phase
        </Box>
        {!isReadOnly && (
          <PrimeTextButton
            onClick={handleRemovePhase}
            icon="pi pi-trash"
            variant="danger"
            label="Remove phase"
            disabled={!currentPhaseId && isDisabled}
          />
        )}
      </Flex>
      <FormikTextInput
        data-cy="phase-title-input"
        disabled={isReadOnly}
        label="Phase title*"
        name={`name`}
        initialValue={values.name}
      />
      <FormikSelect
        data-cy="phase-type-select"
        disabled={isReadOnly}
        name={`phase_type_id`}
        options={phaseTypeItems}
        label="Phase type*"
        placeholder="Choose from list"
        initialValue={currentPhaseTypeId}
        onChange={resetValues}
      />
      <FormikSelect
        data-cy="project-status-select"
        disabled={isReadOnly}
        name={`status`}
        options={[
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ]}
        label="Status*"
        placeholder="Choose from list"
        initialValue={values.status}
      />
      {fieldsPermissions.settlement && (
        <FormikSelect
          data-cy="project-settlement-select"
          disabled={isReadOnly}
          name={`settlement`}
          options={[
            { label: 'T&M', value: 'T&M' },
            { label: 'Fix', value: 'Fix' },
            { label: 'Mixed', value: 'Mixed' },
          ]}
          label="Settlement*"
          placeholder="Choose from list"
          initialValue={values.settlement}
          validate={handleRequiredValueValidation}
        />
      )}
      {fieldsPermissions.responsibleUsers && sortedUsers.length > 0 && (
        <FormikMultiSelect
          data-cy="project-responsible-users-select"
          disabled={isReadOnly}
          name={`phase_responsible_users_attributes`}
          options={sortedUsers}
          label="Responsible users*"
          placeholder="Choose from list"
          validate={handleResponsibleUsersValidation}
          isLabelVisible
          display="chip"
          filter
        />
      )}
      {(fieldsPermissions.startDate ||
        fieldsPermissions.endDate ||
        fieldsPermissions.startDate ||
        fieldsPermissions.date) && (
        <DatesContainer>
          {fieldsPermissions.date && (
            <Box sx={{ position: 'relative' }} width="calc(50% - 12px)">
              <FormikDatePicker
                isHigh={true}
                data-cy="phase-date-picker"
                name={`date`}
                disabled={isReadOnly}
                label="Date*"
                validate={handleRequiredValueValidation}
              />
            </Box>
          )}
          {fieldsPermissions.startDate && fieldsPermissions.endDate && (
            <Box sx={{ position: 'relative' }} width="100%">
              <FormikDatePicker
                isHigh={true}
                selectionMode="range"
                data-cy="phase-start-date-picker"
                name={`start_and_end_date`}
                disabled={isReadOnly}
                label="Start and End date*"
                validate={handleDatesValidation}
                onChange={(e) => {
                  setFieldValue(
                    `start_date`,
                    Array.isArray(e.target.value)
                      ? e.target.value[0]
                      : e.target.value,
                  );
                  setFieldValue(
                    `end_date`,
                    Array.isArray(e.target.value)
                      ? e.target.value[1]
                      : e.target.value,
                  );
                }}
              />
            </Box>
          )}
        </DatesContainer>
      )}
      <HoursContainer isBottomPadding={isHoursContainerVisible}>
        {fieldsPermissions.pmHours && (
          <Box width="calc(33.3% - 24px)" marginRight="24px">
            <FormikNumberInput
              append={'hours'}
              data-cy="pm-hours-input"
              label="PM hours"
              name={`pm_hours`}
            />
          </Box>
        )}
        {fieldsPermissions.beHours && (
          <Box width="calc(33.3% - 24px)" marginRight="24px">
            <FormikNumberInput
              append={'hours'}
              data-cy="be-hours-input"
              label="BE hours"
              name={`be_hours`}
            />
          </Box>
        )}
        {fieldsPermissions.feHours && (
          <Box width="calc(33.3% - 24px)" marginRight="24px">
            <FormikNumberInput
              append={'hours'}
              data-cy="fe-hours-input"
              label="FE hours"
              name={`fe_hours`}
            />
          </Box>
        )}
        {fieldsPermissions.designHours && (
          <Box width="calc(33.3% - 24px)" marginRight="24px">
            <FormikNumberInput
              append={'hours'}
              data-cy="design-hours-input"
              label="Design hours"
              name={`design_hours`}
              last
            />
          </Box>
        )}
        {fieldsPermissions.qaHours && (
          <Box width="calc(33.3% - 24px)" marginRight="24px">
            <FormikNumberInput
              append={'hours'}
              data-cy="qa-hours-input"
              label="QA hours"
              name={`qa_hours`}
              last
            />
          </Box>
        )}
        {fieldsPermissions.hoursMonthly && (
          <Box width="calc(33.3% - 24px)" marginRight="24px">
            <FormikNumberInput
              append={'hours'}
              data-cy="hours-monthly-input"
              label="Hours monthly*"
              name={`hours_monthly`}
              last
              validate={handleRequiredNumberValueValidation}
            />
          </Box>
        )}
      </HoursContainer>

      <Box mt={7} sx={{ textAlign: 'right' }}>
        <Link
          to={
            type === PHASE_FORM_TYPES.ADD
              ? '/projects'
              : `/projects/${projectId}`
          }
        >
          <Button variant="ghost" type="button">
            Cancel
          </Button>
        </Link>
        <Button type="submit" onClick={onClick}>
          {actionButtonCopy}
        </Button>
      </Box>
    </Card>
  );
};

export default PhaseFieldsItem;
