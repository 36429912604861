import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { projectsAPI } from 'api/projects/endpoints';
import { Project, ProjectSettings } from 'api/projects/models';
import { fetcher } from 'fetchers/fetcher';
import { displaySnackbar } from 'store/slices/snackbarSlice';
import useSWR from 'swr';
import { getErrorMesssage } from 'utils/functions';

export type ToggleVisibility = {
  phaseId: number;
  userId: number;
};

export type ProjectContext = {
  project?: Project;
  isLoading: boolean;
  setProjectData: (project: ProjectSettings) => void;
  revalidate: () => void;
  projectId: string;
};

const API_URL = '/projects/';

export const Context = createContext<ProjectContext>({
  setProjectData: () => {},
  revalidate: () => {},
  isLoading: true,
  projectId: '',
});

export const ProjectProvider: FC = ({ children }) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const { data, revalidate } = useSWR<{ project: Project }>(
    API_URL + projectId,
    fetcher,
  );

  const [state, setState] = useState<{ project?: Project; isLoading: boolean }>(
    { isLoading: true, project: undefined },
  );

  const setProjectData = async (projectData: ProjectSettings) => {
    if (typeof projectData.avatar !== 'object') {
      delete projectData.avatar;
    }

    projectsAPI
      .updateProject(projectId, projectData)
      .then((data) => {
        setState({ ...state, project: data.data.project });
        dispatch(displaySnackbar({ message: 'Project was updated' }));
        push(`/projects/${projectId}`);
      })
      .catch((error) => {
        const errorMessage = error?.data?.errors
          ? getErrorMesssage(error?.data?.errors)
          : error?.message || 'Failed updating a project';
        dispatch(displaySnackbar({ message: errorMessage, isError: true }));
      })
      .finally(() => setState((state) => ({ ...state, isLoading: false })));
  };

  useEffect(() => {
    setState({
      isLoading: !data?.project,
      project: data?.project,
    });
  }, [data?.project]);

  return (
    <Context.Provider
      value={{ ...state, revalidate, setProjectData, projectId }}
    >
      {children}
    </Context.Provider>
  );
};

export const useProject = () => useContext(Context);
