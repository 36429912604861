import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding: ${theme.space[5]};
  line-height: ${theme.lineHeights.md2};
  border-radius: ${theme.radii.md};
`;

export const Title = styled.h1`
  font-size: ${theme.fontSizes['md']};
  font-weight: ${theme.fontWeights.semiBold};
  line-height: ${theme.lineHeights.md};
  margin: ${theme.space[2]} ${theme.space[3]};
`;

export const Description = styled.p`
  font-size: ${theme.fontSizes['md']};
  margin: ${theme.space[2]} ${theme.space[3]};
`;
