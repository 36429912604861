import React from 'react';

import { Button, Modal, MODAL_TYPES } from 'components/ui';
import { Box, Flex } from 'rebass';

type Props = {
  content: React.ReactNode;
  headerTitle: string;
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
};

export const DeleteModal: React.FC<Props> = ({
  content,
  headerTitle,
  isOpen,
  onClose,
  onRemove,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      footer={
        <Flex alignItems="center" width="100%">
          <Box marginLeft="auto">
            <Button mr={2} variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={onRemove} variant="danger">
              Remove
            </Button>
          </Box>
        </Flex>
      }
      isOpen
      modalType={MODAL_TYPES.CONFIRM}
      onClose={onClose}
      title={headerTitle}
    >
      {content}
    </Modal>
  );
};
