import { SignInWithTFAPayload } from 'api/users/models';
import httpClient from 'utils/httpClient';

import { AuthProps, SetNewPasswordProps } from './models';

export const authApi = {
  async signInWithEmail({ email, password }: AuthProps) {
    const url = '/auth/sign_in';
    const options = { withCredentials: false };
    return await httpClient.post(
      url,
      {
        email,
        password,
      },
      options,
    );
  },

  async signInWithTFA(payload: SignInWithTFAPayload) {
    const url = '/auth/sign_in';
    return await httpClient.post(url, payload);
  },

  async requestRestPasswordEmail(email: string) {
    const url = '/auth/password';

    return await httpClient.post(url, { email });
  },

  async setNewPassword(payload: SetNewPasswordProps) {
    const url = '/auth/password';

    return await httpClient.put(url, { ...payload });
  },
};
