import { createSlice } from '@reduxjs/toolkit';
import {
  NotificationsTypes,
  NotificationsPeriods,
} from 'api/notifications/models';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';

import { getNotifications } from './notificationsSlice';

type NotificationsFiltersState = {
  query: string;
  startDate: string | undefined;
  endDate: string | undefined;
  periods: NotificationsPeriods[];
  types: NotificationsTypes[];
  isResolved: boolean;
};

const INITIAL_STATE: NotificationsFiltersState = {
  query: '',
  startDate: undefined,
  endDate: undefined,
  periods: [],
  types: [],
  isResolved: false,
};

const notificationsFiltersSlice = createSlice({
  name: 'notifications.filters',
  initialState: INITIAL_STATE,
  reducers: {
    setQueryFilter(state, action) {
      state.query = action.payload;
    },
    setPeriodsFilter(state, action) {
      state.periods = action.payload;
    },
    setTypesFilter(state, action) {
      state.types = action.payload;
    },
    setIsResolvedFilter(state, action) {
      state.isResolved = action.payload;
    },
    setStartDateFilter(state, action) {
      state.startDate = action.payload;
    },
    setEndDateFilter(state, action) {
      state.endDate = action.payload;
    },
    clearNotificationsFilters(state) {
      state.query = '';
      state.startDate = undefined;
      state.endDate = undefined;
      state.periods = [];
      state.types = [];
    },
  },
});

const {
  setQueryFilter,
  setPeriodsFilter,
  setTypesFilter,
  setIsResolvedFilter,
  setStartDateFilter,
  setEndDateFilter,
} = notificationsFiltersSlice.actions;

export const { clearNotificationsFilters } = notificationsFiltersSlice.actions;

export default notificationsFiltersSlice.reducer;

export enum NotificationsFilters {
  QUERY = 'query',
  PERIODS = 'periods',
  TYPES = 'types',
  IS_RESOLVED = 'is_resolved',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
}

export const setNotificationsFilters = (
  filter: NotificationsFilters,
  value: string | number | string[] | boolean,
): AppThunk => (dispatch) => {
  switch (filter) {
    case NotificationsFilters.QUERY: {
      dispatch(setQueryFilter(value));
      break;
    }
    case NotificationsFilters.PERIODS: {
      dispatch(setPeriodsFilter(value));
      break;
    }

    case NotificationsFilters.TYPES: {
      dispatch(setTypesFilter(value));
      break;
    }
    case NotificationsFilters.IS_RESOLVED: {
      dispatch(setIsResolvedFilter(value));
      break;
    }
    case NotificationsFilters.START_DATE: {
      dispatch(setStartDateFilter(value));
      break;
    }
    case NotificationsFilters.END_DATE: {
      dispatch(setEndDateFilter(value));
      break;
    }
  }
};

export const switchNotificationsResolved = (isResolved: boolean): AppThunk => (
  dispatch,
  getState,
) => {
  dispatch(setIsResolvedFilter(isResolved));

  const {
    startDate,
    endDate,
    periods,
    types,
  } = getState().notifications.filters;

  dispatch(
    getNotifications({
      startDate,
      endDate,
      periods,
      alertTypes: types,
      isResolved,
    }),
  );
};

export const selectNotificationsFiltersData = (state: RootState) =>
  state.notifications.filters;
