import { mutateProjectPhases } from '../../mutations/mutateProjectPhases';
import { projectsAPI } from 'api/projects/endpoints';
import { PhaseAttribute } from 'api/projects/models';
import { serialize } from 'object-to-formdata';

export const addProjectPhase = async (
  projectId: string,
  params: PhaseAttribute,
) => {
  const formData = serialize({ phase: params });
  await projectsAPI.addProjectPhase(projectId, formData);
  await mutateProjectPhases();
};
