import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const validationSchema = (
  t: TFunction,
  projectsName: string[],
  projectName?: string,
) =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('errors.required'))
      .test('is-name-taken', t('errors.nameTaken'), (value) => {
        const lowerCaseValue = value?.toLowerCase();
        if (
          lowerCaseValue &&
          projectsName.includes(lowerCaseValue) &&
          lowerCaseValue !== projectName?.toLowerCase()
        ) {
          return false;
        }
        return true;
      }),
    project_type_id: Yup.number().required(t('errors.required')),
    repositories_attributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t('errors.required')),
      }),
    ),
    // phases_attributes: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required(t('errors.required')),
    //     phase_type_id: Yup.number().required(t('errors.required')),
    //     status: Yup.string().required(t('errors.required')),
    //   }),
    // ),
  });
