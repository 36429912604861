import { mutateTeams } from '../../mutations/mutateTeams';
import { organizationAPI } from 'api/phases/endpoints';
import { serialize } from 'object-to-formdata';

import { TeamActionModel } from './model';

export const addTeam = async (params: TeamActionModel) => {
  const formData = serialize({ team: params });
  await organizationAPI.addTeam(formData);
  await mutateTeams();
};
