import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserPosition } from 'api/users/models';
import { Error } from 'components/forms/formik-prime/styled';
import { PrimeTextInput } from 'components/prime';
import { NewPrimeButton } from 'components/prime/NewButton/NewButton';
import { addPosition } from 'fetchers/actions/position/addPosition';
import { updatePosition } from 'fetchers/actions/position/updatePosition';
import { Dialog } from 'primereact/dialog';
import { Box, Flex } from 'rebass';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  positionList: string[];
  position: UserPosition | null;
}

export const PositionModal = ({
  isOpen,
  setIsOpen,
  positionList,
  position,
}: Props) => {
  const [positionName, setPositionName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setDefaultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  useEffect(() => {
    if (positionName.length > 0) {
      setError('');
    }
  }, [positionName]);

  const onClose = () => {
    setError('');
    setDefaultData();
    setIsOpen(false);
  };

  const setDefaultData = () => {
    if (position) {
      setPositionName(position.name);
    } else {
      setPositionName('');
    }
  };

  const onSubmit = () => {
    const _position = positionName.trim?.();
    const list = position
      ? positionList.filter(
          (p) => p.toLowerCase() !== position.name.toLowerCase(),
        )
      : positionList;
    if (!positionName.length) {
      setError(t('errors.required'));
    } else if (list.includes(_position.toLowerCase())) {
      setError(t('errors.nameTaken'));
    } else {
      if (position) {
        updatePosition(positionName, position.id);
      } else {
        addPosition(positionName);
      }
      onClose();
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Dialog
      onHide={onClose}
      visible={isOpen}
      blockScroll={true}
      header={t(position ? 'users.editPosition' : 'users.addPosition')}
    >
      <Flex minWidth="300px" flexDirection="column">
        <PrimeTextInput
          ishigh="false"
          iserror={error}
          value={positionName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setPositionName(event.target.value)
          }
          label={`${t('users.positionName')}*`}
          onKeyDown={onKeyPress}
          autoFocus
        />
        {error && <Error>{error}</Error>}
      </Flex>
      <Flex mt={4} justifyContent="flex-end">
        <Box>
          <NewPrimeButton label={t('common.cancel')} onClick={onClose} />
        </Box>
        <Box ml={2}>
          <NewPrimeButton label={t('common.save')} onClick={onSubmit} />
        </Box>
      </Flex>
    </Dialog>
  );
};
