import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import sortBy from 'lodash/sortBy';
import { RootState } from 'store/rootReducer';
import { getStringifiedWeekData } from 'utils/functions';

export type SelectedWeek = {
  start: string;
  end: string;
  weekNumber: number;
  holidays: string[];
};

type SelectedWeeksState = {
  selectedWeeks: SelectedWeek[];
  currentWeek: SelectedWeek;
  isLoading: boolean;
  error: null | string;
};

const INITIAL_STATE: SelectedWeeksState = {
  selectedWeeks: [getStringifiedWeekData(new Date())],
  currentWeek: getStringifiedWeekData(new Date()),
  isLoading: false,
  error: null,
};

const selectedWeeksSlice = createSlice({
  name: 'selectedWeeks',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedWeeks(state, action: PayloadAction<SelectedWeek[]>) {
      state.selectedWeeks = [
        ...action.payload.sort(({ start: startA }, { start: startB }) =>
          startA.localeCompare(startB),
        ),
      ];
    },
    setCurrentWeek(state, action: PayloadAction<SelectedWeek>) {
      state.currentWeek = action.payload;
    },
  },
});

export const { setSelectedWeeks, setCurrentWeek } = selectedWeeksSlice.actions;
export default selectedWeeksSlice.reducer;

export const selectSelectedWeeksData = (state: RootState) => ({
  selectedWeeks: sortBy(
    state.selectedWeeks.selectedWeeks,
    (week) => week.start,
  ),
});

export const selectSelectedWeeks = ({
  selectedWeeks: { selectedWeeks },
}: RootState) => selectedWeeks;

export const selectCurrentWeek = (state: RootState) => ({
  currentWeek: state.selectedWeeks.currentWeek,
});

export const selectSelectedWeeksNumbers = createSelector(
  selectSelectedWeeks,
  (selectedWeeks) => selectedWeeks.map(({ weekNumber }) => weekNumber),
);

export const selectSelectedWeeksInISOFormat = createSelector(
  selectSelectedWeeks,
  (selectedWeeks) =>
    selectedWeeks.map(({ end, start, ...rest }) => ({
      ...rest,
      end: new Date(end),
      start: new Date(start),
    })),
);

export const formatDate = (date: number | Date, pattern = 'yyyy-MM-dd') =>
  format(date, pattern);
