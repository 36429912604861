import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';

import { SelectItem, SelectVariant } from '../';
import {
  SelectMenu,
  SelectMenuItem,
  SelectToggleButton,
  SelectClearButton,
} from '../Select/styled';
import { selectItemByLabel } from '../Select/utils';
import { css } from '@emotion/core';
import { Checkbox } from 'components/forms/Checkbox';
import {
  FieldErrorMessage,
  FormControl,
  ChevronIcon,
} from 'components/primitive';
import { useSelect, useMultipleSelection, UseSelectProps } from 'downshift';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

export type IconVariant = 'left' | 'below' | 'avatar' | 'none';

type Props = Omit<UseSelectProps<SelectItem>, 'onSelectedItemChange'> & {
  items: SelectItem[];
  initialSelectedItems?: SelectItem[];
  iconVariant: IconVariant;
  label: string;
  last?: boolean;
  variant?: SelectVariant;
  onChange?: (values: string[]) => void;
  sorted?: boolean;
  disabled?: boolean;
  placeholder?: string | React.ReactElement;
  error?: string | React.ReactElement;
  'data-cy'?: string;
};

export const MultiSelect: React.FC<Props> = ({
  items,
  initialSelectedItems = [],
  iconVariant,
  label,
  last,
  error,
  onChange,
  sorted = true,
  variant = 'default',
  disabled = false,
  placeholder = 'Select...',
  'data-cy': dataCy,
}) => {
  const [isOptionChosen, setOptionChosen] = useState(false);
  const {
    getDropdownProps,
    getSelectedItemProps,
    addSelectedItem,
    selectedItems,
    setSelectedItems,
    reset,
  } = useMultipleSelection<SelectItem>({
    initialSelectedItems: initialSelectedItems || [],
  });
  const updatedSelectedItems = isOptionChosen
    ? selectedItems
    : initialSelectedItems;
  const selectedValues = updatedSelectedItems.map((item) => String(item.value));

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    openMenu,
  } = useSelect({
    items: sorted ? items.sort(selectItemByLabel) : items,

    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
      }
      return changes;
    },

    // @ts-ignore
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          setOptionChosen(true);

          if (selectedItem) {
            addSelectedItem(selectedItem);
            onChange &&
              onChange([...selectedValues, String(selectedItem.value)]);
          }
          break;
        default:
          break;
      }
    },
  });

  const handleReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    reset();
    onChange && onChange([]);
  };

  return (
    <FormControl last={last}>
      <SelectToggleButton
        {...getToggleButtonProps(
          getDropdownProps({ preventKeyAction: isOpen }),
        )}
        selected={!!selectedValues.length}
        aria-label={label}
        invalid={error}
        variant={variant}
        disabled={disabled}
        isOpen={isOpen}
        type="button"
        data-cy="multiselect-toggle-btn"
      >
        {!!selectedValues.length ? (
          <>
            <Flex alignItems="center">
              {placeholder}
              <Flex
                justifyContent="center"
                alignItems="center"
                pt="2px"
                sx={{
                  lineHeight: 1,
                  ml: 2,
                  width: 'xs',
                  height: 'xs',
                  backgroundColor: 'primary',
                  fontSize: 'xxs',
                  color: 'white',
                  borderRadius: 'md',
                }}
              >
                {selectedValues.length}
              </Flex>
            </Flex>
            <SelectClearButton
              data-cy="multiselect-clear-btn"
              onClick={handleReset}
            >
              <FiX size={16} color={theme.colors.bermudaGray} />
            </SelectClearButton>
          </>
        ) : (
          <>
            {placeholder}
            <ChevronIcon open={isOpen} />
          </>
        )}
      </SelectToggleButton>
      <SelectMenu {...getMenuProps()} width="294px">
        {isOpen &&
          items.map((item, index) => {
            if (selectedValues.includes(String(item.value))) {
              return (
                <SelectMenuItem
                  key={`${item.label}${index}`}
                  highlighted={highlightedIndex === index}
                  data-cy="multiselect-selected-item"
                  {...getSelectedItemProps({
                    index,
                    selectedItem: item,
                    onClick: () => {
                      setOptionChosen(true);
                      setSelectedItems(
                        updatedSelectedItems.filter(
                          (selectedItem) => item.value !== selectedItem.value,
                        ),
                      );
                      onChange &&
                        onChange(
                          selectedValues.filter(
                            (selectedValue) => item.value !== selectedValue,
                          ),
                        );
                      openMenu();
                    },
                  })}
                >
                  <Checkbox
                    data-cy="multiselect-checkbox"
                    size="sm"
                    isChecked
                    onChange={() => {}}
                    value={item?.value || undefined}
                    labelStyles={css`
                      pointer-events: none;
                    `}
                    iconVariant={iconVariant}
                  />
                </SelectMenuItem>
              );
            }
            return (
              <SelectMenuItem
                key={`${item.label}${index}`}
                highlighted={highlightedIndex === index}
                data-cy="multiselect-not-selected-item"
                {...getItemProps({ item, index })}
              >
                <Checkbox
                  data-cy="multiselect-checkbox"
                  size="sm"
                  isChecked={false}
                  onChange={() => {}}
                  value={item?.value || undefined}
                  labelStyles={css`
                    pointer-events: none;
                  `}
                  iconVariant={iconVariant}
                />
              </SelectMenuItem>
            );
          })}
      </SelectMenu>
      {error && <FieldErrorMessage>{error}</FieldErrorMessage>}
    </FormControl>
  );
};
