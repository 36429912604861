export const selectItemByLabel = (
  a: { label: string },
  b: { label: string },
) => {
  if (a.label === 'No responsible person') return -1;
  if (b.label === 'No responsible person') return 1;
  if (a.label && b.label !== null) {
    return a.label.localeCompare(b.label, undefined, { sensitivity: 'base' });
  } else {
    return 0;
  }
};
