import { RiAddLine } from 'react-icons/ri';

import { Field as FormikField } from '../EditView/EditField.styles';
import styled from '@emotion/styled/macro';
import { Form as FormikForm } from 'formik';
import { StyledButton } from 'screens/User/screens/Edit/ProjectPreferencesForm/styled';
import { theme } from 'styles/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  border: 1px solid ${theme.colors.lightestGrey};
  border-top: none;
  border-bottom-left-radius: ${theme.radii.md};
  border-bottom-right-radius: ${theme.radii.md};
  padding: ${theme.space[1]} ${theme.space[2]};
`;

export const Button = styled.button`
  width: 100%;
  height: ${theme.space[9]};
  display: flex;
  align-items: center;
`;

export const AddIcon = styled(RiAddLine)`
  fill: ${theme.colors.primary};
  margin-right: ${theme.space[2]};
`;

export const Text = styled.p`
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.primary};
  line-height: ${theme.lineHeights.md2};
  text-decoration: underline;
`;

export const Label = styled.label`
  line-height: 1;
  margin: ${theme.space[3]} 0;
  display: block;
`;

export const Field = styled(FormikField)`
  width: 100%;

  &:focus {
    outline-color: ${theme.colors.primaryLight};
  }
`;

export const Form = styled(FormikForm)`
  margin-top: ${theme.space[10]};
`;

export const ErrorMessage = styled.div`
  color: ${theme.colors.danger};
  margin-top: ${theme.space[2]};
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const SubmitButton = styled(StyledButton)`
  background: ${theme.colors.white};
  position: absolute;
  top: 0;
  right: 0;
`;
