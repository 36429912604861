import { RefObject, useCallback, useLayoutEffect, useRef } from 'react';

type MouseHandler = (event: MouseEvent) => void;
type UseClickOutsideProps = {
  handler: MouseHandler;
  refs: RefObject<HTMLElement>[];
  listenWhen: boolean;
};

export const useClickOutside = ({
  handler,
  refs,
  listenWhen,
}: UseClickOutsideProps): RefObject<HTMLElement> => {
  const ref = useRef<HTMLElement>(null);

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      if (refs.some((ref) => ref.current?.contains(event.target as Node))) {
        return;
      }

      handler(event);
    },
    [handler, refs],
  );

  useLayoutEffect(() => {
    if (listenWhen) {
      document.addEventListener('mousedown', handleMouseDown);

      return function cleanup() {
        document.removeEventListener('mousedown', handleMouseDown);
      };
    }
  }, [listenWhen, handleMouseDown]);

  return ref;
};
