import styled from '@emotion/styled/macro';
import { Dialog as PrimeDialog } from 'primereact/dialog';
import { theme } from 'styles/theme';

interface Props {
  width?: string;
  top?: string;
  height?: string;
  noPadding?: boolean;
}

export const Dialog = styled(PrimeDialog)<Props>`
  width: ${({ width }) => (width ? width : '460px')};
  border-radius: ${theme.radii.xl};
  position: absolute;
  top: ${({ top }) => top || theme.dimensions.topBarHeight}px;
  height: ${({ height }) => height || 'auto'};

  .p-dialog-header {
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    padding-bottom: ${({ noPadding }) => (noPadding ? '0' : 'inherit')};
  }

  .p-dialog-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
`;

export default Dialog;
