import React from 'react';

import PrimeRadioButton from 'components/prime/Radio';
import { useField } from 'formik';
import { SelectItem } from 'primereact/api';
import { RadioButtonProps } from 'primereact/radiobutton';
import { Box, Flex } from 'rebass';

type Props = {
  name: string;
  values: SelectItem[];
} & RadioButtonProps;

export const FormikRadioButtons = ({
  values,
  name,
  onChange: customOnChange,
  ...restProps
}: Props) => {
  const [field] = useField(name);

  return (
    <Flex flexDirection="column" mb={4}>
      {values.map((item) => (
        <Box key={item.value} mr={3}>
          <PrimeRadioButton
            {...field}
            onChange={(event: {
              originalEvent: Event;
              value: string;
              target: { name: string; id: string; value: string };
            }) => {
              field.onChange(event);
            }}
            inputId={item.label}
            value={item.value}
            checked={item.value === field.value}
            label={item.label || ''}
            {...restProps}
          />
        </Box>
      ))}
    </Flex>
  );
};
