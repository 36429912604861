import React from 'react';

import { NoProjects } from 'components/ui';
import { Flex } from 'rebass';

const NoProjectsToDisplay: React.FC = () => {
  return (
    <Flex justifyContent="center" my={4}>
      <NoProjects />
    </Flex>
  );
};

export default NoProjectsToDisplay;
