export type OrganizationSettings = {
  company: {
    settings: OrganizationSettingsPayload;
    id: number;
    name: string;
    integrations: CompanyIntegration[];
    enabled_features: EnabledFeatures;
  };
};

export type CompanyIntegration = {
  id: number;
  service_name: IntegrationService;
  active: boolean;
  settings: AtlassianSettings;
};

export interface OrganizationSettingsResponse {
  integrates_with_jira: boolean | null;
  integrates_with_github: boolean | null;
  integrates_with_slack: boolean | null;
  integrates_with_toggl: boolean | null;
  integrates_with_calamari: boolean | null;
  jira_url: string;
  github_organization: string;
  slack_workspace: string;
  calamari_api_url: string;
  toggl_api_url: string;
  jira_workflow_scheme_id: number;
  jira_issue_type_scheme_id: number;
  jira_issue_type_screen_scheme_id: number;
  jira_field_configuration_scheme_id: number;
}

export interface JiraSettings {
  jira_workflow_scheme_id?: string;
  jira_issue_type_scheme_id?: string;
  jira_issue_type_screen_scheme_id?: string;
  jira_field_configuration_scheme_id?: string;
  default_user_jira_group_id?: string;
  default_project_jira_group_id?: string;
  high_level_access_role_id?: string;
  low_level_access_role_id?: string;
  jira_permission_scheme_id?: string;
}

export type OrganizationSettingsPayload = {
  integrates_with_atlassian?: boolean;
  integrates_with_github?: boolean;
  integrates_with_slack?: boolean;
  integrates_with_toggl?: boolean;
  integrates_with_calamari?: boolean;
  integrates_with_ldap?: boolean;
  integrates_with_personio?: boolean;
  integrates_with_google_drive?: boolean;
  atlassian_url?: string;
  github_organization?: string;
  slack_workspace?: string;
  calamari_api_url?: string;
  toggl_api_url?: string;
  worklog_issue_type_id?: string;
  worklog_issue_type_name?: string;
} & JiraSettings;

export type IntegrationService =
  | 'atlassian'
  | 'github'
  | 'slack'
  | 'toggl'
  | 'calamari'
  | 'ldap'
  | 'personio'
  | 'google_drive'
  | 'tempo'
  | 'rollbar';

export type UpdateOrganizationSettingsPayload = {
  service_name: IntegrationService;
  active?: boolean;
  settings?: OrganizationSettingsPayload;
};

export type AtlassianFields = {
  id: number;
  service_name: 'atlassian';
  active: boolean;
  settings: AtlassianSettings;
};

export type AtlassianSettings = {
  atlassian_url?: string;
} & JiraSettings;

export type CalamariSettings = {
  calamari_api_url?: string;
};

export type TempoSettings = {
  settings: {
    worklog_issue_type_id?: string;
    worklog_issue_type_name?: string;
  };
};

export type RollbarSettings = {
  settings: {
    token?: string;
    organization_name?: string;
  };
};

export interface AtlassianIntegrationPayload {
  atlassian_url: string;
  username: string;
  token: string;
}

export interface CalamariIntegrationPayload {
  api_url: string;
  api_key: string;
}

export interface TogglIntegrationPayload {
  api_token: string;
}

export interface LdapIntegrationPayload {
  username: string;
  password: string;
  ldap_host: string;
  ldap_base: string;
  ldap_port: number | undefined;
}

export interface PersonioIntegrationPayload {
  client_id: string;
  client_secret: string;
  google_calendar_api_key: string;
}

export interface TempoIntegrationPayload {
  token: string;
}

export interface RollbarIntegrationPayload {
  token: string;
  organization_name: string;
}

export const IntegrateApps = {
  atlassian: false,
  github: false,
  slack: false,
  calamari: false,
  toggl: false,
  ldap: false,
  personio: false,
  google_drive: false,
  tempo: false,
  rollbar: false,
};

export type IntegrateAppsType = typeof IntegrateApps;

export type IntegrateAppsPayload = { integrations: IntegrateAppsType };

export type EnabledFeatures = {
  atlassian: boolean;
  github: boolean;
  slack: boolean;
  calamari: boolean;
  toggl: boolean;
  ldap: boolean;
  personio: boolean;
  google_drive: boolean;
  tempo: boolean;
  rollbar: boolean;
};
