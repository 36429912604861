import { css } from '@emotion/core';
import { theme } from 'styles/theme';

export const verticalScroll = css`
  //firefox
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${theme.colors.neutralMedium};
    border-radius: 10px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.primary};
    border-radius: 10px !important;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const horizontalScroll = css`
  //firefox
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${theme.colors.neutralMedium};
    border-radius: 10px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.primary};
    border-radius: 10px !important;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
