import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckFill } from 'react-icons/all';
import { useDispatch } from 'react-redux';
import { Column } from 'react-table';

import { TaskInplace } from '../../styled';
import { UserIssue } from 'api/users/models';
import { DeveloperDashboardTable } from 'components/layout';
import { PrimeMultiSelect, StyledDivider } from 'components/prime';
import {
  AvatarWithBackground,
  EstimatesProgressBar,
  NothingFoundBox,
  Tooltip,
} from 'components/ui';
import { EmptyStateWrapper } from 'components/ui/NothingFoundBox/styles';
import { useUserTasks } from 'fetchers/hooks/useUserTasks';
import { Box, Flex, Text } from 'rebass';
import { theme } from 'styles/theme';
import { formatMinutesToHours } from 'utils/functions';

interface Props {
  userId: string;
}

const DeveloperDashboard = ({ userId }: Props) => {
  const [projectsFilter, setProjectsFilter] = useState<number[] | null>(null);
  const [sprintsFilter, setSprintsFilter] = useState<number[] | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isLoading: isDashboardLoading,
    tasks,
    sprints,
    projects,
  } = useUserTasks(userId);

  useEffect(() => {
    return function cleanup() {
      setProjectsFilter([]);
      setSprintsFilter([]);
    };
  }, [dispatch]);

  const filteredSprints = useMemo(() => {
    if (projectsFilter?.length && sprints.length) {
      setSprintsFilter(null);
      return sprints.filter((sprint) =>
        projectsFilter?.includes(sprint.projectId),
      );
    }
    return sprints;
  }, [projectsFilter, sprints]);

  const filteredProjects = useMemo(() => {
    return projects.filter((project) => project.name && project.id);
  }, [projects]);

  const filteredIssues = useMemo(() => {
    if (tasks?.length) {
      let _tasks = [...tasks];
      if (projectsFilter?.length) {
        _tasks = _tasks.filter((task) =>
          projectsFilter.includes(task.project.id),
        );
      }
      if (sprintsFilter?.length) {
        _tasks = _tasks.filter((task) =>
          sprintsFilter?.includes(task.sprint.id),
        );
      }

      return _tasks;
    }
    return [];
  }, [projectsFilter, sprintsFilter, tasks]);

  const openJira = (url: string) => {
    window.open(url);
  };

  const columns: Column<UserIssue>[] = React.useMemo(() => {
    return [
      {
        id: 'name',
        minWidth: 355,
        accessor: (d: { id: number }) => d.id,
        disableSortBy: true,
        Header: String(t('common.name')),
        Cell: ({ row }: any) => {
          const issue = row.original;
          const projectAvatar = issue.project.avatar?.mini.url;

          return (
            <Flex>
              <AvatarWithBackground
                name={issue.project.name}
                image={projectAvatar}
                size="small"
                radius="8px"
                bgColor={
                  projectAvatar ? 'transparent' : theme.colors.primaryLightGray
                }
              />
              <Flex flexDirection="column" ml={3}>
                <Tooltip
                  render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
                    <Box
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      ref={triggerRef}
                      sx={{
                        cursor: 'default',
                      }}
                    >
                      <Text
                        fontSize="sm"
                        color="veryDarkGray"
                        sx={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {issue.description}
                      </Text>
                    </Box>
                  )}
                >
                  {issue.description}
                </Tooltip>

                <Flex alignItems="center">
                  <Text fontSize="xs" color="verDarkGray" opacity={0.6}>
                    {issue.project.name}
                  </Text>
                  <Box
                    width="4px"
                    height="4px"
                    backgroundColor="veryDarkGray"
                    opacity={0.6}
                    marginX={1}
                    sx={{ borderRadius: '100%' }}
                  />
                  <Text
                    fontSize="xs"
                    color="primary"
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={() => openJira(issue.url)}
                  >
                    {issue.jira_key}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          );
        },
      },
      {
        minWidth: 215,
        accessor: (d) => d.id,
        Header: String(t('userProfile.estimates')),
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const issue = row.original;

          return (
            <Flex
              width="100%"
              flexDirection="column"
              px="16px"
              sx={{
                borderLeft: '1px solid rgba(72, 81, 92, 0.1)',
                borderRight: '1px solid rgba(72, 81, 92, 0.1)',
              }}
            >
              <Text margin={0} color="veryDarkGray">
                {t('userProfile.estimate')}:{' '}
                {formatMinutesToHours(issue.time_original_estimate)}
              </Text>
              <EstimatesProgressBar
                spentHours={issue?.time_spent}
                remainingHours={issue?.time_remaining}
                totalHours={issue.time_original_estimate}
              />
            </Flex>
          );
        },
      },
      {
        accessor: (d) => d.id,
        maxWidth: 50,
        Header: String(t('common.status')),
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const issue = row.original;
          const status = issue.deliver_status?.toLowerCase();

          return (
            <Flex
              width="80%"
              justifyContent="center"
              flexDirection="column"
              justifyItems="center"
              alignItems="center"
              fontSize={14}
            >
              {status ? (
                <>
                  <Tooltip
                    render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
                      <Box
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        ref={triggerRef}
                        sx={{
                          cursor: 'default',
                        }}
                      >
                        <Text textAlign="center">
                          <RiCheckFill
                            size="16px"
                            fill={theme.colors.newGreen}
                          />
                          <Text color="newGreen" fontSize="xs">
                            {t(`userProfile.${status}`)}
                          </Text>
                        </Text>
                      </Box>
                    )}
                  >
                    {t(`userProfile.${status}Tooltip`)}
                  </Tooltip>
                </>
              ) : (
                <Text color="newGreen" fontSize="xs">
                  -
                </Text>
              )}
            </Flex>
          );
        },
      },
    ];
  }, [t]);

  return (
    <Box mt={12} width="100%">
      <>
        {!isDashboardLoading && (
          <>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              height="smd"
              sx={{ position: 'relative', top: '-3' }}
            >
              <Box
                as="h2"
                color="veryDarkGray"
                fontSize="lg"
                mb={1}
                sx={{ fontFamily: 'bold' }}
              >
                {t('userProfile.tasks')}
              </Box>
            </Flex>
            <StyledDivider />
            {!!tasks?.length && (
              <Flex>
                <Box mr={2}>
                  <PrimeMultiSelect
                    style={{ width: '200px' }}
                    placeholder={t('common.project')}
                    optionLabel="name"
                    optionValue="id"
                    options={filteredProjects}
                    value={projectsFilter}
                    display="chip"
                    showClear={true}
                    selectAllLabel={t('common.selectAll')}
                    onChange={(e) => {
                      setProjectsFilter(e.target.value);
                    }}
                    isBorder={false}
                    hasAccentBtn
                  />
                </Box>

                <PrimeMultiSelect
                  style={{ width: '200px' }}
                  options={filteredSprints}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t('userProfile.sprint')}
                  value={sprintsFilter}
                  display="chip"
                  showClear={true}
                  selectAllLabel={t('common.selectAll')}
                  onChange={(event) => {
                    setSprintsFilter(event.target.value);
                  }}
                  isBorder={false}
                  hasAccentBtn
                />
              </Flex>
            )}
          </>
        )}
        {tasks?.length ? (
          <Box mt={4}>
            <DeveloperDashboardTable data={filteredIssues} columns={columns} />
          </Box>
        ) : (
          <EmptyStateWrapper>
            {isDashboardLoading ? (
              <Box width="100%" height="100%">
                {Array.from({ length: 6 }).map((_, index) => (
                  <TaskInplace height="60px" key={index} />
                ))}
              </Box>
            ) : (
              <NothingFoundBox isTextCentered />
            )}
          </EmptyStateWrapper>
        )}
      </>
    </Box>
  );
};

export default DeveloperDashboard;
