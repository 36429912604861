import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import NavBar, { NavBarProps } from '../NavBar';
import { OnboardingActions } from '../OnboardingActions';
import { Box, Flex, Text } from 'rebass';

export type NavBarPageViewProps = {
  navBarProps: NavBarProps;
};

const NavBarPageView: React.FC<NavBarPageViewProps> = ({
  navBarProps,
  children,
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  return (
    <Flex>
      <Box width={'320px'}>
        <NavBar {...navBarProps} />
        <OnboardingActions />
      </Box>

      <Box paddingLeft={'60px'} css={{ flex: 1 }}>
        <Text>
          <Link to="/projects">{t('common.projects')} /</Link>
          <Link to={`/projects/${projectId}`}>
            {' '}
            {navBarProps.headerCard?.title}
          </Link>
        </Text>
        {children}
      </Box>
    </Flex>
  );
};

export default NavBarPageView;
