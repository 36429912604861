import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Title } from '../AboutProjectView/AboutProjectView.styles';
import { Project } from 'api/projects/models';
import { Card } from 'components/primitive';
import { Spin } from 'components/ui';
import { Button } from 'components/ui';
import { useProjectPhases } from 'fetchers/hooks/useProjectPhases';
import { Box } from 'rebass';
import ProjectPhasesForm from 'screens/Project/components/ProjectPhasesForm';
import { UserFormWrapper } from 'screens/User/components/styled';
import { selectProjectsName, getProjects } from 'store/slices/projectsSlice';
import { theme } from 'styles/theme';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

export type PhasesFormProps = {
  isLoading?: boolean;
  project?: Project | null;
  projectsName: string[];
  title: string;
  type: PHASE_FORM_TYPES;
};

export enum PHASE_FORM_TYPES {
  ADD = 'add',
  EDIT = 'edit',
}

const ProjectPhasesEdit: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const projectsName = useSelector(selectProjectsName);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { phases = [], isLoading } = useProjectPhases(projectId);
  const canEditProject = usePermissions(PERMISSIONS.EDIT_PROJECT);
  const [emptyPhaseForms, setEmptyPhaseForms] = useState<ReactNode[]>([]);

  useEffect(() => {
    if (!projectsName.length) {
      dispatch(getProjects({}));
    }
  }, [dispatch, projectsName]);

  const openNewPhaseForm = () => {
    setEmptyPhaseForms([
      ...emptyPhaseForms,
      <ProjectPhasesForm
        key={emptyPhaseForms.length}
        type={PHASE_FORM_TYPES.ADD}
      />,
    ]);
  };

  const sortedPhases = phases?.sort((a, b) => a.position - b.position);

  return (
    <UserFormWrapper>
      <Title>{t('projectCard.settings.editSettings.addEditPhases')}</Title>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          {sortedPhases?.map((phase) => (
            <ProjectPhasesForm
              phase={phase}
              key={phase.id}
              type={PHASE_FORM_TYPES.EDIT}
            />
          ))}
          {emptyPhaseForms}
          {canEditProject && (
            <Box marginTop={2} marginBottom={6}>
              <Card size="sm">
                <Button
                  data-cy="add-phase-btn"
                  variant="simple"
                  onClick={openNewPhaseForm}
                  leftComponent={<FiPlus />}
                  type="button"
                  sx={{ marginLeft: theme.space[4] }}
                >
                  Add next phase
                </Button>
              </Card>
            </Box>
          )}
        </>
      )}
    </UserFormWrapper>
  );
};

export default ProjectPhasesEdit;
