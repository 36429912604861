import React from 'react';
import { Row } from 'react-table';

import { ProjectsTableProps } from '../..';
import { SimpleTableRow, SimpleTableRowItem } from '../../../styled';
import { PhaseUserRange } from 'api/projects/models';
import { User } from 'api/users/models';

type Props = {
  isMultiWeeks: boolean;
  row: Row<ProjectsTableProps>;
  user: User;
  phaseUserRange: PhaseUserRange;
};

export const MemberData: React.FC<Props> = ({
  row,
  isMultiWeeks,
  user,
  phaseUserRange,
}) => {
  return (
    <SimpleTableRow
      {...row.getRowProps()}
      as="tr"
      isStaggered
      isMultiWeeks={isMultiWeeks}
    >
      {row.cells.map((cell, index) => {
        return (
          <SimpleTableRowItem
            {...cell.getCellProps()}
            as="td"
            isMultiWeeks={isMultiWeeks}
            fontSize="xs"
            key={`cell_${index}_3`}
          >
            {cell.render('MemberCell', {
              user,
              phaseUserRange,
            })}
          </SimpleTableRowItem>
        );
      })}
    </SimpleTableRow>
  );
};
