import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserPermissions } from 'api/users/models';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import { logoutAction, selectUserPermissions } from 'screens/Auth/authSlice';

type AvailablePermissionsType = {
  [key: string]: any;
};

export enum PERMISSIONS {
  ADD_PROJECT = 'add_project',
  ADD_USER = 'add_user',
  ADDITIONAL_ROLES_ADD = 'additional_roles_add',
  ADDITIONAL_ROLES_EDIT = 'additional_roles_edit',
  ADD_AVAILABLE_RESOURCE = 'available_resource_add',
  EDIT_AVAILABLE_RESOURCE = 'available_resource_edit',
  EDIT_USER_HOURS = 'edit_user_hours',
  CLEAR_USER_HOURS = 'clear_user_hours',
  DASHBOARD = 'dashboard',
  EDIT_PROJECT = 'edit_project',
  EDIT_USER = 'edit_user',
  HIDE_USER = 'hide_user',
  ME = 'me',
  NOTIFICATIONS = 'notifications',
  VIEW_PROJECT = 'view_project',
  VIEW_PROJECT_LIST = 'view_projects_list',
  REMOVE_PROJECT = 'remove_project',
  REMOVE_USER = 'remove_user',
  SET_ACTIVE_USER_ADD = 'set_active_user_add',
  SET_ACTIVE_USER_EDIT = 'set_active_user_edit',
  SHOW_USER = 'show_user',
  USERS = 'users',
  USER = 'user',
  WEEKLY_SUMMARY = 'weekly_summary',
  VIEW_DEVELOPER_DASHBOARD = 'view_developer_dashboard',
  CONFIRM_USER_ON_PHASE = 'confirm_user_on_phase',
  REMOVE_USER_FROM_PHASE = 'remove_user_from_phase',
  NOTIFICATION_SETTING_UPDATE = 'notification_setting',
  CREATE_COMPANY_EXAMPLE_DATA = 'create_company_example_data',
}

export const getPermissionList = (permissions: UserPermissions) => ({
  // Project
  [PERMISSIONS.VIEW_PROJECT_LIST]: () => permissions['Project']['index?'],
  [PERMISSIONS.VIEW_PROJECT]: () => permissions['Project']['show?'],
  [PERMISSIONS.ADD_PROJECT]: () => permissions['Project']['create?'],
  [PERMISSIONS.EDIT_PROJECT]: () => permissions['Project']['update?'],
  [PERMISSIONS.REMOVE_PROJECT]: () => permissions['Project']['destroy?'],

  [PERMISSIONS.DASHBOARD]: () => permissions['Projects::Sprint']['index?'],
  [PERMISSIONS.WEEKLY_SUMMARY]: () =>
    permissions['Reports::Users::Week']['show?'],

  // User
  [PERMISSIONS.ADD_USER]: () => permissions['User']['create?'],
  [PERMISSIONS.ADDITIONAL_ROLES_ADD]: () =>
    !!permissions['User'].permitted_attributes_for_create?.find(
      (attribute) => typeof attribute === 'object' && attribute.user_types,
    ),
  [PERMISSIONS.EDIT_USER]: () => permissions['User']['update?'],
  [PERMISSIONS.HIDE_USER]: () =>
    !!permissions['PhaseUserRange'].permitted_attributes_for_update?.includes(
      'hide',
    ),
  [PERMISSIONS.ME]: () => permissions['User']['me?'],
  [PERMISSIONS.ADDITIONAL_ROLES_EDIT]: () =>
    !!permissions['User'].permitted_attributes_for_update?.find(
      (attribute) => typeof attribute === 'object' && attribute.user_types,
    ),
  [PERMISSIONS.ADD_AVAILABLE_RESOURCE]: () =>
    !!permissions['User'].permitted_attributes_for_create?.includes(
      'available_resource',
    ),
  [PERMISSIONS.EDIT_AVAILABLE_RESOURCE]: () =>
    !!permissions['User'].permitted_attributes_for_update?.includes(
      'available_resource',
    ),
  [PERMISSIONS.SET_ACTIVE_USER_ADD]: () =>
    !!permissions['User'].permitted_attributes_for_create?.includes('active'),
  [PERMISSIONS.SET_ACTIVE_USER_EDIT]: () =>
    !!permissions['User'].permitted_attributes_for_update?.includes('active'),
  [PERMISSIONS.REMOVE_USER]: () => permissions['User']['destroy?'],
  [PERMISSIONS.USER]: () => permissions['User']['show?'],
  [PERMISSIONS.USERS]: () => permissions['User']['index?'],

  // Phase
  [PERMISSIONS.EDIT_USER_HOURS]: () => permissions['PhaseUserHour']['update?'],

  [PERMISSIONS.SHOW_USER]: () =>
    !!permissions['PhaseUserRange'][
      'permitted_attributes_for_update'
    ]?.includes('unhide'),
  [PERMISSIONS.REMOVE_USER_FROM_PHASE]: () =>
    permissions['PhaseUserRange']['destroy?'],
  [PERMISSIONS.CONFIRM_USER_ON_PHASE]: () => {
    const phaseUserRange = permissions['PhaseUserRange'][
      'permitted_attributes_for_update'
    ]?.find(
      (attribute) =>
        typeof attribute === 'object' && !!attribute['phase_user_range'],
      // @ts-ignore
    )?.phase_user_range;

    return isArray(phaseUserRange) && phaseUserRange.includes('confirmed');
  },
  [PERMISSIONS.CLEAR_USER_HOURS]: () =>
    !!permissions['PhaseUserRange'][
      'permitted_attributes_for_update'
    ]?.includes('clear_hours'),

  // Other
  [PERMISSIONS.NOTIFICATIONS]: () => permissions['Notification']['index?'],
  [PERMISSIONS.VIEW_DEVELOPER_DASHBOARD]: () => permissions['Issue']['index?'],
  [PERMISSIONS.NOTIFICATION_SETTING_UPDATE]: () =>
    permissions['NotificationSetting']['update?'],
  [PERMISSIONS.CREATE_COMPANY_EXAMPLE_DATA]: () =>
    permissions['Companies::ExampleData']['create?'],
});

export const usePermissions = (permission: string) => {
  const dispatch = useDispatch();
  const permissions = useSelector(selectUserPermissions, isEqual);
  const availablePermissions: AvailablePermissionsType = useMemo(
    () => getPermissionList(permissions),
    [permissions],
  );

  try {
    return availablePermissions[permission]();
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `Warning: Received \`${permission}\` is not available in permission list. \nHere's list of available permissions: ${Object.values(
          PERMISSIONS,
        ).join(', ')}.`,
      );
    }

    dispatch(logoutAction());
  }
};
