import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const FieldSubLabel = styled.span`
  position: absolute !important;
  font-family: ${theme.fonts.regular} !important;
  text-transform: none !important;
  margin-left: auto;
  right: 0;
  top: 0;
`;
