import { Link } from 'react-router-dom';

import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const NavLink = styled(Link)`
  display: block;
  color: ${theme.colors.veryDarkGray};
  background: ${(props) =>
    props.defaultChecked
      ? theme.colors.primeDarkGrey
      : theme.colors.lightGrayOpacity};
  ${(props) => (props.defaultChecked ? `color: ${theme.colors.white};` : '')}
  transition: all 200ms ease-out;
  border-radius: ${theme.radii.md};
  padding: ${theme.space[3]};
  margin: ${theme.space[1]} 0;
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.semiBold};

  &:hover,
  &.selected {
    background: ${theme.colors.primeDarkGrey};
    color: ${theme.colors.white};
  }
`;
