import { mutateIntegrationStatus } from '../mutations/mutateIntegrationStatus';
import { mutateOrganizationSettings } from '../mutations/mutateOrganizationSettings';
import { organizationSettingsAPI } from 'api/organizationSettings/endpoints';
import { TempoIntegrationPayload } from 'api/organizationSettings/models';

export const addTempoIntegration = async (
  formData: TempoIntegrationPayload,
) => {
  await organizationSettingsAPI.addTempoIntegration(formData);
  await mutateOrganizationSettings();
  await mutateIntegrationStatus();
};
