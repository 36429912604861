import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  iserror?: string;
};

export const CustomLabel = styled(Flex)<Props>`
  position: relative;

  & span {
    text-transform: uppercase;
    font-size: ${theme.fontSizes.xxs};
    color: ${({ iserror }) =>
      iserror ? theme.colors.danger : theme.colors.neutralDark};
    font-family: ${theme.fonts.bold};
    margin-left: ${theme.space[1]};
  }
`;

export const InfoMessage = styled(Flex)`
  margin-left: ${theme.space[3]};
  color: ${theme.colors.danger};
  font-size: ${theme.fontSizes.xxs};
  position: absolute;
  right: 2px;

  & i {
    font-size: ${theme.fontSizes.xs};
    margin-right: ${theme.space[1]};
  }
`;
