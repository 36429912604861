import React from 'react';

import { Roles } from 'api/users/models';
import { Checkbox, CheckboxGroup, CheckboxProps } from 'components/forms';
import { useField } from 'formik';
import { InputSwitchProps } from 'primereact/inputswitch';

type Props = InputSwitchProps & {
  name: string;
  isAuthUser: boolean;
};

export const RolesCheckboxGroup = ({
  name,
  isAuthUser,
  ...restProps
}: Props) => {
  const [field, , helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  return (
    <CheckboxGroup
      {...field}
      isInline
      label="Additional roles"
      name={name}
      //@ts-ignore
      onChange={(value: Array<CheckboxProps['value']>) => {
        setTouched(true);
        setValue(value);
      }}
      value={field.value}
      spacing={8}
      variant="labelInside"
      {...restProps}
    >
      <Checkbox value={Roles.ADMIN} isDisabled={isAuthUser}>
        Admin
      </Checkbox>
      <Checkbox value={Roles.PM}>Product Manager</Checkbox>
      <Checkbox value={Roles.RESOURCE_PLANNER}>Resource Planner</Checkbox>
      <Checkbox value={Roles.TEAM_LEADER}>Team Leader</Checkbox>
      <Checkbox value={Roles.FINANCIAL_ADMINISTRATOR}>
        Financial Administrator
      </Checkbox>
    </CheckboxGroup>
  );
};
