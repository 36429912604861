import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';

import { formatISO } from 'date-fns/esm';
import { debounce } from 'lodash';
import { selectCurrentWeek } from 'store/slices/selectedWeeksSlice';

enum ActionTypes {
  TOGGLE = 'TOGGLE',
  ADD_WEEK = 'ADD_WEEK',
  REMOVE_WEEK = 'REMOVE_WEEK',
  SET_WEEKS = 'SET_WEEKS',
  CLEAR_WEEKS = 'CLEAR_WEEKS',
  RESET_WEEKS = 'RESET_WEEKS',
}

export type WeekDataType = {
  start: Date;
  end: Date;
  weekNumber: number;
  holidays: string[];
};

type Action =
  | { type: ActionTypes.TOGGLE }
  | { type: ActionTypes.ADD_WEEK; payload: WeekDataType }
  | { type: ActionTypes.REMOVE_WEEK; payload: WeekDataType }
  | { type: ActionTypes.SET_WEEKS; payload: WeekDataType[] };

type State = {
  isPickerOpen: boolean;
  chosenWeeks: WeekDataType[];
};

function sortWeeksByStartDate(
  { start: startA }: WeekDataType,
  { start: startB }: WeekDataType,
) {
  const startDateA = formatISO(startA, { representation: 'date' });
  const startDateB = formatISO(startB, { representation: 'date' });

  return startDateA.localeCompare(startDateB);
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.TOGGLE:
      return { ...state, isPickerOpen: !state.isPickerOpen };
    case ActionTypes.ADD_WEEK:
      const extendedWeeks = [...state.chosenWeeks, action.payload].sort(
        sortWeeksByStartDate,
      );
      return { ...state, chosenWeeks: extendedWeeks };
    case ActionTypes.REMOVE_WEEK:
      if (state.chosenWeeks.length !== 1) {
        const truncatedWeeks = state.chosenWeeks.filter(
          (date) => date.weekNumber !== action.payload.weekNumber,
        );
        return { ...state, chosenWeeks: truncatedWeeks };
      }
      return state;
    case ActionTypes.SET_WEEKS:
      return { ...state, chosenWeeks: action.payload };
    default:
      return state;
  }
};

export const useWeekDatePicker = (
  selectedWeeks: WeekDataType[],
  onChange?: (dates: WeekDataType[]) => void,
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialSelectedWeeks = useMemo(() => selectedWeeks, []);
  const { currentWeek } = useSelector(selectCurrentWeek);
  const _currentWeek = useMemo(
    () => ({
      ...currentWeek,
      start: new Date(currentWeek.start),
      end: new Date(currentWeek.end),
    }),
    [currentWeek],
  );
  const isComponentMounted = useRef(false);
  const INITIAL_STATE: State = useMemo(
    () => ({
      isPickerOpen: false,
      chosenWeeks: initialSelectedWeeks,
    }),
    [initialSelectedWeeks],
  );
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { chosenWeeks, isPickerOpen } = state;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = useCallback(
    debounce((weeks: WeekDataType[]) => {
      onChange?.(weeks);
    }, 300),
    [onChange],
  );

  useEffect(() => {
    if (isComponentMounted.current) {
      handleUpdate(chosenWeeks);
    } else {
      isComponentMounted.current = true;
    }
  }, [chosenWeeks, handleUpdate]);

  return {
    addWeek: useCallback((week: WeekDataType) => {
      dispatch({ type: ActionTypes.ADD_WEEK, payload: week });
    }, []),
    isPickerOpen,
    chosenWeeks,
    removeWeek: useCallback((week: WeekDataType) => {
      dispatch({ type: ActionTypes.REMOVE_WEEK, payload: week });
    }, []),
    resetWeeks: useCallback(() => {
      dispatch({
        type: ActionTypes.SET_WEEKS,
        payload: [_currentWeek],
      });
    }, [_currentWeek]),
    setChosenWeek: useCallback((week: WeekDataType) => {
      dispatch({ type: ActionTypes.SET_WEEKS, payload: [week] });
    }, []),
    setChosenWeeks: useCallback((weeks: WeekDataType[]) => {
      dispatch({ type: ActionTypes.SET_WEEKS, payload: weeks });
    }, []),
    togglePicker: useCallback(() => dispatch({ type: ActionTypes.TOGGLE }), []),
  };
};
