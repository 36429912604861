import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiLock } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  signIn,
  selectAuthData,
  clearLoginError,
  setLoginLoading,
} from '../../authSlice';
import { AuthProps } from 'api/auth/models';
import { FormikTextInput } from 'components/forms/formik-prime';
import { PrimeButton } from 'components/prime';
import { Logo, Spin } from 'components/ui';
import { Formik } from 'formik';
import { Box, Flex } from 'rebass';
import { displaySnackbar } from 'store/slices/snackbarSlice';
import { useQuery } from 'utils/hooks/useQuery';

import {
  Wrapper,
  Text,
  Form,
  MailIcon,
  SubmitButton,
  Error,
  Link,
} from './styled';
import { validationSchema } from './validationSchema';

export type LoginErrors = {
  credentials: string;
  inactive: string;
  commonError: string;
  googleError: string;
};

const Login: React.FC = () => {
  const { error, isLoading } = useSelector(selectAuthData);
  const [enableOnBlurValidation, setEnableOnBlurValidation] = useState<boolean>(
    false,
  );

  const { t } = useTranslation();

  const isCredentialsError = error === t('login.invalidCredentials');

  const query = useQuery();
  const dispatch = useDispatch();
  const { replace } = useHistory();

  const authToken = query.get('auth_token');
  const clientId = query.get('client_id');
  const uid = query.get('uid');
  const companyUuid = query.get('company_uuid');
  const success = query.get('success');

  const hasAccess = authToken && clientId && uid;
  const signInErrors: LoginErrors = useMemo(
    () => ({
      credentials: t('login.invalidCredentials'),
      commonError: t('common.error'),
      inactive: t('login.accountInactive'),
      googleError: t('login.googleError'),
    }),
    [t],
  );

  useEffect(() => {
    if (success === 'false') {
      dispatch(
        displaySnackbar({ message: signInErrors.googleError, isError: true }),
      );
      replace({ search: '' });
    }
  }, [dispatch, query, replace, signInErrors.googleError, success]);

  const handleSelectGoogleAccount = () => {
    dispatch(setLoginLoading(true));
    window.open(
      `${process.env.REACT_APP_CRM_API_URL}/auth/google_oauth2`,
      '_self',
    );
  };

  const handleSubmit = (values: AuthProps) => {
    dispatch(signIn({ credentials: values, errors: signInErrors }));
  };

  const handleClearError = useCallback(() => {
    if (error) {
      dispatch(clearLoginError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (authToken && clientId && companyUuid && uid) {
      const headers = {
        'access-token': authToken,
        client: clientId,
        company_uuid: companyUuid,
        uid,
      };
      dispatch(signIn({ headers, errors: signInErrors }));
    }
  }, [authToken, clientId, companyUuid, dispatch, uid, signInErrors]);

  useEffect(() => {
    if (error && !isCredentialsError) {
      dispatch(displaySnackbar({ message: error, isError: true }));
    }
  }, [error, dispatch, isCredentialsError]);

  useEffect(() => {
    handleClearError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Logo width="50" height="80" />
      {hasAccess ? (
        <Box margin="0 0 30px 0">
          <Spin />
        </Box>
      ) : (
        <>
          <Text margin="0">{t('login.heading')}</Text>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={() => validationSchema(t)}
            onSubmit={handleSubmit}
            validateOnBlur={enableOnBlurValidation}
            validateOnChange={false}
          >
            {({ handleSubmit }) => (
              <Form
                onSubmit={(event) => {
                  setEnableOnBlurValidation(true);
                  dispatch(clearLoginError());
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                {isCredentialsError && <Error>{error}</Error>}
                <FormikTextInput
                  name="email"
                  label={t('login.login')}
                  icon={<MailIcon />}
                  onChange={handleClearError}
                />
                <FormikTextInput
                  type="password"
                  name="password"
                  label={
                    <Flex
                      justifyContent="space-between"
                      width="calc(100% - 2rem)"
                    >
                      {t('login.password')}

                      <Link to="/auth/request-reset-password">
                        {t('login.forgotPassword')}
                      </Link>
                    </Flex>
                  }
                  icon={<FiLock />}
                  onChange={handleClearError}
                />
                <SubmitButton
                  type="submit"
                  label={t('login.signIn')}
                  data-cy="login-btn"
                  disabled={isLoading}
                />
              </Form>
            )}
          </Formik>
          <Text>Or</Text>
          <PrimeButton
            data-cy="oauth-login-btn"
            onClick={handleSelectGoogleAccount}
            label={t('login.signInGoogle')}
            disabled={isLoading}
          />
        </>
      )}
    </Wrapper>
  );
};

export default Login;
