import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';

type Props = {
  lastCellShorter?: boolean;
};

export const TableCell = styled(Flex)<Props>`
  &:last-of-type {
    width: ${({ lastCellShorter }) =>
      lastCellShorter ? '80px !important' : 'initial'};
    flex: ${({ lastCellShorter }) =>
      lastCellShorter ? '0 !important' : 'initial'};
  }
`;
