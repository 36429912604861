import { SetStateAction } from 'react';

import { PrimeEvent } from 'components/forms/formik-prime';

export const onPreferencesChange = (
  e: PrimeEvent,
  state: string[],
  setState: SetStateAction<any>,
) => {
  let selectedPreferences = [...state];
  if (e.target.checked) {
    selectedPreferences.push(e.target.value);
  } else {
    selectedPreferences.splice(selectedPreferences.indexOf(e.target.value), 1);
  }
  setState(selectedPreferences);
};

export const formData = {
  teamSizeItems: [{ value: '2-3' }, { value: '3-5' }, { value: '5+' }],
  webOrMobileItems: [{ value: 'Web' }, { value: 'Mobile' }],
  projectTypeItems: [
    { value: 'Product Team' },
    { value: 'MVP' },
    { value: 'Start up' },
    { value: 'Scale up' },
    { value: 'Corporate' },
  ],
};
