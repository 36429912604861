import React from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import { Tooltip } from 'components/ui';
import { Box, Flex } from 'rebass';

type Props = {
  label: string;
  tooltipContent: string;
};

export const HeaderContent = ({ label, tooltipContent }: Props) => {
  return (
    <Flex>
      {label}
      <Tooltip
        render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
          <Box
            marginLeft="6px"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={triggerRef}
            sx={{
              cursor: 'default',
            }}
          >
            <AiOutlineQuestionCircle />
          </Box>
        )}
      >
        {tooltipContent}
      </Tooltip>
    </Flex>
  );
};
