import { PhaseData } from 'api/projects/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

export const useProjectPhases = (projectId: string) => {
  const { data, mutate, revalidate } = useSWR<{ phases: PhaseData[] }>(
    `projects/${projectId}/phases`,
    fetcher,
  );

  return {
    isLoading: !data,
    phases: data?.phases,
    mutate,
    revalidate,
  };
};
