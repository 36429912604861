import { TFunction } from 'i18next';
import * as Yup from 'yup';

const urlRegex = /^(https:\/\/)[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    atlassian_url: Yup.string()
      .required(t('errors.required'))
      .typeError(t('errors.required'))
      .matches(urlRegex, t('errors.invalidUrl')),
    token: Yup.string().required(t('errors.required')),
    username: Yup.string().email().required(t('errors.required')),
    organization_api_key: Yup.string().required(t('errors.required')),
  });
