import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Cell, Row, useFlexLayout, useTable } from 'react-table';

import { ProjectAvatar, ProjectPhaseMember } from 'api/projects/models';
import { UserPosition } from 'components/layout/Tables/columnsStyled';
import {
  DEFAULT_VALUE,
  sortAlphabetically,
} from 'components/layout/Tables/utils/sortAlphabetically';
import {
  ButtonIcon,
  HEADER_STYLE,
  HideIcon,
  MODAL_TYPES,
  ShowIcon,
  TableHeader,
  TableRow,
  TableSubCell,
  TableSubItem,
  TableSubRow,
  Tooltip,
} from 'components/ui/index';
import { useWeeks } from 'fetchers';
import { setUserHoursOnPhase } from 'fetchers/actions/setUserHoursOnPhase';
import {
  setUserVisibility,
  USER_VISIBILITY_ACTIONS,
} from 'fetchers/actions/setUserVisibility';
import { useOrganizationSettings } from 'fetchers/hooks/useOrganizationSettings';
import { useProjectsWeeks, User } from 'fetchers/hooks/useProjectsWeeks';
import { Box, Flex } from 'rebass';
import { HiddenUsers } from 'screens/Projects/components/HiddenUsers';
import { Hours } from 'screens/Projects/components/Hours';
import { User as UserBox } from 'screens/Projects/components/User';
import { setActiveModal } from 'store/slices/modalsSlice';
import {
  ROW_PREFIXES,
  selectIsAllRowsExpanded,
  selectProjectsExpandedRow,
} from 'store/slices/projectsSlice';
import {
  formatDate,
  selectSelectedWeeks,
} from 'store/slices/selectedWeeksSlice';
import { theme } from 'styles/theme';
import { currentStartWeek, currentWeek, MODAL_ID } from 'utils/constants';
import { usersCompareForSort } from 'utils/functions';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

type Props = {
  data: ProjectPhaseMember[];
  projectAvatar?: string;
  projectId: number;
  projectName: string;
  phaseId: number;
  phaseName: string;
};

export enum ACCESSORS {
  AVATAR = 'photo',
  CONFIRMED = 'confirmed',
  TEAM = 'team',
  HIDDEN = 'hidden',
  ID = 'id',
  START_DATE = 'start_date',
  LEAVING_DATE = 'leaving_date',
  NAME = 'name',
  PHASE_USER_RANGE_ID = 'phase_user_range_id',
  PLANNED_HOURS = 'planned_hours',
  POSITION = 'position',
  SPENT_HOURS = 'spent_hours',
  USER_HOURS = 'user_hours',
  SENIORITY = 'seniority',
  WEEKLY_HOURS = 'hours_weekly',
}

type Data = {
  [ACCESSORS.AVATAR]: ProjectAvatar;
  [ACCESSORS.CONFIRMED]: boolean;
  [ACCESSORS.TEAM]: string;
  [ACCESSORS.HIDDEN]: boolean;
  [ACCESSORS.ID]: number;
  [ACCESSORS.START_DATE]: string;
  [ACCESSORS.LEAVING_DATE]: string;
  [ACCESSORS.NAME]: string;
  [ACCESSORS.PHASE_USER_RANGE_ID]: number;
  [ACCESSORS.POSITION]: string;
  [ACCESSORS.SENIORITY]: string;
  [ACCESSORS.WEEKLY_HOURS]: number;
};

type CellProps = Cell<Data>;

type RowProps = Row<Data>;

const INITIAL_GROUPED_ROWS = {
  hidden: [],
  visible: [],
};

export const UsersTable: React.FC<Props> = ({
  data,
  projectAvatar,
  projectId,
  projectName,
  phaseId,
  phaseName,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const expandedRows = useSelector(selectProjectsExpandedRow);
  const selectedWeeks = useSelector(selectSelectedWeeks);
  const isAllRowsExpanded = useSelector(selectIsAllRowsExpanded);
  const sortedUsers = data.slice().sort(usersCompareForSort);
  const currentWeekNumber = currentWeek;
  const { integrations } = useOrganizationSettings();

  const { usersWeeks } = useWeeks();

  const canEditProject = usePermissions(PERMISSIONS.EDIT_PROJECT);
  const canHideUser = usePermissions(PERMISSIONS.HIDE_USER);
  const canShowUser = usePermissions(PERMISSIONS.SHOW_USER);
  const canHideAndShowUser = canHideUser && canShowUser;

  const currentYear = new Date().getFullYear();

  const { isLoading, weeks } = useProjectsWeeks();

  // TODO: typing
  const setShowUserModal = ({
    avatar,
    name,
    phaseUserRangeId,
    phaseId,
  }: any) => {
    dispatch(
      setActiveModal({
        activeModalId: MODAL_ID.SHOW_USER,
        data: {
          user: {
            avatar,
            name,
            phaseId,
            phaseUserRangeId,
            projectId,
          },
          title: 'Unhide member',
          type: MODAL_TYPES.CONFIRM,
        },
      }),
    );
  };

  // TODO: typing
  const renderUserVisibilityButton = (user: any) => {
    if (!user[ACCESSORS.HIDDEN]) {
      return (
        <Box>
          <Tooltip
            render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
              <ButtonIcon
                onClick={() =>
                  setUserVisibility({
                    action: USER_VISIBILITY_ACTIONS.HIDE,
                    phaseUserRangeId: user[ACCESSORS.PHASE_USER_RANGE_ID],
                    projectId,
                    phaseId,
                  })
                }
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                triggerRef={triggerRef}
              >
                <HideIcon />
              </ButtonIcon>
            )}
          >
            Hide user
          </Tooltip>
        </Box>
      );
    }
    return (
      <Box>
        <Tooltip
          render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
            <ButtonIcon
              onClick={() =>
                setShowUserModal({
                  avatar: user[ACCESSORS.AVATAR]?.medium?.url,
                  name: user[ACCESSORS.NAME],
                  phaseUserRangeId: user[ACCESSORS.PHASE_USER_RANGE_ID],
                  phaseId,
                })
              }
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              triggerRef={triggerRef}
            >
              <ShowIcon />
            </ButtonIcon>
          )}
        >
          Unhide user
        </Tooltip>
      </Box>
    );
  };

  const renderMultipleHeader = (shouldRenderLogged: boolean) => {
    return (
      <Flex width="100%">
        {selectedWeeks.length === 1 && (
          <>
            <Box flex={1}>{t('projects.startDate')}</Box>
            <Box flex={1}>{t('projects.endDate')}</Box>
          </>
        )}
        <Box flex={2}>
          {shouldRenderLogged
            ? t('users.loggedAndPlanned')
            : t('users.planned')}
        </Box>
      </Flex>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        accessor: ACCESSORS.NAME,
        Cell: ({ row: { original: row } }: CellProps) => {
          const avatar = row[ACCESSORS.AVATAR];
          const isConfirmed = row[ACCESSORS.CONFIRMED];
          const id = row[ACCESSORS.ID];
          const name = row[ACCESSORS.NAME];
          const phaseUserRangeId = row[ACCESSORS.PHASE_USER_RANGE_ID];
          return (
            <UserBox
              avatar={avatar}
              id={id}
              isConfirmed={isConfirmed}
              name={name}
              phaseUserRangeId={phaseUserRangeId}
              phaseName={phaseName}
              projectName={projectName}
              projectId={projectId}
            />
          );
        },
        Header: 'Members',
        id: ACCESSORS.NAME,
        minWidth: 300,
      },
      ...(selectedWeeks.length === 1
        ? [
            {
              accessor: (d: Data) => d[ACCESSORS.TEAM] || DEFAULT_VALUE,
              Cell: ({ row: { original: row } }: CellProps) =>
                row[ACCESSORS.TEAM] || (
                  <Box color={theme.colors.neutralGray}>
                    {t('userProfile.noTeam')}
                  </Box>
                ),
              Header: 'Team',
              id: ACCESSORS.TEAM,
              minWidth: 200,
            },
            {
              accessor: (d: Data) => d[ACCESSORS.POSITION] || DEFAULT_VALUE,
              Cell: ({ row: { original: row } }: CellProps) => {
                return (
                  <UserPosition>
                    <Box fontSize="xxs">
                      {row[ACCESSORS.POSITION] || (
                        <Box color={theme.colors.neutralGray}>
                          {t('userProfile.noPosition')}
                        </Box>
                      )}
                    </Box>
                  </UserPosition>
                );
              },
              Header: t('userProfile.position'),
              id: ACCESSORS.POSITION,
              minWidth: 150,
            },
            {
              accessor: ACCESSORS.SENIORITY,
              Cell: ({ row: { original: row } }: CellProps) => {
                return (
                  <UserPosition>
                    <Box fontSize="xxs">
                      {row[ACCESSORS.SENIORITY] || (
                        <Box color={theme.colors.neutralGray}>
                          {t('userProfile.noSeniority')}
                        </Box>
                      )}
                    </Box>
                  </UserPosition>
                );
              },
              Header: t('userProfile.seniority'),
              id: ACCESSORS.SENIORITY,
              minWidth: 120,
            },
          ]
        : canHideAndShowUser
        ? [
            {
              Header: '',
              id: 'hide_user',
              width: 50,
              Cell: ({ row: { original: row } }: CellProps) =>
                renderUserVisibilityButton(row),
            },
          ]
        : []),
      ...selectedWeeks.map(({ weekNumber, start }) => {
        const startYear = new Date(start).getFullYear();
        const accessor = `${ACCESSORS.USER_HOURS}_${weekNumber}`;

        const shouldRenderLogged = (() => {
          if (startYear > currentYear) {
            return false;
          }
          return weekNumber < currentWeekNumber && !!integrations?.toggl;
        })();

        return {
          accessor,
          Cell: ({ row: { original: row } }: CellProps) => {
            //@ts-ignore
            const user = row[accessor] as User;

            // @ts-ignore
            if (user === t('common.loading')) {
              return user;
            }

            //@ts-ignore
            user.hoursWeekly = row[ACCESSORS.WEEKLY_HOURS];

            const hoursOff = usersWeeks
              .find((week) => week.week === user.weekNumber)
              ?.users.find((user) => user.id === user.id)?.hours_off;
            const selectedWeekNumbers = selectedWeeks.map((w) => w.weekNumber);
            return (
              <Flex width="100%" alignItems="center">
                {selectedWeeks.length === 1 && (
                  <>
                    <Box flex={1}>{row[ACCESSORS.START_DATE]}</Box>
                    <Box flex={1}>{row[ACCESSORS.LEAVING_DATE]}</Box>
                  </>
                )}
                <Box flex={2}>
                  <Hours
                    hoursOff={hoursOff || 0}
                    hoursWeekly={user.hoursWeekly || 0}
                    id={user.userHour?.id}
                    isConfirmed={row[ACCESSORS.CONFIRMED]}
                    // @ts-ignore
                    isHidden={row[ACCESSORS.HIDDEN] || user.isDisabled}
                    notVerifiedHours={user.notVerifiedUserHours || 0}
                    onChange={setUserHoursOnPhase}
                    phaseId={phaseId}
                    showSpentHours={
                      !selectedWeeks.some(
                        //@ts-ignore
                        (weekNumber) => weekNumber === user.weekNumber,
                      ) && shouldRenderLogged
                    }
                    spentHours={user.spentHours || '00:00'}
                    userId={user.userId}
                    value={user.userHour?.value || 0}
                    verifiedHours={user.verifiedUserHours || 0}
                    //@ts-ignore
                    weekNumber={user.weekNumber}
                    allSelectedWeeks={selectedWeekNumbers}
                  />
                </Box>
              </Flex>
            );
          },
          // @ts-ignore
          disableSortBy: true,
          Header: renderMultipleHeader(shouldRenderLogged),
          id: accessor,
          minWidth: 300,
        };
      }),
      ...(selectedWeeks.length === 1 && canHideAndShowUser
        ? [
            {
              Header: '',
              id: 'hide_user',
              minWidth: 70,
              width: 70,
              Cell: ({ row: { original: row } }: CellProps) =>
                renderUserVisibilityButton(row),
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phaseId, selectedWeeks, t],
  );
  const tableData = useMemo(
    () => [
      ...sortedUsers.map(
        ({
          full_name,
          id,
          phase_user_range,
          team,
          position,
          seniority,
          photo,
          hours_weekly,
        }) => {
          const DATE_FORMAT = 'dd-MM-y';

          return {
            [ACCESSORS.AVATAR]: photo,
            [ACCESSORS.CONFIRMED]: phase_user_range.confirmed,
            [ACCESSORS.HIDDEN]: !!phase_user_range.hidden_at,
            [ACCESSORS.ID]: id,
            [ACCESSORS.NAME]: full_name,
            [ACCESSORS.TEAM]: team,
            [ACCESSORS.PHASE_USER_RANGE_ID]: phase_user_range.id,
            [ACCESSORS.POSITION]: position,
            [ACCESSORS.LEAVING_DATE]: phase_user_range.end_date
              ? formatDate(new Date(phase_user_range.end_date), DATE_FORMAT)
              : '-',
            [ACCESSORS.START_DATE]: formatDate(
              new Date(phase_user_range.start_date),
              DATE_FORMAT,
            ),
            [ACCESSORS.SENIORITY]: seniority,
            [ACCESSORS.WEEKLY_HOURS]: hours_weekly,
            ...selectedWeeks.reduce((accumulator, { start, weekNumber }) => {
              if (isLoading) {
                return {
                  ...accumulator,
                  [`${ACCESSORS.USER_HOURS}_${weekNumber}`]: t(
                    'common.loading',
                  ),
                };
              }

              const userData =
                weeks?.[weekNumber]?.[projectId]?.[phaseId]?.users?.[id];

              return {
                ...accumulator,
                [`${ACCESSORS.USER_HOURS}_${weekNumber}`]: {
                  ...userData,
                  isDisabled: start.localeCompare(currentStartWeek) < 0,
                  userId: id,
                  phaseId,
                  weekNumber,
                },
              };
            }, {}),
          };
        },
      ),
    ],
    [sortedUsers, isLoading, phaseId, projectId, selectedWeeks, t, weeks],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      // @ts-ignore
      columns: tableColumns,
      // @ts-ignore
      data: tableData,
      disableSortRemove: false,
      initialState: {
        // @ts-ignore
        sortBy: [
          {
            desc: false,
            id: ACCESSORS.NAME,
          },
        ],
      },
      sortTypes: {
        alphanumeric: sortAlphabetically(),
      },
    },
    useFlexLayout,
  );

  const hiddenUsersExpanderId = `${ROW_PREFIXES.PHASES}_${phaseId}-hidden_users`;
  const isHiddenUsersExpanded =
    expandedRows[hiddenUsersExpanderId] !== undefined
      ? expandedRows[hiddenUsersExpanderId]
      : false;

  const groupedRows = useMemo(
    () =>
      rows.reduce(
        (acc: { hidden: RowProps[]; visible: RowProps[] }, curr: RowProps) => {
          const isHidden = curr.original[ACCESSORS.HIDDEN];

          if (isHidden) {
            return {
              ...acc,
              hidden: [...acc.hidden, { ...curr }],
            };
          }

          return {
            ...acc,
            visible: [...acc.visible, { ...curr }],
          };
        },
        INITIAL_GROUPED_ROWS,
      ),
    [rows],
  );

  return (
    <TableSubRow ignoreHidingLines>
      <section {...getTableProps()}>
        {groupedRows.visible.length > 0 && (
          <TableHeader headerGroups={headerGroups} style={HEADER_STYLE.MINOR} />
        )}
        <Box
          {...getTableBodyProps()}
          as="ul"
          sx={
            !canEditProject
              ? {
                  boxShadow:
                    groupedRows.hidden.length > 0 ? theme.shadows.md40 : 0,
                  position:
                    groupedRows.hidden.length > 0 ? 'relative' : 'initial',
                }
              : {}
          }
        >
          {groupedRows.visible.map((row) => {
            prepareRow(row);

            return (
              <TableSubItem {...row.getRowProps()} as="li" values={row.values}>
                <TableRow>
                  {row.cells.map(({ getCellProps, render }) => (
                    <TableSubCell {...getCellProps()}>
                      {render('Cell')}
                    </TableSubCell>
                  ))}
                </TableRow>
              </TableSubItem>
            );
          })}
        </Box>
        <HiddenUsers
          expanderId={hiddenUsersExpanderId}
          isAllRowsExpanded={isAllRowsExpanded}
          isExpanded={isHiddenUsersExpanded}
          projectAvatar={projectAvatar}
          members={data}
          phaseId={phaseId}
          projectId={projectId}
          projectName={projectName}
          renderList={() => (
            <>
              {!groupedRows.visible.length && (
                <TableHeader
                  headerGroups={headerGroups}
                  style={HEADER_STYLE.MINOR}
                />
              )}
              <ul {...getTableBodyProps()}>
                {groupedRows.hidden.map((row) => {
                  prepareRow(row);

                  return (
                    <TableSubItem
                      {...row.getRowProps()}
                      as="li"
                      values={row.values}
                    >
                      <TableRow>
                        {row.cells.map(({ getCellProps, render }) => (
                          <TableSubCell {...getCellProps()}>
                            {render('Cell')}
                          </TableSubCell>
                        ))}
                      </TableRow>
                    </TableSubItem>
                  );
                })}
              </ul>
            </>
          )}
          title={
            isHiddenUsersExpanded
              ? t('projects.hideHiddenMembers')
              : t('projects.showHiddenMembers')
          }
        />
      </section>
    </TableSubRow>
  );
};
