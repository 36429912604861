import React, { FC } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import Card, { CardProps } from '../Card/Card';
import { LinkArrowIcon } from 'components/ui';
import { Box, Flex, Text } from 'rebass';
import { theme } from 'styles/theme';

import { NavLink } from './NavBar.styles';

export type NavBarItem = {
  label: string;
  path: string;
  subNavigation?: {
    returnItem: {
      link: string;
      label: string;
    };
    description: string;
    items: NavBarItem[];
  };
};

export type NavBarProps = {
  headerCard?: CardProps;
  items: NavBarItem[];
};

const NavBar: FC<NavBarProps> = ({ headerCard, items }) => {
  const { pathname } = useLocation();

  return (
    <>
      {headerCard && (
        <Box backgroundColor={`${theme.colors.lightGrayOpacity}`}>
          <Card {...headerCard} />
        </Box>
      )}
      <Switch>
        {items
          .filter(({ subNavigation }) => !!subNavigation)
          .map(
            ({ path, subNavigation }) =>
              subNavigation && (
                <Route path={path} key={path}>
                  <NavLink to={subNavigation.returnItem.link} key={path}>
                    <Flex alignItems={'center'}>
                      <LinkArrowIcon />
                      <Text marginLeft={theme.space[3]} lineHeight={1}>
                        {subNavigation.returnItem.label}
                      </Text>
                    </Flex>
                  </NavLink>
                  {subNavigation.description && (
                    <>
                      <Text
                        marginTop={theme.space[3]}
                        padding={`${theme.space[3]} ${theme.space[2]}`}
                      >
                        {subNavigation.description}
                      </Text>
                    </>
                  )}
                  <NavBar items={subNavigation.items} />
                </Route>
              ),
          )}
        <Route>
          <nav>
            {items.map(({ path, label }) => (
              <NavLink to={path} defaultChecked={path === pathname} key={path}>
                {label}
              </NavLink>
            ))}
          </nav>
        </Route>
      </Switch>
    </>
  );
};

NavBar.displayName = 'NavBar';

export default NavBar;
