import React from 'react';

import { FieldSubLabel } from 'components/primitive';
import { DropdownProps } from 'primereact/dropdown';

import { StyledSelect, Wrapper, FieldIcon, Label } from './styled';

export interface DropdownCustomProps {
  iserror?: string;
  variant?: 'default' | 'filled' | 'blue';
  isIcon?: HTMLElement;
  isLabelVisible?: boolean;
  icon?: HTMLElement;
  label?: string;
  subLabel?: string;
  isHigh?: boolean;
}

export const PrimeSelect = ({
  iserror,
  icon,
  label,
  isLabelVisible = !!label,
  subLabel,
  ...restProps
}: DropdownProps & DropdownCustomProps) => {
  return (
    <Wrapper>
      {icon ? <FieldIcon iserror={iserror}>{icon}</FieldIcon> : null}
      {isLabelVisible ? (
        <Label isIcon={icon} iserror={iserror}>
          {label}
        </Label>
      ) : null}
      {subLabel ? <FieldSubLabel>{subLabel}</FieldSubLabel> : null}
      <StyledSelect {...restProps} className={iserror ? 'p-invalid' : ''} />
    </Wrapper>
  );
};
