import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectType } from 'api/users/models';
import { Error } from 'components/forms/formik-prime/styled';
import { PrimeTextInput } from 'components/prime';
import { NewPrimeButton } from 'components/prime/NewButton/NewButton';
import { addProjectType } from 'fetchers/actions/projectType/addProjectType';
import { updateProjectType } from 'fetchers/actions/projectType/updateProjectType';
import { Dialog } from 'primereact/dialog';
import { Box, Flex } from 'rebass';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  projectTypeList: string[];
  projectType: ProjectType | null;
}

export const ProjectTypeModal = ({
  isOpen,
  setIsOpen,
  projectTypeList,
  projectType,
}: Props) => {
  const [projectTypeName, setProjectTypeName] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setDefaultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectType]);

  useEffect(() => {
    if (projectTypeName.length > 0) {
      setError('');
    }
  }, [projectTypeName]);

  const onClose = () => {
    setError('');
    setDefaultData();
    setIsOpen(false);
  };

  const setDefaultData = () => {
    if (projectType) {
      setProjectTypeName(projectType.name);
    } else {
      setProjectTypeName('');
    }
  };

  const onSubmit = () => {
    const _projectTypeName = projectTypeName.trim?.();
    const list = projectType
      ? projectTypeList.filter(
          (p) => p.toLowerCase() !== projectType.name.toLowerCase(),
        )
      : projectTypeList;
    if (!_projectTypeName.length) {
      setError(t('errors.required'));
    } else if (list.includes(_projectTypeName.toLowerCase())) {
      setError(t('errors.nameTaken'));
    } else {
      if (projectType) {
        updateProjectType(_projectTypeName, projectType.id);
      } else {
        addProjectType(_projectTypeName);
      }
      onClose();
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Dialog
      onHide={onClose}
      visible={isOpen}
      blockScroll={true}
      header={t(projectType ? 'users.editProjectType' : 'users.addProjectType')}
    >
      <Flex minWidth="300px" flexDirection="column">
        <PrimeTextInput
          ishigh="false"
          iserror={error}
          value={projectTypeName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setProjectTypeName(event.target.value)
          }
          label={`${t('users.projectTypeName')}*`}
          onKeyDown={onKeyPress}
          autoFocus
        />
        {error && <Error>{error}</Error>}
      </Flex>
      <Flex mt={4} justifyContent="flex-end">
        <Box>
          <NewPrimeButton label={t('common.cancel')} onClick={onClose} />
        </Box>
        <Box ml={2}>
          <NewPrimeButton label={t('common.save')} onClick={onSubmit} />
        </Box>
      </Flex>
    </Dialog>
  );
};
