import React from 'react';
import { Link } from 'react-router-dom';

import { PAGES_PATH } from 'components/layout/PrivateRoute/components/MainLayout/hooks';

import { StyledLink } from './styled';

type ProjectNameLinkProps = {
  'data-cy'?: string;
  href: string;
  onClick?: (event: React.MouseEvent) => void;
  source?: PAGES_PATH | string;
  tabIndex?: number;
  color?: string;
  fontSize?: string;
  fontFamily?: string;
};

export const ProjectNameLink: React.FC<ProjectNameLinkProps> = ({
  children,
  'data-cy': dataCy,
  href,
  onClick,
  source,
  color,
  fontSize,
  fontFamily,
  ...props
}) => (
  <Link
    {...props}
    data-cy={dataCy}
    onClick={onClick}
    to={{
      pathname: href,
      state: {
        from: source,
      },
    }}
  >
    <StyledLink fontSize={fontSize} color={color} fontFamily={fontFamily}>
      {children}
    </StyledLink>
  </Link>
);
