import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, RowWrapper, BoldBox, SmallFontBox } from '../listStyles';
import { Column } from '../models';
import { User } from 'api/users/models';
import { PAGES_PATH } from 'components/layout/PrivateRoute/components/MainLayout/hooks';
import { Avatar, ProjectPhasePersonLink, Tooltip } from 'components/ui';
import { Box, Flex } from 'rebass';
import { Supervisors } from 'screens/Projects/components/Supervisors';
import { theme } from 'styles/theme';
import { trimStringToLength } from 'utils/functions';

interface Props {
  user: User;
  cols: Column<User>[];
  even?: boolean;
}

export const UserRow = ({ user, cols, even }: Props) => {
  const { t } = useTranslation();

  const {
    full_name,
    email,
    photo,
    id,
    position,
    team,
    supervisors,
    skills,
    seniority,
  } = user;

  const renderCol = (colName: keyof User) => {
    switch (colName) {
      case 'full_name':
        return renderNameCol();
      case 'position':
        return renderPositionCol();
      case 'team':
        return renderTeamCol();
      case 'supervisors':
        return renderSupervisorCol();
      case 'skills':
        return renderSkillsCol();
      case 'seniority':
        return renderSeniorityCol();
    }
  };

  const renderNameCol = () => {
    return (
      <ProjectPhasePersonLink href={`/users/${id}`} source={PAGES_PATH.USERS}>
        <Flex alignItems="center">
          <Avatar
            size="small"
            name={full_name || email || t('common.noName')}
            src={photo?.medium?.url || ''}
            radius="xmd"
          />
          <BoldBox as="span" ml={3} fontSize="xs">
            {full_name || email || t('common.noName')}
          </BoldBox>
        </Flex>
      </ProjectPhasePersonLink>
    );
  };

  const renderPositionCol = () => {
    return position ? (
      <SmallFontBox>{position}</SmallFontBox>
    ) : (
      <SmallFontBox color={theme.colors.neutralGray}>
        {t('users.noPosition')}
      </SmallFontBox>
    );
  };

  const renderTeamCol = () => {
    return team ? (
      <SmallFontBox>{team}</SmallFontBox>
    ) : (
      <SmallFontBox color={theme.colors.neutralGray}>
        {t('users.noTeam')}
      </SmallFontBox>
    );
  };

  const renderSeniorityCol = () => {
    return seniority ? (
      <SmallFontBox>{seniority}</SmallFontBox>
    ) : (
      <SmallFontBox color={theme.colors.neutralGray}>
        {t('users.noSeniority')}
      </SmallFontBox>
    );
  };

  const renderSupervisorCol = () => {
    return <Supervisors supervisors={supervisors} />;
  };

  const renderSkillsCol = () => {
    return skills?.length ? (
      <Tooltip
        render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
          <Box
            marginLeft="6px"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={triggerRef}
            sx={{
              cursor: 'default',
            }}
          >
            <SmallFontBox>
              {trimStringToLength(skills?.join(', '), 35)}
            </SmallFontBox>
          </Box>
        )}
      >
        {skills?.join(', ')}
      </Tooltip>
    ) : (
      <SmallFontBox color={theme.colors.neutralGray}>
        {t('userProfile.noSkills')}
      </SmallFontBox>
    );
  };

  return (
    <RowWrapper even={even}>
      {cols.map((col) => (
        <TableCell key={col.key} minWidth={col.minWidth}>
          {renderCol(col.key)}
        </TableCell>
      ))}
    </RowWrapper>
  );
};
