import { FaSpinner } from 'react-icons/fa';

import { keyframes } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type SpinnerProps = {
  variant?: 'white' | 'default';
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(FaSpinner)<SpinnerProps>`
  color: ${({ variant }) =>
    variant === 'white' ? theme.colors.white : theme.colors.primary};
  animation: ${rotate} 1.5s linear infinite;
`;
