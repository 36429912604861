import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SubheaderWrapper } from '../../../styled';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { Box, Flex } from 'rebass';
import { HorizontalLine } from 'screens/User/components/GeneralInfoFields';

interface Props {
  apiUrl?: string;
  onClose: () => void;
  canIntegrate: boolean;
  isIntegrated: boolean;
}

export const SlackSection = ({
  onClose,
  canIntegrate,
  isIntegrated,
}: Props) => {
  const { t } = useTranslation();
  const companyUuid = useMemo(
    () => JSON.parse(localStorage.getItem('company_uuid') || ''),
    [],
  );

  const handleConnectSlack = () => {
    window.open(
      `${
        process.env.REACT_APP_CRM_API_URL
      }/auth/slack?origin=${encodeURIComponent(
        window.location.origin,
      )}/organization-settings&company_uuid=${companyUuid}`,
      '_self',
    );
  };

  return (
    <Flex flexDirection="column" mt={4}>
      <SubheaderWrapper active={true}>
        <Box mr={2} display="inline">
          <i className="pi pi-info-circle" />
        </Box>
        <Box display="inline">
          {!canIntegrate
            ? t('organizationSettings.slack.connect')
            : t(
                isIntegrated
                  ? 'organizationSettings.slack.integrationOn'
                  : 'organizationSettings.slack.integrationValid',
              )}
        </Box>
      </SubheaderWrapper>
      <HorizontalLine />
      <Flex justifyContent="flex-end">
        <Box mr={3}>
          <PrimeTextButton onClick={onClose} label={t('common.cancel')} />
        </Box>
        {!canIntegrate && (
          <NewStyledButton
            data-cy="slack-connect-btn"
            onClick={handleConnectSlack}
            label={t('organizationSettings.slack.connect')}
          />
        )}
      </Flex>
    </Flex>
  );
};
