import React from 'react';
import { FiTrash2 } from 'react-icons/fi';

import { Tooltip } from '../../ui/Tooltip';
import { Box, Flex } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  onClick: () => void;
  title?: string;
};

export const DeleteButton: React.FC<Props> = ({ onClick, title }) => {
  return (
    <Tooltip
      render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
        <Flex
          as="button"
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ref={triggerRef}
          sx={{
            alignItems: 'center',
            backgroundColor: theme.colors.white,
            borderRadius: theme.radii.md,
            boxShadow: theme.shadows.custom,
            justifyContent: 'center',
            position: 'absolute',
            right: 0,
            size: 50,
            top: 0,
            ':hover svg': {
              stroke: theme.colors.danger,
            },
          }}
          type="button"
        >
          <FiTrash2 stroke={theme.colors.bermudaGray} />
          <Box as="span" fontSize={0}>
            {title}
          </Box>
        </Flex>
      )}
    >
      {title}
    </Tooltip>
  );
};
