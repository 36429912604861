import React from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import { MdClose } from 'react-icons/md';
import { RemoveScroll } from 'react-remove-scroll';

import { Flex } from 'rebass';
import { useKeyPress } from 'utils/hooks/useKeyPress';

import {
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from './styled';

export enum MODAL_TYPES {
  SIDE = 'side',
  CONFIRM = 'confirm',
}
export type ModalType = MODAL_TYPES;
export type ModalSize = 'sm' | 'md' | 'lg' | 'xl' | 'expansible';
type ModalRole = 'dialog' | 'alertdialog';

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
  role?: ModalRole;
  size?: ModalSize;
  titleIcon?: React.ReactNode;
  title?: string;
  titleElement?: React.ReactNode;
  modalType?: ModalType;
  finalRef?: React.RefObject<HTMLElement>;
  disableBackdropClick?: boolean;
};

export const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
  footer,
  titleIcon,
  title,
  titleElement,
  role = 'dialog',
  modalType = MODAL_TYPES.SIDE,
  size = 'sm',
  finalRef,
  disableBackdropClick = false,
}) => {
  useKeyPress({
    downHandler: onClose,
    targetKey: 'Escape',
    listenWhen: !!isOpen,
  });

  const deactivateFocusLock = () => {
    if (finalRef?.current) {
      finalRef.current.focus();
    }
  };

  return isOpen
    ? createPortal(
        <FocusLock returnFocus={!finalRef} onDeactivation={deactivateFocusLock}>
          <RemoveScroll enabled={isOpen}>
            <ModalOverlay onClick={disableBackdropClick ? undefined : onClose}>
              <ModalContent
                aria-modal="true"
                role={role}
                size={size}
                modalType={modalType}
                onClick={(e) => e.stopPropagation()}
              >
                <ModalHeader modalType={modalType}>
                  <Flex alignItems="flex-end">
                    {titleIcon}
                    {title}
                    {titleElement}
                  </Flex>
                  <ModalCloseButton
                    onClick={onClose}
                    modalType={modalType}
                    aria-label="Close modal"
                  >
                    <MdClose size="17px" />
                  </ModalCloseButton>
                </ModalHeader>
                {children}
                {footer ? (
                  <ModalFooter modalType={modalType}>{footer}</ModalFooter>
                ) : null}
              </ModalContent>
            </ModalOverlay>
          </RemoveScroll>
        </FocusLock>,
        document.body,
      )
    : null;
};

// TODO: After replacing all old modals rename to `Modal`
export const CleanModal: React.FC<Props> = ({
  onClose,
  children,
  footer,
  titleIcon,
  title,
  titleElement,
  role = 'dialog',
  modalType = MODAL_TYPES.SIDE,
  size = 'sm',
  finalRef,
}) => {
  useKeyPress({
    downHandler: onClose,
    targetKey: 'Escape',
    listenWhen: true,
  });

  const deactivateFocusLock = () => {
    if (finalRef?.current) {
      finalRef.current.focus();
    }
  };

  return (
    <FocusLock returnFocus={!finalRef} onDeactivation={deactivateFocusLock}>
      <RemoveScroll enabled>
        <ModalOverlay onClick={onClose}>
          <ModalContent
            aria-modal="true"
            role={role}
            size={size}
            modalType={modalType}
            onClick={(e) => e.stopPropagation()}
          >
            <ModalHeader modalType={modalType}>
              <Flex alignItems="flex-end">
                {titleIcon}
                {title}
                {titleElement}
              </Flex>
              <ModalCloseButton
                onClick={onClose}
                modalType={modalType}
                aria-label="Close modal"
              >
                <MdClose size="17px" />
              </ModalCloseButton>
            </ModalHeader>
            {children}
            {footer ? (
              <ModalFooter modalType={modalType}>{footer}</ModalFooter>
            ) : null}
          </ModalContent>
        </ModalOverlay>
      </RemoveScroll>
    </FocusLock>
  );
};
