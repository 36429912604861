import styled from '@emotion/styled/macro';
import { InputText, InputTextProps } from 'primereact/inputtext';
import { theme } from 'styles/theme';

import { InputTextCustomProps } from './TextInput';

type Props = {
  isInvalid?: boolean;
  append?: React.ReactNode;
  isIcon?: React.ReactNode;
  variant?: 'filled' | 'default';
  iserror?: string;
};

export const StyledTextInput = styled(InputText)(
  (props: InputTextCustomProps & InputTextProps) => {
    const isHigh = props.ishigh && props.ishigh !== 'false';
    const isFilled = props.variant === 'filled';

    return `
  border-radius: 5px;
  box-shadow: ${theme.shadows.md};
  height: ${
    isHigh ? theme.dimensions.inputHeight : theme.dimensions.inputHeightLow
  };
  font-size: ${theme.fontSizes.xs};
  width: 100%;
  transition: box-shadow 150ms;

  &::placeholder {
    color: ${theme.colors.neutralDark};
    font-size: ${theme.fontSizes.xs};
  }

  &.p-inputtext {
    background-color: ${
      isFilled ? theme.colors.whiteLilac : theme.colors.white
    };};
    border: 1px solid;

    border-color: ${
      isFilled ? theme.colors.neutral : theme.colors.primaryInputBorder
    };

    &:focus {
      outline: none;
      box-shadow: ${theme.shadows.outline};
      border-color: transparent;
    }
  }
`;
  },
);

export const Wrapper = styled.div<Props>`
  position: relative;
  width: 100%;
`;

export const Label = styled.span<Props>`
  left: ${({ isIcon }) => (isIcon ? '25px' : '0')};
  display: block;
  text-transform: uppercase;
  font-size: ${theme.fontSizes.xxs};
  color: ${({ iserror }) =>
    iserror ? theme.colors.danger : theme.colors.neutralDark};
  font-family: ${theme.fonts.bold};
  margin: 0 0 ${theme.space[2]} 0;
  position: relative;
`;

export const FieldIcon = styled.div<Props>`
  position: absolute;
  left: 0;
  top: -5px;
  width: 2rem;
  height: 2rem;
  color: ${({ iserror }) =>
    iserror ? theme.colors.danger : theme.colors.darkGray};
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
`;

export const InputRightElement = styled.div((props: Props) => {
  const isFilled = props.variant === 'filled';
  return `
  position: absolute;
  top: 1px;
  right: 1px;
  display: flex;
  align-items: center;
  height: calc(${theme.sizes.md} - 2px);
  padding: 0 ${theme.space[2]};
  border-radius: ${theme.radii.md};
  background-color: ${isFilled ? theme.colors.whiteLilac : 'transparent'};
  border: 1px solid transparent;
  padding: 0 ${theme.space[1]};
  color: ${theme.colors.darkGray};
  opacity: 0.5;
  user-select: none;
`;
});
