import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UsersFilters } from '../UsersFilters/UsersFilters';
import { SideDialog } from 'components/prime/SideDialog/SideDialog';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { BorderBox } from 'components/primitive/BorderBox/BorderBox';
import { theme } from 'styles/theme';
import { useAreUsersFiltered } from 'utils/hooks/useAreUsersFiltered';

const UserSideControls = () => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const { isFiltered, filtersCount } = useAreUsersFiltered();
  const setFiltersModal = useCallback(() => {
    setDialogVisible(true);
  }, []);
  const { t } = useTranslation();

  return (
    <>
      <BorderBox left={true}>
        <PrimeTextButton
          label={`${t('projects.filters')} ${
            isFiltered ? `(${filtersCount})` : ''
          }`}
          onClick={setFiltersModal}
          icon="pi pi-filter"
          variant="secondary"
          option="list"
          fontSize={theme.fontSizes.sm}
        />
      </BorderBox>
      <SideDialog
        visible={dialogVisible}
        position="top-right"
        header="Filters"
        onHide={() => setDialogVisible(false)}
        modal={true}
        dismissableMask={true}
      >
        <UsersFilters
          isVisible={dialogVisible}
          onHide={() => setDialogVisible(false)}
        />
      </SideDialog>
    </>
  );
};

export default UserSideControls;
