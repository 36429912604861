import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { sortByKey } from '../sortByKey';
import { selectAuthUser } from 'screens/Auth/authSlice';

export const useSortByUserFirst = (items: any[], key: string) => {
  const loggedUser = useSelector(selectAuthUser);
  const _items = useMemo(() => items?.sort(sortByKey(key)) || [], [items, key]);
  const self = _items.findIndex((person) => person.id === loggedUser?.id);
  if (self >= 0) {
    _items.unshift(_items.splice(self, 1)[0]);
  }
  return _items;
};
