import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MODAL_TYPES, ModalSize } from 'components/ui';
import { RootState } from 'store/rootReducer';

type ActiveModalPayload = {
  activeModalId: string;
  data: {
    [key: string]: any;
    size?: ModalSize;
    title?: string;
    type: MODAL_TYPES;
  };
};

const INITIAL_STATE = {
  activeModalId: '',
  data: {},
};

const modalsSlice = createSlice({
  initialState: INITIAL_STATE,
  name: 'modals',
  reducers: {
    resetActiveModal(state) {
      state.activeModalId = INITIAL_STATE.activeModalId;
      state.data = INITIAL_STATE.data;
    },
    setActiveModal(state, action: PayloadAction<ActiveModalPayload>) {
      state.activeModalId = action.payload.activeModalId;
      state.data = action.payload.data;
    },
  },
});

export const { resetActiveModal, setActiveModal } = modalsSlice.actions;

export default modalsSlice.reducer;

export const selectModals = ({ modals }: RootState) =>
  modals as ActiveModalPayload;
