import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  isExpanded: boolean;
  clickable: boolean;
};

const border = `1px solid ${theme.colors.neutralGray}`;

export const HiddenUsersHeader = styled(Flex)<Props>`
  padding: ${theme.space[4]} ${theme.space[2]};
  background-color: ${theme.colors.white};
  align-items: center;
  overflow: hidden;
  color: ${theme.colors.midGray};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out;
  border-top: ${border};
  border-bottom: ${border};

  &:hover,
  &:focus {
    background-color: ${({ clickable }) =>
      clickable ? theme.colors.lightGrayBlue : theme.colors.white};
    color: ${({ clickable }) => (clickable ? theme.colors.primary : 'initial')};
  }

  &:focus {
    outline: 0;
  }
`;
