import React from 'react';
import { Route, Switch } from 'react-router';

import { Global } from '@emotion/core';
import { Spin } from 'components/ui';
import { Flex } from 'rebass';
import EditView from 'screens/Project/screens/EditView';
import { theme } from 'styles/theme';

import NavBarPageView from './components/NavBarPageView';
import { useMenuLinks } from './hooks/useMenuLinks';
import AboutProjectView from './screens/AboutProjectView';
import ProjectIntegrationsView from './screens/ProjectIntegrations';
import ProjectPhasesEdit from './screens/ProjectPhasesEdit';
import ProjectPhasesView from './screens/ProjectPhasesView';
import TeamMembersView from './screens/TeamMembersView';
import UsefulLinksEdit from './screens/UsefulLinksEdit';

const Project = () => {
  const [navBarProps, isLoading] = useMenuLinks();

  if (isLoading)
    return (
      <Flex justifyContent="center">
        <Spin />
      </Flex>
    );

  return (
    <section>
      <Global styles={{ body: { backgroundColor: theme.colors.white } }} />
      <NavBarPageView navBarProps={navBarProps}>
        <Switch>
          <Route exact path="/projects/:projectId/settings">
            <EditView />
          </Route>
          <Route exact path="/projects/:projectId/settings/links">
            <UsefulLinksEdit />
          </Route>
          <Route path="/projects/:projectId/settings/phases">
            <ProjectPhasesEdit />
          </Route>
          <Route exact path="/projects/:projectId/">
            <AboutProjectView />
          </Route>
          <Route path="/projects/:projectId/members">
            <TeamMembersView />
          </Route>
          <Route path="/projects/:projectId/phases">
            <ProjectPhasesView />
          </Route>
          <Route path="/projects/:projectId/integrations">
            <ProjectIntegrationsView />
          </Route>
        </Switch>
      </NavBarPageView>
    </section>
  );
};

Project.displayName = 'Project';

export default Project;
