import { Project } from 'api/projects/models';
import { fetcherWithArgs, getArgsAsString } from 'fetchers/fetcher';
import useSWR from 'swr';

type Projects = {
  projects: Project[];
};

export type ToggleVisibility = {
  phaseId: number;
  userId: number;
};

const API_URL = '/projects';

export const DEFAULT_SETTINGS = [
  {
    only_with_phases: true,
  },
];

export const useUserProjects = (
  userId: string,
  phaseStatus: 'active' | 'inactive' = 'active',
) => {
  const settings = [
    ...DEFAULT_SETTINGS,
    { phase_status: phaseStatus },
    { users_ids: [userId] },
  ];
  const settingsId = getArgsAsString(settings);
  const { data, mutate } = useSWR<Projects>(
    [API_URL, settingsId, phaseStatus],
    (url) => fetcherWithArgs(url, settings),
  );

  return {
    isLoading: !data,
    projects: data?.projects,
    mutate,
  };
};
