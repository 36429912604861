import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type Props = {
  isTextCentered: boolean;
  isSmall: boolean;
};

export const NFBox = styled.div<Props>`
  width: 400px;
  height: ${({ isSmall }) => (isSmall ? '30px' : '40px')};
  background: ${theme.colors.lightGrayBlue};
  display: flex;
  align-items: center;
  justify-content: ${({ isTextCentered }) =>
    isTextCentered ? 'center' : 'flex-start'};
  border-radius: ${theme.radii.sm};
`;

export const Text = styled.p<Props>`
  color: ${theme.colors.midGray};
  font-style: italic;
  font-size: ${({ isSmall }) =>
    isSmall ? theme.fontSizes.xs : theme.fontSizes.sm};
  margin: 0;
  margin-left: ${({ isTextCentered }) => (!isTextCentered ? '20px' : 0)};
`;

export const EmptyStateWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 70px;
  position: relative;
`;
