import React from 'react';

import { FieldSubLabel } from 'components/primitive';
import { MultiSelectProps } from 'primereact/multiselect';
import { MultiSelectCustomProps } from 'screens/Projects/components/ProjectsFilters/models';

import { StyledMultiSelect, Wrapper, FieldIcon, Label } from './styled';
import { selectItemByLabel } from './utils';

export const PrimeMultiSelect = (
  props: MultiSelectProps & MultiSelectCustomProps,
) => {
  const {
    options,
    sorted = true,
    iserror,
    icon,
    isLabelVisible,
    label,
    subLabel,
    isSkillsPicker,
    resetFilterOnHide = true,
    className,
  } = props;
  return (
    <Wrapper className={className}>
      {icon ? <FieldIcon iserror={iserror}>{icon}</FieldIcon> : null}
      {isLabelVisible ? (
        <Label isIcon={icon} iserror={iserror}>
          {label}
        </Label>
      ) : null}
      {subLabel ? <FieldSubLabel>{subLabel}</FieldSubLabel> : null}
      <StyledMultiSelect
        {...props}
        options={sorted ? options?.sort(selectItemByLabel) : props.options}
        className={iserror ? 'p-invalid' : ''}
        isSkillsPicker={isSkillsPicker}
        resetFilterOnHide={resetFilterOnHide}
      />
    </Wrapper>
  );
};
