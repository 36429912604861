import React from 'react';

import { Error, Wrapper } from '../styled';
import {
  PrimeTextInput,
  InputTextCustomProps,
  RefProps,
} from 'components/prime';
import { useField } from 'formik';
import { InputTextProps } from 'primereact/inputtext';

type Props = InputTextProps &
  InputTextCustomProps & {
    name: string;
    validate?: (value: number) => undefined | string | Promise<any>;
  };

export const FormikTextInput = React.forwardRef(
  (
    {
      name,
      onChange: customOnChange,
      onBlur: customOnBlur,
      variant = 'filled',
      validate,
      ...restProps
    }: Props,
    ref: RefProps,
  ) => {
    const [field, meta] = useField({ name, validate });
    return (
      <Wrapper>
        <PrimeTextInput
          {...field}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(event);
            customOnChange && customOnChange(event);
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            field.onBlur(event);
            customOnBlur && customOnBlur(event);
          }}
          iserror={meta.touched ? meta.error : ''}
          variant={variant}
          {...restProps}
          ref={ref}
        />
        {meta.touched && meta.error && <Error>{meta.error}</Error>}
      </Wrapper>
    );
  },
);
