import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const SectionHeader = styled.h3`
  margin: ${theme.space[4]} 0;
  font-size: ${theme.fontSizes.xmd};
  font-family: ${theme.fonts.bold};
`;

export const Text = styled.p`
  margin-bottom: ${theme.space[4]};
`;

export const TableWrapper = styled.div`
  margin-bottom: ${theme.space[10]};
`;
