import { mutatePositions } from '../../mutations/mutatePositions';
import { organizationAPI } from 'api/phases/endpoints';
import { serialize } from 'object-to-formdata';

export const updatePosition = async (name: string, positionId: number) => {
  const formData = serialize({ position: { name } });
  await organizationAPI.updatePosition(positionId, formData);

  await mutatePositions();
};
