import styled from '@emotion/styled/macro';
import { Box } from 'rebass';
import { theme } from 'styles/theme';
import { HEIGHT_OF_TABLE_HEADERS_PLUS_TABLE_GAP } from 'utils/constants';

export const TableWrapper = styled(Box)`
  background-color: ${theme.colors.white};
  border-radius: ${theme.radii.lg};
  border: 1px solid ${theme.colors.neutral};
  overflow: visible;
`;

export const TableBody = styled(Box)`
  border-top: 1px solid ${theme.colors.neutral};
  overflow: auto;
`;

export const TableBodyWrapper = styled(Box)`
  max-height: ${`calc(100vh - ${HEIGHT_OF_TABLE_HEADERS_PLUS_TABLE_GAP})`};
  overflow: auto;
  display: block;
`;
