import { mutate } from 'swr';
import { getCacheKey } from 'utils/getCacheKey';

export const mutateProjects = () => {
  const searchedKey = getCacheKey('arg@"/projects"');

  if (searchedKey) {
    mutate(searchedKey);
  }
};
