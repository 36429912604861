import { mutate } from 'swr';
import { getCacheKey } from 'utils/getCacheKey';

export const mutateProjectsWeeks = (allSelectedWeeks?: number[]) => {
  let searchedKey;

  if (allSelectedWeeks) {
    searchedKey = getCacheKey(
      `arg@"/projects/weeks"@"/users/weeks"@"${allSelectedWeeks.join(',')}"`,
    );
  } else {
    searchedKey = getCacheKey('arg@"/projects/weeks"@"/users/weeks"');
  }

  if (searchedKey) {
    mutate(searchedKey);
  }
};
