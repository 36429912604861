import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ProjectsFilters from '../ProjectsFilters';
import { SideDialog } from 'components/prime/SideDialog/SideDialog';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { theme } from 'styles/theme';
import { useAreProjectsFiltered } from 'utils/hooks/useAreProjectsFiltered';

export const SideControls = () => {
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const { isFiltered, filtersCount } = useAreProjectsFiltered();
  const shouldShowFiltersCount = isFiltered && filtersCount > 0;

  return (
    <>
      <PrimeTextButton
        label={
          (shouldShowFiltersCount ? `(${filtersCount}) ` : '') +
          t('projects.filters')
        }
        onClick={() => setDialogVisible(true)}
        icon="pi pi-filter"
        variant="secondary"
        option="list"
        fontSize={theme.fontSizes.sm}
      />
      <SideDialog
        visible={dialogVisible}
        position="top-right"
        header="Filters"
        onHide={() => setDialogVisible(false)}
        modal={true}
        dismissableMask={true}
      >
        <ProjectsFilters onClose={() => setDialogVisible(false)} />
      </SideDialog>
    </>
  );
};
