import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProject } from '../../hooks/projectContext';
import { EmptyState } from '../AboutProjectView/AboutProjectView.styles';
import { ProjectPhaseMember } from 'api/projects/models';
import { User } from 'api/users/models';
import { PhasesTable } from 'components/ui/Phases/components/PhasesTable';
import cloneDeep from 'lodash.clonedeep';

import { Title } from './ProjectPhasesView.styles';

const ProjectPhasesView = () => {
  const { project } = useProject();
  const { t } = useTranslation();

  const tableData = useMemo(() => {
    if (project) {
      // mutating original project to match UsersTable props model
      const _project = cloneDeep(project);
      _project.phases.forEach((phase: any) => {
        phase.users = phase.users.map((user: User & ProjectPhaseMember) => ({
          full_name: user.full_name,
          id: user.id,
          phase_user_range: user.phase_user_range,
          photo: user.photo,
          position: user.position?.name,
          team: user.team?.name,
          level: user.level,
          hours_weekly: user.hours_weekly,
        }));
        phase.person_responsible = phase.phase_responsible_users;
      });
      return _project.phases.map((phase: any) => ({
        project: project,
        phase: phase,
      }));
    }
    return [];
  }, [project]);

  return (
    <>
      <Title>{t('projectCard.projectPhasesLowercase')}</Title>
      {tableData.length > 0 ? (
        <PhasesTable data={tableData} type="project" />
      ) : (
        <EmptyState>{t('projectCard.noPhases')}</EmptyState>
      )}
    </>
  );
};

export default ProjectPhasesView;
