import { createSelector, createSlice } from '@reduxjs/toolkit';
import { User } from 'api/users/models';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';
import { selectUsersData } from 'store/slices/usersSlice';

type UsersSortByFilter = {
  id: string;
  desc: boolean;
};

type UsersFiltersState = {
  query: string;
  teams: string[];
  positions: string[];
  supervisors: string[];
  skills: string[];
  seniority: string[];
  sortBy: UsersSortByFilter;
};

const INITIAL_STATE: UsersFiltersState = {
  query: '',
  positions: [],
  skills: [],
  supervisors: [],
  teams: [],
  seniority: [],
  sortBy: {
    desc: false,
    id: 'full_name',
  },
};

const filtersSlice = createSlice({
  name: 'users.filters',
  initialState: INITIAL_STATE,
  reducers: {
    setQueryFilter(state, action) {
      state.query = action.payload;
    },
    setTeamsFilter(state, action) {
      state.teams = action.payload;
    },
    setPositionsFilter(state, action) {
      state.positions = action.payload;
    },
    setSupervisorsFilter(state, action) {
      state.supervisors = action.payload;
    },
    setSkillsFilter(state, action) {
      state.skills = action.payload;
    },
    setSeniorityFilter(state, action) {
      state.seniority = action.payload;
    },
    setSortByFilter(state, action) {
      state.sortBy = {
        ...action.payload,
        id: action.payload.id.toLowerCase(),
      };
    },
    clearUserFilters(state) {
      state.query = '';
      state.positions = [];
      state.skills = [];
      state.seniority = [];
      state.supervisors = [];
      state.teams = [];
    },
  },
});

const {
  setQueryFilter,
  setTeamsFilter,
  setPositionsFilter,
  setSupervisorsFilter,
  setSkillsFilter,
  setSeniorityFilter,
  setSortByFilter,
} = filtersSlice.actions;
export const { clearUserFilters } = filtersSlice.actions;

export default filtersSlice.reducer;

export enum UserFilters {
  QUERY = 'query',
  TEAMS = 'teams',
  POSITIONS = 'positions',
  SUPERVISORS = 'supervisors',
  SKILLS = 'skills',
  SENIORITY = 'seniority',
  SORT_BY = 'sortBy',
}

export const setFilter = (
  filter: UserFilters,
  value: string | string[],
): AppThunk => (dispatch) => {
  switch (filter) {
    case UserFilters.QUERY: {
      dispatch(setQueryFilter(value));
      return;
    }
    case UserFilters.TEAMS: {
      dispatch(setTeamsFilter(value));
      return;
    }
    case UserFilters.POSITIONS: {
      dispatch(setPositionsFilter(value));
      return;
    }
    case UserFilters.SUPERVISORS: {
      dispatch(setSupervisorsFilter(value));
      return;
    }
    case UserFilters.SKILLS: {
      dispatch(setSkillsFilter(value));
      return;
    }
    case UserFilters.SENIORITY: {
      dispatch(setSeniorityFilter(value));
      return;
    }
    case UserFilters.SORT_BY: {
      dispatch(setSortByFilter(value));
    }
  }
};

export const selectQueryFilter = (state: RootState) =>
  state.users.filters.query;
const selectTeamsFilter = (state: RootState) => state.users.filters.teams;
const selectSupervisorsFilter = (state: RootState) =>
  state.users.filters.supervisors;
const selectPositionsFilter = (state: RootState) =>
  state.users.filters.positions;
const selectSkillsFilter = (state: RootState) => state.users.filters.skills;
const selectSeniorityFilter = (state: RootState) =>
  state.users.filters.seniority;
const selectSortByFilter = (state: RootState) => state.users.filters.sortBy;

export const selectFilteredUsers = createSelector(
  [
    selectUsersData,
    selectQueryFilter,
    selectTeamsFilter,
    selectSupervisorsFilter,
    selectPositionsFilter,
    selectSkillsFilter,
    selectSeniorityFilter,
  ],
  (data, query, userType, positions, skills, seniority): User[] => {
    return data.users.filter(
      (user: User) =>
        (!query ||
          (user.full_name || user.email)
            .toLowerCase()
            .includes(query.toLowerCase())) &&
        (!userType.length ||
          user.user_types?.some((userT) => userType.includes(userT))) &&
        (!positions.length ||
          positions.includes(String(user.position?.name))) &&
        (!skills.length ||
          user.skills?.some((userSkill) =>
            skills.includes(String(userSkill.name)),
          )) &&
        (!seniority.length || seniority.includes(String(user.level))),
    );
  },
);

export const selectSortedUsers = createSelector(
  [selectFilteredUsers, selectSortByFilter],
  (data: User[], sortBy: UsersSortByFilter) => {
    const { desc, id } = sortBy;

    return data.sort((a: User, b: User) => {
      const firstValue = getValueFromProperty(a, id);
      const secondValue = getValueFromProperty(b, id);

      if (firstValue && !secondValue) {
        return desc ? 1 : -1;
      }

      if (!firstValue && secondValue) {
        return desc ? -1 : 1;
      }

      if (!firstValue && !secondValue) {
        return 0;
      }

      return desc
        ? secondValue.localeCompare(firstValue)
        : firstValue.localeCompare(secondValue);
    });
  },
);

const getValueFromProperty = (item: User, property: string) => {
  // @ts-ignore
  const value = item[property];

  if (typeof value === 'object') {
    return value?.name;
  }

  return value;
};

export const selectUsersFiltersData = (state: RootState) => state.users.filters;
