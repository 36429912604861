import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const ImageWrapper = styled.div`
  position: relative;
  width: 148px;
  height: 148px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const Image = styled.img`
  width: 148px;
  height: 148px;
  object-fit: cover;
  border-radius: ${theme.radii.xxl};
`;

export const InputWrapper = styled.div<{ isInvalid?: boolean }>`
  position: relative;
  width: 148px;
  height: 148px;
  border-radius: ${theme.radii.xxl};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed
    ${({ isInvalid }) =>
      isInvalid ? theme.colors.danger : theme.colors.neutralDark};
  transition: box-shadow 150ms, border-color 150ms;
  cursor: pointer;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;

  &:focus {
    & ~ ${InputWrapper} {
      outline: none;
      box-shadow: ${theme.shadows.outline};
      border-color: transparent;
    }
  }
`;

export const Label = styled.div<{ change?: boolean }>`
  width: 100%;
  min-height: ${theme.space[10]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  color: ${({ change }) =>
    change ? theme.colors.white : theme.colors.midGray};
  background-color: ${({ change }) =>
    change ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
  border-bottom-left-radius: ${({ change }) => (change ? theme.radii.xxl : 0)};
  border-bottom-right-radius: ${({ change }) => (change ? theme.radii.xxl : 0)};
`;

export const Title = styled.div`
  font-size: ${theme.fontSizes.xs};
  margin-bottom: 2.5px;
`;

export const Extensions = styled.div`
  font-size: ${theme.fontSizes.xxs};
`;

export const RemoveAvatar = styled.button`
  position: absolute;
  top: 0;
  right: -24px;
  cursor: pointer;
  outline: none;
`;
