import styled from '@emotion/styled/macro';
import { verticalScroll, horizontalScroll } from 'components/primitive';
import { Flex, Box } from 'rebass';
import { RemoveButton } from 'screens/Projects/components/User/styled';
import { theme } from 'styles/theme';

import { Variant } from './SimpleUsersTable';
import { ActionButtonsBox } from './columnsStyled';

type Props = {
  isMultiWeeks?: boolean;
  isStaggered?: boolean;
  isSprintsTableExpanded?: boolean;
  variant?: Variant;
  flex?: string;
};

type TableHeaderColumnProps = {
  canSort?: boolean;
  isSorted?: boolean | undefined;
  variant?: Variant;
  flex?: string;
  lastCellShorter?: boolean;
};

type ChevronBoxProps = {
  topPosition?: number | string;
};

type TableHeadingProps = {
  isExpanded?: boolean;
  isMultiWeeks?: boolean;
};

export const NoInfoText = styled(Box)`
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.midGray};
`;

export const ChevronBox = styled(Flex)<ChevronBoxProps>`
  align-items: center;
  justify-content: center;
  width: ${theme.sizes.xs};
  height: ${theme.sizes.xs};
  margin-top: ${({ topPosition }) => topPosition || 0};
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: ${theme.radii.md};
  transition: all 150ms;
`;

export const TableHeaderColumnSortbox = styled(Box)<TableHeaderColumnProps>`
  margin-left: ${theme.space[3]};
`;

export const TableHeaderColumn = styled(Flex)<TableHeaderColumnProps>`
  height: ${theme.sizes.md};
  position: relative;
  color: ${theme.colors.newDarkGrey};
  font-family: ${theme.fonts.bold};
  font-size: ${theme.fontSizes.xxs};
  text-transform: capitalize;
  cursor: ${({ canSort }) => (canSort ? 'pointer' : 'initial')};
  user-select: none;
  flex: ${({ flex }) => flex || '1 0 auto'};
  background-color: ${({ variant }) => {
    if (variant === Variant.TRANSPARENT_HEADER) {
      return 'transparent';
    }

    return theme.colors.white;
  }};
  padding-left: ${theme.space[5]};

  margin-right: ${({ variant }) => {
    if (variant === Variant.DEVELOPER_DASHBOARD) {
      return theme.space[2];
    }
    return '0';
  }};

  padding-top: ${({ variant }) => {
    if (variant === Variant.PANEL_DASHBOARD) {
      return theme.space[4];
    }
    return '0';
  }};

  align-items: ${({ variant }) => {
    if (variant === Variant.PANEL_DASHBOARD) {
      return 'flex-start';
    }
    return 'center';
  }};

  transition: background-color 150ms;

  &:hover {
    background-color: ${({ canSort }) => canSort && theme.colors.neutral};

    ${TableHeaderColumnSortbox} {
      opacity: 1;
    }

    &:nth-last-child(2) {
      & + div {
        background-color: ${({ canSort, lastCellShorter }) =>
          canSort && lastCellShorter && theme.colors.neutral};
      }
    }
  }

  &:last-child {
    width: ${({ lastCellShorter }) =>
      lastCellShorter ? '80px !important' : 'initial'};
    flex: ${({ lastCellShorter }) =>
      lastCellShorter ? '0 !important' : 'initial'};
    border-left: ${({ lastCellShorter }) => lastCellShorter && 'none'};
  }
`;

export const TableRowItem = styled(Flex)<Props>`
  position: relative;
  height: ${({ variant }) =>
    variant === Variant.DEVELOPER_DASHBOARD ? '64px' : '80px'};
  flex: none;
  background-color: ${theme.colors.white};
  padding: 0 0 0 1.2rem;
  width: 100%;
  align-items: center;
  transition: background-color 150ms;
  box-shadow: ${({ variant }) =>
    variant === Variant.DEVELOPER_DASHBOARD
      ? 'none !important'
      : '0 1px 2px 0 rgb(189 199 211 / 40%);'};
  border-radius: 0 !important;

  ${({ isMultiWeeks, variant }) => {
    const isOn = isMultiWeeks || variant === Variant.DEVELOPER_DASHBOARD;
    return `
    box-shadow: ${isOn ? theme.shadows.md40 : 'none'};
    z-index: ${isOn ? theme.zIndices[1] : '0'};
    margin-right: ${isOn ? theme.space[2] : '0'};
    border-radius: ${isOn ? theme.radii.md : 'none'};
    `;
  }}
`;

export const TableRow = styled(Box)<Props>`
  position: relative;
  border-radius: ${({ variant }) =>
    variant === Variant.DEVELOPER_DASHBOARD ? theme.radii.xmd : theme.radii.md};
  outline: none;
  overflow: hidden;

  margin-bottom: ${({ isMultiWeeks, isSprintsTableExpanded }) => {
    if (!isMultiWeeks && !isSprintsTableExpanded) {
      return theme.space[3];
    }
    return '0';
  }};

  padding-bottom: ${({ isMultiWeeks }) => {
    if (isMultiWeeks) {
      return theme.space[3];
    }
    return '0';
  }};

  box-shadow: ${({ isMultiWeeks }) => {
    if (isMultiWeeks) {
      return 'none';
    }
    return theme.shadows.md40;
  }};

  &:hover,
  &:focus {
    color: ${theme.colors.primary};

    ${TableRowItem} {
      background-color: ${theme.colors.lightGrayBlue};
    }
  }
`;

export const TableHeading = styled(Box)<TableHeadingProps>`
  margin-bottom: ${({ isExpanded }) => (isExpanded ? '-8px' : 0)};
  padding: 28px 20px 18px 20px;
  border-bottom-right-radius: ${({ isExpanded }) => (isExpanded ? 0 : '5px')};
  border-bottom-left-radius: ${({ isExpanded }) => (isExpanded ? 0 : '5px')};
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover,
  &:focus {
    & ${ChevronBox} {
      background-color: ${theme.colors.lightGrayBlue};
      color: ${theme.colors.primary};
    }
  }

  &:after {
    content: '';
    width: 100%;
    height: ${({ isExpanded, isMultiWeeks }) =>
      isExpanded && isMultiWeeks ? 'calc(100% - 8px)' : '100%'};
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`;

export const SimpleTableRowItem = styled(Flex)<Props>`
  align-items: center;
  position: relative;
  flex: ${({ flex }) => flex || 'none'};
  height: 60px;
  user-select: none;

  padding-left: ${({ variant }) => {
    if (variant === Variant.DEVELOPER_DASHBOARD) {
      return theme.space[1];
    }
    return theme.space[5];
  }};

  background-color: ${({ isMultiWeeks }) => {
    if (isMultiWeeks) {
      return theme.colors.white;
    }
    return 'transparent';
  }};
  margin: ${({ isMultiWeeks }) => {
    if (isMultiWeeks) {
      return `0 ${theme.space[2]} 0 0`;
    }
    return '0';
  }};
`;

export const SimpleTableRow = styled(Flex)<Props>`
  display: table;
  align-items: center;
  width: 100%;
  font-size: ${theme.fontSizes.xs};
  color: ${theme.colors.darkGray};
  background-color: ${({ isMultiWeeks }) => {
    if (isMultiWeeks) {
      return 'transparent';
    }
    return theme.colors.white;
  }};

  &:hover {
    & ${RemoveButton} {
      opacity: 1;
    }

    & ${ActionButtonsBox} {
      opacity: 1;
      pointer-events: all;
    }
  }

  &:nth-of-type(odd) {
    background-color: ${({ isMultiWeeks, isStaggered, variant }) => {
      if (isStaggered) {
        if (isMultiWeeks || variant === Variant.PANEL_DASHBOARD) {
          return 'none';
        }
        return theme.colors.blueExtraLight;
      }
    }};

    ${SimpleTableRowItem} {
      background-color: ${({ isMultiWeeks, isStaggered }) => {
        if (isStaggered) {
          if (isMultiWeeks) {
            return theme.colors.blueExtraLight;
          }
          return 'none';
        }
      }};
    }
  }
`;

export const TableWrapper = styled(Box)`
  max-width: 100%;
  position: relative;

  ${verticalScroll};
  ${horizontalScroll};
`;
