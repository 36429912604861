import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getJiraFieldValues } from '../../helpers';
import { Schema, SchemasPayload } from '../../models';
import { organizationSettingsAPI } from 'api/organizationSettings/endpoints';
import {
  JiraSettings,
  OrganizationSettingsPayload,
} from 'api/organizationSettings/models';
import { FormikSelect, FormikTextInput } from 'components/forms/formik-prime';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { fetcher } from 'fetchers/fetcher';
import { useOrganizationSettings } from 'fetchers/hooks/useOrganizationSettings';
import { Form, Formik } from 'formik';
import { Skeleton } from 'primereact/skeleton';
import { Box, Flex } from 'rebass';
import { SubheaderWrapper } from 'screens/OrganizationSettings/styled';
import { HorizontalLine } from 'screens/User/components/GeneralInfoFields';
import { displaySnackbar } from 'store/slices/snackbarSlice';
import { theme } from 'styles/theme';
import useSWR from 'swr';
import { sortByKey } from 'utils/sortByKey';

interface Props {
  organizationSettings?: OrganizationSettingsPayload;
  onClose: () => void;
}

const API_URL = '/atlassian/jira/schemas';

export const AtlassianSettingsForm = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: schemasOptions } = useSWR<SchemasPayload>(API_URL, fetcher);
  const { mutate, settings } = useOrganizationSettings();
  const atlassianSettings = settings.atlassian;

  const sortedSchemas = useMemo(() => {
    if (schemasOptions) {
      const _schemas: { [key: string]: Schema[] } = { ...schemasOptions };
      Object.keys(_schemas).forEach((key) => {
        _schemas[key] = _schemas[key].sort(sortByKey('name'));
      });
      return _schemas;
    }
    return {};
  }, [schemasOptions]);

  const handleSubmit = (values: JiraSettings) => {
    organizationSettingsAPI
      .updateOrganizationSettings({
        service_name: 'atlassian',
        settings: { ...values },
      })
      .then(() => {
        mutate();
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.updateSuccess'),
          }),
        );
        onClose();
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.updateFail'),
            isError: true,
          }),
        );
      });
  };

  return (
    <>
      {settings.atlassian && sortedSchemas ? (
        <Formik
          initialValues={getJiraFieldValues(settings.atlassian.settings)}
          onSubmit={handleSubmit}
        >
          <Form>
            <SubheaderWrapper active={true}>
              {t('organizationSettings.jira.description')}
            </SubheaderWrapper>
            <Flex flexDirection="column">
              <FormikSelect
                variant="default"
                name="jira_field_configuration_scheme_id"
                options={sortedSchemas?.field_configuration_schemas}
                optionValue="id"
                optionLabel="name"
                isHigh={false}
                label={t(
                  'organizationSettings.jira.schemas.fieldConfiguration',
                )}
                initialValue={
                  atlassianSettings?.settings
                    ?.jira_field_configuration_scheme_id
                }
              />
              <FormikSelect
                variant="default"
                name="jira_issue_type_scheme_id"
                options={sortedSchemas?.issue_type_schemas}
                optionValue="id"
                optionLabel="name"
                isHigh={false}
                label={t('organizationSettings.jira.schemas.issueType')}
                initialValue={
                  atlassianSettings?.settings?.jira_issue_type_scheme_id
                }
              />
              <FormikSelect
                variant="default"
                name="jira_issue_type_screen_scheme_id"
                options={sortedSchemas?.issue_type_screen_schemas}
                optionValue="id"
                optionLabel="name"
                isHigh={false}
                label={t('organizationSettings.jira.schemas.issueTypeScreen')}
                initialValue={
                  atlassianSettings?.settings?.jira_issue_type_screen_scheme_id
                }
              />
              <FormikSelect
                variant="default"
                name="jira_workflow_scheme_id"
                options={schemasOptions?.workflow_schemas?.map(
                  ({ id, name }: { id: number; name: string }) => ({
                    name,
                    id: String(id),
                  }),
                )}
                optionValue="id"
                optionLabel="name"
                isHigh={false}
                label={t('organizationSettings.jira.schemas.workflow')}
                initialValue={
                  atlassianSettings?.settings?.jira_workflow_scheme_id
                }
              />

              <FormikSelect
                variant="default"
                name="jira_permission_scheme_id"
                options={schemasOptions?.permission_schemas?.map(
                  ({ id, name }: { id: number; name: string }) => ({
                    name,
                    id: String(id),
                  }),
                )}
                optionValue="id"
                optionLabel="name"
                isHigh={false}
                label={t('organizationSettings.jira.schemas.permission')}
                initialValue={
                  atlassianSettings?.settings?.jira_permission_scheme_id
                }
              />

              <FormikTextInput
                name="default_user_jira_group_id"
                label="Default group ID for users"
              />
              <FormikTextInput
                name="default_project_jira_group_id"
                label="Default group ID for project"
              />
              <FormikTextInput
                name="high_level_access_role_id"
                label="High access role ID"
              />
              <FormikTextInput
                name="low_level_access_role_id"
                label="Low access role ID"
              />

              <HorizontalLine />
              <Flex justifyContent="flex-end">
                <Box mr={3}>
                  <PrimeTextButton
                    onClick={onClose}
                    label={t('common.cancel')}
                  />
                </Box>
                <NewStyledButton
                  type="submit"
                  data-cy="jira-submit-btn"
                  label={t('organizationSettings.jira.save')}
                />
              </Flex>
            </Flex>
          </Form>
        </Formik>
      ) : (
        <>
          <Box mb={theme.space[6]} mt={theme.dimensions.loaderMarginTop}>
            <Skeleton width="300px" height="18px" />
          </Box>
          {Array.from(Array(8).keys()).map(() => (
            <Box>
              <Box mb={theme.space[2]}>
                <Skeleton width="100px" height={theme.fontSizes.xs} />
              </Box>
              <Box mb={theme.space[5]}>
                <Skeleton
                  width="100%"
                  height={theme.dimensions.inputHeightLow}
                />
              </Box>
            </Box>
          ))}
          <HorizontalLine />
          <Flex justifyContent="flex-end">
            <Box alignSelf="flex-end">
              <Skeleton
                width="100px"
                height={theme.dimensions.inputHeightLow}
              />
            </Box>
          </Flex>
        </>
      )}
    </>
  );
};
