import React from 'react';

import { Avatar } from '../Avatar';
import { Box, Flex } from 'rebass';
import { theme } from 'styles/theme';

interface Props {
  name: string;
  photo?: string;
}

export const AvatarBox = ({ name, photo }: Props) => {
  return (
    <Flex
      alignItems="center"
      marginRight="auto"
      marginLeft="auto"
      margin={theme.space[8]}
      padding={theme.space['1']}
      width="fit-content"
      sx={{
        border: `1px solid ${theme.colors.neutralMedium}`,
        borderRadius: theme.radii.md,
      }}
    >
      <Avatar src={photo || ''} name={name} />
      <Box
        as="span"
        color={theme.colors.pureGray}
        marginLeft={theme.space['5']}
        marginRight="38px"
      >
        {name}
      </Box>
    </Flex>
  );
};
