import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTable, useSortBy, useFlexLayout, Cell } from 'react-table';

import { Project } from 'api/projects/models';
import { Variant } from 'components/layout/Tables/SimpleUsersTable';
import { sortAlphabetically } from 'components/layout/Tables/utils/sortAlphabetically';
import { UlWrapper } from 'components/ui/Phases/components/PhasesTable/styled';
import {
  TableHeader,
  TableItem,
  TableRow,
  TableMainCell,
  ProjectName,
} from 'components/ui/index';
import { theme } from 'styles/theme';

import { TableWrapper } from './styled';

export enum ACCESSORS {
  PROJECT_AVATAR = 'project_avatar',
  PROJECT_ID = 'project_id',
  PROJECT_NAME = 'project',
}

type Props = {
  data: Project[];
};

type CellData = {
  [ACCESSORS.PROJECT_ID]: number;
  [ACCESSORS.PROJECT_AVATAR]: string;
  [ACCESSORS.PROJECT_NAME]: string;
};

type CellProps = Cell<CellData>;

export const ProjectsWithoutPhasesTable: React.FC<Props> = ({ data }) => {
  const { projectId: isSingleProjectPage } = useParams<{ projectId: string }>();

  const tableColumns = useMemo(
    () => [
      {
        accessor: ACCESSORS.PROJECT_NAME,
        Cell: ({ row: { original: row } }: CellProps) => {
          const projectId = row[ACCESSORS.PROJECT_ID];
          const projectName = row[ACCESSORS.PROJECT_NAME];
          const projectAvatar = row[ACCESSORS.PROJECT_AVATAR];

          return (
            <ProjectName
              avatar={projectAvatar}
              id={projectId}
              projectName={projectName}
              isLinkDisabled={!!isSingleProjectPage}
            />
          );
        },
        Header: 'Project',
        id: ACCESSORS.PROJECT_NAME,
        minWidth: 300,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const tableData = useMemo(
    () => [
      ...data.map((project) => ({
        [ACCESSORS.PROJECT_ID]: project.id,
        [ACCESSORS.PROJECT_AVATAR]: project.avatar,
        [ACCESSORS.PROJECT_NAME]: project.name,
      })),
    ],
    [data],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      // @ts-ignore
      columns: tableColumns,
      // @ts-ignore
      data: tableData,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        // @ts-ignore
        sortBy: [
          {
            desc: false,
            id: ACCESSORS.PROJECT_NAME,
          },
        ],
      },
      sortTypes: {
        alphanumeric: sortAlphabetically(),
      },
    },
    useSortBy,
    useFlexLayout,
  );
  const tableProps = {
    ...getTableProps(),
    style: {
      ...getTableProps().style,
    },
  };

  return (
    <TableWrapper flexDirection="column">
      <section
        {...tableProps}
        style={{
          ...tableProps.style,
          minWidth: '100%',
          borderTop: `1px solid ${theme.colors.neutral}`,
        }}
      >
        <TableHeader headerGroups={headerGroups} variant={Variant.DEFAULT} />
        <UlWrapper {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <TableItem
                {...row.getRowProps()}
                as="li"
                values={row.values}
                even={index % 2 === 0}
              >
                <TableRow>
                  {row.cells.map(({ getCellProps, render }) => (
                    <TableMainCell {...getCellProps()}>
                      {render('Cell')}
                    </TableMainCell>
                  ))}
                </TableRow>
              </TableItem>
            );
          })}
        </UlWrapper>
      </section>
    </TableWrapper>
  );
};
