import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FormikTextInput } from 'components/forms/formik-prime';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { addTogglIntegration } from 'fetchers/actions/addTogglIntegration';
import { Formik, Form } from 'formik';
import { Skeleton } from 'primereact/skeleton';
import { Box, Flex } from 'rebass';
import {
  GridWrapper,
  IntegratedInfo,
} from 'screens/OrganizationSettings/styled';
import { HorizontalLine } from 'screens/User/components/GeneralInfoFields';
import { displaySnackbar } from 'store/slices/snackbarSlice';
import { theme } from 'styles/theme';

import { validationSchema } from './validationSchema';

interface Props {
  isIntegrated: boolean;
  isLoading: boolean;
  onClose: () => void;
  canIntegrate: boolean;
}

export const TogglSection = ({
  isIntegrated,
  isLoading,
  onClose,
  canIntegrate,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (values: { api_token: string }) => {
    addTogglIntegration(values)
      .then(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationSuccess'),
          }),
        );
        onClose();
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationFail'),
            isError: true,
          }),
        );
      });
  };

  return isLoading ? (
    <Flex flexDirection="column" mt={4}>
      <GridWrapper gridGap={`0 ${theme.space[5]}`}>
        {Array.from(Array(3).keys()).map(() => (
          <Box>
            <Box mb={theme.space[2]}>
              <Skeleton width="100px" height={theme.fontSizes.xs} />
            </Box>
            <Box mb={theme.space[5]}>
              <Skeleton width="100%" height={theme.dimensions.inputHeightLow} />
            </Box>
          </Box>
        ))}
      </GridWrapper>
      <HorizontalLine />
      <Box alignSelf="flex-end">
        <Skeleton width="249px" height={theme.dimensions.inputHeightLow} />
      </Box>
    </Flex>
  ) : (
    <Flex flexDirection="column" mt={4}>
      {canIntegrate && !isIntegrated ? (
        <IntegratedInfo>
          {t('organizationSettings.toggl.integrationValid')}
        </IntegratedInfo>
      ) : isIntegrated ? (
        <>
          <IntegratedInfo>
            {t('organizationSettings.toggl.integrated')}
          </IntegratedInfo>
        </>
      ) : (
        <Formik
          initialValues={{ api_token: '' }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <Flex flexDirection="column">
              <FormikTextInput
                name="api_token"
                label={t('organizationSettings.toggl.token')}
                ishigh="false"
                variant="default"
              />
              <HorizontalLine />
              <Flex justifyContent="flex-end">
                <Box mr={3}>
                  <PrimeTextButton
                    onClick={onClose}
                    label={t('common.cancel')}
                  />
                </Box>
                <NewStyledButton
                  type="submit"
                  data-cy="toggl-submit-btn"
                  label={t('organizationSettings.toggl.connect')}
                />
              </Flex>
            </Flex>
          </Form>
        </Formik>
      )}
    </Flex>
  );
};
