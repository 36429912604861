import React from 'react';

import { TableRowWrapper } from './styled';

export type TableRowProps = {
  isExpanded?: boolean;
  onClick?: () => void;
  tabIndex?: number;
  even?: boolean;
};

export const TableRow: React.FC<TableRowProps> = ({
  children,
  isExpanded,
  onClick,
  ...props
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClick?.();
    }
  };

  return (
    <TableRowWrapper
      {...props}
      isExpanded={isExpanded}
      onClick={onClick}
      onKeyDown={handleKeyDown}
    >
      {children}
    </TableRowWrapper>
  );
};
