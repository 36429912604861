import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { PhaseMemberPayload } from 'api/phases/models';
import type { Project, ProjectPhase } from 'api/projects/models';
import type { User } from 'api/users/models';
import { FormikDatePicker, FormikSelect } from 'components/forms/formik-prime';
import { FormikAutocomplete } from 'components/forms/formik-prime/Autocomplete/Autocomplete';
import { FormikRadioButtons } from 'components/forms/formik-prime/RadioButton/RadioButton';
import { FieldLabel } from 'components/primitive';
import { Avatar, Button } from 'components/ui';
import { AddUserHours } from 'components/ui/AddUserHours/AddUserHours';
import format from 'date-fns/format';
import { useProjects } from 'fetchers';
import { Form, Formik } from 'formik';
import { Box, Flex } from 'rebass';
import { clearFiltersData } from 'screens/Projects/projectsFiltersSlice';
import { addMemberToPhase } from 'store/slices/projectsPhasesMembersSlice';

import { validationSchema } from './validationSchema';

type Props = {
  onClose: () => void;
  user: User | undefined;
  onAddSuccess: any;
};

type FormValues = {
  phase_id: null | number;
  project: null | Project;
  start_date: null | Date;
  end_date: null | Date;
  confirmed: boolean;
  hours: number;
};

const AddUserToProject: React.FC<Props> = ({ onClose, user, onAddSuccess }) => {
  const [enableValidation, setEnableValidation] = useState<boolean>(false);
  const [phases, setPhases] = useState<any | undefined>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { projectsWithPhases } = useProjects();

  useEffect(() => {
    dispatch(clearFiltersData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDefaultValues = useMemo(
    () => ({
      project: null,
      phase_id: null,
      start_date: null,
      end_date: null,
      confirmed: true,
      hours: 0,
    }),
    [],
  );

  const onProjectChange = (project: Project) => {
    setPhases(
      project?.phases
        ?.filter(getIsUserInPhase)
        .map((phase) => ({ label: phase.name, value: phase.id })),
    );
  };

  const getIsUserInPhase = (phase: ProjectPhase) => {
    return !phase.users?.some((member) => member.id === user?.id);
  };

  const projectOptionTemplate = (project: Project) => {
    return (
      <Flex alignItems="center">
        <Avatar src={project.avatar || ''} size="sm" name={project.name} />
        <Box fontSize="xs" ml={2}>
          {project.name}
        </Box>
      </Flex>
    );
  };

  const onSubmit = async (values: FormValues) => {
    const formValues: PhaseMemberPayload = {
      start_date: values.start_date && format(values.start_date, 'yyyy-MM-dd'),
      end_date: values.end_date && format(values.end_date, 'yyyy-MM-dd'),
      user_id: user?.id,
      phase_id: Number(values.phase_id),
      confirmed: values.confirmed,
      year:
        values.start_date?.getFullYear() ||
        values.end_date?.getFullYear() ||
        new Date().getFullYear(),
    };

    if (values.hours > 0) {
      formValues.hours = values.hours;
    } else {
      delete formValues.hours;
    }

    dispatch(addMemberToPhase(formValues, onAddSuccess));
    onClose();
  };

  return (
    <>
      <>
        <Box sx={{ position: 'relative', height: '100%' }} my={5}>
          <Formik
            initialValues={getDefaultValues}
            onSubmit={onSubmit}
            validateOnChange={enableValidation}
            validateOnBlur={enableValidation}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values }) => (
              <Form
                onSubmit={(event) => {
                  setEnableValidation(true);
                  event.preventDefault();
                  handleSubmit();
                }}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormikAutocomplete
                  isHigh={false}
                  options={projectsWithPhases || []}
                  searchKey="name"
                  valueKey="id"
                  name="project"
                  placeholder={t('users.pickProjectPlaceholder')}
                  label={`${t('common.project')}*`}
                  itemTemplate={projectOptionTemplate}
                  onChange={(e) => onProjectChange(e.value)}
                />

                {!!phases?.length && (
                  <>
                    <FormikSelect
                      variant="default"
                      name="phase_id"
                      options={phases}
                      isHigh={false}
                      placeholder={t('users.selectPhase')}
                      label={`${t('users.projectPhase')}*`}
                    />
                    {values.project && (
                      <Flex flexDirection="column">
                        <FieldLabel>
                          <span>Is the team member confirmed?</span>
                        </FieldLabel>
                        <Flex>
                          <FormikRadioButtons
                            values={[
                              { label: 'Confirmed', value: true },
                              { label: 'Proposition', value: false },
                            ]}
                            name="confirmed"
                          />
                        </Flex>
                      </Flex>
                    )}
                    <Flex width="100%">
                      <Box mr={2} width="408px">
                        <FormikDatePicker
                          data-cy="phase-date-picker"
                          name="start_date"
                          label={`${t('users.onPhaseFrom')}*`}
                          variant="default"
                          maxDate={values.end_date || undefined}
                          showButtonBar
                        />
                      </Box>
                      <Box width="408px">
                        <FormikDatePicker
                          data-cy="phase-date-picker"
                          name="end_date"
                          label={t('common.until')}
                          variant="default"
                          minDate={values.start_date || undefined}
                          showButtonBar
                        />
                      </Box>
                    </Flex>
                    <Flex>
                      <AddUserHours />
                    </Flex>
                  </>
                )}
                <Flex
                  justifyContent="space-between"
                  marginTop="auto"
                  width="100%"
                >
                  <Button
                    variant="ghost"
                    data-cy="close-modal-btn"
                    onClick={onClose}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button data-cy="add-member-btn" type="submit">
                    {t('users.addMemberToPhase')}
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </Box>
      </>
    </>
  );
};

export default AddUserToProject;
