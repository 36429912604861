import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectUsersFiltersData } from 'screens/Users/slices/usersFiltersSlice';

export const useAreUsersFiltered = () => {
  const {
    query,
    seniority,
    skills,
    supervisors,
    teams,
    positions,
  } = useSelector(selectUsersFiltersData);

  const filtersCount = useMemo(() => {
    return [
      seniority.length > 0,
      skills.length > 0,
      supervisors.length > 0,
      teams.length > 0,
      positions.length > 0,
    ].filter((f) => f);
  }, [seniority, skills, supervisors, teams, positions]);

  return {
    isFiltered: filtersCount.length > 0,
    filtersCount: filtersCount.length,
    isQuery: !!query,
  };
};
