import styled from '@emotion/styled/macro';
import { Button } from 'primereact/button';
import { theme } from 'styles/theme';

export interface PrimeButtonCustomProps {
  variant?: 'primary' | 'ghost' | 'grey';
}

export const StyledButton = styled(Button)((props: PrimeButtonCustomProps) => {
  const isGrey = props.variant === 'grey';
  const isGhost = props.variant === 'ghost';

  const getBackgroundColor = () => {
    if (isGrey) {
      return theme.colors.neutral;
    }
    if (isGhost) {
      return 'transparent';
    }
    return theme.colors.primary;
  };

  const getBorder = () => {
    if (isGrey) {
      return 'none';
    }
    if (isGhost) {
      return `2px solid ${theme.colors.primary}`;
    }
    return 'initial';
  };

  const getColor = () => {
    if (isGrey) {
      return theme.colors.bermudaGray;
    }
    if (isGhost) {
      return theme.colors.primary;
    }
    return theme.colors.white;
  };

  return `
  background-color: ${getBackgroundColor()};
  border-radius: ${theme.radii.xl};
  border: ${getBorder()};
  padding: ${theme.space[3]} ${theme.space[6]};

  &.p-button {
    height: ${isGrey ? '40px' : '50px'};
    color: ${getColor()};

    &:hover {
      background-color: ${theme.colors.primary};
      box-shadow: ${isGrey ? 'none' : theme.shadows.mainButton};
    }

    & span.pi {
      font-weight: 600;
      margin-right: ${theme.space[4]};
    }
  }

  & .p-button-label {
    font-size: ${theme.fontSizes.xs};
  }

  & .p-button-icon-left,
  & .p-button-icon-right {
    font-size: ${theme.fontSizes.xxs};
  }
`;
});
