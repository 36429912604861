import React from 'react';

import { Flex } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  marginRight?: any;
  onClick?: (args: any) => void;
  onMouseEnter?: (args: any) => void;
  onMouseLeave?: (args: any) => void;
  triggerRef?: React.Ref<any>;
};

export const ButtonIcon: React.FC<Props> = ({
  children,
  onClick,
  triggerRef,
  ...props
}) => (
  <Flex
    {...props}
    alignItems="center"
    as="button"
    flexShrink={0}
    justifyContent="center"
    onClick={onClick}
    ref={triggerRef}
    size={theme.sizes.sm}
    sx={{
      borderRadius: theme.radii.md,
      cursor: 'pointer',
      ':hover,:active': {
        backgroundColor: theme.colors.lightBlue,
      },
      ':focus': {
        boxShadow: theme.shadows.outline,
        outline: 0,
      },
    }}
  >
    {children}
  </Flex>
);
