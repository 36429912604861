import { mutateProjectsUsefulLinks } from '../../mutations/mutateProjectsUsefulLinks';
import { projectsAPI } from 'api/projects/endpoints';
import { serialize } from 'object-to-formdata';

export const addUsefulLink = async (
  projectId: string,
  link: string,
  name: string,
) => {
  const formData = serialize({ useful_link: { link, name } });
  await projectsAPI.addProjectsUsefulLink(projectId, formData);
  await mutateProjectsUsefulLinks();
};
