import { AuthWrapperProps } from '.';
import styled from '@emotion/styled';
import { Box } from 'rebass';

type BoxWithCursorProps = Pick<AuthWrapperProps, 'onLogoClick'> & {
  hasOnClick?: boolean;
};

export interface Props {}

export const BoxWithCursor = styled(Box)<BoxWithCursorProps>`
  cursor: ${({ hasOnClick }) =>
    hasOnClick ? 'pointer' : 'default'} !important;
  width: fit-content;
`;
