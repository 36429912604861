import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

import { Statuses } from './index';

const { colors, fontSizes } = theme;

type VariantProp = {
  variant: Statuses;
};

export const Text = styled.span<VariantProp>`
  text-transform: capitalize;
  line-height: ${theme.lineHeights.normal};
  font-size: ${fontSizes.xxs};
  color: ${({ variant }) => {
    switch (variant) {
      case Statuses.ACTIVE:
        return colors.newGreen;
      default:
        return colors.neutralDark;
    }
  }};
`;
