import { mutateProjectTypes } from '../../mutations/mutateProjectTypes';
import { organizationAPI } from 'api/phases/endpoints';
import { serialize } from 'object-to-formdata';

export const updateProjectType = async (name: string, typeId: number) => {
  const formData = serialize({ project_type: { name } });
  await organizationAPI.updateProjectType(typeId, formData);

  await mutateProjectTypes();
};
