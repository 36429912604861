import { Link } from 'react-router-dom';

import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const ProjectLink = styled(Link)`
  display: flex;
  align-items: center;

  font-family: ${theme.fonts.bold};
  margin-left: ${theme.space[1]};
`;

export const HoursConditionInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.neutralDark};
  font-size: ${theme.fontSizes.xs};
  margin-top: ${theme.space[2]};

  & svg {
    margin-right: ${theme.space[2]};
  }
`;
