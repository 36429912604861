import React from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import { Tooltip } from '../../ui';
import { Box } from 'rebass';

import { StyledTextButton, StyledListTextButton } from './styled';

interface Props {
  onClick: (prop?: any) => void;
  label: string;
  variant?: 'secondary' | 'success' | 'info' | 'danger' | '';
  disabled?: boolean;
  icon?: string;
  iconPos?: 'left' | 'right';
  fontSize?: string;
  option?: 'list';
  tooltipContent?: string;
}

export const PrimeTextButton = ({ ...props }: Props) => {
  const TooltipElement = () => (
    <Tooltip
      render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
        <Box
          marginLeft="6px"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ref={triggerRef}
          sx={{
            cursor: 'default',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <AiOutlineQuestionCircle />
        </Box>
      )}
    >
      {props.tooltipContent}
    </Tooltip>
  );

  if (props.option === 'list') {
    return (
      <StyledListTextButton
        className={`p-button-text ${
          props.variant ? 'p-button-' + props.variant : ''
        }`}
        {...props}
        type="button"
      >
        {props.tooltipContent && <TooltipElement />}
      </StyledListTextButton>
    );
  }
  return (
    <StyledTextButton
      className={`p-button-text ${
        props.variant ? 'p-button-' + props.variant : ''
      }`}
      {...props}
      type="button"
    >
      {props.tooltipContent && <TooltipElement />}
    </StyledTextButton>
  );
};
