import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    app_type: Yup.array(Yup.string().optional().trim()),
    description: Yup.string()
      .optional()
      .max(250, t('errors.max250Chars'))
      .trim(),
    project_type: Yup.array(Yup.string().optional().trim()),
    team_size: Yup.array(Yup.string().optional().trim()),
  });
