import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { UserRow } from '../UserRow/UserRow';
import { HeaderRow, TableHead, UsersListWrapper } from '../listStyles';
import { Column } from '../models';
import { User } from 'api/users/models';
import { SortIcons } from 'components/primitive/SortIcons/SortIcons';
import { useUsers } from 'fetchers/hooks/users/useUsers';
import { Flex } from 'rebass';
import { setLoading } from 'store/slices/loadingSlice';
import { sortByKey } from 'utils/sortByKey';

import { UserListColumns } from './cols';

export const UserList = () => {
  const dispatch = useDispatch();
  const [sortedUsers, setSortedUsers] = useState<User[]>();
  const [sort, setSort] = useState<
    { col: Column<User>; asc: boolean } | undefined
  >(undefined);

  const { users, isLoading } = useUsers();

  const sortUsers = useMemo(() => {
    if (users) {
      const sorted = [...users].sort(
        sortByKey(sort?.col.key || '', sort?.col.sortKey || ''),
      );
      if (sort?.asc) {
        return sorted;
      } else {
        return sorted.reverse();
      }
    }
  }, [users, sort]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
      setSortedUsers(sortUsers);
    }
  }, [isLoading, dispatch, sortUsers]);

  useEffect(() => {
    setSort({
      col: UserListColumns.find((col) => col.key === 'full_name')!,
      asc: true,
    });
    return () => {
      dispatch(setLoading(false));
    };
  }, [dispatch]);

  const sortBy = (col: Column<User>) => {
    if (sort?.col.key === col.key) {
      setSort({ col, asc: !sort?.asc });
    } else {
      setSort({ col, asc: true });
    }
  };

  return (
    <Flex flexDirection="column">
      <UsersListWrapper>
        <HeaderRow>
          {UserListColumns.map((col) => (
            <TableHead
              sort={col.sort}
              key={col.title}
              minWidth={col.minWidth}
              onClick={() => (col.sort !== false ? sortBy(col) : null)}
            >
              {col.title}
              {col.sort !== false && (
                <SortIcons>
                  {sort?.col.key !== col.key && (
                    <i className="pi pi-sort-amount-up" />
                  )}
                  {sort?.col.key === col.key && (
                    <>
                      {sort?.asc ? (
                        <i className="pi pi-sort-amount-up active" />
                      ) : (
                        <i className="pi pi-sort-amount-down active" />
                      )}
                    </>
                  )}
                </SortIcons>
              )}
            </TableHead>
          ))}
        </HeaderRow>
        {sortedUsers &&
          sortedUsers.map((user, index) => (
            <UserRow
              key={user.id}
              user={user}
              cols={UserListColumns}
              even={index % 2 === 0}
            />
          ))}
      </UsersListWrapper>
    </Flex>
  );
};
