import { mutateTeams } from '../../mutations/mutateTeams';
import { organizationAPI } from 'api/phases/endpoints';
import { serialize } from 'object-to-formdata';

import { TeamActionModel } from './model';

export const updateTeam = async (params: TeamActionModel, teamId: number) => {
  const formData = serialize({ team: params });
  await organizationAPI.updateTeam(teamId, formData);

  await mutateTeams();
};
