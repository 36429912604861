import { UserPosition } from 'api/users/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';
import { sortByKey } from 'utils/sortByKey';

type PositionPayload = {
  positions: UserPosition[];
};

const API_URL = '/positions';

export const usePositions = () => {
  const { data, mutate } = useSWR<PositionPayload>(API_URL, fetcher);

  return {
    isLoading: !data,
    positions: data?.positions.sort(sortByKey('name')) || [],
    mutate,
  };
};
