import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper, BoxItem, TeamName, BoxItemSpecial } from '../styled';
import { Team } from 'api/issues/models';
import { Avatar, Spin } from 'components/ui';
import { useTeams } from 'fetchers/hooks/users/useTeams';
import { Box, Flex } from 'rebass';
import { theme } from 'styles/theme';

import { TeamModal } from './components/TeamModal';

export const TeamSettings = () => {
  const [editedTeam, setEditedTeam] = useState<Team | null>();
  const [teamModalOpen, setTeamModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { teams, isLoading } = useTeams();

  const lowercaseTeamNames = useMemo(
    () => teams?.map((t) => t.name.toLowerCase()) || [],
    [teams],
  );

  const addNewTeam = () => {
    setEditedTeam(null);
    setTeamModalOpen(true);
  };

  const editTeam = (team: Team) => {
    setEditedTeam(team);
    setTeamModalOpen(true);
  };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Wrapper>
            {teams.map((team) => {
              return (
                <BoxItem key={team.id} onClick={() => editTeam(team)}>
                  <Flex flexDirection="column" alignItems="center">
                    <Box>
                      {team.supervisor?.full_name && (
                        <Avatar
                          size="small"
                          src={team.supervisor?.photo?.medium.url || ''}
                          name={team.supervisor?.full_name}
                        />
                      )}
                    </Box>
                    <TeamName>{team.name}</TeamName>
                    {team.supervisor?.full_name ? (
                      <Box
                        fontSize={theme.fontSizes.xxs2}
                        mt={1}
                        color={theme.colors.newDarkGrey}
                      >
                        {team.supervisor.full_name}
                      </Box>
                    ) : (
                      <Box
                        mt={1}
                        color={theme.colors.neutralGray}
                        fontSize={theme.fontSizes.xxs}
                      >
                        {t('userProfile.noSupervisor')}
                      </Box>
                    )}
                  </Flex>
                </BoxItem>
              );
            })}
            <BoxItemSpecial onClick={addNewTeam} key="add-new-team">
              <Box color={theme.colors.newDarkGrey}>{`+ ${t(
                'users.addNewTeam',
              )}`}</Box>
            </BoxItemSpecial>
          </Wrapper>

          <TeamModal
            isOpen={teamModalOpen}
            setIsOpen={setTeamModalOpen}
            teamList={lowercaseTeamNames}
            team={editedTeam || null}
          />
        </>
      )}
    </>
  );
};
