import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { Cell, useFlexLayout, useSortBy, useTable } from 'react-table';

import { ProjectsSprints } from '../ProjectsSprints';
import { ProjectAvatar, ProjectSprint } from 'api/projects/models';
import { PAGES_PATH } from 'components/layout/PrivateRoute/components/MainLayout/hooks';
import { Variant } from 'components/layout/Tables/SimpleUsersTable';
import { sortAlphabetically } from 'components/layout/Tables/utils/sortAlphabetically';
import {
  Avatar,
  ProjectNameLink,
  TableToggleButton,
  TableMainCell,
  TableRow,
  TableHeader,
  Tooltip,
} from 'components/ui';
import { OVERFLOW } from 'components/ui/ScrollableContainer/styled';
import { Box, Flex } from 'rebass';
import {
  selectDashboardExpandedRows,
  ROW_PREFIXES,
  setExpandedRow,
} from 'store/slices/dashboardSlice';
import { theme } from 'styles/theme';

import { HeaderContent } from './HeaderContent';
import { TableItem, Wrapper } from './styled';

type Props = {
  data: ProjectSprint[];
};

export enum ACCESSORS {
  ABWH_FOR_CR = 'abwh_for_cr',
  ABWH_FOR_QA = 'abwh_for_qa',
  ACCEPTED_BY_QA = 'accepted_by_qa',
  BUGS = 'bugs',
  DELIVERED_TO_QA = 'delivered_to_qa',
  ESTIMATES = 'estimates',
  EXPANDER = 'expander',
  ID = 'id',
  PROJECT = 'project',
  REMAINING = 'remaining',
  SPENT_NON_EFFECTIVE = 'spent_not_effective',
  SPRINT = 'sprint',
  SPRINTS = 'sprints',
  TEAM_CAPACITY = 'team_capacity',
  TICKETS_REJECTION = 'tickets_rejection',
  USER = 'user',
  USERS = 'users',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  AVATAR = 'avatar',
}

export const Projects: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const expandedRows = useSelector(selectDashboardExpandedRows);
  const { t } = useTranslation();

  const tableColumns = useMemo(
    () => [
      {
        accessor: ACCESSORS.PROJECT,
        Cell: ({
          row: { original: row },
        }: Cell<{
          [ACCESSORS.ID]: number;
          [ACCESSORS.PROJECT]: string;
          [ACCESSORS.AVATAR]: ProjectAvatar;
        }>) => {
          const name = row[ACCESSORS.PROJECT];
          const id = row[ACCESSORS.ID];
          const avatar = row[ACCESSORS.AVATAR];

          return (
            <Flex alignItems="center">
              <Avatar name={name} src={avatar?.medium?.url || ''} />
              <Box ml={2}>
                <ProjectNameLink
                  href={`/projects/${id}`}
                  source={PAGES_PATH.DASHBOARD}
                >
                  {name}
                </ProjectNameLink>
              </Box>
            </Flex>
          );
        },
        Header: 'Project',
        id: ACCESSORS.PROJECT,
        width: 300,
        minWidth: 300,
        maxWidth: 300,
      },
      {
        accessor: ACCESSORS.TEAM_CAPACITY,
        Header: (
          <HeaderContent
            label={t('dashboard.teamCapacityInHrs')}
            tooltipContent={t('dashboard.teamCapacityTooltip')}
          />
        ),
        id: ACCESSORS.TEAM_CAPACITY,
        width: 200,
      },
      {
        accessor: ACCESSORS.ESTIMATES,
        Header: (
          <HeaderContent
            label={t('dashboard.estimatesInHrs')}
            tooltipContent={t('dashboard.estimatesTooltip')}
          />
        ),
        id: ACCESSORS.ESTIMATES,
        width: 200,
      },
      {
        accessor: ACCESSORS.SPENT_NON_EFFECTIVE,
        Header: (
          <HeaderContent
            label={t('dashboard.spentInHrs')}
            tooltipContent={t('dashboard.spentTooltip')}
          />
        ),
        id: ACCESSORS.SPENT_NON_EFFECTIVE,
        width: 220,
      },
      {
        accessor: ACCESSORS.REMAINING,
        Header: (
          <HeaderContent
            label={t('dashboard.remainingInHrs')}
            tooltipContent={t('dashboard.remainingTooltip')}
          />
        ),
        id: ACCESSORS.REMAINING,
        width: 200,
      },
      {
        accessor: ACCESSORS.DELIVERED_TO_QA,
        Header: (
          <HeaderContent
            label={t('dashboard.deliveredToQaInPercent')}
            tooltipContent={t('dashboard.deliveredToQaTooltip')}
          />
        ),
        id: ACCESSORS.DELIVERED_TO_QA,
        width: 190,
      },
      {
        accessor: ACCESSORS.ACCEPTED_BY_QA,
        Header: (
          <HeaderContent
            label={t('dashboard.acceptedByQaInPercent')}
            tooltipContent={t('dashboard.acceptedByQaTooltip')}
          />
        ),
        id: ACCESSORS.ACCEPTED_BY_QA,
        width: 180,
      },
      {
        accessor: ACCESSORS.ABWH_FOR_CR,
        Header: (
          <HeaderContent
            label={t('dashboard.abwhForCrInHrs')}
            tooltipContent={t('dashboard.abwhTooltip')}
          />
        ),
        id: ACCESSORS.ABWH_FOR_CR,
        width: 170,
      },
      {
        accessor: ACCESSORS.ABWH_FOR_QA,
        Header: (
          <HeaderContent
            label={t('dashboard.abwhForQaInHrs')}
            tooltipContent={t('dashboard.abwhForQaTooltip')}
          />
        ),
        id: ACCESSORS.ABWH_FOR_QA,
        width: 170,
      },
      {
        accessor: ACCESSORS.TICKETS_REJECTION,
        Header: (
          <HeaderContent
            label={t('dashboard.amountOfTicketsRejection')}
            tooltipContent={t('dashboard.amountOfTicketsRejectionTooltip')}
          />
        ),
        id: ACCESSORS.TICKETS_REJECTION,
        width: 210,
      },
      {
        accessor: ACCESSORS.BUGS,
        Header: (
          <HeaderContent
            label={t('dashboard.numberOfTicketsAddedAfterSprintStart')}
            tooltipContent={t(
              'dashboard.numberOfTicketsAddedAfterSprintStartTooltip',
            )}
          />
        ),
        id: ACCESSORS.BUGS,
        width: 235,
      },
      {
        accessor: ACCESSORS.SPRINTS,
        Header: '',
        id: ACCESSORS.SPRINTS,
        width: 32,
        maxWidth: 32,
        disableSortBy: true,
      },
    ],
    [],
  );
  const tableData = useMemo(
    () =>
      data.map(({ id, name, sprints, avatar }) => {
        const lastSprint = sprints[0];

        return {
          [ACCESSORS.ID]: id,
          [ACCESSORS.PROJECT]: name,
          [ACCESSORS.TEAM_CAPACITY]: lastSprint.team_capacity,
          [ACCESSORS.ESTIMATES]: lastSprint.estimation_sum,
          [ACCESSORS.SPENT_NON_EFFECTIVE]: `${lastSprint.spent_sum} (${lastSprint.non_effective_sum})`,
          [ACCESSORS.REMAINING]: lastSprint.remaining,
          [ACCESSORS.DELIVERED_TO_QA]: lastSprint.delivered_percentage,
          [ACCESSORS.ACCEPTED_BY_QA]: lastSprint.accepted_by_qa_percentage,
          [ACCESSORS.ABWH_FOR_CR]: lastSprint.average_cr_to_qa_time,
          [ACCESSORS.ABWH_FOR_QA]: lastSprint.average_qa_reaction_time,
          [ACCESSORS.TICKETS_REJECTION]: lastSprint.rejects_count,
          [ACCESSORS.BUGS]: `${lastSprint.added_after_start_count} (${lastSprint.added_bugs_after_start_count})`,
          [ACCESSORS.SPRINTS]: sprints,
          [ACCESSORS.AVATAR]: avatar,
        };
      }),
    [data],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      // @ts-ignore
      columns: tableColumns,
      data: tableData,
      initialState: {
        // @ts-ignore
        sortBy: [
          {
            desc: false,
            id: ACCESSORS.PROJECT,
          },
        ],
      },
      //@ts-ignore
      autoResetSortBy: false,
      autoResetExpanded: false,
      //@ts-ignore
      disableSortRemove: true,
      sortTypes: {
        alphanumeric: sortAlphabetically(),
      },
    },
    useSortBy,
    useFlexLayout,
  );

  const handleRowClick = useCallback(
    (rowId: string) => {
      dispatch(setExpandedRow(rowId));
    },
    [dispatch],
  );

  return (
    <Wrapper overflowDirection={OVERFLOW.Y}>
      <section {...getTableProps()}>
        <Flex
          width="100%"
          justifyContent="flex-end"
          sx={{ position: 'relative', zIndex: 999 }}
        >
          <Tooltip
            render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
              <Box
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                ref={triggerRef}
                sx={{
                  cursor: 'default',
                  position: 'sticky',
                  right: 0,
                  top: theme.dimensions.topBarHeight - 16,
                  zIndex: 999,
                }}
              >
                <AiOutlineQuestionCircle />
              </Box>
            )}
          >
            {t('dashboard.generalTooltip')}
          </Tooltip>
        </Flex>
        <TableHeader
          headerGroups={headerGroups}
          variant={Variant.GRAY_HEADER}
          headerColumnStyles={{
            flex: 1,
            width: '200px',
          }}
          lastCellShorter
        />
        <ul {...getTableBodyProps()}>
          {rows.map((row) => {
            const expanderId = `${ROW_PREFIXES.PROJECT}_${row.original.id}`;
            const isExpanded = !!expandedRows[expanderId];

            prepareRow(row);

            return (
              <TableItem
                {...row.getRowProps()}
                as="li"
                isExpanded={isExpanded}
                values={row.values}
              >
                <TableRow
                  isExpanded={isExpanded}
                  onClick={() => handleRowClick(expanderId)}
                >
                  {row.cells.map(({ column: { id }, getCellProps, render }) => (
                    <TableMainCell
                      {...getCellProps({
                        style: {
                          flex: '1',
                        },
                      })}
                      lastCellShorter
                    >
                      {id !== ACCESSORS.SPRINTS && render('Cell')}
                    </TableMainCell>
                  ))}
                  <TableToggleButton id={expanderId} isExpanded={isExpanded} />
                </TableRow>
                {isExpanded && (
                  <ProjectsSprints
                    data={row.values.sprints}
                    projectId={row.original.id}
                  />
                )}
              </TableItem>
            );
          })}
        </ul>
      </section>
    </Wrapper>
  );
};
