import React from 'react';

import { Box } from 'rebass';

import { StyledAvatar, Image, Initials, StyledTag } from './styled';

type Props = {
  name: string;
  image?: string;
  size?: 'big' | 'medium' | 'small';
  tagLabel?: string;
  radius?: string;
  bgColor?: string;
};

export const AvatarWithBackground = ({
  image,
  name,
  tagLabel,
  size = 'big',
  radius,
  bgColor,
}: Props) => {
  const [firstName, lastName] = name
    ? name.toUpperCase().split(' ')
    : ['N', 'N'];

  const getInitials = () => {
    if (firstName && lastName) {
      return firstName[0] + lastName[0];
    }

    return firstName[0];
  };

  const Tag = () => (
    <Box
      sx={{
        position: 'absolute',
        bottom: '4px',
      }}
    >
      <StyledTag>{tagLabel}</StyledTag>
    </Box>
  );

  if (!image) {
    return (
      <StyledAvatar size={size} radius={radius} bgColor={bgColor}>
        <Initials size={size}>{getInitials()}</Initials>
        {tagLabel && <Tag />}
      </StyledAvatar>
    );
  }

  return (
    <StyledAvatar size={size} radius={radius} bgColor={bgColor}>
      <Image src={image} radius={radius} size={size} />
      {tagLabel && <Tag />}
    </StyledAvatar>
  );
};
