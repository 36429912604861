import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type Props = {
  isInvalid?: boolean;
  append?: React.ReactNode;
  isIcon?: React.ReactNode;
};

export const Label = styled.span<Props>`
  left: ${({ isIcon }) => (isIcon ? '25px' : '0')};
`;

export const Input = styled.input<Props>`
  height: ${theme.sizes.md};
  padding-left: ${theme.space[2]};
  padding-right: ${({ append }) => (append ? '55px' : theme.space[2])};
  border-radius: ${theme.radii.md};
  background-color: ${theme.colors.whiteLilac};
  border-width: 1px;
  border-style: solid;
  font-family: ${theme.fonts.regular};
  border-color: ${({ isInvalid }) =>
    isInvalid ? theme.colors.danger : theme.colors.neutral};
  font-size: ${theme.fontSizes.sm};
  width: 100%;
  transition: box-shadow 150ms;

  &:focus {
    outline: none;
    box-shadow: ${theme.shadows.outline};
  }

  &::placeholder {
    color: ${theme.colors.darkGray};
    opacity: 0.5;
  }
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
`;

export const InputRightElement = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  display: flex;
  align-items: center;
  height: calc(${theme.sizes.md} - 2px);
  padding: 0 ${theme.space[2]};
  border-radius: ${theme.radii.md};
  background-color: ${theme.colors.whiteLilac};
  border: 1px solid transparent;
  padding: 0 ${theme.space[1]};
  color: ${theme.colors.darkGray};
  opacity: 0.5;
  user-select: none;
`;

export const FieldIcon = styled.div`
  position: absolute;
  left: 0;
  top: -5px;
  width: 2rem;
  height: 2rem;
`;
