import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from 'App';
import { ThemeProvider } from 'emotion-theming';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import store from 'store';
import { GlobalStyles } from 'styles/globalStyles';
import { theme } from 'styles/theme';
import 'utils/i18n';

var container = document.getElementById('root');

if (!container) throw new Error('No container found');

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <App />
    </ThemeProvider>
  </Provider>,
);
