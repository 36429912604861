import React from 'react';

import { CheckboxWrapper, CheckboxPrime, CheckboxLabel } from '../styled';
import { useField } from 'formik';
import { CheckboxProps } from 'primereact/checkbox';

export type PrimeEvent = {
  originalEvent: Event;
  value: string;
  checked: boolean;
  target: {
    type: string;
    name: string;
    id: string;
    value: string;
    checked: boolean;
  };
};

type Props = {
  name: string;
  value: string;
  checked: boolean;
  onChange: (event: PrimeEvent) => void;
} & CheckboxProps;

export const FormikCheckbox = ({
  value,
  name,
  checked,
  onChange,
  ...restProps
}: Props) => {
  const [field] = useField(name);

  return (
    <CheckboxWrapper>
      <CheckboxPrime
        {...field}
        name={name}
        inputId={value}
        value={value}
        onChange={onChange}
        checked={checked}
        {...restProps}
      ></CheckboxPrime>
      <CheckboxLabel htmlFor={value} className="p-checkbox-label">
        {value}
      </CheckboxLabel>
    </CheckboxWrapper>
  );
};
