import { SetNewPasswordProps } from '../../api/auth/models';
import { displaySnackbar } from '../../store/slices/snackbarSlice';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from 'api/auth/endpoints';
import { usersAPI } from 'api/users/endpoints';
import { SignInWithTFAPayload, UserLoginData } from 'api/users/models';
import { AUTH_HEADER_KEYS } from 'screens/Auth/constants';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';
import { initialSortKey } from 'utils/constants';
import { getRedirectRoute } from 'utils/functions';
import { history } from 'utils/history';
import i18n from 'utils/i18n';
import { getFromStorage, saveToStorage } from 'utils/localStorage';

import { LoginErrors } from './screens/Login';

type AuthState = {
  error: string | null;
  isLoading: boolean;
  loggedIn: boolean;
  route: string;
  user: UserLoginData | null;
  userEmail: string;
  isRestPasswordEmailSent: boolean;
  is2FACodeValid: boolean;
};

type AuthHeadersKeys = {
  [key: string]: string;
};

interface signInParams {
  headers?: AuthHeadersKeys;
  credentials?: {
    email: string;
    password: string;
  };
  errors: LoginErrors;
}

const INITIAL_STATE: AuthState = {
  error: null,
  isLoading: true,
  loggedIn: false,
  route: '',
  user: null,
  userEmail: '',
  isRestPasswordEmailSent: false,
  is2FACodeValid: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    loadingStart(state): void {
      state.isLoading = true;
    },
    signInSuccess(
      state,
      action: PayloadAction<{ route: string; user: UserLoginData }>,
    ): void {
      state.error = null;
      state.isLoading = false;
      state.loggedIn = true;
      state.route = action.payload.route;
      state.user = action.payload.user;
    },
    signInFail(state, action: PayloadAction<string>): void {
      state.error = action.payload;
      state.loggedIn = false;
      state.user = null;
      state.isLoading = false;
    },
    signInInProgress(state, action: PayloadAction<string>): void {
      state.error = null;
      state.isLoading = false;
      state.loggedIn = false;
      state.userEmail = action.payload;
    },
    signInWithTFASuccess(
      state,
      action: PayloadAction<{ route: string; user: UserLoginData }>,
    ): void {
      state.error = null;
      state.is2FACodeValid = true;
      state.loggedIn = true;
      state.route = action.payload.route;
      state.user = action.payload.user;
      state.isLoading = false;
    },
    signInWithTFAFail(state, action: PayloadAction<string>): void {
      state.is2FACodeValid = false;
      state.error = action.payload;
      state.loggedIn = false;
      state.user = null;
      state.isLoading = false;
    },
    requestResetPasswordEmailSuccess(state): void {
      state.isRestPasswordEmailSent = true;
      state.isLoading = false;
    },
    requestRestPasswordEmailFail(state): void {
      state.isLoading = false;
      state.isRestPasswordEmailSent = false;
    },
    getUserDetailsStart(state): void {
      state.isLoading = true;
    },
    getUserDetailsSuccess(
      state,
      action: PayloadAction<{ route: string; user: UserLoginData }>,
    ): void {
      state.isLoading = false;
      state.loggedIn = true;
      state.route = action.payload.route;
      state.user = action.payload.user;
    },
    getUserDetailsFail(state): void {
      state.route = INITIAL_STATE.route;
      state.user = null;
      state.isLoading = false;
    },
    logout(state): void {
      state.loggedIn = false;
      state.user = null;
    },
    setRoute(state, action: PayloadAction<string>) {
      state.route = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
  },
});

const {
  setRoute,
  loadingStart,
  signInSuccess,
  signInFail,
  signInInProgress,
  getUserDetailsFail,
  getUserDetailsStart,
  getUserDetailsSuccess,
  logout,
  clearError,
  setLoading,
  requestResetPasswordEmailSuccess,
  requestRestPasswordEmailFail,
  signInWithTFASuccess,
  signInWithTFAFail,
} = authSlice.actions;
export default authSlice.reducer;

export const logoutAction = (): AppThunk => (dispatch) => {
  localStorage.clear();

  dispatch(setRoute('/auth/login'));
  dispatch(logout());
};

const mapAuthHeaders = (headers: AuthHeadersKeys) => {
  AUTH_HEADER_KEYS.forEach((key) => {
    saveToStorage(key, headers[key]);
  });
};

export const signIn = ({
  headers,
  credentials,
  errors,
}: signInParams): AppThunk => async (dispatch) => {
  saveToStorage(initialSortKey, '');
  dispatch(loadingStart());

  let user = null;

  try {
    if (credentials) {
      const { data, headers: authHeaders } = await authApi.signInWithEmail(
        credentials,
      );

      user = data.user;
      mapAuthHeaders(authHeaders);
    }

    if (headers) {
      mapAuthHeaders(headers);
      const { data } = await usersAPI.getMe();
      user = data.user;
    }

    const route = getRedirectRoute(user);

    saveToStorage('route', route);
    saveToStorage('permissions', user.permissions);
    saveToStorage('roles', user.roles);

    dispatch(signInSuccess({ route, user }));
  } catch (error) {
    if (error?.status === 401) {
      const errorKey =
        Object.keys(errors).find(
          (key) => errors[key as keyof LoginErrors] === error?.data?.message,
        ) ?? ('credentials' as keyof LoginErrors);

      dispatch(signInFail(errors[errorKey as keyof LoginErrors]));
    } else if (error?.status === 403) {
      if (credentials) {
        dispatch(signInInProgress(credentials?.email));
        history.push('login/2fa');
      }
    } else {
      dispatch(signInFail(errors.commonError));
    }
  }
};

export const signInWithTFA = (
  tfaData: SignInWithTFAPayload,
): AppThunk => async (dispatch) => {
  dispatch(loadingStart());
  try {
    const { data, headers } = await authApi.signInWithTFA(tfaData);

    const user = data.user;
    mapAuthHeaders(headers);

    const route = getRedirectRoute(user);

    dispatch(signInWithTFASuccess({ route, user }));

    saveToStorage('route', route);
    saveToStorage('permissions', user.permissions);
    saveToStorage('roles', user.roles);

    history.push(route);
  } catch (error) {
    dispatch(signInWithTFAFail(error?.message || i18n.t('tfaInvalidCode')));
    history.push('/');
  }
};

export const requestRestPasswordEmail = (email: string): AppThunk => async (
  dispatch,
) => {
  dispatch(loadingStart());
  try {
    await authApi.requestRestPasswordEmail(email);

    dispatch(requestResetPasswordEmailSuccess());
  } catch {
    dispatch(requestRestPasswordEmailFail());
  }
};

export const setNewPassword = (
  payload: SetNewPasswordProps,
): AppThunk => async (dispatch) => {
  try {
    await authApi.setNewPassword(payload);

    history.push('/auth/login');
    dispatch(
      displaySnackbar({
        message: i18n.t('login.passwordChanged'),
        isError: false,
      }),
    );
  } catch {
    dispatch(
      displaySnackbar({
        message: i18n.t('login.passwordNotChanged'),
        isError: true,
      }),
    );
  }
};

export const clearLoginError = (): AppThunk => async (dispatch) => {
  dispatch(clearError());
};

export const setLoginLoading = (loading: boolean): AppThunk => async (
  dispatch,
) => {
  dispatch(setLoading(loading));
};

export const getUserDetails = (): AppThunk => async (dispatch) => {
  dispatch(getUserDetailsStart());

  try {
    const {
      data: { user },
    } = await usersAPI.getMe();
    const route = getRedirectRoute(user);

    saveToStorage('route', route);
    saveToStorage('permissions', user.permissions);
    dispatch(getUserDetailsSuccess({ route, user }));
  } catch (error) {
    dispatch(getUserDetailsFail());
  }
};

export const selectAuthData = (state: RootState) => state.auth;

export const selectUserPermissions = createSelector(
  selectAuthData,
  ({ user }) => getFromStorage('permissions') || user?.permissions,
);

export const selectUserLoggedIn = createSelector(
  selectAuthData,
  ({ loggedIn }) => loggedIn,
);

export const selectRedirectRoute = createSelector(selectAuthData, (data) => {
  const { route } = data;
  const savedRoute = getFromStorage('route');

  if (!route && !savedRoute) {
    return '/auth/login';
  }

  return savedRoute || route;
});

export const selectAuthUser = createSelector(
  selectAuthData,
  ({ user }) => user,
);

export const selectAuthUserName = createSelector(
  selectAuthUser,
  (authUser) => authUser?.full_name,
);

export const selectAuthUserSignInCount = createSelector(
  selectAuthUser,
  (user) => user?.sign_in_count,
);

export const selectIsSelf = createSelector(
  selectAuthData,
  (_: RootState, userId?: number | string) => userId,
  ({ user }, userId) => user?.id === Number(userId),
);

export const selectIsAuthUserLoading = createSelector(
  selectAuthData,
  ({ isLoading }) => isLoading,
);

export const selectIsRequestRestPasswordSuccess = createSelector(
  selectAuthData,
  (state) => state.isRestPasswordEmailSent,
);

export const selectIs2FACodeValid = createSelector(
  selectAuthData,
  ({ is2FACodeValid }) => is2FACodeValid,
);

export const selectUserEmail = createSelector(
  selectAuthData,
  ({ userEmail }) => userEmail,
);
