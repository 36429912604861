import { FieldError } from 'react-hook-form';

import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type Props = {
  error?: FieldError | null;
};

export const FieldLabel = styled.label<Props>`
  & > span {
    display: block;
    text-transform: uppercase;
    font-size: ${theme.fontSizes.xxs};
    color: ${({ error }) =>
      error?.message ? theme.colors.danger : theme.colors.neutralDark};
    font-family: ${theme.fonts.bold};
    margin: 0 0 ${theme.space[2]} 0;
    position: relative;
  }
`;
