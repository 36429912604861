import React from 'react';
import { RiAddFill } from 'react-icons/all';

import { StyledAddButton } from './styled';

type Props = {
  onClick: () => void;
};

export const AddNewButton = ({ onClick }: Props) => {
  return (
    <StyledAddButton onClick={onClick}>
      <RiAddFill />
    </StyledAddButton>
  );
};
