import styled from '@emotion/styled/macro';
import { Card } from 'components/primitive';
import { Box, Flex } from 'rebass';
import { theme } from 'styles/theme';

interface Props {
  active?: boolean;
}
interface GridWrapperProps {
  gridGap: string;
  margin?: boolean;
}

export const Wrapper = styled(Flex)`
  overflow: auto;
  margin: 0 auto;
  padding-top: ${theme.space[5]};
  width: 1100px;
  position: relative;
`;

export const SectionHeader = styled.h3`
  margin: ${theme.space[4]} 0;
  font-size: ${theme.fontSizes.xmd};
  font-family: ${theme.fonts.bold};
  border-bottom: 1px solid ${theme.colors.neutral};
`;

export const MenuHeader = styled(SectionHeader)`
  margin-bottom: ${theme.space[6]};
  font-size: ${theme.fontSizes.md};
  color: ${theme.colors.newDarkGrey};
  border-bottom: none;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
  }

  &:last-of-type:hover {
    color: ${theme.colors.newDarkGrey};
    cursor: initial;
  }
`;

export const MenuWrapper = styled(Flex)`
  padding-right: ${theme.space[6]};
  position: sticky;
  top: 0;
  left: 0;
`;

export const IntegrationHeader = styled.div`
  font-size: ${theme.fontSizes.md};
  font-family: ${theme.fonts.bold};
`;

export const Integration = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${theme.space[2]};
  padding: ${theme.space[3]} 0};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.colors.neutral};
  }
`;

export const IntegrationInner = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`;

export const SubheaderWrapper = styled.div<Props>`
  margin-bottom: ${theme.space[6]};
  opacity: ${({ active }) => (active ? '1' : '0.3')};
  color: ${theme.colors.midGray};
  font-size: ${theme.fontSizes.xs};
  display: flex;
  align-items: end;
`;

export const GridWrapper = styled.div<GridWrapperProps>`
  margin: ${({ margin }) => (margin ? `${theme.space[4]} 0` : '0')};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ gridGap }) => gridGap};
`;

export const IntegratedInfo = styled.div`
  color: ${theme.colors.midGray};
  font-size: ${theme.fontSizes.xs};
  padding: ${theme.space[5]} 0;
`;

export const StyledCard = styled(Card)<Props>`
  margin-bottom: ${theme.space[8]};
  padding: ${({ active }) =>
    active
      ? `${theme.space[3]} ${theme.space[6]} ${theme.space[1]}`
      : theme.space[6]};
`;

export const Icon = styled.i`
  font-size: ${theme.fontSizes.xs};
  cursor: pointer;
  margin-left: ${theme.space[3]};
  padding: ${theme.space[2]};
`;
