import { useMemo } from 'react';

import { useWeeks } from './useWeeks';

export type User = {
  hoursOff: number;
  hoursWeekly: number;
  notVerifiedUserHours: number;
  spentHours: string;
  userHour: {
    id: number;
    value: number;
  };
  userId: number;
  verifiedUserHours: number;
  weekNumber: number;
};

type ProjectsWeeksData =
  | {
      [weekNumber: number]: {
        [projectId: number]: {
          [phaseId: number]: {
            endDate?: string;
            notVerifiedUserHours: number;
            startDate?: string;
            users: {
              [userId: number]: User;
            };
            verifiedUserHours: number;
            year?: string;
          };
        };
      };
    }
  | undefined;

export const useProjectsWeeks = () => {
  const { isLoading, projectsWeeks, usersWeeks } = useWeeks();

  const weeks = useMemo<ProjectsWeeksData>(() => {
    return projectsWeeks.reduce(
      (weeksAcc, { projects, week }) => ({
        ...weeksAcc,
        [week]: projects.reduce(
          (projectsAcc, { id: projectId, phases }) => ({
            ...projectsAcc,
            [projectId]: phases?.reduce(
              (
                phasesAcc,
                {
                  id: phaseId,
                  not_verified_user_hours: projectNotVerifiedUserHours,
                  users,
                  verified_user_hours: projectVerifiedUserHours,
                },
              ) => {
                return {
                  ...phasesAcc,
                  [phaseId]: {
                    notVerifiedUserHours: projectNotVerifiedUserHours,
                    phaseId,
                    users: users?.reduce(
                      (
                        usersAcc,
                        {
                          id: userId,
                          not_verified_user_hours: userNotVerifiedUserHours,
                          phase_user_hour: userHour,
                          spent_hours: userSpentHours,
                          verified_user_hours: userVerifiedUserHours,
                        },
                      ) => {
                        const user = usersWeeks
                          .find(
                            ({ week: userWeek }) => userWeek === Number(week),
                          )
                          ?.users.find(({ id }) => id === userId);

                        return {
                          ...usersAcc,
                          [userId]: {
                            hoursOff: user?.hours_off,
                            hoursWeekly: user?.hours_weekly,
                            notVerifiedUserHours: userNotVerifiedUserHours,
                            spentHours: userSpentHours,
                            userHour,
                            userId,
                            verifiedUserHours: userVerifiedUserHours,
                          },
                        };
                      },
                      {},
                    ),
                    verifiedUserHours: projectVerifiedUserHours,
                  },
                };
              },
              {},
            ),
          }),
          {},
        ),
      }),
      {},
    );
  }, [projectsWeeks, usersWeeks]);

  return {
    isLoading,
    weeks: weeks as ProjectsWeeksData,
  };
};
