import { ProjectType } from 'api/users/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';
import { sortByKey } from 'utils/sortByKey';

export type ProjectTypesPayload = {
  project_types: ProjectType[];
};

const API_URL = '/project_types';

export const useProjectTypes = () => {
  const { data } = useSWR<ProjectTypesPayload>(API_URL, fetcher);

  return {
    isLoading: !data,
    projectTypes: data?.project_types.sort(sortByKey('name')) || [],
  };
};
