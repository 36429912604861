import { ProjectDevStactItem } from 'api/projects/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';

export type ToggleVisibility = {
  phaseId: number;
  userId: number;
};

const API_URL = '/dev_stacks/';

export const useDevStack = () => {
  const { data, revalidate } = useSWR<{ dev_stacks: ProjectDevStactItem[] }>(
    API_URL,
    fetcher,
  );

  return {
    isLoading: !data,
    devStack: data?.dev_stacks,
    revalidate,
  };
};
