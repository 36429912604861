import React, { useState } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import { MemberData } from '../MemberData';
import { PhaseUserRange } from 'api/projects/models';
import { User } from 'api/users/models';
import { ChevronBox, TableHeading } from 'components/layout/Tables/styled';
import { ChevronIcon } from 'components/primitive';
import { Tooltip } from 'components/ui/Tooltip';
import { Box, Heading } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  isMultiWeeks: boolean;
  row: any;
  users: Array<User & { phase_user_range: PhaseUserRange }>;
};

export const HiddenMembers: React.FC<Props> = ({
  isMultiWeeks,
  row,
  users,
  ...rowProps
}) => {
  const [isExpanded, setExpanded] = useState(false);

  if (!users || !users.length) {
    return null;
  }

  const titleCopy = `Hidden members (${users.length})`;

  const handleClick = () => {
    setExpanded(!isExpanded);
  };

  const handleInfoClick = (event: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
  };

  return (
    <>
      <TableHeading
        {...rowProps}
        as="tr"
        isExpanded={isExpanded}
        isMultiWeeks={isMultiWeeks}
        onClick={handleClick}
        marginRight={isMultiWeeks ? '8px' : 0}
      >
        <Box as="td" width="100%">
          <Box
            sx={{
              width: '100%',
              height: '1px',
              position: 'absolute',
              top: '-1px',
              left: 0,
              boxShadow: theme.shadows.md40,
              pointerEvents: 'none',
            }}
          />
          <Heading
            as="h3"
            color={theme.colors.midGray}
            fontFamily={theme.fonts.bold}
            fontSize={[theme.fontSizes.xxs]}
            sx={{
              display: 'flex',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              userSelect: 'none',
            }}
          >
            {titleCopy}
            <Tooltip
              render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
                <Box
                  marginLeft="6px"
                  onClick={handleInfoClick}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  ref={triggerRef}
                  sx={{
                    cursor: 'default',
                  }}
                >
                  <AiOutlineQuestionCircle />
                </Box>
              )}
            >
              Editing hours for hidden team members is disabled. Hours of hidden
              users are not summing up to total number of hours on a project
              phase.
            </Tooltip>
          </Heading>
          <ChevronBox topPosition="-1px">
            <ChevronIcon open={isExpanded} />
          </ChevronBox>
        </Box>
      </TableHeading>
      {isExpanded && (
        <Box
          {...rowProps}
          as="tr"
          marginBottom="12px"
          sx={{
            borderBottomLeftRadius: isMultiWeeks ? 0 : theme.radii.md,
            borderBottomRightRadius: isMultiWeeks ? 0 : theme.radii.md,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Box as="td" padding={0} width="100%">
            {users.map((user) => (
              <MemberData
                key={user.id}
                isMultiWeeks={isMultiWeeks}
                phaseUserRange={user.phase_user_range}
                row={row}
                // @ts-ignore
                user={user}
              />
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
