import httpClient from '../utils/httpClient';

import { API_NAMES_TO_KEY } from './hooks/useWeeks';

type FetcherArg = {
  [settingName: string]: any;
};

export const fetcher = (url: string) =>
  httpClient.get(url).then((res) => res.data);

export const fetcherWithArgs = (url: string, args: FetcherArg[]) => {
  const params = args.reduce((accumulator, currentValue) => {
    const [optionName] = Object.keys(currentValue);

    return {
      ...accumulator,
      [optionName]: currentValue[optionName],
    };
  }, {});

  return httpClient.get(url, { params }).then((res) => res.data);
};

export const fetcherWithWeeks = (url: string, weeks: number[]) => {
  return httpClient.get(url, { params: { weeks } }).then((res) => res.data);
};

export const multipleFetcherWithWeeks = (urls: string[], weeks: number[]) => {
  const updatedUrls = [...urls];
  updatedUrls.pop();

  return Promise.all(
    updatedUrls.map((url) => {
      return httpClient.get(url, { params: { weeks } }).then((res) => res.data);
    }),
  ).then((apiData) =>
    Object.values(API_NAMES_TO_KEY).reduce(
      (accumulator, currentValue, currentIndex) => ({
        ...accumulator,
        [currentValue]: apiData[currentIndex].weeks,
      }),
      {},
    ),
  );
};

export const getArgsAsString = (args: FetcherArg[]): string =>
  args.map((arg) => JSON.stringify(arg)).join(', ');
