import { AxiosResponse } from 'axios';
import { serialize } from 'object-to-formdata';
import httpClient from 'utils/httpClient';

import { NotificationsPeriods, NotificationsTypes } from './models';

export type NotificationResolvedPayload = {
  resolved: boolean;
};

export type NotificationsApiPayload = {
  startDate?: string;
  endDate?: string;
  periods?: NotificationsPeriods[];
  alertTypes?: NotificationsTypes[];
  teamsIds?: number[];
  userIds?: number[];
  isResolved?: boolean;
  page?: number;
};

export const notificationsAPI = {
  async getNotifications({
    startDate,
    endDate,
    periods,
    alertTypes,
    isResolved,
    page = 1,
  }: NotificationsApiPayload) {
    const selectedPage = `?page=${page}`;
    const startDateQuery = startDate ? `&start_date=${startDate}` : '';
    const endDateQuery = endDate ? `&end_date=${endDate}` : '';
    const periodsQuery = !!periods?.length
      ? `&${periods.map((period) => `periods[]=${period}`).join('&')}`
      : '';
    const alertTypesQuery = !!alertTypes?.length
      ? `&${alertTypes
          .map((alertType) => `alert_types[]=${alertType}`)
          .join('&')}`
      : '';
    const isResolvedQuery = `&is_resolved=${isResolved}`;

    return await httpClient.get(
      `/notifications${selectedPage}${startDateQuery}${endDateQuery}${periodsQuery}${alertTypesQuery}${isResolvedQuery}`,
    );
  },

  async updateNotification(
    id: number,
    notification: NotificationResolvedPayload,
  ): Promise<AxiosResponse> {
    return await httpClient.put(`/notifications/${id}`, notification);
  },

  async bulkUpdateNotification(
    ids: number[],
    resolved: boolean,
  ): Promise<AxiosResponse> {
    const formData = serialize({
      notifications: {
        ids,
        resolved,
      },
    });
    return await httpClient.patch(`/bulk/notifications`, formData);
  },
};
