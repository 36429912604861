import styled from '@emotion/styled/macro';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { theme } from 'styles/theme';

import { DropdownCustomProps } from './Select';

const commonDropdownStyle = (props: DropdownCustomProps, inputName: string) => {
  const { variant, isHigh } = props;
  const isFilled = variant === 'filled';
  const isBlue = variant === 'blue';
  return `
  width: 100%;
  font-family: ${theme.fonts.regular};
  background-color: ${isFilled ? theme.colors.whiteLilac : theme.colors.white};
  border-radius: ${isFilled ? theme.radii.md : theme.radii.sm};
  border-color: ${isFilled ? theme.colors.neutral : theme.colors.neutralGray};
  ${isHigh ? `height: ${theme.dimensions.inputHeight};` : ''}
  &.p-${inputName}:not(p-disabled).p-focus {
    box-shadow: ${theme.shadows.outline};
    border-color: $ ${
      isFilled ? theme.colors.neutral : theme.colors.neutralGray
    };
  }
  & .p-${inputName}-label,
  .p-placeholder {
    font-size: ${isFilled ? theme.fontSizes.sm : theme.fontSizes.xs};
    line-height: 20px;
    font-family: ${theme.fonts.regular};
    ${isHigh ? 'align-items: center; display: flex;' : ''}
  }
  & .p-${inputName}-label {
    .p-${inputName}-token {
      background: ${theme.colors.primaryDark};
      color: ${theme.colors.white};
    }
  }
  & .p-${inputName}-trigger {
    background-color: ${isBlue ? theme.colors.primary : 'transparent'};
    & .p-${inputName}-trigger-icon {
      color: ${isBlue ? theme.colors.white : theme.colors.midGray};
      &, .p-${inputName}-clear-icon {
        font-size: ${theme.fontSizes.xs};
      }
    }
  }

 & .p-${inputName}-items .p-${inputName}-item {
  font-size: ${isFilled ? theme.fontSizes.sm : theme.fontSizes.xs};
 }

  & .p-${inputName}-clear-icon {
    cursor: pointer;
    margin-right: ${isBlue ? theme.space[2] : 0}
  }
  & .p-${inputName}-item span {
    font-size: ${theme.fontSizes.xs};
  }
  & .p-${inputName}-label-container {
    display: flex;
    align-items: center;
    padding-left: 5px;
  }
  & .custom-${inputName}-items-label {
    color: ${theme.colors.placeholder};
    font-size: ${theme.fontSizes.sm};
  }
  
  & .p-${inputName}-item {
    font-size: ${theme.fontSizes.xs};
  }
  
   & .p-autocomplete-dropdown {
    color: ${theme.colors.newDarkGrey};
    background: ${theme.colors.white};
    border: none;
    
    &:hover {
      color: ${theme.colors.newDarkGrey};
      background: ${theme.colors.white};
    }
  }
  
  &.p-autocomplete {
    border: 1px solid ${theme.colors.neutralGray};
    transition: border-color 150ms;
    border-radius: ${theme.radii.sm};
    
    &:hover {
      border-color: ${theme.colors.newBlueInputHover};
    }
  
    input {
      border: none;
      font-size: ${theme.fontSizes.xs};
      
      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }
    }
    
    button {
      box-shadow: none;
      .pi:before {
        color: ${theme.colors.midGray};
        font-size: ${theme.fontSizes.xs};
      }
    }
    
    &.p-inputwrapper-focus {
      box-shadow: ${theme.shadows.outline}
    }
    
    &.p-invalid {
      border: 1px solid ${theme.colors.danger};
    }
  }
`;
};

export const StyledSelect = styled(Dropdown)((props: DropdownCustomProps) =>
  commonDropdownStyle(props, 'dropdown'),
);

export const StyledAutocomplete = styled(
  AutoComplete,
)((props: DropdownCustomProps) => commonDropdownStyle(props, 'autocomplete'));

export const Wrapper = styled.div`
  position: relative;

  & .pi.clear {
    position: absolute;
    right: 36px;
    top: 58%;
    color: ${theme.colors.newDarkGrey};
    font-size: ${theme.fontSizes.xxs2};
    cursor: pointer;
  }
`;

export const Label = styled.span<DropdownCustomProps>`
  left: ${({ isIcon }) => (isIcon ? '25px' : '0')};
  display: block;
  text-transform: uppercase;
  font-size: ${theme.fontSizes.xxs};
  color: ${({ iserror }) =>
    iserror ? theme.colors.danger : theme.colors.neutralDark};
  font-family: ${theme.fonts.bold};
  margin: 0 0 ${theme.space[2]} 0;
  position: relative;
`;

export const FieldIcon = styled.div<DropdownCustomProps>`
  position: absolute;
  left: 0;
  top: -5px;
  width: 2rem;
  height: 2rem;
  color: ${({ iserror }) =>
    iserror ? theme.colors.danger : theme.colors.darkGray};
`;
