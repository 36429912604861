import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  selectRedirectRoute,
  selectUserLoggedIn,
} from 'screens/Auth/authSlice';

import Login from './screens/Login';
import LoginWith2FA from './screens/LoginWith2FA';
import RequestResetPassword from './screens/RequestResetPassword';
import SetPassword from './screens/SetPassword';

const Auth: React.FC = () => {
  const isLoggedIn = useSelector(selectUserLoggedIn);
  const redirectRoute = useSelector(selectRedirectRoute);

  return (
    <Switch>
      <Redirect
        exact
        from={isLoggedIn ? '/auth/login' : '/auth'}
        to={redirectRoute}
      />
      <Route exact path="/auth/login">
        <Login />
      </Route>
      <Route path="/auth/login/2fa">
        <LoginWith2FA />
      </Route>
      <Route path="/auth/request-reset-password">
        <RequestResetPassword />
      </Route>
      <Route path="/auth/set-password">
        <SetPassword />
      </Route>
    </Switch>
  );
};

export default Auth;
