import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

export const Wrapper = styled(Flex)`
  overflow: auto;
  flex-wrap: wrap;
  width: 100%;
`;

export const Icon = styled(Flex)`
  & i {
    cursor: pointer;
    font-size: ${theme.fontSizes.xs};
    margin-left: ${theme.space[1]};
  }
`;

export const BoxItem = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 100px;
  border: 1px solid ${theme.colors.neutralGray};
  margin: ${theme.space[2]};
  padding: ${theme.space[4]};
  border-radius: ${theme.radii.sm};
  cursor: pointer;
`;

export const BoxItemSpecial = styled(BoxItem)`
  border: 1px dashed ${theme.colors.neutralGray};
`;

export const TeamName = styled.span`
  font-family: ${theme.fonts.medium};
  font-size: ${theme.fontSizes.xs};
  color: ${theme.colors.newDarkGrey};
  margin-top: ${theme.space[2]};
  text-align: center;
`;
