import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { closeSnackbar } from 'store/slices/snackbarSlice';

import { SnackbarWrapper } from './styled';

export const Snackbar: React.FC = () => {
  const dispatch = useDispatch();
  const { message, visible, isError } = useSelector(
    (state: RootState) => state.snackbar,
  );

  useEffect(() => {
    let timeout: null | ReturnType<typeof setTimeout> = null;
    if (visible) {
      timeout = setTimeout(() => {
        dispatch(closeSnackbar());
      }, 3000);
    }

    return function cleanup() {
      timeout && clearTimeout(timeout);
    };
  }, [dispatch, visible]);

  if (visible) {
    return <SnackbarWrapper isError={isError}>{message}</SnackbarWrapper>;
  }

  return null;
};
