import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { usersAPI } from 'api/users/endpoints';
import { UserSkill } from 'api/users/models';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';

type SkillsState = {
  skills: UserSkill[];
  isLoading: boolean;
  error: string | null;
};

const INITIAL_STATE: SkillsState = {
  skills: [],
  isLoading: false,
  error: null,
};

const skillsSlice = createSlice({
  name: 'skills',
  initialState: INITIAL_STATE,
  reducers: {
    getSkillsStart(state): void {
      state.isLoading = true;
    },
    getSkillsSuccess(state, action: PayloadAction<UserSkill[]>): void {
      state.error = null;
      state.skills = action.payload;
      state.isLoading = false;
    },
    getSkillsFail(state, action: PayloadAction<string>): void {
      state.error = action.payload;
      state.skills = [];
      state.isLoading = false;
    },
  },
});

const { getSkillsStart, getSkillsSuccess, getSkillsFail } = skillsSlice.actions;

export default skillsSlice.reducer;

export const getSkills = (): AppThunk => async (dispatch) => {
  dispatch(getSkillsStart());

  try {
    const {
      data: { skill_names },
    } = await usersAPI.getSkills();

    dispatch(getSkillsSuccess(skill_names));
  } catch (error) {
    dispatch(getSkillsFail(error?.message || 'Failed fetching skills'));
  }
};

export const selectSkills = createSelector(
  (state: RootState) => state.skills,
  ({ skills }) => skills,
);
