import { fetcher } from '../fetcher';
import { User } from 'api/users/models';
import useSWR from 'swr';

interface UsersData {
  users: User[];
}

const API_URL = '/users?responsible=true';

export const usePersonsResponsible = () => {
  const { data } = useSWR<UsersData>(API_URL, fetcher);

  const users = data?.users.map(({ id, email, full_name, photo }) => ({
    id,
    email,
    full_name,
    photo,
  }));

  return {
    isLoading: !data,
    personsResponsible: users,
  };
};
