import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkmark } from '.';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { useFormikContext } from 'formik';
import { SetPasswordFormFields } from 'screens/Auth/components/SetPassword/FormFields';
import * as Yup from 'yup';

import { CheckmarkContainer } from './styled';

export interface ChangePasswordFieldsProps {}

export type Requirement = {
  name: string;
  conditionString: string;
};

export const ChangePasswordFields: React.FC<ChangePasswordFieldsProps> = ({}) => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const formik = useFormikContext<{ password?: string }>();
  const { t } = useTranslation();
  const passwordValidate = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .optional()
          .min(8, t('errors.password.min'))
          .max(20, t('errors.password.max'))
          .matches(/[A-Z]/, t('errors.password.missingUppercase'))
          .matches(/[a-z]/, t('errors.password.missingLowercase'))
          .matches(/[0-9]/, t('errors.password.missingNumber'))
          .matches(
            /[!@#$%^&*()\-_=+{}[\]'":;\\|?/.>,<`~]/,
            t('errors.password.missingSpecialCharacter'),
          ),
      }),
    [],
  );

  const requirements: Requirement[] = [
    {
      name: t('passwordRequirements.lowercase'),
      conditionString: t('errors.password.missingLowercase'),
    },
    {
      name: t('passwordRequirements.uppercase'),
      conditionString: t('errors.password.missingUppercase'),
    },
    {
      name: t('passwordRequirements.max'),
      conditionString: t('errors.password.max'),
    },
    {
      name: t('passwordRequirements.min'),
      conditionString: t('errors.password.min'),
    },
    {
      name: t('passwordRequirements.specialChar'),
      conditionString: t('errors.password.missingSpecialCharacter'),
    },
    {
      name: t('passwordRequirements.number'),
      conditionString: t('errors.password.missingNumber'),
    },
  ];

  const [isInValidPassword, setIsInValidPassword] = useState({
    [t('errors.password.min')]: true,
    [t('errors.password.missingUppercase')]: true,
    [t('errors.password.missingLowercase')]: true,
    [t('errors.password.missingNumber')]: true,
    [t('errors.password.missingSpecialCharacter')]: true,
  });

  const validate = useCallback(() => {
    try {
      passwordValidate.validateSync(
        { password: formik.values.password },
        { abortEarly: false },
      );
      setIsInValidPassword({});
    } catch (error) {
      const errors = error?.inner;
      const newValues = errors.reduce((acc: {}, curr: any) => {
        return { ...acc, [curr.message]: true };
      }, {});

      setIsInValidPassword(newValues);
    }
  }, [formik.values.password, passwordValidate]);

  useEffect(() => {
    validate();
  }, [formik.values.password, validate]);

  return (
    <>
      {!showChangePassword ? (
        <NewStyledButton
          type="button"
          label={t('changePassword')}
          onClick={() => setShowChangePassword(true)}
        />
      ) : null}
      {showChangePassword ? (
        <>
          <SetPasswordFormFields confirmPasswordFieldName="password_confirmation" />
          <CheckmarkContainer flexDirection="column">
            {requirements.map((requirement) => (
              <Checkmark
                key={requirement.conditionString}
                text={requirement.name}
                value={isInValidPassword[requirement.conditionString] || false}
              />
            ))}
          </CheckmarkContainer>
        </>
      ) : null}
    </>
  );
};
