import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const InvisibleInput = styled.input`
  display: none;
`;

export const DatesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HoursContainer = styled.div<{ isBottomPadding?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: ${({ isBottomPadding }) =>
    isBottomPadding ? theme.space[5] : '0'};
`;
