import React from 'react';

import { StyledChip } from './styled';

type Props = {
  onRemove: () => void;
  label: string | undefined;
};

export const Chip = ({ onRemove, label }: Props) => {
  return <StyledChip onRemove={onRemove} removable label={label} />;
};
