import styled from '@emotion/styled/macro';
import { InputNumber, InputNumberProps } from 'primereact/inputnumber';
import { theme } from 'styles/theme';

import { InputNumberCustomProps } from './NumberInput';

type Props = {
  isInvalid?: boolean;
  append?: React.ReactNode;
  isIcon?: React.ReactNode;
  isfilled?: boolean;
  iserror?: string;
};

export const StyledNumberInput = styled(InputNumber)<
  InputNumberCustomProps & InputNumberProps
>`
  & > input {
    border-radius: ${({ variant }) =>
      variant === 'filled' ? theme.radii.md : theme.radii.sm};
    box-shadow: ${theme.shadows.md};
    height: ${({ isHigh }) =>
      isHigh ? theme.dimensions.inputHeight : theme.dimensions.inputHeightLow};
    font-size: ${theme.fontSizes.xs};
    width: 100%;
    transition: box-shadow 150ms;
    padding-right: ${({ paddingRight }) => (paddingRight ? paddingRight : 0)};

    &::placeholder {
      color: ${theme.colors.neutralDark};
      font-size: ${theme.fontSizes.xs};
    }

    &.p-inputtext {
      background-color: ${({ variant }) =>
        variant === 'filled' ? theme.colors.whiteLilac : theme.colors.white};

      border: ${({ variant }) =>
        variant === 'filled'
          ? `1px solid ${theme.colors.neutral}`
          : `1px solid ${theme.colors.neutralGray}`};

      &:focus {
        outline: none;
        box-shadow: ${theme.shadows.outline};
        border-color: transparent;
      }
    }
  }
`;

export const Wrapper = styled.div<Props>`
  position: relative;
`;

export const Label = styled.span<Props>`
  left: ${({ isIcon }) => (isIcon ? '25px' : '0')};
  display: block;
  text-transform: uppercase;
  font-size: ${theme.fontSizes.xxs};
  color: ${({ iserror }) =>
    iserror ? theme.colors.danger : theme.colors.neutralDark};
  font-family: ${theme.fonts.bold};
  margin: 0 0 ${theme.space[2]} 0;
  position: relative;
`;

export const FieldIcon = styled.div<Props>`
  position: absolute;
  left: 0;
  top: -5px;
  width: 2rem;
  height: 2rem;
  color: ${({ iserror }) =>
    iserror ? theme.colors.danger : theme.colors.darkGray};
`;

export const InputGroup = styled.div`
  position: relative;
  display: flex;
`;

export const InputRightElement = styled.div<Props>`
  position: absolute;
  top: 1px;
  right: 1px;
  display: flex;
  align-items: center;
  height: calc(${theme.sizes.md} - 2px);
  padding: 0 ${theme.space[2]};
  border-radius: ${theme.radii.md};
  background-color: ${({ isfilled }) =>
    isfilled ? theme.colors.whiteLilac : 'transparent'};
  border: 1px solid transparent;
  padding: 0 ${theme.space[1]};
  color: ${theme.colors.darkGray};
  opacity: 0.5;
  user-select: none;
`;
