import React from 'react';

import { ProgressBar, Wrapper } from '../styled';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

export type HoursProgressBarVariant = 'resourcesModal' | 'default';

export type HoursProgressBarProps = {
  verifiedHours: number;
  maxHours?: number;
  hoursOff?: number;
  variant?: HoursProgressBarVariant;
  fontSize?: string;
};

export const HoursProgressBar: React.FC<HoursProgressBarProps> = ({
  verifiedHours,
  maxHours = 40,
  hoursOff = 0,
  variant = 'default',
  fontSize = 'xs',
}) => {
  const maxWorkingHours = maxHours - hoursOff;

  const color =
    verifiedHours < maxWorkingHours || !verifiedHours
      ? theme.colors.primary
      : verifiedHours > maxWorkingHours
      ? theme.colors.danger
      : theme.colors.success;

  return (
    <Wrapper variant={variant}>
      <ProgressBar maxHours={maxWorkingHours} hours={verifiedHours} />
      <Flex justifyContent="space-between" fontSize={fontSize} color={color}>
        <span>Hours</span>
        <span>
          {verifiedHours} / {maxWorkingHours}
        </span>
      </Flex>
    </Wrapper>
  );
};
