import { fetcher } from '../fetcher';
import { IntegrateAppsPayload } from 'api/organizationSettings/models';
import useSWR from 'swr';

const API_URL = '/company/integrations';

export const useIntegrationsStatus = () => {
  const { data, mutate } = useSWR<IntegrateAppsPayload>(API_URL, fetcher);

  return {
    isLoading: !data,
    integrations: data?.integrations,
    mutate,
  };
};
