import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProject } from '../../hooks/projectContext';
import { EmptyState } from '../AboutProjectView/AboutProjectView.styles';
import { TeamMembersResponse } from 'api/projects/models';
import { Avatar, HoursProgressBar } from 'components/ui';
import { SpinnerCover } from 'components/ui/SpinCover';
import { fetcher } from 'fetchers/fetcher';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';
import useSWR from 'swr';

import {
  TeamMembersViewContainer,
  Title,
  SubTitle,
  Table,
  AvatarBox,
  ProgressCell,
  InputSwitch,
  List,
  ListItem,
  UserStatus,
} from './TeamMembersView.styles';

enum Statuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  NEEDED = 'needed',
}
const TeamMembersView: React.FC = () => {
  const { t } = useTranslation();
  const { projectId } = useProject();
  const teamMemberTranslations = (key: string) =>
    t(`projectCard.teamMembers.${key}`);
  const [showInactiveMembers, setShowInactiveMembers] = React.useState(false);
  const { data } = useSWR<TeamMembersResponse>(
    `/projects/${projectId}/members`,
    fetcher,
  );

  const cellData = (translationKey: string, text?: string | null) =>
    text || <EmptyState>{teamMemberTranslations(translationKey)}</EmptyState>;

  return (
    <TeamMembersViewContainer>
      <Title>
        {teamMemberTranslations('title')}{' '}
        <SubTitle>(current week state)</SubTitle>
      </Title>
      <List>
        <ListItem className="active">
          {teamMemberTranslations('active')}
        </ListItem>
        <ListItem className="inactive">
          {teamMemberTranslations('inactive')}
        </ListItem>
        {/* <ListItem className="needed">
          {teamMemberTranslations('resourceNeeded')}
        </ListItem> */}
      </List>
      <Flex
        alignItems="center"
        marginBottom={theme.space[6]}
        marginTop={theme.space[10]}
      >
        <InputSwitch
          checked={showInactiveMembers}
          onChange={() => setShowInactiveMembers(!showInactiveMembers)}
        />
        {teamMemberTranslations('showInactiveMembers')}
      </Flex>
      {!data ? (
        <SpinnerCover />
      ) : (
        <Table>
          <thead>
            <tr>
              <th>{teamMemberTranslations('teamMember')}</th>
              <th>{teamMemberTranslations('position')}</th>
              <th>{teamMemberTranslations('seniority')}</th>
              <th>{teamMemberTranslations('startDate')}</th>
              <th>{teamMemberTranslations('endDate')}</th>
              <th>{teamMemberTranslations('capacity')}</th>
            </tr>
          </thead>
          <tbody>
            {data?.users
              .filter((u) => u.active_project_user || showInactiveMembers)
              .sort((a, b) => a.full_name.localeCompare(b.full_name))
              .map((user) => (
                <tr key={user.id}>
                  <td>
                    <AvatarBox>
                      <Avatar
                        src={
                          user.photo.mini.url ||
                          user.photo.medium.url ||
                          user.photo.url
                        }
                        name={user.full_name}
                        size="sm"
                      />
                      <UserStatus
                        className={
                          user.needed_resource
                            ? Statuses.NEEDED
                            : user.active_project_user
                            ? Statuses.ACTIVE
                            : Statuses.INACTIVE
                        }
                      />
                    </AvatarBox>
                    {user.full_name}
                  </td>
                  <td>{cellData('position', user.position)}</td>
                  <td>{cellData('seniority', user.seniority)}</td>
                  <td>{cellData('startDate', user.start_date)}</td>
                  <td>{cellData('endDate', user.end_date)}</td>
                  <ProgressCell>
                    <HoursProgressBar
                      fontSize={theme.fontSizes.sm}
                      verifiedHours={user.verified_user_hours}
                      maxHours={user.hours_weekly}
                    />
                  </ProgressCell>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </TeamMembersViewContainer>
  );
};

TeamMembersView.displayName = 'TeamMembersView';

export default TeamMembersView;
