import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const SwitchContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: ${theme.radii.lg};
  width: 42px;
  height: 24px;
  background: ${theme.colors.lightGrayBlue};
  transition: all 150ms;
`;

export const SwitchToggleButton = styled.div`
  border-radius: ${theme.radii.xmd};
  width: ${theme.space[5]};
  height: ${theme.space[5]};
  background-color: ${theme.colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  transform: translateX(2px);
  transition: transform 250ms;
`;

export const SwitchInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  &:focus {
    & ~ ${SwitchContainer} {
      box-shadow: ${theme.shadows.outline};
    }
  }
  &:checked {
    & ~ ${SwitchContainer} {
      background-color: ${theme.colors.malachite};
      ${SwitchToggleButton} {
        transform: translateX(20px);
      }
    }
  }
`;
