import styled from '@emotion/styled/macro';
import { MultiSelect } from 'primereact/multiselect';
import { MultiSelectCustomProps } from 'screens/Projects/components/ProjectsFilters/models';
import { theme } from 'styles/theme';

export const StyledMultiSelect = styled(MultiSelect)(
  (props: MultiSelectCustomProps) => {
    const {
      selectAllLabel,
      variant,
      isSkillsPicker,
      isHigh,
      isBorder = true,
      hasAccentBtn,
    } = props;
    const isFilled = variant === 'filled';
    const isFilter = variant === 'filter';

    return `
  width: 100%;
  font-family: ${theme.fonts.regular};
  ${isHigh ? `min-height: ${theme.dimensions.inputHeight};` : ''}

  background-color: ${isFilled ? theme.colors.whiteLilac : theme.colors.white};
  border-radius: ${isFilter ? theme.radii.xl : theme.radii.md};
  border-color: ${
    !isBorder
      ? 'transparent'
      : isFilled
      ? theme.colors.neutral
      : theme.colors.neutralGray
  };
  

  &.p-multiselect:not(p-disabled).p-focus {
    box-shadow: ${isFilled ? 'none' : theme.shadows.mainButton};
    outline: none;
    box-shadow: ${theme.shadows.outline};
    ${isFilled ? `border-color: ${theme.colors.neutral};` : ''}
    &:hover {
      border-color: ${
        isFilled ? theme.colors.neutral : theme.colors.neutralGray
      };
    }
  }

  &.p-multiselect:not(.p-disabled):hover {
    border-color: ${
      !isBorder
        ? 'transparent'
        : isFilter
        ? theme.colors.neutral
        : theme.colors.primary
    };
    };
  }

  & .p-multiselect-label-container {
    & .p-multiselect-label,
    .p-placeholder {
      font-size: ${theme.fontSizes.sm};
      line-height: 20px;
      color: ${
        hasAccentBtn ? theme.colors.veryDarkGray : theme.colors.placeholder
      };
    }

    & .p-multiselect-label {
      .p-multiselect-token {
        background: ${theme.colors.primaryLight};
        color: ${theme.colors.white};
        font-size: ${theme.fontSizes.xs};
      }
    }

    & .p-multiselect-label {
       ${isSkillsPicker ? 'display: flex;' : ''}
       ${isSkillsPicker ? 'flex-wrap: wrap;' : ''}
       ${isSkillsPicker ? 'width: 90%;' : ''}
    }
  }

  & .p-multiselect-trigger {
    margin: 3px;
    background: ${hasAccentBtn ? theme.colors.primaryLight : 'transparent'};
    border-radius: 4px;
  }

  & .p-multiselect-trigger .p-multiselect-trigger-icon,
  .p-multiselect-clear-icon {
    font-size: ${theme.fontSizes.xs};
    color: ${hasAccentBtn ? theme.colors.white : 'inherit'};
    
  }

  & .p-multiselect-clear-icon {
    cursor: pointer;
    margin-right: ${hasAccentBtn ? '10px' : 0};
    color: ${theme.colors.veryDarkGray};
  }

  & .p-multiselect-header .p-checkbox::after {
    content: '${selectAllLabel ? selectAllLabel : ''}';
    position: absolute;
    left: 44px;
    line-height: ${theme.lineHeights.md2};
    font-size: ${theme.fontSizes.xxs2};
  }

  & .p-multiselect-label-container {
    display: flex;
    align-items: center;
    padding-left: 5px;
    ${isHigh ? 'align-items: center;' : ''}
  }

  & .custom-multiselect-items-label {
    color: ${theme.colors.placeholder};
    font-size: ${theme.fontSizes.sm};
  }
  
  & .p-checkbox .p-checkbox-box.p-highlight {
    background: ${theme.colors.primaryLight};
    border-color: ${theme.colors.primaryLight};
  }
  
  & .p-multiselect-items-wrapper {
    height: 200px;
  }

  & > .p-multiselect-label-container {
    min-height: ${theme.sizes.smd};
  }
`;
  },
);

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.span<MultiSelectCustomProps>`
  left: ${({ isIcon }) => (isIcon ? '25px' : '0')};
  display: block;
  text-transform: uppercase;
  font-size: ${theme.fontSizes.xxs};
  color: ${({ iserror }) =>
    iserror ? theme.colors.danger : theme.colors.neutralDark};
  font-family: ${theme.fonts.bold};
  margin: 0 0 ${theme.space[2]} 0;
  position: relative;
`;

export const FieldIcon = styled.div<MultiSelectCustomProps>`
  position: absolute;
  left: 0;
  top: -5px;
  width: 2rem;
  height: 2rem;
  color: ${({ iserror }) =>
    iserror ? theme.colors.danger : theme.colors.darkGray};
`;
