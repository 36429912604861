import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { OrganizationSettingsPayload } from 'api/organizationSettings/models';
import { FormikTextInput } from 'components/forms/formik-prime';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { addAtlassianIntegration } from 'fetchers/actions/addAtlassianIntegration';
import { Formik, Form } from 'formik';
import { Skeleton } from 'primereact/skeleton';
import { Flex, Box } from 'rebass';
import {
  GridWrapper,
  IntegratedInfo,
} from 'screens/OrganizationSettings/styled';
import { HorizontalLine } from 'screens/User/components/GeneralInfoFields';
import { displaySnackbar } from 'store/slices/snackbarSlice';
import { theme } from 'styles/theme';

import { validationSchema } from './validationSchema';

interface Props {
  isLoading: boolean;
  setIsSettingsForm: React.Dispatch<React.SetStateAction<boolean>>;
  organizationSettings?: OrganizationSettingsPayload;
  onClose: () => void;
  isAtlassianIntegratedButInactive: boolean;
}

export const AtlassianIntegrationForm = ({
  setIsSettingsForm,
  isLoading,
  organizationSettings,
  onClose,
  isAtlassianIntegratedButInactive,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async (values: any) => {
    addAtlassianIntegration(values)
      .then(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationSuccess'),
          }),
        );
        setIsSettingsForm(true);
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationFail'),
            isError: true,
          }),
        );
      });
  };

  const getDefaults = () => {
    return {
      atlassian_url: organizationSettings?.atlassian_url,
      token: '',
      username: '',
      organization_api_key: '',
    };
  };

  return !isLoading ? (
    <>
      {isAtlassianIntegratedButInactive ? (
        <IntegratedInfo>
          {t('organizationSettings.atlassian.integrationValid')}
        </IntegratedInfo>
      ) : (
        <Formik
          initialValues={getDefaults()}
          validationSchema={() => validationSchema(t)}
          onSubmit={handleSubmit}
        >
          <Form>
            <Flex flexDirection="column">
              <FormikTextInput
                name="atlassian_url"
                label={t('organizationSettings.atlassian.organizationURL')}
                ishigh={'false'}
                variant="default"
              />
              <FormikTextInput
                name="organization_api_key"
                label={t('organizationSettings.atlassian.organizationApiKey')}
                ishigh={'false'}
                variant="default"
              />
              <FormikTextInput
                name="token"
                label={t('organizationSettings.atlassian.apiKey')}
                ishigh={'false'}
                variant="default"
              />
              <FormikTextInput
                name="username"
                label={t('organizationSettings.atlassian.login')}
                ishigh={'false'}
                variant="default"
              />
              <HorizontalLine />
              <Flex justifyContent="flex-end">
                <Box mr={3}>
                  <PrimeTextButton
                    onClick={onClose}
                    label={t('common.cancel')}
                  />
                </Box>
                <NewStyledButton
                  type="submit"
                  // TODO: Probably should change and then update tests
                  data-cy="jira-submit-btn"
                  label={t('organizationSettings.atlassian.connect')}
                />
              </Flex>
            </Flex>
          </Form>
        </Formik>
      )}
    </>
  ) : (
    <Flex flexDirection="column">
      <GridWrapper gridGap={`0 ${theme.space[5]}`}>
        {Array.from(Array(3).keys()).map(() => (
          <Box>
            <Box mb={theme.space[2]}>
              <Skeleton width="100px" height={theme.fontSizes.xs} />
            </Box>
            <Box mb={theme.space[5]}>
              <Skeleton width="100%" height={theme.dimensions.inputHeightLow} />
            </Box>
          </Box>
        ))}
      </GridWrapper>
      <HorizontalLine />
      <Box alignSelf="flex-end">
        <Skeleton width="238px" height={theme.dimensions.inputHeightLow} />
      </Box>
    </Flex>
  );
};
