import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';

import {
  PhaseAttribute,
  PhaseData,
  ProjectPhaseResponsibleUser,
} from 'api/projects/models';
import { parseISO } from 'date-fns';
import { addProjectPhase } from 'fetchers/actions/projectPhases/addProjectPhase';
import { updateProjectPhase } from 'fetchers/actions/projectPhases/updateProjectPhase';
import { useProjectPhase } from 'fetchers/hooks/useProjectPhase';
import { Formik, Form } from 'formik';
import {
  PhasesFormProps,
  PHASE_FORM_TYPES,
} from 'screens/Project/screens/ProjectPhasesEdit/ProjectPhasesEdit';
import { displaySnackbar } from 'store/slices/snackbarSlice';
import checkPageForErrors from 'utils/checkForErrors';
import {
  confirmPageRefresh,
  removeConfirmationOnPageRefresh,
} from 'utils/functions';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';
import * as Yup from 'yup';

import PhaseFieldsItem from './PhaseFieldsItem';

type ProjectPhasesFormProps = {
  type: PhasesFormProps['type'];
  phase?: PhaseData;
};

const ProjectPhasesForm: React.FC<ProjectPhasesFormProps> = ({
  type,
  phase,
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const canEditProject = usePermissions(PERMISSIONS.EDIT_PROJECT);
  const isReadOnly = type === PHASE_FORM_TYPES.EDIT && !canEditProject;
  const actionButtonCopy =
    type === PHASE_FORM_TYPES.ADD ? 'Save phase' : 'Update phase';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [enableValidation, setEnableValidation] = useState<boolean>(false);
  const [isRemoveButtonDisabled, setIsRemoveButtonDisabled] = useState(true);

  useEffect(() => {
    return () => {
      confirmPageRefresh(false);
    };
  }, []);

  const phaseId = phase?.id ? phase.id : '';
  const { phase: currentPhase, isLoading } = useProjectPhase(
    projectId,
    phaseId,
  );

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.string().required(t('errors.required')),
      phase_type_id: Yup.number().required(t('errors.required')),
      status: Yup.string().required(t('errors.required')),
    });

  const prepareResponsibleUsers = (
    items: ProjectPhaseResponsibleUser[] | undefined,
  ) => {
    const initialResponsibleUsers = currentPhase?.phase_responsible_users || [];
    const result =
      items?.map((item) => {
        const responsibleUserIndex = initialResponsibleUsers.findIndex(
          ({ user }) => user.id === item.user_id,
        );
        if (responsibleUserIndex >= 0) {
          return { ...item };
        }

        return {
          _destroy: false,
          user_id: item.user_id,
        };
      }) || [];

    initialResponsibleUsers.forEach((responsibleUser) => {
      if (!items?.some((item) => item.user_id === responsibleUser.user.id)) {
        result.push({
          id: responsibleUser.id,
          user_id: responsibleUser.user.id,
          _destroy: true,
        });
      }
    });

    return result;
  };

  const prepareUpdatePhaseData = (values: PhaseAttribute) => {
    const payload = { ...values };
    payload.phase_responsible_users_attributes = prepareResponsibleUsers(
      payload.phase_responsible_users_attributes,
    );

    return payload;
  };

  const handleUpdateProjectPhase = (values: PhaseAttribute) => {
    if (!phase?.id) {
      console.error('Phase id is required for edit mode');
      return;
    }

    updateProjectPhase(projectId, phase.id, values);
  };

  const checkPageForErrorsAndChangeActivity = () => {
    checkPageForErrors();
  };

  const handleFormikSubmit = (values: PhaseAttribute) => {
    if (type === PHASE_FORM_TYPES.ADD) {
      addProjectPhase(projectId, values);
      dispatch(
        displaySnackbar({
          message: t('projectCard.settings.editSettings.addedPhase'),
          isError: false,
        }),
      );
      setIsRemoveButtonDisabled(false);
    } else {
      const data = prepareUpdatePhaseData(values);
      handleUpdateProjectPhase(data);
      dispatch(
        displaySnackbar({
          message: t('projectCard.settings.editSettings.updatedPhase'),
          isError: false,
        }),
      );
    }
  };

  const getDefaultValues = () => {
    return {
      id: currentPhase?.id,
      be_hours: currentPhase?.be_hours || 0,
      date:
        (currentPhase?.date &&
          new Date(parseISO(currentPhase.date.toString()))) ||
        undefined,
      design_hours: currentPhase?.design_hours || 0,
      end_date:
        (currentPhase?.end_date &&
          new Date(parseISO(currentPhase.end_date.toString()))) ||
        undefined,
      fe_hours: currentPhase?.fe_hours || 0,
      hours_monthly: currentPhase?.hours_monthly || 0,
      name: currentPhase?.name,
      phase_type_id: currentPhase?.type ? currentPhase?.type.id : undefined,
      phase_responsible_users_attributes: currentPhase?.phase_responsible_users
        ? currentPhase.phase_responsible_users.map(({ user, id }) => ({
            user_id: user.id,
            id,
            _destroy: false,
          }))
        : [],
      pm_hours: currentPhase?.pm_hours || 0,
      qa_hours: currentPhase?.qa_hours || 0,
      settlement: currentPhase?.settlement || undefined,
      start_and_end_date:
        currentPhase?.start_date || currentPhase?.end_date
          ? [
              currentPhase.start_date &&
                new Date(parseISO(currentPhase.start_date.toString())),
              currentPhase.end_date &&
                new Date(parseISO(currentPhase.end_date.toString())),
            ]
          : undefined,
      start_date:
        (currentPhase?.start_date &&
          new Date(parseISO(currentPhase.start_date.toString()))) ||
        undefined,
      status: currentPhase?.status,
    };
  };

  return (
    <>
      {!isLoading && (
        <Formik
          initialValues={getDefaultValues()}
          onSubmit={handleFormikSubmit}
          validationSchema={validationSchema}
          validateOnBlur={enableValidation}
          validateOnChange={enableValidation}
        >
          {({ handleSubmit, dirty, submitCount }) => {
            if (dirty && submitCount === 0) {
              confirmPageRefresh(true);
            } else {
              removeConfirmationOnPageRefresh();
            }

            return (
              <Form
                onSubmit={(event) => {
                  setEnableValidation(true);
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <Prompt
                  when={dirty && submitCount === 0}
                  message={t('prompts.userEditForm')}
                />
                <PhaseFieldsItem
                  isReadOnly={isReadOnly}
                  initialResponsibleUsers={
                    currentPhase?.phase_responsible_users || []
                  }
                  type={type}
                  actionButtonCopy={actionButtonCopy}
                  onClick={checkPageForErrorsAndChangeActivity}
                  isDisabled={isRemoveButtonDisabled}
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default ProjectPhasesForm;
