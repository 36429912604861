import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type Props = {
  last?: boolean;
  marginRight?: string;
  width?: string;
};

export const FormControl = styled.div<Props>`
  position: relative;
  width: ${({ width }) => width};
  margin-right: ${({ marginRight }) => marginRight || 0};
  margin-bottom: ${({ last }) => (last ? 0 : theme.space[6])};
`;
