import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: ${theme.fontSizes.xxs};
  border: 1px solid ${theme.colors.neutral};
  width: ${theme.space[6]};
  height: ${theme.space[6]};
  margin-right: ${theme.space[2]};
  transition: border-color 150ms;
`;

export const StyledBackLink = styled.div`
  div,
  a {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: ${theme.colors.neutralDark};
    font-family: ${theme.fonts.regular};
    transition: color 150ms;

    &:hover,
    &:focus {
      color: ${theme.colors.primary};

      & ${IconWrapper} {
        background-color: ${theme.colors.lightGrayBlue};
        border-color: transparent;
      }
    }
  }
`;
