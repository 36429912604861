import React from 'react';

import { UserSkill as UserSkillType } from 'api/users/models';
import { Flex } from 'rebass';

import { SkillRow, Text, Circle } from './styled';

type Props = {
  skill: UserSkillType;
};

export const UserSkill = ({ skill }: Props) => {
  if (!skill || !skill.level) {
    return null;
  }

  return (
    <SkillRow>
      <Text>{skill.name}</Text>
      <Flex minWidth="42px">
        <Circle isFilled={skill.level >= 1} />
        <Circle isFilled={skill.level >= 2} />
        <Circle isFilled={skill.level >= 3} />
      </Flex>
    </SkillRow>
  );
};
