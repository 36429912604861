import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { multipleFetcherWithWeeks } from '../fetcher';
import { ProjectWeek, UserHoursPayload } from 'api/projects/models';
import { UsersWeek } from 'api/users/models';
import { getNewProjectsWeeks } from 'store/slices/projectsSlice';
import { selectSelectedWeeksNumbers } from 'store/slices/selectedWeeksSlice';
import { getNewUsersWeeks } from 'store/slices/usersSlice';
import useSWR from 'swr';

export const API_NAMES_TO_KEY = {
  PROJECTS_WEEKS: 'projectsWeeks',
  USERS_WEEKS: 'usersWeeks',
};

export const API_URLS = {
  [API_NAMES_TO_KEY.PROJECTS_WEEKS]: '/projects/weeks',
  [API_NAMES_TO_KEY.USERS_WEEKS]: '/users/weeks',
};

type UpdateUserHours = {
  phaseUserHourId: number;
  value: number;
};

export type Weeks = {
  // hideUser: ({ phaseId, userId }: ToggleVisibility) => void;
  isLoading: boolean;
  projectsWeeks: ProjectWeek[];
  setUserHours: (data: UserHoursPayload) => void;
  // showUser: ({ phaseId, userId }: ToggleVisibility) => void;
  updateUserHours: ({ phaseUserHourId, value }: UpdateUserHours) => void;
  usersWeeks: UsersWeek[];
};

export const useWeeks = () => {
  const dispatch = useDispatch();
  const selectedWeeks = useSelector(selectSelectedWeeksNumbers);

  const { data, error } = useSWR<{ [key: string]: any }>(
    [
      API_URLS[API_NAMES_TO_KEY.PROJECTS_WEEKS],
      API_URLS[API_NAMES_TO_KEY.USERS_WEEKS],
      selectedWeeks.join(','),
    ],
    (...urls) => multipleFetcherWithWeeks(urls, selectedWeeks),
  );

  /* 
    TODO: Switch to SWR state only when all actions related to those API will be implemented as SWR hook. 
    It causes additional re-render! 
  */
  useEffect(() => {
    if (!error && data) {
      dispatch(
        getNewUsersWeeks({
          error,
          weeks: data?.[API_NAMES_TO_KEY.USERS_WEEKS],
        }),
      );
      dispatch(
        getNewProjectsWeeks({
          error,
          weeks: data?.[API_NAMES_TO_KEY.PROJECTS_WEEKS],
        }),
      );
    }
  }, [data, dispatch, error]);

  return useMemo(
    () =>
      ({
        isLoading: !data,
        projectsWeeks: data?.[API_NAMES_TO_KEY.PROJECTS_WEEKS] || [],
        usersWeeks: data?.[API_NAMES_TO_KEY.USERS_WEEKS] || [],
      } as Weeks),
    [data],
  );
};
