import React from 'react';

import { Error, Wrapper } from '../styled';
import { PrimeMultiSelect } from 'components/prime';
import { useField } from 'formik';
import { MultiSelectProps } from 'primereact/multiselect';
import { MultiSelectCustomProps } from 'screens/Projects/components/ProjectsFilters/models';

type Props = MultiSelectProps &
  MultiSelectCustomProps & {
    name: string;
    validate?: (values: any) => undefined | string | Promise<any>;
  };

export const FormikMultiSelect = ({
  name,
  onChange: customOnChange,
  onBlur: customOnBlur,
  variant = 'filled',
  isSkillsPicker,
  isHigh = true,
  validate,
  ...restProps
}: Props) => {
  const [field, meta, helpers] = useField({ name, validate });
  const { setTouched } = helpers;

  return (
    <Wrapper>
      <PrimeMultiSelect
        {...field}
        onChange={(event) => {
          field.onChange(event);
          customOnChange && customOnChange(event);
        }}
        onBlur={(event) => {
          setTouched(true);
          customOnBlur && customOnBlur(event);
        }}
        isSkillsPicker={isSkillsPicker}
        iserror={meta.touched ? meta.error : ''}
        value={field.value ? field.value : undefined}
        variant={variant}
        isHigh={isHigh}
        {...restProps}
      />
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </Wrapper>
  );
};
