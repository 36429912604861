import styled from '@emotion/styled/macro';
import { RemoveAvatar as RemoveAvatarComponent } from 'components/forms/Uploader/styled';
import { FieldLabel as FieldLabelComponent } from 'components/primitive';
import { theme } from 'styles/theme';

export const ImageWrapper = styled.div`
  position: relative;
  width: ${theme.dimensions.uploaderWidth};
  height: ${theme.dimensions.uploaderWidth};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  align-items: center;

  > svg {
    position: absolute;
    right: ${theme.space['-2']};
    bottom: ${theme.space['-2']};
    height: ${theme.sizes.sm};
    width: ${theme.sizes.sm};
    transition: all 150ms ease-in-out;
  }
`;

export const Image = styled.img`
  width: ${theme.dimensions.uploaderWidth};
  height: ${theme.dimensions.uploaderWidth};
  object-fit: cover;
  border-radius: ${theme.radii.lg};
`;

export const InputWrapper = styled.div`
  position: relative;
  width: ${theme.dimensions.uploaderWidth};
  height: ${theme.dimensions.uploaderWidth};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: ${theme.dimensions.uploaderWidth};
  height: ${theme.dimensions.uploaderWidth};
`;

export const MissingImageWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;

  > p {
    margin: 0;
  }
`;

export const FieldLabel = styled(FieldLabelComponent)`
  position: relative;
`;

export const RemoveAvatar = styled(RemoveAvatarComponent)`
  transform: translate(-65%, -25%);
  padding: 5px 5px 2px 5px;
  background-color: ${theme.colors.white};
  border-radius: ${theme.radii.md};
  color: ${theme.colors.darkGray};
  border: 1px solid ${theme.colors.lightGray};
  z-index: 1;
`;
