import { combineReducers } from '@reduxjs/toolkit';
import authReducer from 'screens/Auth/authSlice';
import notificationsFiltersReducer from 'screens/Notifications/notificationsFiltersSlice';
import notificationsReducer from 'screens/Notifications/notificationsSlice';
import projectsFiltersReducer from 'screens/Projects/projectsFiltersSlice';
import preferencesReducer from 'screens/Users/slices/preferencesSlice';
import skillsReducer from 'screens/Users/slices/skillsSlice';
import teamsReducer from 'screens/Users/slices/teamsSlice';
import userTypesReducer from 'screens/Users/slices/userTypesSlice';
import usersFiltersReducer from 'screens/Users/slices/usersFiltersSlice';

import dsahboardReducer from './slices/dashboardSlice';
import loadingReducer from './slices/loadingSlice';
import modalsReducer from './slices/modalsSlice';
import projectsPhasesMembersReducer from './slices/projectsPhasesMembersSlice';
import projectsReducer from './slices/projectsSlice';
import selectedWeeksReducer from './slices/selectedWeeksSlice';
import snackbarReducer from './slices/snackbarSlice';
import usersReducer from './slices/usersSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  userTypes: userTypesReducer,
  skills: skillsReducer,
  preferences: preferencesReducer,
  teams: teamsReducer,
  snackbar: snackbarReducer,
  selectedWeeks: selectedWeeksReducer,
  dashboard: dsahboardReducer,
  notifications: combineReducers({
    data: notificationsReducer,
    filters: notificationsFiltersReducer,
  }),

  users: combineReducers({
    data: usersReducer,
    filters: usersFiltersReducer,
  }),
  projects: combineReducers({
    data: projectsReducer,
    phasesMembers: projectsPhasesMembersReducer,
    filters: projectsFiltersReducer,
  }),
  modals: modalsReducer,
  loading: loadingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
