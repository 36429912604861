import React, { useMemo } from 'react';

import { WeekDataType } from '../../hooks';
import { Checkbox } from 'components/forms';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import format from 'date-fns/format';
import { Flex } from 'rebass';

import { StyledOption, WeekLabel, WeekDay } from './styled';

type Props = {
  end: Date;
  holidays: string[];
  isSelected: boolean;
  start: Date;
  onAdd: (dates: WeekDataType) => void;
  onRemove: (dates: WeekDataType) => void;
  weekNumber: number;
};

const getDaysRange = (start: Date, end: Date) =>
  eachDayOfInterval({ start, end });

export const Option: React.FC<Props> = React.memo(
  ({ end, isSelected, start, onAdd, onRemove, weekNumber, holidays }) => {
    const weekData: WeekDataType = {
      start,
      end,
      weekNumber,
      holidays,
    };
    const optionCopy = useMemo(
      () => `${format(start, 'dd.MM')} - ${format(end, 'dd.MM')}`,
      [end, start],
    );
    const daysRange = useMemo(() => getDaysRange(start, end), [end, start]);

    const handleChoose = (
      e:
        | React.MouseEvent<HTMLLIElement>
        | React.ChangeEvent<HTMLInputElement>
        | React.KeyboardEvent<HTMLLIElement>,
    ) => {
      e.stopPropagation();
      if (isSelected) {
        onRemove(weekData);
      } else if (!isSelected) {
        onAdd(weekData);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        handleChoose(e);
      }
    };

    return (
      <StyledOption
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onClick={handleChoose}
      >
        <Checkbox isChecked={isSelected} onChange={handleChoose}>
          {optionCopy}
        </Checkbox>
        <Flex>
          {daysRange.map((value: Date, index: number) => (
            <WeekDay
              saturday={index === 5}
              holiday={
                index === 6 ||
                (!!holidays.length &&
                  holidays.includes(format(value, 'yyyy-MM-dd')))
              }
              key={value.toString()}
            >
              {format(value, 'EEEEEE').toString()}
            </WeekDay>
          ))}
          <WeekLabel>{weekNumber}</WeekLabel>
        </Flex>
      </StyledOption>
    );
  },
);
