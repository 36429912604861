import React, { useState, useEffect } from 'react';

import { OrganizationSettingsPayload } from 'api/organizationSettings/models';
import { Flex } from 'rebass';

import { AtlassianIntegrationForm } from './forms/AtlassianIntegrationForm';
import { AtlassianSettingsForm } from './forms/AtlassianSettingsForm';

interface Props {
  isLoading: boolean;
  integrated: boolean;
  organizationSettings?: OrganizationSettingsPayload;
  onClose: () => void;
  canIntegrate: boolean;
}

export const AtlassianSection = ({
  organizationSettings,
  isLoading,
  integrated,
  onClose,
  canIntegrate,
}: Props) => {
  const [isIntegrated, setIsIntegrated] = useState(false);

  useEffect(() => {
    setIsIntegrated(integrated);
  }, [integrated]);

  return (
    <Flex flexDirection="column" mt={4}>
      {isIntegrated ? (
        <AtlassianSettingsForm
          organizationSettings={organizationSettings}
          onClose={onClose}
        />
      ) : (
        <AtlassianIntegrationForm
          organizationSettings={organizationSettings}
          setIsSettingsForm={setIsIntegrated}
          isLoading={isLoading}
          onClose={onClose}
          isAtlassianIntegratedButInactive={canIntegrate && !isIntegrated}
        />
      )}
    </Flex>
  );
};
