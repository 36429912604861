import { mutateSeniority } from '../../mutations/mutateSeniority';
import { organizationAPI } from 'api/phases/endpoints';
import { serialize } from 'object-to-formdata';

export const updateSeniority = async (name: string, seniorityId: number) => {
  const formData = serialize({ seniority: { name } });
  await organizationAPI.updateSeniority(seniorityId, formData);

  await mutateSeniority();
};
