import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  requestRestPasswordEmail,
  selectIsAuthUserLoading,
  selectIsRequestRestPasswordSuccess,
} from '../../authSlice';
import { Wrapper } from '../../components/Wrapper';
import { FormikTextInput } from 'components/forms/formik-prime';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { Formik, Form } from 'formik';
import { Flex, Text } from 'rebass';

import { validationSchema } from './validationSchema';

const RequestResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const isLoading = useSelector(selectIsAuthUserLoading);
  const isSuccess = useSelector(selectIsRequestRestPasswordSuccess);
  const dispatch = useDispatch();
  const { goBack } = useHistory();

  const handleSubmit = ({ email }: { email: string }) => {
    dispatch(requestRestPasswordEmail(email));
  };

  return (
    <Wrapper heading={t('login.resetPassword')} onLogoClick={goBack}>
      {!isSuccess ? (
        <Formik
          initialValues={{ email: '' }}
          validationSchema={() => validationSchema(t)}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          {({ handleSubmit }) => (
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <FormikTextInput name="email" label={t('login.email')} />

              <Flex justifyContent="flex-end" width="100%">
                <NewStyledButton
                  type="submit"
                  label={t('common.send')}
                  disabled={isLoading}
                />
              </Flex>
            </Form>
          )}
        </Formik>
      ) : (
        <Text>{t('login.requestResetPasswordSuccess')}</Text>
      )}
    </Wrapper>
  );
};

export default RequestResetPassword;
