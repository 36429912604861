import React from 'react';
import { useSelector } from 'react-redux';

import { UserHoursPayload } from 'api/projects/models';
import { HoursTextField } from 'components/forms';
import { HoursWrapper } from 'components/layout/Tables/columnsStyled';
import { HoursProgressBar } from 'components/ui';
import { Flex } from 'rebass';
import { selectSelectedWeeksInISOFormat } from 'store/slices/selectedWeeksSlice';

type Props = {
  hoursOff: number;
  hoursWeekly: number;
  id?: number;
  isConfirmed: boolean;
  isHidden: boolean;
  notVerifiedHours: number;
  onChange: (args: any) => void;
  phaseId: UserHoursPayload['phase_id'];
  showSpentHours: boolean;
  spentHours: string;
  userId: UserHoursPayload['user_id'];
  value: number;
  verifiedHours: number;
  weekNumber: UserHoursPayload['week_number'];
  allSelectedWeeks: number[];
};

export const Hours: React.FC<Props> = ({
  hoursOff,
  hoursWeekly,
  id,
  isConfirmed,
  isHidden,
  notVerifiedHours,
  onChange,
  phaseId,
  showSpentHours,
  spentHours,
  userId,
  value,
  verifiedHours,
  weekNumber,
  allSelectedWeeks,
}) => {
  const selectedWeeksState = useSelector(selectSelectedWeeksInISOFormat);

  const handleUpdate = (newValue: string) => {
    const changedWeek = selectedWeeksState.filter(
      (week) => week.weekNumber === weekNumber,
    );
    onChange({
      phaseId,
      phaseUserHourId: id,
      userId,
      value: Number(newValue),
      weekNumber,
      allSelectedWeeks,
      year: changedWeek[0].start.getFullYear(),
    });
  };

  return (
    <Flex alignItems="center" opacity={isHidden ? 0.4 : 1} width="100%">
      <HoursTextField
        data-cy="hours-input"
        defaultValue={value || 0}
        disabled={isHidden}
        isMemberConfirmed={isConfirmed}
        onEditFinish={handleUpdate}
        placeholder="0"
      />
      {showSpentHours && (
        <Flex
          minWidth="3.1rem"
          height="3.1rem"
          mr={3}
          pl={2}
          alignItems="center"
          justifyContent="start"
        >
          {spentHours}
        </Flex>
      )}
      <HoursWrapper>
        <HoursProgressBar
          verifiedHours={verifiedHours}
          maxHours={hoursWeekly}
          hoursOff={hoursOff}
        />
      </HoursWrapper>
    </Flex>
  );
};
