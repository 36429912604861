import React from 'react';
import { useFlexLayout, useTable, useSortBy, Column } from 'react-table';

import { Variant } from '../SimpleUsersTable';
import { TableWrapper, TableRowItem } from '../styled';
import { UserIssue } from 'api/users/models';
import { Box } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  data: UserIssue[];
  columns: Column<UserIssue>[];
  scroll?: 'x' | 'y' | 'both';
  maxHeight?: string;
};

export const DeveloperDashboardTable: React.FC<Props> = ({
  data,
  columns,
  maxHeight = 'calc(100vh - 265px)',
}) => {
  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable(
    {
      columns,
      data,
      //@ts-ignore
      autoResetSortBy: false,
      autoResetExpanded: false,
      initialState: {
        //@ts-ignore
        sortBy: [{ id: 'project_name', desc: false }],
      },
      //@ts-ignore
      disableSortRemove: true,
    },
    useFlexLayout,
    useSortBy,
  );

  return (
    <TableWrapper maxHeight={maxHeight} overflowY="scroll">
      <Box as="table" width="100%" {...getTableProps()}>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <Box
                as="tr"
                backgroundColor="white"
                marginTop={2}
                sx={{
                  borderRadius: theme.radii.xmd,
                }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <TableRowItem
                      variant={Variant.DEVELOPER_DASHBOARD}
                      as="td"
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                      {cellIndex === 0 || cellIndex === row.cells.length - 1}
                    </TableRowItem>
                  );
                })}
              </Box>
            );
          })}
        </tbody>
      </Box>
    </TableWrapper>
  );
};
