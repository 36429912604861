import { isSameDay } from 'date-fns';

interface DateObject {
  [key: string]: Date | any;
}

export function findIndexByDay<T extends DateObject>(
  collection: T[],
  key: keyof T,
  date: Date,
): number {
  return collection.findIndex((element) => isSameDay(element[key], date));
}
