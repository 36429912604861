import React from 'react';

import { Variant } from '../../../layout/Tables/SimpleUsersTable';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

type Props = {
  variant?: Variant;
};

export const TableSubHeader: React.FC<Props> = ({
  children,
  variant,
  ...props
}) => (
  <Flex
    {...props}
    as="header"
    style={{
      borderTop: `1px solid ${
        variant === Variant.PHASES ? 'transparent' : theme.colors.neutral
      }`,
      borderBottom: `1px solid ${theme.colors.neutral}`,
      overflow: 'auto',
      position: 'sticky',
      top: '-1px',
      right: 0,
      zIndex: +theme.zIndices['1'],
      width: '100%',
      minWidth: 'fit-content',
    }}
  >
    {children}
  </Flex>
);
