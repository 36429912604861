export function sortByKey<T extends {}>(key: keyof T, keyDeep?: string) {
  return (a: any, b: any) => {
    const shallowA = a[key];
    const shallowB = b[key];
    if (keyDeep && shallowA?.[keyDeep] && shallowB?.[keyDeep]) {
      return String(shallowA[keyDeep!])
        .trim()
        .localeCompare(String(b[key][keyDeep!]).trim());
    } else if (!keyDeep) {
      if (!b[key]) {
        return -1;
      }
      if (!a[key]) {
        return 1;
      }
      return a[key].trim().localeCompare(b[key].trim());
    }
    return 0;
  };
}
