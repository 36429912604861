import { mutateIntegrationStatus } from '../mutations/mutateIntegrationStatus';
import { mutateOrganizationSettings } from '../mutations/mutateOrganizationSettings';
import { organizationSettingsAPI } from 'api/organizationSettings/endpoints';
import { UpdateOrganizationSettingsPayload } from 'api/organizationSettings/models';

export const updateOrganizationSettings = async (
  formData: UpdateOrganizationSettingsPayload,
) => {
  await organizationSettingsAPI.updateOrganizationSettings(formData);
  await mutateIntegrationStatus();
  await mutateOrganizationSettings();
};
