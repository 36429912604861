import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatefulPinInput } from 'react-input-pin-code';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BackLink, FullScreenLoader, TFASuccessIcon } from 'components/ui';
import {
  getQrCodeForTFA,
  selectQrCodeForTFA,
  activateTFA,
  selectIsTFAEnabled,
  selectIsTFAError,
} from 'store/slices/usersSlice';
import { theme } from 'styles/theme';

import {
  CardContainer,
  Footer,
  Header,
  HeaderWrapper,
  TFAHeading,
  TFASpan,
  StyledButton,
  StyledCard,
  Text,
  Image,
  TfaKey,
  InputContainer,
  IconBox,
} from './styled';

enum Subview {
  QRCode = 0,
  PINCode = 1,
  Confirmation = 2,
}

const PIN_FORM_INITIAL_VALUES = ['', '', '', '', '', ''];

type StepData = {
  step: string;
  text: string;
  isPrevStepVisible: boolean;
  isPrevStepDisabled: boolean;
  isNextStepDisabled: boolean;
  onClickNext: () => void;
};

const TFAActivationSteps: React.FC = () => {
  const [values, setValues] = useState(PIN_FORM_INITIAL_VALUES);
  const [activeStep, setActiveStep] = useState(0);
  const [isInputValid, setIsInputValid] = useState(false);
  const { userId } = useParams<{ userId: string }>();

  const is2FAEnabled = useSelector(selectIsTFAEnabled);
  const is2FAError = useSelector(selectIsTFAError);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQrCodeForTFA());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tfaData = useSelector(selectQrCodeForTFA);

  const key = tfaData?.key;
  const qrCode = tfaData?.qr_code;

  const isPinValid =
    isInputValid && values.length === 6 && !values.includes('');

  const handleMoveToNextStep = () => setActiveStep(activeStep + 1);

  const handleTakeStepBack = () => setActiveStep(activeStep - 1);

  const handleFinishTFAActivation = () => history.push(`/users/${userId}`);

  const getBorderColor = () => {
    if (is2FAEnabled) {
      return theme.colors.success;
    } else if (is2FAError) {
      return theme.colors.danger;
    } else {
      return theme.colors.newLightestGrey;
    }
  };

  const handleSubmit = (vals: string[]) => {
    const formattedVals = vals.join('');
    const data = { mfa_code: formattedVals };

    dispatch(activateTFA(data));

    if (is2FAEnabled) {
      setIsInputValid(true);
    } else if (is2FAError) {
      setIsInputValid(false);
    }
  };

  const borderColor = getBorderColor();

  const stepData: Record<number, StepData> = {
    [Subview.QRCode]: {
      step: '1',
      text: t('tfaSteps.1'),
      isPrevStepVisible: true,
      isPrevStepDisabled: true,
      isNextStepDisabled: false,
      onClickNext: handleMoveToNextStep,
    },
    [Subview.PINCode]: {
      step: '2',
      text: t('tfaSteps.2'),
      isPrevStepVisible: true,
      isPrevStepDisabled: false,
      isNextStepDisabled: !isPinValid,
      onClickNext: handleMoveToNextStep,
    },
    [Subview.Confirmation]: {
      step: '3',
      text: t('tfaSteps.3'),
      isPrevStepVisible: false,
      isPrevStepDisabled: false,
      isNextStepDisabled: false,
      onClickNext: handleFinishTFAActivation,
    },
  };

  const {
    step,
    text,
    isPrevStepVisible,
    isPrevStepDisabled,
    isNextStepDisabled,
    onClickNext,
  } = stepData[activeStep];

  const header = (
    <HeaderWrapper>
      <Header>
        <TFAHeading>{t('tfaHeading')}</TFAHeading>
        <TFASpan>{`${t('common.step')} ${step} ${t('common.of')} 3`}</TFASpan>
      </Header>
    </HeaderWrapper>
  );

  const footer = (
    <Footer>
      {isPrevStepVisible && (
        <StyledButton
          isPrevStep
          disabled={isPrevStepDisabled}
          onClick={handleTakeStepBack}
        >
          {t('common.previousStep')}
        </StyledButton>
      )}

      <StyledButton disabled={isNextStepDisabled} onClick={onClickNext}>
        {activeStep === Subview.Confirmation
          ? t('common.done')
          : t('common.nextStep')}
      </StyledButton>
    </Footer>
  );

  return (
    <>
      <BackLink />
      <CardContainer>
        <StyledCard footer={footer} header={header}>
          {activeStep === Subview.Confirmation && (
            <IconBox>
              <TFASuccessIcon />
            </IconBox>
          )}
          <Text>{text}</Text>
          {activeStep === Subview.QRCode && (
            <>
              {qrCode && key ? (
                <>
                  <Image src={qrCode} alt="QR Code" />
                  <TfaKey>{key}</TfaKey>
                </>
              ) : (
                <FullScreenLoader />
              )}
            </>
          )}

          {activeStep === Subview.PINCode && (
            <InputContainer>
              <StatefulPinInput
                onChange={(value, index, values) => setValues(values)}
                placeholder=""
                length={6}
                inputStyle={{ background: theme.colors.newLightestGrey }}
                borderColor={theme.colors.newLightestGrey}
                validBorderColor={borderColor}
                errorBorderColor={theme.colors.danger}
                onComplete={() => handleSubmit(values)}
                autoFocus
              />
            </InputContainer>
          )}
        </StyledCard>
      </CardContainer>
    </>
  );
};

export default TFAActivationSteps;
