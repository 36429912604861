import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const LinksTable = styled.table`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
`;

export const TableHead = styled.thead`
  width: 100%;
  padding: ${theme.space[2]} ${theme.space[2]} 0;
  background-color: ${theme.colors.lightestGrey};

  &:first-of-type {
    border-top-left-radius: ${theme.radii.md};
    border-bottom-left-radius: ${theme.radii.md};
  }

  &:last-of-type {
    border-top-right-radius: ${theme.radii.md};
    border-bottom-right-radius: ${theme.radii.md};
  }
`;

export const TableData = styled.td`
  width: 100%;
  max-height: 36px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: ${theme.space[2]};
  border-bottom: 1px solid ${theme.colors.lightestGrey};
  color: ${theme.colors.primeDarkGrey};
`;

export const TableLinkData = styled(TableData)`
  text-decoration: underline;
  color: ${theme.colors.primary};
  padding-right: ${theme.space[4]};
`;
