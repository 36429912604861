import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const Container = styled.div`
  margin-top: 8px;
  margin-bottom: 26px;
`;

export const RadioWrapper = styled.div`
  margin-bottom: 14px;
  display: flex;
  position: relative;
  cursor: pointer;
`;

export const OptionLabel = styled.label`
  padding-left: 25px;
  color: ${theme.colors.darkGray};
  cursor: pointer;
  position: absolute;
`;

export const CustomRadio = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid ${theme.colors.darkGray};
  border-radius: 50%;
`;

export const InitialRadio = styled.input`
  visibility: hidden;
  position: absolute;
  opacity: 0;
  height: 0;
  &:checked {
    & ~ ${CustomRadio} {
      background-color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
    }
  }
`;
