import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const FilterSelectList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: ${theme.space[3]};
  }
`;
