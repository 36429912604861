import styled from '@emotion/styled/macro';
import { Calendar } from 'primereact/calendar';
import { theme } from 'styles/theme';

type Props = {
  isInvalid?: boolean;
  append?: React.ReactNode;
  isIcon?: React.ReactNode;
  variant?: 'default' | 'filled' | 'blue';
  iserror?: string;
  isHigh?: boolean;
};

export const StyledDatePicker = styled(Calendar)(
  ({ variant, isHigh = false }: Props) => {
    const isFilled = variant === 'filled';
    return `

    &::placeholder {
        color: ${theme.colors.neutralDark};
        font-size: ${theme.fontSizes.xs};
    }

    &.p-inputwrapper {
        height: ${
          isHigh
            ? theme.dimensions.inputHeight
            : theme.dimensions.inputHeightLow
        };
        box-shadow: ${theme.shadows.md};
        transition: box-shadow 150ms, border-color 150ms;
        border: 1px solid ${
          isFilled ? theme.colors.neutral : theme.colors.neutralGray
        };
        border-radius: ${isFilled ? theme.radii.md : theme.radii.sm};
        width: 100%;
        cursor: pointer;
        
        &.p-inputwrapper-focus {
          box-shadow: ${theme.shadows.outline};
        }
        
        &:hover {
          border-color: ${theme.colors.newBlueInputHover};
        }
        
        &.p-invalid {
          border: 1px solid ${theme.colors.danger};
        }
    }
    
    & input {
        border-radius: ${theme.radii.sm};
        font-size: ${theme.fontSizes.sm};
        font-family: ${theme.fonts.regular};
        width: 100%;
        padding-left: ${theme.space[3]};
        border: none;
        background-color:${
          isFilled ? theme.colors.whiteLilac : theme.colors.white
        };
        ${!isFilled ? `border-right-width: 0;` : ''};
        cursor: pointer;

        &:enabled:focus {
          outline: none;
          box-shadow: none;
          border-color: transparent;
        }
    }

    & button {
        background-color:${
          isFilled ? theme.colors.whiteLilac : theme.colors.white
        };
        color: ${theme.colors.darkGray};
        border: none;
        border-left: none;
        &:focus {
            outline: none;
            box-shadow: none;
            border-color: transparent;
        }
        &:enabled:hover {
            background-color:${
              isFilled ? theme.colors.whiteLilac : theme.colors.white
            };
            color: ${theme.colors.darkGray};
            border-color: ${isFilled ? theme.colors.neutral : 'transparent'};
          }
  }
 
    & select {
        border-color: ${theme.colors.neutral};
        padding: ${theme.space[2]} ${theme.space[1]};
        font-family: ${theme.fonts.regular};
        font-size: ${theme.fontSizes.xs};
        background-color: ${theme.colors.whiteLilac};
        border-radius: ${theme.radii.sm};
        min-width: 100px;
    }
`;
  },
);

export const Wrapper = styled.div<Props>`
  position: relative;
`;

export const Label = styled.span<Props>`
  left: ${({ isIcon }) => (isIcon ? '25px' : '0')};
  display: block;
  text-transform: uppercase;
  font-size: ${theme.fontSizes.xxs};
  color: ${({ iserror }) =>
    iserror ? theme.colors.danger : theme.colors.neutralDark};
  font-family: ${theme.fonts.bold};
  margin: 0 0 ${theme.space[2]} 0;
  position: relative;
`;

export const FieldIcon = styled.div<Props>`
  position: absolute;
  left: 0;
  top: -5px;
  width: 2rem;
  height: 2rem;
  color: ${({ iserror }) =>
    iserror ? theme.colors.danger : theme.colors.darkGray};
`;
