import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { usersAPI } from 'api/users/endpoints';
import { UserPreferences } from 'api/users/models';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';
import { displaySnackbar } from 'store/slices/snackbarSlice';
import i18n from 'utils/i18n';

type PreferencesState = {
  preferences: UserPreferences | null;
  isLoading: boolean;
  error: string | null;
};

const INITIAL_STATE: PreferencesState = {
  preferences: null,
  isLoading: false,
  error: null,
};

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: INITIAL_STATE,
  reducers: {
    getPreferencesStart(state): void {
      state.isLoading = true;
    },
    getPreferencesSuccess(state, action: PayloadAction<UserPreferences>): void {
      state.error = null;
      state.preferences = action.payload;
      state.isLoading = false;
    },
    getPreferencesFail(state, action: PayloadAction<string>): void {
      state.error = action.payload;
      state.preferences = null;
      state.isLoading = false;
    },
    setPreferencesStart(state): void {
      state.isLoading = true;
    },
    setPreferencesSuccess(state, action: PayloadAction<UserPreferences>): void {
      state.error = null;
      state.preferences = action.payload;
      state.isLoading = false;
    },
    setPreferencesFail(state, action: PayloadAction<string>): void {
      state.error = action.payload;
      state.preferences = null;
      state.isLoading = false;
    },
  },
});

const {
  getPreferencesStart,
  getPreferencesSuccess,
  getPreferencesFail,
  setPreferencesStart,
  setPreferencesSuccess,
  setPreferencesFail,
} = preferencesSlice.actions;

export default preferencesSlice.reducer;

export const getMyPreferences = (): AppThunk => async (dispatch) => {
  dispatch(getPreferencesStart());

  try {
    const { data } = await usersAPI.getPreferences();
    dispatch(getPreferencesSuccess(data));
  } catch (error) {
    dispatch(
      getPreferencesFail(
        error?.message || i18n.t('errors.failedFetchingPreferences'),
      ),
    );
  }
};

export const setPreferences = (payload: UserPreferences): AppThunk => async (
  dispatch,
) => {
  dispatch(setPreferencesStart());

  try {
    await usersAPI.setPreferences(payload);
    dispatch(setPreferencesSuccess(payload));
    dispatch(
      displaySnackbar({
        message: i18n.t('userProfile.preferencesUpdated'),
        isError: false,
      }),
    );
  } catch (error) {
    dispatch(
      setPreferencesFail(
        error?.message || i18n.t('errors.failedAddingPreferences'),
      ),
    );
    dispatch(
      displaySnackbar({
        message: i18n.t('common.error'),
        isError: true,
      }),
    );
  }
};

export const selectLoggedInUserPreferences = createSelector(
  (state: RootState) => state.preferences,
  ({ preferences }) => preferences,
);
