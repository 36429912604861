import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory, useParams } from 'react-router-dom';

import { clearUserFilters } from '../../../Users/slices/usersFiltersSlice';
import { UserPayload, UserSkillPayload } from 'api/users/models';
import { DeleteButton } from 'components/forms/DeleteButton';
import { DeleteModal } from 'components/forms/DeleteModal';
import { Card, CardStack, FormHeader } from 'components/primitive';
import { BackLink, Button, Spin } from 'components/ui';
import { AvatarBox } from 'components/ui/AvatarBox';
import { CannotRemoveModal } from 'components/ui/CannotRemoveModal/CannotRemoveModal';
import { useSkills } from 'fetchers/hooks/users/useSkills';
import { useUser } from 'fetchers/hooks/users/useUser';
import { useUsers } from 'fetchers/hooks/users/useUsers';
import { Form, Formik } from 'formik';
import { Box, Flex } from 'rebass';
import { selectAuthUser } from 'screens/Auth/authSlice';
import { ChangePasswordFields } from 'screens/User/components/ChangePasswordFields';
import GeneralInfoFields, {
  FORM_TYPES,
} from 'screens/User/components/GeneralInfoFields';
import { UserFormWrapper } from 'screens/User/components/styled';
import { getSkillId } from 'screens/User/screens/Edit/utils';
import { removeUser, updateUser } from 'store/slices/usersSlice';
import checkPageForErrors from 'utils/checkForErrors';
import {
  confirmPageRefresh,
  removeConfirmationOnPageRefresh,
} from 'utils/functions';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

import { validationSchema } from './validationSchema';

const UserEdit: React.FC = () => {
  const [enableValidation, setEnableValidation] = useState<boolean>(false);
  const { userId } = useParams<{ userId: string }>();
  const { user } = useUser(userId);
  const { users } = useUsers();
  const { skills } = useSkills();

  const loggedUser = useSelector(selectAuthUser);

  const isOwnerEditing = loggedUser?.id === user?.id || false;

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);
  const [isCannotDeleteVisible, setCannotDeleteVisible] = useState(false);
  const hasAccessToDelete = usePermissions(PERMISSIONS.REMOVE_USER);

  useEffect(() => {
    dispatch(clearUserFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      confirmPageRefresh(false);
    };
  }, []);

  const sortedUsers = useMemo(() => {
    if (users) {
      return users
        .filter(({ full_name }) => full_name?.trim())
        .sort((a, b) => a.full_name.localeCompare(b.full_name));
    }
    return [];
  }, [users]);

  const getDeletedSKills = (values: UserPayload) => {
    const initialSkills = user?.skills;
    if (initialSkills) {
      return initialSkills
        .filter(
          (skill) =>
            !values.skills_attributes?.some(({ name }) => name === skill.name),
        )
        .map((skill) => ({ ...skill, _destroy: true }));
    }
  };

  const getDeletedSupervisors = (values: UserPayload) => {
    const initialSupervisors = user?.team_member_supervisors;
    if (initialSupervisors) {
      return initialSupervisors
        .filter(
          (supervisor) =>
            !values.team_member_supervisors_attributes?.some(
              (sup) => sup.id === supervisor.id,
            ),
        )
        .map(({ id, supervisor }) => ({
          id,
          _destroy: true,
          supervisor_id: supervisor.id,
        }));
    }
  };

  const handleSubmit = (values: UserPayload) => {
    if (user?.id) {
      const newValues = {
        ...values,
        full_name: values.full_name?.trim(),
        password: values?.password?.trim() || undefined,
        password_confirmation: values?.password
          ? values?.password_confirmation?.trim()
          : undefined,
      };
      if (typeof values.photo !== 'object') {
        delete newValues.photo;
      }
      const deletedSkills = getDeletedSKills(values);
      if (deletedSkills && newValues.skills_attributes) {
        newValues.skills_attributes = [
          ...newValues.skills_attributes,
          ...deletedSkills,
        ];
      }

      const deletedSupervisors = getDeletedSupervisors(values);
      if (deletedSupervisors && newValues.team_member_supervisors_attributes) {
        newValues.team_member_supervisors_attributes = [
          ...newValues.team_member_supervisors_attributes,
          ...deletedSupervisors,
        ];
      }

      const shouldMapExistingSkillsToNew =
        newValues.skills_attributes && user.skills && user.skills?.length > 0;

      if (shouldMapExistingSkillsToNew) {
        newValues.skills_attributes = newValues.skills_attributes
          ?.map((skill) => {
            const isSkillAlreadySelected = user.skills?.find(
              (userSkill) =>
                userSkill.name === skill.name &&
                userSkill.level === skill.level,
            );

            if (isSkillAlreadySelected) {
              return {
                ...skill,
                id: isSkillAlreadySelected.id,
              };
            } else {
              return skill;
            }
          })
          .flat() as UserSkillPayload[];
      }

      dispatch(updateUser(user.id, newValues));
    }
  };

  const handleDeleteUser = () => {
    if (user?.team_members.length) {
      setCannotDeleteVisible(true);
    } else {
      setDeleteModalVisibility(true);
    }
  };

  const handleRemoveModal = () => {
    setDeleteModalVisibility(false);
    dispatch(
      removeUser({
        id: user?.id || -1,
        onSuccess: () => {
          history.replace(`/users`);
        },
      }),
    );
  };

  const checkPageForErrorsAndChangeActivity = () => {
    checkPageForErrors();
  };

  const getHoursDefaultValue = (hoursWeekly: number | undefined) => {
    if (hoursWeekly || hoursWeekly === 0) {
      return hoursWeekly;
    }
    return 40;
  };

  const getDefaultValues = () => ({
    full_name: user?.full_name,
    email: user?.email,
    use_google_avatar: user?.use_google_avatar,
    photo: user?.photos?.photo?.url || '',
    team_id: user?.team?.id,
    position_id: user?.position?.id,
    seniority_id: user?.seniority?.id,
    department_id: user?.department?.id,
    skills_attributes: user?.skills?.map((skill) => ({
      ...skill,
      skill_name_id: getSkillId(skill, skills ? skills : []),
    })),
    github_nick: user?.github_nick,
    hours_weekly: getHoursDefaultValue(user?.hours_weekly),
    user_types: user?.user_types,
    available_resource: user?.available_resource,
    active: user?.active,
    notification_setting_attributes: user?.notification_setting,
    google_2fa_enabled: user?.google_2fa_enabled,
    team_member_supervisors_attributes: user?.team_member_supervisors?.map(
      ({ id, supervisor }) => ({
        id,
        supervisor_id: supervisor.id,
        _destroy: false,
      }),
    ),
    show_jira_tickets: user?.show_jira_tickets,
    password: '',
    password_confirmation: '',
  });

  return (
    <>
      <BackLink state={{ from: 'edit-profile' }} />
      <UserFormWrapper>
        <FormHeader>{t('userProfile.editUser')}</FormHeader>
        {hasAccessToDelete && (
          <DeleteButton
            onClick={handleDeleteUser}
            title={t('userProfile.deleteUser')}
          />
        )}
        {users && skills && user ? (
          <Formik
            initialValues={getDefaultValues()}
            onSubmit={handleSubmit}
            validationSchema={() => validationSchema(t)}
            validateOnBlur={enableValidation}
            validateOnChange={enableValidation}
          >
            {({ dirty, handleSubmit, submitCount }) => {
              if (dirty && submitCount === 0) {
                confirmPageRefresh(true);
              } else {
                removeConfirmationOnPageRefresh();
              }

              return (
                <Form
                  onSubmit={(event) => {
                    setEnableValidation(true);
                    event.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Prompt
                    when={dirty && submitCount === 0}
                    message={t('prompts.userEditForm')}
                  />
                  <CardStack>
                    <Card>
                      <GeneralInfoFields
                        users={sortedUsers}
                        skills={skills}
                        type={FORM_TYPES.EDIT}
                      />

                      {isOwnerEditing ? <ChangePasswordFields /> : null}
                    </Card>
                    <Card>
                      <Box sx={{ textAlign: 'center' }} pb={4}>
                        <Link to={`/users/${user?.id}`}>
                          <Button variant="ghost" type="button">
                            {t('common.cancel')}
                          </Button>
                        </Link>
                        <Button
                          type="submit"
                          onClick={checkPageForErrorsAndChangeActivity}
                        >
                          {t('userProfile.saveUser')}
                        </Button>
                      </Box>
                    </Card>
                  </CardStack>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Spin />
        )}
      </UserFormWrapper>
      {hasAccessToDelete && (
        <>
          <DeleteModal
            content={
              <Flex flexDirection="column" alignItems="center">
                <AvatarBox
                  photo={user?.photo?.mini?.url || ''}
                  name={user?.full_name || ''}
                />
                <Box as="p" mb={12}>
                  {t('prompts.deleteUser')}
                </Box>
              </Flex>
            }
            headerTitle={t('userProfile.removeUser')}
            isOpen={isDeleteModalVisible}
            onClose={() => setDeleteModalVisibility(false)}
            onRemove={handleRemoveModal}
          />
          <CannotRemoveModal
            isOpen={isCannotDeleteVisible}
            setIsOpen={setCannotDeleteVisible}
            usersBlockingRemoval={user?.team_members || []}
            itemName="Supervisor"
          />
        </>
      )}
    </>
  );
};

export default UserEdit;
