import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';
import { HEIGHT_OF_TABLE_HEADERS_PLUS_TABLE_GAP } from 'utils/constants';

export const TableWrapper = styled(Flex)`
  background-color: ${theme.colors.white};
  border-radius: ${theme.radii.lg};
  border: 1px solid ${theme.colors.neutral};
`;

export const TableScroller = styled.div`
  overflow: auto;
  max-height: ${`calc(100vh - ${HEIGHT_OF_TABLE_HEADERS_PLUS_TABLE_GAP})`};
`;

export const UlWrapper = styled.ul`
  width: fit-content;
  min-width: 100%;
`;
