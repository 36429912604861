import { useCallback, useEffect, useState } from 'react';

type KeyboardHandler = (event: KeyboardEvent) => void;
type UseKeyPressProps = {
  targetKey: string;
  downHandler?: KeyboardHandler;
  upHandler?: KeyboardHandler;
  listenWhen: boolean;
};

export const useKeyPress = ({
  targetKey,
  downHandler,
  upHandler,
  listenWhen,
}: UseKeyPressProps) => {
  // keep track of whether the target key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // handle key down
  const onDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === targetKey) {
        setKeyPressed(true);

        if (typeof downHandler === 'function') {
          downHandler(event);
        }
      }
    },
    [targetKey, downHandler],
  );

  // handle key up
  const onUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === targetKey) {
        setKeyPressed(false);

        if (typeof upHandler === 'function') {
          upHandler(event);
        }
      }
    },
    [targetKey, upHandler],
  );

  // add event listeners
  useEffect(() => {
    if (listenWhen) {
      window.addEventListener('keydown', onDown);
      window.addEventListener('keyup', onUp);

      return function cleanup() {
        window.removeEventListener('keydown', onDown);
        window.removeEventListener('keyup', onUp);
      };
    }
  }, [listenWhen, onDown, onUp]);

  return keyPressed;
};
