import React from 'react';
import { TableCellProps } from 'react-table';

import { TableSubCelllWrapper } from './styled';

type Props = {
  lastCellShorter?: boolean;
} & TableCellProps;

export const TableSubCell: React.FC<Props> = ({
  children,
  lastCellShorter,
  ...props
}) => (
  <TableSubCelllWrapper lastCellShorter={lastCellShorter} {...props}>
    {children}
  </TableSubCelllWrapper>
);
