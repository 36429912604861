import { SelectVariant } from '../';
import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { verticalScroll } from 'components/primitive';
import { theme } from 'styles/theme';

type Props = {
  highlighted?: boolean;
  selected?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  variant?: SelectVariant;
  isOpen?: boolean;
  width?: string;
};

const selectFiltersToggleButton = ({ isOpen, selected }: Props) => css`
  height: 2.5rem;
  min-width: 12.5rem;
  padding: 0 ${theme.space[4]};
  border: 1px solid ${theme.colors.neutral};
  border-radius: ${theme.radii.xl};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.bermudaGray};
  font-size: ${theme.fontSizes.sm};
  transition: all 150ms;
  cursor: pointer;
  background-color: ${isOpen || selected ? theme.colors.white : 'transparent'};

  &:focus {
    transition-delay: 150ms;
    outline: none;
    box-shadow: ${theme.shadows.outline};
  }
`;

const selectDefaultToggleButton = ({
  invalid,
  disabled,
  selected,
}: Props) => css`
  width: 100%;
  height: ${theme.sizes.md};
  padding: 0 ${theme.space[2]};
  background-color: ${theme.colors.whiteLilac};
  border-radius: ${theme.radii.md};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${invalid ? theme.colors.danger : theme.colors.neutral};
  opacity: ${disabled ? 0.5 : 1};
  color: ${selected ? theme.colors.darkGray : theme.colors.darkGrayOpacity};
  transition: all 150ms;

  &:focus {
    transition-delay: 150ms;
    outline: none;
    box-shadow: ${theme.shadows.outline};
  }
`;

export const SelectLabel = styled.label<Props>`
  display: inline-block;
  text-transform: uppercase;
  font-size: ${theme.fontSizes.xxs};
  color: ${({ invalid }) =>
    invalid ? theme.colors.danger : theme.colors.neutralDark};
  font-family: ${theme.fonts.bold};
  margin-bottom: ${theme.space[2]};
`;

export const SelectClearButton = styled.span`
  margin: 0;
  padding: 0;
  line-height: 0;
  border-radius: ${theme.radii.md};
  transition: box-shadow 150ms;

  &:focus {
    outline: none;
    box-shadow: ${theme.shadows.outline};
  }
`;

export const SelectToggleButton = styled.button<Props>`
  ${({ variant, invalid, disabled, selected, isOpen }) =>
    variant === 'default'
      ? selectDefaultToggleButton({ invalid, disabled, selected })
      : selectFiltersToggleButton({ isOpen, selected })}
`;

export const SelectMenu = styled.ul<Props>`
  left: 0;
  right: 0;
  position: absolute;
  z-index: ${theme.zIndices[3]};
  color: ${theme.colors.darkGray};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.mdStrong};
  border-radius: ${theme.radii.md};
  max-height: 300px;
  width: ${(props) => props.width};
  margin-top: 10px;
  overflow-y: auto;
  outline: none;

  ${verticalScroll}
`;

export const SelectMenuItem = styled.li<Props>`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${theme.sizes.md};
  padding: 0.8rem 1rem;
  text-align: start;
  outline: none !important;
  background-color: ${({ highlighted, selected }) =>
    selected
      ? theme.colors.solitude
      : highlighted
      ? theme.colors.lightBlue
      : 'initial'};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.lightBlue};
  }
`;
