import { mutateIntegrationStatus } from '../mutations/mutateIntegrationStatus';
import { mutateOrganizationSettings } from '../mutations/mutateOrganizationSettings';
import { organizationSettingsAPI } from 'api/organizationSettings/endpoints';
import { TogglIntegrationPayload } from 'api/organizationSettings/models';

export const addTogglIntegration = async (
  formData: TogglIntegrationPayload,
) => {
  await organizationSettingsAPI.addTogglIntegration(formData);
  await mutateOrganizationSettings();
  await mutateIntegrationStatus();
};
