import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { phasesAPI } from 'api/phases/endpoints';
import type { PhaseMemberPayload } from 'api/phases/models';
import type { UserHoursPayload } from 'api/projects/models';
import type { AppThunk } from 'store';
import i18n from 'utils/i18n';

import {
  getProjectsWeeks,
  getProjects,
  getProjectsWeeksWithPartialUpdate,
} from './projectsSlice';
import { displaySnackbar } from './snackbarSlice';
import { getUsersWeeks, getUsersWeeksWithPartialUpdate } from './usersSlice';

type ProjectsPhasesMembersState = {
  isLoading: boolean;
  error: null | string;
};

const INITIAL_STATE: ProjectsPhasesMembersState = {
  isLoading: false,
  error: null,
};

const projectsPhasesMembersSlice = createSlice({
  name: 'projects.phasesMembers',
  initialState: INITIAL_STATE,
  reducers: {
    addMemberToPhaseStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    addMemberToPhaseSuccess(state) {
      state.error = null;
      state.isLoading = false;
    },
    addMemberToPhaseFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },

    deleteMemberFromPhaseStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    deleteMemberFromPhaseSuccess(state) {
      state.error = null;
      state.isLoading = false;
    },
    deleteMemberFromPhaseFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },

    confirmMemberToPhaseStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    confirmMemberToPhaseSuccess(state) {
      state.error = null;
      state.isLoading = false;
    },
    confirmMemberToPhaseFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },

    setMemberHoursOnPhaseStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    setMemberHoursOnPhaseSuccess(state) {
      state.error = null;
      state.isLoading = false;
    },
    setMemberHoursOnPhaseFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },

    updateMemberHoursOnPhaseStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    updateMemberHoursOnPhaseSuccess(state) {
      state.error = null;
      state.isLoading = false;
    },
    updateMemberHoursOnPhaseFail(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export default projectsPhasesMembersSlice.reducer;

const {
  addMemberToPhaseStart,
  addMemberToPhaseSuccess,
  addMemberToPhaseFail,
  setMemberHoursOnPhaseStart,
  setMemberHoursOnPhaseSuccess,
  setMemberHoursOnPhaseFail,
  updateMemberHoursOnPhaseStart,
  updateMemberHoursOnPhaseSuccess,
  updateMemberHoursOnPhaseFail,
} = projectsPhasesMembersSlice.actions;

export const addMemberToPhase = (
  payload: PhaseMemberPayload,
  onSuccessCallback: any,
): AppThunk => async (dispatch, getState) => {
  dispatch(addMemberToPhaseStart());

  try {
    await phasesAPI
      .addMemberToPhase(payload)
      .then(() => {
        dispatch(
          displaySnackbar({
            message: i18n.t('users.userAddSuccess'),
          }),
        );
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: i18n.t('users.userAddFail'),
            isError: true,
          }),
        );
      });

    dispatch(
      getProjectsWeeks({
        weeksNumbers: getState().projects.data.projectsWeeks.map((week) =>
          Number(week.week),
        ),
        withLoading: false,
      }),
    );
    dispatch(
      getUsersWeeks({
        weeksNumbers: getState().projects.data.projectsWeeks.map((week) =>
          Number(week.week),
        ),
        withLoading: false,
      }),
    );
    dispatch(getProjects({ withLoading: false }));
    dispatch(addMemberToPhaseSuccess());

    await onSuccessCallback();
  } catch (error) {
    dispatch(
      addMemberToPhaseFail(error?.message || 'Error adding member to phase'),
    );
  }
};

export const setMemberHoursOnPhase = (
  formData: UserHoursPayload,
): AppThunk => async (dispatch) => {
  dispatch(setMemberHoursOnPhaseStart());

  try {
    await phasesAPI.setMemberHoursOnPhase(formData);

    dispatch(
      getProjectsWeeksWithPartialUpdate({
        weeksNumbers: [formData.week_number],
        withLoading: false,
      }),
    );
    dispatch(
      getUsersWeeksWithPartialUpdate({
        weeksNumbers: [formData.week_number],
        withLoading: false,
      }),
    );
    dispatch(setMemberHoursOnPhaseSuccess());
  } catch (error) {
    if (error.data?.errors?.week_number[0].includes('hidden')) {
      dispatch(
        displaySnackbar({
          message: i18n.t('errors.cannotSetHoursForHiddenUser'),
          isError: true,
        }),
      );
    } else {
      dispatch(
        setMemberHoursOnPhaseFail(
          error?.message || 'Error setting member hours',
        ),
      );
    }
  }
};

export const updateMemberHoursOnPhase = ({
  id,
  value,
  weekNumber,
}: {
  id: number;
  value: number;
  weekNumber: number;
}): AppThunk => async (dispatch) => {
  dispatch(updateMemberHoursOnPhaseStart());

  try {
    await phasesAPI.updateMemberHoursOnPhase(id, value);

    dispatch(
      getProjectsWeeksWithPartialUpdate({
        weeksNumbers: [weekNumber],
        withLoading: false,
      }),
    );
    dispatch(
      getUsersWeeksWithPartialUpdate({
        weeksNumbers: [weekNumber],
        withLoading: false,
      }),
    );
    dispatch(updateMemberHoursOnPhaseSuccess());
  } catch (error) {
    dispatch(
      updateMemberHoursOnPhaseFail(
        error?.message || 'Error setting member hours',
      ),
    );
  }
};
