import React from 'react';

import { FieldSubLabel } from 'components/primitive';
import { CalendarProps } from 'primereact/calendar';

import { StyledDatePicker, Wrapper, FieldIcon, Label } from './styled';

export interface DatePickerCustomProps {
  iserror?: string;
  variant?: 'default' | 'filled' | 'blue';
  isIcon?: HTMLElement;
  isLabelVisible?: boolean;
  icon?: HTMLElement;
  label?: string;
  subLabel?: string;
}

export const PrimeDatePicker = ({
  iserror,
  icon,
  label,
  isLabelVisible = !!label,
  subLabel,
  dateFormat = 'dd.mm.yy',
  readOnlyInput = true,
  yearRange = '2010:2030',
  showIcon = true,
  monthNavigator = true,
  yearNavigator = true,
  ...restProps
}: CalendarProps & DatePickerCustomProps) => {
  return (
    <Wrapper>
      {icon ? <FieldIcon iserror={iserror}>{icon}</FieldIcon> : null}
      {isLabelVisible ? (
        <Label isIcon={icon} iserror={iserror}>
          {label}
        </Label>
      ) : null}
      {subLabel ? <FieldSubLabel>{subLabel}</FieldSubLabel> : null}
      <StyledDatePicker
        {...restProps}
        className={iserror ? 'p-invalid' : ''}
        dateFormat={dateFormat}
        readOnlyInput={readOnlyInput}
        yearRange={yearRange}
        showIcon={showIcon}
        monthNavigator={monthNavigator}
        yearNavigator={yearNavigator}
      />
    </Wrapper>
  );
};
