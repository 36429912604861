import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useParams } from 'react-router-dom';

import { useUser } from 'fetchers/hooks/users/useUser';
import { selectAuthUser } from 'screens/Auth/authSlice';
import { getSkills } from 'screens/Users/slices/skillsSlice';
import { getProjects } from 'store/slices/projectsSlice';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

import TFAActivationSteps from './components/TFAActivationSteps';
import UserAdd from './screens/Add';
import UserEdit from './screens/Edit';
import ProjectPreferences from './screens/Edit/ProjectPreferencesForm';
import UserView from './screens/View';

const User: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { user } = useUser(userId);
  const loggedUser = useSelector(selectAuthUser);
  const isOwnerEditing = loggedUser?.id === user?.id || false;

  const dispatch = useDispatch();
  const canAddUser = usePermissions(PERMISSIONS.ADD_USER);
  const canEditUser = usePermissions(PERMISSIONS.EDIT_USER);
  const canEditProfile = usePermissions(PERMISSIONS.ME);

  useEffect(() => {
    dispatch(getProjects({}));
    dispatch(getSkills());
  }, [dispatch]);

  return (
    <section>
      <Switch>
        {canAddUser && (
          <Route path="/users/new">
            <UserAdd />
          </Route>
        )}
        <Route exact path="/users/:userId">
          <UserView />
        </Route>
        {(canEditUser || canEditProfile) && (
          <Route exact path="/users/:userId/edit">
            <UserEdit />
          </Route>
        )}
        {isOwnerEditing && (
          <>
            <Route path="/users/:userId/edit/two-factor-authentication">
              <TFAActivationSteps />
            </Route>
            <Route path="/users/:userId/edit/project-preferences">
              <ProjectPreferences />
            </Route>
          </>
        )}
      </Switch>
    </section>
  );
};

export default User;
