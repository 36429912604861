import { mutateProject } from '../mutations/mutateProject';
import { phasesAPI } from 'api/phases/endpoints';
import { PhaseMemberPayload } from 'api/phases/models';
import { mutateProjects } from 'fetchers/mutations/mutateProjects';
import { mutateProjectsWeeks } from 'fetchers/mutations/mutateProjectsWeeks';
import { AppDispatch } from 'store';
import { displaySnackbar } from 'store/slices/snackbarSlice';
import i18n from 'utils/i18n';

export const addUserToPhase = async (
  formData: PhaseMemberPayload,
  dispatch: AppDispatch,
  projectId?: number,
) => {
  try {
    await phasesAPI.addMemberToPhase(formData);

    if (projectId) {
      await mutateProject(projectId.toString());
    }
    await mutateProjects();
    await mutateProjectsWeeks();
  } catch (e) {
    dispatch(
      displaySnackbar({
        message: i18n.t('errors.couldNotAddUserToAProject'),
        isError: true,
      }),
    );
  }
};
