import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { usersAPI } from 'api/users/endpoints';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { BorderBox } from 'components/primitive/BorderBox/BorderBox';
import { FullScreenLoader } from 'components/ui/FullScreenLoader';
import { WeekDatePicker } from 'components/ui/WeekDatePicker';
import { useProjects } from 'fetchers';
import { Flex } from 'rebass';
import { SideControls } from 'screens/Projects/components/SideControls';
import { selectProjectsFiltersData } from 'screens/Projects/projectsFiltersSlice';
import { ListHeaderWrapper } from 'screens/Users/components/listStyles';
import {
  selectIsAllRowsExpanded,
  setAllExpandedRow,
} from 'store/slices/projectsSlice';
import { selectSelectedWeeks } from 'store/slices/selectedWeeksSlice';
import { theme } from 'styles/theme';

interface Props {
  type?: string;
}

export const PhasesTableHeader = ({ type }: Props) => {
  const dispatch = useDispatch();
  const { projects } = useProjects();
  const { t } = useTranslation();

  const isSingleProjectView = type === 'project';

  const [isGenerating, setIsGenerating] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const isAllRowsExpanded = useSelector(selectIsAllRowsExpanded);
  const selectedWeeks = useSelector(selectSelectedWeeks) || [];
  const { members } = useSelector(selectProjectsFiltersData);

  useEffect(() => {
    setIsDisabled(!projects?.length);
  }, [projects]);

  const handleGenerateReport = async () => {
    setIsDisabled(true);
    setIsGenerating(true);

    const phasesIds = projects?.map((p) => p.phase.id);
    const weeksNumbers = selectedWeeks.map((w) => w.weekNumber);
    await usersAPI.getWeeksReport({
      phasesIds,
      usersIds: members,
      weeksNumbers,
    });

    setIsGenerating(false);
    setIsDisabled(false);
  };

  const handleExpandingProjects = () => {
    dispatch(setAllExpandedRow());
  };

  return (
    <>
      {isGenerating && <FullScreenLoader />}
      <ListHeaderWrapper justifyContent="space-between">
        <Flex>
          <WeekDatePicker variant="table" />
        </Flex>
        <Flex>
          <BorderBox left={true}>
            <PrimeTextButton
              label={
                isAllRowsExpanded
                  ? isSingleProjectView
                    ? t('projects.collapseAllPhases')
                    : t('projects.collapseAllProjects')
                  : isSingleProjectView
                  ? t('projects.expandAllPhases')
                  : t('projects.expandAllProjects')
              }
              onClick={handleExpandingProjects}
              icon={
                isAllRowsExpanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'
              }
              variant="secondary"
              option="list"
              fontSize={theme.fontSizes.sm}
            />
          </BorderBox>
          {!isSingleProjectView && (
            <>
              <BorderBox left={true}>
                <Flex alignItems="center" justifyContent="center">
                  <PrimeTextButton
                    label={t('projects.generateReport')}
                    onClick={handleGenerateReport}
                    icon="pi pi-download"
                    variant="secondary"
                    disabled={isDisabled}
                    option="list"
                    fontSize={theme.fontSizes.sm}
                    tooltipContent={t('projects.generateReportTooltip')}
                  />
                </Flex>
              </BorderBox>
              <BorderBox left={true}>
                <SideControls />
              </BorderBox>
            </>
          )}
        </Flex>
      </ListHeaderWrapper>
    </>
  );
};
