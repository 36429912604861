import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Project, ProjectPhase } from 'api/projects/models';
import { fetcherWithArgs, getArgsAsString } from 'fetchers/fetcher';
import { selectProjectsFiltersData } from 'screens/Projects/projectsFiltersSlice';
import useSWR from 'swr';
import { initialSortKey } from 'utils/constants';
import { getFromStorage } from 'utils/localStorage';

type Projects = {
  projects: Project[];
};

export type ProjectWithPhase = {
  project: Project;
  phase: ProjectPhase;
};

export type ToggleVisibility = {
  phaseId: number;
  userId: number;
};

const API_URL = '/projects';

export const DEFAULT_SETTINGS = [
  {
    only_with_phases: true,
  },
];

export const useProjects = () => {
  const hasBeenSorted = getFromStorage(initialSortKey);
  const {
    personsResponsible,
    projectTypes,
    members,
    isActive,
    projectsId,
    teamsIds,
    phaseTypesIds,
    isArchived,
  } = useSelector(selectProjectsFiltersData);
  const settings = useMemo(
    () => [
      ...DEFAULT_SETTINGS,
      { phase_status: isActive ? 'active' : null },
      { projects_ids: projectsId },
      { responsible_users_ids: personsResponsible },
      { users_ids: members },
      { project_types_ids: projectTypes },
      { teams_ids: teamsIds },
      { phase_types_ids: phaseTypesIds },
      { archived: isArchived },
    ],
    [
      isArchived,
      isActive,
      projectsId,
      personsResponsible,
      members,
      projectTypes,
      teamsIds,
      phaseTypesIds,
    ],
  );
  const settingsId = getArgsAsString(settings);
  const { data } = useSWR<Projects>(
    hasBeenSorted ? [API_URL, settingsId] : null,
    (url) => fetcherWithArgs(url, settings),
  );

  const projectsPhases = useMemo(
    () =>
      data?.projects.flatMap((project) =>
        project.phases.map((phase) => ({
          project,
          phase,
        })),
      ),
    [data?.projects],
  );

  return {
    isLoading: !data,
    projects: projectsPhases,
    projectsWithPhases: data?.projects,
  };
};
