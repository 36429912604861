import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { TooltipContent } from './styled';

type TooltipProps = {
  render: ({
    onMouseEnter,
    onMouseLeave,
  }: {
    onMouseEnter: (event: React.MouseEvent) => void;
    onMouseLeave: () => void;
    triggerRef: React.Ref<any>;
  }) => void;
};

type TooltipPosition = {
  left: number;
  top: number;
};

const TOP_OFFSET = 8;
const CORNER_OFFSET = 20;

export const Tooltip: React.FC<TooltipProps> = ({ children, render }) => {
  const triggerElement = useRef<any>(null);
  const tooltipElement = useRef<HTMLDivElement>(null);
  const [isTooltipVisible, setTooltipVisibility] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({
    left: 0,
    top: 0,
  });
  const modalRoot = document.getElementById('modal-root');

  const handleMouseEnter = () => {
    if (!triggerElement.current) {
      return;
    }

    const { left, top } = triggerElement.current.getBoundingClientRect();

    setTooltipVisibility(true);
    handleTooltipPosition({ left, top });
  };

  const handleMouseLeave = () => {
    setTooltipVisibility(false);
  };

  const handleTooltipPosition = ({ left, top }: TooltipPosition) => {
    if (!triggerElement.current || !tooltipElement.current) {
      return;
    }

    setTooltipPosition({
      left: getLeftPosition(left),
      top: getTopPosition(top),
    });
  };

  const getLeftPosition = (left: number) => {
    if (!triggerElement.current || !tooltipElement.current) {
      return 0;
    }

    const defaultLeft = Math.round(
      left +
        triggerElement.current.offsetWidth / 2 -
        tooltipElement.current.offsetWidth / 2,
    );

    if (defaultLeft < CORNER_OFFSET) {
      return CORNER_OFFSET;
    }

    if (
      window.innerWidth <=
      defaultLeft + tooltipElement.current.offsetWidth + CORNER_OFFSET
    ) {
      return Math.round(
        window.innerWidth - tooltipElement.current.offsetWidth - CORNER_OFFSET,
      );
    }

    return Math.round(defaultLeft);
  };

  const getTopPosition = (top: number) => {
    if (!triggerElement.current || !tooltipElement.current) {
      return 0;
    }

    const defaultTop = top + triggerElement.current.offsetHeight + TOP_OFFSET;

    if (
      defaultTop + tooltipElement.current.offsetHeight + CORNER_OFFSET >=
      window.innerHeight
    ) {
      return (
        defaultTop -
        tooltipElement.current.offsetHeight -
        triggerElement.current.offsetHeight -
        TOP_OFFSET * 2
      );
    }

    return defaultTop;
  };

  const getTooltipStyles = () => {
    const { top, left } = tooltipPosition;

    return {
      transform: `translate3d(${left}px, ${top}px, 0)`,
    };
  };

  return (
    <>
      {render({
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        triggerRef: triggerElement,
      })}
      {modalRoot &&
        createPortal(
          <TooltipContent
            isVisible={isTooltipVisible}
            ref={tooltipElement}
            style={getTooltipStyles()}
          >
            {children}
          </TooltipContent>,
          modalRoot,
        )}
    </>
  );
};
