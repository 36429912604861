import { UserSeniority } from 'api/users/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';
import { sortByKey } from 'utils/sortByKey';

const API_URL = '/seniorities';

type SeniorityPayload = {
  seniorities: UserSeniority[];
};

export const useSeniority = () => {
  const { data } = useSWR<SeniorityPayload>(API_URL, fetcher);

  return {
    isLoading: !data,
    seniority: data?.seniorities.sort(sortByKey('name')) || [],
  };
};
