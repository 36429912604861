import styled from '@emotion/styled/macro';
import { Skeleton } from 'primereact/skeleton';
import { theme } from 'styles/theme';

export const Name = styled.h1`
  font-size: ${theme.fontSizes.xl};
  font-family: ${theme.fonts.medium};
  color: ${theme.colors.darkGray};
  height: ${theme.space[8]};
  margin: 0 0 ${theme.space[2]} 0;
`;

export const Email = styled.h5`
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.darkGray};
  line-height: ${theme.lineHeights.md};
  margin-bottom: ${theme.space[1]};
  margin: ${theme.space[2]} 0;
  //firefox font fix
  font-weight: inherit;
`;

export const LastEdited = styled.div`
  display: flex;
  font-family: ${theme.fonts.medium};
  font-size: ${theme.fontSizes.xs};
  color: ${theme.colors.neutralDark};
  line-height: ${theme.lineHeights.normal};
  margin: ${theme.space[2]} 0;

  div {
    margin-right: 3px;
  }

  & span,
  & a {
    margin-right: 3px;
  }
`;

export const TaskInplace = styled(Skeleton)`
  background-color: ${theme.colors.white};
  margin-bottom: ${theme.space[2]};
  box-shadow: 0 1px 2px 0 rgb(189 199 211 / 40%);

  &::after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(220, 220, 220, 0.4),
      rgba(255, 255, 255, 0)
    );
  }
`;
