import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

import { AddNewMemberModal } from '../MembersFooterData/AddNewMemberModal';
import { ProjectPhaseMember } from 'api/projects/models';
import { HiddenUsersHeader } from 'components/layout/Tables/ProjectsPhasesTable/components/HiddenMembers/styled';
import { SideDialog } from 'components/prime/SideDialog/SideDialog';
import { TextLinkWrapper } from 'components/ui/TextLinkWrapper';
import { Tooltip } from 'components/ui/Tooltip';
import { Box, Flex } from 'rebass';
import { setExpandedRow } from 'store/slices/projectsSlice';
import { theme } from 'styles/theme';

type Props = {
  expanderId: string;
  isAllRowsExpanded?: boolean;
  isExpanded: boolean;
  renderList: () => void;
  title: string;
  projectAvatar?: string;
  projectId?: number;
  phaseId?: number;
  projectName?: string;
  members?: ProjectPhaseMember[];
};

export const HiddenUsers: React.FC<Props> = ({
  expanderId,
  isAllRowsExpanded,
  isExpanded,
  renderList,
  title,
  projectAvatar,
  members,
  projectId,
  projectName,
  phaseId,
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const areHiddenMembers = useMemo(() => {
    const hidden = members?.filter(
      (member) => member.phase_user_range.hidden_at,
    );
    return hidden && hidden.length > 0;
  }, [members]);

  const handleClick = () => {
    if (members?.length) {
      dispatch(
        setExpandedRow(expanderId, isAllRowsExpanded ? !isExpanded : undefined),
      );
    }
  };

  const handleInfoClick = (event: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
  };

  return (
    <>
      <HiddenUsersHeader
        as="header"
        isExpanded={isExpanded}
        tabIndex={0}
        clickable={!!areHiddenMembers}
      >
        <Box ml={4}>
          <TextLinkWrapper
            onClick={(event) => {
              event.stopPropagation();
              setModalOpen(true);
            }}
            color={theme.colors.primaryLight}
          >
            + {t('projects.addNewMember')}
          </TextLinkWrapper>
        </Box>
        {!!areHiddenMembers && (
          <>
            <Flex
              onClick={handleClick}
              alignItems="center"
              ml={theme.space[11]}
              sx={{
                '&:hover i, &:hover div': {
                  color: theme.colors.primary,
                },
              }}
              mr={4}
            >
              <Box
                as="i"
                className={isExpanded ? 'pi pi-eye-slash' : 'pi pi-eye'}
                fontSize={theme.fontSizes.xxs}
                color="veryDarkGray"
                mr={2}
              />
              <TextLinkWrapper>{title}</TextLinkWrapper>
            </Flex>
            <Tooltip
              render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
                <Box
                  marginLeft="6px"
                  onClick={handleInfoClick}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  ref={triggerRef}
                  sx={{
                    cursor: 'default',
                  }}
                >
                  <AiOutlineQuestionCircle />
                </Box>
              )}
            >
              {t('projects.hoursTooltip')}
            </Tooltip>
          </>
        )}
      </HiddenUsersHeader>
      {isExpanded && renderList()}
      {isModalOpen && (
        <SideDialog
          visible={isModalOpen}
          position="top-right"
          header={`${t('projects.addTeamMemberTo')} ${projectName}`}
          modal={false}
          width="auto"
          onHide={() => setModalOpen(false)}
        >
          <AddNewMemberModal
            onClose={() => setModalOpen(false)}
            project={{
              avatar: projectAvatar,
              id: projectId,
              name: projectName,
            }}
            phaseId={phaseId!}
            members={members || []}
          />
        </SideDialog>
      )}
    </>
  );
};
