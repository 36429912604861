import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { usersAPI } from 'api/users/endpoints';
import { UserType, Roles } from 'api/users/models';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';

type UserTypesState = {
  userTypes: UserType[];
  isLoading: boolean;
  error: string | null;
};

const INITIAL_STATE: UserTypesState = {
  userTypes: [],
  isLoading: false,
  error: null,
};

const UserTypesSlice = createSlice({
  name: 'user.types',
  initialState: INITIAL_STATE,
  reducers: {
    getUserTypesStart(state): void {
      state.isLoading = true;
    },
    getUserTypesSuccess(state, action: PayloadAction<any>): void {
      const userTypes = action.payload;
      state.isLoading = false;
      state.error = null;
      state.userTypes = userTypes;
    },
    getUserTypesFail(state, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
      state.userTypes = [];
    },
  },
});

const {
  getUserTypesStart,
  getUserTypesSuccess,
  getUserTypesFail,
} = UserTypesSlice.actions;

export default UserTypesSlice.reducer;

export const getUserTypes = (): AppThunk => async (dispatch) => {
  dispatch(getUserTypesStart());

  try {
    const {
      data: { user_types },
    } = await usersAPI.getUserTypes();

    dispatch(getUserTypesSuccess(user_types));
  } catch (error) {
    dispatch(getUserTypesFail(error?.message || 'User types not fetched'));
  }
};

function normalizeUserTypes(
  userTypes: UserType[],
): { value: string; label: string }[] {
  return userTypes
    .filter(
      (type) =>
        type.name &&
        type.name !== Roles.USER &&
        type.name !== ('project_manager' as any),
    )
    .map((type) => ({
      value: type.name,
      label: type.name
        .split('_')
        .map((n) => n[0]?.toUpperCase() + n.slice(1))
        .join(' '),
    }));
}

const selectUserTypes = (state: RootState) => state.userTypes.userTypes;

export const selectNormalizedUserTypes = createSelector(
  [selectUserTypes],
  (sel) => normalizeUserTypes(sel),
);
