import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { NewTag } from 'components/prime';
import { Avatar, Spin } from 'components/ui';
import { useProject } from 'fetchers/hooks/useProject';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

import {
  Section,
  SecondaryTitle,
  Text,
  InfoGrid,
  StyledLink,
  Title,
  EmptyState,
  TextDanger,
} from './AboutProjectView.styles';
import UsefulLinks from './components/UsefulLinks';

const AboutProjectView = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { isLoading, project } = useProject(projectId);
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        !!project && (
          <>
            <Section>
              <Title>{t('projectCard.description')}</Title>
              <SecondaryTitle>{t('menu.summary')}</SecondaryTitle>

              {project.summary ? (
                <Text>{project.summary}</Text>
              ) : (
                <EmptyState>{t('projectCard.noSummary')}</EmptyState>
              )}

              {project.is_sensitive && (
                <TextDanger>{t('projectCard.sensitiveDataProject')}</TextDanger>
              )}
            </Section>
            <Section>
              <Title>{t('projectCard.generalInfo')}</Title>
              <InfoGrid>
                <div>
                  <div>
                    <SecondaryTitle>{t('projectCard.type')}</SecondaryTitle>
                    <Text>{project.project_type.name}</Text>
                  </div>
                  <div style={{ marginTop: theme.space[6] }}>
                    <SecondaryTitle>
                      {t('projectCard.clientName')}
                    </SecondaryTitle>
                    {project.client_name ? (
                      <Text>{project.client_name}</Text>
                    ) : (
                      <EmptyState>{t('projectCard.noClientName')}</EmptyState>
                    )}
                  </div>
                </div>
                <div>
                  <SecondaryTitle>
                    {t('projectCard.accountManager')}
                  </SecondaryTitle>
                  {project.account_managers.length ? (
                    project.account_managers.map((manager) => (
                      <Flex mb={2} key={manager.id}>
                        <Avatar
                          size="sm"
                          src={manager.photo.url || ''}
                          name={manager.full_name || ''}
                        />
                        <Text isNextToAvatar>{manager.full_name || '-'}</Text>
                      </Flex>
                    ))
                  ) : (
                    <EmptyState>{t('projectCard.noAccountManager')}</EmptyState>
                  )}
                </div>
                <div>
                  <div>
                    <SecondaryTitle>
                      {t('projectCard.companyName')}
                    </SecondaryTitle>
                    {project.client_company_name ? (
                      <Text>{project.client_company_name}</Text>
                    ) : (
                      <EmptyState>
                        {t('projectCard.noClientCompanyName')}
                      </EmptyState>
                    )}
                  </div>
                  <div style={{ marginTop: theme.space[6] }}>
                    <SecondaryTitle>
                      {t('projectCard.companyWebsite')}
                    </SecondaryTitle>
                    {project.client_company_website ? (
                      <StyledLink
                        href={project.client_company_website}
                        target="_blank"
                      >
                        {project.client_company_website}
                      </StyledLink>
                    ) : (
                      <EmptyState>
                        {t('projectCard.noCompanyWebsite')}
                      </EmptyState>
                    )}
                  </div>
                </div>
                <div>
                  <SecondaryTitle>
                    {t('projectCard.projectManager')}
                  </SecondaryTitle>
                  {project.responsible_users.length ? (
                    project.responsible_users.map((user) => (
                      <Flex mb={2} key={user.id}>
                        <Avatar
                          size="sm"
                          src={user.photo.url || ''}
                          name={user.full_name || ''}
                        />
                        <Text isNextToAvatar>{user.full_name || '-'}</Text>
                      </Flex>
                    ))
                  ) : (
                    <EmptyState>{t('projectCard.noProjectManager')}</EmptyState>
                  )}
                </div>
                <div>
                  <SecondaryTitle>{t('projectCard.devStack')}</SecondaryTitle>
                  {project.dev_stack.length ? (
                    project.dev_stack.map((item) => (
                      <NewTag key={item.id}>{item.name}</NewTag>
                    ))
                  ) : (
                    <EmptyState>{t('projectCard.noDevStack')}</EmptyState>
                  )}
                </div>
                {/* UNCOMMENT AFTER WE HAVE MANAGEMENT HISTORY READY TO DEVELOP */}
                {/* <div>
                  <TextLinkWrapper
                    color={theme.colors.primaryLight}
                    fontSize={theme.fontSizes.sm}
                  >
                    {t('projectCard.managementHistory')}
                  </TextLinkWrapper>
                </div> */}
              </InfoGrid>
            </Section>
            <UsefulLinks links={project.useful_links} />
          </>
        )
      )}
    </>
  );
};

AboutProjectView.displayName = 'AboutProjectView';

export default AboutProjectView;
