import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { usersAPI } from 'api/users/endpoints';
import { UserTeam } from 'api/users/models';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';

type TeamsState = {
  teams: UserTeam[];
  isLoading: boolean;
  error: string | null;
};

const INITIAL_STATE: TeamsState = {
  teams: [],
  isLoading: false,
  error: null,
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState: INITIAL_STATE,
  reducers: {
    getTeamsStart(state): void {
      state.isLoading = true;
      state.error = null;
    },
    getTeamsSuccess(state, action: PayloadAction<any>): void {
      state.isLoading = false;
      state.teams = action.payload;
    },
    getTeamsFail(state, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
      state.teams = [];
    },
  },
});

const { getTeamsStart, getTeamsSuccess, getTeamsFail } = teamsSlice.actions;

export default teamsSlice.reducer;

export const getTeams = (): AppThunk => async (dispatch) => {
  dispatch(getTeamsStart());

  try {
    const {
      data: { teams },
    } = await usersAPI.getTeams();

    dispatch(getTeamsSuccess(teams));
  } catch (error) {
    dispatch(getTeamsFail(error?.message || 'Team not fetched'));
  }
};

export const selectTeamsData = (state: RootState) => state.teams;

export const selectSortedTeams = createSelector(selectTeamsData, ({ teams }) =>
  [...teams].sort((a, b) =>
    a.name === 'Other' ? 1 : a.name.localeCompare(b.name),
  ),
);
