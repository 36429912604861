import styled from '@emotion/styled/macro';
import { Field as FieldComponent } from 'formik';
import { MultiSelect as MultiSelectComponent } from 'primereact/multiselect';
import { Box as BoxComponent, Flex as FlexComponent } from 'rebass';
import { theme } from 'styles/theme';

export const Field = styled(FieldComponent)`
  background-color: ${theme.colors.lightestGrey};
  border: 1px solid ${theme.colors.lightestGrey};
  border-radius: ${theme.radii.sm};
  font-size: ${theme.fontSizes.sm};
  padding: 6px ${theme.space[2]};
`;

export const Flex = styled(FlexComponent)`
  align-items: center;

  > svg {
    margin-right: ${theme.space[2]};
  }
`;

export const Box = styled(BoxComponent)`
  display: flex;
  flex-direction: column;
  padding: ${theme.space[5]} 0;
  line-height: 1;

  textarea {
    height: ${theme.sizes.lg};
  }
`;

export const Checkbox = styled.span`
  position: relative;
  display: inline-block;
  align-items: center;
  margin: 0 ${theme.space[1]};

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }

  > input[type='checkbox'] {
    display: none;
  }

  > label {
    display: flex;
    align-items: center;
    color: ${theme.colors.primeLightGrey};
    background-color: ${theme.colors.lightestGrey};
    padding: ${theme.space[1]} ${theme.space[2]};
    border-radius: ${theme.radii.sm};
    cursor: pointer;
    font-size: ${theme.fontSizes.xs};
    transition: all 150ms ease-in-out;

    > svg {
      width: 0;
      height: 0;
      margin-left: 0;
      transition: all 150ms;
    }
  }

  input[type='checkbox']:checked + label {
    color: ${theme.colors.primaryLight};
    background-color: ${theme.colors.zumthor};

    > svg {
      width: 15px;
      height: 15px;
      margin-left: ${theme.space[1]};
    }
  }
`;

export const MultiSelect = styled(MultiSelectComponent)`
  background-color: ${theme.colors.lightestGrey};
  border: 1px solid ${theme.colors.lightestGrey};
  border-radius: ${theme.radii.sm};
  font-size: ${theme.fontSizes.sm};

  .p-multiselect-token-label,
  .p-multiselect-token-icon {
    color: ${theme.colors.primaryLight};
    background-color: ${theme.colors.zumthor};
`;
