import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

export const UserFormWrapper = styled.div`
  margin: ${theme.space[10]} auto 2rem auto;
  max-width: 613px;
  position: relative;
`;

export const SkillBox = styled.div`
  margin: 1%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.space[1]};
  border-radius: ${theme.radii.md};
  border: 1px solid ${theme.colors.neutral};
  transition: box-shadow 150ms;
  background-color: ${theme.colors.white};

  &:focus {
    outline: none;
    box-shadow: ${theme.shadows.outline};
    border-color: transparent;
  }
`;

export const Link = styled.a`
  color: ${theme.colors.midGray};

  &:hover {
    color: ${theme.colors.primaryDark};
    text-decoration: underline;
  }
`;
