import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';
import {
  getFilterFromStorage,
  removeFromStorage,
  saveFilterToStorage,
} from 'utils/localStorage';

export enum ProjectFilters {
  QUERY = 'query',
  MEMBERS = 'members',
  PROJECTS_ID = 'projects_id',
  PERSONS_RESPONSIBLE = 'personsResponsible',
  IS_ACTIVE = 'isActive',
  IS_ARCHIVED = 'isArchived',
  PROJECT_TYPES = 'projectTypes',
  TEAMS_IDS = 'teams_ids',
  PHASE_TYPES_IDS = 'phase_types_ids',
}

type InitialState = {
  query: string;
  projectsId: number[];
  personsResponsible: string[];
  members: number[];
  isActive: boolean;
  isArchived: boolean;
  projectTypes: string[];
  teamsIds: number[];
  phaseTypesIds: number[];
};

const INITIAL_STATE: InitialState = {
  query: getFilterFromStorage(ProjectFilters.QUERY) || '',
  projectsId: getFilterFromStorage(ProjectFilters.PROJECTS_ID) || [],
  personsResponsible:
    getFilterFromStorage(ProjectFilters.PERSONS_RESPONSIBLE) || [],
  members: getFilterFromStorage(ProjectFilters.MEMBERS) || [],
  isActive:
    getFilterFromStorage(ProjectFilters.IS_ACTIVE) === undefined
      ? true
      : getFilterFromStorage(ProjectFilters.IS_ACTIVE),
  isArchived:
    getFilterFromStorage(ProjectFilters.IS_ARCHIVED) === undefined
      ? false
      : getFilterFromStorage(ProjectFilters.IS_ARCHIVED),
  projectTypes: getFilterFromStorage(ProjectFilters.PROJECT_TYPES) || [],
  teamsIds: getFilterFromStorage(ProjectFilters.TEAMS_IDS) || [],
  phaseTypesIds: getFilterFromStorage(ProjectFilters.PHASE_TYPES_IDS) || [],
};

const filtersSlice = createSlice({
  name: 'projects.filters',
  initialState: INITIAL_STATE,
  reducers: {
    setQueryFilter(state, action) {
      state.query = action.payload;
    },
    setProjectNamesFilter(state, action) {
      state.projectsId = action.payload;
    },
    setPersonsResponsibleFilter(state, action) {
      state.personsResponsible = action.payload;
    },
    setMembersFilter(state, action) {
      state.members = action.payload;
    },
    setIsActive(state, action) {
      state.isActive = action.payload;
    },
    setIsArchived(state, action) {
      state.isArchived = action.payload;
    },
    setProjectTypesFilter(state, action) {
      state.projectTypes = action.payload;
    },
    setTeamsIdsFilter(state, action) {
      state.teamsIds = action.payload;
    },
    setPhaseTypesIdsFilter(state, action) {
      state.phaseTypesIds = action.payload;
    },
    clearFiltersData(state) {
      removeFromStorage(`filter-${ProjectFilters.QUERY}`);
      removeFromStorage(`filter-${ProjectFilters.PROJECTS_ID}`);
      removeFromStorage(`filter-${ProjectFilters.PERSONS_RESPONSIBLE}`);
      removeFromStorage(`filter-${ProjectFilters.MEMBERS}`);
      removeFromStorage(`filter-${ProjectFilters.IS_ACTIVE}`);
      removeFromStorage(`filter-${ProjectFilters.IS_ARCHIVED}}`);
      removeFromStorage(`filter-${ProjectFilters.PROJECT_TYPES}`);
      removeFromStorage(`filter-${ProjectFilters.TEAMS_IDS}`);
      removeFromStorage(`filter-${ProjectFilters.PHASE_TYPES_IDS}`);
      state.query = '';
      state.projectsId = [];
      state.personsResponsible = [];
      state.members = [];
      state.isActive = true;
      state.isArchived = false;
      state.projectTypes = [];
      state.teamsIds = [];
      state.phaseTypesIds = [];
    },
  },
});

const {
  setQueryFilter,
  setProjectNamesFilter,
  setPersonsResponsibleFilter,
  setMembersFilter,
  setIsActive,
  setIsArchived,
  setProjectTypesFilter,
  setTeamsIdsFilter,
  setPhaseTypesIdsFilter,
} = filtersSlice.actions;
export const { clearFiltersData } = filtersSlice.actions;

export default filtersSlice.reducer;

export const setProjectFilter = (
  filter: ProjectFilters,
  value: string | boolean | number[] | string[],
): AppThunk => (dispatch) => {
  saveFilterToStorage(filter, value);

  switch (filter) {
    case ProjectFilters.QUERY: {
      dispatch(setQueryFilter(value));
      break;
    }
    case ProjectFilters.PROJECTS_ID: {
      dispatch(setProjectNamesFilter(value));
      break;
    }
    case ProjectFilters.PERSONS_RESPONSIBLE: {
      dispatch(setPersonsResponsibleFilter(value));
      break;
    }
    case ProjectFilters.MEMBERS: {
      dispatch(setMembersFilter(value));
      break;
    }
    case ProjectFilters.IS_ACTIVE: {
      dispatch(setIsActive(value));
      break;
    }
    case ProjectFilters.IS_ARCHIVED: {
      dispatch(setIsArchived(value));
      break;
    }
    case ProjectFilters.PROJECT_TYPES: {
      dispatch(setProjectTypesFilter(value));
      break;
    }
    case ProjectFilters.TEAMS_IDS: {
      dispatch(setTeamsIdsFilter(value));
      break;
    }
    case ProjectFilters.PHASE_TYPES_IDS: {
      dispatch(setPhaseTypesIdsFilter(value));
      break;
    }
  }
};

export const selectProjectsFiltersData = (state: RootState) =>
  state.projects.filters;
