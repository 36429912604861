import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Team } from 'api/issues/models';
import { User } from 'api/users/models';
import { Error } from 'components/forms/formik-prime/styled';
import { PrimeTextInput } from 'components/prime';
import { PrimeAutocomplete } from 'components/prime/Autocomplete/Autocomplete';
import { NewPrimeButton } from 'components/prime/NewButton/NewButton';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { Avatar } from 'components/ui';
import { CannotRemoveModal } from 'components/ui/CannotRemoveModal/CannotRemoveModal';
import { addTeam } from 'fetchers/actions/team/addTeam';
import { removeTeam } from 'fetchers/actions/team/removeTeam';
import { updateTeam } from 'fetchers/actions/team/updateTeam';
import { useUsers } from 'fetchers/hooks/users/useUsers';
import { Dialog } from 'primereact/dialog';
import { Box, Flex } from 'rebass';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  teamList: string[];
  team: Team | null;
}

export const TeamModal = ({ isOpen, setIsOpen, teamList, team }: Props) => {
  const [teamName, setTeamName] = useState<string>('');
  const [supervisor, setSupervisor] = useState<User | undefined>(undefined);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const usersBlockingRemoval = useRef<User[] | null>(null);

  const { users } = useUsers();
  const { t } = useTranslation();
  const modalContainer = document.querySelector('#modal-root') as HTMLElement;

  useEffect(() => {
    setDefaultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  useEffect(() => {
    if (teamName.length > 0) {
      setError('');
    }
  }, [teamName]);

  const onClose = () => {
    setError('');

    setDefaultData();

    setIsOpen(false);
  };

  const setDefaultData = () => {
    if (team) {
      setTeamName(team.name);
      setSupervisor(team.supervisor.id ? team.supervisor : undefined);
    } else {
      setTeamName('');
      setSupervisor(undefined);
    }
  };

  const onSubmit = () => {
    const _teamName = teamName.trim();
    const list = team
      ? teamList.filter((p) => p !== team.name.toLowerCase())
      : teamList;
    if (!_teamName.length) {
      setError(t('errors.required'));
    } else if (list.includes(_teamName.toLowerCase())) {
      setError(t('errors.nameTaken'));
    } else {
      const data = { name: _teamName, supervisor_id: supervisor?.id || null };
      if (team) {
        updateTeam(data, team.id);
      } else {
        addTeam(data);
      }
      onClose();
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const openInfoModal = ({ data }: any) => {
    if (data && data.errors.includes('users')) {
      usersBlockingRemoval.current = data.users;
      setDialogOpen(true);
    }
  };

  const deleteTeam = (teamId: number) => {
    removeTeam(teamId)
      .then(() => setIsOpen(false))
      .catch((err) => openInfoModal(err));
  };

  const accept = () => {
    deleteTeam(team?.id!);
  };

  const userOptionTemplate = (user: User) => {
    return (
      <Flex alignItems="center" width="380px">
        <Flex flex={4} alignItems="center">
          <Avatar
            src={user.photo?.mini?.url || ''}
            name={user.full_name}
            size="sm"
          />
          <Box fontSize="xs" ml={2}>
            {user.full_name}
          </Box>
        </Flex>
        <Flex fontSize="xxs" flex={4} justifyContent="flex-end">
          {user.position || t('users.noPosition')}
        </Flex>
      </Flex>
    );
  };

  return (
    <Dialog
      onHide={onClose}
      visible={isOpen}
      blockScroll={true}
      header={t(team ? 'users.editTeam' : 'users.addTeam')}
    >
      <Flex minWidth="400px" flexDirection="column">
        <PrimeTextInput
          ishigh="false"
          iserror={error}
          value={teamName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setTeamName(event.target.value)
          }
          label={`${t('users.teamName')}*`}
          onKeyDown={onKeyPress}
          autoFocus
        />
        {error && <Error>{error}</Error>}

        <Box my={4}>
          <PrimeAutocomplete
            options={users || []}
            searchKey="full_name"
            dropdown={true}
            valueKey="id"
            name="project"
            placeholder={t('users.pickUserPlaceholder')}
            delay={0}
            label={t('users.supervisor')}
            itemTemplate={userOptionTemplate}
            appendTo={modalContainer}
            value={supervisor}
            clear={true}
            onCustomClear={() => setSupervisor(undefined)}
            onChange={(e) => setSupervisor(e.target.value)}
          />
        </Box>
      </Flex>
      <Flex mt={4} justifyContent="space-between">
        <Flex>
          {team && (
            <PrimeTextButton
              onClick={accept}
              label={t('users.removeTeam')}
              variant="danger"
            />
          )}
        </Flex>
        <Flex>
          <Box>
            <NewPrimeButton label={t('common.cancel')} onClick={onClose} />
          </Box>
          <Box ml={2}>
            <NewPrimeButton label={t('common.save')} onClick={onSubmit} />
          </Box>
        </Flex>
      </Flex>
      <CannotRemoveModal
        isOpen={dialogOpen}
        setIsOpen={setDialogOpen}
        usersBlockingRemoval={usersBlockingRemoval.current || []}
        itemName="Team"
      />
    </Dialog>
  );
};
