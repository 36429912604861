import React from 'react';

import { Spin, NothingFoundBox } from 'components/ui';
import { useDashboard } from 'fetchers';

import { Projects } from './components/Projects';

export const Dashboard = React.memo(() => {
  const { isLoading, projectsSprints } = useDashboard();

  if (isLoading) {
    return <Spin />;
  }

  if (!projectsSprints?.length) {
    return <NothingFoundBox />;
  }

  return <Projects data={projectsSprints || []} />;
});
