export const theme = {
  fonts: {
    regular: 'TTCommons-Regular, sans-serif',
    medium: 'TTCommons-Medium, sans-serif',
    bold: 'TTCommons-DemiBold, sans-serif',
  },
  fontSizes: {
    xxs: '12px',
    xxs2: '13px',
    xs: '14px',
    sm: '16px',
    md: '18px',
    xmd: '20px',
    lg: '24px',
    lg2: '32px',
    xl: '40px',
    xxl: '58px',
  },
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  lineHeights: {
    base: '1.15',
    xs: '1px',
    normal: '16px',
    md: '18px',
    md2: '20px',
    lg: '46px',
    xl: '50px',
  },
  space: {
    '-4': '-16px',
    '-3': '-12px',
    '-2': '-8px',
    '-1': '-4px',
    0: '0px',
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '20px',
    6: '24px',
    7: '28px',
    8: '32px',
    9: '40px',
    10: '44px',
    11: '48px',
    12: '52px',
  },
  sizes: {
    tiny: '5px',
    xs: '16px',
    xsm: '20px',
    sm: '32px',
    smd: '40px',
    md: '50px',
    slg: '60px',
    lg: '80px',
  },
  radii: {
    xs: '2.5px',
    sm: '3px',
    md: '5px',
    xmd: '8px',
    lg: '10px',
    mlg: '16px',
    xl: '25px',
    xxl: '50px',
  },
  shadows: {
    md: '0 1px 2px 0 rgba(189,199,211,0.2)',
    md40: '0 1px 2px 0 rgba(189,199,211,0.4)',
    mdStrong: '0 1px 2px 0 #bdc7d3',
    lg: '2px 1px 5px 0px rgba(189,199,211,0.2)',
    modalShadow: '0 0 20px 0 rgba(189, 199, 211, 0.4)',
    weekPickerShadow: '0 1px 2px 0 rgba(189, 199, 211, 0.4)',
    outline: '0px 0px 0px 3px rgba(0,102,255,0.4)',
    outlineGray: '0px 0px 0px 3px rgba(69, 79, 95,0.2)',
    custom:
      '0 1px 2px 0 rgba(189, 199, 211, 0.4), 0 2px 4px 0 rgba(189, 199, 211, 0.6)',
    mainButton: '0 0.5rem 1.3rem 0 rgba(0, 102, 255, 0.2)',
    dangerButton: '0 0.5rem 1.3rem 0 rgba(244, 49, 48, 0.2)',
    successButton: '0 0.5rem 1.3rem 0 rgba(140, 219, 60, 0.2)',
  },
  colors: {
    primary: '#0066ff',
    primaryLight: '#0166FF',
    primaryDark: '#235ECC',
    danger: '#e73820',
    lightRed: '#fff3f3',
    success: '#36b37e',
    lightGreen: '#f1f9ea',
    malachite: '#2be832',
    white: '#ffffff',
    warning: '#ffab00',

    primaryLightGray: '#e2e2e2',
    primaryInputBorder: '#C7C7C7',

    veryDarkGray: '#48515C',
    darkGray: '#142847',
    pureGray: '#5f6369',
    midGray: '#727f91',
    lightGray: '#96a1b1',
    lightestGrey: '#F8F8F9',
    newLightestGrey: '#F2F2F2',

    bermudaGray: '#6f89ad',
    neutralGray: '#ced4da',

    neutralDark: '#9aa6b5',
    neutralMediumDark: '#d8d8e2',
    neutral: '#e5e7ee',
    neutralMedium: '#eef1f5',
    neutralLight: '#f8f8fa',
    blueExtraLight: '#fafbfd',

    lightBlue: '#eff6ff',
    lightGrayBlue: '#edf1fa',

    sideModalOverlay: 'rgba(8,34,73,0.05)',
    sideModalOverlayDark: 'rgba(8,34,73,0.2)',
    darkGrayOpacity: 'rgba(20, 41, 71, 0.5)',
    lightGrayOpacity: 'rgba(96, 125, 139, 0.04)',
    primaryOpacity: 'rgba(1, 102, 255, 0.15)',
    lighterGrayOpacity: 'rgba(188, 192, 197, 0.5)',

    whiteLilac: '#f5f7fc',
    solitude: '#e1eefe',

    moccasin: '#fdf6b2',
    papayawhip: '#feeedc',
    mistyrose: '#fde8e7',
    honeydew: '#def7ec',
    lightcyan: '#d5f5f6',
    zumthor: '#E6F0FF',

    saddlebrownDark: '#723b14',
    saddlebrownDarkLight: '#8f4401',
    brown: '#9a1c1c',
    paleRed: '#eec5c5',
    electronBlue: '#04543f',
    emerald: '#03505c',
    placeholder: '#6c757d',
    orange: '#FF5630',
    cobalt: '#0145AD',

    newDarkGrey: '#656F89',
    newLightGrey: '#F0F3F7',
    newGreen: '#36B37E',
    newBlueInputHover: '#2196F3',

    primeLightGrey: '#818792',
    primeDarkGrey: '#454F5F',

    primaryBlack: '#231F20',
    darkestGray: '#030304',
    lighterGray: '#BCC0C5',
  },
  buttons: {
    primary: {
      fontSize: 'sm',
      color: 'white',
      bg: 'primary',
      borderRadius: 'xl',
      height: 'md',
      px: 6,
      lineHeight: 1,
      transition: '150ms',
      ':hover': {
        boxShadow: 'mainButton',
      },
      ':focus': {
        outline: 'none',
        boxShadow: 'outline',
      },
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'bermudaGray',
      bg: 'neutral',
      ':hover': {
        boxShadow: 'mainButton',
        bg: 'primary',
        color: 'white',
      },
      ':focus': {
        outline: 'none',
        boxShadow: 'outline',
      },
    },
    danger: {
      variant: 'buttons.primary',
      bg: 'danger',
      ':hover': {
        boxShadow: 'dangerButton',
      },
    },
    success: {
      variant: 'buttons.primary',
      bg: 'success',
      ':hover': {
        boxShadow: 'successButton',
      },
    },
    ghost: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'transparent',
      ':hover': {
        bg: 'lightBlue',
      },
      ':focus': {
        outline: 'none',
        boxShadow: 'outline',
        bg: 'lightBlue',
      },
    },
    simple: {
      variant: 'buttons.primary',
      color: 'bermudaGray',
      p: 1,
      height: 'smd',
      borderRadius: 'sm',
      bg: 'transparent',
      ':hover': {
        color: 'primary',
      },
      ':focus': {
        color: 'primary',
        outline: 'none',
        boxShadow: 'outline',
      },
    },
    simpleWithBorder: {
      variant: 'buttons.primary',
      color: 'midGray',
      p: 1,
      height: '22px',
      fontSize: 'xs',
      fontFamily: 'regular',
      border: '1px solid',
      borderColor: 'neutral',
      borderRadius: 'md',
      bg: 'transparent',
      ':hover': {
        boxShadow: 'outline',
      },
      ':focus': {
        outline: 'none',
        boxShadow: 'outline',
      },
      ':active': {
        outline: 'none',
        boxShadow: 'outline',
      },
    },
    simpleDanger: {
      variant: 'buttons.simple',
      color: 'danger',
      ':hover': {
        color: 'danger',
      },
      ':focus': {
        color: 'danger',
        outline: 'none',
        boxShadow: 'outline',
      },
    },
  },
  gradients: {
    weekDayGradient:
      'repeating-linear-gradient(55deg, #eaecf2, #ebebed 2px, #ebebed 2px, #ffffff 4px)',
  },
  zIndices: {
    '-1': '-1',
    '0': '500',
    '1': '600',
    '2': '700',
    '3': '800',
    '4': '900',
    '5': '1000',
    '6': '1010',
    '7': '1020',
    '8': '1030',
    '9': '1040',
    max: '99999',
  },
  dimensions: {
    topBarHeight: 68,
    menuIconHeight: 14,
    menuIconWidth: function () {
      return this.menuIconHeight * 1.2;
    },
    headerCellHeight: '38px',
    uploaderWidth: '148px',
    inputHeight: '50px',
    inputHeightLow: '36px',
    loaderMarginTop: '2px',
    desktopSize: '1700px',
  },
};
