import React from 'react';

import { Button } from 'components/ui';
import { AvatarBox } from 'components/ui/AvatarBox';
import { removeUserFromPhase } from 'fetchers/actions/removeUserFromPhase';
import { Box, Flex } from 'rebass';
import { theme } from 'styles/theme';

import { Paragraph } from './styled';

type Props = {
  onClose: () => void;
  user: User;
};

type User = {
  avatar?: string;
  name: string;
  phaseName: string;
  phaseUserRangeId: number;
  projectName: string;
  projectId: number;
};

const UserRemoveModal: React.FC<Props> = ({ onClose, user }) => {
  const {
    avatar,
    name,
    phaseUserRangeId,
    projectName,
    phaseName,
    projectId,
  } = user;

  const handleDeleteMember = () => {
    removeUserFromPhase(phaseUserRangeId, projectId);
    onClose();
  };

  return (
    <>
      <Flex justifyContent="center">
        <AvatarBox name={name} photo={avatar} />
      </Flex>
      <Box
        as="p"
        color="darkGray"
        marginBottom={theme.space[12]}
        marginTop={theme.space[4]}
      >
        Do you want to remove{' '}
        <Paragraph as="span">{name || 'No name'}</Paragraph> from{' '}
        <Paragraph as="span">{projectName} </Paragraph>({phaseName})?
      </Box>
      <Flex alignItems="center" width="100%">
        <Box marginLeft="auto">
          <Button
            mr={2}
            variant="ghost"
            data-cy="close-modal-btn"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            data-cy="remove-member-btn"
            onClick={handleDeleteMember}
          >
            Delete
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default UserRemoveModal;
