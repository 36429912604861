import { Column } from '../models';
import { User } from 'api/users/models';
import i18n from 'i18next';

export const UserListColumns: Column<User>[] = [
  { title: i18n.t('common.name'), minWidth: 220, key: 'full_name' },
  {
    title: i18n.t('users.supervisor'),
    minWidth: 220,
    key: 'supervisors',
    sort: false,
  },
  {
    title: i18n.t('users.filters.position'),
    minWidth: 180,
    key: 'position',
  },
  {
    title: i18n.t('users.team'),
    minWidth: 150,
    key: 'team',
  },
  {
    title: i18n.t('users.skills'),
    minWidth: 150,
    key: 'skills',
    sort: false,
  },
  {
    title: i18n.t('users.seniority'),
    minWidth: 150,
    key: 'seniority',
  },
];
