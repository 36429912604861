import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillInfoCircle } from 'react-icons/all';

import { PrimeNumberInput } from '../../prime';
import { FieldLabel } from '../../primitive';
import {
  differenceInWeeks,
  endOfWeek,
  startOfWeek,
  isPast,
  isSameWeek,
} from 'date-fns';
import { useFormikContext } from 'formik';
import { Box, Flex } from 'rebass';
import { FormValues } from 'screens/Projects/components/MembersFooterData/AddNewMemberModal';
import { HoursConditionInfo } from 'screens/Projects/components/MembersFooterData/AddNewMemberModal/styled';
import { theme } from 'styles/theme';

export const AddUserHours = () => {
  const [totalWeeks, setTotalWeeks] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const { start_date, end_date } = values as FormValues;

  useEffect(() => {
    let counter = 0;
    if (start_date && end_date) {
      counter = Math.abs(
        differenceInWeeks(
          startOfWeek(start_date, { weekStartsOn: 1 }),
          endOfWeek(end_date, { weekStartsOn: 1 }),
        ),
      );
    }
    setTotalWeeks(counter + 1);
  }, [start_date, end_date, hours]);

  useEffect(() => {
    setFieldValue('hours', hours);
  }, [hours, setFieldValue]);

  const now = new Date();

  // the amount of hours can be added if the start date is during current week or in the future (not last week or further in the past)
  const isAddingAmountOfHoursPossible = (startDate: Date, today: Date) => {
    if (
      isSameWeek(startDate, today, { weekStartsOn: 1 }) ||
      !isPast(startDate)
    ) {
      return true;
    }
    return false;
  };

  const displayAddingUserHours = () => {
    if (start_date && !isAddingAmountOfHoursPossible(start_date, now)) {
      return (
        <HoursConditionInfo>
          <AiFillInfoCircle />
          {t('projects.timeRangeInThePast')}
        </HoursConditionInfo>
      );
    } else if (
      start_date &&
      end_date &&
      isAddingAmountOfHoursPossible(start_date, now)
    ) {
      return (
        <Flex alignItems="center">
          <Box flex={1}>
            <PrimeNumberInput
              paddingRight="0"
              onChange={(e) => setHours(e.value > 100 ? 100 : e.value)}
              value={hours}
              isHigh={false}
              variant="standard"
              min={0}
              max={100}
              maxFractionDigits={0}
            />
          </Box>
          <Box flex={7} ml={3} fontSize={theme.fontSizes.xs}>
            {`${t('projects.hoursPerWeek')}: ${hours || 0} (${t(
              'projects.totalHours',
            )}: ${totalWeeks * (hours || 0)})`}
          </Box>
        </Flex>
      );
    } else {
      return (
        <HoursConditionInfo>
          <AiFillInfoCircle />
          {t('projects.hoursPerWeekCondition')}
        </HoursConditionInfo>
      );
    }
  };

  return (
    <Flex flexDirection="column">
      <FieldLabel>
        <span>{t('projects.hoursPerWeek')}</span>
      </FieldLabel>
      {displayAddingUserHours()}
    </Flex>
  );
};
