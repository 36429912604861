import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressBar } from '../styled';
import { Box, Flex } from 'rebass';
import { formatMinutesToHours } from 'utils/functions';

export type EstimatesProgressBarProps = {
  spentHours: number;
  remainingHours: number;
  totalHours: number;
};

export const EstimatesProgressBar: React.FC<EstimatesProgressBarProps> = ({
  spentHours,
  remainingHours,
  totalHours,
}) => {
  const { t } = useTranslation();

  return (
    <Flex minWidth="max-content" width="100%" flexDirection="column" mb="-5px">
      <Flex justifyContent="space-between" color="veryDarkGray" mb={1}>
        <Flex
          width="100%"
          fontSize="xs"
          justifyContent="space-between"
          opacity={0.6}
        >
          <Flex>
            <Box as="span" mr={1}>
              {t('userProfile.clocked')}:
            </Box>
            <span>{formatMinutesToHours(spentHours)}</span>
          </Flex>
          <Flex>
            <Box as="span" mr={1}>
              {t('userProfile.left')}:
            </Box>
            <span>{formatMinutesToHours(remainingHours)}</span>
          </Flex>
        </Flex>
      </Flex>
      <ProgressBar maxHours={totalHours} hours={spentHours} />
    </Flex>
  );
};
