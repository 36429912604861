import styled from '@emotion/styled/macro';
import { Box as BoxComponent } from 'rebass';
import { theme } from 'styles/theme';

export const Box = styled(BoxComponent)`
  display: flex;
  flex-direction: row;
  padding: ${theme.space[5]} 0;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled.button`
  color: ${theme.colors.primaryLight};
  padding: ${theme.radii.lg};
  border: 1px solid ${theme.colors.primaryLight};
  border-radius: ${theme.radii.sm};
  transition: all 150ms ease-in-out;

  &:hover {
    color: ${theme.colors.white};
    background-color: ${theme.colors.primaryLight};
  }
`;
