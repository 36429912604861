import React from 'react';
import { Link } from 'react-router-dom';

import { PAGES_PATH } from 'components/layout/PrivateRoute/components/MainLayout/hooks';
import { ProjectPhasePersonButtonWrapper } from 'components/layout/Tables/columnsStyled';

type ProjectNameLinkProps = {
  'data-cy'?: string;
  href: string;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  source?: PAGES_PATH | string;
  tabIndex?: number;
};

export const ProjectPhasePersonLink: React.FC<ProjectNameLinkProps> = ({
  children,
  'data-cy': dataCy,
  href,
  isDisabled,
  onClick,
  source,
  ...props
}) => {
  if (isDisabled) {
    return (
      <ProjectPhasePersonButtonWrapper
        data-cy={dataCy}
        isDisabled={isDisabled}
        onClick={onClick}
      >
        {children}
      </ProjectPhasePersonButtonWrapper>
    );
  }

  return (
    <Link
      {...props}
      data-cy={dataCy}
      onClick={onClick}
      to={{
        pathname: href,
        state: {
          from: source,
        },
      }}
    >
      <ProjectPhasePersonButtonWrapper>
        {children}
      </ProjectPhasePersonButtonWrapper>
    </Link>
  );
};
