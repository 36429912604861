import { useEffect } from 'react';
import GTM from 'react-gtm-module';

export const useGTM = (init: boolean = false) => {
  useEffect(() => {
    if (init && process.env.REACT_APP_GTM_ID) {
      GTM.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
