import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiLogOut, FiBell } from 'react-icons/fi';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { UserLoginData } from 'api/users/models';
import { NewPrimeButton } from 'components/prime/NewButton/NewButton';
import { MainMenuItem } from 'components/primitive/MainMenuItem';
import {
  Logo,
  Avatar,
  Spin,
  DashboardIcon,
  UsersIcon,
  ProjectsIcon,
  SummaryIcon,
  TimelineIcon,
} from 'components/ui';
import { useOrganizationSettings } from 'fetchers/hooks/useOrganizationSettings';
import { Flex } from 'rebass';
import { logoutAction, selectAuthUser } from 'screens/Auth/authSlice';
import { selectRedirectRoute } from 'screens/Auth/authSlice';
import { theme } from 'styles/theme';
import { isOnlyRoleUser } from 'utils/functions';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

import { PAGE_PATH_TO_ID, PAGES_PATH, useActiveTabState } from './hooks';
import {
  BodyBox,
  IconBox,
  LogoBoxLink,
  NavBar,
  TitleBox,
  MenuMiddle,
  MenuEnd,
  ActionButton,
} from './styled';

type Props = {
  isScrollable?: boolean;
};

const MainLayout: React.FC<Props> = ({ children, isScrollable = true }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const activeTab = useActiveTabState();
  const authUser = useSelector(selectAuthUser);
  const redirectRoute = useSelector(selectRedirectRoute);
  const { integrations } = useOrganizationSettings();
  const permissions = {
    dashboard: usePermissions(PERMISSIONS.DASHBOARD),
    notifications: usePermissions(PERMISSIONS.NOTIFICATIONS),
    projects: usePermissions(PERMISSIONS.VIEW_PROJECT_LIST),
    users: usePermissions(PERMISSIONS.USERS),
    weeklySummary: usePermissions(PERMISSIONS.WEEKLY_SUMMARY),
    addUser: usePermissions(PERMISSIONS.ADD_USER),
    addProject: usePermissions(PERMISSIONS.ADD_PROJECT),
    availableResources: usePermissions(PERMISSIONS.ADD_AVAILABLE_RESOURCE),
  };

  const activePath = window.location.pathname;

  const shouldShowDashboard =
    permissions.dashboard && integrations?.atlassian && integrations?.toggl;

  const isActiveTab = (tab: number) => (tab === activeTab ? 1 : 0);
  const isAdmin = (user: UserLoginData): boolean =>
    !!user.roles.find(({ name }) => name === 'admin');

  const handleLogOut = () => {
    dispatch(logoutAction());
  };

  const goToAddProject = () => {
    history.push('/projects/new');
  };

  const goToUserCreationView = () => {
    history.push('/users/new');
  };

  return (
    <>
      <NavBar>
        <LogoBoxLink data-cy="main-page-redirect-logo" to={redirectRoute}>
          <Logo />
          <TitleBox>ERP</TitleBox>
        </LogoBoxLink>

        <MenuMiddle>
          <Flex height="100%">
            {permissions.projects && isOnlyRoleUser && (
              <MainMenuItem
                url={`/${PAGES_PATH.PROJECTS}`}
                active={isActiveTab(PAGE_PATH_TO_ID[PAGES_PATH.PROJECTS])}
                title={t('projects.projects')}
              >
                <ProjectsIcon />
              </MainMenuItem>
            )}

            <MainMenuItem
              url={`/${PAGES_PATH.USERS}`}
              active={isActiveTab(PAGE_PATH_TO_ID[PAGES_PATH.USERS])}
              title={t('menu.users')}
            >
              <UsersIcon />
            </MainMenuItem>

            {shouldShowDashboard && (
              <MainMenuItem
                url={`/${PAGES_PATH.DASHBOARD}`}
                active={isActiveTab(PAGE_PATH_TO_ID[PAGES_PATH.DASHBOARD])}
                title={t('menu.dashboard')}
              >
                <DashboardIcon />
              </MainMenuItem>
            )}

            {permissions.weeklySummary && (
              <MainMenuItem
                url={`/${PAGES_PATH.WEEKLY_SUMMARY}`}
                active={isActiveTab(PAGE_PATH_TO_ID[PAGES_PATH.WEEKLY_SUMMARY])}
                title={t('menu.teamReport')}
              >
                <SummaryIcon />
              </MainMenuItem>
            )}

            {/* add when timeline ready */}
            {false && (
              <MainMenuItem
                url={`/${PAGES_PATH.TIMELINE}`}
                active={isActiveTab(PAGE_PATH_TO_ID[PAGES_PATH.TIMELINE])}
                title={t('menu.timeline')}
              >
                <TimelineIcon />
              </MainMenuItem>
            )}

            {authUser && isAdmin(authUser) && (
              <MainMenuItem
                active={isActiveTab(
                  PAGE_PATH_TO_ID[PAGES_PATH.ORGANIZATION_SETTINGS],
                )}
                url={`/${PAGES_PATH.ORGANIZATION_SETTINGS}`}
                title={t('menu.organization')}
              >
                <HiOutlineOfficeBuilding size={16} />
              </MainMenuItem>
            )}

            {permissions.availableResources && (
              <MainMenuItem
                url={`/${PAGES_PATH.AVAILABLE_RESOURCES}`}
                active={isActiveTab(
                  PAGE_PATH_TO_ID[PAGES_PATH.AVAILABLE_RESOURCES],
                )}
                title={t('menu.teamAvailability')}
              >
                <UsersIcon />
              </MainMenuItem>
            )}
          </Flex>

          <ActionButton>
            {activePath === '/projects' && permissions.addProject && (
              <NewPrimeButton
                label={`+ ${t('projects.addProject')}`}
                variant="list"
                onClick={goToAddProject}
              />
            )}
            {activePath === '/users' && permissions.addUser && (
              <>
                <NewPrimeButton
                  variant="list"
                  label={t('users.addMember')}
                  onClick={goToUserCreationView}
                  icon="pi pi-plus"
                  iconPos="left"
                />
              </>
            )}
          </ActionButton>
        </MenuMiddle>

        <MenuEnd>
          {permissions.notifications && (
            <IconBox
              active={isActiveTab(PAGE_PATH_TO_ID[PAGES_PATH.NOTIFICATIONS])}
              data-cy="notifications-list-btn"
              to={`/${PAGES_PATH.NOTIFICATIONS}`}
            >
              <FiBell size={16} />
            </IconBox>
          )}
          {authUser ? (
            <Link
              style={{ marginLeft: theme.space[8] }}
              to={{
                pathname: `/users/${authUser.id}`,
                state: {
                  from: redirectRoute.substring(1),
                },
              }}
            >
              <Avatar
                data-cy="myself-view"
                name={authUser.full_name || ''}
                radius="xmd"
                size="small"
                src={authUser.photo?.mini?.url || ''}
              />
            </Link>
          ) : (
            <Flex alignItems="center" justifyContent="center" size={43}>
              <Spin />
            </Flex>
          )}
          <IconBox data-cy="logout-btn" to="/" onClick={handleLogOut}>
            <FiLogOut size={16} />
          </IconBox>
        </MenuEnd>
      </NavBar>

      <BodyBox isScrollable={isScrollable}>{children}</BodyBox>
    </>
  );
};

export default MainLayout;
