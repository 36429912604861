import React from 'react';

import { css, Global } from '@emotion/core';
import 'fonts/fonts.css';
import { normalize } from 'polished';
import { theme } from 'styles/theme';

export const GlobalStyles: React.FC = () => (
  <Global
    styles={css`
      ${normalize()};

      *,
      *::before,
      *::after {
        box-sizing: border-box;
      }
      html {
        font-size: 16px;
        box-sizing: border-box;
        line-height: ${theme.lineHeights.base};
      }

      body {
        color: ${theme.colors.darkGray};
        font-family: ${theme.fonts.regular};
        font-weight: 400;
        background: ${theme.colors.whiteLilac};

        //firefox scrollbar
        scrollbar-color: ${theme.colors.primary} transparent;
        scrollbar-face-color: ${theme.colors.primary};
        scrollbar-track-color: transparent;
        transition: background-color 200ms ease-in-out;
      }
      #root {
        overflow: hidden;
      }
      ul {
        padding: 0;
        margin: 0;
      }
      li {
        list-style-type: none;
      }
      a {
        text-decoration: none;
        color: ${theme.colors.darkGray};
      }
      button {
        border: none;
        background: none;
        cursor: pointer;
      }
      h1 {
        font-size: 2rem;
      }
      h2 {
        font-size: 1.75rem;
      }
      h3 {
        font-size: 1.5rem;
      }
      h4 {
        font-size: 1.25rem;
      }
      h5 {
        font-size: 1rem;
      }

      /* Prime global components */

      .p-component-overlay.p-dialog-mask.p-dialog-visible {
        background-color: rgba(0, 0, 0, 0.1);
      }

      .p-multiselect-panel.p-component {
        z-index: 9999 !important;
        & .p-multiselect-item > span {
          font-size: ${theme.fontSizes.xxs};
        }
      }

      .p-multiselect-items-wrapper {
        height: 200px;
      }

      /* React DatePicker styling */
      .react-datepicker-wrapper {
        width: 100%;
      }
      .react-datepicker {
        background-color: ${theme.colors.white};
        box-shadow: ${theme.shadows.md40};
        border: none;
        display: flex;
        &__header {
          background-color: ${theme.colors.white};
          border-bottom: none;
        }
        &__current-month {
          font-family: ${theme.fonts.medium};
          font-size: ${theme.fontSizes.md};
          text-transform: capitalize;
          margin-bottom: 0.6rem;
          color: ${theme.colors.darkGray};
        }
        &__day-name {
          text-transform: uppercase;
          font-family: ${theme.fonts.bold};
          color: ${theme.colors.neutralDark};
        }
        &__day {
          font-size: ${theme.fontSizes.xs};
          color: ${theme.colors.darkGray};
          &--selected {
            background-color: ${theme.colors.primary};
            color: ${theme.colors.white};
            &:hover {
              background-color: rgba(0, 102, 255, 0.8);
            }
          }
          &--keyboard-selected {
            background-color: ${theme.colors.primary};
            color: ${theme.colors.white};
            &:hover {
              background-color: rgba(0, 102, 255, 0.8);
            }
          }
          &--disabled {
            color: ${theme.colors.neutralLight};
          }
        }
        &__navigation {
          border: solid ${theme.colors.neutralDark};
          border-width: 0 1.3px 1.3px 0;
          outline: none;
          &--previous {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
            left: 1rem;
          }
          &--next {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            right: 1rem;
          }
        }
      }

      &.p-dialog-header {
        border-bottom: 1px solid ${theme.colors.whiteLilac} !important;
      }

      &.p-dialog-content {
        overflow-y: auto !important;
        position: relative;
      }

      &.p-dialog-mask {
        z-index: 9999 !important;
      }

      &.p-autocomplete-panel {
        z-index: 99999 !important;
      }
    `}
  />
);
