import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiAddFill } from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';

import {
  PhaseUserHour,
  Project,
  ProjectPhase,
  UserHoursPayload,
} from 'api/projects/models';
import { User } from 'api/users/models';
import { HoursTextField } from 'components/forms';
import { ProjectsPhasesTable, ProjectsTableProps } from 'components/layout';
import { Variant } from 'components/layout/Tables/SimpleUsersTable';
import { StyledDivider } from 'components/prime';
import { NewPrimeButton } from 'components/prime/NewButton/NewButton';
import {
  AvatarWithBackground,
  ProjectNameLink,
  ProjectPhasePersonLink,
  Spin,
  Tooltip,
  WeekDatePicker,
} from 'components/ui';
import { isPast } from 'date-fns';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { InputSwitch } from 'primereact/inputswitch';
import { Box, Flex, Text } from 'rebass';
import {
  setMemberHoursOnPhase,
  updateMemberHoursOnPhase,
} from 'store/slices/projectsPhasesMembersSlice';
import { selectProjectsWeeks } from 'store/slices/projectsSlice';
import { selectSelectedWeeks } from 'store/slices/selectedWeeksSlice';
import { theme } from 'styles/theme';
import { currentDate } from 'utils/constants';
import { useBackLinkHistory } from 'utils/hooks/useBackLinkHistory';
import { PERMISSIONS, usePermissions } from 'utils/hooks/usePermissions';

import NoProjectsToDisplay from './NoProjectsToDisplay';

type Props = {
  user: User;
  projects: Project[];
  onAddToProject: () => void;
  onPhaseStatusChange: (status: any) => void;
  phaseStatus: 'active' | 'inactive';
  isLoading: boolean;
};

const UserProjects: React.FC<Props> = ({
  user,
  projects,
  onAddToProject,
  phaseStatus,
  onPhaseStatusChange,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const { source } = useBackLinkHistory();
  const { t } = useTranslation();

  const canEditUserHours = usePermissions(PERMISSIONS.EDIT_USER_HOURS);
  const canViewProject = usePermissions(PERMISSIONS.VIEW_PROJECT);

  const selectedWeeks = useSelector(selectSelectedWeeks);
  const projectsWeeks = useSelector(selectProjectsWeeks);
  const canEditAvailableResource = usePermissions(
    PERMISSIONS.EDIT_AVAILABLE_RESOURCE,
  );

  const projectsWithPhases = projects.filter(
    (projects) => !!projects.phases.length,
  );

  const userProjects: Project[] = projectsWithPhases
    .map((project) => {
      return {
        ...project,
        phases: project.phases.filter((phase) => {
          return phase.users?.some((member) => member.id === user.id);
        }),
      };
    })
    .filter((project) => !!project.phases.length);

  const memoizedPhases = React.useMemo(() => {
    return userProjects
      .map((project) => {
        return project.phases
          ?.map((phase) => {
            return {
              project,
              phase,
            };
          })
          ?.flat();
      })
      .flat();
  }, [userProjects]);

  const handleProjectNameLinkClick = (event: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();

      if (!canViewProject) {
        event.preventDefault();
      }
    }
  };

  const handleProjectPhasePersonLinkClick = (event: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
  };

  const handleUpdateMemberHours = React.useCallback(
    async (payload: UserHoursPayload, activeHours?: PhaseUserHour | null) => {
      if (!activeHours) {
        dispatch(setMemberHoursOnPhase(payload));
      } else {
        dispatch(
          updateMemberHoursOnPhase({
            id: activeHours.id,
            value: payload.value,
            weekNumber: payload.week_number,
          }),
        );
      }
    },
    [dispatch],
  );

  const selectedPreviousWeeks = selectedWeeks
    .filter((week) => new Date(week.end) < currentDate)
    .map((week) => week.weekNumber);

  const columns: Column<ProjectsTableProps>[] = React.useMemo(() => {
    if (selectedWeeks.length > 1) {
      return [
        {
          id: 'project_name',
          minWidth: 600,
          accessor: (d) => d.project.name,
          flex: '1 0 auto !important',
          Header: t('common.name') || '',
          disableSortBy: true,
          Cell: ({ row }: any) => {
            const { id, name, avatar } = row.original.project;
            const { name: phaseName } = row.original.phase;
            return (
              <>
                <AvatarWithBackground
                  name={name}
                  size="medium"
                  image={avatar}
                  radius="8px"
                  bgColor={
                    avatar ? 'transparent' : theme.colors.primaryLightGray
                  }
                />
                <Flex flexDirection="column" ml={theme.space[5]}>
                  <ProjectNameLink
                    onClick={handleProjectNameLinkClick}
                    href={`/projects/${id}`}
                    source={`${user?.full_name} profile, ${user?.id}`}
                    color="veryDarkGray"
                    fontSize="sm"
                    fontFamily={theme.fonts.regular}
                  >
                    {name || 'No name'}
                  </ProjectNameLink>
                  <Box
                    as="span"
                    minWidth="md"
                    fontSize={theme.fontSizes.xs}
                    color="veryDarkGray"
                    opacity={0.6}
                  >
                    {phaseName}
                  </Box>
                </Flex>
              </>
            );
          },
        },
        ...selectedWeeks.map((week) => ({
          id: `project_week_${week.weekNumber}`,
          minWidth: 125,
          maxWidth: 125,
          width: 125,
          isFullWidth: true,
          Header: () => (
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              width="100%"
            >
              {t('common.week')} {week.weekNumber}
              <Text
                color="veryDarkGray"
                fontSize="xs"
                opacity={0.6}
                textAlign="center"
                fontFamily="regular"
              >
                {format(parseISO(week.start), 'dd.MM')} -{' '}
                {format(parseISO(week.end), 'dd.MM')}
              </Text>
            </Flex>
          ),
          disableSortBy: true,
          Cell: ({ row }: any) => {
            const currentWeekData = projectsWeeks
              .find((w) => {
                return week.weekNumber === Number(w.week);
              })
              ?.projects?.find((p) => p.id === row.original.project.id)
              ?.phases?.find((ph) => ph.id === row.original.phase.id)
              ?.users?.find((m) => m.id === user.id);

            const phaseUserRange = row.original.phase.users.find(
              (u: User) => u.id === user.id,
            )?.phase_user_range;
            const phaseUserHour = currentWeekData?.phase_user_hour;
            const isUserHidden = phaseUserRange.hidden_at;

            const HoursInput = () => (
              <Box marginLeft="-1.2rem">
                <HoursTextField
                  defaultValue={phaseUserHour?.value}
                  placeholder="0"
                  disabled={
                    !canEditUserHours ||
                    selectedPreviousWeeks.includes(Number(week.weekNumber)) ||
                    isUserHidden
                  }
                  onEditFinish={(changedValue) => {
                    handleUpdateMemberHours(
                      {
                        user_id: user.id,
                        phase_id: row.original.phase.id,
                        value: Number(changedValue),
                        week_number: Number(week.weekNumber),
                        year: Number(week.start.substring(0, 4)),
                      },
                      phaseUserHour,
                    );
                  }}
                  isMemberConfirmed={phaseUserRange?.confirmed}
                  centered
                />
              </Box>
            );

            return (
              <Flex justifyContent="center" width="100%">
                {isUserHidden ? (
                  <Tooltip
                    render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
                      <Box
                        marginLeft="6px"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        ref={triggerRef}
                        sx={{
                          cursor: 'default',
                        }}
                      >
                        <HoursInput />
                      </Box>
                    )}
                  >
                    {t('projects.userIsHidden')}
                  </Tooltip>
                ) : (
                  <HoursInput />
                )}
              </Flex>
            );
          },
        })),
      ];
    }
    return [
      {
        id: 'project_name',
        minWidth: 250,
        accessor: (d) => d.project.name,
        Header: t('common.name') || '',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const { id, name, avatar } = row.original.project;
          const { name: phaseName } = row.original.phase;
          return (
            <>
              <AvatarWithBackground
                name={name}
                size="medium"
                image={avatar}
                radius="8px"
                bgColor={avatar ? 'transparent' : theme.colors.primaryLightGray}
              />
              <Flex flexDirection="column" ml={theme.space[5]}>
                <ProjectNameLink
                  onClick={handleProjectNameLinkClick}
                  href={`/projects/${id}`}
                  source={`${user?.full_name} profile, ${user?.id}`}
                  color="veryDarkGray"
                  fontSize="sm"
                  fontFamily={theme.fonts.regular}
                >
                  {name || 'No name'}
                </ProjectNameLink>
                <Box
                  as="span"
                  minWidth="md"
                  fontSize={theme.fontSizes.xs}
                  color="veryDarkGray"
                  opacity={0.6}
                >
                  {phaseName}
                </Box>
              </Flex>
            </>
          );
        },
      },
      {
        minWidth: 200,
        accessor: (d) => d.phase?.phase_responsible_users[0]?.user?.name,
        Header: 'Person responsible',
        disableSortBy: true,
        Cell: ({ row }: any): any => {
          const { phase_responsible_users } = row.original
            .phase as ProjectPhase;

          if (phase_responsible_users.length < 2) {
            const name =
              phase_responsible_users[0]?.user.name || 'No responsible person';
            const photo = phase_responsible_users[0]?.user.photo?.url || '';

            return (
              <>
                <ProjectPhasePersonLink
                  data-cy="user-view-btn"
                  href={`/users/${phase_responsible_users[0]?.user?.id}`}
                  isDisabled={!phase_responsible_users[0]?.user?.id}
                  onClick={handleProjectPhasePersonLinkClick}
                  source={
                    phase_responsible_users[0]?.user.id === user.id
                      ? source
                      : `${user.full_name} profile, ${user.id}`
                  }
                >
                  <AvatarWithBackground
                    name={name}
                    size="small"
                    image={photo}
                    bgColor={
                      photo ? 'transparent' : theme.colors.primaryLightGray
                    }
                  />
                  <Box
                    as="span"
                    ml={2}
                    fontSize={theme.fontSizes.sm}
                    color="veryDarkGray"
                  >
                    {name}
                  </Box>
                </ProjectPhasePersonLink>
              </>
            );
          }

          return phase_responsible_users.map((data) => (
            <Box
              key={`${data.id}-${data.user.id}`}
              marginRight={1}
              sx={{
                marginTop: '-4px',
                ':hover .name': {
                  opacity: 1,
                },
              }}
            >
              <ProjectPhasePersonLink
                data-cy="user-view-btn"
                href={`/users/${data.user?.id}`}
                isDisabled={!data.user?.id}
                onClick={handleProjectPhasePersonLinkClick}
                source={
                  data.user?.id === user.id
                    ? source
                    : `${user.full_name} profile, ${user.id}`
                }
              >
                <AvatarWithBackground
                  name={data.user.name || ''}
                  size="small"
                  image={data.user.photo?.url || ''}
                  bgColor={
                    data.user.photo?.url
                      ? 'transparent'
                      : theme.colors.primaryLightGray
                  }
                />
                <Box
                  as="span"
                  className="name"
                  fontSize={theme.fontSizes.sm}
                  color="veryDarkGray"
                  sx={{
                    backgroundColor: '#fff',
                    marginLeft: 0,
                    opacity: 0,
                    padding: '2px',
                    pointerEvents: 'none',
                    position: 'absolute',
                    top: '54px',
                    left: '15px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {data.user.name}
                </Box>
              </ProjectPhasePersonLink>
            </Box>
          ));
        },
      },
      {
        accessor: (d) => d.project.project_type,
        width: 100,
        Header: 'Type',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <Text fontSize={theme.fontSizes.xs} color="veryDarkGray">
            {row.original.project.project_type.name}
          </Text>
        ),
      },
      {
        id: 'hours_change',
        minWidth: 50,
        isFullWidth: true,
        Header: () => (
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            width="100%"
          >
            {t('common.week')} {selectedWeeks[0].weekNumber}
            <Text
              color="veryDarkGray"
              fontSize="xs"
              opacity={0.6}
              textAlign="center"
              fontFamily="regular"
            >
              {format(parseISO(selectedWeeks[0].start), 'dd.MM')} -{' '}
              {format(parseISO(selectedWeeks[0].end), 'dd.MM')}
            </Text>
          </Flex>
        ),
        Cell: ({ row }: any) => {
          const phase = row.original.phase;

          const currentWeekData = projectsWeeks
            .find((w) => {
              return selectedWeeks[0].weekNumber === Number(w.week);
            })
            ?.projects?.find((p) => p.id === row.original.project.id)
            ?.phases?.find((ph) => ph.id === row.original.phase.id)
            ?.users?.find((m) => m.id === user.id);

          const phaseUserHour = currentWeekData?.phase_user_hour;

          const phaseUserRange = phase.users.find((u: User) => u.id === user.id)
            ?.phase_user_range;

          const isWeekEndInPast = isPast(new Date(selectedWeeks[0].end));
          const isUserHidden = phaseUserRange.hidden_at;

          const HoursInput = () => (
            <Box marginLeft="-1.2rem">
              <HoursTextField
                defaultValue={phaseUserHour?.value}
                placeholder="0"
                disabled={!canEditUserHours || isWeekEndInPast || isUserHidden}
                onEditFinish={(changedValue) => {
                  handleUpdateMemberHours(
                    {
                      user_id: user.id,
                      phase_id: row.original.phase.id,
                      value: Number(changedValue),
                      week_number: Number(selectedWeeks[0]?.weekNumber),
                      year: Number(selectedWeeks[0]?.start.substring(0, 4)),
                    },
                    phaseUserHour,
                  );
                }}
                isMemberConfirmed={phaseUserRange?.confirmed}
                centered
              />
            </Box>
          );

          return (
            <Flex justifyContent="center" width="100%">
              {isUserHidden ? (
                <Tooltip
                  render={({ onMouseEnter, onMouseLeave, triggerRef }) => (
                    <Box
                      marginLeft="6px"
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      ref={triggerRef}
                      sx={{
                        cursor: 'default',
                      }}
                    >
                      <HoursInput />
                    </Box>
                  )}
                >
                  {t('projects.userIsHidden')}
                </Tooltip>
              ) : (
                <HoursInput />
              )}
            </Flex>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleProjectNameLinkClick,
    handleUpdateMemberHours,
    selectedWeeks,
    user.hours_weekly,
    user.id,
    projectsWeeks,
  ]);

  return (
    <Box flex={1}>
      <Box
        as="h2"
        color="veryDarkGray"
        fontSize="lg"
        mb={1}
        sx={{ fontFamily: 'bold' }}
      >
        {t('common.projects')}
      </Box>
      <StyledDivider />
      <Flex alignItems="center" justifyContent="space-between" mb={7}>
        <Flex>
          <WeekDatePicker variant="table" noRadius withBorders />

          <Flex alignItems="center" ml={4}>
            <InputSwitch
              checked={phaseStatus === 'inactive'}
              onChange={onPhaseStatusChange}
            />
            <Text fontSize="sm" color="veryDarkGray" ml={1}>
              {t('userProfile.showInactiveProjects')}
            </Text>
          </Flex>
        </Flex>

        {user && canEditAvailableResource && (
          <NewPrimeButton onClick={onAddToProject}>
            <RiAddFill size="24px" />
            <Text ml={3}>{t('userProfile.addToProject')}</Text>
          </NewPrimeButton>
        )}
      </Flex>

      {isLoading ? (
        <Spin />
      ) : !!userProjects.length ? (
        <>
          <ProjectsPhasesTable
            data={memoizedPhases}
            columns={columns}
            isPhasesExpanded={false}
            isExpandable={false}
            user={user}
            variant={Variant.TRANSPARENT_HEADER}
            isNewHeader
          />
          <Flex mt="33px" justifyContent="space-between" alignItems="center">
            <Text color="rgba(72, 81, 92, 0.6)">
              {t('projects.availableFor')}{' '}
              <Text as="span" color="rgba(72, 81, 92, 1)">
                {user.hours_weekly}
              </Text>{' '}
              {t('projects.hoursPerWeekSlash')}{' '}
            </Text>
          </Flex>
        </>
      ) : (
        <Box mt={10}>
          <NoProjectsToDisplay />
        </Box>
      )}
    </Box>
  );
};

export default UserProjects;
