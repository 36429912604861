import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { RootState } from 'store/rootReducer';

export enum ROW_PREFIXES {
  PROJECT = 'project',
  SPRINT = 'sprint',
}

type DashboardRowId = string;

type DashboardState = {
  expandedRows: {
    [rowId: string]: boolean;
  };
};

const INITIAL_STATE: DashboardState = {
  expandedRows: {},
};

const dashboardSlice = createSlice({
  initialState: INITIAL_STATE,
  name: 'dashboard',
  reducers: {
    setRow(state, action: PayloadAction<DashboardState['expandedRows']>): void {
      state.expandedRows = action.payload;
    },
  },
});

const { setRow } = dashboardSlice.actions;

export const setExpandedRow = (rowId: DashboardRowId): AppThunk => (
  dispatch,
  getState,
) => {
  const {
    dashboard: { expandedRows },
  } = getState();
  const sprintToCollapseId = Object.keys(expandedRows).find(
    (expandedRowKey) =>
      expandedRowKey !== rowId && expandedRowKey.includes(rowId),
  );
  const sprintRow = sprintToCollapseId ? { [sprintToCollapseId]: false } : {};

  dispatch(
    setRow({
      ...expandedRows,
      ...sprintRow,
      [rowId]: !expandedRows[rowId],
    }),
  );
};

export const selectDashboard = (state: RootState) => state.dashboard;

export const selectDashboardExpandedRows = createSelector(
  selectDashboard,
  ({ expandedRows }) => expandedRows,
);

export default dashboardSlice.reducer;
