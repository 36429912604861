import { useCallback, useMemo } from 'react';

import { fetcher } from '../fetcher';
import { getISOWeek } from 'date-fns';
import useSWR from 'swr';

type Week = {
  end: string;
  holidays: string[];
  start: string;
  weekNumber: number;
};

type FollowingWeeks = {
  [weekNumber: number]: Week;
};

const API_URL = '/weeks';

export const useSortedFollowingWeeks = () => {
  const { data } = useSWR<FollowingWeeks>(API_URL, fetcher);
  const sortWeeks = useCallback(
    (weeks: Week[]) =>
      weeks.sort(({ start: startA }, { start: startB }) =>
        startA.localeCompare(startB),
      ),
    [],
  );

  return useMemo(
    () => ({
      followingWeeks: data
        ? sortWeeks(Object.values(data)).map(({ end, start, ...rest }) => ({
            ...rest,
            end: new Date(end),
            start: new Date(start),
            weekNumber: getISOWeek(new Date(start)),
          }))
        : [],
      isLoading: !data,
    }),
    [data, sortWeeks],
  );
};
