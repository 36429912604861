import React from 'react';
import { useTranslation } from 'react-i18next';

import Title from '../../components/Title';
import { useProject } from '../../hooks/projectContext';

import {
  IntegrationTitle,
  IntegrationWrapper,
  IntegrationMessage,
  IntegrationLink,
  LinkMarker,
  LinkWrapper,
  GoToLink,
} from './ProjectIntegrationsView.styles';

const ProjectIntegrationsView = () => {
  const { t } = useTranslation();
  const { project } = useProject();

  return (
    <>
      <Title style={{ paddingBottom: 0 }}>
        {t('projectCard.pages.projectIntegrations')}
      </Title>

      {project?.project_integrations.length === 0 && (
        <p>{t('projectCard.noProjectIntegrations')}</p>
      )}

      {project?.project_integrations.map((integration) => (
        <IntegrationWrapper key={integration.service_name}>
          <IntegrationTitle>{integration.service_name}</IntegrationTitle>

          {integration.message && (
            <IntegrationMessage>{integration.message}</IntegrationMessage>
          )}

          {integration.links.map((link) => (
            <LinkWrapper key={link.name}>
              <LinkMarker />
              <IntegrationLink href={link.url} target="_blank" rel="noopener">
                {link.name}
                <GoToLink className="go-to-link">
                  {t('projectCard.pages.goToLink')}
                </GoToLink>
              </IntegrationLink>
            </LinkWrapper>
          ))}
        </IntegrationWrapper>
      ))}
    </>
  );
};

export default ProjectIntegrationsView;
