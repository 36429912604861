import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

export const TableWrapper = styled(Flex)`
  background-color: ${theme.colors.white};
  overflow: hidden;
  border-radius: ${theme.radii.lg};
  border: 1px solid ${theme.colors.neutral};
  border-top: none;
`;
