import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IntegrateAppsType } from 'api/organizationSettings/models';
import { NewPrimeButton } from 'components/prime/NewButton/NewButton';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { removeIntegration } from 'fetchers/actions/removeIntegration';
import { useIntegrationsStatus } from 'fetchers/hooks/useIntegrationsStatus';
import { Dialog } from 'primereact/dialog';
import { Box, Flex } from 'rebass';
import { displaySnackbar } from 'store/slices/snackbarSlice';

interface Props {
  integration: keyof IntegrateAppsType;
}

export const RemoveIntegration = ({ integration }: Props) => {
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mutate } = useIntegrationsStatus();

  const removeSettings = () => {
    removeIntegration(integration)
      .then(() => {
        mutate();
        setConfirmOpen(false);
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationRemoved'),
          }),
        );
      })
      .catch(() => {
        dispatch(
          displaySnackbar({
            message: t('organizationSettings.integrationRemovalFail'),
            isError: true,
          }),
        );
      });
  };

  return (
    <>
      <Box mr={2}>
        <PrimeTextButton
          onClick={() => setConfirmOpen(true)}
          label={t('organizationSettings.integrationRemoval')}
        />
      </Box>
      <Dialog
        onHide={() => setConfirmOpen(false)}
        visible={isConfirmOpen}
        blockScroll={true}
        header={t('organizationSettings.integrationRemoval')}
        style={{ width: '450px' }}
      >
        <Box fontSize="xs" py={4}>
          {t('organizationSettings.integrationRemovalConfirm')}
        </Box>
        <Flex justifyContent="flex-end" mt={4}>
          <PrimeTextButton
            onClick={() => setConfirmOpen(false)}
            label={t('common.cancel')}
          />
          <NewPrimeButton label={t('common.remove')} onClick={removeSettings} />
        </Flex>
      </Dialog>
    </>
  );
};
