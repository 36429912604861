import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Section,
  Title,
  SecondaryTitle,
  StyledLink,
  EmptyState,
} from '../../AboutProjectView.styles';
import { UsefulLink } from 'api/projects/models';

import {
  TableData,
  TableHead,
  TableLinkData,
  LinksTable,
} from './UsefulLinks.styles';

type Props = {
  links: UsefulLink[];
};

const UsefulLinks: FC<Props> = ({ links }) => {
  const { t } = useTranslation();
  return (
    <Section>
      <Title>{t('projectCard.sourceLinks')}</Title>
      {links.length ? (
        <LinksTable>
          <TableHead>
            <SecondaryTitle>{t('projectCard.linkSource')}</SecondaryTitle>
          </TableHead>
          <TableHead>
            <SecondaryTitle>{t('projectCard.linkDescription')}</SecondaryTitle>
          </TableHead>
          {links.map((item) => (
            <>
              <TableLinkData key={item.id}>
                <StyledLink href={item.link} target="_blank">
                  {item.link}
                </StyledLink>
              </TableLinkData>
              <TableData>{item.name}</TableData>
            </>
          ))}
        </LinksTable>
      ) : (
        <EmptyState>{t('projectCard.noSourceLinks')}</EmptyState>
      )}
    </Section>
  );
};

export default UsefulLinks;
