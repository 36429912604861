import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Spin } from '../Spin';
import { companyApi } from 'api/company/endpoints';
import { Dialog } from 'components/prime/Dialog';
import { NewStyledButton } from 'components/prime/NewButton/styled';
import { PrimeTextButton } from 'components/prime/TextButton/TextButton';
import { usePositions } from 'fetchers/hooks/users/usePositions';
import { useSkills } from 'fetchers/hooks/users/useSkills';
import { useTeams } from 'fetchers/hooks/users/useTeams';
import { useUsers } from 'fetchers/hooks/users/useUsers';
import { Box, Flex, Text } from 'rebass';
import { displaySnackbar } from 'store/slices/snackbarSlice';
import i18n from 'utils/i18n';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddExampleDataModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isAddingData, setIsAddingData] = useState(false);
  const { mutate: refetchTeams } = useTeams();
  const { mutate: refetchPositions } = usePositions();
  const { mutate: refetchUsers } = useUsers();
  const { mutate: refetchSkills } = useSkills();

  const handleClose = () => {
    localStorage.setItem('isExampleDataModalBlocked', 'true');
    onClose();
  };

  const refetchData = async () => {
    await Promise.all([
      refetchTeams(),
      refetchPositions(),
      refetchUsers(),
      refetchSkills(),
    ]);
  };

  const handleConfirm = async () => {
    setIsAddingData(true);

    try {
      await companyApi.fillInExampleData();

      dispatch(
        displaySnackbar({
          message: i18n.t('company.addExampleDataSuccess'),
          isError: false,
        }),
      );

      refetchData();

      handleClose();
    } catch {
      dispatch(
        displaySnackbar({
          message: i18n.t('company.addExampleDataError'),
          isError: true,
        }),
      );
    } finally {
      setIsAddingData(false);
    }
  };

  return (
    <Dialog
      visible={isOpen}
      header={t('company.addExampleDataHeading')}
      onHide={handleClose}
      top="30%"
      width="600px"
    >
      {!isAddingData ? (
        <>
          <Text mt={3}>{t('company.addExampleDataCopy')}</Text>

          <Flex justifyContent="flex-end">
            <Box mr={3}>
              <PrimeTextButton
                onClick={handleClose}
                label={t('common.cancel')}
              />
            </Box>
            <NewStyledButton onClick={handleConfirm} label={t('common.yes')} />
          </Flex>
        </>
      ) : (
        <Flex justifyContent="center" mt={3}>
          <Spin />
        </Flex>
      )}
    </Dialog>
  );
};
