import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTable } from 'react-table';

import { removeUsefulLink } from 'fetchers/actions/projectUsefulLinks/removeProjectsUsefulLink';
import { confirmPopup } from 'primereact/confirmpopup';
import { StyledLink } from 'screens/Project/screens/AboutProjectView/AboutProjectView.styles';

import {
  Table,
  TableData,
  TableHeadCell,
  TableLinkData,
  RemoveButton,
  RemoveIcon,
} from './UsefulLinksEditTable.styles';

export enum ACCESSORS {
  ID = 'id',
  LINK = 'link',
  NAME = 'name',
}

export type CellData = {
  [ACCESSORS.ID]: string;
  [ACCESSORS.LINK]: string;
  [ACCESSORS.NAME]: string;
};

type Props = {
  data: CellData[];
  projectId: string;
};

const UsefulLinksEditTable: FC<Props> = ({ data, projectId }) => {
  const { t } = useTranslation();

  const confirmDeleteLink = (event: MouseEvent, id: string) => {
    confirmPopup({
      target: event.currentTarget,
      message: t('projectCard.settings.editSettings.confirmDeleteLink'),
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger p-button-outlined',
      accept: () => acceptDelete(id),
      reject: () => rejectDelete(),
    });
  };

  const acceptDelete = (id: string) => {
    removeUsefulLink(projectId, id);
  };

  const rejectDelete = () => {
    return;
  };

  const columns = [
    { accessor: ACCESSORS.LINK, Header: 'Link source' },
    { accessor: ACCESSORS.NAME, Header: 'Link description' },
    {
      Header: 'Action',
      accessor: ACCESSORS.ID,
      Cell: (data: any) => (
        <>
          <RemoveButton
            onClick={(event: any) =>
              confirmDeleteLink(event, data.row.values.id)
            }
          >
            <RemoveIcon size="17px" />
          </RemoveButton>
        </>
      ),
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableColumns = useMemo(() => columns, []);
  const tableData = useMemo(() => data, [data]);

  const tableInstance = useTable({
    columns: tableColumns,
    data: tableData,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <Table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableHeadCell {...column.getHeaderProps()}>
                {column.render('Header')}
              </TableHeadCell>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <>
                    {cell.column.id === 'link' ? (
                      <TableLinkData {...cell.getCellProps()}>
                        <StyledLink href={cell.row.values.link} target="_blank">
                          {cell.render('Cell')}
                        </StyledLink>
                      </TableLinkData>
                    ) : (
                      <TableData {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableData>
                    )}
                  </>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default UsefulLinksEditTable;
