import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import { CleanModal } from 'components/ui';
import { AddNewMemberModal } from 'screens/Projects/components/MembersFooterData/AddNewMemberModal';
import ProjectsFilters from 'screens/Projects/components/ProjectsFilters';
import { UnhideMemberModal } from 'screens/Projects/components/UnhideMemberModal';
import UserRemoveModal from 'screens/Projects/components/UserRemoveModal';
import { selectModals, resetActiveModal } from 'store/slices/modalsSlice';
import { MODAL_ID } from 'utils/constants';

const Modal = {
  [MODAL_ID.ADD_TEAM_MEMBER]: AddNewMemberModal,
  [MODAL_ID.SHOW_USER]: UnhideMemberModal,
  [MODAL_ID.REMOVE_USER]: UserRemoveModal,
  [MODAL_ID.PROJECTS_FILTERS]: ProjectsFilters,
};

export const ModalRoot = () => {
  const dispatch = useDispatch();
  const { activeModalId, data } = useSelector(selectModals);
  const { size, title, type, ...restData } = data;

  const modalPortalId = document.getElementById('modal-root');

  const handleClose = useCallback(() => {
    dispatch(resetActiveModal());
  }, [dispatch]);

  if (!activeModalId || !modalPortalId) {
    return null;
  }

  const ActiveModal = Modal[activeModalId] as React.ElementType;

  return createPortal(
    <CleanModal
      onClose={handleClose}
      size={size}
      title={title}
      modalType={type}
    >
      <ActiveModal {...restData} onClose={handleClose} />
    </CleanModal>,
    modalPortalId,
  );
};
