import styled from '@emotion/styled/macro';
import { InputText } from 'primereact/inputtext';
import { Box, Flex } from 'rebass';
import { theme } from 'styles/theme';
import { HEIGHT_OF_TABLE_HEADERS_PLUS_TABLE_GAP } from 'utils/constants';

import { TableCellProps } from './models';

const border = `1px solid ${theme.colors.neutral}`;

export const HeaderRow = styled.div`
  border-top: ${border};
  border-bottom: ${border};
  display: flex;
  background-color: ${theme.colors.white};
  width: 100%;
  z-index: ${theme.zIndices['1']};
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  min-width: fit-content;
`;

export const UsersListWrapper = styled.div`
  overflow-y: auto;
  max-height: ${`calc(100vh - ${HEIGHT_OF_TABLE_HEADERS_PLUS_TABLE_GAP})`};
`;

export const TableCell = styled.div<TableCellProps>`
  min-width: ${({ minWidth }) => minWidth + 'px'};
  flex: ${({ minWidth }) => minWidth};
  display: flex;
  padding: ${theme.space[2]} ${theme.space[4]};
  font-size: ${theme.fontSizes.xs};
`;

export const TableHead = styled(TableCell)<TableCellProps>`
  align-items: end;
  cursor: ${({ sort }) => (sort !== false ? 'pointer' : 'initial')};
  height: ${theme.space[12]};
  font-family: ${theme.fonts.bold};
  font-size: ${theme.fontSizes.xxs};
  line-height: ${theme.lineHeights.md2};
  color: ${theme.colors.newDarkGrey};
  padding: ${theme.space[4]};
  user-select: none;
`;

export const TableRow = styled.div`
  display: flex;
`;

interface UserRowWrapperProps {
  even?: boolean;
}

export const RowWrapper = styled.div<UserRowWrapperProps>`
  display: flex;
  width: 100%;
  min-width: fit-content;
  align-items: center;
  background-color: ${({ even }) =>
    !even ? theme.colors.newLightGrey : theme.colors.white};
  border-bottom: ${({ even }) => (!even ? border : 'none')};
  max-height: ${theme.space[12]};
  font-size: ${theme.fontSizes.xxs};

  &:last-of-type {
    border-radius: 0 0 ${theme.radii.lg} ${theme.radii.lg};
  }
`;

export const ListHeaderWrapper = styled(Flex)<{ maxHeight?: string }>`
  background-color: ${theme.colors.white};
  z-index: ${theme.zIndices['5']};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : theme.space[9])};
  height: ${({ maxHeight }) => (maxHeight ? maxHeight : theme.space[9])};
  width: 100%;
  border-radius: ${theme.radii.lg} ${theme.radii.lg} 0 0;
  overflow-x: visible;
`;

export const ListSearchField = styled(InputText)`
  border: none;
  width: 100%;

  &:enabled:focus {
    border: none;
    box-shadow: none;
  }
`;

export const BoldBox = styled(Box)`
  font-family: ${theme.fonts.bold};
`;

export const SmallFontBox = styled(Box)`
  font-size: ${theme.fontSizes.xxs};
`;
