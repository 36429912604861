import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import NotFound from 'screens/404';
import { ACCESS_TOKEN_KEY } from 'screens/Auth/constants';
import { getFromStorage } from 'utils/localStorage';

import MainLayout from './components/MainLayout';

interface Props extends RouteProps {
  isScrollable?: boolean;
  hasPermissions?: boolean;
}

export const PrivateRoute: React.FC<Props> = ({
  children,
  isScrollable = true,
  hasPermissions = true,
  ...rest
}) => {
  const isLoggedIn = getFromStorage(ACCESS_TOKEN_KEY);

  if (isLoggedIn && !hasPermissions) {
    return <Route component={NotFound} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <MainLayout isScrollable={isScrollable}>{children}</MainLayout>
        ) : (
          <Redirect
            to={{ pathname: '/auth/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};
