import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddNewButton } from '../AddNewButton';
import { RemoveItemModal } from '../RemoveModal';
import { Icon, Wrapper } from '../styled';
import { ProjectType, User } from 'api/users/models';
import { Tag } from 'components/prime';
import { Spin } from 'components/ui';
import { CannotRemoveModal } from 'components/ui/CannotRemoveModal/CannotRemoveModal';
import { removeProjectType } from 'fetchers/actions/projectType/removeProjectType';
import { useProjectTypes } from 'fetchers/hooks/users/useProjectTypes';
import { Box, Flex } from 'rebass';

import { ProjectTypeModal } from './components/ProjectTypeModal';

interface Error {
  data: { errors: any; users?: User[] };
}

export const ProjectTypes = () => {
  const [editedType, setEditedType] = useState<ProjectType | null>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [typeModalOpen, setTypeModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { projectTypes, isLoading } = useProjectTypes();
  const usersBlockingRemoval = useRef<User[] | null>(null);
  const itemToDelete = useRef<ProjectType | null>(null);

  const addNewType = () => {
    setEditedType(null);
    setTypeModalOpen(true);
  };

  const editType = (type: ProjectType) => {
    setEditedType(type);
    setTypeModalOpen(true);
  };

  const deleteType = () => {
    removeProjectType(itemToDelete.current?.id!)
      .then(() => setConfirmOpen(false))
      .catch((err) => openInfoModal(err));
  };

  const openConfirm = (projectType: ProjectType) => {
    itemToDelete.current = projectType;
    setConfirmOpen(true);
  };

  const openInfoModal = ({ data }: Error) => {
    setConfirmOpen(false);
    if (data?.errors && typeof data.errors === 'string') {
      usersBlockingRemoval.current = data.users || null;
      setDialogOpen(true);
    }
    if (data?.errors && typeof data.errors === 'object') {
      setDialogOpen(true);
    }
  };

  const lowercaseTypeNames = useMemo(() => {
    return projectTypes?.map((p) => p.name.toLowerCase()) || [];
  }, [projectTypes]);

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Wrapper justifyContent="flex-start">
            <AddNewButton onClick={addNewType} />
            {projectTypes?.map((type) => {
              return (
                <Tag key={type.id}>
                  <Flex p={1}>
                    <Flex flex={1}>
                      <Box mr={2} fontSize="xs">
                        {type.name}
                      </Box>
                    </Flex>
                    <Icon>
                      <i
                        className="pi pi-pencil"
                        onClick={() => editType(type)}
                      />
                      <i
                        className="pi pi-trash"
                        onClick={() => openConfirm(type)}
                      />
                    </Icon>
                  </Flex>
                </Tag>
              );
            })}
          </Wrapper>

          <ProjectTypeModal
            isOpen={typeModalOpen}
            setIsOpen={setTypeModalOpen}
            projectTypeList={lowercaseTypeNames}
            projectType={editedType || null}
          />
          <CannotRemoveModal
            isOpen={dialogOpen}
            setIsOpen={setDialogOpen}
            usersBlockingRemoval={usersBlockingRemoval.current || []}
            itemName="ProjectType"
          />
          <RemoveItemModal
            message={t('organizationSettings.deleteConfirm', {
              name: itemToDelete.current?.name,
              item: t('projects.projectType').toLowerCase(),
            })}
            close={() => setConfirmOpen(false)}
            isOpen={isConfirmOpen}
            confirm={deleteType}
            header={t('users.removeProjectType')}
          />
        </>
      )}
    </>
  );
};
