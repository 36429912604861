import styled from '@emotion/styled/macro';
import { theme } from 'styles/theme';

type Props = {
  isMemberConfirmed?: boolean;
  border?: boolean;
  disabled?: boolean;
  centered: boolean;
};

export const HoursInput = styled.input<Props>`
  border: ${({ border }) =>
    border ? `1px solid ${theme.colors.neutral}` : 'none'};
  background-color: ${({ isMemberConfirmed }) =>
    isMemberConfirmed ? 'transparent' : theme.colors.neutralLight};
  width: 3.1rem;
  height: 3.1rem;
  margin-right: ${({ centered }) => (centered ? '0' : '10px')};
  border-radius: ${theme.radii.md};
  position: relative;
  margin-left: ${({ centered }) => (centered ? '0' : '-10px')};
  padding-left: 10px;
  color: ${({ isMemberConfirmed }) =>
    isMemberConfirmed ? theme.colors.primary : theme.colors.midGray};
  cursor: pointer;
  -moz-appearance: textfield;

  &:hover {
    background-color: ${theme.colors.lightGrayBlue};
  }

  &:focus {
    outline: none;
    box-shadow: ${theme.shadows.outline};
  }

  &::placeholder {
    color: ${({ disabled }) =>
      disabled ? theme.colors.primaryLightGray : theme.colors.midGray};
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    cursor: default;
    background-color: transparent;
  }
`;
