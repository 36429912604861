import { UserSkill } from 'api/users/models';
import { fetcher } from 'fetchers/fetcher';
import useSWR from 'swr';
import { sortByKey } from 'utils/sortByKey';

const API_URL = '/skill_names';

type SkillsPayload = {
  skill_names: UserSkill[];
};

export const useSkills = () => {
  const { data, mutate } = useSWR<SkillsPayload>(API_URL, fetcher);

  return {
    isLoading: !data,
    skills: data?.skill_names.sort(sortByKey('name')) || [],
    mutate,
  };
};
