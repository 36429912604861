import styled from '@emotion/styled/macro';
import { Flex } from 'rebass';
import { theme } from 'styles/theme';

interface Props {
  left?: boolean;
  right?: boolean;
}

const border = `1px solid ${theme.colors.neutral}`;

export const BorderBox = styled(Flex)<Props>`
  border-right: ${({ right }) => (right ? border : 'none')};
  border-left: ${({ left }) => (left ? border : 'none')};
`;
