import type { SelectItem } from '.';

export const selectItemByLabel = (a: SelectItem, b: SelectItem) => {
  if (a.label === 'No responsible person') return -1;
  if (b.label === 'No responsible person') return 1;
  if (a.label && b.label !== null) {
    return a.label.localeCompare(b.label, undefined, { sensitivity: 'base' });
  } else {
    return 0;
  }
};
