import { phasesAPI } from 'api/phases/endpoints';
import { mutateProjectsWeeks } from 'fetchers/mutations/mutateProjectsWeeks';

type UserHours = {
  phaseId: number;
  year: number;
  phaseUserHourId?: number;
  userId: number;
  value: number;
  weekNumber: number;
  allSelectedWeeks?: number[];
};

export const setUserHoursOnPhase = async ({
  phaseId,
  phaseUserHourId,
  userId,
  value,
  weekNumber,
  allSelectedWeeks,
  year,
}: UserHours) => {
  if (phaseUserHourId) {
    await phasesAPI.updateMemberHoursOnPhase(phaseUserHourId, value);
  } else {
    await phasesAPI.setMemberHoursOnPhase({
      phase_id: phaseId,
      user_id: userId,
      value: value,
      week_number: weekNumber,
      year: year,
    });
  }

  await mutateProjectsWeeks(allSelectedWeeks);
};
