import styled from '@emotion/styled/macro';
import sdhLogo from 'assets/images/startup_logo.png';
import { verticalScroll } from 'components/primitive/CustomScroll';
import { theme } from 'styles/theme';

export const SideModalBody = styled.div`
  position: relative;
  height: 80%;
  display: flex;
  flex-direction: column;
`;

export const TitleIcon = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
  color: ${theme.colors.primary};
  background-color: ${theme.colors.lightBlue};
  border-radius: ${theme.radii.sm};
`;

export const DescriptionBox = styled.div`
  margin-bottom: 3rem;
`;

export const DescriptionTitle = styled.h4`
  color: ${theme.colors.midGray};
  text-transform: uppercase;
  font-size: ${theme.fontSizes.xxs};
  margin-top: 1.2rem;
  margin-bottom: 0.8rem;
  font-family: ${theme.fonts.bold};
`;

export const Description = styled.p`
  color: ${theme.colors.midGray};
  font-size: ${theme.fontSizes.xs};
  font-family: ${theme.fonts.regular};
  letter-spacing: 0.2px;
`;

export const SubTypeBox = styled.div`
  width: 104%;
  position: relative;
  left: -1.1rem;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
  ${verticalScroll};
`;

export const SubTypeItem = styled.div`
  height: 3.1rem;
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${theme.colors.neutral};
`;

export const SubTypeTitle = styled.div`
  padding-left: 1.1rem;
`;

export const Gradient = styled.div`
  width: 100%;
  height: 1.87rem;
  position: absolute;
  bottom: 6rem;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(20, 40, 71, 0),
    rgba(20, 40, 71, 0.06)
  );
  pointer-events: none;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const CompanyLogo = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: ${theme.radii.xmd};
  margin-right: 0.68rem;
  background: url(${sdhLogo}) no-repeat;
  background-size: contain;
`;

export const Placeholder = styled.span`
  color: ${theme.colors.darkGray};
`;
